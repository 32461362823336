import { Tag } from "components/page/newHeader/styles/HeaderStyles";
import {
  CarouselContainerWithScrollBar,
  SectionContainer,
  SliderSectionInner,
} from "components/rhombus-UI/theme/containers";
import { graphql, Link, StaticQuery } from "gatsby";
import React, { useState } from "react";
import { FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import TextLinkV2 from "../common/TextLinkV2";
import {
  CardTitle,
  FeaturedCaseStudyCard,
  FeaturedTag,
  slideWrapperStyles,
} from "./styles/caseStudyStyles";
import { isDateWithinLast7Days } from "./util/caseStudyFunction";
import { PreTitle } from "components/rui/typography";

export default function FeaturedCaseStudySlider() {
  const renderContent = (data) => {
    const [startPercentage, setStartPercentage] = useState(0);
    const [endPercentage, setEndPercentage] = useState(80);
    const { edges: _caseStudies } = data.allMarkdownRemark;

    const handlePercentageBasedOnActiveIndex = (activeIndex) => {
      if (activeIndex === 0) {
        setStartPercentage(0);
        setEndPercentage(80);
      } else if (activeIndex > 0) {
        setStartPercentage(10);
      } else if (activeIndex === data.length - 1) {
        setEndPercentage(0);
      }
    };

    return (
      <SectionContainer style={{}}>
        <SliderSectionInner>
          <CarouselContainerWithScrollBar
            startPercentage={startPercentage}
            endPercentage={endPercentage}
          >
            <Swiper
              freeMode
              onSlideChange={(swiper) =>
                handlePercentageBasedOnActiveIndex(swiper.activeIndex)
              }
              slidesPerView="auto"
              centeredSlides
              centeredSlidesBounds
              spaceBetween={40}
              scrollbar={{ draggable: true }}
              mousewheel={{
                releaseOnEdges: true,
                invert: false,
              }}
              modules={[Mousewheel, FreeMode, Scrollbar]}
            >
              {_caseStudies.map((item) => {
                const caseStudy = item.node.frontmatter;
                const slug = item.node.fields.slug;
                return (
                  <SwiperSlide
                    key={item.node.id}
                    className={slideWrapperStyles}
                  >
                    <FeaturedCaseStudyCard as={Link} to={slug}>
                      <div className="card-image-wrapper">
                        <img
                          src={caseStudy.heroImage}
                          className="card-image"
                          alt=""
                        />

                        {caseStudy.cardLogo && (
                          <img
                            src={caseStudy.cardLogo}
                            className="logo"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="inner">
                        <FeaturedTag>
                          <p>Featured</p>
                        </FeaturedTag>
                        <div
                          style={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <PreTitle color="var(--primary-bg)">
                            {caseStudy.industry}
                          </PreTitle>
                          {isDateWithinLast7Days(caseStudy.date) && (
                            <Tag>New</Tag>
                          )}
                        </div>
                        <CardTitle
                          style={{
                            textAlign: "left",
                            fontSize: "24px",
                          }}
                        >
                          {caseStudy.pageTitle}
                        </CardTitle>
                        <div className="footer">
                          <TextLinkV2
                            title="Read Story"
                            color="var(--blue-500)"
                            path={slug}
                          />
                        </div>
                      </div>
                    </FeaturedCaseStudyCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </CarouselContainerWithScrollBar>
        </SliderSectionInner>
      </SectionContainer>
    );
  };
  const pageQuery = graphql`
    query {
      allMarkdownRemark(
        limit: 3
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "case-study" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date
              companyLogo
              heroImage
              companyName
              pageTitle
              featured
              industry
              cardLogo
            }
          }
        }
      }
    }
  `;

  return <StaticQuery query={pageQuery} render={renderContent} />;
}
