import React, { useState } from "react";
import CompareTable from "./CompareTable";
import CompareTrialSection from "./CompareTrialSection";
import CustomerStoriesCarousel from "components/common/Customer Stories Carousel/CustomerStoriesCarousel";
import { graphql, useStaticQuery } from "gatsby";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import HeroLayout1 from "components/common/hero/HeroLayout1";

export default function ComparePage({ competitors, compareData }) {
  const [filter, setFilter] = useState(compareData.frontmatter.title);

  const onFilterChange = (newOption) => {
    const getCurrentPage = competitors.find((competitor) => {
      return competitor.name === newOption;
    });

    setFilter(newOption);
    window.location.pathname = getCurrentPage.url;
  };

  const data = useStaticQuery(graphql`
    query {
      tenFedLogo: file(
        relativePath: { eq: "components/customer-stories/logos/10-federal.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      luxerLogo: file(
        relativePath: { eq: "components/customer-stories/logos/luxer-one.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      blakesLogo: file(
        relativePath: { eq: "components/customer-stories/logos/blakes.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      nemahaLogo: file(
        relativePath: { eq: "components/customer-stories/logos/nemaha.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      hero: file(
        relativePath: { eq: "components/compare/img/compare-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const slides = [
    {
      quote:
        "Rhombus has allowed us to remotely view any property, be alerted to specific activities, better serve our customers, actively control building access, and more.",
      cite: "Brad Minsley, Managing Director",
      company: "10 Federal",
      image: data.tenFedLogo,
    },
    {
      quote:
        "We have a system that is able to operate smoothly no matter how many cameras we add. That alone is a positive ROI.",
      cite: "Arik Levy, CEO",
      company: "Luxer One",
      image: data.luxerLogo,
    },
    {
      quote:
        "Any school district looking to upgrade thheir video surveillance should choose Rhombus. The UI, performance, and price point make perfect sense.",
      cite: "Brett Brack, Director of IT",
      company: "Nemaha School District",
      image: data.nemahaLogo,
    },
    {
      quote:
        "The quality and speed of service we get from Rhombus are incredie. It’s a wonderful partnership.",
      cite: "Roger Hart-York, IT Operations Manager",
      company: "Blake’s Lotaburger",
      image: data.blakesLogo,
    },
  ];

  return (
    <>
      <HeroLayout1
        data={{
          preTitle: "compare",
          title: "Rhombus vs. Other Physical Security Platforms",
          description:
            "Rhombus provides an integrated platform with industry-leading performance, cybersecurity, and so much more. See why industry leaders choose Rhombus.",
          image: data.hero,
          altText: "",
          button1: {
            text: "Start Trial",
            path: "/free-trial/",
          },
        }}
        bottom
      />
      <CompareTable
        compareData={compareData.frontmatter}
        competitors={competitors}
        title={compareData.frontmatter.title}
        filter={filter}
        setFilter={onFilterChange}
      />
      <CompareTrialSection
        title={compareData.frontmatter.compareTitle}
        paragraph={compareData.frontmatter.compareDescription}
        link={compareData.frontmatter.link}
        linkText={compareData.frontmatter.linkText}
        image={compareData.frontmatter.thumbnail}
      />
      <CustomerStoriesCarousel
        slides={slides}
        backgroundColor="white"
        title="See Why Industry Leaders Choose Rhombus"
      />
      <TrialBanner />
    </>
  );
}
