import Link from "components/page/headers/HeaderLink";
import { styled } from "@linaria/react";
import React, { useCallback, useEffect } from "react";
import { NavButtonContainer, NavLink } from "../styles/HeaderStyles";
import RhombusButton from "components/common/RhombusButton";
import { MEGA_LINKS, NAV_LINKS } from "../lib/menu-data";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  currentLinkAtom,
  megaMenuOpenAtom,
  scrollActiveAtom,
} from "../atoms/headerAtoms";
import TextLinkV2 from "components/common/TextLinkV2";
import Carrot from "components/common/icon-components/Carrot";
import IconCarrot from "components/common/icon-components/IconCarrot";
import { useGetQuoteTitleAtom } from "components/landing-pages/surveillance/SurveillancePageV4";

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  gap: 2.5rem;
  padding-block-start: 0.25rem;
`;

const DesktopHeaderContent = ({
  dark,
  landingPage,
  customLinks,
  ctaLink,
  includeButton = false,
  buttonType,
  buttonText,
}) => {
  const [currentLink, setCurrentLink] = useAtom(currentLinkAtom);
  const [megaMenuOpen, setMegaMenuOpen] = useAtom(megaMenuOpenAtom);
  const scrollActive = useAtomValue(scrollActiveAtom);
  const setGetQuoteTitle = useSetAtom(useGetQuoteTitleAtom);

  useEffect(() => {
    setMegaMenuOpen(false);
    setCurrentLink(null);
  }, []);

  const handleMegaLinkAction = (link) => {
    setCurrentLink(currentLinkStateHandler(link));
    setMegaMenuOpen((prev) => megaMenuOpenStateHandler(link, prev));
  };

  const megaMenuOpenStateHandler = useCallback(
    (link, prevCurrentLinkState) => {
      if (!currentLink) return true;
      if (currentLink && link.name === currentLink.name) {
        return !prevCurrentLinkState;
      } else {
        return true;
      }
    },
    [currentLink]
  );

  const currentLinkStateHandler = useCallback(
    (link) => {
      if (!currentLink) return link;
      if (currentLink && link.name === currentLink.name) {
        return null;
      } else {
        return link;
      }
    },
    [currentLink]
  );

  return (
    <>
      <NavLinks>
        {!landingPage &&
          MEGA_LINKS.map((link) => (
            <NavLink
              key={link.name}
              onClick={() => handleMegaLinkAction(link)}
              active={
                currentLink && link.name === currentLink.name ? true : false
              }
              color={
                dark && !megaMenuOpen && !scrollActive
                  ? "var(--nuetral-200)"
                  : "var(--nuetral-500)"
              }
              hoverColor={
                dark && !megaMenuOpen && !scrollActive
                  ? "var(--white)"
                  : "var(--primary-bg)"
              }
            >
              {link.name}
              <Carrot />
            </NavLink>
          ))}
        {!landingPage &&
          NAV_LINKS.map((link) => (
            <NavLink
              key={link.name}
              color={
                dark && !megaMenuOpen && !scrollActive
                  ? "var(--nuetral-200)"
                  : "var(--nuetral-500)"
              }
              hoverColor={
                dark && !megaMenuOpen && !scrollActive
                  ? "var(--white)"
                  : "var(--primary-bg)"
              }
            >
              <Link to={link.path} activeClassName="active">
                {link.name}
              </Link>
            </NavLink>
          ))}
        {landingPage &&
          customLinks &&
          customLinks.map((link) => (
            <NavLink
              color={
                dark && !megaMenuOpen && !scrollActive
                  ? "var(--nuetral-200)"
                  : "var(--nuetral-500)"
              }
              hoverColor={
                dark && !megaMenuOpen && !scrollActive
                  ? "var(--white)"
                  : "var(--nuetral-900)"
              }
            >
              <Link key={link.name} to={link.path} activeClassName="active">
                {link.name}
              </Link>
            </NavLink>
          ))}
      </NavLinks>
      {!landingPage && (
        <NavButtonContainer>
          <RhombusButton
            useHref
            target="_blank"
            path="https://console.rhombussystems.com/login/"
            title="Console Sign in"
            type={
              dark && !megaMenuOpen && !scrollActive
                ? "secondary-light"
                : "secondary"
            }
          />
          <RhombusButton
            title="Request Demo"
            path="/live-demo/"
            type="primary"
            small
          />
        </NavButtonContainer>
      )}
      {landingPage && includeButton && (
        <NavButtonContainer>
          <RhombusButton
            small
            title={buttonText}
            path={ctaLink}
            type={buttonType}
            useTitleCase={false}
            useHref={ctaLink.includes("#")}
            renderIcon={() =>
              buttonType === "landing-v2" && (
                <IconCarrot color="var(--blue-900)" />
              )
            }
            onClick={() => setGetQuoteTitle(false)}
          />
        </NavButtonContainer>
      )}
    </>
  );
};

export default DesktopHeaderContent;
