import React from "react";
import { css } from "@linaria/core";
import CameraDetailHeader from "./CameraDetailHeader";
import CameraSpecs from "./CameraSpecs";
import CameraVideo from "./CameraVideo";
import ExporeCameraModels from "../ExploreOtherCameras";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import AccessoriesContainer from "components/accessories/v2/AccessoriesContainer";

export default function CameraDetailPage({
  camera,
  ctaCopy,
  pricingCopy,
  ctaLink,
  pricingLink,
}) {
  const {
    model,
    type,
    description,
    specs,
    exploreModels,
    accessoryCompatible,
    sensorCompatible,
  } = camera;

  return (
    <>
      <CameraDetailHeader
        model={camera?.heroTitle ?? model}
        type={type}
        description={description}
        webHeader={camera.webHeader}
        mobileHeader={camera.mobileHeader}
        objectPosition={camera.objectPosition}
        heroAlt={camera?.heroAlt ?? ""}
        hidePricing={camera?.hidePricing ?? false}
        ctaCopy={ctaCopy}
        pricingCopy={pricingCopy}
        ctaLink={ctaLink}
        pricingLink={pricingLink}
      />
      <CameraSpecs
        title={camera?.specTitle ?? `${model} Camera Specifications`}
        subTitle={camera.specSubTitle}
        specs={specs}
        dims={camera.dims}
        dimeAlt={camera?.dimsAlt ?? ""}
        pdf={camera.pdf}
      />
      {camera?.renderProductDiagram}
      {accessoryCompatible && (
        <AccessoriesContainer
          defaultSelection={model}
          singleDeviceMode
          staticTitle={camera?.accTitle ?? null}
        />
      )}
      {sensorCompatible && <CameraVideo />}
      <ExporeCameraModels
        title={exploreModels.title}
        subtitle={exploreModels.subtitle}
        cameraCards={exploreModels.cameraCards}
      />
      <TrialBanner />
    </>
  );
}
