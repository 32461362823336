import PageContent from "components/page/PageContent";
import { css } from "@linaria/core";
import React from "react";
import Link from "components/common/Link";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import SlideInContainer from "components/common/SlideInContainer";
import { FancyTitle } from "components/rhombus-UI/theme/typography";
import TextLinkV2 from "components/common/TextLinkV2";
import { Body, SectionTitle } from "components/rui/typography";

const contentClass = css`
  padding: 5rem 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const titleContainer = css`
  text-align: center;
  margin-bottom: 48px;
  width: 100%;

  @media (max-width: 700px) {
    margin-bottom: 35px;
  }

  div {
    width: 100%;
  }

  h1 {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500) 36%,
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
  }
`;

const cardContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 70px;

  @media (max-width: 1330px) {
    column-gap: 45px;
  }

  @media (max-width: 700px) {
    row-gap: 35px;
  }

  > div {
    width: 45%;
    margin: 0 auto;

    @media (max-width: 950px) {
      width: 100%;
    }
  }
`;

const linkContainerClass = css`
  text-decoration: none;
  color: rgba(43, 46, 48, 0.99);
  display: flex;
  justify-content: center;

  @media (max-width: 700px) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const cardClass = css`
  display: block;
  background: white;
  border-radius: 14px;
  padding: 62px 13px 62px 64px;
  width: 614px;
  height: 236px;
  background: var(--nuetral-100);

  @media (max-width: 1330px) {
    padding: 21px 13px 18.5px 32px;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: 156.5px;
  }
`;

const cardContentClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 70px;

  @media (max-width: 1330px) {
    gap: 34.5px;
  }
  @media (max-width: 950px) {
    gap: 70px;
  }
  @media (max-width: 700px) {
    gap: 34.5px;
  }

  h3 {
    font-size: 18px;
    line-height: 25.18px;
    margin-bottom: 8px;

    @media (max-width: 700px) {
      font-size: 14px;
      margin-bottom: unset;
    }
  }
  p {
    font-size: 16px;
    line-height: 2;
    max-width: 236px;

    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

const textContainer = css`
  max-width: 445px;
`;

const imageClass = css`
  pointer-events: none;

  @media (max-width: 700px) {
    width: 25%;
  }
`;

export default function ExploreOtherAccessControl({ title, subtitle, cards }) {
  return (
    <section>
      <PageContent>
        <div className={contentClass}>
          <div className={titleContainer}>
            <SlideInContainer>
              <SectionTitle>{title}</SectionTitle>
            </SlideInContainer>
            <SlideInContainer>
              <Body>{subtitle}</Body>
            </SlideInContainer>
          </div>
          <div className={cardContainer}>
            {cards.map((card, index) => {
              const image = getImage(card.image);
              return (
                <SlideInContainer>
                  <Link
                    to={card.link}
                    className={linkContainerClass}
                    key={card.model}
                  >
                    <div key={index} className={cardClass}>
                      <div className={cardContentClass}>
                        <GatsbyImage
                          image={image}
                          style={{ maxWidth: card.width }}
                          alt={card.alt}
                          className={imageClass}
                        />
                        <div className={textContainer}>
                          <h3>{card.model}</h3>
                          <p>{card.useCase}</p>
                          <TextLinkV2 path={card.link} title={"Learn more"} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </SlideInContainer>
              );
            })}
          </div>
        </div>
      </PageContent>
    </section>
  );
}
