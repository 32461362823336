import { styled } from "@linaria/react";
import React from "react";
import { ProfilePhoto } from "./AuthorSnippet";
import {
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import IconLinkedIn from "components/common/icon-components/IconLinkedIn";

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 32px;
  gap: 16px;
  background: #ebeef0;
  border-radius: 8px;
  margin-top: 2.5rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SocialLink = styled.a`
  filter: grayscale(1);
  transition: all 250ms;
  :hover {
    filter: grayscale(0);
  }
`;

const AuthorProfileCard = ({ src, alt, name, role, bio, socialLink }) => {
  return (
    <Card>
      <ProfilePhoto src={src} alt={alt} height={48} width={48} />
      <Details>
        <TitleRow>
          <div>
            <TitleSmall>{name}</TitleSmall>
            <MainParagraph>{role}</MainParagraph>
          </div>
          <SocialLink href={socialLink} target="_blank">
            <IconLinkedIn />
          </SocialLink>
        </TitleRow>
        <MainParagraph>{bio}</MainParagraph>
      </Details>
    </Card>
  );
};

export default AuthorProfileCard;
