import React from "react";
import { FlexRowCenterCenter } from "../layout";
import { styled } from "@linaria/react";

const Dot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ active, primaryColor, secondaryColor }) =>
    active ? primaryColor : secondaryColor};
`;

const SliderNavDots = ({
  slides,
  currentIndex,
  setCurrentIndex,
  center = true,
  primaryColor = "var(--blue-500)",
  secondaryColor = "var(--blue-100)",
  spacing = "4px",
}) => {
  return (
    <FlexRowCenterCenter
      gap={spacing}
      style={center ? {} : { justifyContent: "flex-start" }}
    >
      {slides.map((_, index) => (
        <Dot
          key={index}
          active={index === currentIndex}
          onClick={() => setCurrentIndex(index)}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      ))}
    </FlexRowCenterCenter>
  );
};

export default SliderNavDots;
