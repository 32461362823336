import IndustryPageUpdated from "components/industries/updated/IndustryPageUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function FoodAndBeverageIndustry() {
  const renderContent = (data) => {
    const pageData = {
      industry: "Food and Beverage",
      header: {
        headerTitle: "Simplify Restaurant Operations and Improve Visibility",
        headerParagraph:
          "Enhance quality control, increase food safety, ensure better customer experiences, and streamline operations with the help of cloud-based cameras, smart sensors, and advanced analytics from Rhombus.",
        headerImg: data.headerImage,
        mobileHeaderImg: data.headerImageMobile,
      },
      industryTitle: "Trusted by Innovative Food & Beverage Leaders",
      industryLogos: [
        {
          img: data.logo1,
          width: "93px",
          alt: "First Watch",
        },
        {
          img: data.logo2,
          width: "138px",
          alt: "Blakes Lotaburger",
        },
        {
          img: data.logo3,
          width: "160px",
          alt: "bassment",
        },
        {
          img: data.logo4,
          width: "127px",
          alt: "Kitchen united mix",
        },
        {
          img: data.logo5,
          width: "173px",
          alt: "Five star food service",
        },
        {
          img: data.logo6,
          width: "96px",
          alt: "Nisos",
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "money",
          title: "Reduce Theft and Fraud",
          p: "Detect, deter, and investigate theft and fraud with 24/7 monitoring. AI-powered alerts and intuitive investigative tools help you manage security at scale.",
        },
        {
          icon: data.icon2,
          iconAlt: "light-bulb",
          title: "Maintain an Elevated Ambiance",
          p: "Say goodbye to obtrusive hardware, servers, and complexity. Obtain best-in-class visibility without compromising on interior aesthetics.",
        },
        {
          icon: data.icon3,
          iconAlt: "shield",
          title: "Create Safer Spaces",
          p: "Protect customers and employees with high-resolution video, industry-leading system reliability, and automated real-time alerts when security issues occur.",
        },
        {
          icon: data.icon4,
          iconAlt: "Investigate",
          title: "Simplified Investigations",
          p: "No more manual scrubbing. Save time with multi-camera investigations and smart search—face/vehicle recognition, audio analytics, color search, and more. ",
        },
      ],
      learnRows: [
        {
          image: data.learnImage1,
          title:
            "Keep Staff and Guests Safe with High Definition Video Evidence",
          description:
            "Monitor multiple locations from anywhere with centralized 24/7 cloud surveillance. Protect staff and patrons with clear video evidence of incidents and deter criminal activity such as theft, fraud, vandalism, and break-ins.",
          linkText: "Learn About Cloud-Based Cameras",
          linkUrl: "/cameras",
        },
        {
          image: data.learnImage2,
          title: "Integrate Point-of-Sale Data with Camera Footage",
          description:
            "Integrate with point-of-sale (POS) systems to automatically pair transactions with video footage. Quickly and easily cross-reference sales events with video context to ensure accuracy, address discrepancies, and make informed decisions.",
          linkText: "Learn About Integrations",
          linkUrl: "/integrations",
        },
        {
          image: data.learnImage3,
          title: "Make Decisions with Occupancy and Space Utilization Data",
          description:
            "Layer data on video feeds to visualize occupancy trends, wait time analysis, queue lengths, heat maps, and space utilization. Generate reports to make data-driven decisions and ensure efficient operations and staffing.",
          linkText: "Learn About Lumeo integration",
          linkUrl: "/blog/integration-lumeo",
        },
        {
          image: data.learnImage4,
          title:
            "Monitor for Persons and Vehicles of Interest and Improve Efficiency",
          description:
            "Proactively protect your spaces by flagging suspicious people or vehicles and receive alerts if repeat offenders appear on any camera. Increase efficiency by monitoring drive-thrus and improving line busting with real-time data.",
          linkText: "Learn About AI Analytics",
          linkUrl: "/ai-analytics/",
        },
        {
          image: data.learnImage5,
          title:
            "Save Money and Manage Food Safety at Scale with Smart IoT Sensors",
          description:
            "Identify temperature and humidity anomalies in real time and fix the issue before spoilage occurs. Reduce food waste, proactively identify failing equipment, and detect inefficiencies in energy usage to reduce costs.",
          linkText: "Learn About Smart Sensors",
          linkUrl: "/sensors",
        },
      ],
      industryUseCases: [
        {
          title: "Rhombus in Quick Service Restaurants",
          tabTitle: "Quick Service Restaurants ",

          description:
            "From operational insights to monitoring for theft, Rhombus helps QSRs enhance security and increase efficiency.",
          image: data.useCase1,
          mobileImage: data.useCaseMobile1,
        },
        {
          title: "Rhombus in Chains & Franchises",
          tabTitle: "Chains & Franchises",

          description:
            "Save time and standardize. Whether it’s one or one thousand locations, view and manage them seamlessly from a single pane of glass.",
          image: data.useCase2,
          mobileImage: data.useCaseMobile2,
        },
        {
          title: "Rhombus in Industrial Kitchens",
          tabTitle: "Industrial Kitchens",
          description:
            "Get the full picture of what’s happening in the kitchen. Improve efficiency, reduce shrinkage, and monitor food safety at scale.",
          image: data.useCase3,
          mobileImage: data.useCaseMobile3,
        },
        {
          title: "Rhombus in Bars & Cafes",
          tabTitle: "Bars & Cafes",

          description:
            "Improve safety by screening for unusual behavior or persons of interest. Increase efficiency with occupancy trends and more.",
          image: data.useCase4,
          mobileImage: data.useCaseMobile4,
        },
      ],
      form: {
        title: "Request Demo",
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        paragraph:
          "Contact our team today and see why Rhombus is the leading physical security solution for businesses of all sizes.",
        caseStudy: {
          title: "Rhombus is Trusted by Food & Beverage Leaders",
          image: data.qouteImage,
          logo: {
            img: data.logo2,
            width: "122px",
            alt: "Blake's Lotaburger",
          },
          link: "/case-studies/blakes-lotaburger/",
          author: {
            avatar: data.avatar,
            name: "Roger Hart-York",
            role: "IT Operations Manager at Blake’s Lotaburger",
            qoute:
              "“Whether it's spending extra time answering a question, placing a special order, or proactively reaching out to check in, <strong>the Rhombus team is always there for us.</strong>”",
          },
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Video Security System for Restaurants | Cloud Physical Security |
            Rhombus
          </title>
          <meta
            name="description"
            content="Discover how Rhombus security cameras, environmental sensors, access control, and alarms can streamline video surveillance and operations for restaurants and foodservice businesses. Start a free trial today!"
          />
        </Helmet>

        <IndustryPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/food-and-bev-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/food-and-bev-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: {
          eq: "components/industries/img/food-and-beverage/logo1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/food-and-beverage/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/food-and-beverage/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/food-and-beverage/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: {
          eq: "components/industries/img/food-and-beverage/logo5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/food-and-beverage/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/dollar.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/insight.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/shield.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: {
          eq: "components/industries/img/icons/investigations.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage1: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage2: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/point-of-sale-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage3: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/space-and-occupancy-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage4: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/lpr-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage5: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/environmental-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase1: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/qsr-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase2: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/chains-and-franchise-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase3: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/industrial-kitchen-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase4: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/bars-and-cafe-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile1: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/mobile-qsr-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile2: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/mobile-chains-and-franchises-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile3: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/mobile-industrial-kitchen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile4: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/mobile-bars-cafes-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      qouteImage: file(
        relativePath: {
          eq: "components/industries/updated/img/food-and-beverage/blakes-lotaburger-qoute-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/roger-hart-york.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
