export const PATHS = {
  PRODUCTS: {
    ACCESS_CONTROL: {
      OVERVIEW: "/access-control/",
      DOOR_READERS: {
        OVERVIEW: "/access-control/door-readers/",
      },
      DOOR_CONTROLLER: "/access-control/dc20/",
      SECURE_CARDS: "/access-control/secure-cards/",
      SECURE_FOBS: "/access-control/secure-fobs/",
    },
    ALARM_MONITORING: "/alarm-monitoring/",
    CAMERAS: {
      OVERVIEW: "/cameras/",
      DOME: { OVERVIEW: "/cameras/dome-cameras/" },
      FISHEYE: { OVERVIEW: "/cameras/fisheye-cameras/" },
      BULLET: { OVERVIEW: "/cameras/bullet-cameras/" },
      MULTISENSOR: { OVERVIEW: "/cameras/multisensor-cameras/" },
      COMPARE: "/cameras/compare-cameras/",
    },
    SENSORS: {
      OVERVIEW: "/sensors/",
      AUDIO_GATEWAY: "/sensors/a100",
      ENVIRONMENTAL: "/sensors/environmental-monitoring",
      MOTION: "/sensors/m15",
      DOOR: "/sensors/d20",
      BUTTON: "/sensors/b10",
    },
  },
  WHY_RHOMBUS: {
    PRICING: "/pricing/",
    CASE_STUDIES: "/case-studies/",
    TRUST: "/trust/",
    INTEGRATIONS: "/integrations/",
    BLOG: "/blog/",
  },
  COMPETITION: {
    VERKADA: "/compare/verkada/",
    AVIGILON: "/compare/avigilon/",
    MERAKI: "/compare/meraki/",
  },
  INDUSTRIES: {
    EDUCATION: "/industries/education/",
    FOOD_AND_BEV: "/industries/food-and-beverage/",
    GOV: "/industries/government/",
    HEALTHCARE: "/industries/healthcare/",
    MANUFACTURING: "/industries/manufacturing/",
    RETAIL: "/industries/retail/",
    STORAGE: "/industries/storage-and-warehouse/",
  },
  INSIGHTS: {
    WHAT_IS_BUSINESS_VIDEO_SURVEILLANCE:
      "/blog/essential-features-business-video-surveillance-systems/",
    WHAT_IS_CLOUD_VIDEO_SURVEILLANCE: "/blog/cloud-based-video-surveillance/",
  },
};
