import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";

import React, { useMemo } from "react";
import {
  WebinarHeaderContainer,
  WebinarHeaderImageWrapper,
  WebinarHeaderInner,
  WebinarHeaderSectionLeft,
  WebinarHeaderSectionRight,
  WebinarHeaderTextContainer,
} from "./styles/webinarStyles";
import useIsMobile from "hooks/useIsMobile";
import BackLink from "components/common/BackLink";
import DateRow from "./DateRow";
import { getType, WEBINAR_TYPES } from "../util/webinarsFunctions";
import { HeroTitle, PreTitle } from "components/rui/typography";

const WebinarHeader = ({
  type,
  title,
  date,
  formId,
  image,
  centerImage = false,
  url,
}) => {
  const { isMobile } = useIsMobile(820);

  const _type = getType(type, date);

  const formTitle = useMemo(() => {
    return _type === WEBINAR_TYPES.live ? "Register Now" : "Watch the Webinar";
  }, [_type]);

  const submitButtonText = useMemo(() => {
    return _type === WEBINAR_TYPES.live ? "Reserve Your Spot" : "Watch Now";
  }, [_type]);

  const redirectLink = useMemo(() => {
    return _type === WEBINAR_TYPES.OnDemand
      ? `/thank-you/on-demand-video/${url}`
      : "";
  }, [_type]);

  return (
    <WebinarHeaderContainer centerImage={centerImage}>
      <WebinarHeaderInner>
        <WebinarHeaderSectionLeft>
          <WebinarHeaderTextContainer>
            <BackLink text={"Browse Webinars"} path={"/webinars/"} />
            <PreTitle color="var(--primary-bg)">{_type}</PreTitle>
            <HeroTitle color="var(--primary-bg)">{title}</HeroTitle>
            <DateRow date={date} type={_type} />
          </WebinarHeaderTextContainer>
          <WebinarHeaderImageWrapper centerImage={centerImage}>
            <img src={image} alt={title} />
          </WebinarHeaderImageWrapper>
        </WebinarHeaderSectionLeft>
        <WebinarHeaderSectionRight>
          <FormWrapper width={isMobile ? "100%" : "550px"} title={formTitle}>
            <RhombusForm
              formId={formId}
              customRedirect={redirectLink}
              customSubmitText={submitButtonText}
            />
          </FormWrapper>
        </WebinarHeaderSectionRight>
      </WebinarHeaderInner>
    </WebinarHeaderContainer>
  );
};

export default WebinarHeader;
