import React from "react";
import { css, cx } from "@linaria/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RhombusButton from "components/common/RhombusButton";
import PageContent from "components/page/PageContent";
import SlideInContainer from "components/common/SlideInContainer";
import { isArray } from "lodash";
import { Body, TitleMed } from "components/rui/typography";
import { FlexColumn, FlexRow, FlexRowApart } from "components/rui/layout";
import { styled } from "@linaria/react";

const Section = styled.section`
  background-color: ${(props) => props.bgColor};
`;

const contentContainter = css`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    padding: 35px 0px;
  }
`;

const titleClass = css`
  max-width: 820px;
  gap: 20px;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const HeaderContainer = styled(FlexRowApart)`
  margin-bottom: 75px;
  gap: 20px;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }
`;

const columnsContainer = css`
  display: flex;
  justify-content: space-between;
  gap: 25px;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    gap: unset;
  }
`;

const columnStyle = css`
  width: 33%;
  max-width: 512.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  > div {
    width: 100%;
    margin: 0 auto;
    @media (max-width: 700px) {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    width: 100%;
    align-items: unset;
  }
`;

const textContainer = css`
  margin-bottom: 25px;
  li {
    font-size: 18px;
  }
  @media (max-width: 700px) {
    li {
      font-size: 16px;
    }
    margin-bottom: 20px;
  }
`;

const boldText = css`
  font-weight: bold;
  font-size: 18px;
`;

const descriptionText = css`
  line-height: 2;
  font-size: 18px;
  font-weight: 300;
  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

const subtleText = css`
  * {
    color: var(--body-subtle);
    font-size: 10px;
    font-style: italic;
    line-height: normal;
  }

  margin-bottom: 10px;
`;

const dimensionsContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 700px) {
    margin: 0 auto 39.32px auto;
    align-items: center;
  }
`;

const imageStyle = css`
  max-width: 355px;
  pointer-events: none;
  margin-bottom: 45.4px;
  @media (max-width: 700px) {
    max-width: 272.48px;
    margin-bottom: unset;
  }
`;

const buttonContainerClass = css`
  justify-self: flex-end;
  align-self: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

const column1 = cx(
  columnStyle,
  css`
    @media (max-width: 700px) {
      order: 2;
    }
  `
);
const fullSize = cx(
  columnStyle,
  css`
    width: 512.5px;
    @media (max-width: 700px) {
      width: 100%;
    }
  `
);

const column2 = cx(
  columnStyle,
  css`
    @media (max-width: 700px) {
      order: 3;
    }
  `
);
const column3 = cx(
  columnStyle,
  css`
    max-width: unset;
    align-items: flex-end;
    @media (max-width: 700px) {
      order: 1;
      align-items: center;
    }
  `
);

export default function CameraSpecs({
  title,
  subTitle,
  specs,
  dims,
  pdf,
  dimsAlt,
  bgColor = "var(--white)",
}) {
  const imageRef = getImage(dims);
  const hasDescription = Boolean(specs?.renderInlineDescription);
  const renderDimsWithHeader = specs?.renderDimsWithHeader;

  const renderColumn = (items) =>
    items.map((item) => (
      <SlideInContainer key={item.title}>
        <div className={textContainer}>
          <div className={boldText}>{item.title}</div>
          {isArray(item.text) ? (
            item.text.map((line, idx) => (
              <div
                key={idx}
                className={item.subtle ? subtleText : descriptionText}
              >
                {item.bullets ? <li>{line}</li> : <Body>{line}</Body>}
              </div>
            ))
          ) : (
            <div className={item.subtle ? subtleText : descriptionText}>
              {item.text}
            </div>
          )}
        </div>
      </SlideInContainer>
    ));

  return (
    <Section bgColor={bgColor}>
      <PageContent>
        <div className={contentContainter}>
          <HeaderContainer>
            <FlexColumn className={titleClass}>
              <TitleMed>{title}</TitleMed>
              <>{subTitle}</>
            </FlexColumn>
            {renderDimsWithHeader && dims && (
              <div className={dimensionsContainer} style={{ flexShrink: 0 }}>
                <GatsbyImage
                  image={imageRef}
                  alt={dimsAlt ?? `${specs.model} camera with dimensions`}
                  className={imageStyle}
                  objectFit="contain"
                  objectPosition={"center"}
                />
                <div className={buttonContainerClass}>
                  <RhombusButton
                    title="Download Specs"
                    path={pdf}
                    type="primary-dark"
                    target="_blank"
                    useHref={true}
                  />
                </div>
              </div>
            )}
          </HeaderContainer>
          <div className={columnsContainer}>
            {specs?.renderInlineDescription}
            {specs && (
              <>
                <div className={hasDescription ? fullSize : column1}>
                  {renderColumn(specs.column1 || [])}
                </div>
                {specs?.column2 && (
                  <div className={column2}>{renderColumn(specs.column2)}</div>
                )}
              </>
            )}
            {dims && (
              <div className={column3}>
                {/* logic goes here so we still render col3 */}
                {!renderDimsWithHeader && (
                  <SlideInContainer>
                    <div className={dimensionsContainer}>
                      <GatsbyImage
                        image={imageRef}
                        alt={dimsAlt ?? `${specs.model} camera with dimensions`}
                        className={imageStyle}
                        objectFit="contain"
                        objectPosition={"center"}
                      />
                      <div className={buttonContainerClass}>
                        <RhombusButton
                          title="Download Specs"
                          path={pdf}
                          type="primary-dark"
                          target="_blank"
                          useHref={true}
                        />
                      </div>
                    </div>
                  </SlideInContainer>
                )}
              </div>
            )}
          </div>
        </div>
      </PageContent>
    </Section>
  );
}
