import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@linaria/react";
import { TitleSmall } from "components/rhombus-UI/theme/typography";
import Check from "components/common/icon-components/Check";
import LicensePicker from "./LicensePicker";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { graphql, Link, StaticQuery } from "gatsby";
import { ButtonBase } from "components/rhombus-UI/theme/buttons";
import ArrowUp from "components/common/icon-components/ArrowUp";
import { toTitleCase } from "components/common/util/helperFunctions";
import useStickyElementAtTop from "hooks/useStickyElementAtTop";

const ViewerContainer = styled(SectionInner)`
  flex-direction: column;
  align-items: center;
  gap: 0;
  margin-bottom: -2.5rem;
`;

const StickySection = styled.div`
  background: var(--white);
  z-index: 2;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  padding-top: 1.25rem;
  max-width: 100%;
`;

const BackToTopButton = styled.div`
  background: var(--nuetral-100);
  border-radius: 2rem;
  padding: 0.5rem 1.25rem;
  font-weight: 800;
  position: sticky;
  bottom: 2.5rem;
  box-shadow: 1px 1px 5px #03193320;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin-top: 2.5rem;
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  transition: all 250ms ease-in;
  z-index: 3;
  :hover {
    box-shadow: 1px 10px 15px #03193320;
    transform: translateY(-0.25rem);
  }
  :hover .card-image {
    transform: scale(1.1);
  }
  @media (max-width: 600px) {
    z-index: 1;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  margin-top: -2.75rem;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.625rem;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
`;

const TitleCell = styled.div`
  width: 50%;
  font-size: 18px;
  @media (max-width: 600px) {
    max-width: 150px;
    font-size: 16px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  gap: 1.25rem;
`;

const InfoCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`;

const TableTitle = styled(TitleSmall)`
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const CategoryRow = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  @media (max-width: 600px) {
    max-width: 150px;
  }
`;

const TableRow = styled.div`
  display: flex;
  border-bottom: 2px solid var(--blue-100);
  padding: 1.25rem 0;
  width: 100%;
  justify-content: space-between;
`;

export default function LicenseTable({ selectedTab = "Camera" }) {
  const renderContent = (data) => {
    const { edges: _licenseTables } = data.allMarkdownRemark;

    const [activeTab, setActiveTab] = useState("Camera");

    const stickyRef = useRef(null);
    const stickyActive = useStickyElementAtTop(stickyRef);

    useEffect(() => {
      setActiveTab(selectedTab);
    }, []);

    const tabTitles = useMemo(() => {
      const tabs = _licenseTables
        .map((item) => item.node.frontmatter.tableTitle)
        .filter((item) => item !== selectedTab);
      return [selectedTab, ...tabs];
    }, [_licenseTables, selectedTab]);

    const currentTable = useMemo(() => {
      const activeTable = _licenseTables.filter(
        (item) => item.node.frontmatter.tableTitle === activeTab
      );
      const sections = activeTable.map(
        (item) => item.node.frontmatter.tableSection
      );

      return sections.flat();
    }, [activeTab, _licenseTables]);

    return (
      <SectionContainer
        style={{ overflow: "visible", position: "relative", paddingTop: "0" }}
        id="top"
      >
        <ViewerContainer>
          <LicensePicker
            tabTitles={tabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <StickySection ref={stickyRef}>
            <TableHeader>
              <TitleCell>
                {stickyActive ? (
                  <ButtonBase as={Link} to="#top">
                    {activeTab}
                  </ButtonBase>
                ) : (
                  <TableTitle color="var(--blue-500)">
                    {toTitleCase(currentTable[0].sectionTitle)}
                  </TableTitle>
                )}
              </TitleCell>
              <InfoRow>
                <InfoCell>
                  <TableTitle as={"h3"}>Professional</TableTitle>
                </InfoCell>
                <InfoCell eCell>
                  <TableTitle as={"h3"}>Enterprise</TableTitle>
                </InfoCell>
              </InfoRow>
            </TableHeader>
          </StickySection>
          <Table>
            {currentTable.map((tableSection, i) => {
              return (
                <TableSection key={i}>
                  <CategoryRow>
                    <TableTitle color="var(--blue-500)">
                      {toTitleCase(tableSection.sectionTitle)}
                    </TableTitle>
                  </CategoryRow>
                  {tableSection.tableRow.map((row) => (
                    <TableRow>
                      <TitleCell>{row.feature}</TitleCell>
                      <InfoRow>
                        <InfoCell>
                          {row.proffesional && (
                            <Check color="#78D414" height="12px" />
                          )}
                        </InfoCell>
                        <InfoCell>
                          {row.enterprise && (
                            <Check color="#78D414" height="12px" />
                          )}
                        </InfoCell>
                      </InfoRow>
                    </TableRow>
                  ))}
                </TableSection>
              );
            })}
          </Table>
          {stickyActive && (
            <BackToTopButton as={Link} to="#top">
              Back to Top <ArrowUp height="12" />
            </BackToTopButton>
          )}
        </ViewerContainer>
      </SectionContainer>
    );
  };

  const pageQuery = graphql`
    query {
      allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { tableTitle: ASC } }
        filter: { frontmatter: { templateKey: { eq: "license-table" } } }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              tableTitle
              tableSection {
                sectionTitle
                tableRow {
                  feature
                  proffesional
                  enterprise
                }
              }
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={pageQuery} render={renderContent} />;
}
