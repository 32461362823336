import React from "react";

const IconSearch = ({ size = "16", color = "var(--blue-500)" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7953 5.89767C10.7953 8.60258 8.60258 10.7953 5.89767 10.7953C3.19275 10.7953 1 8.60258 1 5.89767C1 3.19275 3.19275 1 5.89767 1C8.60258 1 10.7953 3.19275 10.7953 5.89767Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M9.25391 9.46254L14.9997 15.0375"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default IconSearch;
