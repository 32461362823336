import TrialBanner from "components/common/footer-banners/TrialBanner";
import IntegrationPage from "components/integrations/IntegrationPage";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const IntegrationsPageTemplate = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  return (
    <GlobalLayout>
      <Helmet>
        <title>{frontmatter.appName} | Integrations | Rhombus </title>
        <meta
          name="description"
          content={`Discover how Rhombus integrates with ${frontmatter.appName}`}
        />
        <meta
          name="thumbnail"
          content={
            frontmatter?.metaImage ??
            "https://rhombus.com/img/logo-w-background.png"
          }
        />
        <meta
          property="og:image"
          content={
            frontmatter?.metaImage ??
            "https://rhombus.com/img/logo-w-background.png"
          }
        />
        {frontmatter.draft && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <IntegrationPage
        appIcon={frontmatter.appIcon}
        appName={frontmatter.appName}
        companyName={frontmatter.companyName}
        shortDescription={frontmatter.shortDescription}
        appListing={frontmatter.appListing}
        categories={frontmatter.categories}
        worksWith={frontmatter.worksWith}
        longDescription={frontmatter.longDescription}
        benefits={frontmatter.benefits}
        resources={frontmatter.resources}
        tags={frontmatter.tags}
      />
      <TrialBanner />
    </GlobalLayout>
  );
};

export default IntegrationsPageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        metaImage
        appIcon
        appName
        companyName
        shortDescription
        longDescription
        draft
        appListing {
          appListingImage
          altText
        }
        categories
        tags
        worksWith
        benefits
        resources {
          resourceText
          resourceUrl
        }
      }
    }
  }
`;
