import React from "react";

const IconInteroperable = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M20.8633 10.6641H24.5292"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M26.2526 10.5C26.2526 11.1443 25.7303 11.6667 25.0859 11.6667C24.4416 11.6667 23.9193 11.1443 23.9193 10.5C23.9193 9.85567 24.4416 9.33333 25.0859 9.33333C25.7303 9.33333 26.2526 9.85567 26.2526 10.5Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M16.3346 22.75C16.3346 23.3943 15.8123 23.9167 15.168 23.9167C14.5236 23.9167 14.0013 23.3943 14.0013 22.75C14.0013 22.1057 14.5236 21.5833 15.168 21.5833C15.8123 21.5833 16.3346 22.1057 16.3346 22.75Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M9.33464 5.25C9.33464 5.89433 8.8123 6.41667 8.16797 6.41667C7.52364 6.41667 7.0013 5.89433 7.0013 5.25C7.0013 4.60567 7.52364 4.08333 8.16797 4.08333C8.8123 4.08333 9.33464 4.60567 9.33464 5.25Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M7.0026 16.334H4.08594"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M4.08464 16.334C4.08464 16.9783 3.5623 17.5007 2.91797 17.5007C2.27364 17.5007 1.7513 16.9783 1.7513 16.334C1.7513 15.6897 2.27364 15.1673 2.91797 15.1673C3.5623 15.1673 4.08464 15.6897 4.08464 16.334Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M19.4804 17.5H22.7526V22.75H16.3359"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M7.58333 9.91667H3.5L3.5 5.25H6.71432"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M14.2917 15.6641L8.19388 12.249L14.2917 8.83457L20.3895 12.249L14.2917 15.6641Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M20.9987 14.582L14.2904 18.082L7.58203 14.582"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default IconInteroperable;
