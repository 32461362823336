import React from "react";
import { css } from "@linaria/core";
import PageContent from "components/page/PageContent";
import { FancyPageTitle } from "components/rhombus-UI/theme/typography";
import { TextContainer } from "components/rhombus-UI/theme/containers";

const contentContainer = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0px;
  gap: 4rem;
  min-height: 690px;
  @media (max-width: 1050px) {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    padding: 55px 0px 35px 0px;
  }
`;

export default function OnDemandVideoHeader({ title, description, videoId }) {
  return (
    <section style={{ background: "var(--gradient-light)" }}>
      <PageContent>
        <div className={contentContainer}>
          <TextContainer>
            <FancyPageTitle>{title}</FancyPageTitle>
            {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
          </TextContainer>
          <div
            className="wistia_responsive_padding"
            style={{
              padding: "56.25% 0 0 0",
              position: "relative",
            }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
                padding: "1rem",
              }}
            >
              <span
                className={`wistia_embed wistia_async_${videoId} popover=true popoverAnimateThumbnail=true videoFoam=true`}
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                &nbsp;
              </span>
            </div>
          </div>
        </div>
      </PageContent>
    </section>
  );
}
