import { styled } from "@linaria/react";
import React from "react";
import {
  CTA_1,
  CTA_2,
  HeroButtonPrimary,
  HeroButtonSecondary,
} from "./SurveillanceHeroV3";
import Link from "components/page/headers/HeaderLink";
import { useSetAtom } from "jotai";
import { useGetQuoteTitleAtom } from "./SurveillancePageV4";

export const ButtonRow = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
  a {
    width: 220px !important;
  }
  @media (max-width: 600px) {
    a {
      width: calc(220px * 0.8) !important;
    }
  }
  @media (max-width: 360px) {
    a {
      width: calc(220px * 0.7) !important;
      font-size: 14px;
    }
  }
`;

const StickyBanner = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem 1.25rem;
  position: sticky;
  bottom: 0;
  z-index: 999;
  background: var(--white);
  box-shadow: 0 0 30px #0b0c0d50;
`;

const StickyButtonBanner = () => {
  const setUseGetQuoteTitle = useSetAtom(useGetQuoteTitleAtom);
  return (
    <StickyBanner>
      <ButtonRow>
        <HeroButtonPrimary
          as={Link}
          to={CTA_1.link}
          onClick={() => setUseGetQuoteTitle(true)}
        >
          {CTA_1.copy}
        </HeroButtonPrimary>
        <HeroButtonSecondary as={Link} to={CTA_2.link}>
          {CTA_2.copy}
        </HeroButtonSecondary>
      </ButtonRow>
    </StickyBanner>
  );
};

export default StickyButtonBanner;
