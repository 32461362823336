import { styled } from "@linaria/react";
import RhombusButton from "components/common/RhombusButton";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import React from "react";

const Hero = styled(SectionContainer)`
  background: var(--gradient-light);
  text-align: center;
  align-items: center;
  min-height: 650px;
`;

const HeroText = styled(TextContainer)`
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 0;
  h1 {
    max-width: 670px;
  }
  p {
    margin-top: 24px;
    margin-bottom: 32px;
    max-width: 700px;
  }
`;

const SimpleHero = ({ preTitle, title, blurb, buttonTitle, buttonPath }) => {
  return (
    <Hero>
      <SectionInner>
        <HeroText>
          <PreTitle>{preTitle}</PreTitle>
          <FancyPageTitle>{title}</FancyPageTitle>
          <MainParagraph>{blurb}</MainParagraph>
          <RhombusButton title={buttonTitle} path={buttonPath} useHref />
        </HeroText>
      </SectionInner>
    </Hero>
  );
};

export default SimpleHero;
