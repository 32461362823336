import React, { useMemo, useState } from "react";
import PageSection from "./PageSection";
import { styled } from "@linaria/react";
import { TitleMed } from "../typography";
import useIsMobile from "hooks/useIsMobile";
import SliderNavDots from "../slider-components/SliderNavDots";
import { useSwipeable } from "react-swipeable";
import { STANCE_VIDEO_URL } from "components/home-page/constants";

const DEFAULT_TABBER_CONTENT = [
  {
    tabTitle: "Rapid Search & Response",
    title: "From insights to actions, instantly ",
    bulletPoints: [
      "Visibility anytime, anywhere",
      "Automated workflows",
      "AI-powered search and review",
    ],
    videoUrl:
      "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/Gif+Rhombus+Rapid+Search+%26+Response+V2.webm",
  },
  {
    tabTitle: "Seamless Deployment",
    title: "Minimum burden, maximum coverage",
    bulletPoints: [
      "Quick PoE install",
      "Ultra-low latency and bandwidth",
      "Unlimited scalability",
    ],
    videoUrl:
      "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/Gif_Rhombus_Seamless+Deployment.webm",
  },
  {
    tabTitle: "Reliable & Secure",
    title: "Secured & ready, when it matters",
    bulletPoints: [
      "99% platform uptime",
      "Camera onboard storage",
      "Optimized for data security",
    ],
    videoUrl:
      "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/Gif+Rhombus+Reliable+%26+Secure_V2.webm",
  },
  {
    tabTitle: "Full Interoperability",
    title: "Future-proof operations, breakdown silos",
    bulletPoints: [
      "Integrate with existing hardware",
      "Scale with 50+ app integrations",
      "Evolve tech stack with 100% open API",
    ],
    videoUrl:
      "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/Gif+Rhombus+Full+Interoperability+V2.webm",
  },
];

const TabberContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--section-gap);
  width: 100%;
  align-items: center;
  @media (max-width: 900px) {
    gap: var(--section-gap-mobile);
  }
`;

const TabberBar = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--primary-bg);
  user-select: none;
  overflow: hidden;
  width: 100%;
  max-width: fit-content;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--headerFontFamily);
  text-align: center;
  padding: 12px 40px;
  cursor: pointer;
  transition: all 250ms;
  font-size: 20px;
  color: ${(props) => (props.active ? "var(--white)" : "var(--body-subtle)")};
  background-color: ${(props) =>
    props.active ? "var(--primary-bg)" : "unset"};
  :not(:last-of-type) {
    border-right: 1px solid var(--primary-bg);
  }
  :hover {
    background-color: var(--primary-bg);
    color: var(--white);
  }
  @media (max-width: 900px) {
    width: 50%;
    height: 60px;
    font-size: 16px;
    :nth-child(2) {
      border-right: none;
    }
    :nth-child(2),
    :first-of-type {
      border-bottom: 1px solid var(--primary-bg);
    }
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

const TabContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--section-gap-mobile);
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    gap: calc(var(--section-gap-mobile) / 2);
  }
`;
const TabContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--section-gap-mobile);
  width: 50%;
  ul {
    margin: 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: calc(var(--section-gap-mobile) / 2);
    li {
      font-size: 18px;
    }
    li::marker {
      color: var(--blue-500);
      font-size: 20px;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    gap: calc(var(--section-gap-mobile) / 2);
    ul {
      gap: calc(var(--section-gap-mobile) / 4);
    }
  }
`;

const TabContentRight = styled.div`
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 436px;
  max-width: 654px;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 900px) {
    height: 300px;
  }
`;

const TabberVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  @media (min-width: 1440px) {
    object-fit: cover;
  }
`;

/**
 *
 * Tabber component that supports 4 tabs, swipeable on mobile
 */
const TabberSection = ({ preTitle, title, blurb }) => {
  const { isMobile } = useIsMobile(900);
  const [currentIndex, setCurrentIndex] = useState(0);

  const tabs = useMemo(() => {
    return DEFAULT_TABBER_CONTENT.map((t) => t.tabTitle);
  }, [DEFAULT_TABBER_CONTENT]);

  const currentTabContent = useMemo(() => {
    return DEFAULT_TABBER_CONTENT[currentIndex];
  }, [currentIndex, DEFAULT_TABBER_CONTENT]);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    trackMouse: true,
  });

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % tabs.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? tabs.length - 1 : prevIndex - 1
    );
  };

  return (
    <PageSection preTitle={preTitle} title={title} blurb={blurb}>
      <TabberContainer>
        <TabberBar>
          {tabs.map((t, index) => (
            <Tab
              onClick={() => setCurrentIndex(index)}
              active={index === currentIndex}
            >
              {t}
            </Tab>
          ))}
        </TabberBar>
        <TabContentContainer {...handlers}>
          <TabContentLeft>
            <TitleMed>{currentTabContent.title}</TitleMed>
            <ul>
              {currentTabContent.bulletPoints.map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          </TabContentLeft>
          <TabContentRight>
            <VideoContainer>
              <TabberVideo
                src={currentTabContent.videoUrl}
                playsInline
                autoPlay
                loop
                muted
              />
            </VideoContainer>
          </TabContentRight>
        </TabContentContainer>
        {isMobile && (
          <SliderNavDots
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            slides={tabs}
          />
        )}
      </TabberContainer>
    </PageSection>
  );
};

export default TabberSection;
