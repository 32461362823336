import React from "react";
import { styled } from "@linaria/react";
import {
  faLinkedinIn,
  faYoutube,
  faFacebook,
  faInstagram,
  faFacebookF,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import { Link } from "gatsby";
import { COMPANY_PHONE_NUMBER, MARKETING_PHONE_NUMBER } from "./constants";
import { FlexColumn } from "components/rhombus-UI/theme/containers";

const Links = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

const linkStyle = css`
  height: 20px;
  aspect-ratio: 1;
  border-radius: 4px;
  padding: 5px;
  background: var(--white);
  transition: all 250ms;
  :hover {
    background: var(--primary);
  }

  @media (max-width: 375px) {
    height: 15px;
  }
`;

const SocialLinks = () => {
  const commonProps = {
    color: "var(--primary-bg)",
  };

  const links = [
    <FontAwesomeIcon
      icon={faFacebookSquare}
      {...commonProps}
      href="https://www.facebook.com/rhombus/"
      className={linkStyle}
    />,
    <FontAwesomeIcon
      icon={faInstagram}
      {...commonProps}
      href="https://www.instagram.com/rhombus_hq/"
      className={linkStyle}
    />,
    <FontAwesomeIcon
      icon={faLinkedinIn}
      {...commonProps}
      href="https://www.linkedin.com/company/rhombus-systems"
      className={linkStyle}
    />,
    <FontAwesomeIcon
      icon={faYoutube}
      {...commonProps}
      href="https://www.youtube.com/channel/UC1vFNrCK_4AkCWVcjVc9LTg"
      className={linkStyle}
    />,
  ];

  return (
    <Links>
      {links.map((component) => (
        <a href={component.props.href} target="_blank">
          {component}
        </a>
      ))}
    </Links>
  );
};

export default SocialLinks;
