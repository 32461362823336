import r170TinyRender from "./assets/r120-tiny.png";
import r120TinyRender from "./assets/r170-tiny.png";
import r230TinyRender from "./assets/r230-tiny.png";
import r360TinyRender from "./assets/r360-tiny.png";
import r400TinyRender from "./assets/r400-tiny.png";
import r500TinyRender from "./assets/r500-tiny.png";
import r120LargeRender from "./assets/r120-large.png";
import r170LargeRender from "./assets/r170-large.png";
import r230LargeRender from "./assets/r230-large.png";
import r360LargeRender from "./assets/r360-large.png";
import r400LargeRender from "./assets/r400-large.png";
import r500LargeRender from "./assets/r500-large.png";
import r520LargeRender from "./assets/r520-tiny-render.png";
import r520TinyRender from "./assets/r520-render.png";
import r600 from "./assets/R600.png";
import r600TinyRender from "./assets/r600-tiny.png";
import n100TinyRender from "./assets/n100-tiny-render.png";

export const CAMERA_SPECS = {
  useCase: "Use Case",
  dimensions: "Dimensions",
  connectivity: "Connectivity",
  imageSensor: "Image Sensor",
  lensAndZoom: "Lens and Zoom",
  focalLength: "Focal Length",
  fov: "Field of View",
  weatherResistance: "Weather Resistance",
  impactResistence: "Impact Resistance",
  nightVision: "Night Vision",
  storage: "Storage",
  ble: "Rhombus Sensor Network (BLE)",
};

export const CAMERAS = [
  {
    basePrice: 349,
    modelName: "R120 Mini",
    category: "Dome",
    renderSmall: r120TinyRender,
    renderLarge: r120LargeRender,
    useCase: { name: "Use Case", value: "Maximizing Camera Coverage" },
    dimensions: { name: "Dimensions", value: "118mm x 90mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "2MP" },
    lensAndZoom: { name: "Lens and Zoom", value: "Digital PTZ" },
    focalLength: { name: "Focal Length", value: "2.8mm" },
    fov: { name: "Field of View", value: "104° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "IK10" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (100ft in low light)",
    },
    storage: { name: "Storage", value: "128GB" },
    ble: { name: "Rhombus Sensor Network", value: true },
  },
  {
    basePrice: 425,
    modelName: "R170 Micro",
    category: "Dome",
    renderSmall: r170TinyRender,
    renderLarge: r170LargeRender,
    useCase: { name: "Use Case", value: "Discreet Visibility" },
    dimensions: { name: "Dimensions", value: "105mm x 52mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "5MP" },
    lensAndZoom: { name: "Lens and Zoom", value: "Digital PTZ" },
    focalLength: { name: "Focal Length", value: "2.8mm" },
    fov: { name: "Field of View", value: "108° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "" },
    impactResistence: { name: "Impact Resistance", value: "" },
    nightVision: { name: "Night Vision", value: "" },
    storage: { name: "Storage", value: "128GB, 256GB, 512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: false },
  },
  {
    basePrice: 499,
    modelName: "R200 Mini",
    category: "Dome",
    renderSmall: r120TinyRender,
    renderLarge: r120LargeRender,
    useCase: { name: "Use Case", value: "General Visibility" },
    dimensions: { name: "Dimensions", value: "118mm x 90mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "5MP" },
    lensAndZoom: { name: "Lens and Zoom", value: "Digital PTZ" },
    focalLength: { name: "Focal Length", value: "2.8mm" },
    fov: { name: "Field of View", value: "104° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "IK10" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (100ft in low light)",
    },
    storage: { name: "Storage", value: "128GB, 256GB, 512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: true },
  },
  {
    basePrice: 899,
    modelName: "R230 WiFi",
    category: "Dome",
    renderSmall: r230TinyRender,
    renderLarge: r230LargeRender,
    useCase: { name: "Use Case", value: "Flexible Deployment" },
    dimensions: { name: "Dimensions", value: "117mm x 83.5mm" },
    connectivity: { name: "Connectivity", value: "WiFi or Ethernet" },
    imageSensor: { name: "Image Sensor", value: "5MP" },
    lensAndZoom: { name: "Lens and Zoom", value: "2.8x optical zoom" },
    focalLength: { name: "Focal Length", value: "2.8 - 8mm" },
    fov: { name: "Field of View", value: "53.1°-110.1° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "IK10" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (100ft in low light)",
    },
    storage: { name: "Storage", value: "128GB, 256GB, 512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: true },
  },
  {
    basePrice: 1299,
    modelName: "R400 Dome",
    category: "Dome",
    renderSmall: r400TinyRender,
    renderLarge: r400LargeRender,
    useCase: { name: "Use Case", value: "Entryways" },
    dimensions: { name: "Dimensions", value: "134mm x 118mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "8.3MP 4K" },
    lensAndZoom: { name: "Lens and Zoom", value: "2.5x optical zoom" },
    focalLength: { name: "Focal Length", value: "3.6 - 11mm" },
    fov: { name: "Field of View", value: "94° - 39° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "IK10" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (130ft in low light)",
    },
    storage: { name: "Storage", value: "512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: false },
  },
  {
    basePrice: 1299,
    modelName: "R360 Fisheye",
    category: "Fisheye",
    renderSmall: r360TinyRender,
    renderLarge: r360LargeRender,
    useCase: { name: "Use Case", value: "Large Open Areas" },
    dimensions: { name: "Dimensions", value: "130mm x 58mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "12MP" },
    lensAndZoom: { name: "Lens and Zoom", value: "Digital PTZ" },
    focalLength: { name: "Focal Length", value: "1.65mm" },
    fov: { name: "Field of View", value: "180° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "IK10" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (16ft in low light)",
    },
    storage: { name: "Storage", value: "512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: false },
  },
  {
    basePrice: 1499,
    modelName: "R500 Telephoto",
    category: "Bullet",
    renderSmall: r500TinyRender,
    renderLarge: r500LargeRender,
    useCase: { name: "Use Case", value: "Parking Lots" },
    dimensions: { name: "Dimensions", value: "146mm x 225mm x 92mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "8.3MP 4K" },
    lensAndZoom: {
      name: "Lens and Zoom",
      value: "2.5x optical zoom & motor autofocus",
    },
    focalLength: { name: "Focal Length", value: "11 - 28mm" },
    fov: { name: "Field of View", value: "39° - 16° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (130ft in low light)",
    },
    storage: { name: "Storage", value: "512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: false },
  },
  {
    basePrice: 1499,
    modelName: "R510 Standard Zoom",
    category: "Bullet",
    renderSmall: r500TinyRender,
    renderLarge: r500LargeRender,
    useCase: { name: "Use Case", value: "Building Perimeter" },
    dimensions: { name: "Dimensions", value: "146mm x 225mm x 92mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "8.3MP 4K" },
    lensAndZoom: {
      name: "Lens and Zoom",
      value: "2.5x optical zoom & motor autofocus",
    },
    focalLength: { name: "Focal Length", value: "2.6 - 11mm" },
    fov: { name: "Field of View", value: "94° - 39° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (130ft in low light)",
    },
    storage: { name: "Storage", value: "512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: false },
  },
  {
    basePrice: 899,
    modelName: "R520 Mini",
    category: "Bullet",
    renderSmall: r520LargeRender,
    renderLarge: r520TinyRender,
    useCase: { name: "Use Case", value: "General Visibility" },
    dimensions: { name: "Dimensions", value: "85mm x 85mm x 214.5mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "5MP" },
    lensAndZoom: {
      name: "Lens and Zoom",
      value: "3x optical zoom",
    },
    focalLength: { name: "Focal Length", value: "3.3 - 9.7mm" },
    fov: { name: "Field of View", value: "97° - 27° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "IK10" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (98ft in low light)",
    },
    storage: { name: "Storage", value: "128GB, 256GB, 512GB, 1TB" },
    ble: { name: "Rhombus Sensor Network", value: true },
  },
  {
    basePrice: 3099,
    modelName: "R600 Multisensor",
    category: "Multisensor",
    renderSmall: r600TinyRender,
    renderLarge: r600,
    useCase: { name: "Use Case", value: "Comprehensive Coverage" },
    dimensions: { name: "Dimensions", value: "275mm x 118 mm" },
    connectivity: { name: "Connectivity", value: "Ethernet" },
    imageSensor: { name: "Image Sensor", value: "Four 5MP sensors" },
    lensAndZoom: {
      name: "Lens and Zoom",
      value: "Digital PTZ",
    },
    focalLength: { name: "Focal Length", value: "3.7mm - 7.7mm " },
    fov: { name: "Field of View", value: "94 - 43° HFoV" },
    weatherResistance: { name: "Weather Resistance", value: "IP66" },
    impactResistence: { name: "Impact Resistance", value: "IK10" },
    nightVision: {
      name: "Night Vision",
      value: "Infrared illuminators (100ft in low light)",
    },
    storage: { name: "Storage", value: "1TB, 2TB" },
    ble: { name: "Rhombus Sensor Network", value: true },
  },
  {
    basePrice: null,
    modelName: "N100 Relay Core",
    category: "Relay Connector",
    renderSmall: n100TinyRender,
    renderLarge: null,
    disableCompare: true,
  },
];
