import CTAPage2 from "components/landing-pages/CTA/CTAPage2";
import React, { useEffect, useState } from "react";
import image from "components/landing-pages/CTA/assets/r600/r600-bg-image.png";
import useIsMobile from "hooks/useIsMobile";
import { DKI_SURV_E } from "components/data/landing-page-data";

export default function CTAPage5() {
  const [headline, setHeadline] = useState("");
  const { isMobile: isLaptop } = useIsMobile(1225);
  const { isMobile: isTablet } = useIsMobile(1024);

  useEffect(() => {
    const defaultHeadline =
      "Take the next step— Start a risk-free trial with Rhombus' Cameras. Install and start viewing footage from any device in seconds";
    const keyWord = localStorage.getItem("utm_keyword");
    setHeadline(DKI_SURV_E[keyWord] ?? defaultHeadline);
  }, []);

  return (
    <CTAPage2
      bgImage={image}
      bgImageMobile={image}
      title={"Try Rhombus in a Risk Free Trial"}
      lastStepTitle={"See the R600 in Action"}
      subTitle={headline}
      formId={"3b3e5ed2-8bae-4d5c-bd2e-932dc2ca23b1"}
      mobileFormPadding="17.5rem"
      bgImageRightPos="-15rem"
      bgImageBottomPos={isLaptop ? "150px" : isTablet ? "200px" : "50px"}
      backLink="/lp/surveillance-e/"
      useCenterMobileImage
    />
  );
}
