import { toTitleCase } from "components/common/util/helperFunctions";
import { styled } from "@linaria/react";
import React from "react";
import { TitleMed } from "components/rui/typography";

const FormWrapperContainer = styled.div`
  position: ${(props) => (props.sticky ? "sticky" : "unset")};
  top: 7.5rem;
  scroll-margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: ${(props) => props.width};
  max-width: 100%;
  border-radius: 12px;
  padding: ${(props) => (props.darkMode ? 0 : "1.75rem")};
  background: ${(props) => (props.darkMode ? "transparent" : "var(--white)")};
  z-index: 1;
  box-shadow: 1px 1px 15px #03193320;

  > h3,
  > p {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 25px;
  }

  @media (max-width: 600px) {
    width: 100%;
    border-radius: ${(props) =>
      props.outline ? "1.25rem" : props.darkMode ? "0.5rem" : "unset"};
    background: ${(props) => (props.darkMode ? "transparent" : "var(--white)")};
    padding: ${(props) => (props.darkMode ? 0 : "1.75rem 1.25rem")};
  }
`;

export default function FormWrapper({
  children,
  width = "550px",
  outline = false,
  title,
  darkMode = false,
  sticky = false,
  style,
}) {
  return (
    <FormWrapperContainer
      outline={outline}
      width={width}
      id="form"
      darkMode={darkMode}
      sticky={sticky}
      style={style}
    >
      {title && (
        <TitleMed color={darkMode && "var(--white)"}>
          {toTitleCase(title)}
        </TitleMed>
      )}
      {children}
    </FormWrapperContainer>
  );
}
