import { PATHS } from "./routes";

const CAMERA_MENU = {
  title: "Security Cameras",
  links: [
    { to: PATHS.PRODUCTS.CAMERAS.OVERVIEW, title: "Overview" },
    { to: PATHS.PRODUCTS.CAMERAS.DOME.OVERVIEW, title: "Dome Cameras" },
    { to: PATHS.PRODUCTS.CAMERAS.FISHEYE.OVERVIEW, title: "Fisheye Cameras" },
    { to: PATHS.PRODUCTS.CAMERAS.BULLET.OVERVIEW, title: "Bullet Cameras" },
    {
      to: PATHS.PRODUCTS.CAMERAS.MULTISENSOR.OVERVIEW,
      title: "Multisensor Cameras",
    },
    { to: PATHS.PRODUCTS.CAMERAS.COMPARE, title: "Compare Cameras" },
  ],
};

const SENSOR_MENU = {
  title: "IoT Sensors",
  links: [
    { to: PATHS.PRODUCTS.SENSORS.OVERVIEW, title: "Overview" },
    { to: PATHS.PRODUCTS.SENSORS.AUDIO_GATEWAY, title: "Audio Gateway" },
    {
      to: PATHS.PRODUCTS.SENSORS.ENVIRONMENTAL,
      title: "Environmental Sensors",
    },
    { to: PATHS.PRODUCTS.SENSORS.MOTION, title: "Motion Sensors" },
    { to: PATHS.PRODUCTS.SENSORS.DOOR, title: "Door Sensors" },
    { to: PATHS.PRODUCTS.SENSORS.BUTTON, title: "Button Sensors" },
  ],
};

const AC_MENU = {
  title: "Access Control",
  links: [
    { to: PATHS.PRODUCTS.ACCESS_CONTROL.OVERVIEW, title: "Overview" },
    {
      to: PATHS.PRODUCTS.ACCESS_CONTROL.DOOR_READERS.OVERVIEW,
      title: "Door Readers",
    },
    {
      to: PATHS.PRODUCTS.ACCESS_CONTROL.DOOR_CONTROLLER,
      title: "Door Controller",
    },
    { to: PATHS.PRODUCTS.ACCESS_CONTROL.SECURE_CARDS, title: "Secure Cards" },
    { to: PATHS.PRODUCTS.ACCESS_CONTROL.SECURE_FOBS, title: "Secure Fobs" },
  ],
};

const ALM_MENU = {
  title: "Alarm Monitoring",
  links: [{ to: PATHS.PRODUCTS.ALARM_MONITORING, title: "Alarm Monitoring" }],
};

const WHY_RHOMBUS_MENU = {
  title: "Why Rhombus",
  links: [
    { to: PATHS.WHY_RHOMBUS.PRICING, title: "Pricing" },
    { to: PATHS.WHY_RHOMBUS.CASE_STUDIES, title: "Customer Stories" },
    { to: PATHS.WHY_RHOMBUS.TRUST, title: "Trust" },
    { to: PATHS.WHY_RHOMBUS.INTEGRATIONS, title: "Integrations" },
    { to: PATHS.WHY_RHOMBUS.BLOG, title: "Blog" },
  ],
};

const COMPETITION_MENU = {
  title: "Vs. Competitors",
  links: [
    { to: PATHS.COMPETITION.VERKADA, title: "Verkada" },
    { to: PATHS.COMPETITION.AVIGILON, title: "Avigilon" },
    { to: PATHS.COMPETITION.MERAKI, title: "Meraki" },
  ],
};

const INDUSTRIES_MENU = {
  title: "Industries",
  links: [
    { to: PATHS.INDUSTRIES.EDUCATION, title: "Education" },
    { to: PATHS.INDUSTRIES.FOOD_AND_BEV, title: "Food & Beverage" },
    { to: PATHS.INDUSTRIES.GOV, title: "Government" },
    { to: PATHS.INDUSTRIES.HEALTHCARE, title: "Healthcare" },
    { to: PATHS.INDUSTRIES.MANUFACTURING, title: "Manufacturing" },
    { to: PATHS.INDUSTRIES.RETAIL, title: "Retail" },
    { to: PATHS.INDUSTRIES.STORAGE, title: "Storage" },
  ],
};

const INSIGHTS_MENU = {
  title: "Insights",
  links: [
    {
      to: PATHS.INSIGHTS.WHAT_IS_BUSINESS_VIDEO_SURVEILLANCE,
      title: "What is Business Video Surveillance?",
    },
    {
      to: PATHS.INSIGHTS.WHAT_IS_CLOUD_VIDEO_SURVEILLANCE,
      title: "What is Cloud Video Surveillance?",
    },
  ],
};

export const SITE_MAP_MENUS = [
  CAMERA_MENU,
  SENSOR_MENU,
  AC_MENU,
  ALM_MENU,
  WHY_RHOMBUS_MENU,
  COMPETITION_MENU,
  INDUSTRIES_MENU,
  INSIGHTS_MENU,
];
