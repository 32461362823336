import G2BadegCarousel from "components/common/infinite-carousels/G2BadgeCarousel";
import CustomVideoPlayer from "components/common/video/CustomVideoPlayer";
import { ZoomInfoForm } from "components/form/zoomInfo/ZoomInfoForm";
import GlobalLayout from "components/page/GlobalLayout";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import { Helmet } from "react-helmet";

const PageContainer = styled.section`
  height: 100vh;
  min-height: 800px;
  display: flex;
  margin-top: -80px;
  overflow-x: hidden;
  justify-content: center;
  @media (max-width: 1024px) {
    height: fit-content;
    min-height: 100vh;
  }
`;

const PageColLeft = styled.div`
  width: 50%;
  background: var(--primary-bg);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10rem 2.5rem;
  padding-bottom: 5rem;
  gap: 5rem;
`;

const PageColRight = styled(PageColLeft)`
  background: var(--gradient-light);
  align-items: center;
  flex-grow: 1;
  @media (max-width: 1024px) {
    padding: 7.5rem 1.25rem;
    gap: 2.5rem;
    min-height: 100vh;
  }
`;

const ContentLeftContainer = styled(TextContainer)`
  color: var(--white);
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 1.5rem;
  p {
    max-width: 389px;
  }
  h2 {
    font-size: 20px;
  }
`;

const imageStyles = css`
  width: 100%;
  max-width: 413px;
  max-height: 226px;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    max-height: 175px;
  }
`;

const CTAPage = ({
  image,
  title,
  blurb,
  formId,
  formTitle,
  formBlurb,
  formLastStepTitle,
  formLastStepText,
  successTitle,
  successMessage,
  backLink,
  setOnFormCompleteBoolean = null,
  formCompleteBoolean,
  videoUrl = null,
}) => {
  const { isMobile } = useIsMobile(1024);
  const handleImageOrVideo = () => {
    if (!!videoUrl) {
      return (
        <CustomVideoPlayer
          videoUrl={videoUrl}
          disabled={!formCompleteBoolean}
        />
      );
    } else {
      return (
        <GatsbyImage
          image={getImage(image)}
          className={imageStyles}
          objectFit="contain"
        />
      );
    }
  };

  return (
    <GlobalLayout
      landingPage
      formOnly
      dark={!isMobile}
      color="transparent"
      logoLink={backLink}
      useFullWidth
      useIncreasedPadding
      useFullWidthLogo
      diableStickyHeader
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageContainer>
        {!isMobile && (
          <PageColLeft>
            <ContentLeftContainer>
              {handleImageOrVideo()}
              <TitleMed>{title}</TitleMed>
              <MainParagraph>{blurb}</MainParagraph>
            </ContentLeftContainer>
            <G2BadegCarousel />
          </PageColLeft>
        )}
        <PageColRight>
          {isMobile && handleImageOrVideo()}
          <ZoomInfoForm
            formId={formId}
            title={formTitle}
            lastStepTitle={formLastStepTitle}
            subTitle={formBlurb}
            successTitle={successTitle}
            successMessage={successMessage}
            lastStepText={formLastStepText}
            setOnFormCompleteBoolean={setOnFormCompleteBoolean}
            useButtonv2
          />
          {isMobile && <G2BadegCarousel dark />}
        </PageColRight>
      </PageContainer>
    </GlobalLayout>
  );
};

export default CTAPage;
