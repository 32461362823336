export const SUB_FOOTER_LINKS = [
  {
    title: "Privacy",
    path: "https://legal.rhombus.com/privacy/privacy-policy",
  },
  {
    title: "Cookie Preferences",
    path:
      typeof Osano !== "undefined"
        ? null
        : "https://legal.rhombus.com/privacy/cookie-preferences",
    onClick: () =>
      typeof Osano !== "undefined"
        ? Osano.cm.showDrawer("osano-cm-dom-info-dialog-open")
        : null,
  },
  {
    title: "Customer Terms & Conditions",
    path: "https://legal.rhombus.com/end-user-tos",
  },
  {
    title: "Reseller Terms & Conditions",
    path: "https://legal.rhombus.com/reseller/rhombus-systems-reseller-agreement",
  },
  {
    title: "State Licenses",
    path: "https://legal.rhombus.com/corporate/state-licenses",
  },
  { title: "Sitemap", path: "/sitemap/" },
];

const PRODUCT_LINKS = [
  { title: "Cameras", path: "/cameras/" },
  { title: "Sensors", path: "/sensors/" },
  { title: "Pricing", path: "/pricing/" },
  { title: "Instant Demo", path: "/instant-demo/" },
];

const COMPANY_LINKS = [
  { title: "About Us", path: "/about/" },
  { title: "Partners", path: "/partner-program/" },
  { title: "Blog", path: "/blog/" },
  {
    title: "Media Kit",
    path: "https://www.dropbox.com/sh/xqnmgjrtgk4s5kn/AADa9cNFuT9nrajqvXWhucqxa?dl=0",
  },
  { title: "Careers", path: "/careers/" },
  { title: "Contact", path: "/contact-sales/" },
];

const SUPPORT_LINKS = [
  { title: "Help", path: "https://support.rhombussystems.com/hc/en-us" },
  {
    title: "Getting Started",
    path: "https://support.rhombussystems.com/hc/en-us/articles/20933716115469-Getting-Started-with-Rhombus",
  },
  {
    title: "API Reference",
    path: "https://apidocs.rhombussystems.com/",
  },
];

export const FOOTER_MENUS = [
  { title: "PRODUCTS", links: PRODUCT_LINKS },
  { title: "COMPANY", links: COMPANY_LINKS },
  { title: "SUPPORT", links: SUPPORT_LINKS },
];

export const EXPERIMENTAL_FOOTER_MENUS = [
  {
    title: "LEARN MORE",
    links: [
      { title: "Pricing", path: "/lp/pricing/" },
      { title: "Contact", path: "/lp/cta-3/" },
    ],
  },
  {
    title: "PRODUCTS",
    links: [
      { title: "Cameras", path: "/lp/cameras-c/" },
      { title: "Access Control", path: "/lp/access-control-d/" },
    ],
  },
];

export const MARKETING_PHONE_NUMBER = {
  link: "tel:9169077404",
  title: "916.907.7404",
};

export const COMPANY_PHONE_NUMBER = {
  link: "tel:+1-877-746-6797",
  title: "+1 877.746.6797",
};
