import React from "react";
import { css } from "@linaria/core";
import { StaticImage } from "gatsby-plugin-image";

import G2SwiperCarousel from "./G2SwiperCarousel";
import G2Slide from "./G2Slide";
import TextLink from "components/common/TextLink";
import PageContent from "components/page/PageContent";
import { FancyTitleMed } from "components/rhombus-UI/theme/typography";
import TextLinkV2 from "components/common/TextLinkV2";
import { FlexRowCenterCenter } from "components/rui/layout";
import { TitleMed } from "components/rui/typography";

const sectionClass = css`
  padding: 108px 0px 83px;
  background: var(--nuetral-100);

  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 2px;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  @media (max-width: 700px) {
    padding: 60px 0px 25px;
  }
`;

export default function G2Carousel({ slides }) {
  return (
    <section className={sectionClass}>
      <PageContent>
        <TitleMed style={{ marginBottom: "5rem" }} center>
          See Why Industry Leaders Choose Rhombus
        </TitleMed>
        <G2SwiperCarousel>
          {slides.map((slide, i) => {
            return <G2Slide slide={slide} key={i} />;
          })}
        </G2SwiperCarousel>
        <FlexRowCenterCenter style={{ marginTop: "5rem" }}>
          <TextLinkV2
            path="https://www.g2.com/products/rhombus-systems/reviews/"
            target="_blank"
            title={"Read G2 Reviews"}
          />
        </FlexRowCenterCenter>
      </PageContent>
    </section>
  );
}
