import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { StaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { EventContainer } from "./styles/rctp-styles";
import TrainingEvent from "./TrainingEvent";
import { checkLastDate, compareStartDates } from "./util/rctp-functions";

export default function TrainingEventFeed() {
  const renderContent = (data) => {
    const { edges: _events } = data.allMarkdownRemark;
    const [events, setEvents] = useState(_events);

    useEffect(() => {
      const filteredEvents = _events
        .filter(checkLastDate)
        .sort(compareStartDates);
      setEvents(filteredEvents);
    }, []);

    return (
      <SectionContainer id="event-locations" style={{ overflow: "unset" }}>
        <SectionInner>
          <EventContainer>
            {events.map((event) => (
              <TrainingEvent eventData={event} />
            ))}
          </EventContainer>
        </SectionInner>
      </SectionContainer>
    );
  };

  const GET_TRAINING_SESSIONS = graphql`
    query {
      allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { date: ASC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "training-session" }
            draft: { ne: true }
          }
        }
      ) {
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              state
              image
              formId
              trainingEvents {
                trainingDates {
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    }
  `;

  return <StaticQuery query={GET_TRAINING_SESSIONS} render={renderContent} />;
}
