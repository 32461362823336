export const rhombusSpecs = {
  "Continuous high-resolution recording and storage": true,
  "At-rest bandwidth consumption": "10 - 30 Kbps",
  "HD WAN bandwidth consumption": "0.5 - 1.4 Mbps",
  "Video Latency": "0.2 - 0.5 secconds",
  "Native offline streaming": true,
  "Camera Pricing": "From $349",
  "License pricing": "From $149",
};

export const verkadaSpecs = {
  "Continuous high-resolution recording and storage": false,
  "At-rest bandwidth consumption": "20 - 50 Kbps",
  "HD WAN bandwidth consumption": "1 - 3 Mbps",
  "Video Latency": "Up to 12 seconds",
  "Native offline streaming": "Requires purchase",
  "Camera Pricing": "From $799",
  "License pricing": "From $199",
};

export const onPremSpecs = {
  "No NVR/DVRs, servers, or hard drives": false,
  "Secure remote access": false,
  "Straightforward deployment": false,
  "Unlimited scalability across multiple locations": false,
  "Intuitive software": false,
  "Automatic firmware updates": false,
  "New features at no cost": false,
  "Customizable user permissions": false,
};

export const cloudSpecs = {
  "No NVR/DVRs, servers, or hard drives": true,
  "Secure remote access": true,
  "Straightforward deployment": true,
  "Unlimited scalability across multiple locations": true,
  "Intuitive software": true,
  "Automatic firmware updates": true,
  "New features at no cost": true,
  "Customizable user permissions": true,
};
