import { getParam } from "components/form/util/functions";

export const getCurrentSlug = () => {
  const pathArray = window.location.pathname
    .split("/")
    .filter((item) => !!item);
  const slug = pathArray[pathArray.length - 1];
  return slug;
};

export const handleBlogPostUTMS = () => {
  const slug = getCurrentSlug();
  const medium = getParam("utm_medium") ?? "organic";
  localStorage.setItem(
    "UTM_VALUES",
    `?utm_content=${slug}&utm_medium=${medium}`
  );
};

export const handleBlogFreeTrialUTMS = () => {
  const slug = getCurrentSlug();
  const queryString = `?utm_source=blog&utm_medium=post&utm_campaign=${slug}&utm_content=free_trial_cta`;
  localStorage.setItem("UTM_VALUES", queryString);
};
