import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@linaria/react";

import RhombusButton from "components/common/RhombusButton";

import {
  MAX_SUBMISSIONS,
  getDate,
  getEventSubmissionCount,
  getTimeRange,
} from "./util/rctp-functions";
import { Tag } from "components/page/newHeader/styles/HeaderStyles";
import { Body, PreTitle, TitleSmall } from "components/rui/typography";

const EventSelectorContainer = styled.div`
  background: var(--nuetral-100);
  border: 1px solid var(--nuetral-200);
  padding: 1.25rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-width: 532px;
  width: 100%;
  flex-shrink: 0;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

const EventCard = styled.div`
  background: var(--white);
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid var(--nuetral-200);
  transition: all 250ms ease-in-out;

  .title-row {
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 18px;
    }
  }

  p {
    font-weight: 300;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li:not(:only-of-type):not(:last-of-type) {
      border-bottom: 1px solid var(--nuetral-200);
    }
    li:only-of-type,
    li:last-of-type {
      padding-bottom: 0;
    }
    li {
      padding: 0.75rem 0;
      p {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
`;

const EventSelector = ({ events, title, link, formId }) => {
  const [seatsLeft, setSeatsLeft] = useState(null);

  useEffect(() => {
    (async () => {
      const submissionCount = await getEventSubmissionCount(formId);
      if (!submissionCount) return;
      const count = MAX_SUBMISSIONS * events.length ?? 1 - submissionCount;
      setSeatsLeft(count);
    })();
  }, []);

  const isBooked = useMemo(() => {
    return seatsLeft === 0;
  }, [seatsLeft]);

  const isLimited = useMemo(() => {
    return seatsLeft <= 10 && seatsLeft > 0;
  }, [seatsLeft]);

  const renderTag = () => {
    return isBooked ? (
      <Tag color="var(--nuetral-300)">No Seats Left</Tag>
    ) : isLimited ? (
      <Tag>Limited Seats</Tag>
    ) : (
      <></>
    );
  };

  return (
    <EventSelectorContainer>
      <PreTitle color="var(--primary-bg)">{title}</PreTitle>
      {events.map((event) => {
        return (
          <EventCard>
            <div>
              <div className="title-row">
                <TitleSmall>{event.city}</TitleSmall>
                {renderTag()}
              </div>
              <Body>{event.address}</Body>
            </div>
            <ul>
              {event.trainingDates.map((timeSlot) => {
                return (
                  <li>
                    <p>
                      <b>{getDate(timeSlot.startDate)} </b>
                      <span>
                        {getTimeRange(timeSlot.startDate, timeSlot.endDate)}
                      </span>
                    </p>
                  </li>
                );
              })}
            </ul>
          </EventCard>
        );
      })}
      <RhombusButton
        type="primary-dark"
        title="Reserve Your Seat"
        path={link}
        useHref
        target="_blank"
        style={{ width: "100%" }}
        disabled={isBooked}
      />
    </EventSelectorContainer>
  );
};

export default EventSelector;
