import { css } from "@linaria/core";
import React from "react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import { PageTitle } from "components/rhombus-UI/theme/typography";
import RhombusButton from "components/common/RhombusButton";
import BackLink from "components/common/BackLink";
import {
  CTA_BUTTON_COPY_5,
  FREE_TRIAL_LINK,
  PRICING_COPY,
} from "components/data/landing-page-data";
import { Body } from "components/rui/typography";

const sectionClass = css`
  width: 100%;
  display: grid;
  margin-top: -80px;
  min-height: 740px;
  background-color: var(--primary-bg);
  @media (max-width: 820px) {
    min-height: 500px;
  }
`;

const contentWrapper = css`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  position: relative;
`;

const contentClass = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  color: var(--white);
  margin: 0 auto;
  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 820px) {
    justify-content: flex-end;
    padding-bottom: 80px;
  }

  p {
    max-width: 445px;
  }
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
`;

const linkContainer = css`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 2.5rem;
  @media (max-width: 400px) {
    flex-direction: column;
    a {
      width: 100%;
    }
  }
`;

const pageContentClass = css`
  height: 100%;
`;

export default function CameraDetailHeader({
  model,
  type,
  description,
  webHeader,
  mobileHeader,
  objectPosition,
  heroAlt,
  hidePricing,
  ctaCopy = CTA_BUTTON_COPY_5,
  pricingCopy = PRICING_COPY,
  ctaLink = FREE_TRIAL_LINK,
  pricingLink = null,
}) {
  const _pricingLink = pricingLink ?? `/pricing/#${type}`;
  const images = withArtDirection(getImage(webHeader), [
    {
      media: "(max-width: 820px)",
      image: getImage(mobileHeader),
    },
  ]);
  return (
    <section className={sectionClass}>
      <GatsbyImage
        image={images}
        alt={heroAlt ?? ""}
        style={{
          gridArea: "1/1",
          pointerEvents: "none",
          opacity: 0.6,
        }}
        loading="eager"
        objectPosition={objectPosition}
      />
      <div className={contentWrapper}>
        <PageContent contentClass={pageContentClass}>
          <div className={contentClass}>
            {type && type !== "Fisheye" && type !== "Multisensor" && (
              <BackLink
                text={`Browse All ${type} Cameras`}
                path={`/cameras/${type.toLowerCase()}-cameras/#cards`}
                light
              />
            )}
            <div className={headerContainer}>
              <PageTitle>{model}</PageTitle>
            </div>
            <Body light>{description}</Body>
            <div className={linkContainer}>
              <RhombusButton
                type="primary-light"
                title={ctaCopy}
                path={ctaLink}
              />
              {!hidePricing && (
                <RhombusButton
                  type="secondary-light"
                  title={pricingCopy}
                  path={_pricingLink}
                />
              )}
            </div>
          </div>
        </PageContent>
      </div>
    </section>
  );
}
