import TrialBanner from "components/common/footer-banners/TrialBanner";
import { graphql, StaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import { useSetAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";

import { categoriesAtom, currentIndexAtom, loadingAtom } from "./atoms/atoms";
import Categories from "./Categories";
import FormSection from "./FormSection";
import IntegrationFeed from "./IntegrationFeed";
import { BREAK_POINT, CHUNK_SIZE } from "./lib/constants";
import MarketPlaceHeader from "./MarketPlaceHeader";
import { MarketContainer, MarketInner } from "./styles/MarketPlace-css";
import {
  getAllCategoriesInUse,
  prefixDataWithFeaturedPosts,
} from "./util/functions";

export const MarketPlacePage = () => {
  const renderContent = (data) => {
    const { isMobile } = useIsMobile(BREAK_POINT);
    const { edges: _integrations } = data.allMarkdownRemark;

    const [constantData, setConstantData] = useState([]);
    const [mutableData, setMutableData] = useState([]);
    const [currentFilter, setCurrentFilter] = useState([]);

    const setLoading = useSetAtom(loadingAtom);
    const setCurrentIndex = useSetAtom(currentIndexAtom);
    const setCategoriesAtom = useSetAtom(categoriesAtom);

    useMemo(() => {
      if (!_integrations) setLoading(true);
      const data = prefixDataWithFeaturedPosts(_integrations) || _integrations;
      for (let i = 0; i < data.length; i += CHUNK_SIZE) {
        setMutableData((prev) => [...prev, data.slice(i, i + CHUNK_SIZE)]);
        setCurrentFilter((prev) => [...prev, data.slice(i, i + CHUNK_SIZE)]);
        setConstantData((prev) => [...prev, data.slice(i, i + CHUNK_SIZE)]);
      }
      setCategoriesAtom(getAllCategoriesInUse(_integrations));
      setLoading(false);
    }, []);

    useEffect(() => {
      setCurrentIndex(0);
      setMutableData(currentFilter);
    }, [currentFilter]);

    useEffect(() => {
      const target = document.getElementById("marketplace-body");
      setTimeout(() => {
        target.scrollIntoView({
          behavior: "smooth",
        });
      }, 1000);
    }, []);

    return (
      <>
        <MarketPlaceHeader />
        <MarketContainer id="marketplace-body">
          <MarketInner>
            {!isMobile && (
              <Categories
                data={constantData}
                setCurrentFilter={setCurrentFilter}
              />
            )}
            <IntegrationFeed
              data={mutableData}
              constantData={constantData}
              setCurrentFilter={setCurrentFilter}
              currentFilter={currentFilter}
              setMutableData={setMutableData}
            />
          </MarketInner>
        </MarketContainer>
        <FormSection formId="d436a52a-1f59-48cc-9116-15417435177f" />
        <TrialBanner />
      </>
    );
  };

  const query = graphql`
    query {
      allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { appName: ASC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "integrations-page" }
            tags: { nin: ["Hide"] }
          }
        }
      ) {
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              draft
              appIcon
              appName
              companyName
              shortDescription
              longDescription
              draft
              appListing {
                appListingImage
                altText
              }
              categories
              tags
              worksWith
              benefits
              resources {
                resourceText
                resourceUrl
              }
            }
          }
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
};

export default MarketPlacePage;
