import { css } from "@linaria/core";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import RhombusButton from "components/common/RhombusButton";
import {
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";
import { Body, HeroTitle } from "components/rui/typography";

const sectionClass = css`
  width: 100%;
  min-height: 90vh;
  margin-top: -80px;
  display: grid;
`;

const contentClass = css`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  position: relative;
`;

const textClass = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  min-height: 570px;
  max-height: calc(100vh - 90px);
  color: var(--white);
  @media (max-width: 700px) {
    align-items: center;
  }
  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  p {
    max-width: 445px;
  }
`;

const pageContentClass = css`
  height: 100%;
`;

export default function DoorReadersHeader() {
  const data = useStaticQuery(graphql`
    query {
      web: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/door-readers-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      mobile: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/door-readers-header-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const images = withArtDirection(getImage(data.web), [
    {
      media: "(max-width: 700px)",
      image: getImage(data.mobile),
    },
  ]);

  return (
    <section className={sectionClass}>
      <GatsbyImage
        image={images}
        alt="Rhombus DR20 and DR40 door readers showcasing sleek design and robust build. Advanced technology for managing visitors efficiently."
        style={{
          gridArea: "1/1",
          pointerEvents: "none",
        }}
        imgStyle={{ objectPosition: "left", filter: "brightness(0.7)" }}
        loading="eager"
      />
      <div className={contentClass}>
        <PageContent contentClass={pageContentClass}>
          <div className={textClass}>
            <TextContainer width="665px">
              <HeroTitle>
                Secure Building Entry with Modern Access Control Door Readers
              </HeroTitle>
              <Body light>
                Easily and securely access buildings by card, mobile app, or
                wave-to-unlock gestures with a suite of smart, touchless access
                control door card readers.
              </Body>
              <RhombusButton
                type="primary-light"
                title={CTA_BUTTON_COPY_3}
                path="/live-demo/"
                style={{ marginTop: "0.5rem" }}
              />
            </TextContainer>
          </div>
        </PageContent>
      </div>
    </section>
  );
}
