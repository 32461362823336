import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { Body, PreTitle, TitleMed } from "components/rui/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import RhombusButton from "../RhombusButton";

const CTASection = ({
  image,
  preTitle,
  title,
  blurb,
  buttonCopy,
  buttonLink,
  useHref,
}) => {
  return (
    <SectionContainer color="var(--tertiary-bg)">
      <SectionInner style={{ gap: "5rem" }}>
        <SectionCol className="col">
          <TextContainer>
            <PreTitle color="var(--primary-bg)">{preTitle}</PreTitle>
            <TitleMed>{title}</TitleMed>
            <Body>{blurb}</Body>
          </TextContainer>
          <RhombusButton
            useHref={useHref}
            target={useHref && "_blank"}
            title={buttonCopy}
            path={buttonLink}
            type="primary-dark"
          />
        </SectionCol>
        <SectionCol>
          <GatsbyImage image={getImage(image)} style={{ width: "100%" }} />
        </SectionCol>
      </SectionInner>
    </SectionContainer>
  );
};

export default CTASection;
