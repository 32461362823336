import IndustryPageUpdated from "components/industries/updated/IndustryPageUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function HealthcareIndustry() {
  const renderContent = (data) => {
    const pageData = {
      industry: "Healthcare",
      header: {
        headerTitle:
          "Protect Patients and Staff While Maintaining HIPAA Compliance",
        headerParagraph:
          "Safeguard healthcare environments with cutting-edge cloud-based cameras, smart sensors, access control systems, and state-of-the-art analytics, all while ensuring compliance with rigorous HIPAA regulations.",
        headerImg: data.headerImage,
        mobileHeaderImg: data.headerImageMobile,
      },
      industryTitle: "Trusted by Healthcare Leaders",
      industryLogos: [
        {
          img: data.logo1,
          width: "146.4px",
          alt: "Praesum Healthcare",
        },
        {
          img: data.logo2,
          width: "165.6px",
          alt: "Sono Bello",
        },
        {
          img: data.logo3,
          width: "141px",
          alt: "Rio Grande Hospital & Clinic",
        },
        {
          img: data.logo4,
          width: "165.6px",
          alt: "Alliance Healthcare Service",
        },
        {
          img: data.logo5,
          width: "181.2px",
          alt: "Planet Fitness",
        },
        {
          img: data.logo6,
          width: "164.4px",
          alt: "California Family Fitness",
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "camera",
          title: "Improve Patient Safety",
          p: "Obtain real-time visibility across facilities and receive proactive AI-powered alerts when harmful or potential emergencies occur.",
        },
        {
          icon: data.icon2,
          iconAlt: "lightening bolt",
          title: "Eliminate Operational Inefficiency",
          p: "No DVR/NVRs or complex configurations. Modern architecture enables simple deployment, no manual updates, and improved reliability.",
        },
        {
          icon: data.icon3,
          iconAlt: "shield",
          title: "Protect Privacy and Data",
          p: "Protect PHI/ePHI and stay HIPAA compliant with a security solution that features zero-trust system architecture and end-to-end encryption.",
        },
        {
          icon: data.icon4,
          iconAlt: "check mark",
          title: "Centralize Management",
          p: "Access your entire physical security system from a single pane of glass. Save time and simplify operations with enterprise cloud management.",
        },
      ],
      learnRows: [
        {
          image: data.learnImage1,
          title: "Enhance Patient and Staff Safety",
          description:
            "By strategically monitoring entrances, hallways, parking lots, and waiting rooms, healthcare facilities can swiftly detect security threats, deter crime, and gather crucial evidence for incident resolution and investigations.",
          linkText: "Learn About Cloud-Based Cameras",
          linkUrl: "/cameras",
        },
        {
          image: data.learnImage2,
          title: "Optimize Emergency Response Time for Critical Situations",
          description:
            "Access a range of solutions, including instant push notifications for unusual behavior, person-of-interest detection, panic buttons, and alarm monitoring. Verify threats and relay key details to emergency services efficiently. ",
          linkText: "Learn About Alarm Monitoring",
          linkUrl: "/alarm-monitoring/",
        },
        {
          image: data.learnImage3,
          title: "Gain Insight into Cameraless Areas with Smart Sensors",
          description:
            "Increase safety and security in spaces where cameras are not needed or permitted with the use of IoT smart sensors. Get alerts when motion is detected in restricted areas, a door has been accessed or left ajar, or unsafe air quality is detected.",
          linkText: "Learn About IoT Sensors",
          linkUrl: "/sensors",
        },
        {
          image: data.learnImage4,
          title: "Protect Patient Privacy and Data",
          description:
            "Ensure HIPAA compliance and safeguard PHI/ePHI with a secure solution. Zero-trust system architecture and end-to-end encryption protect data by default, and camera privacy regions aid compliance and preserve patient privacy.",
          linkText: "Learn About Compliance",
          linkUrl: "/compliance",
        },
        {
          image: data.learnImage5,
          title: "Stay in Control of Access Points",
          description:
            "Regulate entry and exit points and ensure only authorized personnel have access to restricted areas. Prevent potential security breaches and easily monitor and control who has access to sensitive information and valuable medical equipment.",
          linkText: "Learn About Access Control",
          linkUrl: "/access-control",
        },
      ],
      industryUseCases: [
        {
          title: "Rhombus in Clinics & Urgent Care",
          tabTitle: "Clinics & Urgent Care",

          description:
            "Create a safe clinic environment, protect patient privacy and PHI/ePHI, and save time with efficient, user-friendly technology.",
          image: data.useCase1,
          mobileImage: data.useCaseMobile1,
        },
        {
          title: "Rhombus in Hospitals",
          tabTitle: "Hospitals",

          description:
            "Improve staff and patient safety and care with proactive monitoring, swift threat response, and efficient security operations.",
          image: data.useCase2,
          mobileImage: data.useCaseMobile2,
        },
        {
          title: "Rhombus in Long-Term Care Facilities",
          tabTitle: "Long-Term Care Facilities",
          description:
            "Create a nurturing environment that delivers efficient care, protects staff and residents, and provides peace of mind to families.",
          image: data.useCase3,
          mobileImage: data.useCaseMobile3,
        },
        {
          title: "Rhombus in Insurance Offices",
          tabTitle: "Insurance Offices",

          description:
            "Enforce authorized access, protect sensitive customer information, and secure valuable assets while meeting HIPAA requirements.",
          image: data.useCase4,
          mobileImage: data.useCaseMobile4,
        },
      ],
      form: {
        title: "Request Demo",
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        paragraph:
          "Contact our team today and see why Rhombus is the leading physical security solution for businesses of all sizes.",
        caseStudy: {
          title: "Rhombus is the Trusted Solution by Healthcare Leaders",
          image: data.qouteImage,
          logo: {
            img: data.logo1,
            width: "122px",
            alt: "Praesum Healthcare",
          },
          link: "/case-studies/praesum-healthcare/",
          author: {
            avatar: data.avatar,
            name: "Daniel Ruiz",
            role: "IT Manager at Praesum Healthcare",
            qoute:
              "“What I love most about the system is how reliable it’s been. <strong>We just set it up and forget it.</strong> It’s that good.”",
          },
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Video Security System for Healthcare | Cloud Physical Security |
            Rhombus
          </title>
          <meta
            name="description"
            content="Safeguard healthcare and medical institutions with Rhombus smart security cameras, sensors, access control, and alarms. Improve video surveillance and safety, while ensuring HIPAA compliance. Start a free trial today!"
          />
        </Helmet>

        <IndustryPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/healthcare-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/healthcare-hero-image-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: { eq: "components/industries/img/healthcare/logo1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/industries/img/healthcare/logo2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/industries/img/healthcare/logo3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/industries/img/healthcare/logo4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/industries/img/healthcare/logo5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: { eq: "components/industries/img/healthcare/logo6.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/camera.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/shield.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage1: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage2: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/alarm-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage3: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/smart-sensors-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage4: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/privacy-screen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage5: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/access-points-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase1: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/clinics-urgent-care-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase2: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/hospitals-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase3: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/long-term-care-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase4: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/insurance-offices-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile1: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/mobile-clinics-and-urgent-care-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile2: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/mobile-hospitals-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile3: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/mobile-long-term-care-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile4: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/mobile-insurance-offices-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      qouteImage: file(
        relativePath: {
          eq: "components/industries/updated/img/healthcare/praesum-healthcare-qoute-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/daniel-ruiz-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
