import AccessControlSpecsPage from "components/access-control/specs/AccessControlSpecsPage";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, useStaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import Helmet from "react-helmet";

export default function DC20() {
  const data = useStaticQuery(graphql`
    query {
      dc20Header: file(
        relativePath: {
          eq: "components/access-control/specs/img/dc20-product-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dc20HeaderMobile: file(
        relativePath: {
          eq: "components/access-control/specs/img/dc20-product-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dc20Dims: file(
        relativePath: {
          eq: "components/access-control/specs/img/dc20-dimensions-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR20: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr20.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR40: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr40.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);

  const { isMobile } = useIsMobile(700);

  let exploreDR40Width = "51px";
  let exploreDR20Width = "48px";

  if (isMobile) {
    exploreDR40Width = "41px";
    exploreDR20Width = "38px";
  }

  const dc20 = {
    title: "DC20 Door Controller",
    model: "DC20",
    imageHeader: data.dc20Header,
    imageHeaderMobile: data.dc20HeaderMobile,
    heroAlt:
      "A rhombus DC20 access control device mounted on a wall, ready for continuous duty to monitor site events and manage entry.",
    objectPosition: isMobile ? "center" : "top",
    description:
      "Cloud-managed, secure by default, and built for enterprise scalability, the DC20 pairs seamlessly with Rhombus Access Control devices and existing door hardware. ",
    image: data.dc20Dims,
    dimsAlt:
      "Rhombus DC20 door controller with dimensions displayed. Compact design with easy plug and play and ability to view and review access logs.",
    pdf: "/files/specs/DC20.pdf",
    manual: "/files/manuals/DC20-Manual.pdf",
    column1: [
      {
        title: "Controller Parts",
        text: (
          <>
            Module, enclosure and power cables.{" "}
            <a
              href="/files/specs/DC20-M.pdf"
              target="_blank"
              style={{
                fontSize: "inherit",
                color: "var(--blue-500)",
                textDecoration: "none",
              }}
            >
              *Module-only option available at request.
            </a>
          </>
        ),
      },
      {
        title: "Dimensions",
        text: "330mm (13.0in) x 313 mm (12.3in) x 113mm (4.4in)",
      },
      {
        title: "Weight",
        text: "13.0 lb (5.9 kg) (Enclosure + internal parts, excluding batteries)",
      },
      {
        title: "AC Power Input",
        text: "100-240VAC, 50-60Hz 90W Max",
      },
      {
        title: "Relays",
        text: "4 Door Relays, 2 Aux Relays",
      },
      {
        title: "Inputs",
        text: (
          <>
            4 REX and 4 DPI &#40;Supervised&#41;
            <br></br>1 AUX input &#40;Unsupervised&#41;
          </>
        ),
      },
    ],
    column2: [
      {
        title: "Operating Temperature",
        text: "32˚F - 122˚F (0˚C - 50˚C)",
      },
      {
        title: "Connectivity",
        text: "Ethernet 10 / 100Mbps RJ45",
      },
      {
        title: "In the Box",
        text: "2 enclosure keys, mounting screws, user manual",
      },
      {
        title: "Mounting",
        text: "Wood / drywall screws",
      },
      {
        title: "10-Year Warranty Included",
      },
    ],
    exploreCards: [
      {
        image: data.exploreDR20,
        alt: "Rhombus DR20 door reader mounted on a wall. Sleek design for secure gate access, easy to place and use with touchless entry options",
        width: exploreDR20Width,
        model: "DR20",
        useCase: "Modern, touchless, and reliable smart reader",
        link: "/access-control/door-readers/dr20/",
      },
      {
        image: data.exploreDR40,
        alt: "Rhombus DR40 access control door reader with integrated camera. Sleek design for continuous duty site security and seamless entry management.",
        width: exploreDR40Width,
        model: "DR40",
        useCase: "Equipped with a door reader, camera, and intercom",
        link: "/access-control/door-readers/dr40/",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>DC20 Door Controller | Door Access Control | Rhombus</title>
        <meta
          name="description"
          content="Discover seamless access management with DC20 door access control reader, a secure and scalable device that is cloud-managed, enterprise-ready, and seamlessly integrates with existing hardware."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-DC20.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-DC20.png"
        />
      </Helmet>
      <AccessControlSpecsPage model={dc20} />
    </GlobalLayout>
  );
}
