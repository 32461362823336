import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import icon1 from "components/common/icons/pto.png";
import icon2 from "components/common/icons/health.png";
import icon3 from "components/common/icons/piggy-bank.png";
import icon4 from "components/common/icons/growth.png";
import icon5 from "components/common/icons/wfh.png";
import icon6 from "components/common/icons/perks.png";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";

const Container = styled(SectionContainer)`
  background: var(--nuetral-100);
  padding-top: 6rem;
  padding-bottom: 6rem;
`;

const SectionRight = styled(SectionCol)`
  flex-direction: column;
  width: 75%;
  gap: 4rem;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const SectionLeft = styled(SectionCol)`
  position: relative;
`;

const imageStyles = css`
  position: absolute !important;
  left: -15% !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  width: 476px !important;
  height: calc(692px - 6rem) !important;
  border-radius: 1rem !important;
  @media (min-width: 1800px) {
    left: 0 !important;
  }
  @media (max-width: 1024px) {
    left: -50% !important;
  }
  @media (max-width: 820px) {
    display: none !important;
  }
`;

const benefitRow = css`
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
`;

const benefitBox = css`
  flex-basis: calc((100% - 4rem) / 2);
  @media (max-width: 600px) {
    flex-basis: 100%;
  }
`;

const titleRow = css`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const iconStyles = css`
  width: 39px;
`;

const TeamBenefits = () => {
  const { isMobile } = useIsMobile(820);
  const BENEFITS = [
    {
      title: "Flexible PTO",
      description:
        "Take some time when you need recharge. We believe a healthy work/life balance is crucial for long-term success.",
      icon: icon1,
    },
    {
      title: "Health Insurance",
      description:
        "Don't worry, we got you covered. Majority employer paid medical, dental, and vision for you and your dependents.",
      icon: icon2,
    },
    {
      title: "Stock Options",
      description:
        "Do your best work and be rewarded. Everyone receives stock options so when the company succeeds, you do as well.",
      icon: icon3,
    },
    {
      title: "Professional Development",
      description:
        "Receive reimbursements for professional development to help you maximize your opportunity at Rhombus.",
      icon: icon4,
    },
    {
      title: "Work from Home",
      description:
        "Work better at home versus the office? Have the ability to work remote, in the office, or hybrid, depending on the role.",
      icon: icon5,
    },
    {
      title: "Office Perks",
      description:
        "Enjoy where you work - fully-loaded snack bar, happy hours, ping-pong breaks, and more!",
      icon: icon6,
    },
  ];
  return (
    <Container>
      <SectionInner>
        <SectionLeft>
          <StaticImage
            src="./img/benefits-min.png"
            alt="Rhombus Benefits"
            className={imageStyles}
            objectFit="cover"
            loading="eager"
          />
        </SectionLeft>
        <SectionRight>
          <TitleMed>We Take Care of Our Team</TitleMed>
          <div className={benefitRow}>
            {BENEFITS.map((item) => (
              <div className={benefitBox}>
                <TextContainer style={{ gap: "1rem" }}>
                  <div className={titleRow}>
                    <img
                      src={item.icon}
                      alt={item.title}
                      className={iconStyles}
                    />
                    <TitleSmall>{item.title}</TitleSmall>
                  </div>
                  <MainParagraph>{item.description}</MainParagraph>
                </TextContainer>
              </div>
            ))}
          </div>
        </SectionRight>
      </SectionInner>
    </Container>
  );
};

export default TeamBenefits;
