import RhombusButton from "components/common/RhombusButton";
import { css } from "@linaria/core";
import React from "react";

const quoteBannerClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  font-size: 16px;
  padding-bottom: 5rem;
  border-bottom: 1px solid var(--nuetral-200);
  max-width: 1390px;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    a {
      width: 90%;
    }
  }
`;

export default function PricingFooterBanner() {
  return (
    <div className={quoteBannerClass}>
      <RhombusButton
        type="primary-dark"
        path="/custom-quote/"
        title="Request Custom Quote"
      />
      <RhombusButton
        type="secondary"
        path="/files/rhombus-price-sheet.pdf"
        title="Download Pricing Sheet"
        target="_blank"
        useHref
      />
    </div>
  );
}
