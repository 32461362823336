import React from "react";
import { usePagination, DOTS } from "hooks/usePagination";
import { css, cx } from "@linaria/core";

const paginationContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding-bottom: 75px;
  padding-inline-start: unset;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
`;

const paginationItem = css`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  line-height: 1.5;
  font-size: 14px;
  min-width: 32px;
  color: rgba(43, 46, 48, 0.99);

  &.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  &:hover {
    background-color: rgba(60, 135, 236, 0.15);
    cursor: pointer;
  }

  &.selected {
    background-color: rgba(60, 135, 236, 0.15);
  }
`;

const paginationItemActive = cx(
  paginationItem,
  css`
    background-color: rgba(60, 135, 236, 0.15);
  `
);

const arrowContainer = css`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  line-height: 1.5;
  min-width: 32px;
`;

const arrowClass = css`
  cursor: pointer;
  transition: all 500ms ease;

  &::before {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(43, 46, 48, 0.99);
    border-top: 0.12em solid rgba(43, 46, 48, 0.99);
  }

  &:hover {
    &::before {
      border-right: 0.12em solid var(--blue-500);
      border-top: 0.12em solid var(--blue-500);
    }
  }
`;

const rightArrowClass = cx(
  arrowClass,
  css`
    transform: rotate(45deg);
  `
);

const rightArrowDisabled = cx(
  rightArrowClass,
  css`
    cursor: unset;
    pointer-events: none;

    ::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }

    &:hover {
      ::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }
    }
  `
);

const leftArrowClass = cx(
  arrowClass,
  css`
    transform: rotate(-135deg) translate(-50%);
  `
);

const leftArrowDisabled = cx(
  leftArrowClass,
  css`
    cursor: unset;
    pointer-events: none;

    ::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }

    &:hover {
      ::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }
    }
  `
);

export default function BlogPagination({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <ul className={paginationContainer}>
      <li className={arrowContainer} onClick={onPrevious}>
        <div
          className={currentPage === 1 ? leftArrowDisabled : leftArrowClass}
        />
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={
              pageNumber === currentPage ? paginationItemActive : paginationItem
            }
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li className={arrowContainer} onClick={onNext}>
        <div
          className={
            currentPage === lastPage ? rightArrowDisabled : rightArrowClass
          }
        />
      </li>
    </ul>
  );
}
