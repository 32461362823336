import React from "react";
import HeroLayout1 from "../hero/HeroLayout1";
import DeviceCards from "../DeviceCards";
import DeviceBenefits from "../DeviceBenefits";
import DeviceFeturedSpecs from "../DeviceFeturedSpecs";
import CTABanner from "../footer-banners/CTABanner";
import FAQSection from "../FAQSection";

const DevicePageTemplate = ({
  data,
  video,
  videoBackup,
  deviceType = null,
  recommend = false,
  image = null,
  cameras = true,
  reverse = false,
  faq = null,
}) => {
  return (
    <>
      <HeroLayout1
        reverse={reverse}
        data={data.headerSection}
        bottom
        backLink={
          cameras && { path: "/cameras/#cards", text: "Browse All Cameras" }
        }
        minHeight="712px"
        dark
      />
      {data.cardSection && (
        <DeviceCards
          data={data.cardSection}
          center={data.cardSection?.center}
        />
      )}
      {data.benefitsSection && (
        <DeviceBenefits
          data={data.benefitsSection}
          center={data.benefitsSection?.center}
        />
      )}
      <DeviceFeturedSpecs
        data={data.specSection}
        videos={video && { video: video, backup: videoBackup }}
        image={image}
        reverse
      />
      {faq && (
        <FAQSection
          image={faq.image}
          imageAlt={faq.alt}
          reverse
          title={faq.title}
          QA={faq.QA}
        />
      )}
      {recommend && (
        <DeviceCards
          data={data.exploreSection}
          recommend
          deviceType={deviceType}
        />
      )}
      <CTABanner />
    </>
  );
};

export default DevicePageTemplate;
