import GlobalLayout from "components/page/GlobalLayout";
import SensorOverviewPage from "components/sensors/SensorOverviewPage";
import React from "react";
import Helmet from "react-helmet";

export default function Senors() {
  return (
    <GlobalLayout color="var(--primary-bg)" dark>
      <Helmet>
        <title>Enhance Situational Awareness | IoT Sensors | Rhombus</title>
        <meta
          name="description"
          content="Expand security insights beyond video surveillance for smarter, safer spaces. Detect vaping, unauthorized access, motion, glass break, and more with commercial IoT sensors, powered by AI alerts, on a cloud-managed platform."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-sensors.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-sensors.png"
        />
      </Helmet>
      <SensorOverviewPage />
    </GlobalLayout>
  );
}
