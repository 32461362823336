import { styled } from "@linaria/react";
import {
  DefaultCard,
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { CategoryCol } from "components/integrations/marketplace/styles/MarketPlace-css";

export const WebinarsHeroContainer = styled(SectionContainer)`
  background: var(--gradient-light-fade);
`;

export const WebinarsHeroInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const HeroLeft = styled(SectionCol)`
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const HeroRight = styled(SectionCol)`
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const FeaturedCard = styled(DefaultCard)`
  width: 600px;
  height: 600px;
  max-width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  box-shadow: 1px 10px 15px #03193320;
  text-decoration: none;
  color: inherit;

  .image-wrapper {
    height: 50%;
    position: relative;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 250ms ease-in;
    }
  }

  :hover .image-wrapper img {
    transform: scale(1.1);
  }

  .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.25rem;
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 100%;
    }
  }

  .card-footer {
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1.25rem;
    padding-top: 0;
    a {
      font-size: 16px;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 450px;
  }
`;

export const TypeTag = styled.div`
  position: absolute;
  top: 1.75rem;
  left: 1.25rem;
  padding: 0.75rem 1.25rem;
  background: var(--primary-bg);
  z-index: 3;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--white);
  font-size: 18px;
`;

export const WebinarsSectionInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SidebarContainer = styled(CategoryCol)`
  width: 20%;
  position: sticky;
  top: 7.5rem;
  height: fit-content;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const WebinarFeedContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  flex-grow: 1;
  width: 80%;
  overflow: visible;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const WebinarFeed = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
`;

export const WebinarCard = styled(DefaultCard)`
  width: calc((100% - 2.5rem) / 2);
  height: 462px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  transition: all 250ms ease-in;
  .image-wrapper {
    height: 50%;
    position: relative;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 250ms ease-in;
    }
  }

  .card-header {
    height: 10%;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem;
    padding-bottom: 0;
  }

  .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.25rem;
    h2 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 100%;
    }
  }

  .card-footer {
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1.25rem;
    padding-top: 0;
  }

  :hover {
    box-shadow: 1px 10px 15px #03193320;
    transform: translateY(-0.25rem);
  }
  :hover .image-wrapper img {
    transform: scale(1.1);
  }

  @media (max-width: 820px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LiveWebinarTag = styled.div`
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  background: var(--primary-bg);
  color: var(--white);
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
`;
