import {
  FlexCenter,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

import { FREE_TRIAL_BUTTON_SCHEMA } from "./lib/constants";
import RhombusButton from "./RhombusButton";
import { Body, TitleLarge } from "components/rui/typography";

const imageStyles = css`
  width: 100% !important;
  margin-bottom: -5.5rem;
`;

const imageCoverStyles = css`
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  @media (max-width: 820px) {
    object-position: bottom center !important;
  }
`;

const BladeInner = styled(SectionInner)`
  flex-direction: row-reverse;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled(TextContainer)`
  gap: 1.6rem;
  @media (max-width: 820px) {
    text-align: center;
    align-items: center;
  }
`;
/**
 * Only use for a custom variation of our @see CTABanner or @see TrialBanner
 */

const RhombusBlade = ({
  image = null,
  title = "Try Rhombus for Free!",
  blurb = "See why school districts, cities, and Fortune 500 companies use Rhombus.",
  button = FREE_TRIAL_BUTTON_SCHEMA,
  color = "var(--gradient-light)",
  reverse = false,
  fancy = false,
  fancyButton = false,
  cover = false,
}) => {
  return (
    <SectionContainer
      style={{
        flexDirection: reverse ? "row" : "row-reverse",
        background: color,
        position: "relative",
        minHeight: "500px",
      }}
    >
      <BladeInner>
        <SectionCol
          style={{
            alignItems: "flex-end",
          }}
        >
          {image ? (
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              className={cover ? imageCoverStyles : imageStyles}
              objectFit="contain"
            />
          ) : (
            <StaticImage
              src="./footer-banners/images/rhombus-console-suite-min.png"
              className={imageStyles}
              alt="Poduim with two Rhombus cameras and a computer and phone showing the console"
              quality={100}
              placeholder="blurred"
              objectFit="contain"
            />
          )}
        </SectionCol>
        <FlexCenter style={{ zIndex: "2" }}>
          <Text>
            <TitleLarge>{title}</TitleLarge>
            <Body>{blurb}</Body>
            {button && (
              <RhombusButton
                path={button.path}
                title={button.text}
                useHref={button.useHref}
                target={button.target}
              />
            )}
          </Text>
        </FlexCenter>
      </BladeInner>
    </SectionContainer>
  );
};

export default RhombusBlade;
