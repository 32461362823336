import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";
import { Body, TitleLarge } from "components/rui/typography";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";

const SectionWrapper = styled(SectionContainer)`
  padding: var(--padding-top-bottom) 1.25rem;
  position: relative;
  min-height: 500px;
`;

const imageStyles = css`
  width: 100% !important;
  margin-bottom: calc((var(--padding-top-bottom) + 4px) * -1);
`;

const BladeInner = styled(SectionInner)`
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const Text = styled(TextContainer)`
  gap: 1.6rem;
  justify-content: center;
  @media (max-width: 820px) {
    align-items: center;
    text-align: center;
    max-width: unset;
  }
`;

const CTABannerHome = () => {
  return (
    <SectionWrapper color="var(--secondary-bg)">
      <BladeInner>
        <SectionCol style={{ zIndex: "2" }}>
          <Text width="549px">
            <TitleLarge>Explore Rhombus for your facilities.</TitleLarge>
            <Body>
              Discover how our innovative solutions can enhance your security
              and operational efficiency today.
            </Body>
            <RhombusButton
              path={"/live-demo/"}
              type="primary"
              title={CTA_BUTTON_COPY_3}
            />
          </Text>
        </SectionCol>
        <SectionCol style={{ alignItems: "flex-end" }}>
          <StaticImage
            src="./images/product-podium-min.png"
            className={imageStyles}
            alt="Display of all Rhombus products, including cameras, iot sensors, and access control, showcasing comprehensive security solutions."
            quality={100}
            placeholder="blurred"
            objectFit="contain"
            objectPosition={"bottom"}
          />
        </SectionCol>
      </BladeInner>
    </SectionWrapper>
  );
};

export default CTABannerHome;
