import { css } from "@linaria/core";
import { styled } from "@linaria/react";

export const MenuTitle = styled.h3`
  color: var(--nuetral-400);
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

export const menuColumn = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.75rem 1.25rem;
  flex-grow: 1;
  max-width: 300px;
  &:last-of-type:not(:nth-of-type(2)):not(:only-of-type) {
    max-width: unset;
  }
  &:nth-of-type(5) {
    padding-right: calc((100% - var(--max-width)) / 2);
    max-width: unset;
  }
  &:only-of-type {
    max-width: 300px;
  }
  @media (max-width: 1415px) {
    &:nth-of-type(5) {
      padding-right: 1.25rem;
    }
  }
  @media (max-width: 1200px) {
    padding: 1.25rem 0;
    height: fit-content;
    width: calc(100% / 3);
  }
  @media (max-width: 820px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const MenuLinks = styled.ul`
  list-style: none;
  padding: 0;
  border-top: 1px solid var(--nuetral-200);
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-top: 1.25rem;
`;

export const MenuLink = styled.li`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  .active {
    color: var(--blue-500);
    background-color: var(--secondary-bg);
  }
  a {
    color: var(--primary-bg);
    background-color: transparent;
    width: fit-content;
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: all 250ms ease-in;
    text-transform: capitalize;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    :hover {
      color: var(--blue-500);
      background-color: var(--secondary-bg);
    }
  }
`;

export const Tag = styled.div`
  display: flex;
  padding: 0 6px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${(props) => (props.color ? props.color : "var(--primary-bg)")};
  color: var(--white);
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
`;

export const NavLink = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  color: ${(props) => (props.active ? "var(--primary-bg)" : props.color)};
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1;
  transition: all 250ms ease-in;
  border-radius: 0.25rem;
  width: fit-content;
  a {
    color: ${(props) => (props.active ? "var(--primary-bg)" : props.color)};
    text-decoration: none;
    transition: all 250ms ease-in;
    font-size: inherit;
  }
  svg {
    transition: all 250ms ease-in;
    transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0)")};
  }
  :hover {
    color: ${(props) => props.hoverColor};
  }
  :hover a {
    color: ${(props) => props.hoverColor};
  }
  .active {
    color: ${(props) => props.hoverColor};
  }
  /* UNDERLINE */
  /* :has(.active) {
    ::after {
      position: absolute;
      content: "";
      bottom: -0.75rem;
      left: 0;
      transition: all 250ms ease-in;
      width: 100%;
      height: 2px;
      background: var(--primary);
    }
  }
  ::after {
    position: absolute;
    content: "";
    bottom: -0.75rem;
    left: 0;
    transition: all 250ms ease-in;
    width: ${(props) => (props.active ? "100%" : 0)};
    height: 2px;
    background: var(--gradient-base);
  } */
`;

export const FeaturedCard = styled.a`
  display: flex;
  max-width: 464px;
  width: 100%;
  padding: 12px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  border: 0.5px solid var(--nuetral-200);
  color: var(--neutral-900);
  background: var(--white);
  text-decoration: none;
  transition: all 250ms ease-in;
  :hover {
    border-color: var(--primary-bg);
  }
  .card-image-wrapper {
    height: 120px;
    aspect-ratio: 16/14;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .card-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: left;
    // blurry image fix
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    height: 100%;
    flex-grow: 1;
  }
  .link-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
    gap: 36px;
    flex-grow: 1;
  }
  h3 {
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    max-width: 323px;
    .card-image-wrapper {
      width: 100%;
      height: 128px;
    }
    .card-image {
      object-position: center;
    }
  }
`;

export const NavButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  gap: 18px;
  @media (max-width: 820px) {
    .console-sign-in-link {
      display: none;
    }
  }
`;
