import React from "react";
import { GradientTag, GreenTag, GreyTag, Tag } from "./styles/MarketPlace-css";
import { COLLECTION_NAMES } from "./lib/constants";
import LogoSmall from "./img/logo-icon-white.png";

const BLACK_TAGS = [
  COLLECTION_NAMES.NEW,
  COLLECTION_NAMES.COMING_SOON,
  COLLECTION_NAMES.BETA,
];
const GREY_TAGS = [COLLECTION_NAMES.THIRD_PARTY];
const GREEN_TAGS = [COLLECTION_NAMES.FEATURED];
const GRADIENT_TAGS = [COLLECTION_NAMES.CERTIFIED];

const AppTag = ({ tag }) => {
  if (BLACK_TAGS.includes(tag)) {
    return (
      <Tag>
        <p>{tag}</p>
      </Tag>
    );
  }

  if (GREY_TAGS.includes(tag)) {
    return (
      <GreyTag>
        <p>{tag}</p>
      </GreyTag>
    );
  }

  if (GREEN_TAGS.includes(tag)) {
    return (
      <GreenTag>
        <p>{tag}</p>
      </GreenTag>
    );
  }

  if (GRADIENT_TAGS.includes(tag)) {
    return (
      <GradientTag>
        <img src={LogoSmall} />
        <p>{tag}</p>
      </GradientTag>
    );
  }

  return <></>;
};

export default AppTag;
