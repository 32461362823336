import { Link } from "gatsby";
import React from "react";

import { ButtonLink } from "components/rui/buttons";

const TextLinkV2 = ({
  path,
  title,
  target = "_self",
  reverse = false,
  onClick,
  as = Link,
  small = false,
  light = false,
}) => {
  return (
    <ButtonLink
      as={as}
      to={path}
      target={target}
      className="rhombus-text-link"
      reverse={reverse}
      onClick={onClick}
      small={small}
      light={light}
      title={title}
    >
      {title}
    </ButtonLink>
  );
};

export default TextLinkV2;
