import { css } from "@linaria/core";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import BlogPagination from "./BlogPagination";
import ChipFilters from "components/rhombus-UI/ChipFilters";
import { styled } from "@linaria/react";

const itemsClass = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 60px;
  margin-bottom: 75px;
  width: 100%;

  @media (max-width: 975px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

const FilterSection = styled.div`
  padding-bottom: 2.5rem;
  width: 100%;
`;

export default function IndustryFilter({ items, categories, card }) {
  const ItemCard = card;
  const [filter, setFilter] = useState(null);
  const [currentIndustryPage, setCurrentIndustryPage] = useState(1);
  const [paginationTotal, setPaginationTotal] = useState(items.length);
  const pageSize = 12;

  const filteredItems = useMemo(() => {
    const firstPageIndex = (currentIndustryPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    if (!filter) return items.slice(firstPageIndex, lastPageIndex);

    return items
      .filter(a => a.node.frontmatter.subCategory === filter)
      .slice(firstPageIndex, lastPageIndex);
  }, [items, currentIndustryPage, setCurrentIndustryPage, filter]);

  useEffect(() => {
    setCurrentIndustryPage(1);
    setPaginationTotal(filteredItems.length);
  }, [filter, filteredItems]);

  const handleFilterSelection = useCallback(filterArray => {
    setFilter(filterArray[0]);
  }, []);

  return (
    <>
      <FilterSection>
        <ChipFilters
          center
          handleFilter={handleFilterSelection}
          options={categories.map(item => item)}
        />
      </FilterSection>
      <div className={itemsClass}>
        {filteredItems.map(post => {
          return <ItemCard key={post.node.id} post={post.node} />;
        })}
      </div>
      <BlogPagination
        currentPage={currentIndustryPage}
        totalCount={paginationTotal}
        pageSize={pageSize}
        onPageChange={page => setCurrentIndustryPage(page)}
        siblingCount={1}
      />
    </>
  );
}
