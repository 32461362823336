import TextLinkV2 from "components/common/TextLinkV2";
import { graphql, Link, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useSetAtom } from "jotai";
import React from "react";

import { megaMenuOpenAtom } from "../atoms/headerAtoms";
import { FeaturedCard } from "../styles/HeaderStyles";

export default function FeaturedPages() {
  const setMegaMenuOpen = useSetAtom(megaMenuOpenAtom);
  const renderContent = (data) => {
    const featuredPages = [
      {
        image: data.instantDemo,
        title: "Instant Demo",
        blurb:
          "See why modern organizations choose Rhombus to protect their spaces.",
        path: "/instant-demo/",
        linkText: "Watch Now",
      },
      {
        image: data.caseStudies,
        title: "Customer Stories",
        blurb:
          "Learn why Rhombus is rated as #1 in Physical Security and is trusted by industry leaders.",
        path: "/case-studies/",
        linkText: "Learn More",
      },
    ];
    return (
      <>
        {featuredPages.map((page) => (
          <FeaturedCard
            as={Link}
            to={page.path}
            onClick={() => setMegaMenuOpen(false)}
          >
            <div className="card-image-wrapper">
              <GatsbyImage
                image={getImage(page.image)}
                className="card-image"
                alt={page.title}
                objectFit="cover"
              />
            </div>
            <div className="text-container">
              <h3>{page.title}</h3>
              <p>{page.blurb}</p>
              <div className="link-container">
                <TextLinkV2 path={page.path} title={page.linkText} small />
              </div>
            </div>
          </FeaturedCard>
        ))}
      </>
    );
  };
  const GET_IMAGES = graphql`
    query {
      instantDemo: file(
        relativePath: { eq: "components/page/newHeader/img/instant-demo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      caseStudies: file(
        relativePath: { eq: "components/page/newHeader/img/case-studies.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
