import HomePage from "components/home-page/HomePage";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function Home() {
  const color = "transparent";
  return (
    <GlobalLayout transparent color={color} dark>
      <Helmet>
        <title>
          #1 Rated Commercial Security System | Cloud Physical Security |
          Rhombus
        </title>
        <meta
          name="description"
          content="Start a free trial with the #1 rated physical security solution for customer satisfaction. Deter threats and streamline operations with a cloud commercial security system."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/meta-homepage.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/meta-homepage.png"
        />
      </Helmet>
      <HomePage />
    </GlobalLayout>
  );
}
