import { ZoomInfoForm } from "components/form/zoomInfo/ZoomInfoForm";
import GlobalLayout from "components/page/GlobalLayout";
import { styled } from "@linaria/react";
import React from "react";
import { Helmet } from "react-helmet";
import LogoBlade from "../consultant-lp/LogoBlade";
import Disclaimer from "components/form/Disclaimer";

const PageContainer = styled.section`
  height: calc(100vh - 80px);
  min-height: 800px;
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--gradient-light);
  gap: 2.5rem;
  position: relative;
  overflow-x: hidden;
  ::after {
    content: "";
    position: absolute;
    width: 845px;
    height: 419px;
    right: ${(props) => props.bgImageRightPos};
    bottom: ${(props) => props.bgImageBottomPos};
    z-index: 0;
    background-image: ${(props) => `url(${props.bgImage})`};
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 820px) {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    height: fit-content;
    min-height: 100vh;
  }
  @media (max-width: 820px) {
    gap: 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  z-index: 1;
  flex-grow: 1;
  position: relative;
  ::after {
    position: absolute;
    width: 100%;
    height: 298px;
    right: 0;
    left: 0;
    margin: auto;
    bottom: -2rem;
    z-index: -1;
    background-image: ${(props) => `url(${props.bgImage})`};
    background-position: ${(props) =>
      props.useCenterMobileImage ? "center" : "bottom right"};
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 820px) {
      content: "";
    }
  }
  @media (max-width: 820px) {
    padding-bottom: ${(props) => props.mobileFormPadding};
  }
`;

const CTAPage2 = ({
  formId,
  title,
  lastStepTitle,
  subTitle,
  bgImage,
  bgImageMobile,
  mobileFormPadding = "5rem",
  bgImageRightPos = "0",
  bgImageBottomPos = "100px",
  backLink = "/lp/cameras-c/",
  useCenterMobileImage = false,
}) => {
  return (
    <GlobalLayout
      landingPage
      formOnly
      color="var(--gradient-light)"
      logoLink={backLink}
      useFullWidth
      useIncreasedPadding
      useFullWidthLogo
      diableStickyHeader
    >
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <PageContainer
        bgImage={bgImage}
        bgImageRightPos={bgImageRightPos}
        bgImageBottomPos={bgImageBottomPos}
      >
        <FormContainer
          bgImage={bgImageMobile}
          mobileFormPadding={mobileFormPadding}
          useCenterMobileImage={useCenterMobileImage}
        >
          <ZoomInfoForm
            useFormBackground
            useButtonv2
            maxFormWidth="591px"
            useFancyTitle
            formId={formId}
            title={title}
            lastStepTitle={lastStepTitle}
            subTitle={subTitle ?? headline}
            renderDisclaimer={() => <Disclaimer formId={formId} />}
          />
        </FormContainer>
        <LogoBlade
          style={{
            paddingTop: "2.5rem",
            paddingBottom: "2.5rem",
            background: "var(--white)",
            alignSelf: "stretch",
            zIndex: 1,
          }}
        />
      </PageContainer>
    </GlobalLayout>
  );
};

export default CTAPage2;
