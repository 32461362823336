import React from "react";

const Check = ({ color = "currentColor", height = "17", width = "23" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23 17"
      fill="none"
    >
      <path
        d="M2 7.6164L8.53695 14.8136L21.0431 2"
        stroke={color}
        stroke-width="2.62"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Check;
