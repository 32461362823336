import GlobalLayout from "components/page/GlobalLayout";
import AccessControlSpecsPage from "components/access-control/specs/AccessControlSpecsPage";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import useIsMobile from "hooks/useIsMobile";

export default function DR40() {
  const data = useStaticQuery(graphql`
    query {
      dr40Header: file(
        relativePath: {
          eq: "components/access-control/specs/img/dr40-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dr40HeaderMobile: file(
        relativePath: {
          eq: "components/access-control/specs/img/dr40-header-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dr40Dims: file(
        relativePath: {
          eq: "components/access-control/specs/img/dr40-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR40: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr20.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDC20: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dc20-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);
  const { isMobile } = useIsMobile(700);
  let exploreDR20Width = "48px";

  if (isMobile) {
    exploreDR20Width = "38px";
  }

  const dr40 = {
    title: "DR40 Commercial Video Intercom",
    specTitle: "DR40 Commercial Video Intercom Specifications",
    accTitle: "DR40 Commercial Video Intercom Accessories",
    model: "DR40",
    imageHeader: data.dr40Header,
    imageHeaderMobile: data.dr40HeaderMobile,
    heroAlt:
      "DR40 rhombus access control system views the site properly in a few seconds to monitor building access and security",
    objectPosition: "right",
    description:
      "Experience seamless guest management with the DR40 and Rhombus Access Control. Grant access, review entry events, and easily communicate with visitors on an integrated cloud platform.",
    image: data.dr40Dims,
    dimsAlt:
      "DR40 rhombus access control system views the site properly in a few seconds to monitor building access and security",
    pdf: "/files/specs/DR40.pdf",
    manual: "/files/manuals/DR40-Manual.pdf",
    column1: [
      {
        title: "Dimensions",
        text: "Mullion 50mm (1.97in) x 165mm (6.5in) x 35.1mm (1.38in) (height off wall, 38.3mm including cable)",
      },
      {
        title: "Credential Compatibility",
        text: "Rhombus Badge, ISO 14443A (CSN), Rhombus Key App",
      },
      {
        title: "Weight",
        text: "0.40kg (0.9lb))",
      },
      {
        title: "Weather Resistance",
        text: "IP65",
      },
      {
        title: "Operating Temperature",
        text: "-40˚C (-40˚F) to 50˚C (122˚F)",
      },
      {
        title: "Operating Humidity",
        text: "10% - 90%",
      },
      {
        title: "Image Sensor",
        text: "OS05A20",
      },
      {
        title: "Resolution",
        text: "5MP sensor, 2688 x 1944 resolution",
      },
    ],
    column2: [
      {
        title: "Field of View",
        text: [
          "155.2˚ Diagonal FoV",
          "118.4° Horizontal FoV",
          "86.4°Vertical FoV",
        ],
      },
      {
        title: "Focal Length",
        text: "2.6mm",
      },
      {
        title: "Input Voltage",
        text: "802.3af or 11-28VDC",
      },
      {
        title: "Power Consumption MAX",
        text: "9.3W",
      },
      {
        title: "Frequency",
        text: "13.56 MHz",
      },
      {
        title: "Controller Compatibility",
        text: "DC20",
      },
      {
        title: "Ethernet",
        text: "10 / 100Mbps",
      },
      {
        title: "Mounting",
        text: "Wood / drywall screws, 1-Gang Wall Plate",
      },
      {
        title: "Aux Relay",
        text: "",
      },
      {
        title: "BLE Enabled",
        text: "",
      },
      {
        title: "Rhombus Sensor Network Compatible",
        text: "",
      },
      {
        title: "NDAA, FCC, UL 294 Compliant",
        text: "",
      },
      {
        title: "10-Year Warranty Included",
      },
    ],
    exploreCards: [
      {
        image: data.exploreDR40,
        alt: "DR20 Access Control Door Reader",
        width: exploreDR20Width,
        model: "DR20",
        useCase: "Modern, touchless, and reliable smart reader",
        link: "/access-control/door-readers/dr20/",
      },
      {
        image: data.exploreDC20,
        alt: "DC20 Access Control Unit",
        width: "168px",
        model: "DC20",
        useCase: "4-Door Access Control Unit",
        link: "/access-control/dc20/",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>
          DR40 Commercial Video Intercom | Door Access Control Systems | Rhombus
        </title>
        <meta
          name="description"
          content="Boost building security with Rhombus DR40 commercial video intercom. Get instant notifications, visitor verification, and seamless communication for secure building access through the cloud-managed interface."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-DR40.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-DR40.png"
        />
      </Helmet>
      <AccessControlSpecsPage model={dr40} />
    </GlobalLayout>
  );
}
