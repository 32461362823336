import AccordionSection from "components/common/AccordionSection";
import DeviceCards from "components/common/DeviceCards";
import Features from "components/common/Features";
import TrialBanner2 from "components/common/footer-banners/TrialBanner2";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import SensorOverviewIndustrySection from "./overview/SensorOverviewIndustrySection";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";
import FAQSection from "components/common/FAQSection";
import CTABanner from "components/common/footer-banners/CTABanner";

const FAQ_DATA = [
  {
    question: "What types of IoT sensors does Rhombus offer?",
    answer:
      "Rhombus provides a range of IoT sensors, including proximity sensors, temperature sensors, pressure sensors, and humidity sensors. These sensors are designed for various applications such as environmental monitoring, security, and operational efficiency.",
  },
  {
    question: "How do Rhombus sensors enhance security in smart buildings?",
    answer:
      "Rhombus security sensors detect motion, monitor entry points, and alert users to unusual activities. They integrate seamlessly with other connected devices to provide comprehensive security solutions for smart buildings.",
  },
  {
    question: "Can Rhombus sensors be used for industrial applications?",
    answer:
      "Yes, Rhombus offers industrial IoT sensors that monitor environmental conditions like air quality and temperature. These sensors help improve operational efficiency and predictive maintenance in industrial settings.",
  },
  {
    question: "How do Rhombus sensors enable environmental monitoring?",
    answer:
      "Rhombus air quality iot sensors collect data on various environmental parameters and track their changes. They provide real-time monitoring to help maintain safe conditions and ensure compliance with health standards.",
  },
  {
    question:
      "What are the benefits of using Rhombus sensors for security management?",
    answer:
      "Rhombus sensors measure a wide range of security parameters not covered by cameras. Their interoperability allows for enhanced data sharing and system coordination. By providing real-time data, these IoT devices support informed decisions to reduce costs and improve efficiency.",
  },
];

export default function SensorOverviewPage() {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/cameras/img/sensors-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      faqImage: file(
        relativePath: { eq: "components/sensors/overview/img/sensor-faq.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      button: file(
        relativePath: {
          eq: "components/sensors/overview/img/button-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      audio: file(
        relativePath: {
          eq: "components/sensors/overview/img/audio-gateway-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      entry: file(
        relativePath: {
          eq: "components/sensors/overview/img/entry-monitoring-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      environmental: file(
        relativePath: { eq: "components/sensors/img/e50-card-fade-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      motion: file(
        relativePath: {
          eq: "components/sensors/overview/img/m15-product-card-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      web: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      mobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      one: file(
        relativePath: { eq: "components/sensors/overview/img/acc-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      two: file(
        relativePath: { eq: "components/sensors/overview/img/acc-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      three: file(
        relativePath: { eq: "components/sensors/overview/img/acc-3-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      icon1: file(relativePath: { eq: "components/common/icons/network.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/security.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/updates.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);
  const headerSection = {
    preTitle: "IOT SENSORS",
    title:
      "Expand Security Insights and Situational Awareness with IoT Sensors",
    description:
      "Experience unparalleled security monitoring and capture critical insights with commercial IoT sensors. Gain real-time, actionable data paired with high-resolution video for secure and efficient environments - all on an easy-to-use cloud platform.",
    image: data.headerImage,
    altText:
      "Display of various Rhombus IoT sensors on a podium, showcasing proximity, temperature, pressure, and infrared sensors for diverse applications.",
    button1: {
      text: CTA_BUTTON_COPY_3,
      path: "/free-trial/",
    },
  };

  const cardSection = {
    title: "Go Beyond Video Surveillance for Comprehensive Safety",
    cards: [
      {
        image: data.button,
        altText:
          "Rhombus IoT sensor B10 button installed on a wall, showcasing its role as a smart IoT security device in connected environments. Enable one-touch emergency response with this programmable button.",
        name: "Smart Button",
        description:
          "Get one-touch emergency responses with this programmable button.",
        link: "/sensors/b10/",
        flex: 3,
      },
      {
        image: data.audio,
        altText:
          "Rhombus Iot Sensor A100 Audio Gateway installed on a wall to detect unwanted visitors and protect property effectively.",
        name: "Audio Gateway",
        description:
          "Two-way audio with broadcasting and intelligent audio analytics.",
        link: "/sensors/a100/",
        flex: 3,
      },
      {
        image: data.environmental,
        altText:
          "The IoT sensor E50, installed on a ceiling, has multiple sensors—temperature sensors, pressure sensors, chemical sensors, gas sensors, humidity sensors, and more—to help track environmental parameters. It detect leaks and safeguards spaces with proactive alerts.",
        name: "Environmental Monitoring",
        description: "Monitor air quality, vape, and temperature in real-time.",
        link: "/sensors/environmental-monitoring/",
        flex: 3,
      },
      {
        image: data.entry,
        altText:
          "Rhombus IoT sensor D20 Door installed, enhancing security by integrating with smart devices for real-time monitoring and alerts.",
        name: "Entry Monitoring",
        description: "Track and secure any entry point across your spaces.",
        link: "/sensors/d20/",
        flex: 3,
      },
      {
        image: data.motion,
        altText:
          "The M1 iot sensors, installed on a wall, detect infrared radiation to sense motion around the spaces where cameras are restricted.",
        name: "Motion Sensing",
        description: "Detect motion while protecting privacy.",
        link: "/sensors/m15/",
        flex: 3,
      },
    ],
  };

  const accordionSection = {
    title: "Elevate Your Physical Security with Connected Sensors",
    items: [
      {
        title: "Sound the Alarm",
        p: "Accelerate emergency response with the single push of a button. Instantaneously alert dispatchers of an emergency and trigger a loud siren to draw attention and deter unwanted guests.",
        img: data.one,
        alt: "Person pressing Rhombus IoT Sensor B10 emergency button, which sends electrical signals, during active shooter threat, utilizing IoT devices for real-time alerts and security response.",
      },
      {
        title: "Monitor Environments",
        p: "Protect employees, equipment, and assets from hazardous environmental changes. Receive instant notifications for temperature and humidity fluctuations or when smoke, vape, or toxic air quality is detected.",
        img: data.two,
        alt: "IoT sensor E15 in cold storage, using IoT temperature and humidity sensors for environmental monitoring and predictive maintenance.",
      },
      {
        title: "Expand Visibility",
        p: "Increase safety and security in spaces where cameras are not needed or allowed. Track motion and entry events for unusual activity to protect against unauthorized access, theft, and vandalism.",
        img: data.three,
        alt: "D20 IoT sensor on a door, utilizing IoT technology for security and monitoring, enhancing safety with real-time data collection.",
      },
    ],
  };

  const features = [
    {
      icon: data.icon1,
      iconAlt: "Integrated Experience",
      title: "Integrated Experience",
      p: "Seamlessly integrate sensors with cameras within your operation to gain insights beyond just capturing video.",
    },
    {
      icon: data.icon2,
      iconAlt: "Secure & Reliable",
      title: "Secure & Reliable",
      p: "All communication is end-to-end encrypted for uncompromising reliability and cybersecurity.",
    },
    {
      icon: data.icon3,
      iconAlt: "Powerful Data",
      title: "Powerful Data",
      p: "Receive real-time alerts and visualize sensor data with video evidence to streamline operations.",
    },
    {
      icon: data.icon4,
      iconAlt: "Automatic Updates",
      title: "Automatic Updates",
      p: "Always up to date and secure — receive new features and product enhancements at no cost.",
    },
  ];

  return (
    <>
      <HeroLayout1 data={headerSection} bottom reverse dark />
      <DeviceCards data={cardSection} center />
      <AccordionSection data={accordionSection} color="var(--nuetral-100)" />
      <SensorOverviewIndustrySection />
      <Features
        color="var(--nuetral-100)"
        data={features}
        title="Sensor Features"
      />
      <FAQSection
        QA={FAQ_DATA}
        image={data.faqImage}
        imageAlt={
          "Rhombus E50 environmental sensor detecting vape in a restroom, indicating air quality changes with real-time alerts."
        }
        title="IoT Sensors FAQs"
        reverse
      />
      <CTABanner />
    </>
  );
}
