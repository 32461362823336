import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import React from "react";
import ReviewSlider from "./ReviewSlider";
import { styled } from "@linaria/react";

const Section = styled(SectionContainer)`
  overflow: visible;
  padding: 5rem 2.5rem;
  @media (max-width: 600px) {
    padding: 5rem 1.25rem;
  }
`;

const ReviewSliderSection = () => {
  return (
    <Section id="testimonials">
      <SectionInner>
        <ReviewSlider fullSize />
      </SectionInner>
    </Section>
  );
};

export default ReviewSliderSection;
