import React from "react";

const IconSystem = ({
  size = "30",
  stroke = "var(--white)",
  fill = "var(--white)",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M19.9112 16.2088L16.2516 19.8684C15.5604 20.5596 14.4396 20.5596 13.7484 19.8684L10.0888 16.2088C9.39756 15.5175 9.39756 14.3968 10.0888 13.7056L13.7484 10.046C14.4396 9.35475 15.5604 9.35475 16.2516 10.046L19.9112 13.7056C20.6024 14.3968 20.6024 15.5175 19.9112 16.2088Z"
        stroke={stroke}
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5958 9.63887V4.27637H14.4041L14.4041 9.63887H15.5958ZM9.63667 14.4044H4.27417V15.596H9.63667V14.4044ZM20.3593 14.4044H25.7218V15.596H20.3593V14.4044ZM14.4041 25.726L14.4041 20.3635H15.5958V25.726L14.4041 25.726Z"
        fill={fill}
      />
      <path
        d="M3.6776 13.8102C3.01946 13.8102 2.48594 14.3437 2.48594 15.0018C2.48594 15.66 3.01946 16.1935 3.6776 16.1935C4.33574 16.1935 4.86927 15.66 4.86927 15.0018C4.86927 14.3437 4.33574 13.8102 3.6776 13.8102Z"
        stroke={stroke}
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M26.3185 13.8102C26.9766 13.8102 27.5102 14.3437 27.5102 15.0018C27.5102 15.66 26.9766 16.1935 26.3185 16.1935C25.6604 16.1935 25.1268 15.66 25.1268 15.0018C25.1268 14.3437 25.6604 13.8102 26.3185 13.8102Z"
        stroke={stroke}
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M16.1898 26.3224C16.1898 26.9805 15.6563 27.5141 14.9982 27.5141C14.34 27.5141 13.8065 26.9805 13.8065 26.3224C13.8065 25.6643 14.34 25.1307 14.9982 25.1307C15.6563 25.1307 16.1898 25.6643 16.1898 26.3224Z"
        stroke={stroke}
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M16.1898 3.67956C16.1898 3.02142 15.6563 2.48789 14.9982 2.48789C14.34 2.48789 13.8065 3.02142 13.8065 3.67956C13.8065 4.3377 14.34 4.87122 14.9982 4.87122C15.6563 4.87122 16.1898 4.33769 16.1898 3.67956Z"
        stroke={stroke}
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default IconSystem;
