import SlideInContainer from "components/common/SlideInContainer";
import TextLinkV2 from "components/common/TextLinkV2";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import { Body, PreTitle, TitleMed } from "components/rui/typography";

const SectionRight = styled(SectionCol)`
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
`;

const SpecRow = styled.div`
  display: flex;
  gap: 2rem;
  overflow: hidden;
  margin: 1rem 0;
  align-items: flex-start;
`;

const SpecCol = styled.table`
  width: 200px;
`;

const imageStyles = css`
  width: 100% !important;
`;

const SpecTitle = styled(TitleMed)`
  font-size: 32px;
`;

const BenefitRows = ({ data, color = "var(--white)", reverse = false }) => {
  const { isMobile } = useIsMobile(820);
  const renderRows = () => {
    if (!data) return;
    const rows = data.map((row, index) => (
      <SectionContainer key={row.preTitle} style={{ background: color }}>
        <SectionInner
          style={
            (index + 1) % 2 !== 0 || reverse
              ? { flexDirection: "row", gap: "4rem" }
              : { flexDirection: "row-reverse", gap: "4rem" }
          }
        >
          {!isMobile && (
            <SectionCol>
              <GatsbyImage
                image={getImage(row.image)}
                alt={row?.altText ?? row.title}
                className={imageStyles}
                objectFit="contain"
              />
            </SectionCol>
          )}
          <SectionRight>
            <TextContainer>
              <SlideInContainer>
                <PreTitle>{row.preTitle}</PreTitle>
                <TitleMed>{row.title}</TitleMed>
              </SlideInContainer>
              {isMobile ? (
                <>
                  <SlideInContainer>
                    <SpecRow>
                      {row.specs &&
                        row.specs.map((item) => (
                          <SpecCol key={item.title}>
                            <tr>
                              <td>
                                <SpecTitle>{item.title}</SpecTitle>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Body>{item.description}</Body>
                              </td>
                            </tr>
                          </SpecCol>
                        ))}
                    </SpecRow>
                  </SlideInContainer>
                  <SlideInContainer>
                    <Body>{row.description}</Body>
                  </SlideInContainer>
                </>
              ) : (
                <>
                  <SlideInContainer>
                    <Body>{row.description}</Body>
                  </SlideInContainer>
                  <SlideInContainer>
                    <SpecRow>
                      {row.specs &&
                        row.specs.map((item) => (
                          <SpecCol key={item.title}>
                            <tr>
                              <td>
                                <SpecTitle>{item.title}</SpecTitle>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Body>{item.description}</Body>
                              </td>
                            </tr>
                          </SpecCol>
                        ))}
                    </SpecRow>
                  </SlideInContainer>
                  <SlideInContainer>
                    {row.link && (
                      <TextLinkV2 path={row.link.path} title={row.link.title} />
                    )}
                  </SlideInContainer>
                </>
              )}
            </TextContainer>
            {isMobile && (
              <GatsbyImage
                image={getImage(row.image)}
                alt={row?.altText ?? row.title}
                className={imageStyles}
                objectFit="contain"
                objectPosition="top"
              />
            )}
            <SlideInContainer>
              <Body color="var(--nuetral-300)">{row.note && row.note}</Body>
            </SlideInContainer>
          </SectionRight>
        </SectionInner>
      </SectionContainer>
    ));
    return rows;
  };
  return renderRows();
};

export default BenefitRows;
