import AccordionSection from "components/common/AccordionSection";
import { StaticQuery, graphql } from "gatsby";
import React from "react";

export default function RctpProgramAccordian() {
  const renderContent = (data) => {
    const accordianSection = {
      title: "Training Overview",
      items: [
        {
          title: "What You’ll Learn",
          listItems: [
            "Product Suite Overview (Cameras, Access Control, Sensors, Alarms)​",
            "System Architecture​",
            "Networking Best Practices​",
            "User Management and Permissions​",
            "Notifications and Alerts ",
            "Feature Reviews​",
            "Installations, Accessories, and Mounts",
          ],
          img: data.slide1,
          alt: "",
        },
        {
          title: "Who Should Attend",
          listItems: [
            "Technical sales support personnel: Enhance your ability to assist your sales team with product recommendations, system design, and technical evaluations",
            "Technical consultants: Equip yourself to confidently work directly with Rhombus customers, solutions engineers, and account executives.​",
          ],
          img: data.slide2,
          alt: "",
        },
        {
          title: "Benefits of Being an RCTP Certified Partner ",
          listItems: [
            "Puts you first in line for Rhombus generated opportunities",
            "Your company will be eligible for programs such as the Proactive RMA Replacement program",
            "Certificate and badge indicating your expertise as a Rhombus Certified Technical Professional",
          ],
          img: data.slide3,
          alt: "",
        },
      ],
    };
    return (
      <AccordionSection
        data={accordianSection}
        fancy={false}
        color="var(nuetral-100)"
      />
    );
  };
  const GET_IMAGES = graphql`
    query {
      slide1: file(
        relativePath: {
          eq: "components/RCTP-Program/img/product-suite-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide2: file(
        relativePath: { eq: "components/RCTP-Program/img/atendees-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide3: file(
        relativePath: {
          eq: "components/RCTP-Program/img/rhombus-certificate-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
