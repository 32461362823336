import React from "react";

const IconNetwork = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4548 12.9843C14.4548 15.4663 12.4428 17.4803 9.95884 17.4803C7.47684 17.4803 5.46484 15.4663 5.46484 12.9843C5.46484 10.5023 7.47684 8.48828 9.95884 8.48828C12.4428 8.48828 14.4548 10.5023 14.4548 12.9843Z"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.6012 7.492C49.6012 10.526 47.1432 12.984 44.1092 12.984C41.0752 12.984 38.6172 10.526 38.6172 7.492C38.6172 4.458 41.0752 2 44.1092 2C47.1432 2 49.6012 4.458 49.6012 7.492Z"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.49 45.6894C10.49 48.1714 8.478 50.1854 5.994 50.1854C3.512 50.1854 1.5 48.1714 1.5 45.6894C1.5 43.2074 3.512 41.1934 5.994 41.1934C8.478 41.1934 10.49 43.2074 10.49 45.6894Z"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.4561 27.1197C32.4561 30.9057 29.3861 33.9757 25.6001 33.9757C21.8141 33.9757 18.7461 30.9057 18.7461 27.1197C18.7461 23.3337 21.8141 20.2637 25.6001 20.2637C29.3861 20.2637 32.4561 23.3337 32.4561 27.1197Z"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        d="M13.4219 15.8193L20.4399 22.6213"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        d="M20.4389 31.6172L9.96094 42.8952"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        d="M31.5078 23.6469L40.9378 11.9609"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        d="M25.6017 33.9746L27.2577 42.8946"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.9665 46.1659C30.9665 47.7959 29.6445 49.1179 28.0145 49.1179C26.3845 49.1179 25.0625 47.7959 25.0625 46.1659C25.0625 44.5359 26.3845 43.2139 28.0145 43.2139C29.6445 43.2139 30.9665 44.5359 30.9665 46.1659Z"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        d="M31.3789 30.8037L44.1109 37.2557"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.9234 38.0428C47.9234 39.0968 47.0694 39.9508 46.0154 39.9508C44.9634 39.9508 44.1094 39.0968 44.1094 38.0428C44.1094 36.9888 44.9634 36.1348 46.0154 36.1348C47.0694 36.1348 47.9234 36.9888 47.9234 38.0428Z"
        stroke="#2A7DE1"
        stroke-width="2.60001"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default IconNetwork;
