import { useState, useEffect, useRef } from "react";

const useRelativeScrollDetection = () => {
  const [scrolling, setScrolling] = useState(false);
  const lastScrollY = useRef(null);
  const isScrolling = useRef(false);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      clearTimeout(scrollTimeout.current);

      if (currentScrollY !== lastScrollY.current) {
        if (!isScrolling.current) {
          setScrolling(true);
          isScrolling.current = true;
        }
      }

      lastScrollY.current = currentScrollY;

      scrollTimeout.current = setTimeout(() => {
        setScrolling(false);
        isScrolling.current = false;
      }, 150); // Adjust the timeout duration as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);

  return scrolling;
};

export default useRelativeScrollDetection;
