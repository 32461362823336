import { styled } from "@linaria/react";
import IconCheckMark from "components/common/icon-components/IconCheckMark";
import { CTA_BUTTON_COPY, FORM_IDS } from "components/data/landing-page-data";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  FlexRow,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  Title,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Section = styled(SectionCol)`
  flex-direction: column;
  gap: 48px;
  &.form-container {
    align-items: flex-end;
  }
  @media (max-width: 820px) {
    max-width: 530px;
    align-items: center;
    &.form-container {
      align-items: center;
    }
  }
`;
const HeroTitle = styled(Title)`
  font-size: 64px;
  color: var(--primary-bg);
  @media (max-width: 1240px) {
    font-size: 56px;
  }
  @media (max-width: 600px) {
    font-size: 48px;
  }
`;

const HeroP = styled(MainParagraph)`
  font-size: 24px;
  @media (max-width: 1240px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const StatRow = styled(FlexRow)`
  max-width: 530px;
  width: 100%;
  gap: 5rem;
  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

const badgeProps = {
  height: 77,
  width: 67,
  quality: 100,
  objectFit: "contain",
  loading: "eager",
  placeholder: "blurred",
};

const checkList = ["Custom platform demo", "Customized Free trial", "Pricing"];
const stats = [
  { title: "99.9%", subTitle: "Uptime" },
  { title: "24/7", subTitle: "Support" },
  { title: "99%", subTitle: "Renewal Rate" },
];
const FormSectionF = () => {
  return (
    <SectionContainer style={{ background: "var(--gradient-light)" }}>
      <SectionInner style={{ alignItems: "center" }}>
        <Section>
          <TextContainer>
            <HeroTitle>Take the Next Step</HeroTitle>
            <HeroP>Modernize Your Security Infrastructure</HeroP>
          </TextContainer>
          <FlexRow style={{ gap: "1.25rem", width: "100%" }}>
            <MainParagraph style={{ fontSize: "18px" }}>
              What You&apos;ll Get:
            </MainParagraph>
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              {checkList.map((item) => (
                <li>
                  <FlexRow style={{ gap: "0.5rem", alignItems: "center" }}>
                    <IconCheckMark color="var(--primary)" stroke="4" />
                    <MainParagraph style={{ fontSize: "18px" }}>
                      {item}
                    </MainParagraph>
                  </FlexRow>
                </li>
              ))}
            </ul>
          </FlexRow>
          <FlexRow style={{ gap: "1.25rem", width: "100%" }}>
            <StaticImage
              src="../../../common/img/g2-badges/badge-1.png"
              {...badgeProps}
            />
            <StaticImage
              src="../../../common/img/g2-badges/badge-11.png"
              {...badgeProps}
            />
            <StaticImage
              src="../../../common/img/g2-badges/badge-10.png"
              {...badgeProps}
            />
            <StaticImage
              src="../../../common/img/g2-badges/badge-9.png"
              {...badgeProps}
            />
            <StaticImage
              src="../../../common/img/g2-badges/badge-8.png"
              {...badgeProps}
            />
            <StaticImage
              src="../../../common/img/g2-badges/badge-7.png"
              {...badgeProps}
            />
          </FlexRow>
          <StatRow>
            {stats.map((s) => (
              <TextContainer
                style={{ textAlign: "center", width: "fit-content" }}
              >
                <TitleMed>{s.title}</TitleMed>
                <MainParagraph>{s.subTitle}</MainParagraph>
              </TextContainer>
            ))}
          </StatRow>
        </Section>
        <Section className="form-container">
          <FormWrapper title={CTA_BUTTON_COPY} outline>
            <RhombusForm
              formId={FORM_IDS.LIVE_DEMO}
              useSecondaryButton
              customSubmitText="Get Pricing"
            />
          </FormWrapper>
        </Section>
      </SectionInner>
    </SectionContainer>
  );
};

export default FormSectionF;
