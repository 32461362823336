import React from "react";

import CompareCameras from "../../components/cameras/compare-cameras/CompareCamerasPage";
import GlobalLayout from "../../components/page/GlobalLayout";
import Helmet from "react-helmet";

export default function CompareCamerasPage() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Dome vs Fisheye vs Bullet Security Cameras | Try for Free | Rhombus
        </title>
        <meta
          name="description"
          content="Start a free trial with Rhombus security cameras. Compare Dome vs Fisheye vs Bullet, and other models to find the best video surveillance system for your organization."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-compare-cameras.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-compare-cameras.png"
        />
      </Helmet>
      <CompareCameras />
    </GlobalLayout>
  );
}
