import TextLinkV2 from "components/common/TextLinkV2";
import { styled } from "@linaria/react";
import { kebabCase } from "lodash";
import React from "react";

const SnippetContainer = styled.div`
  margin: 2.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;
export const ProfilePhoto = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: var(--nuetral-200);
  overflow: hidden;
  object-fit: cover;
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthorSnippet = ({ publishDate, name, src, alt, category }) => {
  return (
    <SnippetContainer>
      <ProfilePhoto src={src} alt={alt} height={48} width={48} />
      <Details>
        <div>
          <i>
            by <b>{name}</b>, on {publishDate}
          </i>
        </div>
        <i>
          <TextLinkV2
            path={`/blog/${kebabCase(category)}/`}
            title={category}
            noIcon
          />
        </i>
      </Details>
    </SnippetContainer>
  );
};

export default AuthorSnippet;
