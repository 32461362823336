import {
  CarouselContainerWithScrollBar,
  DefaultFlexContainer,
  SectionContainer,
  SliderSectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { StaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import { EventContainer, trainingSessionSlide } from "./styles/rctp-styles";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";

import RhombusButton from "components/common/RhombusButton";
import SlideInContainer from "components/common/SlideInContainer";
import TrainingEvent from "./TrainingEvent";
import { checkLastDate, compareStartDates } from "./util/rctp-functions";
import { styled } from "@linaria/react";

const SliderContainer = styled(CarouselContainerWithScrollBar)`
  @media (min-width: 1024px) {
    width: fit-content;
  }
`;

export default function UpComingTrainings() {
  const renderContent = (data) => {
    const { edges: _events } = data.allMarkdownRemark;

    const [events, setEvents] = useState(_events);

    useEffect(() => {
      const filteredEvents = _events
        .filter(checkLastDate)
        .sort(compareStartDates);
      setEvents(filteredEvents.slice(0, 3));
    }, [_events]);

    if (!events || events.length === 0) return null;

    return (
      <SectionContainer
        style={{
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        <SliderSectionInner>
          <TextContainer
            style={{
              textAlign: "center",
              width: "931px",
              maxWidth: "calc(100% - 1.25rem)",
            }}
          >
            <SlideInContainer>
              <FancyTitleMed>Upcoming Trainings</FancyTitleMed>
            </SlideInContainer>
            <MainParagraph>
              Our RCTP trainings are held in multiple cities across the US and
              Canada. Find a session near you, RSVP today, and unlock the path
              to becoming a Rhombus security expert. Limited seats are available
              so don't wait.
            </MainParagraph>
          </TextContainer>
          {events.length === 1 ? (
            <EventContainer
              style={{ padding: "0 1.25rem", justifyContent: "center" }}
            >
              <TrainingEvent eventData={events[0]} />
            </EventContainer>
          ) : (
            <SliderContainer>
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={30}
                scrollbar={{ draggable: true }}
                modules={[Scrollbar]}
                breakpoints={{
                  820: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                }}
              >
                {events.map((event) => {
                  return (
                    <SwiperSlide
                      className={trainingSessionSlide}
                      key={event.node.id}
                    >
                      <TrainingEvent eventData={event} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </SliderContainer>
          )}

          <RhombusButton
            type="primary-dark"
            title={events.length === 1 ? "View Location" : "View All Locations"}
            path={
              events.length === 1
                ? events[0].node.fields.slug
                : "/rctp/training-sessions#event-locations"
            }
          />
        </SliderSectionInner>
      </SectionContainer>
    );
  };
  const query = graphql`
    query {
      allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { date: ASC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "training-session" }
            draft: { ne: true }
          }
        }
      ) {
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              state
              image
              formId
              trainingEvents {
                trainingDates {
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
