import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Logos = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  user-select: none;
  @media (max-width: 1100px) {
    flex-wrap: nowrap;
    width: 244px;
  }
  @media (max-width: 820px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

const logoStyles = css`
  width: 47.25px;
  aspect-ratio: 1;
  object-position: top;
  @media (max-width: 1100px) {
    width: 38px;
  }
`;

const ComplianceLogos = () => {
  return (
    <Logos as={Link} to="/trust/">
      <StaticImage
        src="./img/SOC.png"
        alt="SOC"
        className={logoStyles}
        quality={100}
        loading="lazy"
        objectFit="contain"
        objectPosition={"top"}
      />
      <StaticImage
        src="./img/NDAA.png"
        alt="NDAA"
        className={logoStyles}
        quality={100}
        loading="lazy"
        objectFit="contain"
        objectPosition={"top"}
      />
      <StaticImage
        src="./img/GDPR.png"
        alt="GDPR"
        className={logoStyles}
        quality={100}
        loading="lazy"
        objectFit="contain"
        objectPosition={"top"}
      />
      <StaticImage
        src="./img/TAA.png"
        alt="TAA"
        className={logoStyles}
        quality={100}
        loading="lazy"
        objectFit="contain"
        objectPosition={"top"}
      />
    </Logos>
  );
};

export default ComplianceLogos;
