import IconTime from "components/common/icon-components/IconTime";
import { styled } from "@linaria/react";

import React, { useState } from "react";
import { formatISODateWithTimeZone } from "./util";
import { WEBINAR_TYPES } from "components/webinars/util/webinarsFunctions";
import { TitleSmall } from "components/rui/typography";

const StyledDateRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.75rem 0;
`;

const DateRow = ({ date, timeZone = "America/Los_Angeles", type }) => {
  const [expired, _] = useState(false);

  if (type === WEBINAR_TYPES.OnDemand) return <></>;
  return (
    <StyledDateRow>
      <IconTime />
      <TitleSmall>
        {expired
          ? "This event has passed"
          : formatISODateWithTimeZone(date, timeZone)}
      </TitleSmall>
    </StyledDateRow>
  );
};

export default DateRow;
