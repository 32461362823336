import { styled } from "@linaria/react";

export const ButtonBase = styled.a`
  display: inline-block;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primary-bg);
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: all 250ms;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid transparent;
  :hover {
    font-weight: 600;
  }
  // so there is no layout shift when increasing font size
  ::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const ButtonPrimary = styled(ButtonBase)`
  background: var(--primary);
  color: var(--primary-bg);
`;

export const ButtonPrimaryLight = styled(ButtonBase)`
  background: var(--white);
  color: var(--primary-bg);
`;

export const ButtonPrimaryDark = styled(ButtonBase)`
  background: var(--primary-bg);
  color: var(--white);
`;

export const ButtonSecondary = styled(ButtonBase)`
  border-color: var(--primary-bg);
  background: transparent;
  color: var(--primary-bg);
  :hover {
    border-radius: 4px;
    border-width: 1.2px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px);
  }
`;

export const ButtonSecondaryLight = styled(ButtonSecondary)`
  color: var(--white);
  border-color: var(--white);
`;

export const ButtonLink = styled.a`
  display: inline-block;
  color: ${(props) => (props.light ? "var(--white)" : "var(--primary-bg)")};
  font-family: "Sora Variable", sans-serif;
  font-size: ${(props) => (props.small ? "12px" : "16px")};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-color: var(--primary);
  text-decoration-thickness: 12%;
  text-underline-offset: 30%;
  text-underline-position: from-font;
  transition: all 250ms;
  width: fit-content;
  :hover {
    font-weight: 600;
    text-decoration-thickness: 18%;
  }
  // so there is no layout shift when increasing font size
  ::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const ButtonLinkSmall = styled(ButtonLink)`
  font-weight: 300;
  font-family: var(--bodyFontFamily);
  font-size: inherit;
  text-decoration-thickness: 8%;
  text-underline-offset: 36%;
  :hover {
    text-decoration-thickness: 12%;
  }
`;
