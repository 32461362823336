import G2BadgeSlider from "components/common/G2BadgeSlider";
import TextLinkV2 from "components/common/TextLinkV2";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import React from "react";

export default function G2Section({
  bgColor = "var(--nuetral-100)",
  title = "Rated #1 in Physical Security on G2",
  blurb = "Users love Rhombus. Among other physical security solutions, Rhombus ranks #1 thanks to our easy-to-use platform, plug-and-play setup, and world-class support.",
}) {
  return (
    <SectionContainer
      style={{
        background: bgColor,
        paddingRight: 0,
        paddingLeft: 0,
      }}
    >
      <SectionInner
        style={{
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <G2BadgeSlider />
        <TextContainer
          width="800px"
          style={{
            textAlign: "center",
            alignItems: "center",
            gap: "1.25rem",
            padding: "0 1.25rem",
          }}
        >
          <FancyTitle>{title}</FancyTitle>
          <MainParagraph>{blurb}</MainParagraph>
        </TextContainer>
        <TextLinkV2 path="/g2/" title="Learn More" />
      </SectionInner>
    </SectionContainer>
  );
}
