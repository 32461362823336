import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import React from "react";
import {
  FormCol,
  FormSectionContainer,
  FormTitleSection,
} from "./styles/MarketPlace-css";
import {
  SectionCol,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { BREAK_POINT } from "./lib/constants";

const FormSection = ({ formId }) => {
  const { isMobile } = useIsMobile(BREAK_POINT);
  return (
    <FormSectionContainer id="marketplace-form">
      <SectionInner>
        <SectionCol>
          <FormTitleSection>
            <FancyTitle>Looking for a Specific Integration?</FancyTitle>
            <MainParagraph>
              The Rhombus Engineering Team releases new features and
              integrations every month. If you would like to request a specific
              third-party integration, let us know!
            </MainParagraph>
          </FormTitleSection>
        </SectionCol>
        <FormCol>
          <FormWrapper width={isMobile ? "100%" : "586px"} title="Get in Touch">
            <RhombusForm formId={formId} />
          </FormWrapper>
        </FormCol>
      </SectionInner>
    </FormSectionContainer>
  );
};

export default FormSection;
