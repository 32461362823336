import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import RhombusButton from "components/common/RhombusButton";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import SocialLinks from "./SocialLinks";
import { TitleSmall } from "components/rui/typography";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  text-align: center;
  color: var(--white);
  width: 100%;
  @media (max-width: 1100px) {
    justify-content: flex-start;
    align-items: flex-start;
    width: calc((100% - 10rem) / 4);
    flex-shrink: 0;
    flex-grow: 1;
    gap: 0;
    h4 {
      margin-bottom: 28px;
    }
  }
  @media (max-width: 600px) {
    width: calc(50% - 2.5rem);
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 0.75rem;
  @media (max-width: 1100px) {
    margin-bottom: 28px;
    flex-direction: column;
    width: 100%;
  }
`;

const buttonStyles = css`
  width: 111px;
`;

const AppDownload = () => {
  const { isMobile } = useIsMobile(1100);
  const buttonProps = {
    className: buttonStyles,
    useHref: true,
    target: "_blank",
    style: { borderRadius: "9999px", fontWeight: 400 },
  };
  return (
    <Container>
      <TitleSmall light>GET THE APP</TitleSmall>
      <ButtonRow>
        <RhombusButton
          type="secondary-light"
          title={"IOS"}
          path="https://apps.apple.com/us/app/rhombus-systems/id1237841963"
          {...buttonProps}
        />
        <RhombusButton
          type="secondary-light"
          title={"Android"}
          path="https://play.google.com/store/apps/details?id=com.rhombussystems.rhombus"
          {...buttonProps}
        />
      </ButtonRow>
      {isMobile && <SocialLinks />}
    </Container>
  );
};

export default AppDownload;
