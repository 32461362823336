import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import {
  cardContainerClass,
  disclaimer,
  productSectionContainer,
} from "../styles/pricingStyles";
import SensorCard from "./SensorCard";
import SensorCardLicense from "./SensorCardLicense";
import sensorPricingData from "./sensorPricingData";
import { Body, TitleMed } from "components/rui/typography";

export default function SensorSection() {
  const data = useStaticQuery(graphql`
    query {
      a100Image: file(
        relativePath: { eq: "components/pricing/img/a100-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      b10Image: file(
        relativePath: { eq: "components/pricing/img/b10-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      d20Image: file(
        relativePath: { eq: "components/pricing/img/D20-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      e15Image: file(
        relativePath: { eq: "components/pricing/img/E15-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      e50Image: file(
        relativePath: { eq: "components/pricing/img/e50-pricing-render.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      m15Image: file(
        relativePath: { eq: "components/pricing/img/m15-pricing.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `);
  const sensors = sensorPricingData(data);
  return (
    <div>
      <div className={productSectionContainer}>
        <span className={disclaimer}>
          <Body subtle>
            Listed price is per sensor. Additional shipping and international
            fees may apply. Sensors compatible with Rhombus BLE-enabled devices
            only.
          </Body>
        </span>
        <TitleMed>License Required</TitleMed>

        <div
          className={cardContainerClass}
          style={{ justifyContent: "center" }}
        >
          {sensors.map((sensor) => {
            if (sensor.licensePrices) {
              return <SensorCardLicense info={sensor} key={sensor.name} />;
            }
            return <></>;
          })}
        </div>
      </div>
      <div className={productSectionContainer}>
        <TitleMed>No License Required</TitleMed>
        <div className={cardContainerClass}>
          {sensors.map((sensor) => {
            if (!sensor.licensePrices) {
              return <SensorCard info={sensor} key={sensor.name} />;
            }
            return <></>;
          })}
        </div>
      </div>
    </div>
  );
}
