import { ContentCol, ContentRow, TextContainer } from "components/rui/layout";
import { Body, TitleMed } from "components/rui/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import AdvantageCards, { ADAPT_CARDS, PROTECT_CARDS } from "./AdvantageCards";
import { css } from "@linaria/core";
import PageSection from "components/rui/page-components/PageSection";

const imageStyles = css`
  width: 100%;
  max-width: 668px;
  height: auto;
`;

const PlatformSection = () => {
  return (
    <PageSection
      preTitle={"The Rhombus Platform Advantage"}
      title={"Eliminate threats, not options"}
    >
      <ContentRow reverseOnMobile>
        <ContentCol>
          <TextContainer gap="12px">
            <TitleMed>Built to protect.</TitleMed>
            <Body>
              Simple and seamless physical security with a cybersecurity focus
            </Body>
          </TextContainer>
          <AdvantageCards cards={PROTECT_CARDS} />
        </ContentCol>
        <ContentCol>
          <StaticImage
            src="./assets/built-to-protect-min.png"
            className={imageStyles}
            quality={100}
            loading="eager"
            objectFit="contain"
            objectPosition={"left"}
          />
        </ContentCol>
      </ContentRow>
      <ContentRow>
        <ContentCol>
          <StaticImage
            src="./assets/designed-to-adapt-min.png"
            className={imageStyles}
            quality={100}
            loading="eager"
            objectFit="contain"
            objectPosition={"left"}
          />
        </ContentCol>
        <ContentCol>
          <TextContainer gap="12px">
            <TitleMed>Designed to adapt.</TitleMed>
            <Body>
              Future-proof operations while protecting existing investments
            </Body>
          </TextContainer>
          <AdvantageCards cards={ADAPT_CARDS} />
        </ContentCol>
      </ContentRow>
    </PageSection>
  );
};

export default PlatformSection;
