import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import {
  cardContainerClass,
  disclaimer,
  productSectionContainer,
} from "../styles/pricingStyles";
import CameraCard from "./CameraCard";
import { cameraPricingData } from "./cameraPricingData";
import { Body, TitleMed } from "components/rui/typography";

export default function CameraSection() {
  const data = useStaticQuery(graphql`
    query {
      r120: file(
        relativePath: { eq: "components/pricing/img/r120-200-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      r170: file(
        relativePath: { eq: "components/pricing/img/r170-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      r230: file(
        relativePath: { eq: "components/pricing/img/r230-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      r360: file(
        relativePath: { eq: "components/pricing/img/r360-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      r400: file(
        relativePath: { eq: "components/pricing/img/r400-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      r500: file(
        relativePath: { eq: "components/pricing/img/r500-510-angled-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      r520: file(
        relativePath: { eq: "components/pricing/img/r520-pricing.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      r600: file(
        relativePath: { eq: "components/pricing/img/r600-pricing.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `);

  const { domeCameras, fisheyeCameras, bulletCameras, multiSensorCameras } =
    cameraPricingData(data);
  return (
    <div>
      <div className={productSectionContainer} id="Dome">
        <span className={disclaimer}>
          <Body subtle>
            All cameras include 10-year warranties. Listed price is per camera,
            additional shipping and international fees may apply.
          </Body>
        </span>
        <TitleMed>Dome Cameras</TitleMed>
        <div className={cardContainerClass}>
          {domeCameras.map((camera) => (
            <CameraCard info={camera} key={camera.name} />
          ))}
        </div>
      </div>
      <div className={productSectionContainer} id="Fisheye">
        <TitleMed>Fisheye Cameras</TitleMed>
        <div className={cardContainerClass}>
          {fisheyeCameras.map((camera) => (
            <CameraCard info={camera} key={camera.name} />
          ))}
        </div>
      </div>
      <div className={productSectionContainer} id="Bullet">
        <TitleMed>Bullet Cameras</TitleMed>
        <div className={cardContainerClass}>
          {bulletCameras.map((camera) => (
            <CameraCard info={camera} key={camera.name} />
          ))}
        </div>
      </div>
      <div className={productSectionContainer} id="Multisensor">
        <TitleMed>Multisensor Cameras</TitleMed>
        <div className={cardContainerClass}>
          {multiSensorCameras.map((camera) => (
            <CameraCard info={camera} key={camera.name} />
          ))}
        </div>
      </div>
    </div>
  );
}
