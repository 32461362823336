import React from "react";

const IconFiles = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
    >
      <g clip-path="url(#clip0_27235_23724)">
        <path
          d="M35.832 50.5V41.7109H47.5508V6.35938H20.6241L17.6944 0.5H3.60547V41.7109H15.3242V50.5H35.832ZM32.9023 47.5703H18.2539V35.8516H32.9023V47.5703ZM29.9727 32.9219H21.1836V28.332C21.1836 25.9089 23.155 23.9375 25.5781 23.9375C28.0013 23.9375 29.9727 25.9089 29.9727 28.332V32.9219ZM15.8837 3.42969L18.8134 9.28906H44.6211V12.2188H6.53516V3.42969H15.8837ZM6.53516 38.7812V15.1484H44.6211V38.7812H35.832V32.9219H32.9023V28.332C32.9023 24.2935 29.6167 21.0078 25.5781 21.0078C21.5396 21.0078 18.2539 24.2935 18.2539 28.332V32.9219H15.3242V38.7812H6.53516Z"
          fill="#2A7DE1"
        />
        <path
          d="M24.1133 38.7812H27.043V44.6406H24.1133V38.7812Z"
          fill="#2A7DE1"
        />
      </g>
      <defs>
        <clipPath id="clip0_27235_23724">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.578125 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconFiles;
