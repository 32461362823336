import { styled } from "@linaria/react";
import RhombusButton from "components/common/RhombusButton";
import TextLinkV2 from "components/common/TextLinkV2";
import { FlexColumnCenter, FlexColumnEnd } from "components/rui/layout";
import PageSection from "components/rui/page-components/PageSection";
import SliderNavDots from "components/rui/slider-components/SliderNavDots";
import { Body, PreTitle, TitleMed } from "components/rui/typography";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { STANCE_VIDEO_URL, STORIES } from "./constants";
import Link from "components/page/headers/HeaderLink";

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 40px;
  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 900px) {
    gap: 20px;
  }
`;

const BaseStoryCard = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 420px;
  padding: 28px 30px;
  gap: 20px;
  border-radius: 12px;
  border: 1px solid var(--primary-bg-50);
  background: var(--white);
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  min-width: 340px;
  transition: all 250ms;
  h3 {
    font-size: 36px;
  }
  h4 {
    color: var(--primary-bg);
  }
  q {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    color: var(--primary-bg);
  }
  :not(:last-of-type) {
    width: calc((100% - 40px) / 4);
  }
  :last-of-type {
    flex-grow: 1;
  }
  :hover {
    border-color: var(--blue-500);
    background: var(--tertiary-bg);
    box-shadow: 0px 5px 10px var(--primary-bg-30);
  }
  @media (max-width: 900px) {
    max-width: 340px;
    flex-shrink: 0;
  }
`;

const FeaturedCard = styled(BaseStoryCard)`
  max-width: unset;
  width: 100%;
  flex-direction: row;
  padding: 0;
  overflow: hidden;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    height: unset;
  }
`;

const FeaturedCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  max-width: 480px;
  height: 100%;
  padding: 28px 30px;
  text-decoration: none;
`;

export const VideoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  position: relative;
  a {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 10;
  }
  /* ::after {
    content: "";
    height: 300px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      var(--primary-bg) 100%
    );
    @media (max-width: 768px) {
      background: transparent;
    }
  } */
`;

const FeaturedCardVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CustomerStoriesSection = () => {
  const logos = useStaticQuery(graphql`
    query {
      stanceLogo: file(
        relativePath: {
          eq: "components/home-page/assets/logos/stance-logo.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, height: 40)
        }
      }
      luxerOneLogo: file(
        relativePath: {
          eq: "components/home-page/assets/logos/luxer-one-logo.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, height: 60)
        }
      }
      middleBuryLogo: file(
        relativePath: {
          eq: "components/home-page/assets/logos/middlebury-logo.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, height: 60)
        }
      }
      smhaLogo: file(
        relativePath: { eq: "components/home-page/assets/logos/smha-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, height: 60)
        }
      }
    }
  `);
  const { isMobile: showSlider } = useIsMobile(1200);
  const { isMobile } = useIsMobile(768);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % STORIES.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? STORIES.length - 1 : prevIndex - 1
    );
  };

  const scrollToIndex = (index) => {
    if (containerRef.current) {
      const cardWidth = 340 + 40;
      containerRef.current.scrollTo({
        left: index * cardWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToIndex(currentIndex);
  }, [currentIndex, scrollToIndex]);

  return (
    <PageSection
      preTitle={"customer stories"}
      title={
        "Learn how others are securing spaces and saving time with Rhombus"
      }
    >
      <FlexColumnCenter gap="40px" grow {...handlers}>
        <FeaturedCard as={Link} to={"/case-studies/stance/"}>
          <FeaturedCardDetails>
            <PreTitle>RETAIL</PreTitle>
            <GatsbyImage
              image={getImage(logos.stanceLogo)}
              objectFit="contain"
              objectPosition="left"
            />
            <div>
              <TitleMed>20+</TitleMed>
              <Body bold>locations nationwide</Body>
            </div>
            <FlexColumnEnd gap="20px">
              <q>
                Whenever you're deploying new technology, the people you're
                working with can make or break the experience.{" "}
                <b>
                  Rhombus and LVC have made this experience incredibly easy and
                  seamless.
                </b>
              </q>
              {isMobile && (
                <RhombusButton
                  path="/case-studies/stance/"
                  title="Read story"
                  type="secondary"
                />
              )}
            </FlexColumnEnd>
          </FeaturedCardDetails>
          <VideoWrapper>
            <FeaturedCardVideo
              src={STANCE_VIDEO_URL}
              playsInline
              autoPlay
              loop
              muted
            />
            {!isMobile && (
              <RhombusButton
                path="/case-studies/stance/"
                title="Watch Customer Story"
                type="primary-light"
              />
            )}
          </VideoWrapper>
        </FeaturedCard>
        <CardContainer ref={containerRef}>
          {STORIES.map((s) => (
            <BaseStoryCard as={Link} to={s.link}>
              <PreTitle>{s.industry}</PreTitle>
              <GatsbyImage
                image={getImage(logos[s.logoKey])}
                objectFit="contain"
                objectPosition="left"
              />
              <div>
                <TitleMed>{s.stat.title}</TitleMed>
                <Body bold>{s.stat.subTitle}</Body>
              </div>
              <FlexColumnEnd gap="20px">
                <q>{s.qoute}</q>
                <RhombusButton
                  path={s.link}
                  title={"Read story"}
                  type="secondary"
                />
              </FlexColumnEnd>
            </BaseStoryCard>
          ))}
        </CardContainer>
        {showSlider && (
          <SliderNavDots
            slides={STORIES}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        )}
      </FlexColumnCenter>
      <TextLinkV2 title={"See More Customer Stories"} path={"/case-studies/"} />
    </PageSection>
  );
};

export default CustomerStoriesSection;
