import { useEffect, useState } from "react";

const useCookieBanner = () => {
  const [hideCookieBanner, setHideCookieBanner] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const isLP = window.location.pathname.includes("/lp/");
    // hide banner if we are on a landing page
    setHideCookieBanner(isLP);
  }, []);

  useEffect(() => {
    const handleCookieBanner = () => {
      const cookieBanner = document.querySelector(".osano-cm-window");

      if (cookieBanner) {
        if (hideCookieBanner) {
          cookieBanner.style.display = "none";
        } else {
          cookieBanner.style.display = "block";
        }
      }
    };

    handleCookieBanner();

    const observer = new MutationObserver(() => {
      handleCookieBanner();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [hideCookieBanner]);
};

export default useCookieBanner;
