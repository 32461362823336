import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import SlideInContainer from "./SlideInContainer";
import { Body, SectionTitle, TitleSmall } from "components/rui/typography";

const flexRow = css`
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;

const flexItem = css`
  flex-basis: calc((100% - 3rem) / 4);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  p {
    max-width: 270px;
  }
  h3 {
    max-width: 290px;
  }
  @media (max-width: 820px) {
    flex-basis: calc((100% - 1rem) / 2);
  }
  @media (max-width: 600px) {
    flex-basis: 100%;
  }
`;

const iconStyles = css`
  height: 66px !important;
  aspect-ratio: 1 !important;
`;

const InfoBoxesInner = styled(SectionInner)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4rem;
  > div {
    width: 100%;
  }
`;

const InfoBoxSection = ({ title, infoBoxes }) => {
  return (
    <SectionContainer>
      <InfoBoxesInner>
        <SectionTitle>{title}</SectionTitle>
        <SlideInContainer>
          <div className={flexRow}>
            {infoBoxes.map((item) => (
              <div key={item.title} className={flexItem}>
                <TextContainer style={{ gap: "1rem", alignItems: "center" }}>
                  <GatsbyImage
                    image={getImage(item.icon)}
                    alt={item.title}
                    objectFit="contain"
                    className={iconStyles}
                  />
                  <TitleSmall>{item.title}</TitleSmall>
                  <Body>{item.blurb}</Body>
                </TextContainer>
              </div>
            ))}
          </div>
        </SlideInContainer>
      </InfoBoxesInner>
    </SectionContainer>
  );
};

export default InfoBoxSection;
