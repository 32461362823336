import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import CameraDetail from "components/cameras/detail/CameraDetailPage";
import {
  CTA_BUTTON_COPY_6,
  LIVE_DEMO_LINK,
} from "components/data/landing-page-data";

const R520Page = () => {
  const data = useStaticQuery(graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r520-header-long.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r520-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r520-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      bulletCamera: file(
        relativePath: { eq: "components/cameras/img/explore-r500-510-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bulletCameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r500-510-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const camera = {
    model: "R520",
    heroTitle: "R520 Mini Bullet Camera",
    type: "Bullet",
    description:
      "General-purpose 5MP mini bullet with Optical Zoom. Ideal for monitoring access points and long-range visibility in outdoor spaces.",
    webHeader: data.webImage,
    mobileHeader: data.mobileImage,
    imageAlt:
      "The R520 mini 5MP bullet camera, mounted on a wall in the rain, monitors access points and provides long-range visibility in outdoor locations",
    objectPosition: "50%",
    dims: data.dims,
    dimsAlt: "Dimensions of Rhombus R520 mini 5MP bullet camera.",
    pdf: "/files/specs/R520.pdf",
    specs: {
      column1: [
        {
          title: "Resolution",
          text: "5MP image sensor, 2560 x 1920 resolution",
        },
        {
          title: "Aperture",
          text: "F/1.6, P-iris",
        },
        {
          title: "Pan / Tilt / Zoom",
          text: "3x optical zoom + up to 2x digital PTZ",
        },
        {
          title: "Field of View",
          text: [
            "132° - 34° Diagonal FoV",
            "97° - 27° Horizontal FoV",
            "70° - 20° Vertical FoV",
          ],
        },
        {
          title: "Focal Length",
          text: "3.3mm - 9.7mm motorized varifocal lens",
        },
        {
          title: "Dimensions",
          text: "85mm (3.34in) x 85mm (3.34in) x 214.5mm (8.42in)",
        },
        {
          title: "Weather Resistance",
          text: "IP66",
        },
        {
          title: "Night Vision",
          text: "Infrared illuminators (98ft in low light)",
        },
        {
          title: "Onboard Storage Included",
          text: [
            "128GB or 20 days",
            "256GB or 40 days",
            "512GB or 90 days",
            "1TB or 180 days",
          ],
        },
      ],
      column2: [
        {
          title: "Ethernet",
          text: "10 / 100Mbps, MDI / MDI-X Auto Sensing",
        },
        {
          title: "Power-Over-Ethernet",
          text: "Standard 802.3af for one-cable installation",
        },
        {
          title: "Power Consumption",
          text: "Max 7.5 watts",
        },
        {
          title: "Operating Temperature",
          text: ["-30 to 55˚ C", "-22˚ to 131˚ F"],
        },
        {
          title: "Operating Humidity",
          text: "0% - 90% RH",
        },
        {
          title: "In the Box",
          text: "Torx key, mounting screws, anchors, waterproof ethernet connector",
        },
        {
          title: "Baseline Steady State",
          text: "10 - 30 Kbps per camera",
        },
        {
          title: "HD WAN Live Streaming",
          text: "530 - 720 Kbps per camera",
        },
        {
          title: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant",
        },
        {
          title: "10-Year Warranty Included",
        },
        {
          title: "Rhombus Sensor Network Compatible",
        },
      ],
    },
    accessoryCompatible: true,
    sensorCompatible: false,
    accessories: ["ACCR5POLE", "ACCR5JB"],
    exploreModels: {
      title: "Explore Other Bullet Cameras",
      subtitle:
        "Find the solution that’s best suited for your needs. Compare all camera models or view other bullet cameras below.",
      cameraCards: [
        {
          image: data.bulletCamera,
          mobileImage: data.bulletCameraMobile,
          alt: "R500 bullet camera model",
          width: "189px",
          model: "R500 Telephoto",
          useCase: "Ideal for parking lots.",
          link: "/cameras/bullet-cameras/r500",
        },
        {
          image: data.bulletCamera,
          mobileImage: data.bulletCameraMobile,
          alt: "R510 bullet camera model",
          width: "189px",
          model: "R510 Standard Zoom",
          useCase: "Ideal for building perimeters.",
          link: "/cameras/bullet-cameras/r510",
        },
      ],
    },
  };
  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>R520 5MP Bullet Camera | Bullet Cameras | Rhombus</title>
        <meta
          name="description"
          content="Monitor access points and gain long-range visibility for outdoor spaces. Featuring a 5MP sensor and 3X optical zoom, the R520 mini bullet camera delivers an ideal balance of quality, coverage, and reliability."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-R520.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-R520.png"
        />
      </Helmet>
      <CameraDetail
        camera={camera}
        ctaCopy={CTA_BUTTON_COPY_6}
        ctaLink={LIVE_DEMO_LINK}
      />
    </GlobalLayout>
  );
};

export default R520Page;
