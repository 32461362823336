import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { styled } from "@linaria/react";
import React, { useEffect, useState } from "react";
import { Converter } from "showdown";
import DateRow from "./DateRow";
import useIsMobile from "hooks/useIsMobile";
import BackLink from "components/common/BackLink";
import { checkExpiration } from "./util";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  @media (max-width: 1024px) {
    padding: 5rem 0;
  }
`;
const HeroInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const HeroLeft = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    padding: 1.25rem;
  }
`;
const HeroRight = styled(SectionCol)`
  justify-content: flex-end;

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding: 1.25rem;
  }
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const LogoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.25rem;
  margin-top: 0.75rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img``;

const HybridEventHero = ({
  preTitle,
  title,
  date,
  timeZone,
  description,
  locationOnline,
  locationInPerson,
  note,
  formId,
  logos,
}) => {
  const { isMobile } = useIsMobile(1024);
  const converter = new Converter();
  const _description = converter.makeHtml(description);
  const _note = converter.makeHtml(note);
  const [expired, setExpired] = useState(false);

  // useEffect(() => {
  //   const getIsExpired = async () => {
  //     const expired = await checkExpiration(date);
  //     setExpired(expired);
  //   };
  //   getIsExpired();
  // }, []);

  return (
    <HeroContainer>
      <HeroInner>
        <HeroLeft>
          <TextContainer width={"665px"}>
            <BackLink text={"Browse Webinars"} path={"/webinars/"} />
            <PreTitle>{preTitle}</PreTitle>
            <FancyPageTitle>{title}</FancyPageTitle>
            <DateRow date={date} timeZone={timeZone} />
            <div dangerouslySetInnerHTML={{ __html: _description }} />
          </TextContainer>
          {locationOnline && locationInPerson && (
            <TextContainer width={"665px"}>
              <TitleSmall>Online or In-Person </TitleSmall>
              <MainParagraph>{locationOnline}</MainParagraph>
              <MainParagraph>{locationInPerson}</MainParagraph>
            </TextContainer>
          )}
          {logos && (
            <TextContainer width={"665px"}>
              <TitleSmall>Co-Hosted by</TitleSmall>
              <LogoRow>
                {logos.map((logo, index) => {
                  const {
                    logoSrc: src,
                    logoAlt: alt,
                    logoWidth: width,
                    logoHeight: height,
                  } = logo;
                  return (
                    <LogoWrapper key={`${alt}-${index}`}>
                      <Logo src={src} alt={alt} height={height} width={width} />
                    </LogoWrapper>
                  );
                })}
              </LogoRow>
            </TextContainer>
          )}
          {_note && (
            <TextContainer width={"665px"}>
              <div dangerouslySetInnerHTML={{ __html: _note }} />
            </TextContainer>
          )}
        </HeroLeft>
        {formId && !expired && (
          <HeroRight>
            <FormWrapper
              title="Event Registration"
              width={isMobile ? "665px" : "550px"}
            >
              <RhombusForm formId={formId} />
            </FormWrapper>
          </HeroRight>
        )}
      </HeroInner>
    </HeroContainer>
  );
};

export default HybridEventHero;
