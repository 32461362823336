import RhombusButton from "components/common/RhombusButton";
import PageContent from "components/page/PageContent";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import { css } from "@linaria/core";
import React from "react";
import { Helmet } from "react-helmet";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";

const containerClass = css`
  width: 100%;
  background: var(--primary-bg);
  height: calc(100vh);
  margin-top: -80px;
  position: relative;
`;

const videoClass = css`
  display: block;
  object-fit: cover;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  margin: 0px auto;
`;
const contentClass = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
  color: white;
  margin: 0 auto;

  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 700px) {
    justify-content: flex-end;
    padding-bottom: 80px;
  }

  p {
    font-size: 16px;
    line-height: 2;
    max-width: 445px;

    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

const textWrapper = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  color: var(--white);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const pageContentClass = css`
  height: 100%;
`;

export default function AccessControlVideo() {
  const videoUrl =
    "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/access_control_video_trimmed.webm";

  return (
    <section className={containerClass}>
      <Helmet>
        <link rel="preload" href={videoUrl} as="video" type="video/webm" />
      </Helmet>
      <video
        src={videoUrl}
        className={videoClass}
        muted
        autoPlay
        loop
        playsInline
      />
      <div className={textWrapper}>
        <PageContent contentClass={pageContentClass}>
          <div className={contentClass}>
            <TextContainer width="665px">
              <PageTitle>
                Elevate Building Security with Advanced Door Access Control
                System
              </PageTitle>
              <MainParagraph>
                Wave hello to modern access control with centralized management,
                simplified yet secure access, seamless remote control, and
                unlimited scalability.
              </MainParagraph>
              <RhombusButton
                type="primary-light"
                title={CTA_BUTTON_COPY_3}
                path="/live-demo/"
                style={{ marginTop: "0.5rem" }}
              />
            </TextContainer>
          </div>
        </PageContent>
      </div>
    </section>
  );
}
