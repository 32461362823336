import TextLink from "components/common/TextLink";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";

import {
  arrowClass,
  arrowLinkContainer,
  cameraDaysClass,
  cameraImageClass,
  cameraImageContainer,
  cameraInfoContainer,
  cameraPriceClass,
  cardContainer,
  circleButtonSelectedClass,
  circleButtonUnselectedClass,
  licenseTypeButtonsClass,
  licenseTypeContainerClass,
  lineClass,
  resolutionText,
  smallFontClass,
  tooltipClass,
  tooltipContainer,
  tooltipIconClass,
  tooltipTextClass,
  typeButtonSelectedClass,
  typeButtonUnselectedClass,
  typeTooltipText,
  yearsButtonsClass,
  yearsContainerClass,
} from "../styles/pricingStyles";
import { licencePricing } from "./cameraPricingData";
import TextLinkV2 from "components/common/TextLinkV2";

export default function CameraCard({ info }) {
  const [priceIndex, setPriceIndex] = useState(0);
  const [licensePriceIndex, setLicensePriceIndex] = useState(0);
  const [licenseType, setLicenseType] = useState(0);

  const specLink = `/cameras/${info.category.toLowerCase()}-cameras/${info.name.toLowerCase()}`;
  const imageRef = getImage(info.image);

  const licensePricingForCameras = licencePricing.filter((pricingObject) =>
    pricingObject.for.includes(info.name)
  );

  return (
    <div className={cardContainer} id={info.name.toLowerCase()}>
      <div className={cameraImageContainer}>
        <GatsbyImage
          image={imageRef}
          className={cameraImageClass}
          alt={info?.altText ?? info.name}
          objectFit="contain"
        />
      </div>
      <div className={cameraInfoContainer}>
        <div>
          <h3 className="heavy">
            {info.name} {info.title}{" "}
            <span className={resolutionText}>{info.resolution}</span>
          </h3>
          <TextLinkV2 path={specLink} target="_blank" title={"View Specs"} />
        </div>
        <div>
          <div className={lineClass}>
            <div className={tooltipContainer}>
              <h3>Days of storage</h3>
              <div className={tooltipClass}>
                <StaticImage
                  src="../img/tooltip-icon.png"
                  className={tooltipIconClass}
                  alt="Days of Storage Tooltip"
                  placeholder="blurred"
                />
                <span className={tooltipTextClass}>
                  Storage days based on default camera settings.
                </span>
              </div>
            </div>
            <div className={cameraPriceClass}>{`$${info.prices[
              priceIndex
            ].value.toLocaleString()}`}</div>
          </div>
          <div className={cameraDaysClass}>
            {info.prices.map((p, index) => (
              <button
                key={p.days}
                className={
                  index === priceIndex
                    ? circleButtonSelectedClass
                    : circleButtonUnselectedClass
                }
                onClick={() => setPriceIndex(index)}
              >
                {p.days}
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className={licenseTypeContainerClass}>
            <div className={lineClass}>
              <div className={tooltipContainer}>
                <h3>License</h3>
                <div className={tooltipClass}>
                  <StaticImage
                    src="../img/tooltip-icon.png"
                    className={tooltipIconClass}
                    alt="License Type Tooltip"
                    placeholder="blurred"
                  />
                  <span className={typeTooltipText}>
                    AI-features and cloud archiving not included in the
                    Professional License. For a detailed comparison, visit our{" "}
                    <TextLink to="/license-comparison/">
                      License Comparison
                    </TextLink>{" "}
                    page.
                  </span>
                </div>
              </div>
              <div className={cameraPriceClass}>{`$${licensePricingForCameras[
                licenseType
              ]?.prices[licensePriceIndex]?.price.toLocaleString()}`}</div>
            </div>
            <div className={licenseTypeButtonsClass}>
              {licensePricingForCameras.map((p, index) => (
                <button
                  onClick={() => setLicenseType(index)}
                  key={index}
                  className={
                    licenseType === index
                      ? typeButtonSelectedClass
                      : typeButtonUnselectedClass
                  }
                >
                  {p.name}
                </button>
              ))}
            </div>
          </div>
          <div className={yearsContainerClass}>
            <h3>Years</h3>
            <div className={yearsButtonsClass}>
              {licensePricingForCameras[licenseType]?.prices.map((p, index) => (
                <button
                  onClick={() => setLicensePriceIndex(index)}
                  key={index}
                  className={
                    licensePriceIndex === index
                      ? circleButtonSelectedClass
                      : circleButtonUnselectedClass
                  }
                >
                  {p.term}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className={lineClass}>
            <h3 className="heavy">Total Cost</h3>
            <h3>{`$${(
              info.prices[priceIndex].value +
              licensePricingForCameras[licenseType]?.prices[licensePriceIndex]
                .price
            ).toLocaleString()} `}</h3>
          </div>
          <div className={lineClass}>
            <p className={smallFontClass}>Includes hardware and license</p>
            <p className={smallFontClass}>
              <strong>(MSRP USD)</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
