import React from "react";

const IconAiInsights = ({ size = "44", stroke = "var(--white)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
    >
      <rect width="44" height="44" rx="22" fill="#17323B" />
      <rect
        x="13.4289"
        y="24.1437"
        width="3.42857"
        height="10.2857"
        stroke={stroke}
        stroke-width="1.14286"
      />
      <rect
        x="28.8576"
        y="13.8566"
        width="3.42857"
        height="20.5714"
        stroke={stroke}
        stroke-width="1.14286"
      />
      <rect
        x="21.4284"
        y="19.5714"
        width="3.42857"
        height="14.8571"
        stroke={stroke}
        stroke-width="1.14286"
      />
      <path
        d="M11.5358 16.4431C13.0883 15.8835 14.3108 14.661 14.8704 13.1085C15.43 14.661 16.6525 15.8836 18.2049 16.4431C16.6525 17.0027 15.4298 18.2252 14.8704 19.7776C14.3108 18.2252 13.0883 17.0027 11.5358 16.4431Z"
        stroke={stroke}
        stroke-width="1.20968"
      />
      <path
        d="M17.9494 9.46697C18.5499 9.17872 19.0347 8.6939 19.323 8.09341C19.6113 8.6939 20.0961 9.17872 20.6966 9.46697C20.0961 9.75523 19.6113 10.24 19.323 10.8405C19.0347 10.24 18.5499 9.75523 17.9494 9.46697Z"
        stroke={stroke}
        stroke-width="0.857143"
      />
      <path
        d="M21.9499 13.4666C22.5504 13.1783 23.0352 12.6935 23.3235 12.093C23.6117 12.6935 24.0966 13.1783 24.6971 13.4666C24.0966 13.7548 23.6117 14.2396 23.3235 14.8401C23.0352 14.2396 22.5504 13.7548 21.9499 13.4666Z"
        stroke={stroke}
        stroke-width="0.857143"
      />
    </svg>
  );
};

export default IconAiInsights;
