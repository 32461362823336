import RhombusButton from "components/common/RhombusButton";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  Title,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const PlatformSectionInner = styled(SectionInner)`
  flex-direction: column;
  gap: 5rem;
`;

const StyledRow = styled.div`
  display: flex;
  gap: 2.5rem;

  > div {
    width: 50%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    display: flex;
    @media (max-width: 820px) {
      width: 100%;
      justify-content: center;
    }
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;
    > div:first-of-type {
      /* justify-content: flex-end; */
    }
  }

  &:nth-of-type(odd) {
    > div:last-of-type {
      /* justify-content: flex-end; */
    }
  }

  @media (max-width: 820px) {
    flex-direction: column-reverse !important;
    text-align: center;
    /* > div {
      justify-content: center !important;
    } */
  }
`;

const imageStyles = css`
  height: 100%;
  width: 100%;
  max-width: 580px;
`;

const ROWS = [
  {
    title: "Smart Cameras",
    subTitle: "Protect What Matters Most with Powerful Camera Solutions",
    description: `The Rhombus suite of dome, fisheye, and bullet cameras deliver exceptional performance and reliability for businesses of all sizes. Capture, detect, and manage security events from a single pane of glass.`,
  },
  {
    title: "AI Analytics",
    subTitle: "Stay in the Know with Real-Time, AI-Powered Smart Alerts",
    description: `Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.`,
  },
  {
    title: "Access Control",
    subTitle: "Unify Building Management Like Never Before",
    description: `Pair seamlessly with the Rhombus Platform for all-in-one security at scale. Manage all aspects of your spaces from a single pane of glass to streamline operations, eliminate complexity, and improve safety.`,
  },
  {
    title: "Alarm Monitoring",
    subTitle:
      "Protect Your Spaces Around the Clock with Professional Monitoring",
    description: `Smart alarms, real agents. Protect your buildings 24/7/365 with modern alarm monitoring that combines automatic security alerts with live monitoring services.`,
  },
  {
    title: "Environmental Monitoring",
    subTitle: "Create Healthier Spaces and Manage Climate-Controlled Areas",
    description: `Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with real-time alerts and synchronized video.`,
  },
];

export default function AllInOnePlatformSection() {
  const { isMobile } = useIsMobile(820);
  const renderContent = data => {
    const IMAGES = [
      data.image1,
      data.image2,
      data.image3,
      data.image4,
      data.image5,
    ];
    return (
      <SectionContainer id="platform" style={{ overflow: "visible" }}>
        <PlatformSectionInner>
          <Title color="var(--blue-800)" style={{ textAlign: "center" }}>
            An All-in-One Platform
          </Title>
          {ROWS.map((row, index) => {
            const { title, subTitle, description } = row;
            const image = IMAGES[index];
            return (
              <StyledRow>
                <div>
                  <TextContainer width="580px">
                    <TitleMed color="var(--blue-800)">{title}</TitleMed>
                    <TitleSmall
                      fontSize="1.25rem"
                      style={{ fontWeight: "500" }}
                    >
                      {subTitle}
                    </TitleSmall>
                    <MainParagraph style={{ margin: "0.75rem 0" }}>
                      {description}
                    </MainParagraph>
                    {!isMobile && (
                      <RhombusButton
                        title="Get Started"
                        type="landing-v3"
                        path="/lp/cta-1/"
                      />
                    )}
                  </TextContainer>
                </div>
                <div>
                  <GatsbyImage
                    image={getImage(image)}
                    alt={title}
                    objectFit="contain"
                    objectPosition="center"
                    className={imageStyles}
                  />
                </div>
              </StyledRow>
            );
          })}
        </PlatformSectionInner>
      </SectionContainer>
    );
  };
  const GET_IMAGES = graphql`
    query {
      image1: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/smart-cameras.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      image2: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/ai-analytics.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      image3: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/access-control.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      image4: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/frame-5.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      image5: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/enviromental.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
