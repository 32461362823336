import RhombusButton from "components/common/RhombusButton";
import SlideInContainer from "components/common/SlideInContainer";
import PageContent from "components/page/PageContent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import React from "react";
import { TitleMed } from "components/rui/typography";

const noManualModels = ["Secure Card", "Secure Fob"];

const contentContainer = css`
  display: flex;
  flex-direction: column;
  padding: 74px 0px 70px 0px;

  @media (max-width: 700px) {
    padding: 35px 0px;
  }
`;

const titleClass = css`
  display: inline-block;
  margin-bottom: 76px;

  @media (max-width: 700px) {
    text-align: center;
    width: 100%;
  }
`;

const columnsContainer = css`
  display: flex;
  justify-content: space-between;
  gap: 75px;

  @media (max-width: 1000px) {
    gap: 25px;
  }

  @media (max-width: 700px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: unset;
  }
`;

const textColumnContainer = css`
  width: 60%;
  display: flex;
  justify-content: space-between;
  gap: 75px;

  @media (max-width: 1000px) {
    gap: 25px;
  }

  @media (max-width: 700px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: unset;
  }
`;

const columnStyle = css`
  width: 50%;
  max-width: 329px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    width: 100%;
    align-items: unset;
    max-width: 512.5px;
  }

  > div {
    width: 100%;
    margin: 0 auto;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
`;

const column3 = cx(
  columnStyle,
  css`
    width: 30%;

    @media (max-width: 700px) {
      width: 100%;
      max-width: 254px;
    }
  `
);

const textContainer = css`
  margin-bottom: 28px;
`;

const boldText = css`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const descriptionText = css`
  margin-bottom: 0.5rem;
`;

const dimensionsContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
`;

const imageStyle = css`
  margin-bottom: 2.5rem;
  pointer-events: none;
`;

const buttonContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  font-size: 14px;
  width: 100%;
  a {
    width: 90%;
  }
  @media (max-width: 700px) {
    justify-content: center;
    padding: 10px 0px;
    display: none;
  }
`;

export default function AccessControlSpecs({ model }) {
  const imageRef = getImage(model.image);

  return (
    <section>
      <PageContent>
        <div className={contentContainer}>
          <SlideInContainer>
            <TitleMed className={titleClass}>
              {model.model} Specifications
            </TitleMed>
          </SlideInContainer>
          <div className={columnsContainer}>
            <div className={textColumnContainer}>
              <div className={columnStyle}>
                {model.column1.map((item) => {
                  return (
                    <SlideInContainer key={item.product}>
                      <div className={textContainer}>
                        <p className={boldText}>{item.title}</p>
                        <p className={descriptionText}>{item.text}</p>
                      </div>
                    </SlideInContainer>
                  );
                })}
              </div>
              <div className={columnStyle}>
                {!!model.column2 && (
                  <>
                    {model.column2.map((item) => {
                      return (
                        <SlideInContainer key={item.title}>
                          <div className={textContainer}>
                            <p className={boldText}>{item.title}</p>
                            {Array.isArray(item.text) ? (
                              item.text.map((line) => {
                                return (
                                  <p className={descriptionText}>{line}</p>
                                );
                              })
                            ) : (
                              <p className={descriptionText}>{item.text}</p>
                            )}
                          </div>
                        </SlideInContainer>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className={column3}>
              <SlideInContainer>
                <div className={dimensionsContainer}>
                  <GatsbyImage
                    image={imageRef}
                    className={imageStyle}
                    alt={model?.dimsAlt ?? `${model.title} with dimensions`}
                    style={{ maxHeight: "301px" }}
                    objectFit="contain"
                    objectPosition="center"
                  />
                  <div className={buttonContainer}>
                    <RhombusButton
                      title="Download Specs"
                      path={model.pdf}
                      type="primary-dark"
                      target="_blank"
                      useHref
                    />
                    {!noManualModels.includes(model.model) && (
                      <RhombusButton
                        title="User Manual"
                        path={model.manual}
                        type="secondary"
                        target="_blank"
                        useHref
                      />
                    )}
                  </div>
                </div>
              </SlideInContainer>
            </div>
          </div>
        </div>
      </PageContent>
    </section>
  );
}
