import PageContent from "components/page/PageContent";
import { css } from "@linaria/core";
import React from "react";
import Link from "components/common/Link";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import SlideInContainer from "components/common/SlideInContainer";
import RhombusButton from "components/common/RhombusButton";
import TextLinkV2 from "components/common/TextLinkV2";
import { Body, SectionTitle, TitleSmall } from "components/rui/typography";

const sectionClass = css`
  padding: 5rem 1.25rem;
`;

const contentClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const titleContainer = css`
  text-align: center;
  margin-bottom: 35px;
  @media (max-width: 700px) {
    margin: 0 auto 10px auto;
  }
  div {
    width: 100%;
  }

  h2 {
    margin-bottom: 0.5rem;
  }
`;

const topLinkClass = css`
  font-size: 14px;
  margin-bottom: 74.32px;

  @media (max-width: 700px) {
    font-size: 14px;
    margin-bottom: 36px;
  }
`;

const cardContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 70px;

  @media (max-width: 1330px) {
    column-gap: 45px;
  }

  @media (max-width: 700px) {
    row-gap: 35px;
  }

  > div {
    width: 45%;
    margin: 0 auto;

    @media (max-width: 950px) {
      width: 100%;
    }
  }
`;

const linkContainerClass = css`
  text-decoration: none;
  color: rgba(43, 46, 48, 0.99);
  display: flex;
  justify-content: center;

  @media (max-width: 700px) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const cardClass = css`
  display: block;
  background: white;
  border-radius: 14px;
  padding: 62px 13px 62px 64px;
  width: 614px;
  height: 236px;
  background: var(--nuetral-100);

  @media (max-width: 1330px) {
    padding: 21px 13px 18.5px 32px;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: fit-content;
  }
`;

const cardContentClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 70px;

  @media (max-width: 1330px) {
    gap: 34.5px;
  }
  @media (max-width: 950px) {
    gap: 70px;
  }
  @media (max-width: 700px) {
    gap: 34.5px;
  }

  h3 {
    font-size: 18px;
    line-height: 25.18px;
    margin-bottom: 8px;

    @media (max-width: 700px) {
      font-size: 14px;
      margin-bottom: unset;
    }
  }
  p {
    font-size: 16px;
    line-height: 2;

    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

const textContainer = css`
  max-width: 445px;
`;

const imageClass = css`
  pointer-events: none;

  @media (max-width: 700px) {
    width: 25%;
  }
`;

export default function ExporeCameraModels({ title, subtitle, cameraCards }) {
  return (
    <section className={sectionClass}>
      <PageContent>
        <div className={contentClass}>
          <div className={titleContainer}>
            <SectionTitle>{title}</SectionTitle>
            <Body>{subtitle}</Body>
          </div>
          <RhombusButton
            title="Compare Cameras"
            className={topLinkClass}
            path="/cameras/compare-cameras/"
            type="primary-dark"
          />
          <div className={cardContainer}>
            {cameraCards.map((card, index) => {
              const images = withArtDirection(getImage(card.image), [
                {
                  media: "(max-width: 700px)",
                  image: getImage(card.mobileImage),
                },
              ]);
              return (
                <SlideInContainer>
                  <Link
                    to={card.link}
                    className={linkContainerClass}
                    key={card.model}
                  >
                    <div key={index} className={cardClass}>
                      <div className={cardContentClass}>
                        <GatsbyImage
                          image={images}
                          style={{ maxWidth: card.width }}
                          alt={card.alt}
                          className={imageClass}
                        />
                        <div className={textContainer}>
                          <TitleSmall>{card.model}</TitleSmall>
                          <Body>{card.useCase}</Body>
                          <TextLinkV2 title={"Learn More"} path={card.link} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </SlideInContainer>
              );
            })}
          </div>
        </div>
      </PageContent>
    </section>
  );
}
