import React from "react";

const IconLock = () => {
  return (
    <svg
      width="38"
      height="52"
      viewBox="0 0 38 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.14 50.0007H7.332C4.398 50.0007 2 47.6007 2 44.6687V25.5527C2 22.6187 4.398 20.2207 7.332 20.2207H31.14C34.072 20.2207 36.472 22.6187 36.472 25.5527V44.6687C36.472 47.6007 34.072 50.0007 31.14 50.0007Z"
        stroke="#00C1DE"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M27.0581 20.22V7.332C27.0581 4.4 24.6581 2 21.7261 2H16.7461C13.8141 2 11.4141 4.4 11.4141 7.332V20.22"
        stroke="#00C1DE"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.559 31.9045C23.559 29.5285 21.633 27.6045 19.259 27.6045C16.883 27.6045 14.957 29.5285 14.957 31.9045C14.957 33.2805 15.607 34.5045 16.613 35.2905V38.3825C16.613 39.8365 17.803 41.0265 19.259 41.0265C20.713 41.0265 21.903 39.8365 21.903 38.3825V35.2905C22.909 34.5045 23.559 33.2805 23.559 31.9045Z"
        stroke="#00C1DE"
        stroke-width="3"
      />
    </svg>
  );
};

export default IconLock;
