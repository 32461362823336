import { useState, useEffect, useCallback } from "react";

const useScrollDetection = (scrollThreshold = 0) => {
  const [scrollActive, setScrollActive] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY !== scrollThreshold) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  }, [setScrollActive]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return scrollActive;
};

export default useScrollDetection;
