import { SectionContainer } from "components/rhombus-UI/theme/containers";
import { TitleSmall } from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const SliderWrapper = styled(SectionContainer)`
  padding: 0;
  background: transparent;
  flex-shrink: 0;
`;

const sectionInner = css`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
`;

const carouselWrapper = css`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  -webkit-mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
  mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
`;
const carouselInner = css`
  width: max-content;
  display: flex;
  animation: scroll 40s linear infinite;
  gap: 9px;
  @keyframes scroll {
    to {
      transform: translate(calc(-33.3% - 4.5px));
    }
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

const logoStyles = css`
  width: 4rem;
  transform: scale(0.9);
`;

export default function G2BadegCarousel({ dark = false }) {
  const renderContent = (data) => {
    const BADGE_SET = [
      data.badge1,
      data.badge2,
      data.badge3,
      data.badge4,
      data.badge5,
      data.badge6,
      data.badge7,
      data.badge8,
      data.badge9,
      data.badge10,
      data.badge11,
    ];
    const BADGE_SET_DARK = [
      data.badgeDark1,
      data.badgeDark2,
      data.badgeDark3,
      data.badgeDark4,
      data.badgeDark5,
      data.badgeDark6,
      data.badgeDark7,
      data.badgeDark8,
      data.badgeDark9,
      data.badgeDark10,
      data.badgeDark11,
    ];

    const badgesToRender = dark ? BADGE_SET_DARK : BADGE_SET;

    const badgeRow = [...badgesToRender, ...badgesToRender, ...badgesToRender];

    return (
      <SliderWrapper>
        <div className={sectionInner}>
          <TitleSmall
            color={dark ? "var(--primary-bg)" : "var(--white)"}
            style={{ fontSize: "15px" }}
          >
            #1 in Physical Security on G2
          </TitleSmall>
          <div className={carouselWrapper}>
            <div
              className={carouselInner}
              style={{ "--width": badgeRow.length }}
            >
              {badgeRow.map((logo, index) => (
                <CarouselItem key={index}>
                  <GatsbyImage
                    image={getImage(logo)}
                    alt=""
                    objectPosition="center"
                    className={logoStyles}
                    loading="eager"
                    objectFit="contain"
                  />
                </CarouselItem>
              ))}
            </div>
          </div>
        </div>
      </SliderWrapper>
    );
  };
  const GET_LOGOS = graphql`
    query {
      badge1: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-1-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge2: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-2-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge3: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-3-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge4: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-4-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge5: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-5-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge6: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-6-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge7: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-7-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge8: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-8-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge9: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-9-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge10: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-10-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge11: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-11-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge12: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-12-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge13: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-13-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge14: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-14-light.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark1: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-1-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark2: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-2-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark3: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-3-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark4: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-4-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark5: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-5-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark6: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-6-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark7: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-7-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark8: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-8-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark9: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-9-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark10: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-10-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark11: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-11-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark12: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-12-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark13: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-13-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badgeDark14: file(
        relativePath: {
          eq: "components/common/infinite-carousels/assets/G2/badge-14-dark.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_LOGOS} render={renderContent} />;
}
