import { styled } from "@linaria/react";
import { Link } from "gatsby";
import React from "react";
import { SUB_FOOTER_LINKS } from "./constants";
import { handlePhoneLink } from "./util";

const SubFooterContainer = styled.div`
  background-color: var(--primary-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;

  span,
  a,
  button {
    font-size: 13px;
    color: var(--body-subtle);
  }
  button {
    background: transparent;
  }
`;

const FooterInner = styled.div`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  justify-content: space-between;
  background:
    linear-gradient(var(--primary-bg), var(--primary-bg)) padding-box,
    linear-gradient(to right, var(--secondary), var(--secondary)) border-box;
  border-top: 1px solid transparent;
  padding: 24px 0;
  flex-wrap: wrap;
  gap: 2.5rem;
  @media (max-width: 600px) {
    text-align: center;
    justify-content: center;
  }
`;

const SubFooterNav = styled.nav`
  display: flex;
  gap: 2.5rem;
  width: fit-content;
  a {
    text-decoration: none;
  }
  flex-wrap: wrap;
`;

const SubFooter = () => {
  return (
    <SubFooterContainer>
      <FooterInner>
        <SubFooterNav>
          <Link to={handlePhoneLink().link}>{handlePhoneLink().title}</Link>
          {SUB_FOOTER_LINKS.map((link) => {
            if (link.onClick) {
              return <button onClick={link.onClick}>{link.title}</button>;
            } else
              return (
                <Link to={link.path} target="_blank">
                  {link.title}
                </Link>
              );
          })}
        </SubFooterNav>
        <span>© Rhombus Systems. All Rights Reserved.</span>
      </FooterInner>
    </SubFooterContainer>
  );
};

export default SubFooter;
