import { uniq } from "lodash";

export const getAllIndustriesInUse = (data) => {
  const categoryArr = [];
  data.forEach((item) => categoryArr.push(item.node.frontmatter.industry));
  const uniqueIndustries = uniq(categoryArr);
  return uniqueIndustries;
};

export const caseStudiesWithFeaturedPostsFirst = (data) => {
  const featured = data.filter((item) => item.node.frontmatter.featured);
  const withoutFeatured = data.filter(
    (item) => !item.node.frontmatter.featured
  );
  return [...featured, ...withoutFeatured];
};

export const isDateWithinLast7Days = (isoDate) => {
  const parsedDate = new Date(isoDate);
  const currentDate = new Date();
  const timeDifference = currentDate - parsedDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference >= 0 && daysDifference <= 6;
};
