import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const BannerContainer = styled.div`
  display: flex;
  height: 486px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 1200px) {
    flex-direction: row-reverse;
  }
  @media (max-width: 820px) {
    flex-direction: column;
    height: unset;
  }
`;

const BannerCol = styled.div`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  &:first-of-type,
  &:last-of-type {
    height: 100%;
  }
  @media (max-width: 1200px) {
    width: calc(100% / 2);
    &:first-of-type {
      display: none;
    }
  }
  @media (max-width: 820px) {
    width: 100%;

    &:first-of-type {
      display: block;
      img {
        object-position: 0 -100px;
      }
    }

    &:last-of-type {
      img {
        object-position: 0 100px;
      }
    }

    &:first-of-type,
    &:last-of-type {
      height: unset;
      max-width: 665px;
    }
  }
`;

const BannerText = styled(TextContainer)`
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.25rem;
  max-width: 500px;
`;

const imageClass = css`
  height: 100%;
`;

const IntegrationBanner = () => {
  return (
    <BannerContainer>
      <BannerCol>
        <StaticImage
          src="./assets/integration-1.png"
          className={imageClass}
          quality={100}
          loading={"lazy"}
        />
      </BannerCol>
      <BannerCol>
        <BannerText>
          <PreTitle>50+ Integrations</PreTitle>
          <FancyTitleMed>
            Seamlessly integrate into existing systems
          </FancyTitleMed>
          <MainParagraph>
            Create adaptable security solutions with over 50+ integrations that
            provide customizable functionality, better performance, and greater
            flexibility.
          </MainParagraph>
        </BannerText>
      </BannerCol>
      <BannerCol>
        <StaticImage
          src="./assets/integration-2.png"
          className={imageClass}
          quality={100}
          loading={"lazy"}
        />
      </BannerCol>
    </BannerContainer>
  );
};

export default IntegrationBanner;
