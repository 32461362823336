import IconAiAnalaytics from "components/common/icon-components/IconAiAnalaytics";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { TitleMed, TitleSmall } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const SpecCard = styled.div`
  display: flex;
  width: 100%;
  border-radius: 1.25rem;
  background: ${(props) =>
    props.isVideo ? "transparent" : "var(--nuetral-100)"};
  @media (max-width: 1024px) {
    flex-direction: column;
    overflow: hidden;
    background: var(--nuetral-100);
  }
`;

const ImageSection = styled.div`
  width: ${(props) => (props.isVideo ? "60%" : "50%")};
  display: ${(props) => (props.isVideo ? "flex" : "grid")};
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  @media (max-width: 1024px) {
    width: 100%;
    height: 30%;
  }
`;

const imageClass = css`
  background-color: var(--blue-900);
  padding: 20px;
  text-align: center;
`;

const SpecSection = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 2.5rem;
  padding-right: ${(props) => (props.isVideo ? 0 : "2.5rem")};
  gap: 36px;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 2.5rem;
  }
`;

const SpecList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
  justify-content: space-evenly;
`;

const SpecRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 16px;
  svg {
    flex-shrink: 0;
  }
`;

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 16/9;
  object-position: center;
  overflow: hidden;
  border-radius: 1.25rem;
  @media (max-width: 1024px) {
    aspect-ratio: unset;
    width: 100%;
    height: 100%;
    border-radius: unset;
    box-shadow: unset;
  }
`;

const CameraOveriewSpecs = ({
  videoSrc = null,
  images = [null, null, null, null],
  title = "Specs Title",
  subTitle = "Specs sub-title",
  specs = [
    {
      icon: <IconAiAnalaytics height="25" width="23" />,
      text: "AI Analytics included onboard for actionable insights ",
    },
  ],
}) => {
  return (
    <SectionContainer>
      <SectionInner>
        <SpecCard isVideo={!!videoSrc}>
          <ImageSection isVideo={!!videoSrc}>
            {videoSrc ? (
              <Video autoPlay loop muted playsInline>
                <source src={videoSrc} type="video/webm" />
              </Video>
            ) : (
              <>
                {images.map((image) => (
                  <GatsbyImage
                    image={getImage(image)}
                    className={imageClass}
                    objectFit="cover"
                  />
                ))}
              </>
            )}
          </ImageSection>
          <SpecSection isVideo={!!videoSrc}>
            <TextContainer style={{ gap: "12px" }}>
              <TitleMed>{title}</TitleMed>
              <TitleSmall style={{ fontSize: "20px" }}>{subTitle}</TitleSmall>
            </TextContainer>
            <SpecList>
              {specs.map((spec) => {
                const { icon, text } = spec;
                return (
                  <SpecRow>
                    {icon}
                    <span>{text}</span>
                  </SpecRow>
                );
              })}
            </SpecList>
          </SpecSection>
        </SpecCard>
      </SectionInner>
    </SectionContainer>
  );
};

export default CameraOveriewSpecs;
