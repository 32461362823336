import SlideInContainer from "components/common/SlideInContainer";
import { FancyTitle, TitleSmall } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import React from "react";

const containerClass = css`
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 5rem 0;
  max-width: var(--max-width);
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1440px) {
    padding: 5rem 1.25rem;
  }
  @media (max-width: 1175px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 600px) {
    padding: 5rem 1.25rem;
  }
`;

const containerReverse = cx(
  containerClass,
  css`
    flex-direction: row-reverse;
    @media (max-width: 1480px) {
      width: 100%;
    }

    @media (max-width: 1175px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 600px) {
      padding: 44px 0px 0px;
    }
  `
);

const leftContainer = css`
  width: 40%;
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

const learnClass = css`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 55px;
  h3 {
    text-transform: capitalize;
  }
`;

const learnContainer = css`
  padding: 25px 0px 75px 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 693px;
`;

const learnLineClass = css`
  display: flex;
  .gatsby-image-wrapper {
    overflow: visible;
  }
`;
const bulletClass = css`
  width: 25px;
  height: 25px;
  margin: 5px 25px 0px 0px;
  aspect-ratio: 1;
  overflow: visible;
`;

const speakerClass = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-top: 2.5rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const speakerCard = css`
  display: flex;
  gap: 1.25rem;
  max-width: 300px;
  h4,
  p {
    font-size: 14px;
    line-height: 150%;
  }
`;

const speakerImgClass = css`
  width: 95px !important;
  height: 95px !important;
  aspect-ratio: 1 !important;
  flex-shrink: 0 !important;
`;

const rightContainer = css`
  width: 60%;
  max-width: 693px;
  .about {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 1175px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 1110px) {
    padding: 0px 20px;
  }

  @media (max-width: 400px) {
    padding: 0 10px;
  }

  h1 {
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    margin-bottom: 31px;
  }
`;

const imageClass = css`
  min-height: 600px !important;
  max-height: 800px !important;
  border-radius: 1.25rem !important;
  position: sticky !important;
  top: 11rem;
  @media (max-width: 1175px) {
    display: none !important; //must be used to override gatsby image styling
  }
`;

const logoContainer = css`
  display: flex;
  gap: 2.5rem;
  margin-bottom: 2.5rem;
  flex-shrink: 0;
`;

const logoStyles = css`
  width: 164px;
  height: 62px;
`;

export default function WebinarAboutSection({
  about,
  learnLines,
  speakers,
  image,
  alt,
  learnTitle,
  color = "var(--white)",
  logos = null,
  reverse = false,
}) {
  const imageRef = getImage(image);

  return (
    <section style={{ background: color }}>
      <div className={reverse ? containerReverse : containerClass}>
        <div className={leftContainer}>
          <GatsbyImage
            image={imageRef}
            className={imageClass}
            alt={alt}
            objectPosition="right"
            objectFit="contain"
          />
        </div>
        <div className={rightContainer}>
          <div className={logoContainer}>
            {logos &&
              logos.map((logo) => (
                <GatsbyImage
                  image={getImage(logo)}
                  alt=""
                  className={logoStyles}
                  objectFit="contain"
                />
              ))}
          </div>
          <SlideInContainer>
            <FancyTitle style={{ textAlign: "left" }} className="about">
              About the Webinar
            </FancyTitle>
          </SlideInContainer>
          {about.map((item) => (
            <p className="about">{item}</p>
          ))}
          <div className={learnClass}>
            <SlideInContainer>
              <TitleSmall>{learnTitle}:</TitleSmall>
            </SlideInContainer>
            <div className={learnContainer}>
              {learnLines.map((line, i) => {
                return (
                  <SlideInContainer key={i}>
                    <div className={learnLineClass}>
                      <StaticImage
                        src="./img/check.png"
                        alt="checked"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>{line}</p>
                    </div>
                  </SlideInContainer>
                );
              })}
            </div>
          </div>
          <>
            <TitleSmall>Featured Speakers</TitleSmall>
            <div className={speakerClass}>
              {speakers.map((speaker) => {
                const imageRef = getImage(speaker.image);
                return (
                  <div key={speaker.name} className={speakerCard}>
                    <GatsbyImage
                      image={imageRef}
                      alt={speaker.name}
                      objectFit="contain"
                      className={speakerImgClass}
                    />
                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <h4>{speaker.name}</h4>
                      <p>{speaker.title}</p>
                      <p>
                        <b>{speaker.company}</b>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </div>
    </section>
  );
}
