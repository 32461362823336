import FAQSection from "components/common/FAQSection";
import { SectionContainer } from "components/rhombus-UI/theme/containers";
import React from "react";

import PricingFooterBanner from "./PricingFooterBanner";
import { graphql, useStaticQuery } from "gatsby";

const questions = [
  {
    question: "How do I get started with Rhombus?",
    answer: [
      "To get started, you must purchase a camera (one-time fee) and a license. It's as simple as that.",
    ],
  },
  {
    question: "Can I purchase just the cameras without a license?",
    answer: ["All of our cameras require an ongoing license to operate."],
  },
  {
    question: "How am I billed?",
    answer: [
      "Rhombus products are available from Authorized Rhombus Resellers who can offer a variety of flexible payment options. Please contact your Authorized Rhombus Reseller or the Rhombus sales team to learn how you can get started today.",
    ],
  },
  {
    question: "What does the license cover?",
    answer: [
      "Rhombus license covers the ongoing costs associated with providing a service that does not require DVRs, NVRs, or any of the associated equipment. As part of the license, the service is constantly being improved meaning that in a few months you'll receive more for your money than when you purchased the service. We're constantly adding new features as well as providing frequent firmware updates to patch known security vulnerabilities. You can read a more in depth description of what our subscription covers",
    ],
    link: {
      text: " Here",
      path: "/blog/what-does-the-rhombus-subscription-cover/",
    },
  },
  {
    question: "Do Rhombus cameras consume a lot of bandwidth?",
    answer: [
      "Not at all. Our cameras store all of the video locally on the cameras meaning they take virtually no bandwidth at all.",
    ],
    link: {
      text: " Article",
      path: "https://support.rhombussystems.com/hc/en-us/articles/360038029191-Understanding-Bandwidth-Ports-etc",
    },
  },
  {
    question: "Do Rhombus cameras have a warranty?",
    answer: ["Yes - all of our cameras come standard with a 10-year warranty."],
  },
];

export default function PricingFooter() {
  const data = useStaticQuery(graphql`
    query {
      faq: file(
        relativePath: { eq: "components/pricing/img/pricing-faq-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return (
    <>
      <SectionContainer
        style={{
          flexDirection: "column",
          paddingTop: 0,
          paddingBottom: 0,
          alignItems: "center",
        }}
      >
        <PricingFooterBanner />
      </SectionContainer>
      <FAQSection
        title="Pricing FAQs"
        QA={questions}
        image={data.faq}
        imageAlt={"Rhombus security camera mounted on a wall "}
        reverse
      />
    </>
  );
}
