export const capturePostHogEvent = event => {
  if (typeof posthog === "undefined") return;
  const currentUrl = window.location.href;
  posthog.capture(event, "$pageview", {
    $current_url: currentUrl,
  });
};

export const captureCTAButtonClick = () => {
  capturePostHogEvent("CTA_Button_Click");
};
