import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import RhombusButton from "../RhombusButton";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";

const TextBox = styled.div`
  width: fit-content;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const imageStyles = css`
  width: 100%;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 2.5rem;
`;

const MobileScrollSection = ({ rows, title, sectionId }) => {
  return (
    <SectionContainer id={sectionId}>
      <SectionInner
        style={{ flexDirection: "column", alignItems: "center", gap: "5rem" }}
      >
        <FancyTitle style={{ textAlign: "center" }}>{title}</FancyTitle>
        {rows.map(item => (
          <TextBox>
            <TextContainer
              style={{ width: "665px", maxWidth: "100%", gap: "1.25rem" }}
            >
              <GatsbyImage
                image={getImage(item.image)}
                alt={item.title}
                className={imageStyles}
                objectFit="cover"
              />
              <PreTitle color="var(--blue-500)">{item.preTitle}</PreTitle>
              <TitleMed>{item.title}</TitleMed>
              <MainParagraph
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </TextContainer>
            <RhombusButton
              title={CTA_BUTTON_COPY}
              type="primary"
              path="#top"
              style={{ marginTop: "1.25rem" }}
            />
          </TextBox>
        ))}
      </SectionInner>
    </SectionContainer>
  );
};

export default MobileScrollSection;
