import { styled } from "@linaria/react";

// Hero & Section Titles
export const HeroTitle = styled.h1`
  color: ${(props) => props.color ?? "var(--white)"};
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media (max-width: 900px) {
    font-size: 32px;
  }
`;

export const SectionPreTitle = styled.h3`
  color: ${(props) => props.color ?? "var(--primary-bg)"};
  text-align: center;
  font-family: var(--bodyFontFamily);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 3.2px;
  text-transform: uppercase;
`;

export const PreTitle = styled.h4`
  color: ${(props) => props.color ?? "var(--white)"};
  text-align: ${(props) => (props.center ? "center" : "unset")};
  font-family: var(--bodyFontFamily);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

export const SectionTitle = styled.h2`
  color: ${(props) => props.color ?? "var(--primary-bg)"};
  text-align: center;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  max-width: 960px;
  @media (max-width: 900px) {
    font-size: 28px;
  }
`;

// Generics
export const TitleLarge = styled.h2`
  color: ${(props) => (props.light ? "var(--white)" : "var(--primary-bg)")};
  text-align: ${(props) => (props.center ? "center" : "unset")};
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media (max-width: 900px) {
    font-size: 28px;
  }
`;

export const TitleMed = styled.h3`
  color: ${(props) => (props.light ? "var(--white)" : "var(--primary-bg)")};
  text-align: ${(props) => (props.center ? "center" : "unset")};
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

export const TitleSmall = styled.h4`
  color: ${(props) => (props.light ? "var(--white)" : "var(--primary-bg)")};
  text-align: ${(props) => (props.center ? "center" : "unset")};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const Body = styled.p`
  color: ${({ light, subtle }) =>
    light
      ? "var(--white)"
      : subtle
        ? "var(--body-subtle)"
        : "var(--primary-bg)"};
  text-align: ${(props) => (props.center ? "center" : "unset")};
  font-weight: ${(props) => (props.bold ? 600 : 300)};
  font-size: ${(props) => (props.large ? "20px" : "18px")};
  line-height: 150%;
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;
