import { toTitleCase } from "components/common/util/helperFunctions";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  FancyPageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "@linaria/core";
import React from "react";

const HeaderWrapper = css`
  background-image: var(--gradient-base);
  display: flex;
  justify-content: center;
  padding: 5rem 2.5rem;
  overflow: hidden;
  position: relative;
  min-height: 90vh;
  @media (max-width: 600px) {
    padding: 0;
    min-height: 550px;
    margin-top: 0 !important;
  }
`;

const HeaderWrapperLight = cx(
  HeaderWrapper,
  css`
    background: var(--gradient-light);
  `
);

const HeaderInner = css`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  @media (max-width: 1175px) {
    flex-direction: column;
  }
`;

const HeaderLeft = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 30px;
  @media (max-width: 1440px) {
    justify-content: flex-start;
  }

  @media (max-width: 1175px) {
    padding: 0;
    justify-content: center;
  }
`;

const HeaderRight = css`
  display: flex;
  width: 50%;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 2000px) {
    justify-content: flex-start;
  }
  @media (max-width: 1175px) {
    display: none;
  }
`;

const ImageClass = css`
  // using important tag due to gatsby placeholder not preserving styles
  width: var(--width) !important;
  height: var(--height) !important;
  margin-right: -50% !important;
  @media (max-width: 1175px) {
    display: none;
  }
`;

const formTextWrap = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  width: 100%;
  max-width: 665px;
  z-index: 1;
  @media (max-width: 600px) {
    justify-content: flex-start;
  }
`;

const TextContainer = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    max-width: 617px;
  }
  @media (max-width: 600px) {
    padding: 4rem 20px 0 20px;
  }
`;

const mobileImage = css`
  display: none;
  @media (max-width: 600px) {
    margin-bottom: -60px;
    z-index: 0;
    display: block;
  }
`;

const dateRow = css`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-weight: 800;
  }
`;

const iconStyles = css`
  height: 25px !important;
  aspect-ratio: 1 !important;
`;

const WebinarHero2 = ({ data }) => {
  const { isMobile } = useIsMobile(1175);

  return (
    <section className={HeaderWrapperLight}>
      <div className={HeaderInner}>
        <div className={HeaderLeft}>
          <div className={formTextWrap}>
            <div
              className={TextContainer}
              style={{ color: "var(--nuetral-900)" }}
            >
              <PreTitle>{data.preTitle}</PreTitle>
              <FancyPageTitle style={{ maxWidth: "665px" }}>
                {toTitleCase(data.title)}
              </FancyPageTitle>
              {data.date ? (
                <div className={dateRow}>
                  <StaticImage
                    src="../icons/time.png"
                    alt="time"
                    placeholder="blurred"
                    loading="eager"
                    objectFit="contain"
                    className={iconStyles}
                  />
                  <p>{data.date}</p>
                </div>
              ) : (
                <></>
              )}

              {isMobile ? (
                <GatsbyImage
                  image={getImage(data.image)}
                  loading="eager"
                  alt={data.title}
                  className={mobileImage}
                />
              ) : (
                <></>
              )}
            </div>
            <FormWrapper
              width={isMobile ? "100%" : "550px"}
              title={data.formTitle}
            >
              <RhombusForm formId={data.formId} />
            </FormWrapper>
          </div>
        </div>
        <div className={HeaderRight}>
          <GatsbyImage
            image={getImage(data.image)}
            loading="eager"
            alt={data.title}
            objectFit="contain"
            className={ImageClass}
            style={{ "--width": "951.185px", "--height": "599.535px" }}
          />
        </div>
      </div>
    </section>
  );
};

export default WebinarHero2;
