import BackLink from "components/common/BackLink";
import { FORM_IDS } from "components/data/landing-page-data";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import GlobalLayout from "components/page/GlobalLayout";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import { styled } from "@linaria/react";
import React from "react";

const PageContainer = styled(SectionContainer)`
  background-color: var(--blue-900);
  height: calc(100vh - 80px);
  min-height: 700px;
  @media (max-width: 1024px) {
    height: fit-content;
    min-height: 100vh;
  }
`;

const CenterTextContainer = styled(TextContainer)`
  max-width: 576px;
  text-align: center;
  @media (max-width: 600px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default function FormOnlyPage() {
  const backLink = "/lp/surveillance-c/";
  return (
    <GlobalLayout
      landingPage
      formOnly
      dark
      color="var(--blue-800)"
      logoLink={backLink}
    >
      <PageContainer style={{ backgroundColor: "var(--blue-800)" }}>
        <SectionInner style={{ justifyContent: "center" }}>
          <FormContainer>
            <CenterTextContainer>
              <PageTitle color="var(--white)">See Rhombus in action</PageTitle>
              <MainParagraph color="var(--white)">
                Schedule a 30-minute product demo with expert Q&A.
              </MainParagraph>
            </CenterTextContainer>
            <FormWrapper darkMode>
              <RhombusForm
                formId={FORM_IDS.LIVE_DEMO}
                useSecondaryButton
                darkMode
              />
            </FormWrapper>
            <BackLink path={backLink} text="Back" color="var(--white)" />
          </FormContainer>
        </SectionInner>
      </PageContainer>
    </GlobalLayout>
  );
}
