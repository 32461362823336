import { toTitleCase } from "components/common/util/helperFunctions";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "@linaria/core";
import React from "react";

import RhombusButton from "../RhombusButton";
import { Body, HeroTitle, PreTitle } from "components/rui/typography";

const HeaderWrapper = css`
  background-image: var(--gradient-base);
  display: flex;
  justify-content: center;
  padding: 5rem 2.5rem;
  overflow: hidden;
  position: relative;
  min-height: 740px;
  @media (max-width: 600px) {
    padding: 0;
    min-height: 550px;
    margin-top: 0 !important;
  }
`;

const HeaderWrapperLight = cx(
  HeaderWrapper,
  css`
    background: var(--gradient-light);
  `
);

const HeaderInner = css`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  @media (max-width: 1175px) {
    flex-direction: column;
  }
`;

const HeaderLeft = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 30px;
  @media (max-width: 1440px) {
    justify-content: flex-start;
  }

  @media (max-width: 1175px) {
    padding: 0;
    justify-content: center;
  }
`;

const HeaderRight = css`
  display: flex;
  width: 50%;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  /* overflow: hidden; */
  @media (min-width: 2000px) {
    justify-content: flex-start;
  }
  @media (max-width: 1175px) {
    display: none;
  }
`;

const ImageClass = css`
  // using important tag due to gatsby placeholder not preserving styles
  width: var(--width) !important;
  height: var(--height) !important;
  margin-right: -50% !important;
  @media (max-width: 1175px) {
    display: none;
  }
  @media (min-width: 2000px) {
    margin-right: 0 !important;
  }
`;

const backgroundImage = css`
  // using important tag due to gatsby placeholder not preserving styles
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  left: 0;
  right: 0;
`;

const formTextWrap = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  z-index: 1;
  @media (max-width: 600px) {
    justify-content: flex-start;
  }
`;

const TextContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  p {
    max-width: 617px;
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  @media (max-width: 600px) {
    padding: 4rem 20px 0 20px;
  }
`;

const title = css`
  font-weight: 900;
  max-width: 665px;
  @media (min-width: 1175px) {
    font-size: 48px;
    line-height: 1.2;
  }
`;
const titleFancy = cx(
  title,
  css`
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  `
);

const mobileImage = css`
  display: none;
  @media (max-width: 600px) {
    margin-bottom: -60px;
    z-index: 0;
    display: block;
  }
`;

const HeroLayout2 = ({
  data,
  theme = "light",
  formTitle = "Request Demo",
  cover = false,
  width = "962px",
  height = "694px",
  logos = false,
  buttonText = null,
  useCustomCase = false,
}) => {
  const { isMobile } = useIsMobile(1175);
  const dark = theme === "dark";

  const title = useCustomCase ? data.title : toTitleCase(data.title);

  return (
    <section
      className={dark ? HeaderWrapper : HeaderWrapperLight}
      id="landing-page-header"
      style={
        logos
          ? { paddingBottom: 0, marginTop: "-4rem" }
          : cover
            ? { marginTop: "-80px", paddingTop: "10rem" }
            : {}
      }
    >
      {cover && (
        <GatsbyImage
          image={getImage(data.image)}
          loading="eager"
          alt={data.title}
          className={backgroundImage}
          objectFit="cover"
        />
      )}
      <div className={HeaderInner}>
        <div className={HeaderLeft}>
          <div className={formTextWrap}>
            <div
              className={TextContainer}
              style={
                dark
                  ? { color: "var(--white)" }
                  : { color: "var(--nuetral-900)" }
              }
            >
              {data.preTitle && (
                <PreTitle color={dark ? "var(--white)" : "var(--primary-bg)"}>
                  {data.preTitle}
                </PreTitle>
              )}
              <HeroTitle color={dark ? "var(--white)" : "var(--primary-bg)"}>
                {title}
              </HeroTitle>
              {data.titleInfo && (
                <ul>
                  {data.titleInfo.map((info) => (
                    <li>
                      <strong>{info}</strong>
                    </li>
                  ))}
                </ul>
              )}
              <Body>{data.paragraph}</Body>
              {!data.formId && (
                <RhombusButton type="landing" title="Get Quote" />
              )}
              {isMobile && !cover ? (
                <GatsbyImage
                  image={getImage(data.mobileImage)}
                  loading="eager"
                  alt={data.title}
                  className={mobileImage}
                />
              ) : (
                <></>
              )}
            </div>

            {data.formId && (
              <FormWrapper
                width={isMobile ? "100%" : "549px"}
                title={formTitle}
              >
                <RhombusForm
                  formId={data.formId}
                  customSubmitText={buttonText}
                />
              </FormWrapper>
            )}
          </div>
        </div>
        <div className={HeaderRight}>
          {!cover && (
            <GatsbyImage
              image={getImage(data.image)}
              loading="eager"
              alt={data.title}
              objectFit="contain"
              className={ImageClass}
              style={{ "--width": width, "--height": height }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroLayout2;
