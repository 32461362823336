import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import {
  cardContainerClass,
  disclaimer,
  productSectionContainer,
} from "../styles/pricingStyles";
import AccessControlCard from "./AccessControlCard";
import accessControlData from "./accessControlData";
import { Body, TitleMed } from "components/rui/typography";

export default function AccessControlSection() {
  const data = useStaticQuery(graphql`
    query {
      dr40: file(relativePath: { eq: "components/pricing/img/dr40-min.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      dr20: file(relativePath: { eq: "components/pricing/img/dr20-min.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      dc20: file(relativePath: { eq: "components/pricing/img/dc20-min.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      secureCard: file(
        relativePath: { eq: "components/pricing/img/secure-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, height: 75)
        }
      }
      secureFob: file(
        relativePath: { eq: "components/pricing/img/secure-fob-pricing.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, height: 75)
        }
      }
    }
  `);

  const { doorReaders, secureCard, secureFob } = accessControlData(data);
  return (
    <div>
      <div className={productSectionContainer}>
        <span className={disclaimer}>
          <Body subtle>Access Control requires DC20 and door licenses.</Body>
        </span>
        <TitleMed>Door Readers and Controllers</TitleMed>

        <div
          className={cardContainerClass}
          style={{ justifyContent: "center" }}
        >
          {doorReaders.map((item) => (
            <AccessControlCard info={item} />
          ))}
        </div>
      </div>
      <div className={productSectionContainer}>
        <TitleMed>Secure Cards & Fobs</TitleMed>
        <div
          className={cardContainerClass}
          style={{ justifyContent: "center" }}
        >
          <AccessControlCard info={secureCard} />
          <AccessControlCard info={secureFob} />
        </div>
      </div>
    </div>
  );
}
