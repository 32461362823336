import React from "react";
import GlobalLayout from "../components/page/GlobalLayout";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import ExperienceCards from "components/thank-you/ExperienceCards";
import {
  FancyPageTitle,
  FancyTextLink,
} from "components/rhombus-UI/theme/typography";
import { css } from "@linaria/core";
import Vector from "components/common/img/404-2.svg";
import TrialBanner from "components/common/footer-banners/TrialBanner";

const notFoundText = css`
  font-weight: 400;
  font-size: 24px;
  color: var(--blue-900);
  display: inline-block;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const vectorStyles = css`
  width: 100%;
`;

export default function PageNotFound() {
  return (
    <GlobalLayout>
      <SectionContainer>
        <SectionInner>
          <SectionCol>
            <TextContainer style={{ gap: "4rem", justifyContent: "center" }}>
              <FancyPageTitle>404 - Page Not Found!</FancyPageTitle>
              <p className={notFoundText}>
                Oof, it looks like we can’t find that page. Try checking{" "}
                <FancyTextLink href="/" style={{ display: "inline-block" }}>
                  rhombus.com
                </FancyTextLink>{" "}
                or one of the links below.
              </p>
            </TextContainer>
          </SectionCol>
          <SectionCol>
            <img src={Vector} className={vectorStyles} alt="404 Image" />
          </SectionCol>
        </SectionInner>
      </SectionContainer>
      <ExperienceCards />
      <TrialBanner />
    </GlobalLayout>
  );
}
