import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useCompatibleVideo from "hooks/useCompatibleVideo";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const SpecContainer = styled.div`
  width: 100%;
  background-color: var(--nuetral-100);
  border-radius: 10px;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  overflow: hidden;
  min-height: 424px;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const videoContainer = css`
  width: 50%;
  height: 100%;
  @media (max-width: 1024px) {
    width: 100%;
    height: 40%;
  }
`;

const flexCol = css`
  width: 50%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 6rem 2rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const flexRow = css`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  row-gap: 4rem;
  padding-right: 2rem;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const titleRow = css`
  padding-left: 2rem;
  @media (max-width: 600px) {
    text-align: center;
    padding: 0;
  }
`;

const specItem = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: calc((100% - 4rem) / 3);
  text-align: center;
  h3 {
    font-weight: 900;
    margin-bottom: 0.5rem;
  }
  p {
    line-height: 1.1;
  }
  @media (max-width: 600px) {
    flex-basis: calc((100% - 2rem) / 2);
  }
`;

const videoClass = css`
  object-fit: cover;
  opacity: 0.9 !important;
  width: 100% !important;
  height: 100% !important;
  object-position: center;
`;

const iconStyles = css`
  height: 48px !important;
  aspect-ratio: 1 !important;
  margin-bottom: 1rem !important;
`;

const DeviceFeturedSpecs = ({
  data,
  videos,
  image = null,
  reverse = false,
}) => {
  const video = videos ? useCompatibleVideo(videos.video, videos.backup) : "";
  return (
    <SectionContainer>
      <SectionInner className="flex-col">
        <SpecContainer reverse={reverse}>
          <div className={flexCol}>
            <div className={titleRow}>
              <TitleMed>{data.title}</TitleMed>
            </div>
            <div className={flexRow}>
              {data.specs.map((item) => (
                <div className={specItem} key={item.title}>
                  <GatsbyImage
                    image={getImage(item.icon)}
                    alt={item.title}
                    objectFit="contain"
                    className={iconStyles}
                  />
                  <h3>{item.title}</h3>
                  <MainParagraph>{item.subTitle}</MainParagraph>
                </div>
              ))}
            </div>
          </div>
          <div className={videoContainer}>
            {image ? (
              <GatsbyImage
                image={getImage(image)}
                alt={data?.altText ?? data.title}
                className={videoClass}
                objectFit="cover"
                objectPosition="center"
              />
            ) : (
              <video
                src={video}
                className={videoClass}
                muted
                autoPlay
                loop
                playsInline
              />
            )}
          </div>
        </SpecContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default DeviceFeturedSpecs;
