import BenefitRows from "components/ITPage/BenefitRows";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

export default function SupportSection({ color = "var(--white)" }) {
  const data = useStaticQuery(graphql`
    query {
      benefitImage3: file(
        relativePath: { eq: "components/ITPage/img/partnership-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  return (
    <BenefitRows
      data={[
        {
          preTitle: "World Class Support",
          title: "We’re Here for You",
          description:
            "Experience best-in-class partnership and support throughout your entire lifetime with Rhombus. Work with a dedicated point of contact and in-house team that provides hands-on support, from end-user training to building custom solutions from scratch.",
          specs: [
            { title: "99%", description: "renewal rate, industry record" },
            {
              title: "24/7",
              description: "in-house support with automatic ticket creation",
            },
          ],
          image: data.benefitImage3,
          altText:
            "Rhombus team showcasing its collaboration to solve customer issues. Whether it's installing security cameras, alarm systems, or questions around electrical work, the Rhombus support team is always available.",
          link: {
            title: "Visit Support Hub",
            path: "https://support.rhombussystems.com/hc/en-us",
          },
        },
      ]}
      color={color}
      reverse
    />
  );
}
