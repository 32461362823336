import { useLayoutEffect, useState } from "react";

export default function useScrollBounds() {
  const [scrollBounds, setScrollBounds] = useState({
    scrollTop: 0,
    scrollBottom: 0,
  });

  useLayoutEffect(() => {
    function handleChangeBounds() {
      const scrollTop = window.scrollY;
      setScrollBounds({
        scrollTop,
        scrollBottom: scrollTop + window.innerHeight,
      });
    }

    handleChangeBounds();

    window.addEventListener("scroll", handleChangeBounds);
    window.addEventListener("resize", handleChangeBounds);

    return () => {
      window.removeEventListener("scroll", handleChangeBounds);
      window.removeEventListener("resize", handleChangeBounds);
    };
  }, []);

  return scrollBounds;
}
