import React from "react";

const IconDeploy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
    >
      <g clip-path="url(#clip0_27235_23908)">
        <path
          d="M50.0781 5.88379H36.7881V3.41504C32.8129 3.49893 30.7785 3.24141 29.3968 0H20.79C19.3028 3.23486 17.3417 3.4668 13.3682 3.41504V5.88379H0.078125V41.1743H19.3327L18.4039 45.3452C18.1778 46.3609 17.293 47.0703 16.2524 47.0703H13.3105V50H36.8457V47.0703H33.9038C32.8633 47.0703 31.9784 46.3609 31.7523 45.3452L30.8235 41.1743H50.0781V5.88379ZM16.2979 6.28203C19.1597 6.02334 21.1271 4.95547 22.4888 2.92969H27.6445C29.0348 5.08506 31.1796 6.04551 33.8585 6.28428V18.1494C33.9791 21.7353 27.3695 25.9678 25.0776 27.135C21.1399 25.079 16.1759 21.1474 16.2979 18.1494V6.28203H16.2979ZM13.3682 8.81348V18.1494C13.2338 23.9838 22.2737 29.1303 25.0781 30.4138C30.1257 28.0487 36.9442 23.0579 36.7881 18.1494V8.81348H47.1484V32.3608H3.00781V8.81348H13.3682ZM28.8926 45.982C28.9778 46.3649 29.1039 46.7287 29.2645 47.0703H20.8916C21.0522 46.7288 21.1783 46.3649 21.2636 45.982L22.3342 41.1743H27.822L28.8926 45.982ZM3.00781 38.2446V35.2905H47.1484V38.2446H3.00781Z"
          fill="#2A7DE1"
        />
        <path
          d="M31.4426 11.3254L29.371 9.25391L23.9466 14.6782L20.7864 11.518L18.7148 13.5896L23.9466 18.8214L31.4426 11.3254Z"
          fill="#2A7DE1"
        />
      </g>
      <defs>
        <clipPath id="clip0_27235_23908">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.078125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDeploy;
