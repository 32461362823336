import CTABanner from "components/common/footer-banners/CTABanner";
import DefaultHeroLayout from "components/common/hero/DefaultHeroLayout";
import SupportSection from "components/common/SupportSection";
import LicenseTable from "components/license-comparison/LicenseTable";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function licenseComparison() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Compare License Plans | Security Camera Licenses | Rhombus
        </title>
        <meta
          name="description"
          content="Explore Rhombus flexible licensing options for security camera, audio, and video intercom. From basic surveillance to advanced AI-powered analytics, find a plan that fits your organization's size and needs."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-License.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-License.png"
        />
      </Helmet>
      <DefaultHeroLayout
        preTitle="License Comparison"
        title="Find the Right License for Your Organization"
        blurb={
          "Compare and choose between professional and enterprise licenses to match your operational needs for different capabilities across AI, search, storage, integrations, and more."
        }
        backgroundColor="var(--gradient-light-fade)"
        center
        maxWidth="1024px"
      />
      <LicenseTable selectedTab="Audio" />
      <SupportSection />
      <CTABanner />
    </GlobalLayout>
  );
}
