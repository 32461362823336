import { styled } from "@linaria/react";
import RhombusButton from "components/common/RhombusButton";
import { FlexColumn, FlexRow } from "components/rui/layout";
import SliderNavDots from "components/rui/slider-components/SliderNavDots";
import { TitleMed } from "components/rui/typography";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { animated, useSpring } from "@react-spring/web";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";

const CASE_STUDIES = [
  {
    title: "Luxer One scaled rapidly across 11K locations and 13K devices",
    link: "/case-studies/luxer-one/",
  },
  {
    title: "Middlebury School reduced system outages by 80%",
    link: "/case-studies/middlebury-community-schools/",
  },
  {
    title: "Middleby Outdoors cut search time from 2 days to 10 minutes",
    link: "/case-studies/middleby-outdoor/",
  },
];

const BladeContainer = styled.div`
  user-select: none;
  cursor: grab;
  display: flex;
  justify-content: center;
  height: 546px;
  width: 100%;
  padding: 40px 20px;
  background-color: var(--primary-bg);
  background-image:
    linear-gradient(to right, var(--primary-bg) 10%, transparent 50%),
    linear-gradient(to left, var(--primary-bg) 10%, transparent 50%),
    url("./assets/rhombus-city-scape.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media (max-width: 900px) {
    background-image:
      linear-gradient(to top, var(--primary-bg) 10%, transparent 80%),
      linear-gradient(to bottom, var(--primary-bg) 10%, transparent 80%),
      url("./assets/rhombus-city-scape.png");
  }
  @media (max-width: 900px) {
    height: 600px;
    padding: 30px;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 600px) {
    height: 800px;
    background-image:
      linear-gradient(to top, var(--primary-bg) 20%, transparent 50%),
      linear-gradient(to bottom, var(--primary-bg) 20%, transparent 50%),
      url("./assets/rhombus-city-scape-mobile.png");
    background-position-y: -50px;
  }
`;

const BladeInner = styled.div`
  max-width: var(--max-width);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CaseStudySlideContainer = styled.div`
  display: ${({ active }) => (active ? "flex" : "none")};
  max-width: 500px;
  transition: all 250ms ease-in;
  user-select: none;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 900px) {
    max-width: 362px;
    min-height: 175px;
  }
`;

const CaseStudySlide = ({ title, link, active, springs }) => {
  return (
    <CaseStudySlideContainer active={active}>
      <animated.div style={springs}>
        <TitleMed light>{title}</TitleMed>
      </animated.div>
      <RhombusButton
        type="secondary-light"
        path={link}
        title={"Read customer story"}
      />
    </CaseStudySlideContainer>
  );
};

const DesktopG2Badges = () => {
  return (
    <FlexColumn gap="28px">
      <FlexRow gap="28px">
        <StaticImage src="./assets/g2/badge-9.png" height={114} />
        <StaticImage src="./assets/g2/badge-7.png" height={114} />
        <StaticImage src="./assets/g2/badge-10.png" height={114} />
        <StaticImage src="./assets/g2/badge-11.png" height={114} />
      </FlexRow>
      <FlexRow gap="28px">
        <StaticImage src="./assets/g2/badge-8.png" height={114} />
        <StaticImage src="./assets/g2/badge-6.png" height={114} />
        <StaticImage src="./assets/g2/badge-1.png" height={114} />
        <StaticImage src="./assets/g2/badge-ai.png" height={114} />
      </FlexRow>
    </FlexColumn>
  );
};

const MobileG2Badges = () => {
  return (
    <FlexColumn gap="28px">
      <FlexRow gap="28px">
        <StaticImage src="./assets/g2/badge-9.png" height={114} />
        <StaticImage src="./assets/g2/badge-7.png" height={114} />
        <StaticImage src="./assets/g2/badge-10.png" height={114} />
      </FlexRow>
      <FlexRow gap="28px">
        <StaticImage src="./assets/g2/badge-8.png" height={114} />
        <StaticImage src="./assets/g2/badge-1.png" height={114} />
        <StaticImage src="./assets/g2/badge-ai.png" height={114} />
      </FlexRow>
    </FlexColumn>
  );
};

const ReviewBlade = () => {
  const { isMobile } = useIsMobile(900);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [springs, api] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const animate = () =>
    api.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: {
        tension: 280,
        friction: 60,
      },
    });

  useEffect(() => {
    animate();
  }, [currentIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    trackMouse: true,
  });

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % CASE_STUDIES.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? CASE_STUDIES.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <BladeContainer {...handlers}>
      <BladeInner>
        <FlexColumn gap="30px">
          {CASE_STUDIES.map((c, index) => (
            <CaseStudySlide
              title={c.title}
              link={c.link}
              active={currentIndex === index}
              springs={springs}
            />
          ))}
          <SliderNavDots
            slides={CASE_STUDIES}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            center={false}
            primaryColor="var(--white)"
            secondaryColor="var(--body-subtle)"
            spacing="12px"
          />
        </FlexColumn>
        {isMobile ? <MobileG2Badges /> : <DesktopG2Badges />}
      </BladeInner>
    </BladeContainer>
  );
};

export default ReviewBlade;
