import React from "react";

const IconCamera = ({ size = "44", color = "var(--blue-500)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 45 44"
      fill="none"
    >
      <g clip-path="url(#clip0_29047_18496)">
        <path
          d="M41.9004 0H3.10238C1.68252 0 0.527344 1.15517 0.527344 2.57512V7.17234C0.527344 8.59229 1.68252 9.74746 3.10246 9.74746H4.71834V15.2677C4.71834 25.0734 12.6958 33.0509 22.5015 33.0509C32.3072 33.0509 40.2846 25.0734 40.2846 15.2677V9.74746H41.9005C43.3204 9.74746 44.4756 8.59229 44.4756 7.17234V2.57512C44.4754 1.15517 43.3203 0 41.9004 0ZM37.7094 15.2677C37.7094 23.6534 30.8871 30.4758 22.5014 30.4758C14.1157 30.4758 7.29338 23.6534 7.29338 15.2677V9.74746H37.7094V15.2677ZM3.10238 7.17234V2.57512H41.9003L41.902 7.17234C41.9012 7.17243 3.10323 7.17234 3.10238 7.17234Z"
          fill={color}
        />
        <path
          d="M31.3915 38.7141C35.5431 38.0042 38.4832 35.0523 39.1908 30.9149C39.3106 30.214 38.8396 29.5487 38.1387 29.4288C37.4378 29.309 36.7723 29.78 36.6525 30.4808C36.3625 32.1765 35.5633 33.6994 34.2105 34.7693C33.3032 35.4887 32.2087 35.9618 30.9575 36.1758C30.2566 36.2957 29.7855 36.961 29.9053 37.6619C30.0255 38.3641 30.692 38.8339 31.3915 38.7141Z"
          fill={color}
        />
        <path
          d="M43.4068 29.9562C42.7054 29.8361 42.0405 30.3074 41.9207 31.0082C40.9983 36.4019 37.0819 40.4871 31.4847 41.4442C30.7838 41.5641 30.3128 42.2294 30.4327 42.9304C30.5528 43.6325 31.2194 44.1022 31.9188 43.9824C38.4633 42.8634 43.3321 38.0322 44.4589 31.4422C44.5788 30.7414 44.1077 30.0761 43.4068 29.9562Z"
          fill={color}
        />
        <path
          d="M14.0487 36.1758C10.9642 35.6482 8.87538 33.5308 8.35374 30.4808C8.23385 29.7799 7.5687 29.309 6.86762 29.4288C6.16671 29.5487 5.69569 30.214 5.81549 30.9149C6.52396 35.0573 9.46964 38.0054 13.6147 38.7141C14.3152 38.8339 14.981 38.3631 15.1009 37.662C15.2207 36.9609 14.7496 36.2957 14.0487 36.1758Z"
          fill={color}
        />
        <path
          d="M13.5201 41.4442C8.01489 40.5027 4.02241 36.4951 3.08414 31.0082C2.96435 30.3074 2.29971 29.8362 1.59803 29.9562C0.897121 30.076 0.426098 30.7414 0.545981 31.4423C1.66918 38.0111 6.51692 42.8592 13.0861 43.9824C13.7867 44.1023 14.4523 43.6314 14.5722 42.9304C14.692 42.2293 14.221 41.564 13.5201 41.4442Z"
          fill={color}
        />
        <path
          d="M22.5026 27.8373C26.7624 27.8373 30.2279 24.3717 30.2279 20.112C30.2279 15.8522 26.7624 12.3867 22.5026 12.3867C18.2429 12.3867 14.7773 15.8522 14.7773 20.112C14.7773 24.3717 18.2429 27.8373 22.5026 27.8373ZM22.5026 14.9617C25.3424 14.9617 27.6528 17.2721 27.6528 20.1119C27.6528 22.9517 25.3424 25.2621 22.5026 25.2621C19.6628 25.2621 17.3525 22.9518 17.3525 20.112C17.3525 17.2722 19.6628 14.9617 22.5026 14.9617Z"
          fill={color}
        />
        <path
          d="M22.5024 21.3992C23.2134 21.3992 23.7899 20.8228 23.7899 20.1117C23.7899 19.4007 23.2134 18.8242 22.5024 18.8242C21.7913 18.8242 21.2148 19.4007 21.2148 20.1117C21.2148 20.8228 21.7913 21.3992 22.5024 21.3992Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_29047_18496">
          <rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCamera;
