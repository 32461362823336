import React from "react";

const LocationIcon = ({
  color = "currentColor",
  height = "26",
  width = "18",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 29"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0946 10.4722C20.0946 4.94611 15.0574 0.586776 9.28712 1.66469C5.83065 2.31036 3.01216 5.01003 2.23544 8.41169C1.66628 10.9044 2.16553 13.2564 3.33989 15.1533H3.32679L10.0671 26.9334C10.4986 27.6885 11.5954 27.6885 12.028 26.9334L18.7673 15.1533H18.7553C19.5986 13.7894 20.0946 12.1904 20.0946 10.4722Z"
        stroke={color}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9588 10.6209C14.9588 12.7637 13.2076 14.5003 11.0468 14.5003C8.88703 14.5003 7.13477 12.7637 7.13477 10.6209C7.13477 8.47804 8.88703 6.74146 11.0468 6.74146C13.2076 6.74146 14.9588 8.47804 14.9588 10.6209Z"
        stroke={color}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationIcon;
