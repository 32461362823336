import { styled } from "@linaria/react";
import IconCheckCircle from "components/common/icon-components/IconCheckCircle";
import React from "react";
import { MainParagraph } from "./theme/typography";

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
`;

const StyledLi = styled.li`
  display: flex;
  gap: 8px;
  list-style: none;
  svg {
    margin-top: 5px;
  }
`;

const Checklist = ({ listItems }) => {
  return (
    <StyledUl>
      {listItems.map((item) => (
        <StyledLi>
          <IconCheckCircle />
          <MainParagraph>{item}</MainParagraph>
        </StyledLi>
      ))}
    </StyledUl>
  );
};

export default Checklist;
