import React, { useMemo, useState } from "react";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { styled } from "@linaria/react";
import RhombusButton from "components/common/RhombusButton";
import { DEVICE_MAP } from "./constants";
import useIsMobile from "hooks/useIsMobile";
import { Column } from "./styles";
import SelectorHeader from "./SelectorHeader";
import { CAMERA_SPECS } from "./camera-data";

const TableContainer = styled(SectionContainer)`
  padding-top: 0;
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableRow = styled.div`
  display: flex;
  width: 100%;
  padding: 1.25rem 0;
  gap: 2.5rem;
  border-bottom: 1px solid var(--blue-100);

  &:last-of-type,
  :nth-last-of-type(2) {
    border: none;
  }
  @media (max-width: 1024px) {
    gap: 1.25rem;
    border: none;
    padding: 1.25rem;
  }
`;

const CompareDevices = ({ deviceData, title }) => {
  const [selectedDevice1, setSelectedDevice1] = useState("");
  const [selectedDevice2, setSelectedDevice2] = useState("");
  const [selectedDevice3, setSelectedDevice3] = useState("");

  const { isMobile } = useIsMobile(1024);

  const deviceData1 = useMemo(() => {
    const selectedDevice = deviceData.filter(
      item => item.modelName === selectedDevice1
    );
    return selectedDevice[0];
  }, [selectedDevice1]);

  const deviceData2 = useMemo(() => {
    const selectedDevice = deviceData.filter(
      item => item.modelName === selectedDevice2
    );
    return selectedDevice[0];
  }, [selectedDevice2]);

  const deviceData3 = useMemo(() => {
    const selectedDevice = deviceData.filter(
      item => item.modelName === selectedDevice3
    );
    return selectedDevice[0];
  }, [selectedDevice3]);

  const createSafeDeviceLink = (deviceName, deviceCat) => {
    return `/cameras/${deviceCat.toLowerCase()}-cameras/${
      DEVICE_MAP[deviceName]
    }`;
  };

  const learnMoreLink1 = useMemo(() => {
    if (!deviceData1) return;
    return createSafeDeviceLink(deviceData1.modelName, deviceData1.category);
  }, [deviceData1]);

  const learnMoreLink2 = useMemo(() => {
    if (!deviceData2) return;

    return createSafeDeviceLink(deviceData2.modelName, deviceData2.category);
  }, [deviceData2]);

  const learnMoreLink3 = useMemo(() => {
    if (!deviceData3) return;
    return createSafeDeviceLink(deviceData3.modelName, deviceData3.category);
  }, [deviceData3]);

  const handleValue = (key, deviceData) => {
    if (key === "ble") {
      return deviceData && deviceData[key].value ? "Compatible" : "-";
    } else {
      return deviceData && deviceData[key].value ? deviceData[key].value : "-";
    }
  };

  const rowsMap = Object.keys(CAMERA_SPECS).map(key => {
    return {
      name: CAMERA_SPECS[key],
      column1Value: handleValue(key, deviceData1),
      column2Value: handleValue(key, deviceData2),
      column3Value: handleValue(key, deviceData3),
    };
  });

  return (
    <section>
      <SelectorHeader
        deviceData={deviceData}
        title={title}
        selectedDevice1={selectedDevice1}
        setSelectedDevice1={setSelectedDevice1}
        selectedDevice2={selectedDevice2}
        setSelectedDevice2={setSelectedDevice2}
        selectedDevice3={selectedDevice3}
        setSelectedDevice3={setSelectedDevice3}
      />
      <TableContainer>
        <SectionInner>
          <Table>
            {rowsMap.map(row => (
              <>
                {isMobile && (
                  <TableRow style={{ background: "var(--nuetral-100)" }}>
                    <Column>
                      <b>{row.name}</b>
                    </Column>
                  </TableRow>
                )}

                <TableRow>
                  {!isMobile && (
                    <Column>
                      <b>{row.name}</b>
                    </Column>
                  )}

                  <Column>{row.column1Value}</Column>

                  <Column>{row.column2Value}</Column>

                  {!isMobile && <Column>{row.column3Value}</Column>}
                </TableRow>
              </>
            ))}

            <TableRow style={{ border: "none" }}>
              {!isMobile && <Column></Column>}

              <Column>
                {selectedDevice1 && (
                  <RhombusButton
                    title="Learn More"
                    path={learnMoreLink1}
                    type="secondary"
                  />
                )}
              </Column>

              <Column>
                {selectedDevice2 && (
                  <RhombusButton
                    title="Learn More"
                    path={learnMoreLink2}
                    type="secondary"
                  />
                )}
              </Column>
              {!isMobile && (
                <Column>
                  {selectedDevice3 && (
                    <RhombusButton
                      title="Learn More"
                      path={learnMoreLink3}
                      type="secondary"
                    />
                  )}
                </Column>
              )}
            </TableRow>
          </Table>
        </SectionInner>
      </TableContainer>
    </section>
  );
};

export default CompareDevices;
