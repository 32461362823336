import { useEffect, useState } from "react";

export function useIsVisible(ref, useArray = false) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [currentElement, setCurrentElement] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      setCurrentElement(entry.target);
    });
    if (useArray) {
      ref.current.map(i => {
        observer.observe(i);
      });
      return () => {
        ref.current.map(() => {
          observer.disconnect();
        });
      };
    } else {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, useArray]);

  return useArray ? isIntersecting && currentElement : isIntersecting;
}
