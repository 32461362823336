import { styled } from "@linaria/react";

export const ButtonBase = styled.a`
  display: inline-flex;
  padding: ${(props) => (props.small ? "8px 24px" : "12px 28.25px")};
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: ${(props) => (props.small ? "40px" : "48px")};
  width: fit-content;
  transition: all 250ms;
  font-size: ${(props) => (props.small ? "14px" : "16px")};
  font-weight: 600;
  border-radius: ${(props) => (props.small ? "6px" : "8px")};
  background: var(--blue-900);
  color: var(--white);
  text-decoration: none;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
  cursor: pointer;
  user-select: none;
  :hover {
    background: var(--blue-500);
  }
  .carrot {
    transform: rotate(-90deg);
    transition: transform 250ms ease-in;
  }
  :hover .carrot {
    transform: rotate(-90deg) translateY(0.25rem);
  }
  svg {
    transition: all 250ms ease-in;
  }
  :hover svg {
    transform: translateX(0.25rem);
  }
`;

export const ButtonBaseOutlined = styled(ButtonBase)`
  background: transparent;
  border: 1px solid var(--blue-900);
  color: var(--blue-900);
  :hover {
    color: var(--white);
    background: var(--blue-500);
    border-color: var(--blue-500);
  }
`;

export const ButtonWhite = styled(ButtonBase)`
  background: var(--white);
  color: var(--blue-900);
  :hover {
    color: var(--white);
    background: var(--blue-500);
    border-color: var(--blue-500);
  }
`;

export const ButtonWhiteOutlined = styled(ButtonBaseOutlined)`
  background: transparent;
  border: 1px solid var(--white);
  color: var(--white);
  :hover {
    color: var(--white);
    background: var(--blue-500);
    border-color: var(--blue-500);
  }
`;

export const GradientButton = styled(ButtonBase)`
  background: linear-gradient(
    90deg,
    var(--blue-500),
    var(--teal-500),
    var(--blue-500),
    var(--teal-500)
  );
  background-size: 300% 100%;
  :hover {
    animation: animateBg 3s linear infinite;
  }
  @keyframes animateBg {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

export const LandingPageButton = styled(GradientButton)`
  padding: 16px 38px;
  gap: 10px;
  font-size: 20px;
  font-weight: 900;
  animation: animateBg 3s linear infinite;
  box-shadow: 0.1rem 0.1rem 1rem #00c1de80;
  max-height: unset;
  :hover {
    box-shadow: 0.1rem 0.1rem 1rem var(--teal-500);
  }
  @keyframes animateBg {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

export const LandingPageButtonV2 = styled(ButtonBase)`
  padding: 12px 28.25px;
  border-radius: 4px;
  background: var(--primary);
  color: var(--blue-900);
  :hover {
    background: var(--teal-500);
  }
`;

export const LandingPageButtonV3 = styled(LandingPageButtonV2)`
  background: var(--cta-blue);
  color: var(--white);
  :hover {
    background: var(--blue-800);
  }
`;

export const LandingPageButtonV4 = styled(LandingPageButtonV2)`
  background: var(--primary-bg);
  color: var(--white);
  padding: 20px 32px;
  :hover {
    color: var(--primary-bg);
    background: var(--primary);
  }
`;

export const LandingPageButtonFancy = styled(GradientButton)`
  padding: 20px 32px;
  gap: 10px;
  font-size: 20px;
  border-radius: 4px;
  max-height: unset;
  font-weight: 800;
  font-size: 18px;
  :hover {
    animation: animateBg 3s linear infinite;
  }
  @keyframes animateBg {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

export const NavButtonOutlined = styled(ButtonBaseOutlined)`
  padding: 6.795px 20.386px;
  border-radius: 4px;
  height: 32px;
  border-width: 2px;
  :hover {
    color: var(--white);
    background: var(--cta-blue-light);
    border-color: var(--cta-blue-light);
  }
`;
