import {
  DefaultFlexContainer,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { styled } from "@linaria/react";
import React from "react";
import IconCheckCircle from "./icon-components/IconCheckCircle";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import TextLinkV2 from "./TextLinkV2";

const BulletSectionInner = styled(SectionInner)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5rem;
`;

const List = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  width: 100%;
  text-align: left;
  flex-wrap: wrap;
  gap: 2.5rem;
`;

const LineItem = styled.li`
  flex-basis: calc(50% - 2.5rem);
  display: flex;
  @media (max-width: 820px) {
    flex-basis: 100%;
  }
`;

const BulletContainer = styled.div`
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  margin: 5px 1rem 0px 0px;
`;

const BulletPointSection = ({
  bgColor = "var(--white)",
  title,
  bullets,
  link,
}) => {
  return (
    <SectionContainer style={{ background: bgColor }}>
      <BulletSectionInner>
        <FancyTitle>{title}</FancyTitle>
        <List>
          {bullets.map((item) => (
            <LineItem>
              <BulletContainer>
                <IconCheckCircle />
              </BulletContainer>
              <MainParagraph>{item}</MainParagraph>
            </LineItem>
          ))}
        </List>
        <DefaultFlexContainer>
          <TextLinkV2 title={link.title} path={link.path} target="_blank" />
        </DefaultFlexContainer>
      </BulletSectionInner>
    </SectionContainer>
  );
};

export default BulletPointSection;
