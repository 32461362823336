import CaseStudyBlade from "components/common/CaseStudyBlade";
import Features from "components/common/Features";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import videoSrc from "../../videos/access-control-lp-video-min.mp4";
import HeroLayout5 from "components/common/hero/HeroLayout5";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";

export default function accesControlA() {
  const renderContent = (data) => {
    const pageData = {
      headerSection: {
        title: "Unify Building Access Control like Never Before",
        paragraph:
          "Rhombus’ modern access control suite provides centralized management, seamless remote access, and unlimited scalability to grow with your organization.",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
      industryLogos: [
        {
          img: data.logo1,
          width: "118px",
          alt: "Amazon",
        },
        {
          img: data.logo2,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo3,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo4,
          width: "115px",
          alt: "Stryker",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Contruction",
        },
      ],
      infoSlider: {
        title: "Everything You Need for Access Control",
        list: [
          {
            tabTitle: "Video Intercom",
            title: "Native Video and Two-Way Audio",
            description:
              "Get maximum visibility on arrivals and departures by pairing access events with live footage. Get notified when visitors arrive, see who’s at the door, and talk to guests all from one device.",
            image: data.benefit1,
          },
          {
            tabTitle: "Touchless Entry",
            title: "New Ways to Access Your Spaces",
            description:
              "Gain instant access to buildings with a wave of your hand, tap of your finger, or the touch of a keycard. Rhombus’ Door Reader’s fast and reliable performance ensures smooth access for guests and employees.",
            image: data.benefit2,
          },
          {
            tabTitle: "Backup Power",
            title: "Power Down? Not a Problem",
            description:
              "Never worry when the power goes out. Rhombus Access Control provides uninterrupted service during outages, ensuring building security is maintained in all conditions.",
            image: data.benefit3,
          },
          {
            tabTitle: "All-in-One",
            title: "All Your Locations on a Single Pane of Glass",
            description:
              "Oversee unlimited doors and users, set access schedules, manage lockdown plans, and orchestrate sophisticated workflows between access control, cameras, sensors, and alarms—all from one user-friendly dashboard.",
            image: data.benefit4,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "User-Friendly",
          title: "User-Friendly",
          p: "Rhombus combines consumer-level ease of use with enterprise-grade performance, no technical expertise required.",
        },
        {
          icon: data.icon2,
          iconAlt: "AI Analytics",
          title: "AI Analytics",
          p: "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.  ",
        },
        {
          icon: data.icon3,
          iconAlt: "Bandwidth & Latency",
          title: "Bandwidth & Latency",
          p: "Get the best performance with 50% less bandwidth utilization and 10x better latency compared to other cloud vendors.",
        },
        {
          icon: data.icon4,
          iconAlt: "Infinite Scalability",
          title: "Infinite Scalability",
          p: "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
        },
      ],
      caseStudyBlade: {
        image: data.bladeImage,
        logo: data.caseStudyLogo,
        author: {
          avatar: data.avatar,
          name: "Brad Minsley ",
          role: "Managing Director at 10 Federal",
          review:
            "Rhombus has allowed us to remotely view any property, be alerted to specific activities, better serve our customers, actively control building access, and more.",
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Enterprise Cloud-Managed Access Control System </title>
          <meta
            name="description"
            content="Unified cloud-managed access control that integrates perfectly with our video, sensors, & alarm monitoring."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <HeroLayout5
          formTitle={CTA_BUTTON_COPY}
          buttonText={CTA_BUTTON_COPY}
          title={pageData.headerSection.title}
          formId={pageData.headerSection.formId}
          blurb={pageData.headerSection.paragraph}
          video={{
            src: videoSrc,
            aspectRatio: 1,
          }}
        />
        <IndustryFeaturedUpdated
          title="Trusted by Industry Leaders"
          logos={pageData.industryLogos}
          color="var(--white)"
        />
        <TrustedPartnerSection />
        <InfoSlider
          data={pageData.infoSlider.list}
          title={pageData.infoSlider.title}
          color="var(--nuetral-100)"
          button
        />
        <Features
          data={pageData.features}
          title="What Sets Rhombus Apart"
          color="var(--white)"
        />
        <CaseStudyBlade data={pageData.caseStudyBlade} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/landing-pages/img/access-control-hero-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      mobileHeaderImage: file(
        relativePath: {
          eq: "components/landing-pages/img/access-control-hero-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(relativePath: { eq: "components/common/logos/amazon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/common/logos/hurley.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-black.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(relativePath: { eq: "components/common/logos/stryker.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/landing-pages/search/img/intercom-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/landing-pages/search/img/touchless-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/landing-pages/search/img/backup-power-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/location.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/brad-minsley.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      caseStudyLogo: file(
        relativePath: {
          eq: "components/common/logos/10-federal-logo-large.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/common/img/case-studies/10-federal-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
