import {
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const BladeContainer = styled(SectionContainer)`
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const bgImage = css`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
  z-index: 0;
  filter: brightness(0.7) !important;
`;

const BladeInner = styled(SectionInner)`
  z-index: 1;
`;

const BladeCol = styled(SectionCol)`
  align-self: center;
  @media (max-width: 820px) {
    justify-content: center;
  }
`;

const reviewContainer = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--white);
  q {
    font-size: 28px;
    font-weight: 350;
    line-height: 2;
  }
  @media (max-width: 820px) {
    align-items: center;
    q {
      text-align: center;
    }
  }
`;

const avatarRow = css`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  @media (max-width: 768px) {
    p {
      font-size: 1rem;
    }
  }
`;

const avatar = css`
  width: 60px !important;
  aspect-ratio: 1 !important;
`;

const logoClass = css`
  width: var(--width) !important;
  height: var(--height) !important;
  flex-shrink: 0 !important;
`;

const CaseStudyBlade = ({ data, width = "346px", height = "103px" }) => {
  return (
    <BladeContainer>
      <GatsbyImage
        image={getImage(data.image)}
        alt={data.author.review}
        className={bgImage}
      />
      <BladeInner>
        <BladeCol>
          <GatsbyImage
            image={getImage(data.logo)}
            alt={"case study"}
            objectFit="contain"
            className={logoClass}
            style={{ "--height": height, "--width": width }}
          />
        </BladeCol>
        <BladeCol>
          <div className={reviewContainer}>
            <q>{data.author.review}</q>
            <div className={avatarRow}>
              {data.author.avatar && (
                <GatsbyImage
                  image={getImage(data.author.avatar)}
                  alt={data.author.name}
                  className={avatar}
                />
              )}
              <div>
                <b>{data.author.name}</b>
                <MainParagraph color="var(--white)">
                  {data.author.role}
                </MainParagraph>
              </div>
            </div>
          </div>
        </BladeCol>
      </BladeInner>
    </BladeContainer>
  );
};

export default CaseStudyBlade;
