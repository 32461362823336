import React from "react";
import IndustryHeaderUpdated from "./IndustryHeaderUpdated";
import IndustryFeaturedUpdated from "./IndustryFeaturedUpdated";
import IndustryLearnRow from "./IndustryLearnRow";
import Features from "components/common/Features";
import IndustryFormUpdated from "./IndustryFormUpdated";
import IndustryUseCases from "./IndustryUseCases";

const IndustryPageUpdated = ({ data }) => {
  return (
    <>
      <IndustryHeaderUpdated data={data.header} />
      <IndustryFeaturedUpdated
        title={data.industryTitle}
        logos={data.industryLogos}
      />
      <IndustryLearnRow data={data.learnRows} />
      <IndustryUseCases data={data.industryUseCases} />
      <Features data={data.features} color="var(--nuetral-100)" />
      <IndustryFormUpdated data={data.form} />
    </>
  );
};

export default IndustryPageUpdated;
