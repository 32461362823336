import React from "react";
import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";
import { graphql, Link, useStaticQuery } from "gatsby";
import video from "components/cameras/mp4/built-to-last.hevc_1.mp4";
import videoBackup from "components/cameras/mp4/backups/built-to-last.mp4";
import {
  CTA_BUTTON_COPY_6,
  LIVE_DEMO_LINK,
} from "components/data/landing-page-data";

export default function DomeCameras() {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device3: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device4: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device5: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/r400-pole-mount.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/r400-outdoor-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/r400-outdoor-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/spec-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(
        relativePath: { eq: "components/common/icons/certification.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(relativePath: { eq: "components/common/icons/plug.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(relativePath: { eq: "components/common/icons/updates.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/common/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(
        relativePath: { eq: "components/common/icons/customizable.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(relativePath: { eq: "components/common/icons/secure.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImageMobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      recommend1: file(
        relativePath: { eq: "components/cameras/img/fisheye-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend2: file(
        relativePath: { eq: "components/cameras/img/bullet-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend3: file(
        relativePath: { eq: "components/cameras/img/r600-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      faq: file(
        relativePath: { eq: "components/cameras/img/dome-cameras-faq-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  const pageData = {
    headerSection: {
      preTitle: "Dome cameras",
      title: "See More, Worry Less With Smart Dome Cameras",
      description:
        "Protect your people and assets with a modern security solution and advanced dome surveillance cameras. Streamline security operations in diverse environments with simplified plug-and-play deployment and powerful analytics on a secure, easy-to-use cloud platform.",
      image: data.headerImage,
      altText:
        "Four high-performance dome surveillance cameras displayed on a podium, highlighting the advanced technology and design of Rhombus dome cameras for enhanced security solutions.",
      button1: {
        text: CTA_BUTTON_COPY_6,
        path: LIVE_DEMO_LINK,
      },
    },
    cardSection: {
      title: "A Suite Of Dome Cameras For Diverse Security Needs",
      cards: [
        {
          image: data.device1,
          altText:
            "Rhombus R120 Mini Dome Camera placed on a wall- Outdoor IP Camera with Night Vision, Wide Dynamic Range, Motion Detection, and Sleek Form Factor for Versatile Indoor and Outdoor Security Monitoring.",
          name: "R120 Mini Dome",
          description:
            "2MP Dome with Digital PTZ ideal for maximizing camera coverage",
          link: "/cameras/dome-cameras/r120/",
          flex: 3,
        },
        {
          image: data.device2,
          altText:
            "Rhombus R170 Micro-Dome Camera installed on a wooden wall, showcasing its sleek form factor and versatility for indoor settings. This standard dome security camera features a discreet design, making it ideal for retail stores and other security systems that require unobtrusive monitoring. The R170 offers high-quality images and video quality, with motion detection capabilities.",
          name: "R170 Micro Dome",
          description:
            "5MP Micro-Dome with Digital PTZ ideal for discreet indoor deployments",
          link: "/cameras/dome-cameras/r170/",

          flex: 3,
        },
        {
          image: data.device3,
          altText:
            "R200 Rhombus dome camera mounted on a white wall, showcasing its sleek form factor and versatile design for indoor and outdoor security needs. This dome camera features infrared night vision, motion detection, and high-quality video capabilities, ensuring effective monitoring and perimeter protection for retail stores and other environments",
          name: "R200 Mini Dome",
          description:
            "5MP Dome with Digital PTZ for actionable AI analytics and high-resolution coverage.",
          link: "/cameras/dome-cameras/r200/",

          flex: 3,
        },
        {
          image: data.device4,
          altText:
            "A sleek R230 Rhombus dome security camera is mounted on a grey ceiling, showcasing its versatile design for both indoor and outdoor security needs. This high-quality dome security camera features advanced motion detection and infrared night vision capabilities, ensuring optimal video quality and perimeter protection even in low light conditions.",
          name: "R230 Wi-Fi Dome",
          description:
            "5MP Dome with Optical Zoom ideal for long-distance visibility and WiFi connectivity",
          link: "/cameras/dome-cameras/r230/",

          flex: 3,
        },
        {
          image: data.device5,
          altText:
            "The Rhombus R400 dome camera is a powerful 4K dome security camera with a sleek form factor, ideal for indoor and outdoor use. Its vandal and weather-resistant design makes it suitable for perimeter protection in various settings like retail stores. Its 8MP image sensor delivers high-quality images even in low light conditions, thanks to the built-in infrared night vision.",
          name: "R400 Dome",
          description:
            "8MP Dome with Optical Zoom ideal for powerful analytics and long-distance visibility.",
          link: "/cameras/dome-cameras/r400/",

          flex: 3,
        },
      ],
    },
    exploreSection: {
      cards: [
        {
          image: data.recommend1,
          name: "Fisheye Cameras",
          description:
            "Immersive coverage and visibility with minimal camera placement",
          link: "/cameras/fisheye-cameras/",
          flex: 3,
        },
        {
          image: data.recommend2,
          name: "Bullet Cameras",
          description: "Ruggedized form-factor with high-resolution video",
          link: "/cameras/bullet-cameras/",
          flex: 3,
        },
        {
          image: data.recommend3,
          name: "Multisensor Cameras",
          description: "One powerful camera for complete coverage",
          link: "/cameras/multisensor-cameras/",
          flex: 3,
        },
      ],
    },
    benefitsSection: {
      title:
        "Modernize Physical Security with Easy-to-Deploy Dome Surveillance Cameras",
      items: [
        {
          image: data.benefit1,
          altText:
            "Vandal-proof dome camera mounted on a building corner using an L-shaped bracket, ideal for surveillance in high-traffic areas with its discreet and durable design.",
          title: "Enhanced Visibility with Flexible Deployment",
          description:
            "Expand coverage across spaces with versatile dome surveillance cameras. From entry-point to specialized units, dome cameras provide reliable, extensive protection.",
        },
        {
          image: data.benefit2,
          altText:
            "High-quality dome security camera installed on an office wall, part of an advanced dome security camera system. This dome camera features night vision capabilities and is designed for both indoor and outdoor use, ensuring comprehensive surveillance.",
          title: "Powerful Onboard Analytics",
          description:
            "Get crystal-clear footage and lightning-fast processing right out of the box. Footage is analyzed directly on the camera to save time, optimize bandwidth, and accelerate live alerts.",
        },
        {
          image: data.benefit3,
          altText:
            "A dome camera installed on a construction site offers flexible security solutions that can be adapted to any location. This outdoor dome security camera is designed for easy relocation, ensuring comprehensive coverage as the project evolves. Equipped with night vision capabilities, it provides 24/7 surveillance, effectively deterring theft and vandalism. ",
          title: "Discreet Design for Effective Surveillance",
          description:
            "Dome cameras blend into any environment. Its sleek design minimizes attention, ensuring security without compromising aesthetics and providing unobtrusive surveillance.",
        },
      ],
    },
    specSection: {
      title: "A Reliable Security Solution",
      image: data.specImage,
      reverse: true,
      specs: [
        { icon: data.icon1, title: "10-Year", subTitle: "Warranty" },
        {
          icon: data.icon2,
          title: "Plug-and-Play",
          subTitle: "Simple Setup",
        },
        {
          icon: data.icon3,
          title: "Automatic Updates",
          subTitle: "Included",
        },
        {
          icon: data.icon4,
          title: "Onboard Analytics",
          subTitle: "Included",
        },
        {
          icon: data.icon5,
          title: "Customizable",
          subTitle: "Settings & Permissions",
        },
        {
          icon: data.icon6,
          title: "Secure by Default",
          subTitle: "Framework",
        },
      ],
    },
  };

  const faq = {
    title: "Dome Cameras FAQs",
    image: data.faq,
    alt: "Indoor dome camera with a discreet design in an office, ensuring secure access to video data while blending seamlessly with the surroundings.",
    QA: [
      {
        question:
          "Are Rhombus dome security cameras suitable for both indoor and outdoor use?",
        answer:
          "Yes, Rhombus dome cameras are designed for versatile deployment both indoors and outdoors. Their vandal-resistant construction makes them ideal for various environments, providing reliable surveillance for your property in any setting.",
      },
      {
        question: "Do dome cameras have audio capabilities?",
        answer: (
          <>
            Rhombus dome cameras do not have a built-in mic but can be paired
            with the A100 sensor to provide synchronized audio and video
            recordings. The Rhombus{" "}
            <Link to="/sensors/a100/">A100 Audio Gateway Sensor </Link>
            features an internal microphone and speaker, allowing for two-way
            communication and audio recording.
          </>
        ),
      },
      {
        question: "How easy is it to install Rhombus dome cameras?",
        answer:
          "Rhombus dome cameras are designed for easy installation with plug-and-play technology. They can be quickly set up and integrated into your existing security infrastructure, making them a convenient choice for property managers looking to enhance their surveillance systems.",
      },
      {
        question: "Do Rhombus dome cameras offer zoom capabilities?",
        answer: (
          <>
            Yes. Many Rhombus dome camera models feature digital or optical zoom
            capabilities. For instance, the{" "}
            <Link to="/cameras/dome-cameras/r230/">R230 Wi-Fi dome camera</Link>{" "}
            and <Link to="/cameras/dome-cameras/r400/">R400 dome camera</Link>{" "}
            models offer optical zoom, which is ideal for long-distance
            visibility and detailed monitoring of specific areas.
          </>
        ),
      },
    ],
  };

  return (
    <GlobalLayout color="var(--primary-bg)" dark>
      <Helmet>
        <title>AI Commercial Dome Cameras | Try for Free | Rhombus</title>
        <meta
          name="description"
          content="Start a free trial with Rhombus smart dome surveillance cameras to maximize security coverage in different spaces. Streamline video surveillance with AI insights and remote access."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-Dome-Cameras-min.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-Dome-Cameras-min.png"
        />
      </Helmet>
      <DevicePageTemplate
        data={pageData}
        video={video}
        videoBackup={videoBackup}
        deviceType="Camera"
        recommend
        reverse
        faq={faq}
      />
    </GlobalLayout>
  );
}
