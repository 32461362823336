import React from "react";

const IconSliderArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.07812 16.9453L9.48812 15.5353L3.90813 9.94531L16.0781 9.94531L16.0781 7.94531L3.90813 7.94531L9.48813 2.35531L8.07813 0.945312L0.0781257 8.94531L8.07812 16.9453Z"
        fill="black"
      />
    </svg>
  );
};

export default IconSliderArrow;
