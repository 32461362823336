import GlobalLayout from "components/page/GlobalLayout";
import PricingPage from "components/pricing/PricingPage";
import React from "react";
import Helmet from "react-helmet";

export default function Pricing() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Physical Security System Pricing | Get a Quote | Rhombus</title>
        <meta
          name="description"
          content="Check out Rhombus pricing plans for your physical security needs. Start your free trial today and  safeguard your spaces—no strings attached."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-pricing.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-pricing.png"
        />
      </Helmet>
      <PricingPage />
    </GlobalLayout>
  );
}
