import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css } from "@linaria/core";
import React from "react";
import { Autoplay, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import RhombusButton from "./RhombusButton";
import { Body, SectionTitle } from "components/rui/typography";

const swiperContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-mask: linear-gradient(
    90deg,
    #0000,
    var(--nuetral-900) 10% 80%,
    #0000
  );
  .swiper {
    padding: 0 2.5rem !important;
    @media (max-width: 600px) {
      padding: 0 1.25rem !important;
    }
  }
  .swiper-wrapper {
    width: 100% !important;
    transform-style: preserve-3d !important;
    -webkit-transform-style: preserve-3d !important;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
    padding-bottom: 2.5rem;
  }
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 2px;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--nuetral-200);
    border-radius: 1rem;
    .swiper-scrollbar-drag {
      background-image: var(--gradient-base);
      border-radius: 1rem;
      width: 100%;
      cursor: grab;
    }
  }
`;

const slideWrapper = css`
  width: 600px !important;
  user-select: none;
  @media (max-width: 600px) {
    width: 300px !important;
  }
`;

const sliderInner = css`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
  @media (max-width: 600px) {
    gap: 1rem;
  }
`;

const authorRow = css`
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 600px) {
    p {
      font-size: 10px;
    }
  }
`;

const avatar = css`
  height: 90px !important;
  aspect-ratio: 1 !important;
  border-radius: 50% !important;
  @media (max-width: 600px) {
    height: 70px !important;
  }
`;

const mobileTestimonialContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  & > div {
    width: 90%;
  }
  & > div:nth-child(even) {
    align-self: flex-start;
  }
  & > div:nth-child(odd) {
    align-self: flex-end;
  }
`;

const buttonContainer = css`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    a {
      width: 90%;
    }
  }
`;

/**
 * @deprecated
 */

const TestimonySlider = ({
  data,
  title,
  subTitle,
  color,
  button1 = null,
  button2 = null,
}) => {
  const { isMobile } = useIsMobile(768);

  return (
    <SectionContainer color={color}>
      <SectionInner className="flex-col">
        {title && subTitle && (
          <TextContainer>
            <SectionTitle>{title}</SectionTitle>
            <Body center large>
              {subTitle}
            </Body>
          </TextContainer>
        )}
        <div className={swiperContainer}>
          <Swiper
            modules={[Autoplay, Scrollbar]}
            autoplay
            loop
            scrollbar={{
              draggable: false,
            }}
            spaceBetween={20}
            slidesPerView={isMobile ? 1 : "auto"}
            centeredSlides={isMobile}
            centeredSlidesBounds={isMobile}
            loopedSlides={15}
            breakpoints={{
              425: {
                slidesPerView: 1.25,
              },
              625: {
                slidesPerView: 1.5,
              },
              900: {
                slidesPerView: 2.5,
              },
              1230: {
                slidesPerView: 3.5,
              },
            }}
          >
            {[...data, ...data, ...data].map((slide) => (
              <SwiperSlide className={slideWrapper} key={slide.author.name}>
                <div className={sliderInner}>
                  <GatsbyImage
                    image={getImage(slide.image)}
                    alt={slide.author.name}
                    objectFit="contain"
                  />
                  <div className={authorRow}>
                    <GatsbyImage
                      image={getImage(slide.author.avatar)}
                      alt={slide.author.name}
                      className={avatar}
                      objectFit="contain"
                    />
                    <TextContainer style={{ width: "60%" }}>
                      <h3>{slide.author.name}</h3>
                      <p>{slide.author.role}</p>
                    </TextContainer>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {button1 && button2 && (
          <div className={buttonContainer}>
            <RhombusButton
              type="primary-dark"
              path={button1.path}
              target="_blank"
              title={button1.text}
              useHref
            />
            <RhombusButton
              type="secondary"
              path={button2.path}
              title={button2.text}
            />
          </div>
        )}
      </SectionInner>
    </SectionContainer>
  );
};

export default TestimonySlider;
