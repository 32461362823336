import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

const D20Page = () => {
  const data = useStaticQuery(graphql`
    query {
      d20Header: file(
        relativePath: { eq: "components/sensors/img/d20-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      d20HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/d20-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      d20Dims: file(
        relativePath: { eq: "components/sensors/img/d20-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);
  const sensor = {
    model: "D20",
    title: "D20 Commercial IoT Sensor for Entry Monitoring",
    specTitle: "D20 Commercial IoT Sensor Specifications",
    imageHeader: data.d20Header,
    imageHeaderMobile: data.d20HeaderMobile,
    heroAlt:
      "Rhombus D20 commercial IoT sensors mounted on a door frame. This continuous data collection device monitors door activity and enables instant notice of abnormal events through AI-powered alerts.",
    image: data.d20Dims,
    dimsAlt:
      "D20 IoT sensor displayed with dimensions. Its does not have a temperature sensor, humidity sensor, lorawan temperature sensor or temperature alarm capabilites.",
    objectPosition: "90%",
    description:
      "Get real-time awareness, instant alerts, and synchronized video clips for entry events. Manage entry points during emergencies and prevent unauthorized access.",
    pdf: "/files/specs/D20.pdf",
    accessoryCompatible: false,
    column1: [
      {
        title: "Dimensions",
        text: "77mm (3.03in) x 27mm (1.06in) x 27.7mm (1.09in)",
      },
      {
        title: "Range",
        text: "Up to 100m (328ft)",
      },
      {
        title: "Battery Type",
        text: "Replaceable 1x3.6V ER14505 (Lithium Battery included)",
      },
      {
        title: "Battery Life",
        text: "Up to 8 years with typical medium-heavy use",
      },
      {
        title: "Weight",
        text: "50g (w/ battery)",
      },
    ],
    column2: [
      {
        title: "Mounting",
        text: "Two-sided tape (included) or screws (included)",
      },
      {
        title: "Weather Resistance",
        text: "IPX4",
      },
      {
        title: "Magnet Sensing Distance",
        text: "Up to 20mm (0.78in)",
      },
      {
        title: "Magnet Dimensions",
        text: "40mm (1.57in) x 15mm (0.59in) x 15mm (0.59in)",
      },
      {
        title: "NDAA, FCC, CE, REACH, RoHS, IC, and RCM Compliant",
      },
      {
        title: "2-Year Warranty Included",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>D20 Entry Monitor | Commercial IoT Sensors | Rhombus</title>
        <meta
          name="description"
          content="Get real-time awareness, alerts for entry events with Rhombus D20 commercial IoT sensor. Integrate entry insights with video footage on a single cloud-managed physical security platform."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-D20.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-D20.png"
        />
      </Helmet>
      <SensorSpecsPage sensor={sensor} />
    </GlobalLayout>
  );
};

export default D20Page;
