import {
  getFormById,
  getItemsfromLocalStorage,
  handleInitialStateStorage,
  loadReCaptcha,
  removeItemFromLocalStorage,
  sendCustomGtagEvent,
  sendPostHogEvent,
  submitForm,
} from "components/form/util/functions";
import FormLoader from "components/loaders/FormLoader";
import { navigate } from "gatsby";
import useLocationSearch from "hooks/useLocationSearch";
import React, { useEffect, useMemo, useState } from "react";
import { useAtom, useAtomValue } from "jotai";

import {
  checkBoxWrapper,
  Form,
  formFooter,
  formRow,
  submitBtn,
} from "./styles/formStyles";
import MultiSelectCheckBoxDropDown from "./MultiSelectCheckBoxDropDown";
import FormInput from "./FormInput";
import FormTextArea from "./FormTextArea";
import FormSelect from "./FormSelect";
import {
  ButtonBase,
  LandingPageButtonV2,
} from "components/rhombus-UI/theme/buttons";
import BooleanCheckBox, { StyledCheckBox } from "./BooleanCheckBox";
import RadioFieldSet from "./RadioFieldSet";
import { isExpandedAtom } from "./atoms";
import { displayChiliPiperAtom } from "components/page/atoms/globalAtoms";
import { TitleMed } from "components/rhombus-UI/theme/typography";
import { DefaultFlexContainer } from "components/rhombus-UI/theme/containers";
import RhombusButton from "components/common/RhombusButton";
import IconCarrot from "components/common/icon-components/IconCarrot";
import Disclaimer from "./Disclaimer";
import { ButtonPrimaryDark } from "components/rui/buttons";

const RhombusForm = ({
  formId,
  customRedirect = "",
  customSubmitText = "",
  useSecondaryButton = false,
  useFormExpansion = false,
  expandFormFieldIndex = 6,
  groupSliceNumber = 4,
  useExpandOnEmail = false,
  darkMode = false,
  filled = false,
  useEventClosedFallback = false,
}) => {
  const [formFieldGroups, setFormFieldGroups] = useState([]);
  const [submitText, setSubmitText] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({});
  const [validationState, setValidationState] = useState({});
  const [consentFieldInfo, setConsentFieldInfo] = useState();
  const [userAgreed, setUserAgreed] = useState(false);
  const [sending, setSending] = useState(false);
  const [redirectLink, setRedirectLink] = useState("/thank-you/");
  const [userInteractedWithForm, setUserInteractedWithForm] = useState(false);
  const { parameters } = useLocationSearch();
  const displayChiliPiper = useAtomValue(displayChiliPiperAtom);
  const [isExpanded, setIsExpanded] = useAtom(isExpandedAtom);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (userInteractedWithForm) {
      sendCustomGtagEvent("form_start");
      sendPostHogEvent("form_start");
    }
  }, [userInteractedWithForm]);

  const handleFocus = () => {
    if (!userInteractedWithForm) {
      setUserInteractedWithForm(true);
    }
  };

  useEffect(() => {
    const previousURL = document.referrer;
    if (previousURL) return;
    try {
      window.ZIWhiteList = ["formcomplete"]; // Use Whitelist variable to tell the ZoomInfo script to re-run only FormComplete.
      window._zi_fc.formId = null;
      window._zi_fc.projectKey = null; // Clear and Form ID and or Project Key stored from the previous initialization to allow FormComplete to re-run.
      window.zitag.GetListOfEntitlements(); // Rerun the Get Entitlement method to finally reload FormComplete.
    } catch (err) {
      console.error("ZI - An error occurred", err);
    }
  }, []);

  useEffect(() => {
    let defaultState = {};
    let defaultValidation = {};
    const fields = [];
    loadReCaptcha();
    getFormById(formId).then((data) => {
      if (data) {
        if (!!data.redirect) {
          setRedirectLink(data.redirect);
        }
        setSubmitText(data.submitText);
        if (data.formFieldGroups) {
          setFormFieldGroups(data.formFieldGroups);
          data.formFieldGroups.map((newItem) =>
            newItem.fields.map((field) => {
              fields.push(field);
              handleInitialStateStorage(field, parameters);
              defaultState = {
                ...defaultState,
                [field.name]: {
                  value: getItemsfromLocalStorage(field.name),
                  objectTypeId: "0-1",
                },
              };

              defaultValidation = {
                ...defaultValidation,
                [field.name]: {
                  valid: "",
                },
              };
            })
          );

          setFormState(defaultState);
          setValidationState(defaultValidation);
          setLoading(false);
          if (data.metaData) {
            const match = data.metaData.find(
              (item) => item.name === "legalConsentOptions"
            );
            if (!match || match === undefined) return;
            setConsentFieldInfo(JSON.parse(match.value));
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (loading || !formState.country) return;
    const {
      country: { value },
    } = formState;
    if (value === "United States" || value === "Canada") return;
    delete formState.state;
  }, [formState.country, loading]);

  const displayedFields = useMemo(() => {
    if (loading) return;
    if (!useFormExpansion) return formFieldGroups;
    if (useExpandOnEmail && !isExpanded) return formFieldGroups.slice(1, 2);
    if (isExpanded && useExpandOnEmail) return formFieldGroups;
    const displayedFormState = formFieldGroups.flatMap((group) =>
      group.fields
        .filter((field) => !field.hidden)
        .map((field) => formState[field.name])
    );

    if (displayedFormState[expandFormFieldIndex].value === "") {
      return formFieldGroups.slice(0, groupSliceNumber);
    }
    return formFieldGroups;
  }, [
    formFieldGroups,
    formState,
    loading,
    useFormExpansion,
    expandFormFieldIndex,
    groupSliceNumber,
    useExpandOnEmail,
    validationState,
    isExpanded,
  ]);

  const renderFields = (fields) => {
    const field = fields.map((item) => {
      if (item.hidden) {
        return (
          <input
            key={item.name}
            name={item.name}
            className="hiddenField"
            onChange={(e) => {
              setFormState({
                ...formState,
                [item.name]: {
                  objectTypeId: item.objectTypeId,
                  value: e.target.value,
                },
              });
            }}
          />
        );
      }
      return renderFieldComponent(item.fieldType, item);
    });
    return field;
  };

  const renderFieldComponent = (type, item) => {
    const commonProps = {
      field: item,
      formState: formState,
      setFormState: setFormState,
      darkMode: darkMode,
      filled: filled,
    };

    const componentMap = {
      select: <FormSelect {...commonProps} />,
      textarea: <FormTextArea {...commonProps} />,
      checkbox: <MultiSelectCheckBoxDropDown {...commonProps} />,
      radio: <RadioFieldSet {...commonProps} />,
      booleancheckbox: <BooleanCheckBox {...commonProps} />,
    };

    return (
      componentMap[type] || (
        <FormInput
          field={item}
          type={type}
          formState={formState}
          setFormState={setFormState}
          validationState={validationState}
          setValidationState={setValidationState}
          key={item.name}
          darkMode={darkMode}
          filled={filled}
        />
      )
    );
  };

  const renderConsentField = () => {
    if (!consentFieldInfo) return;
    const consentText = consentFieldInfo.communicationConsentCheckboxes.map(
      (item) => item.label
    );
    return (
      <div className={formRow}>
        <label className={checkBoxWrapper}>
          <StyledCheckBox
            type="checkbox"
            required
            defaultChecked={userAgreed}
            onChange={() => setUserAgreed(!userAgreed)}
            name={consentFieldInfo.processingConsentCheckboxLabel}
            aria-label={consentFieldInfo.processingConsentCheckboxLabel}
          />
          <span dangerouslySetInnerHTML={{ __html: consentText }} />
        </label>
      </div>
    );
  };

  const errorFound = useMemo(() => {
    return Object.keys(validationState).some(
      (key) => validationState[key].valid === false
    );
  }, [validationState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    const pageUri = window && window.location.href;
    if (errorFound) {
      setSending(false);
      return;
    }
    const agreementSatisfied = userAgreed && consentFieldInfo;
    const consent = agreementSatisfied ? consentFieldInfo : false;
    try {
      const data = await submitForm(
        formId,
        formState,
        pageUri,
        document.title,
        consent
      );
      if (!data) {
        setSending(false);
        return;
      }
      const _redirectLink = customRedirect || redirectLink;
      removeItemFromLocalStorage("sfdccampaignid");
      sendPostHogEvent("full_form_complete");
      sendCustomGtagEvent("full_form_complete");
      sendCustomGtagEvent("full_form_complete_ga4");
      setSending(false);
      if (!displayChiliPiper) {
        navigate(_redirectLink);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Something went wrong.");
      setSending(false);
    }
  };

  const handleClick = (e) => {
    if (errorFound) {
      e.preventDefault();
    }
    if (
      !isExpanded &&
      !!formState.email.value &&
      !errorFound &&
      useExpandOnEmail
    ) {
      e.preventDefault();
      setIsExpanded(true);
    }
  };

  if (!formId && useEventClosedFallback) {
    return (
      <DefaultFlexContainer
        style={{
          height: "200px",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <TitleMed style={{ fontSize: "28px" }}>
          Registration for this event has closed.
        </TitleMed>
        <RhombusButton
          title="More Events"
          renderIcon={() => <IconCarrot />}
          path="/events/"
        />
      </DefaultFlexContainer>
    );
  }

  if (loading || sending) {
    return (
      <FormLoader darkMode={darkMode} useExpandOnEmail={useExpandOnEmail} />
    );
  }

  return (
    <Form
      onSubmit={handleSubmit}
      id={`rhombus-form-${formId}`}
      data-testid={`rhombus-form-test-${formId}`}
      onFocus={handleFocus}
      darkMode={darkMode}
    >
      {!!errorMessage && (
        <span style={{ color: "var(--error-red)" }}>{errorMessage}</span>
      )}
      {displayedFields.map((item, index) => {
        return (
          <div className={formRow} key={index}>
            {renderFields(item.fields)}
          </div>
        );
      })}
      {renderConsentField()}
      <div className={formRow}>
        {useSecondaryButton && darkMode ? (
          <LandingPageButtonV2
            data-testid="submit-button"
            as={"button"}
            type="submit"
            aria-label="submit"
            name="submit"
            title="submit"
            onClick={handleClick}
            style={{ width: "100%" }}
          >
            {!!customSubmitText ? customSubmitText : submitText}
          </LandingPageButtonV2>
        ) : useSecondaryButton ? (
          <>
            <ButtonPrimaryDark
              data-testid="submit-button"
              as={"button"}
              type="submit"
              aria-label="submit"
              name="submit"
              title="submit"
              onClick={handleClick}
              style={{ width: "100%" }}
              className={submitBtn}
            >
              {!!customSubmitText ? customSubmitText : submitText}
            </ButtonPrimaryDark>
          </>
        ) : (
          <ButtonPrimaryDark
            as={"button"}
            data-testid="submit-button"
            type="submit"
            className={submitBtn}
            aria-label="submit"
            name="submit"
            title="submit"
            onClick={handleClick}
          >
            {!!customSubmitText ? customSubmitText : submitText}
          </ButtonPrimaryDark>
        )}
      </div>
      {!useExpandOnEmail && !isExpanded && (
        <div className={formFooter}>
          <Disclaimer formId={formId} />
        </div>
      )}
      {useExpandOnEmail && isExpanded && (
        <div className={formFooter}>
          <Disclaimer formId={formId} />
        </div>
      )}
    </Form>
  );
};

export default RhombusForm;
