import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { Title } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const BladeContainer = styled(SectionContainer)`
  position: relative;
  overflow: hidden;
  min-height: 405px;
  color: var(--white);
`;

const BladeInner = styled(SectionInner)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4rem;
  z-index: 1;

  h2 {
    max-width: 900px;
    @media (max-width: 600px) {
      font-size: 32px;
    }
  }
`;

const backgroundImage = css`
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-position: center !important;
  filter: brightness(0.8) !important;
`;

const TitleBlade = ({ title, image }) => {
  return (
    <BladeContainer>
      <GatsbyImage
        image={getImage(image)}
        alt="r360"
        objectFit="cover"
        className={backgroundImage}
      />
      <BladeInner>
        <Title>{title}</Title>
      </BladeInner>
    </BladeContainer>
  );
};

export default TitleBlade;
