import { styled } from "@linaria/react";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import React from "react";
import PricingTable from "./PricingTable";

const PricingContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const PriceComp = () => {
  return (
    <SectionContainer>
      <SectionInner style={{ flexDirection: "column" }}>
        <TextContainer style={{ textAlign: "center", alignItems: "center" }}>
          <FancyTitleMed style={{ fontSize: "40px" }}>
            Minimize overhead while maximizing coverage
          </FancyTitleMed>
          <MainParagraph>
            See how Rhombus minimizes the costs of traditional security with
            24/7 protection and powerful analytics.
          </MainParagraph>
        </TextContainer>
        <PricingContainer>
          <PricingTable />
          <PricingTable infoName="rhombus" />
        </PricingContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default PriceComp;
