import React from "react";
import { PROVINCES, US_STATES } from "./lib/libraries";
import {
  inputContainer,
  inputWrap,
  selectStyles,
  selectStylesDark,
  selectStylesFilled,
} from "./styles/formStyles";

const COUNTRIES = {
  US: "United States",
  CA: "Canada",
};

const FormSelect = ({
  field,
  formState,
  setFormState,
  darkMode,
  filled,
  useDependentState = true,
}) => {
  if (
    field.name === "state" &&
    useDependentState &&
    (formState.country !== COUNTRIES.US || formState.country !== COUNTRIES.CA)
  )
    return <></>;
  const selectedValue = formState[field.name]?.value;
  const CA = selectedValue === COUNTRIES.CA;
  const US = selectedValue === COUNTRIES.US;
  // eslint-disable-next-line no-nested-ternary
  const optionData = US ? US_STATES : CA ? PROVINCES : null;
  return (
    <>
      <div className={inputContainer} key={field.name}>
        <div className={inputWrap}>
          <select
            data-testid={field.name}
            className={
              darkMode
                ? selectStylesDark
                : filled
                ? selectStylesFilled
                : selectStyles
            }
            onChange={e =>
              setFormState({
                ...formState,
                [field.name]: {
                  objectTypeId: field.objectTypeId,
                  value: e.target.value,
                },
              })
            }
            required={field.required}
            defaultValue=""
            name={field.name}
          >
            <option value="" disabled>
              {field.unselectedLabel}
            </option>
            {field.options.map(option => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {(US || CA) && (
        <div className={inputContainer}>
          <div className={inputWrap}>
            <select
              className={
                darkMode
                  ? selectStylesDark
                  : filled
                  ? selectStylesFilled
                  : selectStyles
              }
              onChange={e =>
                setFormState({
                  ...formState,
                  state: {
                    objectTypeId: field.objectTypeId,
                    value: e.target.value,
                  },
                })
              }
              defaultValue=""
              required
              name="state"
            >
              <option value="" disabled>
                State/Region
              </option>
              {optionData.map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default FormSelect;
