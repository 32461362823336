import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  Title,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const BladeContainer = styled(SectionContainer)`
  position: relative;
  overflow: hidden;
`;

const BladeInner = styled(SectionInner)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4rem;
  z-index: 1;
`;

const specRow = css`
  display: flex;
  gap: 4rem;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
`;

const specCol = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const backgroundImage = css`
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-position: center !important;
`;

const ScalabilityBlade = () => {
  const specs = [
    { title: "50,000+", subTitle: "Locations secured" },
    { title: "3,000+", subTitle: "Global customers" },
    { title: "550TB", subTitle: "of video analyzed per day" },
  ];
  return (
    <BladeContainer>
      <StaticImage
        src="./img/r360-gym-min.png"
        alt="r360"
        objectFit="cover"
        className={backgroundImage}
      />
      <BladeInner>
        <Title color="var(--white)">Built for Enterprise Scalability</Title>
        <div className={specRow}>
          {specs.map(item => (
            <div className={specCol}>
              <TitleMed color="var(--white)">{item.title}</TitleMed>
              <MainParagraph color="var(--white)">
                {item.subTitle}
              </MainParagraph>
            </div>
          ))}
        </div>
      </BladeInner>
    </BladeContainer>
  );
};

export default ScalabilityBlade;
