import React from "react";

const Phone = ({ color = "currentColor", height = "16", width = "16" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.59979 1.48168C5.19579 1.96057 6.02379 2.86015 6.69845 4.46881C6.93845 5.04163 6.71712 5.70838 6.19112 6.04373L5.95712 6.19322C5.40579 6.54512 5.19379 7.25155 5.48245 7.83562C6.13045 9.14596 7.55379 11.3526 10.1838 12.5419C10.7978 12.819 11.5245 12.5439 11.8405 11.9525V11.9525C12.1691 11.338 12.9251 11.0933 13.5591 11.3916C14.4711 11.8209 15.7331 12.5134 16.6298 13.3971C17.1231 13.8833 17.1198 14.6817 16.6391 15.1798C16.1851 15.6507 15.5191 16.2169 14.6518 16.6297C13.8565 17.0074 12.9358 17.0173 12.1031 16.7295C9.68912 15.8954 4.29445 13.2298 1.14979 5.4762C0.905787 4.87494 0.96712 4.1943 1.28112 3.62611C1.65912 2.94283 2.23512 2.04722 2.91245 1.48366C3.40112 1.0762 4.10379 1.08282 4.59979 1.48168Z"
        stroke={color}
        strokeWidth="1.3"
      />
    </svg>
  );
};

export default Phone;
