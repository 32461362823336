import { styled } from "@linaria/react";
import React, { useEffect } from "react";

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const CustomVideo = styled.video`
  height: ${props => props.height};
  width: 100%;
  aspect-ratio: ${props => props.aspectRatio};
  object-fit: cover;
  object-position: center;
  border-radius: 1rem;
  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0;
  }
`;

const Video = ({
  src,
  autoPlay = true,
  loop = true,
  muted = true,
  aspectRatio = 1 / 1,
  height = "600px",
  playbackRate = 2,
}) => {
  useEffect(() => {
    const video = document.getElementById("myVideo");
    video.playbackRate = playbackRate;
  }, []);

  return (
    <VideoWrapper>
      <CustomVideo
        id="myVideo"
        src={src}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        playsInline
        aspectRatio={aspectRatio}
        height={height}
      />
    </VideoWrapper>
  );
};

export default Video;
