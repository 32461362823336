import { css } from "@linaria/core";
import React from "react";
import SlideInContainer from "components/common/SlideInContainer";
import { StaticImage } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import { FancyTitleMed } from "components/rhombus-UI/theme/typography";
import TextLinkV2 from "components/common/TextLinkV2";

const contentClass = css`
  padding: 65px 0px;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 65px;

  > div {
    width: 100%;
  }

  > div:last-child {
    width: 100%;
    div {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 500px) {
    padding: 65px 15px;
  }

  > div:first-child {
    align-self: center;
  }
`;

const headerContainer = css`
  display: flex;
  justify-content: center;

  h1 {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
`;

const logoWrapper = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 925px) {
    width: 30%;
  }
  @media (max-width: 650px) {
    width: 45%;
  }
`;

const imageContainerClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    row-gap: 40px;
  }
`;

const imageClass = css`
  width: 135px;
  pointer-events: none;
`;

export default function TrustedStripe() {
  return (
    <section style={{ display: "grid", background: "var(--nuetral-100)" }}>
      <PageContent>
        <div className={contentClass}>
          <SlideInContainer>
            <div className={headerContainer}>
              <FancyTitleMed>Trusted by IT Professionals</FancyTitleMed>
            </div>
          </SlideInContainer>
          <SlideInContainer>
            <div className={imageContainerClass}>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/amazon.png"
                  style={{ maxWidth: "100px", top: "5px" }}
                  alt="Amazon logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/aramark-black.png"
                  className={imageClass}
                  alt="Aramark logo"
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/hurley.png"
                  style={{ width: "100px" }}
                  alt="Hurley logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/lafs-black.png"
                  className={imageClass}
                  alt="The Los Angeles Film School logo"
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/goodwill-2-min.png"
                  style={{ width: "125px" }}
                  alt="Goodwill logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>

              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/stryker.png"
                  style={{ width: "110px" }}
                  alt="Stryker logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>
            </div>
          </SlideInContainer>
          <SlideInContainer>
            <TextLinkV2 path="/live-demo/" title="Schedule a Live Demo" />
          </SlideInContainer>
        </div>
      </PageContent>
    </section>
  );
}
