import { styled } from "@linaria/react";
import {
  FlexColumn,
  FlexRow,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  SectionTitle,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import React from "react";
import icon1 from "./assets/money-chart.svg";
import icon2 from "./assets/location-alert.svg";
import icon3 from "./assets/handyman.svg";
import icon4 from "./assets/time.svg";

const STAT_CARD_DATA = [
  { title: "150%", subTitle: "Productivity Increase" },
  { title: "40%", subTitle: "Lower Costs vs Legacy Systems" },
  { title: "18x", subTitle: "Faster Incident Resolution" },
];

const ICON_BOXES = [
  {
    icon: { src: icon1 },
    text: "Multiple vendors and systems drain your resources",
  },
  {
    icon: { src: icon2 },

    text: "Current security tools can't keep up with your growth",
  },
  {
    icon: { src: icon3 },

    text: "Legacy systems demand constant maintenance and on-site support",
  },
  {
    icon: { src: icon4 },

    text: "Investigating incidents takes days instead of minutes",
  },
];

const CardContainer = styled(FlexRow)`
  gap: 5rem;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 820px) {
    gap: 1.25rem;
  }
`;

const Card = styled(FlexColumn)`
  background: var(--nuetral-100);
  border-radius: 1rem;
  height: 329px;
  width: 329px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2.5rem;
  h2 {
    color: var(--blue-500);
    font-size: 80px;
  }
  h3 {
    line-height: 1.4;
    width: 150px;
  }
  @media (max-width: 820px) {
    width: calc(50% - 1.25rem);
    height: unset;
    padding: 1.25rem;
    aspect-ratio: 1;
    gap: 1.25rem;
    h2 {
      font-size: 42px;
      font-weight: 900;
    }
    h3 {
      font-size: 18px;
    }
  }
`;

const IconBoxContainer = styled(FlexRow)`
  gap: 2.5rem;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const IconBox = styled(FlexColumn)`
  flex-basis: 25%;
  gap: 24px;
  max-width: 316px;
  p {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
  }
  @media (max-width: 1024px) {
    flex-basis: 50%;
    text-align: center;
    align-items: center;
  }
  @media (max-width: 820px) {
    flex-basis: 100%;
  }
`;

const StatCards = () => {
  return (
    <CardContainer>
      {STAT_CARD_DATA.map((card) => (
        <Card>
          <h2>{card.title}</h2>
          <TitleSmall>{card.subTitle}</TitleSmall>
        </Card>
      ))}
    </CardContainer>
  );
};

const IconBoxes = () => {
  return (
    <IconBoxContainer>
      {ICON_BOXES.map((item) => (
        <IconBox>
          <img src={item.icon.src} width={60} height={60} />
          <MainParagraph>{item.text}</MainParagraph>
        </IconBox>
      ))}
    </IconBoxContainer>
  );
};

const HighlightsF = () => {
  return (
    <SectionContainer>
      <SectionInner className="flex-col" style={{ gap: "5rem" }}>
        <StatCards />
        <SectionTitle>
          Modern Surveillance Shouldn't Be Complicated
        </SectionTitle>
        <IconBoxes />
      </SectionInner>
    </SectionContainer>
  );
};

export default HighlightsF;
