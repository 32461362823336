import Apps from "components/integrations/marketplace/Apps";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";

import {
  currentIndexAtom,
  filterTitleAtom,
  loadingAtom,
  selectionsAtom,
} from "./atoms/atoms";
import Categories from "./Categories";
import { BREAK_POINT, CATEGORIES, WORKS_WITH } from "./lib/constants";
import Search from "./Search";
import {
  Chip,
  ChipContainer,
  FeedBody,
  FeedCol,
  FeedFooter,
  FeedHeader,
  FeedTitle,
  PaginationButtonLeft,
  PaginationButtonRight,
  PaginationWrapper,
} from "./styles/MarketPlace-css";
import { FilterButton } from "components/rhombus-UI/theme/ui-elements";
import Adjustments from "components/common/icon-components/Adjustments";
import CommonModal from "components/common/modals/CommonModal";
import IconSliderArrow from "components/common/icon-components/IconSliderArrow";
import Arrow from "components/common/icon-components/Arrow";
import CloseIcon from "components/common/icon-components/Close";
import IconCheckMark from "components/common/icon-components/IconCheckMark";

const IntegrationFeed = ({
  data,
  constantData,
  setCurrentFilter,
  setMutableData,
  currentFilter,
}) => {
  const { isMobile } = useIsMobile(BREAK_POINT);
  const [modalOpen, setModalOpen] = useState(false);
  const [feedData, setFeedData] = useState([]);

  const filterTitle = useAtomValue(filterTitleAtom);
  const setLoading = useSetAtom(loadingAtom);

  const [currentIndex, setCurrentIndex] = useAtom(currentIndexAtom);
  const [selections, setSelections] = useAtom(selectionsAtom);

  const [iconStatus, setIconStatus] = useState({});

  useEffect(() => {
    if (Array.isArray(data[0])) {
      setFeedData(data[currentIndex]);
    } else {
      setFeedData(data);
    }

    setLoading(false);
  }, [data, currentIndex]);

  const renderFilters = () => {
    if (isMobile) {
      return (
        <FilterButton
          aria-label="filter"
          title="filter"
          name="filter"
          onClick={() => setModalOpen(!modalOpen)}
          active={modalOpen}
        >
          <Adjustments />
        </FilterButton>
      );
    }
    return <></>;
  };

  const renderPagination = () => {
    return data.map((_, index) => {
      return (
        <div
          key={index}
          onClick={() => setCurrentIndex(index)}
          style={currentIndex === index ? { color: "var(--blue-500)" } : {}}
        >
          {index + 1}
        </div>
      );
    });
  };

  const handlePaginate = (action) => {
    if (action === "increase" && currentIndex < data.length - 1) {
      return setCurrentIndex(currentIndex + 1);
    }
    if (action === "decrease" && currentIndex !== 0) {
      return setCurrentIndex(currentIndex - 1);
    }
  };

  const renderModal = () => {
    if (!isMobile) return <></>;
    return (
      <CommonModal onClose={() => setModalOpen(false)} isOpen={modalOpen}>
        <Categories data={constantData} setCurrentFilter={setCurrentFilter} />
      </CommonModal>
    );
  };

  const handleMultiSelect = (filter) => {
    const checked = selections[filter].checked;
    setSelections({ ...selections, [filter]: { checked: !checked } });
  };

  const handleMouseOver = (filter) => {
    setIconStatus({ ...iconStatus, [filter]: { closed: true } });
  };

  const handleMouseLeave = (filter) => {
    setIconStatus({ ...iconStatus, [filter]: { closed: false } });
  };

  const renderDescription = () => {
    if (CATEGORIES[filterTitle] === "" || !CATEGORIES[filterTitle]) {
      return CATEGORIES.All;
    }
    return CATEGORIES[filterTitle];
  };

  return (
    <FeedCol>
      <FeedHeader>
        <TextContainer>
          <FeedTitle>{filterTitle}</FeedTitle>
          <MainParagraph>{renderDescription()}</MainParagraph>
        </TextContainer>
        {renderFilters()}
        {renderModal()}
      </FeedHeader>
      <Search currentFilter={currentFilter} setMutableData={setMutableData} />
      <ChipContainer>
        {WORKS_WITH.map((item) => (
          <Chip>
            <input
              type="checkbox"
              onChange={() => handleMultiSelect(item)}
              onMouseEnter={() => handleMouseOver(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              checked={selections[item]?.checked}
            />
            <span>{item}</span>
            {selections[item]?.checked ? (
              <>
                {iconStatus[item]?.closed ? <CloseIcon /> : <IconCheckMark />}
              </>
            ) : (
              <></>
            )}
          </Chip>
        ))}
      </ChipContainer>
      <FeedBody>
        <Apps data={feedData} />
      </FeedBody>
      <FeedFooter>
        {data.length > 1 && Array.isArray(data[0]) ? (
          <PaginationWrapper>
            <PaginationButtonLeft onClick={() => handlePaginate("decrease")}>
              <Arrow color="var(--primary-bg)" />
            </PaginationButtonLeft>
            {renderPagination()}
            <PaginationButtonRight onClick={() => handlePaginate("increase")}>
              <Arrow color="var(--primary-bg)" />
            </PaginationButtonRight>
          </PaginationWrapper>
        ) : (
          <></>
        )}
      </FeedFooter>
    </FeedCol>
  );
};

export default IntegrationFeed;
