import React, { useState } from "react";
import { css, cx } from "@linaria/core";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PageContent from "components/page/PageContent";
import SlideInContainer from "components/common/SlideInContainer";
import { FancyTitleMed } from "components/rhombus-UI/theme/typography";
import { TitleMed } from "components/rui/typography";

const sectionClass = css`
  padding: 5rem 0px;

  @media (max-width: 700px) {
    padding: 50px 0px;
  }
`;

const headerContainerClass = css`
  display: flex;
  justify-content: center;
  padding-bottom: 54px;

  @media (max-width: 700px) {
    padding-bottom: 20px;
  }
`;

const headerClass = css`
  text-align: center;
`;

const contentClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const optionsContainer = css`
  width: 100%;
  max-width: 779px;
`;

const optionsClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    background: transparent;
    padding: 10px 0px;
  }

  @media (max-width: 415px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    button {
      width: 45%;
    }
  }
`;

const optionClass = css`
  color: var(--body-subtle);
  font-weight: 500;
  @media (max-width: 415px) {
    width: fit-content;
    margin: 0 auto;
  }
`;

const optionClassActive = cx(
  optionClass,
  css`
    color: var(--primary-bg);
    @media (max-width: 415px) {
      border-bottom: 2px solid var(--primary-bg);
      padding-bottom: 5px;
    }
  `
);

const lineContainer = css`
  display: block;
  height: 2px;
  background: #cecece;
  @media (max-width: 415px) {
    display: none;
  }
`;

const lineClass = css`
  height: 2px;
  margin-top: 10px;
  background: var(--primary-bg);
  border: none;
  transition: 0.3s ease-in-out;
`;

const foodAndBevLine = cx(
  lineClass,
  css`
    margin-left: 0%;
    width: 165px;

    @media (max-width: 700px) {
      width: 128.8px;
    }
  `
);

const commercialLine = cx(
  lineClass,
  css`
    width: 99.3px;
    margin-left: 37%;

    @media (max-width: 700px) {
      width: 77.2px;
    }
  `
);
const educationLine = cx(
  lineClass,
  css`
    width: 84px;
    margin-left: 65%;

    @media (max-width: 700px) {
      width: 65.3px;
    }
  `
);
const storageLine = cx(
  lineClass,
  css`
    margin-left: calc(100% - 66.6px);
    width: 66.6px;

    @media (max-width: 700px) {
      margin-left: calc(100% - 51.8px);
      width: 51.8px;
    }
  `
);

const itemContainer = css`
  display: none;
  width: 100%;
  gap: 45px;
  flex-direction: column;
  align-items: center;
  row-gap: 64px;
  padding-top: 36px;

  animation: fadein 1s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 700px) {
    row-gap: 30px;
  }
`;

const itemContainerActive = cx(
  itemContainer,
  css`
    display: flex;
  `
);

const hiddenText = css`
  text-align: center;
  max-width: 691px;
  display: none;

  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const activeText = cx(
  hiddenText,
  css`
    display: block;
  `
);

const hiddenImageClass = css`
  display: none;
  width: 100%;
  max-width: 819px;

  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const activeImageClass = cx(
  hiddenImageClass,
  css`
    display: block;
  `
);

export default function SensorOverviewIndustrySection() {
  const data = useStaticQuery(graphql`
    query {
      foodAndBev: file(
        relativePath: {
          eq: "components/sensors/overview/img/food-and-bev-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      commercial: file(
        relativePath: {
          eq: "components/sensors/overview/img/commercial-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      education: file(
        relativePath: {
          eq: "components/sensors/overview/img/education-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      storage: file(
        relativePath: { eq: "components/sensors/overview/img/storage-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `);
  const [industry, setIndustry] = useState(0);

  const options = [
    {
      industry: "Food and Beverage",
      image: getImage(data.foodAndBev),
      alt: "Kitchen equipped with IoT sensors for monitoring temperature and humidity, enhancing food safety through advanced IoT applications.",
      text: "Maintain air quality and food safety in the kitchen with environmental sensors. Keep air quality in check and monitor refrigerator and freezer temperatures, to ensure proper food preservation.",
    },
    {
      industry: "Commercial",
      image: getImage(data.commercial),
      alt: "Console reading of door sensor in an office reception, showcasing sensors used for monitoring entry and enhancing security.",
      text: "Gain real-time awareness on the premises. Monitor entry points, receive notifications for doors left open, and detect unauthorized access with motion sensing.",
    },
    {
      industry: "Education",
      image: getImage(data.education),
      alt: "Console screen displaying library environment details via IoT sensors, capturing temperature, humidity, and motion for real-time monitoring.",
      text: "Keep students and staff safe with a comprehensive picture of what’s happening on campus. Quickly detect and track unusual events, bullying, vaping, and more.",
    },
    {
      industry: "Storage",
      image: getImage(data.storage),
      alt: "Console screen showing storage facility details with IoT sensors, alerting after-hours movement detection with a siren for security.",
      text: "Equip employees with an easy-to-use solution to monitor facilities at scale. See what’s happening in real-time and communicate with customers on site from anywhere.",
    },
  ];

  return (
    <section className={sectionClass}>
      <PageContent>
        <SlideInContainer>
          <div className={headerContainerClass}>
            <TitleMed className={headerClass}>
              Integrate IoT Sensors for Diverse Industry Applications
            </TitleMed>
          </div>
        </SlideInContainer>
        <div className={contentClass}>
          <div className={optionsContainer}>
            <div className={optionsClass}>
              {options.map((item, index) => {
                return (
                  <button key={index} onClick={() => setIndustry(index)}>
                    <h2
                      className={
                        industry === index ? optionClassActive : optionClass
                      }
                    >
                      {item.industry}
                    </h2>
                  </button>
                );
              })}
            </div>
            <div className={lineContainer}>
              <hr
                className={
                  industry === 0
                    ? foodAndBevLine
                    : industry === 1
                      ? commercialLine
                      : industry === 2
                        ? educationLine
                        : storageLine
                }
              />
            </div>
          </div>
          {options.map((item, index) => (
            <div
              key={item.industry}
              className={
                industry === index ? itemContainerActive : itemContainer
              }
            >
              <p className={industry === index ? activeText : hiddenText}>
                {item.text}
              </p>
              <GatsbyImage
                image={item.image}
                alt={item.alt}
                className={
                  industry === index ? activeImageClass : hiddenImageClass
                }
              />
            </div>
          ))}
        </div>
      </PageContent>
    </section>
  );
}
