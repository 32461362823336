import React from "react";
import RhombusButton from "components/common/RhombusButton";
import {
  CTA_BUTTON_COPY_3,
  CTA_LINK_4,
} from "components/data/landing-page-data";
import {
  FlexCenter,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { styled } from "@linaria/react";
import { BulletList, ListItem } from "./CTASection2";
import IconCheckMark from "components/common/icon-components/IconCheckMark";
import useIsMobile from "hooks/useIsMobile";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light-bottom);
  padding-bottom: ${(props) => (props.center ? "5rem" : 0)};
`;

const HeroInner = styled(SectionInner)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const HeroLeft = styled(SectionCol)`
  padding-bottom: ${(props) => (props.center ? 0 : "5rem")};
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    width: 100%;
    padding-bottom: 0;
    text-align: center;
    .text {
      align-items: center;
    }
  }
`;

const HeroRight = styled(FlexCenter)`
  align-items: ${(props) =>
    props.center ? "center !important" : "flex-end !important"};
  justify-content: flex-end !important;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center !important;
  }
`;

const BadgeRow = styled.div`
  display: flex;
  gap: 1.25rem;
`;

function Hero({
  title = "Future proof your security with flexible, cloud-driven protection",
  blurb = "Outdated security systems leave your organization ill-equipped for growth and vulnerable to evolving threats. See how Rhombus is changing enterprise security with a flexible, cloud-driven platform that delivers scalable, cutting-edge protection.",
  image,
  alt,
  ctaCopy = CTA_BUTTON_COPY_3,
  ctaLink = CTA_LINK_4,
  bulletPoints,
  center = false,
}) {
  const badgeProps = {
    height: 77,
    width: 67,
    quality: 100,
    objectFit: "contain",
    loading: "eager",
    placeholder: "blurred",
  };

  const { isMobile } = useIsMobile(1200);

  return (
    <HeroContainer center={center}>
      <HeroInner>
        <HeroLeft center={center}>
          <TextContainer style={{ gap: "2rem" }} className="text" width="665px">
            <FancyPageTitle>{title}</FancyPageTitle>
            <MainParagraph>{blurb}</MainParagraph>
            {bulletPoints && !isMobile && (
              <BulletList>
                {bulletPoints.map((item) => (
                  <ListItem>
                    <IconCheckMark color="var(--teal-500)" />
                    {item}
                  </ListItem>
                ))}
              </BulletList>
            )}
            <RhombusButton
              useTitleCase={false}
              title={ctaCopy}
              path={ctaLink}
              style={{ padding: "10px" }}
              type="primary-dark"
            />
            <BadgeRow>
              <StaticImage
                src="../../common/img/g2-badges/badge-1.png"
                {...badgeProps}
              />
              <StaticImage
                src="../../common/img/g2-badges/badge-11.png"
                {...badgeProps}
              />
              <StaticImage
                src="../../common/img/g2-badges/badge-10.png"
                {...badgeProps}
              />
              <StaticImage
                src="../../common/img/g2-badges/badge-9.png"
                {...badgeProps}
              />
            </BadgeRow>
          </TextContainer>
        </HeroLeft>
        <HeroRight center={center}>
          {image ? (
            <GatsbyImage
              image={getImage(image)}
              objectFit="contain"
              loading="eager"
              objectPosition={center ? "center" : "bottom"}
              placeholder="blurred"
              style={{ maxWidth: "600px", width: "100%" }}
              alt={alt ?? blurb}
            />
          ) : (
            <StaticImage
              src="./assets/mixed-products.png"
              objectFit="contain"
              quality={100}
              loading="eager"
              objectPosition="bottom"
              placeholder="blurred"
              style={{ maxWidth: "600px" }}
              alt={alt ?? blurb}
            />
          )}
        </HeroRight>
      </HeroInner>
    </HeroContainer>
  );
}

export default Hero;
