import React from "react";
import { styled } from "@linaria/react";

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Loader = styled.span`
  &:nth-of-type(3),
  &:last-of-type {
    width: 100%;
  }
  height: 53px;
  width: calc(50% - 20px);
  flex-grow: 1;
  overflow: hidden;
  background-size: 300% 100%;
  border-radius: 4px;
  background-image: ${props =>
    props.darkMode
      ? "linear-gradient(90deg, var(--nuetral-900), var(--blue-800), var(--nuetral-900), var(--blue-800))"
      : "linear-gradient(90deg, #f1f1f1,var(--white), #f1f1f1, var(--white))"};
  animation: animateBg 2s infinite;
  @keyframes animateBg {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

const FormLoader = ({ darkMode, useExpandOnEmail, fieldCount = 8 }) => {
  const skeletonFieldsCount = useExpandOnEmail ? 2 : fieldCount;
  return (
    <LoaderContainer>
      {Array.from({ length: skeletonFieldsCount }, (_, index) => (
        <Loader key={index} darkMode={darkMode} />
      ))}
    </LoaderContainer>
  );
};

export default FormLoader;
