import {
  checkExpiration,
  formatISODateWithTimeZone,
} from "components/hybrid-event/util";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";

const DateRow = ({ date, type }) => {
  const [expired, setExpired] = useState(false);

  // useEffect(() => {
  //   const getIsExpired = async () => {
  //     const expired = await checkExpiration(date);
  //     setExpired(expired);
  //   };
  //   getIsExpired();
  // }, []);

  return (
    !!date &&
    type === "Live Webinar" && (
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <StaticImage
          src="./assets/time.png"
          alt="time"
          placeholder="blurred"
          loading="eager"
          objectFit="contain"
          style={{
            height: "20px",
            aspectRatio: "1",
            flexShrink: 0,
          }}
        />
        <p>
          {expired
            ? "This event has passed"
            : formatISODateWithTimeZone(date, "America/Los_Angeles")}
        </p>
      </div>
    )
  );
};

export default DateRow;
