import FeaturedEventAndBlog from "../featuredSections/FeaturedEventAndBlog";
import FeaturedPages from "../featuredSections/FeaturedPages";
import FeaturedPartners from "../featuredSections/FeaturedPartnes";

const SOLUTION_MENU_DATA = [
  {
    title: "why rhombus",
    links: [
      { name: "Overview", path: "/why-rhombus/" },
      { name: "Customer Stories", path: "/case-studies/", tag: "new" },
      { name: "About Us", path: "/about/" },
      { name: "Trust", path: "/trust/" },
    ],
  },
  {
    title: "by industry",
    links: [
      { name: "Education", path: "/industries/education/" },
      {
        name: "Food & Beverage",
        path: "/industries/food-and-beverage/",
      },
      { name: "Government", path: "/industries/government/" },
      { name: "Healthcare", path: "/industries/healthcare/" },
      { name: "Manufacturing", path: "/industries/manufacturing/" },
      { name: "Retail", path: "/industries/retail/" },
      {
        name: "Storage & Warehouse",
        path: "/industries/storage-and-warehouse/",
      },
    ],
  },
  {
    title: "for teams",
    links: [
      { name: "IT", path: "/teams/IT/" },
      { name: "Security", path: "/teams/security/" },
      { name: "C-Suite", path: "/teams/c-suite/" },
      {
        name: "Operations & Facilities",
        path: "/teams/operations-and-facilities/",
      },
    ],
  },
];

const PRODUCTS_MENU_DATA = [
  {
    title: "platform",
    links: [
      { name: "Console", path: "/console/" },
      { name: "Integrations", path: "/integrations/", tag: "popular" },
      { name: "AI Analytics", path: "/ai-analytics/" },
    ],
  },
  {
    title: "cameras",
    links: [
      { name: "Overview", path: "/cameras/" },
      { name: "Dome Cameras", path: "/cameras/dome-cameras/" },
      { name: "Fisheye Cameras", path: "/cameras/fisheye-cameras/" },
      { name: "Bullet Cameras", path: "/cameras/bullet-cameras/" },
      {
        name: "Multisensor Cameras",
        path: "/cameras/multisensor-cameras/",
      },
      {
        name: "Relay Core Connector",
        path: "/cameras/relay-connector/n100/",
        tag: "new",
      },
      {
        name: "Relay Lite Connector",
        path: "/cameras/relay-connector/lite/",
        tag: "new",
      },
      { name: "Accessories", path: "/accessories/" },
      {
        name: "Compare Cameras",
        path: "/cameras/compare-cameras/",
      },
    ],
  },
  {
    title: "sensors",
    links: [
      { name: "Overview", path: "/sensors/" },
      { name: "Audio Gateway", path: "/sensors/a100/" },
      {
        name: "Environmental Monitoring",
        path: "/sensors/environmental-monitoring/",
      },
      {
        name: "Motion Sensing",
        path: "/sensors/m15/",
      },
      { name: "Entry Monitoring", path: "/sensors/d20/" },
      { name: "Smart Button", path: "/sensors/b10/" },
      { name: "Accessories", path: "/accessories/sensors/" },
    ],
  },
  {
    title: "access control",
    links: [
      { name: "Overview", path: "/access-control/" },
      {
        name: "Door Readers & Intercoms ",
        path: "/access-control/door-readers/",
      },
      { name: "Door Controller", path: "/access-control/dc20/" },
      {
        name: "Secure Cards",
        path: "/access-control/secure-cards/",
      },
      {
        name: "Secure Fobs",
        path: "/access-control/secure-fobs/",
      },
      { name: "Accessories", path: "/accessories/access-control/" },
    ],
  },
  {
    title: "alarm monitoring",
    links: [{ name: "Overview", path: "/alarm-monitoring/" }],
  },
];

const RESOURCES_MENU_DATA = [
  {
    title: "support",
    links: [
      { name: "Contact Sales", path: "/contact-sales/" },
      {
        name: "Knowledge Base",
        path: "https://support.rhombussystems.com/hc/en-us",
        external: true,
      },
      {
        name: "API Reference",
        path: "https://apidocs.rhombussystems.com/docs",
        external: true,
      },
    ],
  },
  {
    title: "learning",
    links: [
      { name: "Blog", path: "/blog/" },
      { name: "Events", path: "/events/" },
      { name: "Webinars", path: "/webinars/" },
      {
        name: "Instant Demo",
        path: "/instant-demo/",
        tag: "popular",
      },
      {
        name: "Physical Security Report ",
        path: "/physical-security-report/",
      },
      {
        name: "License Comparison",
        path: "/license-comparison/",
        tag: "new",
      },
    ],
  },
];

const PARTNERS_MENU_DATA = [
  {
    title: "Learn",
    links: [
      {
        name: "Partner Program",
        path: "/partner-program/",
      },
    ],
  },
  {
    title: "For Partners",
    links: [
      {
        name: "Partner Portal",
        path: "https://partners.rhombussystems.com/English/",
        external: true,
      },
      {
        name: "Apply Today",
        path: "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
        external: true,
      },
    ],
  },
  {
    title: "Training",
    links: [
      {
        name: "RCTP Program",
        path: "/rctp/",
        tag: "new",
      },
    ],
  },
];

export const MEGA_LINKS = [
  {
    name: "Solutions",
    menuData: SOLUTION_MENU_DATA,
    showFeaturedSection: true,
    featuredSection: FeaturedPages,
  },
  {
    name: "Products",
    menuData: PRODUCTS_MENU_DATA,
    showFeaturedSection: false,
    featuredSection: null,
  },
  {
    name: "Resources",
    menuData: RESOURCES_MENU_DATA,
    showFeaturedSection: true,
    featuredSection: FeaturedEventAndBlog,
  },
  {
    name: "Partners",
    menuData: PARTNERS_MENU_DATA,
    showFeaturedSection: true,
    featuredSection: FeaturedPartners,
  },
];
export const NAV_LINKS = [{ name: "pricing", path: "/pricing/" }];

export const SURVEILLANCE_LANDING_PAGE_LINKS = [
  { name: "Overview", path: "#overview" },
  { name: "Platform Features", path: "#platform-features" },
  { name: "Testimonials", path: "#testimonials" },
];

export const SURVEILLANCE_LANDING_PAGE_LINKS_2 = [
  { name: "Platform", path: "#platform" },
  { name: "Features", path: "#platform-features" },
  { name: "Testimonials", path: "#testimonials" },
];
