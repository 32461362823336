import React from "react";
import { css } from "@linaria/core";
import PageContent from "components/page/PageContent";
import SlideInContainer from "components/common/SlideInContainer";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RhombusButton from "components/common/RhombusButton";
import { SectionTitle, TitleMed } from "components/rui/typography";

const sectionClass = css`
  padding: 5rem 1.25rem;
`;

const titleContainer = css`
  margin-bottom: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  h2 {
    max-width: 800px;
  }
`;

const contentClass = css`
  display: flex;
  justify-content: center;
  column-gap: 5rem;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    column-gap: 100px;
  }
  @media (max-width: 700px) {
    justify-content: space-around;
    column-gap: unset;
  }
`;

const columnClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 47px;
  width: calc(100% / 3);
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  > div {
    width: 100%;
  }

  h2 {
    margin-bottom: 5px;
  }
  h1 {
    font-size: 24px;
    line-height: 33px;
  }
  h2,
  p {
  }
`;

const imageClass = css`
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 30px;
  transition: all 250ms;
  max-width: 221px;
  :hover {
    border-color: var(--blue-500);
  }

  @media (max-width: 1000px) {
    margin: 25px;
  }
`;

const imageContainer = css`
  display: flex;
  justify-content: center;

  div {
    width: fit-content;
  }
`;

const title = css`
  h3,
  a {
    text-decoration: none;
    color: var(--blue-500);
    font-weight: 900;
    font-size: 32px;
    line-height: 1.5;
    cursor: pointer;
  }
`;

export default function DoorReaderModels() {
  const data = useStaticQuery(graphql`
    query {
      dr20Image: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/dr20-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      dr40Image: file(
        relativePath: {
          eq: "components/access-control/door-readers/overview/img/dr40-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `);

  const sensorModels = [
    {
      image: data.dr20Image,
      alt: "Rhombus DR20 access control door card reader, a sleek and modern device that's part of a comprehensive access control system. It supports various access methods and integrates with remote management capabilities, ensuring only authorized personnel can gain access to secured premises.",
      name: "DR20 Door Reader",
      hwVariation: "DR20",
      doorbell: false,
      videoIntercom: false,
      price: "249",
      link: "/access-control/door-readers/dr20/",
    },
    {
      image: data.dr40Image,
      alt: "Rhombus DR40 door reader, featuring advanced access control card readers with an adjustable read range and vandal-resistant design. This smart door access solution enhances security while providing reliable performance for managing entry points.",
      name: "DR40 Video Intercom",
      hwVariation: "DR40",
      doorbell: true,
      videoIntercom: true,
      price: "899",
      link: "/access-control/door-readers/dr40/",
    },
  ];

  return (
    <section className={sectionClass}>
      <PageContent>
        <div className={titleContainer}>
          <SectionTitle>
            Streamlined Access and Visitor Management with Access Control
            Readers
          </SectionTitle>
        </div>
        <div className={contentClass}>
          {sensorModels.map((model) => {
            const imageRef = getImage(model.image);
            return (
              <div className={columnClass} key={model.name}>
                <SlideInContainer>
                  <Link to={model.link} className={imageContainer}>
                    <GatsbyImage
                      image={imageRef}
                      alt={model.alt}
                      className={imageClass}
                      imgStyle={{ width: "fit-content" }}
                      objectFit="contain"
                    />
                  </Link>
                </SlideInContainer>
                <SlideInContainer>
                  <div className={title}>
                    <TitleMed>
                      <Link to={model.link}>{model.name}</Link>
                    </TitleMed>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <div>
                    <h4>Wave to Unlock</h4>
                    <p>Included</p>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <div>
                    <h4>NFC</h4>
                    <p>Credentials</p>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <div>
                    <h4>BLE</h4>
                    <p>Credentials</p>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <div>
                    <h4>Doorbell</h4>
                    <p>{model.doorbell ? "Included" : "-"}</p>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <div>
                    <h4>Video Intercom</h4>
                    <p>{model.videoIntercom ? "Included" : "-"}</p>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <div>
                    <h4>IP65</h4>
                    <p>Weatherproof</p>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <div>
                    <h4>${model.price}</h4>
                    <p>Starting Price</p>
                  </div>
                </SlideInContainer>
                <SlideInContainer>
                  <RhombusButton
                    type="primary-dark"
                    path={model.link}
                    title={`Explore ${model.hwVariation}`}
                  />
                </SlideInContainer>
              </div>
            );
          })}
        </div>
      </PageContent>
    </section>
  );
}
