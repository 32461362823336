import TextLink from "components/common/TextLink";
import PageContent from "components/page/PageContent";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import React from "react";

import CustomerStoriesSwiperCarousel from "./CustomerStoriesSwiperCarousel";
import CustomerStorySlide from "./CustomerStorySlide";

const sectionClass = css`
  padding: 108px 0px 83px;

  @media (max-width: 700px) {
    padding: 60px 0px 25px;
  }
`;

const headerClass = css`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 50.36px;
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px auto 60px auto;

  @media (max-width: 700px) {
    margin: 0px auto 10px auto;
    font-size: 24px;
  }
`;

const arrowLinkContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 83px;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    margin-top: 25px;
  }

  p {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
  }
`;

const arrowClass = css`
  height: 12px;
  padding-left: 12px;
  padding-right: 6px;
  transition: padding 0.3s ease-in-out;
  .${arrowLinkContainer}:hover & {
    padding-left: 20px;
  }
`;

/**
 * @deprecated
 */

export default function CustomerStoriesCarousel({
  slides,
  backgroundColor,
  title,
}) {
  return (
    <section className={sectionClass} style={{ background: backgroundColor }}>
      <PageContent>
        <h1 className={headerClass}>{title}</h1>
        <CustomerStoriesSwiperCarousel>
          {slides.map((slide, i) => {
            return <CustomerStorySlide slide={slide} key={i} />;
          })}
        </CustomerStoriesSwiperCarousel>
        <TextLink
          to="/case-studies/"
          target="_blank"
          className={arrowLinkContainer}
        >
          <p>View Customer Stories</p>
          <div className={arrowClass}>
            <StaticImage
              src="../../common/img/blue-arrow.png"
              alt="arrow"
              style={{ width: "7px" }}
              placeholder="blurred"
            />
          </div>
        </TextLink>
      </PageContent>
    </section>
  );
}
