import React from "react";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import CameraDetail from "components/cameras/detail/CameraDetailPage";
import { FlexColumn } from "components/rui/layout";
import { Body } from "components/rui/typography";
import RhombusButton from "components/common/RhombusButton";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

const RelayConnectorLight = () => {
  const data = useStaticQuery(graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/relay-lite-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/relay-lite-header-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/n100-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      r120Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r120-200-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r120CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r120-200-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r500Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r500-510-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r500CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r500-510-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r360Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r360.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r360CameraMobile: file(
        relativePath: { eq: "components/cameras/img/explore-r360-mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r600Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r600.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r600CameraMobile: file(
        relativePath: { eq: "components/cameras/img/explore-r600.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      relayDiagram: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/relay-lite-diagram.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      relayDiagramMobile: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/relay-lite-diagram-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const camera = {
    model: "LITE",
    type: "",
    heroTitle: "Relay Lite",
    specTitle: "Relay Lite Overview and Specifications",
    description:
      "Ideal for mixed camera environments. Relay Lite is a no-hardware solution that uses Rhombus cameras to integrate third-party cameras for seamless, extended visibility.",
    webHeader: data.webImage,
    mobileHeader: data.mobileImage,
    objectPosition: "center",
    hidePricing: true,
    accessoryCompatible: false,
    sensorCompatible: false,
    renderProductDiagram: (
      <SectionContainer color="var(--tertiary-bg)">
        <SectionInner>
          <GatsbyImage
            image={withArtDirection(getImage(data.relayDiagram), [
              {
                media: "(max-width: 700px)",
                image: getImage(data.relayDiagramMobile),
              },
            ])}
            quality={100}
            alt="Relay Lite Overview and Specifications"
          />
        </SectionInner>
      </SectionContainer>
    ),
    specs: {
      renderInlineDescription: (
        <FlexColumn gap="20px" style={{ marginBottom: "20px", maxWidth: 512 }}>
          <Body>
            <b>Rhombus Relay Lite</b> enables organizations to incorporate
            select third-party cameras into their existing Rhombus deployment
            for complete visibility.
          </Body>
          <Body>
            Relay Lite provides a simple path for consolidating existing cameras
            into the Rhombus Console for unified live viewing and management.
          </Body>
          <Body>
            Relay Lite uses ONVIF protocols, when supported, to ingest
            third-party cameras. Once camera streams reach the Rhombus device,
            video footage and cloud communication are fully encrypted.¹
          </Body>
          <RhombusButton
            title="Download Specs"
            path={"/files/specs/relay-lite.pdf"}
            type="primary-dark"
            target="_blank"
            useHref={true}
          />
        </FlexColumn>
      ),
      column1: [
        {
          title: "Camera Streams",
          text: "One third party camera stream per Rhombus camera",
        },
        {
          title: "Network",
          text: "Auto-identifies Rhombus camera on same network as third party camera",
        },
        {
          title: "Storage",
          text: "No storage. Live view only",
        },
        {
          title: "Hardware",
          text: "No additional hardware",
        },
        {
          subtle: true,
          text: [
            "¹ As camera manufacturers own their firmware updates, Rhombus cannot offer firmware updates for non-Rhombus cameras. Users will need to manage and upgrade their firmware to avoid potential security issues. ",
          ],
        },
      ],
    },
    exploreModels: {
      title: "Explore Rhombus Cameras",
      subtitle:
        "Find the solution that's best suited for your needs. Compare all camera models or view other models below.",
      cameraCards: [
        {
          image: data.r120Camera,
          mobileImage: data.r120CameraMobile,
          alt: "dome camera model",
          width: "189px",
          model: "Dome",
          useCase: "Ideal for most environments",
          link: "/cameras/dome-cameras/",
        },
        {
          image: data.r500Camera,
          mobileImage: data.r500CameraMobile,
          alt: "bullet camera model",
          width: "195px",
          model: "Bullet",
          useCase: "Ideal for outdoor environments",
          link: "/cameras/bullet-cameras/",
        },
        {
          image: data.r360Camera,
          mobileImage: data.r360CameraMobile,
          alt: "fisheye camera model",
          width: "195px",
          model: "Fisheye",
          useCase: "Ideal for immersive coverage",
          link: "/cameras/bullet-cameras/",
        },
        {
          image: data.r600Camera,
          mobileImage: data.r600CameraMobile,
          alt: "fisheye camera model",
          width: "195px",
          model: "Multisensor",
          useCase: "Ideal for complete coverage",
          link: "/cameras/bullet-cameras/",
        },
      ],
    },
  };
  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>
          Cloud Migrate Existing Cameras with No-Hardware | Relay Lite Connector
          I Rhombus
        </title>
        <meta
          name="description"
          content="Rhombus Relay Lite helps organizations integrate third-party cameras with existing Rhombus cameras, no additional hardware required. Explore how Relay Lite can consolidate your security operations."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/relay-lite-meta.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/relay-lite-meta.png"
        />
      </Helmet>
      <CameraDetail camera={camera} />
    </GlobalLayout>
  );
};

export default RelayConnectorLight;
