import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import TextLinkV2 from "components/common/TextLinkV2";
import Link from "components/page/headers/HeaderLink";
import {
  FlexColumn,
  FlexRowCenterCenter,
  TextContainer,
} from "components/rui/layout";
import PageSection from "components/rui/page-components/PageSection";
import { Body, TitleSmall } from "components/rui/typography";
import React from "react";

const CardContainer = styled(FlexRowCenterCenter)`
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ProductCard = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 668px;
  padding: 40px 40px 40px 0;
  width: calc(50% - 40px);
  height: 400px;
  border-radius: 8px;
  background-color: var(--primary-bg);
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: all 250ms;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  box-shadow: 1px 1px 10px var(--primary-bg-50);
  text-decoration: none;

  h4 {
    font-size: 22px;
  }

  div {
    max-width: 200px;
  }
  :hover {
    box-shadow: 1px 10px 20px var(--primary-bg-50);
    transform: translateY(-8px);
  }
  @media (max-width: 1200px) {
    height: 300px;
  }
  @media (max-width: 900px) {
    height: 250px;
    padding-right: 20px;
    width: 100%;
  }
`;

const cardStyles1 = css`
  background-image: url("./assets/cameras-podium-min.png");
  background-position-y: 80px;
  background-size: 400px;
  @media (max-width: 1200px) {
    background-size: 300px;
    background-image:
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 90%),
      url("./assets/cameras-podium-min.png");
  }
  @media (max-width: 900px) {
    background-size: contain;
    background-position-y: 20px;
    background-image:
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 50%),
      url("./assets/cameras-podium-min.png");
  }
`;

const cardStyles2 = css`
  background-image: url("./assets/access-control-podium-min.png");
  background-size: 400px;
  @media (max-width: 1200px) {
    background-size: 300px;
    background-image:
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 90%),
      url("./assets/access-control-podium-min.png");
  }
  @media (max-width: 900px) {
    background-size: contain;
    background-position-y: 20px;
    background-image:
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 50%),
      url("./assets/access-control-podium-min.png");
  }
`;

const cardStyles3 = css`
  background-image: url("./assets/sensor-podium-min.png");
  background-position-y: 80px;
  background-size: 400px;
  @media (max-width: 1200px) {
    background-size: 300px;
    background-image:
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 90%),
      url("./assets/sensor-podium-min.png");
  }
  @media (max-width: 900px) {
    background-size: contain;
    background-position-y: 20px;
    background-image:
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 50%),
      url("./assets/sensor-podium-min.png");
  }
`;

const cardStyles4 = css`
  background-image:
    linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 70%),
    url("./assets/alm-agent-2.png");
  background-size: cover;
`;

const PRODUCT_CARD_ROW_1 = [
  {
    title: "Smart Cameras",
    text: "Suite of dome, fisheye, bullet, and multisensor security cameras.",
    classname: cardStyles1,
    link: "/cameras/",
  },
  {
    title: "Access Control",
    text: " Door controllers, readers, and intercoms. Secure mobile, card, and fob access.",
    classname: cardStyles2,
    link: "/access-control/",
  },
];

const PRODUCT_CARD_ROW_2 = [
  {
    title: "IoT Sensors",
    text: " Audio, environmental, motion, entry, and multipurpose panic sensors.",
    classname: cardStyles3,
    link: "/sensors/",
  },
  {
    title: "Alarm Monitoring",
    text: "TMA 5-diamond certified live agent verification and dispatch.",
    classname: cardStyles4,
    link: "/alarm-monitoring/",
  },
];

const ProductsSection = () => {
  return (
    <PageSection
      preTitle={"OUR PRODUCTS"}
      title={"Integrated experience with enterprise-grade hardware"}
      bgColor="var(--tertiary-bg)"
    >
      <FlexColumn gap="40px" grow>
        <CardContainer gap="40px">
          {PRODUCT_CARD_ROW_1.map((card) => (
            <ProductCard className={card.classname} as={Link} to={card.link}>
              <TextContainer gap="20px">
                <TitleSmall light>{card.title}</TitleSmall>
                <Body light>{card.text}</Body>
                <TextLinkV2 path={card.link} title={"Learn more"} light />
              </TextContainer>
            </ProductCard>
          ))}
        </CardContainer>
        <CardContainer gap="40px">
          {PRODUCT_CARD_ROW_2.map((card) => (
            <ProductCard className={card.classname} as={Link} to={card.link}>
              <TextContainer gap="20px">
                <TitleSmall light>{card.title}</TitleSmall>
                <Body light>{card.text}</Body>
                <TextLinkV2 path={card.link} title={"Learn more"} light />
              </TextContainer>
            </ProductCard>
          ))}
        </CardContainer>
      </FlexColumn>
    </PageSection>
  );
};

export default ProductsSection;
