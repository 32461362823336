import { FlexRow } from "components/rhombus-UI/theme/containers";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import React from "react";

const IconTextRow = ({ iconElement, text }) => {
  return (
    <FlexRow gap={"0.5rem"} center>
      {iconElement}
      <MainParagraph>
        <b>{text}</b>
      </MainParagraph>
    </FlexRow>
  );
};

export default IconTextRow;
