import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import Error from "../../../../images/form-error.png";

export const HeaderContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  padding-bottom: 0;
`;

export const HeaderInner = styled(SectionInner)`
  gap: 4rem;
  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  gap: 2rem;
  padding-bottom: 4rem;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const HeaderRight = styled.div`
  flex-grow: 1;
  width: 50%;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const HeaderTextContainer = styled(TextContainer)`
  max-width: 630px;
  width: 100%;
`;

export const HeroImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
`;

export const ImageClass = css`
  width: 100%;
  height: auto;
  margin-bottom: -20px;
`;

export const SearchBarContainer = styled.div``;

export const SearchBarWrapper = styled.div`
  display: flex;
  max-width: 616px;
  width: 100%;
  padding: 8px 20px;
  align-items: center;
  overflow: hidden;
  gap: 10px;
  border-radius: 24px;
  background:
    linear-gradient(var(--white), var(--white)) padding-box,
    var(--primary-bg) border-box;
  border: 1px solid transparent;
  :has(input:focus) {
    outline: 2px solid var(--primary);
  }
  @media (max-width: 820px) {
    max-width: 100%;
  }
`;

export const SearchIcon = styled.div`
  width: 20px;
  flex-shrink: 0;
  aspect-ratio: 1;
`;

export const SearchBar = styled.input`
  height: 100%;
  flex-grow: 1;
  font-family: inherit;
  color: var(--nuetral-300);
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  -webkit-appearance: none;
  background: transparent;
  ::-webkit-search-cancel-button {
    content: "";
    position: absolute;
    background-image: url(${Error});
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-size: contain;
    -webkit-appearance: none;
    cursor: pointer;
    filter: grayscale(1);
    opacity: 0.3;
  }
  ::placeholder {
    color: var(--nuetral-300);
  }
`;

export const MarketContainer = styled(SectionContainer)`
  min-height: 800px;
  scroll-margin-top: 5rem;
  overflow: unset;
`;

export const MarketInner = styled(SectionInner)`
  gap: 4rem;
  @media (max-width: 1024px) {
    flex-direction: row;
  }
`;

export const CategoryCol = styled.nav`
  display: flex;
  flex-direction: column;
  width: 20%;
  gap: 2.5rem;

  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const SelectionContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 0.5rem;
  @media (max-width: 600px) {
    gap: 1.25rem;
  }
`;

export const SelectionTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const Selection = styled.li`
  line-height: 2;
  transition: all 250ms;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    color: var(--blue-500);
  }
  color: ${(props) => (props.active ? "var(--blue-500)" : "unset")};
  border-left: ${(props) =>
    props.active ? "2px solid var(--blue-500)" : "2px solid transparent"};
  padding-left: 0.5rem;
  gap: 0.5rem;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const MultiSelectionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const MultiSelection = styled.input`
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: var(--white);
  border: 1px solid var(--nuetral-200);
  border-radius: 5px;
  display: grid;
  place-content: center;
  transition: all 250ms;
  cursor: pointer;
  ::before {
    content: "";
    width: 12px;
    height: 12px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--primary-bg);
    border-radius: 2px;
  }
  :checked::before {
    transform: scale(1);
  }
  :hover,
  :checked {
    border-color: var(--primary-bg);
  }
`;

export const FeedCol = styled.div`
  flex-grow: 1;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const FeedHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
  position: relative;
  max-width: 616px;
  @media (max-width: 820px) {
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
  }
`;

export const FeedTitle = styled.h2`
  @media (min-width: 820px) {
    font-size: 32px;
  }
  font-size: 24px;
  font-weight: 900;
`;

export const ChipContainer = styled.ul`
  display: flex;
  list-style: none;
  gap: 1rem;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  @media (max-width: 820px) {
    display: none;
  }
`;

export const Chip = styled.li`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--primary-bg);
  border-radius: 30px;
  border: 1px solid var(--primary-bg);
  text-align: center;
  font-size: 16px;
  padding: 5px 15px;
  font-weight: 350;
  line-height: 200%;
  transition: all 250ms;
  background-color: ${(props) =>
    props.active ? "var(--secondary-bg)" : "unset"};
  position: relative;
  overflow: hidden;
  img {
    transition: all 250ms;
  }
  input {
    position: absolute;
    height: 1000px;
    width: 1000px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  :has(input:checked) {
    background-color: var(--secondary-bg);
  }

  :hover {
    background-color: var(--secondary-bg);
  }
`;

export const FeedBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-content: flex-start;
  overflow: hidden;
  min-height: 530px;
`;

export const FeedLoader = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  span {
    width: 302px;
    height: 255px;
    flex-shrink: 0;
    border-radius: 12px;
    overflow: hidden;
    display: inline-block;
    background-image: linear-gradient(
      90deg,
      var(--nuetral-200),
      var(--white),
      var(--nuetral-200),
      var(--white)
    );
    background-size: 300% 100%;
    animation: animateBg 2s infinite;
    @keyframes animateBg {
      0% {
        background-position: 100% 0%;
      }
      100% {
        background-position: 0% 0%;
      }
    }
  }
`;

export const IntegrationCard = styled.a`
  display: flex;
  border-radius: 0.5rem;
  padding: 1.25rem;
  border: 1px solid var(--nuetral-200);
  width: calc((100% - (2 * 1rem)) / 3);
  min-height: 225px;
  gap: 1.25rem;
  flex-shrink: 0;
  flex-direction: column;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  overflow: hidden;
  padding: 16px;
  gap: 1rem;
  transition: all 250ms;

  h3 {
    color: var(--primary-bg);
    font-size: 16px;
    font-weight: 900;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  :hover {
    box-shadow: 1px 1px 10px #00000010;
    border: ${(props) =>
      props.draft
        ? "1px solid var(--nuetral-200)"
        : "1px solid var(--primary-bg)"};
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    height: fit-content;
  }
`;

export const IntegrationCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 2rem;
  position: relative;
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: -16px;
  width: 50%;
  height: 100%;
  gap: 10px;
`;

export const Tag = styled.div`
  display: flex;
  padding: 4px 16px 4px 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px 0px 0px 12px;
  background: var(--primary-bg);
  color: var(--white);

  width: fit-content;
  p {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    line-height: 1;
  }
  img {
    height: 12px;
    aspect-ratio: 1;
  }
`;

export const GreenTag = styled(Tag)`
  background: var(--blue-500);
`;

export const GreyTag = styled(Tag)`
  background: var(--nuetral-300);
`;

export const GradientTag = styled(Tag)`
  background: var(--gradient-base);
`;

export const AppIconWrapper = styled.div`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  aspect-ratio: 1;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppIcon = css`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
`;

export const FeedFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100px;
  width: 100%;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  color: var(--nuetral-200);
  div {
    cursor: pointer;
    transition: all 250ms;
    :hover {
      color: var(--primary-bg);
    }
  }
`;

export const PaginationButtonLeft = styled.div`
  width: 24px;
  height: 24px;
  color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 250ms;
  cursor: pointer;
  svg {
    transition: all 250ms;
    path,
    rect {
      transition: all 250ms;
    }
    transform: rotate(180deg);
  }
  :hover {
    svg {
      path,
      rect {
        stroke: var(--blue-500);
      }
    }
  }
`;

export const PaginationButtonRight = styled(PaginationButtonLeft)`
  svg {
    transform: rotate(0);
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 140%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 600px;
  width: 100%;
  overflow: auto;
  z-index: 10;
  border-radius: 12px;
  border: 1px solid var(--nuetral-200);
  background: var(--white);
  box-shadow: 1px 1px 10px #00000020;
  ::-webkit-scrollbar {
    width: 4px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--nuetral-200);
    border-radius: 3rem;
  }
`;

export const FormSectionContainer = styled(SectionContainer)`
  background-color: var(--nuetral-100);
  position: relative;
  overflow: clip;
  scroll-margin-top: 90px;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const FormTitleSection = styled(TextContainer)`
  z-index: 1;
  @media (max-width: 600px) {
    padding: 4rem 20px;
  }
`;

export const FormCol = styled(SectionCol)`
  justify-content: flex-end;
  @media (max-width: 820px) {
    padding-right: 0;
  }
`;

export const NotFoundContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 616px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  p {
    font-weight: 400;
    font-size: 24px;
    color: var(--blue-900);
    @media (max-width: 600px) {
      font-size: 16px;
    }
    a {
      display: inline-block;
    }
  }
`;
