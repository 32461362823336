import React from "react";

const IconSystemProtection = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
    >
      <g clip-path="url(#clip0_27235_23931)">
        <path
          d="M0.078125 5.51172V44.4914H50.0781V5.51172H0.078125ZM47.1484 11.3715H12.1227V8.44142H47.1484V11.3715ZM9.193 8.44142V11.3715H3.00782V8.44142H9.193ZM3.00782 41.5617V14.3012H47.1484V41.5617H3.00782Z"
          fill="#2A7DE1"
        />
        <path
          d="M25.0794 22.5117C22.0912 22.5117 19.6602 24.9428 19.6602 27.931C19.9326 35.1104 30.2273 35.1085 30.4986 27.9309C30.4986 24.9428 28.0676 22.5117 25.0794 22.5117ZM25.0794 30.4205C23.7066 30.4205 22.5898 29.3037 22.5898 27.931C22.7149 24.6327 27.4443 24.6336 27.5689 27.931C27.5689 29.3037 26.4521 30.4205 25.0794 30.4205Z"
          fill="#2A7DE1"
        />
        <path
          d="M25.0767 19.5781C17.5917 19.5781 11.7405 26.7057 11.4951 27.0091L10.75 27.9303L11.4951 28.8515C19.8494 38.6689 30.3637 38.6028 38.6582 28.8515L39.4033 27.9303L38.6582 27.0091C38.4129 26.7057 32.5617 19.5781 25.0767 19.5781ZM25.0767 33.3527C20.4357 33.3527 16.3183 29.6869 14.6104 27.9284C21.5104 20.7701 28.6543 20.8008 35.5431 27.9322C33.8394 29.692 29.7356 33.3527 25.0767 33.3527Z"
          fill="#2A7DE1"
        />
      </g>
      <defs>
        <clipPath id="clip0_27235_23931">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.078125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSystemProtection;
