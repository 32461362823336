import {
  ButtonBaseOutlined,
  ButtonWhite,
  ButtonWhiteOutlined,
  GradientButton,
  LandingPageButton,
  LandingPageButtonV2,
  LandingPageButtonV3,
  NavButtonOutlined,
} from "components/rhombus-UI/theme/buttons";
import { Link } from "gatsby";
import React from "react";

import { toTitleCase } from "./util/helperFunctions";
import { captureCTAButtonClick } from "components/util/landing-page-functions";
import { POSSIBLE_CTAS } from "components/data/landing-page-data";
import {
  ButtonPrimary,
  ButtonPrimaryDark,
  ButtonPrimaryLight,
  ButtonSecondary,
  ButtonSecondaryLight,
} from "components/rui/buttons";

const buttonComponents = {
  primary: ButtonPrimary,
  "primary-light": ButtonPrimaryLight,
  "primary-dark": ButtonPrimaryDark,
  secondary: ButtonSecondary,
  "secondary-light": ButtonSecondaryLight,
  gradient: GradientButton,
  landing: LandingPageButton,
  white: ButtonWhite,
  "white-outlined": ButtonWhiteOutlined,
  "landing-v2": LandingPageButtonV2,
  "landing-v3": LandingPageButtonV3,
  "nav-outlined": NavButtonOutlined,
};

const RhombusButton = ({
  title,
  path,
  type = "primary",
  target,
  useHref = false,
  small = false,
  disabled = false,
  style,
  className,
  useTitleCase = true,
  renderIcon,
  onClick,
}) => {
  const formattedTitle = toTitleCase(title);

  const scrollToForm = () => {
    const references = document.querySelector("#form");
    if (!references) {
      window && window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
    } else {
      references.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const ButtonComponent = buttonComponents[type];
  const asComponent = path && !useHref ? Link : "a";
  const handleClick = path ? null : scrollToForm;

  const onClickAction = () => {
    if (POSSIBLE_CTAS.includes(title)) {
      captureCTAButtonClick();
    }

    return handleClick?.() || onClick?.();
  };

  return (
    <ButtonComponent
      as={asComponent}
      to={path && path}
      onClick={onClickAction}
      target={target}
      href={useHref ? path : undefined}
      small={small}
      style={style}
      disabled={disabled}
      className={className}
      title={useTitleCase ? formattedTitle : title}
    >
      {useTitleCase ? formattedTitle : title}
      {renderIcon?.()}
    </ButtonComponent>
  );
};

export default RhombusButton;
