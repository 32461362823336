import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  PreTitle,
  Title,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "@linaria/core";
import React from "react";

import SlideInContainer from "./SlideInContainer";

const sectionContainer = css`
  display: flex;
  justify-content: center;
  padding: 95px 20px;
  position: relative;
  overflow: hidden;
  scroll-margin-top: 90px;
  @media (max-width: 600px) {
    padding: 0;
  }
`;
const sectionInner = css`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: var(--max-width);
  justify-content: space-between;
  gap: 2rem;
  @media (max-width: 820px) {
    flex-direction: column;
    gap: 4rem;
  }
`;
const sectionLeft = css`
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  @media (max-width: 1024px) {
    padding-right: 4rem;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 4rem 20px;
    padding-bottom: 0;
  }
`;

const sectionRight = cx(
  sectionLeft,
  css`
    align-items: flex-end;
    padding: 0;
    @media (max-width: 820px) {
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
);

const featureList = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 550px;
`;

const featureItem = css`
  display: flex;
  gap: 1rem;
`;

const iconClass = css`
  width: 25px !important;
  height: 25px !important;
  margin: 5px 5px 0px 0px !important;
  aspect-ratio: 1 !important;
  overflow: visible !important;
`;

const DemoFormSection = ({
  data,
  color = "var(--tertiary-bg)",
  formTitle = "Request Demo",
  useFormExpansion = false,
  fancy = true,
  useEventClosedFallback = false,
}) => {
  const { isMobile } = useIsMobile(820);

  const TitleComponent = fancy ? FancyTitle : Title;

  return (
    <section
      className={sectionContainer}
      id="form"
      style={{ background: color }}
    >
      <div className={sectionInner}>
        <div className={sectionLeft}>
          <TextContainer
            style={!fancy && { gap: "1.25rem" }}
            width={!fancy && "614px"}
          >
            <PreTitle>{data.preTitle}</PreTitle>
            <TitleComponent color={!fancy && "var(--white)"}>
              {data.title}
            </TitleComponent>
            <MainParagraph color={!fancy && "var(--white)"}>
              {data.description}
            </MainParagraph>
          </TextContainer>
          {data.learnLines && (
            <div className={featureList}>
              <h3>What You’ll Learn in Your Demo</h3>
              {data.learnLines.map((item) => (
                <SlideInContainer>
                  <div className={featureItem}>
                    <StaticImage
                      src="./img/validation.png"
                      alt="check"
                      className={iconClass}
                      objectFit="contain"
                    />
                    <p>{item}</p>
                  </div>
                </SlideInContainer>
              ))}
            </div>
          )}
        </div>
        <div className={sectionRight}>
          <FormWrapper width={isMobile ? "100%" : "586px"} title={formTitle}>
            <RhombusForm
              formId={data.id}
              useFormExpansion={useFormExpansion}
              useEventClosedFallback={useEventClosedFallback}
            />
          </FormWrapper>
        </div>
      </div>
    </section>
  );
};

export default DemoFormSection;
