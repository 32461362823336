import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { uniq } from "lodash";

export const MAX_SUBMISSIONS = 20;

const DEFAULT_TIMEZONE = "Etc/UTC";

const getDefaultTimeZone = (isoDate) => {
  const date = parseISO(isoDate);
  return toZonedTime(date, DEFAULT_TIMEZONE);
};

export const getEventSubmissionCount = (formId) => {
  return fetch(`/api/rctpData/getSubmissionCount/${formId}`)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getMonth = (isoDate) => {
  return format(getDefaultTimeZone(isoDate), "MMM");
};

export const getYear = (isoDate) => {
  return format(getDefaultTimeZone(isoDate), "yyyy");
};

export const getDay = (isoDate) => {
  return format(getDefaultTimeZone(isoDate), "d");
};

const createAscendingSubsets = (arr) => {
  if (arr.length === 0) return [];
  let result = [];
  let temp = [arr[0]];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] >= arr[i - 1]) {
      temp.push(arr[i]);
    } else {
      result.push(temp);
      temp = [arr[i]];
    }
  }
  result.push(temp);
  return result;
};

const stringifyDayRange = (days) => {
  return days.join(", ");
};

export const renderDateRange = (dates) => {
  const earliestDate = dates[0];
  const latestDate = dates[dates.length - 1];
  const earliestYear = getYear(earliestDate);
  const latestYear = getYear(latestDate);
  const earliestMonth = getMonth(earliestDate);
  const latestMonth = getMonth(latestDate);

  const allDays = dates.map(getDay);
  const dayRangeSubsets = createAscendingSubsets(allDays).map(uniq);

  if (dayRangeSubsets.length === 1) {
    return `${earliestMonth} ${stringifyDayRange(
      dayRangeSubsets[0]
    )}, ${earliestYear}`;
  } else if (earliestYear !== latestYear) {
    return `${earliestMonth} ${stringifyDayRange(
      dayRangeSubsets[0]
    )}, ${earliestYear} - ${latestMonth} ${stringifyDayRange(
      dayRangeSubsets[1]
    )}, ${latestYear}`;
  } else {
    return `${earliestMonth} ${stringifyDayRange(
      dayRangeSubsets[0]
    )} - ${latestMonth} ${stringifyDayRange(
      dayRangeSubsets[1]
    )}, ${earliestYear}`;
  }
};

export const getDate = (isoDate) => {
  return format(getDefaultTimeZone(isoDate), "MMMM d, yyyy");
};

export const checkLastDate = (event) => {
  const currentDate = Date.now();
  const dates = event.node.frontmatter.trainingEvents
    .map((item) => item.trainingDates.map((i) => [i.startDate, i.endDate]))
    .flat(Infinity);
  const lastDate = new Date(dates[dates.length - 1] ?? "").getTime();
  return currentDate < lastDate;
};

export const compareStartDates = (a, b) => {
  const dateA = parseISO(
    a.node.frontmatter.trainingEvents[0].trainingDates[0].startDate
  );
  const dateB = parseISO(
    b.node.frontmatter.trainingEvents[0].trainingDates[0].startDate
  );
  return dateA - dateB;
};

export const getTimeRange = (startDate, endDate) => {
  const startTime = format(getDefaultTimeZone(startDate), "h:mmaaa");
  const endTime = format(getDefaultTimeZone(endDate), "h:mmaaa");
  return `${startTime} - ${endTime}`;
};
