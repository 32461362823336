import { styled } from "@linaria/react";
import React from "react";
import badge1 from "./img/footer-badge-1.svg";
import badge2 from "./img/footer-badge-2.svg";
import badge3 from "./img/footer-badge-3.svg";
import badge4 from "./img/footer-badge-4.svg";

const Badges = styled.div`
  display: flex;
  gap: 1.25rem;
  user-select: none;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    width: calc(120px + 1.25rem) !important;
  }
`;

const Badge = styled.img`
  width: 60px;
  aspect-ratio: 1;
`;

const BADGES = [badge1, badge2, badge3, badge4];

const G2Badges = () => {
  return (
    <Badges>
      {BADGES.map((badge) => (
        <Badge src={badge} />
      ))}
    </Badges>
  );
};

export default G2Badges;
