import { styled } from "@linaria/react";
import IconAlert from "components/common/icon-components/IconAlert";
import IconAudio from "components/common/icon-components/IconAudio";
import IconCamera from "components/common/icon-components/IconCamera";
import IconGuard from "components/common/icon-components/IconGuard";
import IconMapPin from "components/common/icon-components/IconMapPin";
import React from "react";

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  width: fit-content;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Panel = styled.div`
  background: var(--nuetral-100);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.rhombus {
    h3 {
      color: var(--blue-500);
    }
  }
`;

const ProductTitle = styled.h3`
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  text-transform: uppercase;
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 42px;
  align-items: center;
  flex-wrap: wrap;
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 52px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const ProductHeader = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
  b {
    font-size: 24px;
    line-height: 0;
  }
`;

const TableTile = styled.h3`
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  &:not(:last-of-type) {
    border-bottom: 1px solid #dfe2e5;
    padding: 0 0 20px 0;
  }
`;

const RowTitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  &.bold {
    font-weight: 800;
  }
  &.large {
    font-size: 24px;
  }
  &.rhombus {
    color: var(--blue-500);
  }
  @media (max-width: 820px) {
    font-size: 14px;
    &.large {
      font-size: 18px;
    }
  }
`;

const RowInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  span,
  i {
    color: #7b848e;
    text-align: right;
  }

  i {
    font-size: 12px;
  }
  span {
    font-size: 14px;
  }

  @media (max-width: 820px) {
    span {
      font-size: 10px;
    }
    i {
      font-size: 8px;
    }
  }
`;

const PRODUCT_TITLE = {
  competitor: "After-hours security guards",
  rhombus: "Rhombus",
};

const PRODUCT_MAP = {
  location: {
    name: "Location",
    Icon: <IconMapPin />,
  },
  guard: {
    name: "Guards",
    Icon: <IconGuard />,
  },
  camera: {
    name: "Cameras",
    Icon: <IconCamera size="26" />,
  },
  a100: {
    name: "Audio Gateway",
    Icon: <IconAudio />,
  },
  alm: {
    name: "Alarm Monitoring",
    Icon: <IconAlert size="26" />,
  },
};

const PRODUCTS = {
  competitor: [
    { product: PRODUCT_MAP.location, quantity: 1 },
    { product: PRODUCT_MAP.guard, quantity: 2 },
  ],
  rhombus: [
    { product: PRODUCT_MAP.location, quantity: 1 },
    { product: PRODUCT_MAP.camera, quantity: 15 },
    { product: PRODUCT_MAP.a100, quantity: 1 },
    { product: PRODUCT_MAP.alm, quantity: null },
  ],
};

const TABLE_DATA = {
  competitor: {
    title: "Recurring costs",
    tdc: { frequency: "8 hours/day", time: "10pm - 6am" },
    cph: {
      title: "Cost per hour",
      cost: "$25/hour",
      products: "1 unarmed guard + 1 armed guard",
    },
    monthlyTotal: {
      cost: "$12,000/Month",
      info: "Source: Bureau of Labor Statistics May 2023",
    },
  },
  rhombus: {
    title: "Upfront + Recurring costs",
    tdc: { frequency: "24 hours/day", time: "7 days per week" },
    cph: {
      title: "Effective cost per hour",
      cost: "$1.11/hour",
      products: ["Products + Licensing"],
    },
    monthlyTotal: {
      cost: "$814/Month",
      info: "*1 year contract using 15 R200 cameras",
    },
  },
};

const PricingTable = ({ infoName = "competitor" }) => {
  const { tdc, cph, monthlyTotal } = TABLE_DATA[infoName];
  return (
    <TableContainer className={infoName}>
      <Panel className={infoName}>
        <ProductTitle>{PRODUCT_TITLE[infoName]}</ProductTitle>
        <ProductRow>
          {PRODUCTS[infoName].map((item) => {
            const { product, quantity } = item;
            const { name, Icon } = product;
            return (
              <Product>
                <ProductHeader>
                  {Icon}
                  {quantity && <b>{quantity}</b>}
                </ProductHeader>
                <div>
                  <span>{name}</span>
                </div>
              </Product>
            );
          })}
        </ProductRow>
      </Panel>
      <Panel className={infoName}>
        <TableTile>{TABLE_DATA[infoName].title}</TableTile>
        <TableRow>
          <RowTitle>Total Daily Coverage</RowTitle>
          <RowInfo>
            <RowTitle>{tdc.frequency}</RowTitle>
            <span>{tdc.time}</span>
          </RowInfo>
        </TableRow>
        <TableRow>
          <RowTitle>{cph.title}</RowTitle>
          <RowInfo>
            <RowTitle>{cph.cost}</RowTitle>
            <span>{cph.products}</span>
          </RowInfo>
        </TableRow>
        <TableRow>
          <RowTitle className={`bold ${infoName}`}>Monthly total</RowTitle>
          <RowInfo>
            <RowTitle
              className={`bold ${infoName} ${infoName === "rhombus" && "large"}`}
            >
              {monthlyTotal.cost}
            </RowTitle>
            <i>{monthlyTotal.info}</i>
          </RowInfo>
        </TableRow>
      </Panel>
    </TableContainer>
  );
};

export default PricingTable;
