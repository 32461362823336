import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";
import { Body, TitleLarge } from "components/rui/typography";

const imageStyles = css`
  width: 100% !important;
`;

const BladeInner = styled(SectionInner)`
  flex-direction: row-reverse;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled(TextContainer)`
  gap: 1.6rem;
  @media (max-width: 820px) {
    align-items: center;
    text-align: center;
  }
`;

const TrialBanner2 = ({ title = "Try Rhombus for Free!" }) => {
  return (
    <SectionContainer
      style={{
        position: "relative",
        minHeight: "500px",
        background: "var(--gradient-light)",
      }}
    >
      <BladeInner>
        <SectionCol>
          <StaticImage
            src="./images/cta-banner-2-min.png"
            className={imageStyles}
            alt="Rhombus product suite providing ai video analytics"
            quality={100}
            placeholder="blurred"
            objectFit="contain"
          />
        </SectionCol>
        <SectionCol style={{ zIndex: "2" }} className="col">
          <Text>
            <TitleLarge>{title}</TitleLarge>
            <Body>
              See why school districts, cities, and Fortune 500 companies use
              Rhombus.
            </Body>
            <RhombusButton
              path="/free-trial/"
              type="primary"
              title="Start Trial"
            />
          </Text>
        </SectionCol>
      </BladeInner>
    </SectionContainer>
  );
};

export default TrialBanner2;
