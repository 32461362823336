import PageContent from "components/page/PageContent";
import {
  SectionContainer,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useEffect, useRef, useState } from "react";

import RhombusButton from "./RhombusButton";
import TextLinkV2 from "./TextLinkV2";
import { toTitleCase } from "./util/helperFunctions";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import {
  BulletList,
  ListItem,
} from "components/landing-pages/consultant-lp/CTASection2";
import IconCheckMark from "./icon-components/IconCheckMark";
import { Body, PreTitle, TitleMed } from "components/rui/typography";

const InfoSliderContainer = styled(SectionContainer)`
  min-height: 800px;
  @media (max-width: 820px) {
    height: fit-content;
  }
`;
const sectionInner = css`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 48px;
`;
const sectionHeader = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
`;
export const tabContainer = css`
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
  height: fit-content;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const tabRow = css`
  display: flex;
  list-style: none;
  padding: 0;
  gap: 46px;
  height: 100%;
`;
const tab = css`
  font-weight: 800;
  color: var(--body-subtle);
  font-size: 18px;
  height: 47px;
  cursor: pointer;
  transition: all 250ms;
  white-space: nowrap;
  position: relative;
  :hover {
    color: var(--primary-bg);
  }
  :not(:last-of-type)::before {
    content: "";
    position: absolute;
    height: 2px;
    width: calc(100% + 46px);
    background-color: var(--body-subtle);
    bottom: 0;
    left: 0;
  }

  :last-of-type::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: var(--body-subtle);
    bottom: 0;
    left: 0;
  }

  ::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: transparent;
    bottom: 0;
    left: 0;
    transition: all 250ms;
  }
`;

const tabActive = cx(
  tab,
  css`
    color: var(--primary-bg);
    ::after {
      background-color: var(--primary-bg);
    }
  `
);

const sectionBody = css`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 4rem;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
`;
const bodyLeft = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
  > h2 {
    max-width: 500px;
  }
  p {
    max-width: 550px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const animated = cx(
  bodyLeft,
  css`
    animation: fadeIn 500ms linear;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
);

const bodyRight = css`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InfoSlider = ({
  data,
  title,
  color = "var(--white)",
  button = false,
  fancy = true,
  buttonCopy = CTA_BUTTON_COPY,
  useLink = false,
}) => {
  const [selection, setSelection] = useState(data[0]);
  const [currentIndex, setCurrentIndex] = useState(data.indexOf(selection));
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    setCurrentIndex(data.indexOf(selection));
  }, [selection]);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 501);
  }, [animate]);

  const getPrevElementsWidth = () => {
    let totalWidth = 0;
    if (currentIndex === 0 || currentIndex === data.length - 1) return 0;
    for (let i = 0; i < currentIndex; i++) {
      const el = document.getElementById(data[i].tabTitle);
      totalWidth += el && el.offsetWidth;
    }
    return totalWidth;
  };

  const scrollRef = useRef(null);
  const previousIndex = useRef(data.indexOf(selection));

  useEffect(() => {
    previousIndex.current = data.indexOf(selection);
  }, [selection]);

  const scroll = (offset) => {
    scrollRef.current.scrollLeft += offset;
  };

  const handleTabClick = (item, index) => {
    const posOffset = 46 * currentIndex + getPrevElementsWidth();
    const negOffset = -posOffset;
    const defaultOffset = 46 * 2.5;
    if (previousIndex.current === 0 && index !== 0) {
      scroll(defaultOffset);
    } else if (previousIndex.current > index) {
      scroll(negOffset);
    } else {
      scroll(posOffset);
    }
    setSelection(item);
    setAnimate(true);
  };

  const scrollToForm = () => {
    const references = document.querySelector("#form");
    if (!references) {
      window && window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
    } else {
      references.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <InfoSliderContainer style={{ background: color }}>
      <PageContent>
        <div className={sectionInner}>
          <div className={sectionHeader}>
            <TitleMed style={{ textAlign: "center" }}>{title}</TitleMed>
            <div className={tabContainer} ref={scrollRef}>
              <ul className={tabRow}>
                {data.map((item, index) => (
                  <li
                    key={item.tabTitle}
                    id={item.tabTitle}
                    onClick={() => handleTabClick(item, index)}
                    className={
                      item.tabTitle === selection.tabTitle ? tabActive : tab
                    }
                  >
                    {item.tabTitle}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={sectionBody}>
            <div className={animate ? animated : bodyLeft}>
              <TextContainer>
                <PreTitle color="var(--primary-bg)">
                  {selection.preTitle ? selection.preTitle : selection.tabTitle}
                </PreTitle>
                <TitleMed>{toTitleCase(selection.title)}</TitleMed>
                <Body>{selection.description}</Body>
              </TextContainer>
              {selection.bullets && (
                <BulletList style={{ marginTop: "24px" }}>
                  {selection.bullets.map((item) => (
                    <ListItem>
                      <IconCheckMark color="var(--teal-500)" />
                      {item}
                    </ListItem>
                  ))}
                </BulletList>
              )}
              {button && !useLink && (
                <RhombusButton type="primary-dark" title={buttonCopy} />
              )}
              {useLink && !button && (
                <TextLinkV2
                  title={buttonCopy}
                  onClick={scrollToForm}
                  as="button"
                />
              )}
              {selection.link && (
                <TextLinkV2
                  path={selection.link.path}
                  title={selection.link.title}
                />
              )}
            </div>
            <div className={bodyRight}>
              <GatsbyImage
                image={getImage(selection.image)}
                alt={selection?.altText ?? selection.title}
                className={
                  selection.useRoundImage
                    ? css`
                        border-radius: 16px;
                        overflow: hidden;
                      `
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      </PageContent>
    </InfoSliderContainer>
  );
};

export default InfoSlider;
