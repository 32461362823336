import React from "react";

const Carrot = ({ color = "currentColor", height = "5", width = "10" }) => {
  return (
    <svg
      className="carrot"
      width={width}
      height={height}
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.5L5 4.5L1 0.500001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Carrot;
