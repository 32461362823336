import React from "react";
import { Body, PreTitle, SectionTitle } from "../typography";
import { styled } from "@linaria/react";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { FlexColumnCenter } from "../layout";

const SectionWrapper = styled(SectionContainer)`
  padding: var(--padding-top-bottom) 1.25rem;
  @media (max-width: 768px) {
    padding: var(--padding-top-bottom-mobile) 1.25rem;
  }
`;

const ContentContainer = styled(SectionInner)`
  gap: var(--section-gap);
  @media (max-width: 768px) {
    gap: var(--section-gap-mobile);
  }
`;

const PageSection = ({
  preTitle,
  title,
  bgColor = "var(--white)",
  blurb,
  children,
}) => {
  return (
    <SectionWrapper color={bgColor}>
      <ContentContainer className="flex-col">
        <FlexColumnCenter gap="20px">
          <PreTitle color="var(--primary-bg)" center>
            {preTitle}
          </PreTitle>
          <SectionTitle>{title}</SectionTitle>
          {blurb && (
            <Body large center bold>
              {blurb}
            </Body>
          )}
        </FlexColumnCenter>
        {children}
      </ContentContainer>
    </SectionWrapper>
  );
};

export default PageSection;
