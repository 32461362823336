import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";

export default function useLocationSearch() {
  const [utmSearch, setUtmSearch] = useState("");
  const [referralRockSearch, setreferralRockSearch] = useState("");

  useEffect(() => {
    const paramsFromUrl =
      !!window &&
      !!window.location &&
      !!window.location.search &&
      window.location.search;

    const utmFromLocalStorage =
      !!localStorage &&
      !!localStorage.getItem &&
      localStorage.getItem("UTM_VALUES");

    const referralRockFromLocalStorage =
      !!localStorage &&
      !!localStorage.getItem &&
      localStorage.getItem("REFERRAL_ROCK");

    const paramsToObject = !!paramsFromUrl && queryString.parse(paramsFromUrl);

    const isReferralRock =
      !!paramsToObject &&
      Object.keys(paramsToObject).indexOf("REFERRALCODE") > -1;

    if (isReferralRock) {
      const nexReferralRockSearch =
        paramsFromUrl || referralRockFromLocalStorage || null;
      if (nexReferralRockSearch) {
        localStorage.setItem("REFERRAL_ROCK", nexReferralRockSearch);
        setreferralRockSearch(nexReferralRockSearch);
      } else {
        setreferralRockSearch("?");
      }
      if (utmFromLocalStorage) {
        setUtmSearch(utmFromLocalStorage);
      } else {
        setUtmSearch("?");
      }
    } else {
      const nextUtmSearch = paramsFromUrl || utmFromLocalStorage || null;
      if (nextUtmSearch) {
        localStorage.setItem("UTM_VALUES", nextUtmSearch);
        setUtmSearch(nextUtmSearch);
      } else {
        setUtmSearch("?");
      }
      if (referralRockFromLocalStorage) {
        setreferralRockSearch(referralRockFromLocalStorage);
      } else {
        setreferralRockSearch("?");
      }
    }
  }, [setUtmSearch, setreferralRockSearch]);

  const utmParameters = useMemo(() => queryString.parse(utmSearch), [
    utmSearch,
  ]);

  const referralRockParameters = useMemo(
    () => queryString.parse(referralRockSearch),
    [referralRockSearch]
  );

  const parameters = { ...utmParameters, ...referralRockParameters };

  return {
    parameters,
  };
}
