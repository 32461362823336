import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import React from "react";
import Logo from "../../../images/rhombus-symbol-40x40.svg";
import { navigate } from "gatsby";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import { Body, TitleMed } from "components/rui/typography";

const sectionContainer = css`
  background: var(--white);
  display: flex;
  justify-content: center;
  padding: 3rem 10px;
  scroll-margin-top: 90px;
  overflow: hidden;
`;

const sectionInner = css`
  display: flex;
  max-width: var(--max-width);
  width: 100%;
  gap: 1.6rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 3.2rem;
  }
`;

const qouteContainer = css`
  width: 50%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const formContainer = css`
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0 1.6rem;

  p {
    max-width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    border-top: 1px solid var(--blue-500);
  }
`;

const qouteBox = css`
  height: 100%;
  border-radius: 1.8rem;
  width: 100%;
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.6rem;
  max-height: 795px;
  overflow: hidden;
  @media (max-width: 768px) {
    border-radius: 1rem;
    min-height: 477px;
    gap: 1.6rem;
  }
`;

const qouteBoxHeader = css`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-start;
`;

const qouteImage = css`
  position: absolute !important;
  top: 0;
  width: 100% !important;
  aspect-ratio: 1 !important;
  border-radius: 12px !important;
  -webkit-border-radius: 12px;
  max-height: 501px !important;
  transition: all 250ms ease-in;
  @media (max-width: 1024px) {
    height: 100% !important;
  }
`;

const qouteBody = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  max-height: 501px;
  height: fit-content;
  border-radius: 12px;
  overflow: hidden;
  background: var(--primary-bg);

  :hover .${qouteImage} {
    transform: scale(1.1);
  }
`;

const logoClass = css`
  width: 23px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const textContainer = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  color: var(--white);
  gap: 1.6rem;
  padding: 1.6rem;
  padding-bottom: 3.2rem;
  h3 {
    font-size: 25px;
    line-height: 2;
    font-weight: 100;
    @media (max-width: 1024px) {
      font-size: 1.2rem;
    }
  }
`;

const avatarRow = css`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  @media (max-width: 768px) {
    p {
      font-size: 1rem;
    }
  }
`;

const avatar = css`
  width: 60px;
  aspect-ratio: 1;
  @media (max-width: 768px) {
    width: 30px;
  }
`;

const caseStudyLogo = css`
  filter: invert(1);
`;

const IndustryFormUpdated = ({ data }) => {
  return (
    <section className={sectionContainer} id="book-demo">
      <div className={sectionInner}>
        <div className={qouteContainer}>
          <div className={qouteBox}>
            <div className={qouteBoxHeader}>
              <img src={Logo} alt="rhombus logo" className={logoClass} />
              <TitleMed>{data.caseStudy.title}</TitleMed>
            </div>
            <div
              className={qouteBody}
              onClick={() => navigate(data.caseStudy.link)}
            >
              <GatsbyImage
                image={getImage(data.caseStudy.image)}
                alt={data.caseStudy.title}
                className={qouteImage}
              />
              <div className={textContainer}>
                <GatsbyImage
                  image={getImage(data.caseStudy.logo.img)}
                  alt={data.caseStudy.logo.alt}
                  style={{ width: data.caseStudy.logo.width }}
                  className={caseStudyLogo}
                />
                <Body
                  light
                  large
                  dangerouslySetInnerHTML={{
                    __html: data.caseStudy.author.qoute,
                  }}
                ></Body>
                <div className={avatarRow}>
                  {data.caseStudy.author.avatar ? (
                    <GatsbyImage
                      image={getImage(data.caseStudy.author.avatar)}
                      alt={data.caseStudy.author.name}
                      className={avatar}
                    />
                  ) : (
                    <></>
                  )}
                  <div>
                    <h4>{data.caseStudy.author.name}</h4>
                    <p>{data.caseStudy.author.role}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={formContainer}>
          <FormWrapper height="567px" width="584px" title={data.title}>
            <RhombusForm formId={data.id} />
          </FormWrapper>
        </div>
      </div>
    </section>
  );
};

export default IndustryFormUpdated;
