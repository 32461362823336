import CameraDetailHeader from "components/cameras/detail/CameraDetailHeader";
import CameraSpecs from "components/cameras/detail/CameraSpecs";
import ExporeCameraModels from "components/cameras/ExploreOtherCameras";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import {
  CTA_BUTTON_COPY_7,
  CTA_LINK_3,
} from "components/data/landing-page-data";
import LandingPageFooter from "components/landing-pages/consultant-lp/LandingPageFooter";
import GlobalLayout from "components/page/GlobalLayout";
import { FlexColumn } from "components/rui/layout";
import { Body } from "components/rui/typography";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const SPECS = {
  renderDimsWithHeader: true,
  column1: [
    {
      title: "Onboard Storage",
      text: ["2TB"],
    },
    {
      title: "Camera Storage",
      text: ["Up to 20 days of storage per camera²"],
    },
    {
      title: "Networking",
      text: ["1x 2.5Gb RJ45"],
    },
    {
      title: "Dimensions",
      text: ["117mm x 112 mm x 54mm"],
    },
    {
      title: "Power Supply",
      text: ["12-24VDC input, 19V 120W external PSU included"],
    },
    {
      title: "Power Consumption",
      text: ["120W max"],
    },
  ],
  column2: [
    {
      title: "N100 License Options",
      bullets: true,
      text: [
        "View and storage only with no additional license",
        "Standard Analytics with Rhombus Professional License³",
        "Advanced Analytics with Rhombus Enterprise License³",
      ],
    },
    { title: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant" },
    { title: "Rhombus 5-Year Warranty Included" },
    {
      subtle: true,
      text: [
        "¹ As camera manufacturers own their firmware updates, Rhombus cannot offer firmware updates for non-Rhombus cameras. Users will need to manage and upgrade their firmware to avoid potential security issues. ",
        "² Days of storage and number of cameras per device can vary based on camera resolution, bitrate, and motion. ",
        "³ Performance of Rhombus AI analytics can vary based on camera image quality and performance.",
      ],
    },
  ],
};

const RelayLandingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/n100-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/n100-header-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/n100-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <GlobalLayout
      landingPage
      hideFooter
      dark
      color="transparent"
      includeButton
      buttonText={CTA_BUTTON_COPY_7}
      ctaLink={CTA_LINK_3}
    >
      <Helmet>
        <title>Rhombus Relay: Enterprise Cloud NVR</title>
        <meta
          name="description"
          content="Integrate existing cameras to the Rhombus console for AI insights, remote management, and more."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <CameraDetailHeader
        model={"Relay Core: Cloud NVR"}
        description={
          "Integrates existing third-party cameras for a simplified migration to cloud-managed physical security. Discover best-in-class cloud NVR that transforms yesterday's cameras into tomorrow's intelligence."
        }
        webHeader={data.webImage}
        mobileHeader={data.mobileImage}
        objectPosition={"center"}
        heroAlt={""}
        ctaCopy={CTA_BUTTON_COPY_7}
        ctaLink={CTA_LINK_3}
        hidePricing
      />
      <CameraSpecs
        title={"N100 Relay Core Overview and Specifications"}
        specs={SPECS}
        subTitle={
          <FlexColumn gap="16px">
            <Body>
              <b>Rhombus Relay Core</b> lets organizations easily upgrade their
              existing cameras and gain the benefits of the Rhombus platform.
              With remote access, AI insights, and centralized management in a
              compact, easy-to-use form factor, Relay Core transforms
              third-party RTSP streaming cameras into smart cameras on the
              Rhombus console.
            </Body>
            <Body>
              The N100 uses ONVIF protocols, when supported, to ingest
              third-party cameras. Once camera streams reach the Rhombus device,
              video footage and cloud communication are fully encrypted.¹
            </Body>
          </FlexColumn>
        }
        dims={data.dims}
        dimeAlt={"N100 Dimensions"}
        pdf={"/files/specs/n100.pdf"}
      />
      <TrialBanner
        buttonCopy={CTA_BUTTON_COPY_7}
        ctaLink={CTA_LINK_3}
        title="See Rhombus for Yourself"
        blurb="Request a demo with our team today and see why industry leaders choose Rhombus."
      />
      <LandingPageFooter />
    </GlobalLayout>
  );
};

export default RelayLandingPage;
