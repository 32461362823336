import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import { FeaturedCard, TypeTag } from "./styles/webinarsPageStyles";

import TextLinkV2 from "../common/TextLinkV2";
import { animated, useSpring } from "@react-spring/web";
import { Link } from "gatsby";
import { chooseEventToFeature, getType } from "./util/webinarsFunctions";
import DateRow from "components/hybrid-event/DateRow";
import { Body, TitleMed } from "components/rui/typography";

export default function FeaturedWebinar() {
  const data = useStaticQuery(graphql`
    query {
      webinar: allMarkdownRemark(
        limit: 1
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "webinar-post" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              type
              cardImage
              startDate
              title
              blurb
            }
          }
        }
      }
      introWebinar: allMarkdownRemark(
        limit: 1
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "intro-webinar-post" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              type
              cardImage
              startDate
              title
              blurb
              timeZone
            }
          }
        }
      }
      hybridEvent: allMarkdownRemark(
        limit: 1
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "hybrid-event" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              type
              title
              cardImage
              topics
              dateInfo {
                startDate
                timeZone
              }
              eventDetails {
                eventSummary
              }
            }
          }
        }
      }
    }
  `);

  const { webinar, introWebinar, hybridEvent } = data;
  const { edges: _featuredWebinar } = webinar;
  const { edges: _introWebinar } = introWebinar;
  const { edges: _featuredHybridEvent } = hybridEvent;

  const featuredEvent = chooseEventToFeature(
    _featuredWebinar,
    _introWebinar,
    _featuredHybridEvent
  );

  if (featuredEvent.length === 0) {
    return <></>;
  }

  const date =
    featuredEvent[0].node.frontmatter.startDate ||
    featuredEvent[0].node.frontmatter.dateInfo.startDate;
  const type = getType(featuredEvent[0].node.frontmatter.type, date);
  const image = featuredEvent[0].node.frontmatter.cardImage;
  const title = featuredEvent[0].node.frontmatter.title;
  const blurb =
    (featuredEvent[0].node.frontmatter.blurb ||
      featuredEvent[0].node.frontmatter?.eventDetails?.eventSummary) ??
    "";
  const slug = featuredEvent[0].node.fields.slug;

  const timeZone =
    (featuredEvent[0].node.frontmatter.dateInfo?.timeZone ||
      featuredEvent[0].node.frontmatter.timeZone) ??
    "America/Los_Angeles";

  const [fadeIn] = useSpring(
    () => ({
      from: {
        opacity: 0,
        transform: "translateY(2.5rem)",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
      },
      to: { opacity: 1, transform: "translateY(0rem)" },
      config: { mass: 3, bounce: 1, duration: 500 },
    }),
    []
  );

  const linkText = useMemo(() => {
    return type === "Live Webinar" ? "Register Now" : "Watch Now";
  }, [type]);

  return (
    <animated.div style={fadeIn}>
      <FeaturedCard as={Link} to={slug}>
        <div className="image-wrapper">
          <TypeTag>{type}</TypeTag>
          <img src={image} alt={title} />
        </div>
        <div className="card-body">
          <DateRow date={date} timeZone={timeZone} type={type} />
          <TitleMed style={{ fontSize: "32px" }}>{title}</TitleMed>
          <Body>{blurb}</Body>
        </div>
        <div className="card-footer">
          <TextLinkV2 title={linkText} path={slug} />
        </div>
      </FeaturedCard>
    </animated.div>
  );
}
