import {
  FlexColumn,
  FlexRow,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { SectionTitle } from "components/rhombus-UI/theme/typography";
import React from "react";
import image1 from "./assets/streamlined-management-min.png";
import image2 from "./assets/ai-powered-min.png";
import image3 from "./assets/infastructure-min.png";
import image4 from "./assets/security-min.png";
import testimonialImage from "./assets/j-miller-min.png";
import companyLogo from "./assets/middlebury-logo.png";
import { styled } from "@linaria/react";

const PLATFORM_CARDS = [
  {
    image: image1,
    title: "Streamlined Management",
    blurb: "Control all locations, users, and devices from a single dashboard.",
  },
  {
    image: image2,
    title: "AI-Powered Intelligence",
    blurb:
      "Automatic alerts and smart detection reduce manual monitoring and response times.",
  },
  {
    image: image3,
    title: "Zero Infrastructure Overhead",
    blurb:
      "Cloud-first architecture eliminates costly on-premise servers & bandwidth.",
  },
  {
    image: image4,
    title: "Advanced Security",
    blurb:
      "Enterprise-grade encryption and automatic updates keep your system secure.",
  },
];

const CardContainer = styled(FlexRow)`
  gap: 5rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const PlatformCard = styled(FlexColumn)`
  border-radius: 1rem;
  overflow: hidden;
  height: 600px;
  width: calc(50% - 5rem);
  .image {
    flex-grow: 1;
    aspect-ratio: 600/440;
    background-color: #dbe5ef;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }
  .text {
    background-color: var(--white);
    padding: 1.25rem;
    min-height: 160px;
    flex-grow: 1;
    gap: 12px;
    h2 {
      font-size: 32px;
      line-height: 1.3;
    }
    p {
      font-size: 18px;
    }
  }
  @media (min-width: 1440px) {
    .image {
      img {
        object-fit: cover;
      }
    }
  }

  @media (max-width: 1024px) {
    height: 440px;
    .text {
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 820px) {
    height: 440px;
    width: 100%;
    max-width: 440px;
  }
`;

const PlatformCards = () => {
  return (
    <CardContainer>
      {PLATFORM_CARDS.map((item) => (
        <PlatformCard>
          <div className="image">
            <img src={item.image} />
          </div>
          <TextContainer className="text">
            <h2>{item.title}</h2>
            <p>{item.blurb}</p>
          </TextContainer>
        </PlatformCard>
      ))}
    </CardContainer>
  );
};

const Card = styled(FlexRow)`
  display: flex;
  width: 100%;
  max-width: 1320px;
  height: 513px;
  padding: 28px;
  gap: 28px;
  flex-shrink: 0;
  background-color: var(--white);
  border-radius: 1rem;
  overflow: hidden;
  align-items: center;
  .card-image {
    height: 100%;
    object-fit: contain;
    max-height: 513px;
  }
  q {
    color: var(--blue-800);
    font-size: 32px;
    font-weight: 800;
    line-height: 1.5;
  }
  @media (max-width: 1150px) {
    q {
      font-size: 24px;
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    height: unset;
    align-items: flex-start;
    .card-image {
      object-fit: cover;
      width: 100%;
      border-radius: 1rem;
      max-height: 440px;
    }
  }
`;

const HighlightText = styled(FlexColumn)`
  border-left: 1px solid var(--nuetral-900);
  padding-left: 14px;
  gap: 7px;
  h3 {
    font-size: 20px;
  }
  p {
    text-transform: uppercase;
    line-height: 1;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    p {
      font-size: 12px;
    }
  }
`;

const TestimonialCard = () => {
  return (
    <Card>
      <img src={testimonialImage} className="card-image" />
      <FlexColumn
        style={{
          gap: "2.5rem",
          justifyContent: "center",
        }}
      >
        <FlexRow style={{ gap: "48px" }}>
          <HighlightText>
            <h3>70%</h3>
            <p>shorter investigation time</p>
          </HighlightText>
          <HighlightText>
            <h3>80%</h3>
            <p>decrease in system outages</p>
          </HighlightText>
        </FlexRow>
        <q>
          With Rhombus, we've reduced the time it takes to investigate incidents
          by 70%. What used to take hours of reviewing footage now only takes
          minutes.
        </q>
        <FlexRow style={{ alignItems: "center", gap: "20px" }}>
          <img src={companyLogo} width={82} height={82} />
          <TextContainer>
            <span>
              <b>Jeremy Miller,</b> Director of Technology and Communications
            </span>
            <b>Middlebury Community Schools</b>
          </TextContainer>
        </FlexRow>
      </FlexColumn>
    </Card>
  );
};

const PlatformF = () => {
  return (
    <SectionContainer style={{ backgroundColor: "var(--nuetral-100)" }}>
      <SectionInner className="flex-col" style={{ gap: "5rem" }}>
        <SectionTitle>One Platform. Complete Control.</SectionTitle>
        <PlatformCards />
        <TestimonialCard />
      </SectionInner>
    </SectionContainer>
  );
};

export default PlatformF;
