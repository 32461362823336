export const cameraPricingData = (data) => {
  const domeCameras = [
    {
      name: "R120",
      category: "Dome",
      title: "Mini Dome",
      resolution: "2MP",
      image: data.r120,
      altText: "Rhombus R120 Mini Dome Camera",
      prices: [
        {
          days: "20",
          storage: "128GB",
          value: 349,
        },
      ],
    },
    {
      name: "R170",
      category: "Dome",
      title: "Micro Dome",
      resolution: "5MP",
      image: data.r170,
      altText: "Rhombus R170 Micro Dome Camera",
      prices: [
        {
          days: "20",
          storage: "128GB",
          value: 425,
        },
        {
          days: "40",
          storage: "256GB",
          value: 525,
        },
        {
          days: "90",
          storage: "512GB",
          value: 825,
        },
        {
          days: "180",
          storage: "1TB",
          value: 1325,
        },
      ],
    },
    {
      name: "R200",
      category: "Dome",
      title: "Mini Dome",
      resolution: "5MP",
      image: data.r120,
      altText: "Rhombus R200 Mini Dome Camera",
      prices: [
        {
          days: "20",
          storage: "128GB",
          value: 499,
        },
        {
          days: "40",
          storage: "256GB",
          value: 599,
        },
        {
          days: "90",
          storage: "512GB",
          value: 899,
        },
        {
          days: "180",
          storage: "1TB",
          value: 1399,
        },
      ],
    },
    {
      name: "R230",
      category: "Dome",
      title: "WiFi Dome",
      resolution: "5MP",
      image: data.r230,
      altText: "Rhombus R230 Wifi Dome Camera",
      prices: [
        {
          days: "20",
          storage: "128GB",
          value: 899,
        },
        {
          days: "40",
          storage: "256GB",
          value: 999,
        },
        {
          days: "90",
          storage: "512GB",
          value: 1299,
        },
        {
          days: "180",
          storage: "1TB",
          value: 1799,
        },
      ],
    },
    {
      name: "R400",
      category: "Dome",
      title: "Dome",
      resolution: "8.3MP 4k",
      image: data.r400,
      altText: "Rhombus R400 Dome Camera",
      prices: [
        {
          days: "30",
          storage: "512GB",
          value: 1299,
        },
        {
          days: "60",
          storage: "1TB",
          value: 1799,
        },
      ],
    },
  ];

  const fisheyeCameras = [
    {
      name: "R360",
      category: "Fisheye",
      title: "Fisheye",
      resolution: "12MP",
      image: data.r360,
      altText: "Rhombus R360 Fisheye Camera",
      prices: [
        {
          days: "30",
          storage: "512GB",
          value: 1299,
        },
        {
          days: "60",
          storage: "1TB",
          value: 1799,
        },
      ],
    },
  ];

  const bulletCameras = [
    {
      name: "R500",
      category: "Bullet",
      title: "Telephoto Bullet",
      resolution: "8.3MP 4k",
      image: data.r500,
      altText: "Rhombus R500 Telephone Bullet Camera",
      prices: [
        {
          days: "30",
          storage: "512GB",
          value: 1499,
        },
        {
          days: "60",
          storage: "1TB",
          value: 1999,
        },
      ],
    },
    {
      name: "R510",
      category: "Bullet",
      title: "Standard Zoom Bullet",
      resolution: "8.3MP 4k",
      image: data.r500,
      altText: "Rhombus R510 Standard Zoom Bullet Camera",
      prices: [
        {
          days: "30",
          storage: "512GB",
          value: 1499,
        },
        {
          days: "60",
          storage: "1TB",
          value: 1999,
        },
      ],
    },
    {
      name: "R520",
      category: "Bullet",
      title: "Mini Bullet",
      resolution: "5MP",
      image: data.r520,
      altText: "Rhombus R520 Mini Bullet Camera",
      prices: [
        {
          days: "20",
          storage: "128GB",
          value: 899,
        },
        {
          days: "40",
          storage: "256GB",
          value: 999,
        },
        {
          days: "90",
          storage: "512GB",
          value: 1299,
        },
        {
          days: "180",
          storage: "1TB",
          value: 1799,
        },
      ],
    },
  ];

  const multiSensorCameras = [
    {
      name: "R600",
      category: "Multisensor",
      title: "Multisensor",
      resolution: "4 x 5MP",
      image: data.r600,
      altText: "Rhombus R600 Multi sensor camera",
      prices: [
        {
          days: "40",
          storage: "1TB",
          value: 3099,
        },
        {
          days: "90",
          storage: "2TB",
          value: 4099,
        },
      ],
    },
  ];

  return {
    domeCameras,
    fisheyeCameras,
    bulletCameras,
    multiSensorCameras,
  };
};

export const licencePricing = [
  {
    name: "Professional",
    for: [
      "R120",
      "R170",
      "R200",
      "R230",
      "R360",
      "R400",
      "R500",
      "R510",
      "R520",
      "A100",
    ],
    prices: [
      {
        term: 1,
        price: 149,
      },
      {
        term: 3,
        price: 359,
      },
      {
        term: 5,
        price: 519,
      },
      {
        term: 10,
        price: 1039,
      },
    ],
  },
  {
    name: "Enterprise",
    for: [
      "R120",
      "R170",
      "R200",
      "R230",
      "R360",
      "R400",
      "R500",
      "R510",
      "R520",
      "A100",
    ],
    prices: [
      {
        term: 1,
        price: 199,
      },
      {
        term: 3,
        price: 479,
      },
      {
        term: 5,
        price: 699,
      },
      {
        term: 10,
        price: 1399,
      },
    ],
  },
  {
    name: "Enterprise",
    for: ["R600"],
    prices: [
      {
        term: 1,
        price: 599,
      },
      {
        term: 3,
        price: 1449,
      },
      {
        term: 5,
        price: 2199,
      },
      {
        term: 10,
        price: 4399,
      },
    ],
  },
];
