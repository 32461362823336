import { SectionContainer } from "components/rhombus-UI/theme/containers";
import { StaticQuery, graphql } from "gatsby";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useMemo, useState } from "react";
import { SectionTitle } from "components/rui/typography";
import TextLinkV2 from "./TextLinkV2";

const SliderWrapper = styled(SectionContainer)`
  background: var(--gradient-light);
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const carouselWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const CarouselInner = styled.ul`
  user-select: none;
  display: flex;
  gap: 2rem;
  height: 100%;
  width: calc(10rem * ${(props) => props.width});
  overflow: auto;
  -webkit-mask: linear-gradient(
    90deg,
    #0000,
    var(--nuetral-900) 10% 80%,
    #0000
  );
  ::-webkit-scrollbar {
    display: none;
  }
  mask: linear-gradient(90deg, #0000, var(--nuetral-900) 10% 80%, #0000);
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  pointer-events: none;
  -ms-overflow-style: none;
  pointer-events: none;
`;
const carouselItem = css`
  height: 7rem;
  aspect-ratio: 1;
  transition: all 0.3s ease;
  animation: translateinfinite 75s linear infinite reverse;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes translateinfinite {
    100% {
      transform: translateX(calc(-7rem * var(--width)));
    }
  }
`;

const carouselItem2 = css`
  height: 7rem;
  aspect-ratio: 1;
  transition: all 0.3s ease;
  animation: translateinfinite 75s linear infinite;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes translateinfinite {
    100% {
      transform: translateX(calc(-7rem * var(--width)));
    }
  }
`;

const iconStyles = css`
  height: 100%;
  aspect-ratio: 1;
  background: var(--white);
  border-radius: 5px;
  object-fit: contain;
  overflow: hidden;
`;

export default function IntegrationSlider() {
  const renderContent = (data) => {
    const { edges: _integrations } = data.allMarkdownRemark;
    const [appIcons, setAppIcons] = useState([]);

    useMemo(() => {
      _integrations.map((item) => {
        setAppIcons((prev) => [...prev, item.node.frontmatter.appIcon]);
      });
    }, []);

    const half = Math.ceil(appIcons.length / 2);

    const firstHalf = appIcons.slice(0, half);
    const secondHalf = appIcons.slice(half);

    const appIconRow1 = [...firstHalf, ...firstHalf];
    const appIconRow2 = [...secondHalf, ...secondHalf].reverse();

    return (
      <SliderWrapper>
        <SectionTitle>
          Enjoy Plug-and-Play Integrations for All Needs
        </SectionTitle>
        <div className={carouselWrapper}>
          <CarouselInner width={appIconRow1.length}>
            {appIconRow1.map((icon, index) => (
              <li
                className={carouselItem}
                key={index}
                style={{ "--width": firstHalf.length }}
              >
                <img src={icon} className={iconStyles}></img>
              </li>
            ))}
          </CarouselInner>
        </div>
        <div className={carouselWrapper}>
          <CarouselInner width={appIconRow2.length}>
            {appIconRow2.map((icon, index) => (
              <li
                className={carouselItem2}
                key={index}
                style={{ "--width": secondHalf.length }}
              >
                <img src={icon} className={iconStyles}></img>
              </li>
            ))}
          </CarouselInner>
        </div>
        <TextLinkV2 path={"/integrations"} title={"Explore Integrations"} />
      </SliderWrapper>
    );
  };
  const query = graphql`
    query {
      allMarkdownRemark(
        limit: 60
        filter: {
          frontmatter: {
            templateKey: { eq: "integrations-page" }
            tags: { in: ["Slider"] }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              appIcon
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
