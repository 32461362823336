import { SectionContainer } from "components/rhombus-UI/theme/containers";
import React, { useEffect, useMemo, useState } from "react";
import WebinarSidebar from "./WebinarSidebar";
import WebinarsFeed from "./WebinarsFeed";
import { graphql, useStaticQuery } from "gatsby";
import {
  filterWebinarsByCollectionOrTopic,
  getAllTopicsInUse,
  getType,
  isHybridEventExpired,
  isLiveWebinarExpired,
  WEBINAR_TYPES,
} from "./util/webinarsFunctions";
import useIsMobile from "hooks/useIsMobile";
import { WebinarsSectionInner } from "./styles/webinarsPageStyles";

export default function Webinars() {
  const data = useStaticQuery(graphql`
    query {
      webinarsQuery: allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "webinar-post" }
            draft: { ne: true }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              type
              cardImage
              startDate
              title
              topics
            }
          }
        }
      }
      introWebinarsQuery: allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "intro-webinar-post" }
            draft: { ne: true }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              type
              cardImage
              startDate
              title
              cardTitle
              topics
            }
          }
        }
      }
      hybridEventsQuery: allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "hybrid-event" }
            draft: { ne: true }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              type
              title
              cardImage
              topics
              dateInfo {
                startDate
              }
            }
          }
        }
      }
    }
  `);
  const { webinarsQuery, introWebinarsQuery, hybridEventsQuery } = data;
  const { edges: _webinars } = webinarsQuery;
  const { edges: _intro_webinars } = introWebinarsQuery;
  const { edges: _hybridEvents } = hybridEventsQuery;
  const [webinars, setWebinars] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [filteredWebinars, setFilteredWebinars] = useState([]);
  const { isMobile } = useIsMobile(1024);

  useEffect(() => {
    setWebinars(
      [
        ..._intro_webinars.filter(isLiveWebinarExpired),
        ..._hybridEvents.filter(isHybridEventExpired),
        ..._webinars,
      ]
        .map((webinar) => {
          webinar.node.frontmatter.type = getType(
            webinar.node.frontmatter.type,
            webinar.node.frontmatter.startDate
          );
          return webinar;
        })
        .sort((a, b) => {
          const dateA = new Date(
            a.node.frontmatter.startDate ||
              a.node.frontmatter?.dateInfo?.startDate
          );
          console.log(dateA);
          const dateB = new Date(
            b.node.frontmatter.startDate ||
              b.node.frontmatter?.dateInfo?.startDate
          );

          if (
            a.node.frontmatter.type === WEBINAR_TYPES.live &&
            b.node.frontmatter.type === WEBINAR_TYPES.live
          )
            return dateA - dateB;
        })
    );
  }, []);

  const topics = useMemo(() => {
    return getAllTopicsInUse(webinars);
  }, [webinars]);

  useEffect(() => {
    setFilteredWebinars(
      filterWebinarsByCollectionOrTopic(webinars, activeFilter)
    );
  }, [activeFilter, webinars]);

  return (
    <SectionContainer id="webinars-feed" style={{ overflow: "unset" }}>
      <WebinarsSectionInner>
        {!isMobile && (
          <WebinarSidebar
            topics={topics}
            setActiveFilter={setActiveFilter}
            activeFilter={activeFilter}
          />
        )}
        <WebinarsFeed
          webinars={filteredWebinars}
          topics={topics}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
        />
      </WebinarsSectionInner>
    </SectionContainer>
  );
}
