import {
  CarouselContainerWithScrollBar,
  SectionContainer,
  SliderSectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import { FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { PreTitle, TitleMed } from "components/rui/typography";

const SliderSection = styled(SectionContainer)`
  padding-left: 0;
  padding-right: 0;
  h2,
  h3 {
    padding: 0 2.5rem;
  }
  @media (max-width: 600px) {
    h2,
    h3 {
      padding: 0 1.25rem;
    }
  }
`;

const slideImageStyles = css`
  transition: all 250ms ease-in;
`;

const slideStyles = css`
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  width: 313px !important;
  height: 358px !important;
  overflow: hidden !important;
  transition: all 250ms ease-in-out;
  :hover {
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.3);
  }
  :hover .${slideImageStyles} {
    transform: scale(1.05);
  }
`;

const IndustryCard = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: flex-end;
`;

const CardHeader = styled.div`
  width: 100%;
  height: 161px;
  flex-shrink: 0;
  overflow: hidden;
`;

const CardBody = styled.div`
  gap: 1.25rem;
  padding: 0.5rem;
  flex-grow: 1;
`;

const Qoute = styled.q`
  font-size: 16px;
  color: var(--nuetral-900);
  line-height: 200%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  flex-grow: 1;
`;

const logoStyles = css``;

const TitleContainer = styled(TextContainer)`
  text-align: center;
  @media (max-width: 600px) {
    text-align: left;
  }
`;

export default function IndustryLeadersCards({
  color = "var(--white)",
  title = " Trusted by Industry Leaders",
  preTitle = null,
}) {
  const data = useStaticQuery(graphql`
    query {
      industryCard1: file(
        relativePath: {
          eq: "components/home/img/industry-cards/luxer-one-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      industryLogo1: file(
        relativePath: {
          eq: "components/common/logos/color/luxer-one-logo-color.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 62, height: 20)
        }
      }
      industryCard2: file(
        relativePath: { eq: "components/home/img/industry-cards/cau-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      industryLogo2: file(
        relativePath: { eq: "components/common/logos/color/cau-logo-color.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 43, height: 31)
        }
      }
      industryCard3: file(
        relativePath: { eq: "components/home/img/industry-cards/ahf-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      industryLogo3: file(
        relativePath: { eq: "components/common/logos/color/ahf-logo-color.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 79, height: 22)
        }
      }
      industryCard4: file(
        relativePath: {
          eq: "components/home/img/industry-cards/praesum-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      industryLogo4: file(
        relativePath: {
          eq: "components/common/logos/color/praesum-logo-color.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 73, height: 22)
        }
      }
      industryCard5: file(
        relativePath: {
          eq: "components/home/img/industry-cards/10-federal-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      industryLogo5: file(
        relativePath: {
          eq: "components/common/logos/color/10-federal-logo-color.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 71, height: 22)
        }
      }
      industryCard6: file(
        relativePath: { eq: "components/home/img/industry-cards/uacdc-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      industryLogo6: file(
        relativePath: {
          eq: "components/common/logos/color/uacdc-logo-color.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 28, height: 33)
        }
      }
    }
  `);
  const industryCards = [
    {
      qoute: (
        <>
          We never have to worry a about the reliability of our video feeds, and{" "}
          <b>my team’s efficiency increased by over 150%.</b>
        </>
      ),
      link: "/case-studies/luxer-one/",
      image: data.industryCard1,
      alt: "Demonstrating how Rhombus physical security systems technology provided Luxer with video cameras for reliable video feeds",
      logo: data.industryLogo1,
    },
    {
      qoute: (
        <>
          Rhombus genuinely cares about creating a product and service that{" "}
          <b>fits their users’ needs.</b>
        </>
      ),
      link: "/case-studies/clark-atlanta-university/",
      image: data.industryCard2,
      alt: "Showcasing how Rhombus commercial security system protected Clark Atlanta University against any security threats",
      logo: data.industryLogo2,
    },
    {
      qoute: (
        <>
          <b>Rhombus adapts to every environment we throw at it</b>, including
          very challenging environments.
        </>
      ),
      link: "/case-studies/aids-healthcare-foundation/",
      image: data.industryCard3,
      alt: "Showcasing how Rhombus advanced physical security technology protected Clark Atlanta University against any physical security threats",
      logo: data.industryLogo3,
    },
    {
      qoute: (
        <>
          Rhombus made my life easier and has{" "}
          <b>given my end-users the ability to do their job even better.</b>
        </>
      ),
      link: "/case-studies/praesum-healthcare/",
      image: data.industryCard4,
      alt: "Showcasing how Rhombus commercial cameras helped Praesum Healthcare mitigate physical security risks",
      logo: data.industryLogo4,
    },
    {
      qoute: (
        <>
          We’re barely scratching the surface of what’s possible and{" "}
          <b>the whole experience has been exceptional.</b>,
        </>
      ),
      link: "/case-studies/10-federal/",
      image: data.industryCard5,
      alt: "Showcasing how Rhombus physical security systems helped 10 federal self storage evaluate their physical security measures",
      logo: data.industryLogo5,
    },
    {
      qoute: (
        <>
          Our IT and security staff tell me that{" "}
          <b>it’s the best system they’ve ever worked with</b> and they’ve been
          doing this for 20+ years.
        </>
      ),
      link: "/case-studies/uacdc/",
      image: data.industryCard6,
      alt: "Showcasing how Rhombus commercial physical security management helped University Areas DC assess their physical security threats",
      logo: data.industryLogo6,
    },
  ];

  return (
    <SliderSection style={{ background: color }}>
      <SliderSectionInner>
        <TitleContainer>
          {preTitle && (
            <PreTitle center color="var(--primary-bg)">
              {preTitle}
            </PreTitle>
          )}
          <TitleMed center>{title}</TitleMed>
        </TitleContainer>
        <CarouselContainerWithScrollBar>
          <Swiper
            slidesPerView="auto"
            centeredSlides
            centeredSlidesBounds
            spaceBetween={20}
            freeMode
            scrollbar={{ draggable: true }}
            mousewheel={{
              releaseOnEdges: true,
              invert: false,
            }}
            modules={[Scrollbar, Mousewheel, FreeMode]}
          >
            {industryCards.map((item, index) => (
              <SwiperSlide className={slideStyles} key={index}>
                <IndustryCard as={Link} to={item.link}>
                  <CardHeader>
                    <GatsbyImage
                      image={getImage(item.image)}
                      alt={item.alt}
                      objectPosition="center"
                      objectFit="cover"
                      className={slideImageStyles}
                    />
                  </CardHeader>
                  <CardBody>
                    <Qoute>{item.qoute}</Qoute>
                  </CardBody>
                  <CardFooter>
                    <GatsbyImage
                      image={getImage(item.logo)}
                      objectFit="contain"
                      className={logoStyles}
                    />
                  </CardFooter>
                </IndustryCard>
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselContainerWithScrollBar>
      </SliderSectionInner>
    </SliderSection>
  );
}
