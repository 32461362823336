import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { FancyPageTitle } from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

import Calendar from "./img/calendar.svg";
import Location from "./img/location.svg";
import Person from "./img/person.svg";
import Web from "./img/web.svg";
import BackLink from "components/common/BackLink";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
`;

const HeroInner = styled(SectionInner)`
  @media (max-width: 1075px) {
    flex-direction: column;
  }
`;

const sectionLeft = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  .logo {
    width: 110px;
    height: 89px;
    object-position: left;
    object-fit: contain;
  }
  @media (max-width: 1075px) {
    width: 100%;
  }
`;

const infoBubble = css`
  background: var(--white);
  display: flex;
  border-radius: 1.25rem;
  padding: 2.5rem 1.5rem;
  justify-content: space-between;
  width: 70%;
  max-width: 500px;
  @media (max-width: 1075px) {
    width: 100%;
    position: absolute;
    bottom: -1.25rem;
    left: -1.25rem;
    box-shadow: 1px 1px 10px #03193320;
  }
  @media (max-width: 600px) {
    position: static;
    box-shadow: none;
  }
`;

const col = css`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const infoItem = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  a,
  p {
    font-weight: 600;

    font-size: 14px;
  }
  a {
    color: inherit;
    color: var(--blue-500);
  }
`;

const iconStyles = css`
  height: 24px;
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const sectionRight = css`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1075px) {
    width: 100%;
    position: relative;
  }
`;

const heroImage = css`
  width: 695px;
  height: 476px;
  object-fit: cover;
  overflow: hidden;
  margin-right: -7.5rem;
  border-radius: 1.25rem;
  @media (min-width: 1600px) {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
  @media (max-width: 1075px) {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const SpeakingSessionHero = ({
  image,
  logo,
  title,
  booth,
  website,
  startDate,
  endDate,
  location,
}) => {
  const { isMobile } = useIsMobile(1075);
  const startDateTime = toZonedTime(startDate, "Etc/UTC");
  const endDateTime = toZonedTime(endDate, "Etc/UTC");
  const displayDate = `${format(startDateTime, "MMMM")} ${format(startDateTime, "d")} - ${format(endDateTime, "d")}, ${format(startDateTime, "yyyy")}`;

  const eventInfo = [
    { icon: Person, info: `Meet us at Booth #${booth}` },
    { icon: Calendar, info: displayDate },
    { icon: Location, info: location },
  ];

  return (
    <HeroContainer>
      <HeroInner>
        <div className={sectionLeft}>
          <BackLink path="/events#events-feed" text="In-Person Events" />
          <TextContainer>
            <img src={logo} className="logo" alt={title} />
            <FancyPageTitle>{title}</FancyPageTitle>
          </TextContainer>
          {!isMobile && (
            <div className={infoBubble}>
              <div className={col}>
                {eventInfo.map((item) => (
                  <div className={infoItem}>
                    <img src={item.icon} alt="icon" className={iconStyles} />
                    <p>{item.info}</p>
                  </div>
                ))}
              </div>
              <div className={col}>
                <div className={infoItem}>
                  <img src={Web} alt="Web Icon" className={iconStyles} />
                  <a href={website} target="_blank" rel="noreferrer">
                    Website
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={sectionRight}>
          <img src={image} alt="" className={heroImage} />
          {isMobile && (
            <div className={infoBubble}>
              <div className={col}>
                {eventInfo.map((item) => (
                  <div className={infoItem}>
                    <img src={item.icon} alt="icon" className={iconStyles} />
                    <p>{item.info}</p>
                  </div>
                ))}
              </div>
              <div className={col}>
                <div className={infoItem}>
                  <img src={Web} alt="Web Icon" className={iconStyles} />
                  <a href={website} target="_blank" rel="noreferrer">
                    Website
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </HeroInner>
    </HeroContainer>
  );
};

export default SpeakingSessionHero;
