import PageContent from "components/page/PageContent";
import React from "react";
import SlideInContainer from "./SlideInContainer";
import { css } from "@linaria/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  DefaultFlexContainer,
  SectionContainer,
} from "components/rhombus-UI/theme/containers";
import RhombusButton from "./RhombusButton";
import { TitleMed, TitleSmall } from "components/rui/typography";

const headerContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  @media (max-width: 700px) {
    justify-content: center;
  }

  > div {
    width: 100%;
    max-width: 665px;

    div {
      display: flex;
      justify-content: center;

      @media (max-width: 700px) {
        justify-content: flex-start;
      }
    }
  }
`;

const gifContainer = css`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  column-gap: 55px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 25px;
  }
  @media (max-width: 700px) {
    justify-content: center;
    margin: 0 auto;
    max-width: 445px;
  }
`;

const textContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  width: 100%;

  @media (max-width: 1000px) {
    width: 45%;
  }
  @media (max-width: 700px) {
    width: 100%;
  }

  div {
    width: 100%;
  }

  p {
    max-width: 445px;
  }
`;

const iconClass = css`
  height: 47px;
  width: 47px !important;
  margin-bottom: 1.25rem;
  pointer-events: none;
  content-visibility: visible;
`;

const Features = ({
  data,
  title = "Platform Features",
  color = "var(--white)",
  useCTA = false,
}) => {
  return (
    <SectionContainer style={{ backgroundColor: color }}>
      <PageContent>
        <div className={headerContainer}>
          <SlideInContainer className="slide">
            <TitleMed center>{title}</TitleMed>
          </SlideInContainer>
        </div>
        <div className={gifContainer}>
          {data.map((item, index) => (
            <div key={index} className={textContainer}>
              <SlideInContainer className="slide">
                <GatsbyImage
                  image={getImage(item.icon)}
                  alt={item.iconAlt}
                  className={iconClass}
                  objectFit="contain"
                />
                <TitleSmall>{item.title}</TitleSmall>
              </SlideInContainer>
              <SlideInContainer className="slide">
                <p>{item.p}</p>
              </SlideInContainer>
            </div>
          ))}
        </div>
        {useCTA && (
          <DefaultFlexContainer style={{ marginTop: "2.5rem" }}>
            <RhombusButton
              title={"Compare Cameras"}
              path={"/cameras/compare-cameras/"}
            />
          </DefaultFlexContainer>
        )}
      </PageContent>
    </SectionContainer>
  );
};

export default Features;
