import RelatedBlogPostCard from "components/blog/RelatedBlogPostCard";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import {
  FancyPageTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { css } from "@linaria/core";
import React, { useEffect, useState } from "react";

import Content from "../components/blog/Content";
import TableOfContents from "components/table-of-contents/TableOfContents";
import TableOfContentsMobile from "components/table-of-contents/TableOfContentsMobile";
import BackLink from "components/common/BackLink";
import AuthorSnippet from "components/blog/AuthorSnippet";
import AuthorProfileCard from "components/blog/AuthorProfileCard";
import { styled } from "@linaria/react";
import {
  CTA_BUTTON_COPY_7,
  LIVE_DEMO_LINK,
} from "components/data/landing-page-data";

const blogPostSectionClass = css`
  padding: 5rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 42px;
  }
  @media (max-width: 600px) {
    padding: 5rem 1.25rem;
    h1 {
      font-size: 32px;
    }
  }
`;

const BlogPostContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.useToc ? "var(--max-width)" : "1024px")};
  #blog-content p {
    margin-bottom: 1em;
  }

  #blog-content a {
    color: var(--primary-bg);
    font-family: inherit;
    font-size: inherit;
    font-style: normal;
    line-height: 150%;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-color: var(--primary);
    text-decoration-thickness: 8%;
    text-underline-offset: 36%;
    text-underline-position: from-font;
    transition: all 250ms;
    width: fit-content;
    :hover {
      text-decoration-thickness: 12%;
    }
  }

  #blog-content img {
    max-width: 850px;
    height: auto;
    display: block;
    content-visibility: visible;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin: 1.25rem 0;
  }
`;

const blogPostWrapper = css`
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  flex-grow: 1;
  width: 100%;
  @media (max-width: 1175px) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const blogPostImageContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin-bottom: 2.5rem;
  max-height: 600px;
  border-radius: 8px;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 0px;
  }
`;

const blogPostContentClass = css`
  h1,
  h2,
  h3 {
    font-weight: 600;
    margin: 2rem 0 1rem 0;
    line-height: 1.5;
    scroll-margin-top: 110px;
    @media (max-width: 800px) {
      scroll-margin-top: 330px;
    }
  }
  h2 {
    font-size: 32px;
  }

  p {
    margin: 1rem 0;
  }
  a {
    font-size: inherit;
  }
  @media (max-width: 600px) {
    padding: 5rem 1.25rem;
    ul {
      padding-left: 1.25rem;
    }
    h2,
    h3 {
      font-size: 24px;
    }
  }
`;

const RelatedArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 5rem;
  align-items: center;
  width: 100%;
`;

const relatedArticlesRowClass = css`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 45px;
  max-width: var(--max-width);
  width: 100%;
  margin: 0 auto 40px auto;
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }
`;

export default function BlogPostTemplate({
  content,
  author,
  category,
  helmet,
  thumbnail,
  thumbnailAlt,
  title,
  date,
  relatedPosts,
  useCTA,
}) {
  const { isMobile } = useIsMobile(1175);
  const [useToc, setUseToc] = useState(false);

  useEffect(() => {
    setUseToc(!!document.querySelector(`#blog-content h2`));
  }, []);

  const {
    name,
    profilePhoto: { thumbnail: src, altText: alt },
    role,
    bio,
    socialLink,
  } = author;

  return (
    <>
      <section className={blogPostSectionClass}>
        {helmet ?? <></>}
        <BlogPostContent useToc={useToc}>
          <BackLink path="/blog/" text=" Back to blog home" />
          <div className={blogPostWrapper}>
            {useToc &&
              (!isMobile ? (
                <div>
                  <TableOfContents
                    contentID="blog-content"
                    useFullWidth
                    buttonCopy={CTA_BUTTON_COPY_7}
                    buttonLink={LIVE_DEMO_LINK}
                  />
                </div>
              ) : (
                <TableOfContentsMobile
                  contentID="blog-content"
                  buttonCopy={CTA_BUTTON_COPY_7}
                  buttonLink={LIVE_DEMO_LINK}
                />
              ))}
            <ContentContainer>
              <FancyPageTitle>{title}</FancyPageTitle>
              <AuthorSnippet
                publishDate={date}
                name={name}
                src={src}
                alt={alt}
                category={category}
              />
              <div className={blogPostImageContainer}>
                <img src={thumbnail} alt={thumbnailAlt} />
              </div>
              <div id="blog-content">
                <Content
                  content={content}
                  className={blogPostContentClass}
                  useCTA={useCTA}
                />
              </div>
              {bio && (
                <AuthorProfileCard
                  alt={alt}
                  bio={bio}
                  name={name}
                  role={role}
                  socialLink={socialLink}
                  src={src}
                />
              )}
            </ContentContainer>
          </div>
        </BlogPostContent>
        {relatedPosts && (
          <RelatedArticlesContainer>
            <TitleMed style={{ fontWeight: 600, fontSize: "24px" }}>
              Related Articles
            </TitleMed>
            <div className={relatedArticlesRowClass}>
              {relatedPosts.map((post, i) => (
                <RelatedBlogPostCard post={post} key={i} />
              ))}
            </div>
          </RelatedArticlesContainer>
        )}
      </section>
      <TrialBanner />
    </>
  );
}
