import SlideInContainer from "components/common/SlideInContainer";
import Link from "components/page/headers/HeaderLink";
import PageContent from "components/page/PageContent";
import {
  FlexCenter,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import {
  Body,
  SectionPreTitle,
  SectionTitle,
  TitleMed,
  TitleSmall,
} from "components/rui/typography";

const Section = styled(SectionContainer)`
  background: var(--nuetral-100);
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
`;

const foundersContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  max-width: 1025px;
  margin: 0 auto;
  flex-wrap: wrap;
`;

const founderClass = css`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(43, 46, 48, 0.99);
  text-align: center;
  @media (max-width: 600px) {
    width: 100%;
  }

  > div {
    width: 100%;
  }
`;

const founderImage = css`
  width: 200px;
  aspect-ratio: 1;
  pointer-events: none;
  margin-bottom: 1rem;
`;

const linkClass = css`
  height: 100%;
  width: 100%;
  transition: all 500ms ease;
  &:hover {
    transform: scale(1.03);
  }
`;

export default function Founders() {
  return (
    <>
      <Section>
        <PageContent>
          <SlideInContainer>
            <div className={headerContainer}>
              <SectionPreTitle>leadership</SectionPreTitle>
              <SectionTitle>Meet The Founders</SectionTitle>
            </div>
          </SlideInContainer>
          <div className={foundersContainer}>
            <div className={founderClass}>
              <Link
                href="https://www.linkedin.com/in/garrettlarsson/"
                target="_blank"
                className={linkClass}
              >
                <SlideInContainer>
                  <StaticImage
                    src="./img/garrett.png"
                    className={founderImage}
                    alt="Founder Garrett Larson"
                  />
                </SlideInContainer>
              </Link>

              <SlideInContainer>
                <TitleSmall>Garrett Larsson</TitleSmall>
              </SlideInContainer>
              <SlideInContainer>
                <Body>CEO</Body>
              </SlideInContainer>
            </div>

            <div className={founderClass}>
              <Link
                href="https://www.linkedin.com/in/davegus/"
                target="_blank"
                className={linkClass}
              >
                <SlideInContainer>
                  <StaticImage
                    src="./img/dave.png"
                    className={founderImage}
                    alt="Founder Dave Gustafson"
                  />
                </SlideInContainer>
              </Link>
              <SlideInContainer>
                <TitleSmall>Dave Gustafson</TitleSmall>
              </SlideInContainer>
              <SlideInContainer>
                <p>VP of Hardware</p>
              </SlideInContainer>
            </div>
            <div className={founderClass}>
              <Link
                href="https://www.linkedin.com/in/omarkhans/"
                target="_blank"
                className={linkClass}
              >
                <SlideInContainer>
                  <StaticImage
                    src="./img/omar.png"
                    className={founderImage}
                    alt="Founder Omar Khan"
                  />
                </SlideInContainer>
              </Link>

              <SlideInContainer>
                <TitleSmall>Omar Khan</TitleSmall>
              </SlideInContainer>
              <SlideInContainer>
                <p>COO</p>
              </SlideInContainer>
            </div>
          </div>
        </PageContent>
      </Section>
      <SectionContainer color="var(--nuetral-100)">
        <SectionInner style={{ gap: "4rem" }} reverse>
          <SectionCol>
            <StaticImage
              src="./img/industry-veterans-min.png"
              alt=""
              objectFit="contain"
            />
          </SectionCol>
          <FlexCenter>
            <TextContainer style={{ gap: "1rem" }}>
              <TitleMed>Industry Veterans in Cloud Physical Security</TitleMed>
              <Body>
                Founded in 2016, Rhombus was one of the first companies in the
                world to develop cloud-based physical security at the enterprise
                level. Since pioneering cloud security cameras, we've continued
                to create new, innovative ways to serve our customers. From new
                product lines and services to new features rolled out at no
                cost, we're proud to share our expertise and pave the way for
                modernized physical security
              </Body>
            </TextContainer>
          </FlexCenter>
        </SectionInner>
      </SectionContainer>
      <SectionContainer color="var(--nuetral-100)">
        <SectionInner style={{ gap: "4rem" }}>
          <FlexCenter>
            <TextContainer style={{ gap: "1rem" }}>
              <TitleMed>Prioritizing Sustainable Growth</TitleMed>
              <Body>
                Instead of a 'grow-at-all-costs mentality, Rhombus has embraced
                a model of sustainable growth. While still expanding rapidly, we
                grow with care and have built a team of experts who share our
                partnership focus and are dedicated to the long-term success of
                our customers, end-users, and technology partners.
              </Body>
            </TextContainer>
          </FlexCenter>
          <SectionCol>
            <StaticImage
              src="./img/highlights-min.png"
              alt=""
              objectFit="contain"
            />
          </SectionCol>
        </SectionInner>
      </SectionContainer>
    </>
  );
}
