import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useState } from "react";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Body,
  PreTitle,
  TitleMed,
  TitleSmall,
} from "components/rui/typography";

const SlideContainer = styled(SectionContainer)`
  padding: 0;
  padding-bottom: 5rem;
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 2px;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .swiper-scrollbar-drag {
      background-image: linear-gradient(
        270deg,
        var(--teal-500) 0%,
        var(--blue-500) 100%
      );
    }
    @media (max-width: 820px) {
      width: 90%;
    }
  }
`;

const cardContainer = css`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const maskedBoth = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      var(--nuetral-900),
      var(--nuetral-900) 10% 80%,
      var(--nuetral-900)
    );
    @media (max-width: 1023px) {
      -webkit-mask: none;
    }
  `
);
const maskedStart = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      var(--white),
      var(--nuetral-900) 10% 80%,
      #0000
    );
    @media (max-width: 1023px) {
      -webkit-mask: none;
    }
  `
);
const maskedEnd = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      var(--nuetral-900),
      var(--nuetral-900) 10% 80%,
      var(--white)
    );
    @media (max-width: 1023px) {
      -webkit-mask: none;
    }
  `
);

const imageStyles = css`
  width: 100% !important;
  aspect-ratio: 1 !important;
  border-radius: 12px !important;
  transition: all 250ms;
  opacity: 0.9;
`;

const cardStyles = css`
  padding-bottom: 20px;
  width: 100%;
  max-width: 360px;
  a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-decoration: none;
    -webkit-appearance: none;
    color: var(--nuetral-900);
    cursor: pointer;
    text-decoration: none;
  }
  p {
    max-width: 80%;
  }
  :hover {
    .${imageStyles} {
      opacity: 1;
    }
  }
`;

const title = css`
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 1;
`;

const mainTitle = cx(
  title,
  css`
    font-size: 32px;
  `
);

export default function ExperienceCards() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile } = useIsMobile(1439);

  const updateIndex = (swiperInstance) => {
    if (swiperInstance === null) return;
    const currentSlide = swiperInstance?.activeIndex;
    setCurrentIndex(currentSlide);
  };

  const renderContent = (data) => {
    const cards = [
      {
        image: data.image1,
        preTitle: "learn",
        title: "Customer Stories",
        blurb: "Discover why industry leaders choose Rhombus",
        link: "/case-studies/",
      },
      {
        image: data.image2,
        preTitle: "watch",
        title: "Instant Demo",
        blurb: "Watch a five-minute demo to learn more about the platform",
        link: "/instant-demo/",
      },
      {
        image: data.image3,
        preTitle: "Discover",
        title: "The Rhombus Blog",
        blurb: "See how Rhombus can improve your physical security experiences",
        link: "/blog",
      },
      {
        image: data.image4,
        preTitle: "learn",
        title: "Our Pricing",
        blurb: "Find the right devices for your organization’s needs",
        link: "/pricing",
      },
    ];

    const renderMask = () => {
      const start = currentIndex === 0;
      const end = isMobile
        ? currentIndex === cards.length - 1
        : currentIndex === 1;
      if (start) {
        return maskedStart;
      }
      if (end) {
        return maskedEnd;
      }
      return maskedBoth;
    };

    return (
      <SlideContainer>
        <SectionInner style={{ flexDirection: "column" }}>
          <h2 className={mainTitle}>More ways to Experience Rhombus</h2>
          <div className={isMobile ? renderMask() : cardContainer}>
            <Swiper
              initialSlide={currentIndex}
              onActiveIndexChange={updateIndex}
              slidesPerView="auto"
              spaceBetween={40}
              breakpoints={{
                425: {
                  slidesPerView: 1.25,
                  scrollbar: {
                    enabled: true,
                    draggable: true,
                    dragSize: 150,
                    snapOnRelease: true,
                  },
                },
                625: {
                  slidesPerView: 1.5,
                  scrollbar: {
                    enabled: true,
                    draggable: true,
                    dragSize: 150,
                    snapOnRelease: true,
                  },
                },
                900: {
                  slidesPerView: 2.5,
                  scrollbar: {
                    enabled: true,
                    draggable: true,
                    dragSize: 250,
                    snapOnRelease: true,
                  },
                },
                1230: {
                  slidesPerView: 4,
                  scrollbar: {
                    enabled: true,
                    draggable: true,
                    dragSize: 125,
                    snapOnRelease: true,
                  },
                },
              }}
              scrollbar={{
                enabled: true,
                draggable: true,
                snapOnRelease: true,
              }}
              modules={[Scrollbar]}
            >
              {cards.map((card) => (
                <SwiperSlide className={cardStyles}>
                  <a href={card.link}>
                    <GatsbyImage
                      image={getImage(card.image)}
                      alt={card.title}
                      className={imageStyles}
                    />
                    <PreTitle color="var(--primary-bg)">
                      {card.preTitle}
                    </PreTitle>
                    <TitleSmall>{card.title}</TitleSmall>
                    <Body>{card.blurb}</Body>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </SectionInner>
      </SlideContainer>
    );
  };
  const GET_IMAGES = graphql`
    query {
      image1: file(
        relativePath: { eq: "components/thank-you/img/customers-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      image2: file(
        relativePath: { eq: "components/thank-you/img/instant-demo-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      image3: file(
        relativePath: { eq: "components/thank-you/img/blog-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      image4: file(
        relativePath: { eq: "components/thank-you/img/pricing-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
