import Layout from "components/blog/Layout";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import BlogPostTemplate from "./blog-post-template";
import { format, parse } from "date-fns";

const BlogPost = ({ data }) => {
  const {
    markdownRemark: post,
    allMarkdownRemark: relatedPosts,
    author,
  } = data;

  const openGraphDate = format(
    parse(post.frontmatter.date, "MMMM dd, yyyy", new Date()),
    "yyyy-MM-dd"
  );
  const handleMetaImage = () => {
    if (post.frontmatter.metaImage) {
      return post.frontmatter.metaImage;
    }
    return post.frontmatter.thumbnail;
  };

  const metaTitle =
    post.frontmatter?.metaTitle ?? `${post.frontmatter.title} | Blog`;

  const altText =
    post.frontmatter.featuredImages?.featuredImageAltText ?? metaTitle;

  const metaDescription =
    post.frontmatter?.metaDescription ?? post.frontmatter.description;

  const thumbnail =
    post.frontmatter.featuredImages?.featuredImageThumbnail ??
    post.frontmatter.thumbnail;

  const helmet = (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="fb:app_id" content="2181573555228841" />
      <meta property="og:site_name" content="Rhombus" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={post.frontmatter.title} />
      <meta property="og:description" content={post.frontmatter.description} />
      <meta
        property="og:url"
        content={`https://rhombus.com${post.fields.slug}`}
      />
      <meta
        property="og:image"
        content={`https://rhombus.com${handleMetaImage()}`}
      />
      <meta property="article:published_time" content={openGraphDate} />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/rhombus/"
      />
      <meta
        property="article:author"
        content="https://www.facebook.com/rhombus/"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={post.frontmatter.title} />
      <meta name="twitter:description" content={post.frontmatter.description} />
      <meta
        name="twitter:url"
        content={`https://rhombus.com${post.fields.slug}`}
      />
      <meta
        name="twitter:image"
        content={`https://rhombus.com${handleMetaImage()}`}
      />
      <meta name="twitter:label1" content="Written by" />
      <meta
        name="twitter:data1"
        content={author.edges[0].node.frontmatter.name}
      />
      <meta name="twitter:site" content="@rhombussystems" />
      <meta name="twitter:creator" content="@rhombussystems" />
      {post.frontmatter.draft && (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Helmet>
  );
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        description={post.frontmatter.description}
        helmet={helmet}
        author={author.edges[0].node.frontmatter}
        category={post.frontmatter.category}
        title={post.frontmatter.title}
        thumbnail={thumbnail}
        thumbnailAlt={altText}
        date={post.frontmatter.date}
        relatedPosts={relatedPosts.edges.map((relatedPost) => relatedPost.node)}
        useCTA={post.frontmatter?.useCTA}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $categories: [String], $author: String) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        draft
        category
        date(formatString: "MMMM DD, YYYY")
        metaTitle
        metaDescription
        title
        description
        thumbnail
        metaImage
        featuredImages {
          featuredImageThumbnail
          featuredImageAltText
          featuredImageTitle
        }
        useCTA
      }
    }
    author: allMarkdownRemark(
      limit: 1
      filter: {
        frontmatter: {
          templateKey: { eq: "author-post" }
          name: { eq: $author }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            profilePhoto {
              thumbnail
              altText
            }
            name
            role
            bio
            socialLink
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      filter: {
        id: { ne: $id }
        frontmatter: {
          draft: { ne: true }
          type: { eq: "blog-post" }
          category: { in: $categories }
        }
      }
      sort: { frontmatter: { date: DESC } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail
            featuredImages {
              featuredImageThumbnail
              featuredImageAltText
              featuredImageTitle
            }
          }
        }
      }
    }
  }
`;
