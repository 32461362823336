import React from "react";

import {
  cardContainerClass,
  disclaimer,
  productSectionContainer,
} from "../styles/pricingStyles";
import AlarmCard from "./AlarmCard";
import { Body, TitleMed } from "components/rui/typography";

export default function AlarmSection() {
  return (
    <div className={productSectionContainer}>
      <span className={disclaimer}>
        <Body subtle>
          Price is paid up front and reflects cost per location. Licensing based
          on monthly verifications by U.S.-Based Five Diamond Certified
          Dispatchers.
        </Body>
      </span>
      <TitleMed>Alarm Monitoring</TitleMed>
      <div className={cardContainerClass}>
        <AlarmCard />
      </div>
    </div>
  );
}
