import { animated, useSpring } from "@react-spring/web";
import RedirectIcon from "components/common/icon-components/RedirectIcon";
import Link from "components/page/headers/HeaderLink";
import { useSetAtom } from "jotai";
import { css, cx } from "@linaria/core";
import React, { useEffect } from "react";

import { megaMenuOpenAtom } from "../atoms/headerAtoms";
import {
  menuColumn,
  MenuLink,
  MenuLinks,
  MenuTitle,
  Tag,
} from "../styles/HeaderStyles";

const menuContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  background-color: var(--nuetral-100);
  padding: 1.5rem;
  gap: 2.5rem;
`;

const featureMenuColumn = cx(menuColumn);

const DropDownContent = ({
  menuData,
  showFeaturedSection,
  FeaturedSection,
}) => {
  const setMegaMenuOpen = useSetAtom(megaMenuOpenAtom);
  const [popInLeft, set] = useSpring(
    () => ({
      from: { opacity: 0, marginLeft: "-1rem" },
      to: { opacity: 1, marginLeft: "0" },
      delay: 500,
    }),
    []
  );

  useEffect(() => {
    set({
      from: { opacity: 0, marginLeft: "-1rem" },
      to: { opacity: 1, marginLeft: "0" },
    });
  }, [menuData]);

  const renderFeaturedSection = () => {
    if (showFeaturedSection) {
      return (
        <animated.nav className={featureMenuColumn} style={popInLeft}>
          <MenuTitle>Featured</MenuTitle>
          <MenuLinks>
            <FeaturedSection />
          </MenuLinks>
        </animated.nav>
      );
    }
    return <></>;
  };

  return (
    <div className={menuContainer}>
      {menuData.map(menu => (
        <animated.nav className={menuColumn} key={menu.title} style={popInLeft}>
          <MenuTitle>{menu.title}</MenuTitle>
          <MenuLinks>
            {menu.links.map(link => (
              <MenuLink>
                <Link
                  key={link.name}
                  to={link.path}
                  activeClassName="active"
                  onClick={() => setMegaMenuOpen(false)}
                >
                  {link.name}
                  {link.external && <RedirectIcon color="var(--nuetral-400)" />}
                  {link.tag && <Tag>{link.tag}</Tag>}
                </Link>
              </MenuLink>
            ))}
          </MenuLinks>
        </animated.nav>
      ))}
      {renderFeaturedSection()}
    </div>
  );
};

export default DropDownContent;
