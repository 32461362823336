import WhyRhombusPage from "components/why-rhombus/WhyRhombusPage";
import React from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../components/page/GlobalLayout";

export default function WhyRhombus() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Why Rhombus - Cloud Physical Security System for Businesses | Rhombus
        </title>
        <meta
          name="description"
          content="Learn why schools, storage facilities, cities, hospitals, and other businesses prefer Rhombus over traditional NVR systems and other cloud vendors. Our cloud-managed platform makes physical security simpler and smarter to increase safety and efficiency."
        />
      </Helmet>
      <WhyRhombusPage />
    </GlobalLayout>
  );
}
