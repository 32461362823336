import {
  DefaultFlexContainer,
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  Title,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const AboutSectionContainer = styled(DefaultFlexContainer)`
  width: 100%;
  gap: 5rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  width: 50%;
  height: 100%;
  flex-grow: 1;
  p {
    max-width: 663px;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const HighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

const HighlightRow = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

const iconStyles = css`
  display: flex;
  width: 40.33px;
  flex-shrink: 0;
`;

const AboutSection = ({ partnerName, paragraphs, highlights }) => {
  return (
    <SectionContainer>
      <SectionInner
        style={{ flexDirection: "column", alignItems: "center", gap: "5rem" }}
      >
        <Title color="var(--blue-500)">About {partnerName}</Title>
        <AboutSectionContainer>
          <Section>
            {paragraphs.map(p => (
              <MainParagraph>{p}</MainParagraph>
            ))}
          </Section>
          <Section>
            <HighlightContainer>
              {highlights.map(item => (
                <HighlightRow>
                  <GatsbyImage
                    image={getImage(item.icon)}
                    className={iconStyles}
                    objectFit="contain"
                    alt=""
                  />
                  <div>
                    {item.title.map(t => (
                      <TitleSmall>{t}</TitleSmall>
                    ))}
                  </div>
                </HighlightRow>
              ))}
            </HighlightContainer>
          </Section>
        </AboutSectionContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default AboutSection;
