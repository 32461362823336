import { styled } from "@linaria/react";
import IconMapPin from "components/common/icon-components/IconMapPin";
import IconTime from "components/common/icon-components/IconTime";
import IconTextRow from "components/common/IconTextRow";
import DefaultInfoSlider from "components/common/sliders/DefaultInfoSlider";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import GlobalLayout from "components/page/GlobalLayout";
import {
  FlexColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import { Helmet } from "react-helmet";

const HeroLeft = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
`;

const HeroRight = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
  @media (max-width: 820px) {
    display: none;
  }
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 7.5rem;
`;

const IscWest2025 = () => {
  const { isMobile } = useIsMobile(820);
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Exclusive Partner Happy Hour at ISC West!</title>
        <meta
          name="description"
          content="After a long day on the show floor, treat yourself to complimentary drinks and delicious appetizers while connecting with the Rhombus team and other industry professionals. This is a fantastic opportunity to network and learn more about what's new at Rhombus."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SectionContainer
        style={{ background: "var(--gradient-light)", overflow: "unset" }}
      >
        <SectionInner>
          <HeroLeft>
            <TextContainer style={{ gap: "2rem" }} width="675px">
              <StaticImage
                alt="ISC West 2025 Logo"
                src="../../../static/img/isc-west-logo.png"
                height={80}
                width={141}
                objectFit="contain"
                objectPosition={"left"}
                quality={100}
                loading="eager"
                placeholder="blurred"
              />
              <FancyPageTitle>
                Exclusive Partner Happy Hour at ISC West!
              </FancyPageTitle>
              <FlexColumn gap="1rem">
                <IconTextRow
                  iconElement={<IconTime color="var(--blue-500)" />}
                  text={"Thursday, April 3 from  5:00 - 7:00 PM"}
                />
                <IconTextRow
                  iconElement={<IconMapPin size="25" />}
                  text={"Sugarcane at The Venetian"}
                />
              </FlexColumn>
              <MainParagraph>
                After a long day on the show floor, treat yourself to
                complimentary drinks and delicious appetizers while connecting
                with the Rhombus team and other industry professionals. This is
                a fantastic opportunity to network and learn more about what's
                new at Rhombus.
              </MainParagraph>
              <TextContainer>
                <TitleSmall>Chance to Win Apple AirPods Max!</TitleSmall>
                <MainParagraph>
                  As an added bonus, we'll be raffling off a pair of Apple
                  AirPods Max! Don't miss your chance to win these premium
                  headphones.
                </MainParagraph>
              </TextContainer>
              <TitleSmall>
                This event is at capacity - please register below to be added to
                the waitlist.
              </TitleSmall>
            </TextContainer>
            <FormWrapper outline width={isMobile ? "675px" : undefined}>
              <RhombusForm formId={"0381d443-3468-40ab-b903-5e36945f9b0e"} />
            </FormWrapper>
          </HeroLeft>
          <HeroRight>
            <StickyContainer>
              <StaticImage
                alt="ISC West Rhombus Happy Hour"
                src="../../../static/img/happy-hour-hero.png"
                height={600}
                width={600}
                objectFit="contain"
                quality={100}
                loading="eager"
                placeholder="blurred"
              />
            </StickyContainer>
          </HeroRight>
        </SectionInner>
      </SectionContainer>
      <DefaultInfoSlider />
    </GlobalLayout>
  );
};

export default IscWest2025;
