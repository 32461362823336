import React from "react";

const IconAudio = ({ size = "26", color = "var(--blue-500)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 27 26"
      fill="none"
    >
      <g clip-path="url(#clip0_29047_18594)">
        <path
          d="M13.5 0C13.0793 0 12.7383 0.341047 12.7383 0.761719V25.2383C12.7383 25.659 13.0793 26 13.5 26C13.9207 26 14.2617 25.659 14.2617 25.2383V0.761719C14.2617 0.341047 13.9207 0 13.5 0Z"
          fill={color}
        />
        <path
          d="M16.5469 3.91406C16.1262 3.91406 15.7852 4.25511 15.7852 4.67578V21.3226C15.7852 21.7433 16.1262 22.0844 16.5469 22.0844C16.9675 22.0844 17.3086 21.7433 17.3086 21.3226V4.67578C17.3086 4.25511 16.9675 3.91406 16.5469 3.91406Z"
          fill={color}
        />
        <path
          d="M19.5938 7.38672C19.1731 7.38672 18.832 7.72777 18.832 8.14844V17.8543C18.832 18.275 19.1731 18.616 19.5938 18.616C20.0144 18.616 20.3555 18.275 20.3555 17.8543V8.14844C20.3555 7.72771 20.0144 7.38672 19.5938 7.38672Z"
          fill={color}
        />
        <path
          d="M22.6406 9.25391C22.22 9.25391 21.8789 9.59495 21.8789 10.0156V15.9849C21.8789 16.4055 22.22 16.7466 22.6406 16.7466C23.0613 16.7466 23.4023 16.4055 23.4023 15.9849V10.0156C23.4023 9.59495 23.0613 9.25391 22.6406 9.25391Z"
          fill={color}
        />
        <path
          d="M25.6875 10.7773C25.2668 10.7773 24.9258 11.1184 24.9258 11.5391V14.4614C24.9258 14.8821 25.2668 15.2231 25.6875 15.2231C26.1082 15.2231 26.4492 14.8821 26.4492 14.4614V11.5391C26.4492 11.1184 26.1082 10.7773 25.6875 10.7773Z"
          fill={color}
        />
        <path
          d="M10.4531 3.91406C10.0325 3.91406 9.69141 4.25511 9.69141 4.67578V21.3226C9.69141 21.7433 10.0325 22.0844 10.4531 22.0844C10.8738 22.0844 11.2148 21.7433 11.2148 21.3226V4.67578C11.2148 4.25511 10.8738 3.91406 10.4531 3.91406Z"
          fill={color}
        />
        <path
          d="M7.40625 7.38672C6.98558 7.38672 6.64453 7.72777 6.64453 8.14844V17.8543C6.64453 18.275 6.98558 18.616 7.40625 18.616C7.82692 18.616 8.16797 18.275 8.16797 17.8543V8.14844C8.16797 7.72771 7.82692 7.38672 7.40625 7.38672Z"
          fill={color}
        />
        <path
          d="M4.35938 9.25391C3.9387 9.25391 3.59766 9.59495 3.59766 10.0156V15.9849C3.59766 16.4055 3.9387 16.7466 4.35938 16.7466C4.78005 16.7466 5.12109 16.4055 5.12109 15.9849V10.0156C5.12109 9.59495 4.78005 9.25391 4.35938 9.25391Z"
          fill={color}
        />
        <path
          d="M1.3125 10.7773C0.891828 10.7773 0.550781 11.1184 0.550781 11.5391V14.4614C0.550781 14.8821 0.891828 15.2231 1.3125 15.2231C1.73317 15.2231 2.07422 14.8821 2.07422 14.4614V11.5391C2.07422 11.1184 1.73317 10.7773 1.3125 10.7773Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_29047_18594">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAudio;
