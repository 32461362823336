import TextLinkV2 from "components/common/TextLinkV2";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useSetAtom } from "jotai";
import React, { useMemo } from "react";

import { megaMenuOpenAtom } from "../atoms/headerAtoms";
import { FeaturedCard } from "../styles/HeaderStyles";
import { chooseEventToFeature } from "components/webinars/util/webinarsFunctions";

export default function FeaturedEventAndBlog() {
  const setMegaMenuOpen = useSetAtom(megaMenuOpenAtom);
  const data = useStaticQuery(graphql`
    query {
      webinar: allMarkdownRemark(
        limit: 1
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "webinar-post" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              type
              blurb
              startDate
              featuredImage
            }
          }
        }
      }
      introWebinar: allMarkdownRemark(
        limit: 1
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "intro-webinar-post" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              type
              blurb
              startDate
              featuredImage
            }
          }
        }
      }
      blog: allMarkdownRemark(
        limit: 1
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: { templateKey: { eq: "blog-post" }, draft: { ne: true } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              description
              thumbnail
              featuredImages {
                featuredImageNav
                featuredImageAltText
                featuredImageTitle
              }
            }
          }
        }
      }
      hybridEvent: allMarkdownRemark(
        limit: 1
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "hybrid-event" }
            draft: { ne: true }
            featured: { eq: true }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              type
              title
              navImage
              dateInfo {
                startDate
              }
              eventDetails {
                eventSummary
              }
            }
          }
        }
      }
    }
  `);
  const { webinar, blog, hybridEvent, introWebinar } = data;
  const { edges: _webinar } = webinar;
  const { edges: _hybridEvent } = hybridEvent;
  const { edges: _introWebinar } = introWebinar;
  const eventData = chooseEventToFeature(
    _webinar,
    _introWebinar,
    _hybridEvent
  )[0]?.node;

  const blogData = blog.edges[0].node;
  const type = eventData?.frontmatter?.type;
  const image =
    eventData?.frontmatter.featuredImage || eventData?.frontmatter?.navImage;

  const linkText = useMemo(() => {
    switch (type) {
      case "Live Webinar":
        return "Register Now";
      case "In-Person":
        return "Join Us";
      case "On-Demand Webinar":
        return "Watch Now";
      default:
        return "Watch Now";
    }
  }, [type]);

  return (
    <>
      {eventData && (
        <FeaturedCard
          as={Link}
          to={eventData.fields.slug}
          onClick={() => setMegaMenuOpen(false)}
        >
          <div className="card-image-wrapper">
            <img
              src={image}
              className="card-image"
              alt={eventData.frontmatter.title}
            />
          </div>
          <div className="text-container">
            <h3>Events & Webinars</h3>
            <p>{eventData.frontmatter.title}</p>
            <div className="link-container">
              <TextLinkV2 path={eventData.fields.slug} title={linkText} small />
            </div>
          </div>
        </FeaturedCard>
      )}
      {blogData && (
        <FeaturedCard
          as={Link}
          to={blogData.fields.slug}
          onClick={() => setMegaMenuOpen(false)}
        >
          <div className="card-image-wrapper">
            <img
              src={
                blogData.frontmatter.featuredImages.featuredImageNav ??
                blogData.frontmatter.thumbnail
              }
              className="card-image"
              alt={
                blogData.frontmatter.featuredImages.featuredImageAltText ?? ""
              }
              title={
                blogData.frontmatter.featuredImages.featuredImageTitle ?? ""
              }
              style={{ objectPosition: "center" }}
            />
          </div>

          <div className="text-container">
            <h3>Blog</h3>
            <p>{blogData.frontmatter.title}</p>
            <div className="link-container">
              <TextLinkV2
                path={blogData.fields.slug}
                title="Learn More"
                small
              />
            </div>
          </div>
        </FeaturedCard>
      )}
    </>
  );
}
