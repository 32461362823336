import React from "react";

const IconSecureShield = ({
  width = "43",
  height = "50",
  color = "#2A7DE1",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 43 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.20809 10.7692C1.62093 16.8256 0.72185 40.402 20.7219 47.4497C21.0705 47.5759 21.4558 47.5759 21.8228 47.4677C25.1255 46.4763 41.4741 40.0775 41.0154 10.9315C41.0154 10.0663 40.3182 9.36332 39.4374 9.30924C36.2631 9.11097 28.7402 8.08356 22.52 2.87439C21.9329 2.3697 21.0888 2.2976 20.4466 2.74822C17.8778 4.51465 11.0338 8.7685 3.82277 9.29122C2.97873 9.34529 2.29983 9.94011 2.20809 10.7692V10.7692Z"
        stroke={color}
        stroke-width="3.22001"
        stroke-miterlimit="10"
      />
      <path
        d="M26.8315 22.5029H16.1709C14.9245 22.5029 13.9141 23.4955 13.9141 24.72V32.1462C13.9141 33.3706 14.9245 34.3632 16.1709 34.3632H26.8315C28.0779 34.3632 29.0884 33.3706 29.0884 32.1462V24.72C29.0884 23.4955 28.0779 22.5029 26.8315 22.5029Z"
        stroke={color}
        stroke-width="2.00001"
        stroke-miterlimit="10"
      />
      <path
        d="M24.665 22.5032V17.3662C24.665 16.1946 23.6926 15.2393 22.4999 15.2393H20.4815C19.2889 15.2393 18.3164 16.1946 18.3164 17.3662V22.5032"
        stroke={color}
        stroke-width="2.00001"
        stroke-miterlimit="10"
      />
      <path
        d="M23.2519 27.1538C23.2519 26.1984 22.4629 25.4414 21.5087 25.4414C20.5546 25.4414 19.7656 26.2165 19.7656 27.1538C19.7656 27.6945 20.0225 28.1812 20.4445 28.5056V29.7313C20.4445 30.3081 20.9216 30.7767 21.5087 30.7767C22.0959 30.7767 22.573 30.3081 22.573 29.7313V28.5056C22.9766 28.1992 23.2519 27.7125 23.2519 27.1538Z"
        stroke={color}
        stroke-width="1.50001"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default IconSecureShield;
