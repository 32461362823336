import AccordionSection from "components/common/AccordionSection";
import RhombusBanner from "components/common/banners/RhombusBanner";
import Features from "components/common/Features";
import FeatureSlider from "components/common/FeatureSlider";
import TrialBanner2 from "components/common/footer-banners/TrialBanner2";
import HeroLayout3 from "components/common/hero/HeroLayout3";
import InfoSlider from "components/common/InfoSlider";
import React from "react";

import Highlights from "../../common/Highlights";

const AiPage = ({ data }) => {
  return (
    <>
      <HeroLayout3 data={data.header} />
      <InfoSlider
        color="var(--white)"
        data={data.infoSlider.slides}
        title="Speed Up Investigations with Smart Search"
      />
      <Highlights data={data.highlights} />
      <AccordionSection
        data={data.accordionSection}
        color="var(--white)"
        fancy={false}
      />
      <RhombusBanner
        title={data.bannerSection.title}
        blurb={data.bannerSection.blurb}
        Button={data.bannerSection.button}
        image={data.bannerSection.image}
        altText="Rhombus video surveillance cameras capturing video footage in a commercial building"
      />
      <FeatureSlider data={data.featureSlider} />
      <Features
        data={data.features}
        title="Streamline Security with AI Video Analytics and Actionable Insights"
        color="var(--nuetral-100)"
      />
      <TrialBanner2 />
    </>
  );
};

export default AiPage;
