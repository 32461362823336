import SlideInContainer from "components/common/SlideInContainer";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import React from "react";
import { Body, TitleMed, TitleSmall } from "components/rui/typography";

const sectionContainer = css`
  display: flex;
  justify-content: center;
  padding: 5rem 1.25rem 0 1.25rem;
`;

const sectionContainerCenter = cx(
  sectionContainer,
  css`
    padding: 5rem 1.25rem;
  `
);
const sectionInner = css`
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  gap: 5rem;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;

const sectionLeft = css`
  width: var(--width);
  overflow: hidden;
  position: relative;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const imageWrapper = css`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  @media (max-width: 820px) {
    justify-content: center;
  }
`;

const imageClass = css`
  width: 80% !important;
`;

const imageWrapperCenter = cx(
  imageWrapper,
  css`
    align-items: flex-start;
    justify-content: center;
    .${imageClass} {
      width: 100% !important;
      border-radius: 1rem !important;
    }
  `
);

const sectionRight = cx(
  sectionLeft,
  css`
    flex-grow: 1;
    width: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.5rem;
    .inline {
      h3,
      p {
        font-size: 16px;
      }
    }
  `
);

const highlightList = css`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-bottom: 5rem;
`;

const highlight = css`
  display: flex;
`;

const checkClass = css`
  width: 25px !important;
  height: 25px !important;
  margin: 5px 25px 0px 0px !important;
  aspect-ratio: 1 !important;
  overflow: visible !important;
`;

const Highlights = ({
  data,
  center = false,
  color = "var(--nuetral-100)",
  inlineTitle = false,
  sectionWidth = "50%",
}) => {
  return (
    <section
      className={center ? sectionContainerCenter : sectionContainer}
      style={{ background: color }}
    >
      <div className={sectionInner}>
        <div className={sectionLeft} style={{ "--width": sectionWidth }}>
          <div className={center ? imageWrapperCenter : imageWrapper}>
            <GatsbyImage
              image={getImage(data.image)}
              alt={data?.altText ?? data.title}
              className={imageClass}
            />
          </div>
        </div>
        <div className={sectionRight}>
          <TitleMed>{data.title}</TitleMed>
          <ul
            className={highlightList}
            style={center ? { paddingBottom: 0 } : undefined}
          >
            {data.list.map((item) => (
              <SlideInContainer>
                <li className={highlight}>
                  <StaticImage
                    src="./img/validation.png"
                    alt="check mark"
                    className={checkClass}
                    placeholder="blurred"
                    objectFit="contain"
                  />
                  {inlineTitle ? (
                    <div className="inline">
                      <TitleSmall style={{ display: "inline" }}>
                        {item.title}
                      </TitleSmall>
                      <Body style={{ display: "inline" }}>
                        {item.description}
                      </Body>
                    </div>
                  ) : (
                    <div>
                      <TitleSmall>{item.title}</TitleSmall>
                      <Body>{item.description}</Body>
                    </div>
                  )}
                </li>
              </SlideInContainer>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Highlights;
