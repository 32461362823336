import React from "react";

const IconCarrot = ({ color = "currentColor", height = "11", width = "6" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 6 11"
      fill="none"
    >
      <path
        d="M1.4939 1.75L4.89149 5.57229L1.4939 9.39458"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconCarrot;
