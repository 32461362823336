import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { FancyTitle, PreTitle } from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import React from "react";

const Container = styled(SectionContainer)`
  background: var(--gradient-light);
  padding-bottom: 0;
`;

const ImageCol = styled(SectionCol)`
  flex-direction: column;
  justify-content: space-between;
`;

const FormCol = styled(FlexEndColumn)`
  padding-bottom: 5rem;
`;

const imageStyles = css`
  width: 100% !important;
  @media (max-width: 820px) {
    max-width: 550px;
    margin-bottom: -5.5rem;
  }
`;

const FormBanner = ({ preTitle, title, formId, formTitle, submitText }) => {
  const { isMobile } = useIsMobile(820);

  return (
    <Container id="pricing-form">
      <SectionInner>
        <ImageCol>
          <TextContainer>
            <PreTitle>{preTitle}</PreTitle>
            <FancyTitle>{title}</FancyTitle>
          </TextContainer>
          <StaticImage
            src="./assets/footer-image.png"
            className={imageStyles}
            alt="Display of all Rhombus products, including cameras, iot sensors, and access control, showcasing comprehensive security solutions."
            quality={100}
            placeholder="blurred"
            objectFit="contain"
          />
        </ImageCol>
        <FormCol>
          <FormWrapper
            title={formTitle}
            width={isMobile ? "100%" : "550px"}
            outline
          >
            <RhombusForm formId={formId} customSubmitText={submitText} />
          </FormWrapper>
        </FormCol>
      </SectionInner>
    </Container>
  );
};

export default FormBanner;
