import "./GlobalLayout.css";
// Supports weights 100-800
import "@fontsource-variable/sora";
// Supports weights 100-900
import "@fontsource-variable/dm-sans";

import SecurityChecklistPopup from "components/common/pop-ups/SecurityChecklistPopup";
import useLocationSearch from "hooks/useLocationSearch";
import useScrollBlock from "hooks/useScrollBlock";
import { useAtom, useSetAtom } from "jotai";
import Cookies from "js-cookie";
import _debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";

import {
  blockPopupAtom,
  displayChiliPiperAtom,
  popupCookieExistsAtom,
  showPopupAtom,
} from "./atoms/globalAtoms";
import {
  currentLinkAtom,
  megaMenuOpenAtom,
} from "./newHeader/atoms/headerAtoms";
import Header from "./newHeader/Header";
import { Script } from "gatsby";
import PostHogScript from "./scripts/PostHogScript";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import FooterV2 from "./footer/FooterV2";
import FooterCTA from "./footer/FooterCTA";
import useCookieBanner from "hooks/useCookieBanner";

export default function GlobalLayout({
  children,
  color = "var(--white)",
  dark = false,
  landingPage = false,
  formOnly = false,
  customLinks = null,
  ctaLink = "#form",
  useIncreasedPadding = false,
  logoLink = "/",
  displayChiliPiperProp = false,
  includeButton = false,
  buttonType = "primary",
  useFullWidth = false,
  useFullWidthLogo = false,
  diableStickyHeader = false,
  hideFooter = false,
  useCTAFooter = false,
  buttonText = CTA_BUTTON_COPY,
  useExperimentalFooter = false,
}) {
  useCookieBanner();
  useLocationSearch();
  const setMegaMenuOpen = useSetAtom(megaMenuOpenAtom);
  const setCurrentLink = useSetAtom(currentLinkAtom);
  const [showPopup, setShowPopup] = useAtom(showPopupAtom);
  const [popupCookieExists, setPopupCookie] = useAtom(popupCookieExistsAtom);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [blockPopup, setBlockPopup] = useAtom(blockPopupAtom);
  const [displayChiliPiper, setDisplayChiliPiper] = useAtom(
    displayChiliPiperAtom
  );
  const [blockChiliUTM, setBlockChiliUTM] = useState(false);

  useEffect(() => {
    setDisplayChiliPiper(displayChiliPiperProp);
  }, [displayChiliPiperProp]);

  useEffect(() => {
    const utmSource = sessionStorage.getItem("utm_source");
    if (utmSource === "sdr" || utmSource === "ae") {
      setBlockChiliUTM(true);
    } else return;
  }, []);

  useEffect(() => {
    if (!window || window === "undefined") return;
    const currentUrl = window.location.href;
    const blockedUrlPaths =
      /(lp|webinar|blog|events|contact|pricing|partner|integrations|compare-cameras|cookie-notice|grants|thank-you|rctp|careers|license-comparison)/;
    const homePage = window.location.pathname === "/";
    if (blockedUrlPaths.test(currentUrl) || homePage) {
      setBlockPopup(true);
    } else {
      setBlockPopup(false);
    }
  }, []);

  useEffect(() => {
    if (
      Cookies.get("physical-security-checklist-popup-session") ||
      Cookies.get("physical-security-checklist-popup-never")
    ) {
      setPopupCookie(true);
    }
  }, []);

  useEffect(() => {
    if (blockPopup) return;
    if (popupCookieExists) return;
    if (scrollPercentage >= 50) {
      setShowPopup(true);
    }
  }, [popupCookieExists, blockPopup, scrollPercentage]);

  useEffect(() => {
    if (showPopup) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [showPopup]);

  const calculateScroll = () => {
    if (!window || !window) return;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight - windowHeight;
    const scrolled = window.scrollY;
    const scrolledPercentage = (scrolled / scrollHeight) * 100;
    setScrollPercentage(scrolledPercentage);
  };

  const handleScroll = useCallback(() => {
    return _debounce(calculateScroll, 200);
  }, [calculateScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderChiliPiperScript1 = useCallback(() => {
    if (displayChiliPiper && !blockChiliUTM) {
      return (
        <Script strategy="off-main-thread">
          {`function q(a){return function(){ChiliPiper[a].q=(ChiliPiper[a].q||[]).concat([arguments]);};}window.ChiliPiper=window.ChiliPiper||"submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function(a,b){a[b]=q(b);return a;},{});
  ChiliPiper.scheduling("rhombus","inbound-router",{title:"Thanks! What time works best for a quick call?"});`}
        </Script>
      );
    } else return;
  }, [displayChiliPiper, blockChiliUTM]);

  const renderChiliPiperScript2 = useCallback(() => {
    if (displayChiliPiper && !blockChiliUTM) {
      return (
        <Script
          id="🌶️"
          src="https://js.chilipiper.com/marketing.js"
          type="text/javascript"
          async
        ></Script>
      );
    } else return;
  }, [displayChiliPiper, blockChiliUTM]);

  const title = "Rhombus - Next-Generation Enterprise Security Camera System";
  const meta = [
    {
      name: "description",
      content:
        "Secure, cloud-managed video surveillance for enterprise, business, or commercial property. No DVR/NVRs required. Easy to manage. Infinitely scalable.",
    },
    {
      name: "keywords",
      content:
        "video security, cloud video security, rhombus, rhombus systems, cloud managed security cameras, video surveillance, cloud cctv, enterprise video security, enterprise security cameras, business security cameras",
    },
  ];

  return (
    <div className={"globalLayoutClass"}>
      <Helmet title={title} meta={meta}>
        <link rel="preconnect" href="https://px.ads.linkedin.com" />
        <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
        <link rel="preconnect" href="https://www.googleadservices.com" />
        <html lang="en" />
        <link rel="icon" href="https://assets.rhombussystems.com/favicon.ico" />
        <meta name="theme-color" content="var(--nuetral-100)" />
        <meta charSet="UTF-8" />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://rhombus.com/img/logo-w-background.png"
        />
        <meta
          name="viewport"
          content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, target-densitydpi=device-dpi"
        />
        {/* Before </head> Scripts Go Here! */}
        <script>
          {`
            (function (w, o, d) {
                w[o] =
                    w[o] ||
                    function () {
                        w[o][d].push(arguments);
                    };
                w[o][d] = w[o][d] || [];
            })(window, 'Osano', 'data');
            window.Osano('onInitialized', consent => {
            countryCode = window.Osano.cm.countryCode;
            if (countryCode === 'us'){
              window.Osano.cm.hideDialog()
            }
            });
          `}
        </script>
        <script src="https://cmp.osano.com/6oqOkUUtG7mC6ScB/d1fbf09f-ecd3-4390-8c69-e28269c3377f/osano.js"></script>
      </Helmet>
      <Header
        color={color}
        dark={dark}
        landingPage={landingPage}
        customLinks={customLinks}
        ctaLink={ctaLink}
        useIncreasedPadding={useIncreasedPadding}
        logoLink={logoLink}
        includeButton={includeButton}
        buttonType={buttonType}
        useFullWidth={useFullWidth}
        useFullWidthLogo={useFullWidthLogo}
        diableStickyHeader={diableStickyHeader}
        buttonText={buttonText}
      />
      <main
        onClick={() => {
          setMegaMenuOpen(false);
          setCurrentLink(null);
        }}
      >
        {showPopup && !blockPopup && <SecurityChecklistPopup />}
        {children}
      </main>
      {!formOnly && !hideFooter && (
        <FooterV2 useExperimentalFooter={useExperimentalFooter} />
      )}
      {useCTAFooter && hideFooter && <FooterCTA />}
      {process.env.NODE_ENV === "production" && (
        <>
          {/* Before </body> Scripts Go Here! */}
          {renderChiliPiperScript1()}
          {renderChiliPiperScript2()}
          <PostHogScript />
        </>
      )}
    </div>
  );
}
