import React from "react";

const IconLP = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_31522_19429)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.2769 12.3187H3.44754C2.59387 12.3187 1.896 11.6322 1.896 10.7933V6.10359C1.896 5.26465 2.59387 4.57812 3.44754 4.57812H14.2769C15.1305 4.57812 15.8291 5.26465 15.8291 6.10359V10.7933C15.8291 11.6322 15.1305 12.3187 14.2769 12.3187Z"
          stroke="white"
          stroke-width="0.685714"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.51952 9.04492L4.13666 8.23121L3.53323 7.43349H4.02009L4.37437 7.92721L4.72866 7.43349H5.2178L4.61437 8.23121L5.22923 9.04492H4.74009L4.37437 8.53749L4.00866 9.04492H3.51952ZM5.04693 9.04492L5.66407 8.23121L5.06064 7.43349H5.5475L5.90179 7.92721L6.25607 7.43349H6.74521L6.14179 8.23121L6.75664 9.04492H6.2675L5.90179 8.53749L5.53607 9.04492H5.04693ZM6.57434 9.04492L7.19148 8.23121L6.58805 7.43349H7.07491L7.4292 7.92721L7.78348 7.43349H8.27262L7.6692 8.23121L8.28405 9.04492H7.79491L7.4292 8.53749L7.06348 9.04492H6.57434ZM8.25155 8.59006V8.27235H8.98298V8.59006H8.25155ZM8.94836 9.04492L9.5655 8.23121L8.96207 7.43349H9.44893L9.80321 7.92721L10.1575 7.43349H10.6466L10.0432 8.23121L10.6581 9.04492H10.1689L9.80321 8.53749L9.4375 9.04492H8.94836ZM10.4758 9.04492L11.0929 8.23121L10.4895 7.43349H10.9763L11.3306 7.92721L11.6849 7.43349H12.1741L11.5706 8.23121L12.1855 9.04492H11.6963L11.3306 8.53749L10.9649 9.04492H10.4758ZM12.0032 9.04492L12.6203 8.23121L12.0169 7.43349H12.5037L12.858 7.92721L13.2123 7.43349H13.7015L13.098 8.23121L13.7129 9.04492H13.2237L12.858 8.53749L12.4923 9.04492H12.0032Z"
          fill="white"
        />
        <ellipse
          cx="13.7814"
          cy="6.51301"
          rx="0.409798"
          ry="0.387031"
          fill="white"
        />
        <ellipse
          cx="3.94349"
          cy="6.51301"
          rx="0.409798"
          ry="0.387031"
          fill="white"
        />
        <ellipse
          cx="3.94349"
          cy="10.8587"
          rx="0.409798"
          ry="0.387031"
          fill="white"
        />
        <ellipse
          cx="13.7814"
          cy="10.8587"
          rx="0.409798"
          ry="0.387031"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31522_19429">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.98291 0.554688)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconLP;
