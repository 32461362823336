import AccordionSection from "components/common/AccordionSection";
import DeviceCards from "components/common/DeviceCards";
import AccessControlBanner from "components/common/footer-banners/AccessControlBanner";
import InfoSlider from "components/common/InfoSlider";
import PlatormModule from "components/common/modules/PlatormModule";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import AccessControlVideo from "./AccessControlVideo";
import FAQSection from "components/common/FAQSection";
import AcVideo from "./AcVideo";

export default function AccessControlPage() {
  const data = useStaticQuery(graphql`
    query {
      dc20: file(
        relativePath: { eq: "components/access-control/img/dc20-500-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      dr40: file(
        relativePath: { eq: "components/access-control/img/dr40-500-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      dr20: file(
        relativePath: { eq: "components/access-control/img/dr20-500-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      secureCards: file(
        relativePath: {
          eq: "components/access-control/specs/img/secure-cards-hero-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      secureFobs: file(
        relativePath: {
          eq: "components/access-control/img/rhombus-secure-fob-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      slide1: file(
        relativePath: {
          eq: "components/access-control/img/slide-1-1000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide2: file(
        relativePath: {
          eq: "components/access-control/img/slide-2-1000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide3: file(
        relativePath: {
          eq: "components/access-control/img/operational-power-outages-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      slide4: file(
        relativePath: {
          eq: "components/access-control/img/slide-4-1000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp1: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/smartphone-app-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp2: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/easy-access-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp3: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/swipe-and-fav-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      keyApp4: file(
        relativePath: {
          eq: "components/access-control/img/keyApp/centralized-management-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card1: file(
        relativePath: { eq: "components/common/modules/img/cameras-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card2: file(
        relativePath: {
          eq: "components/common/modules/img/ai-analytics-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card3: file(
        relativePath: {
          eq: "components/common/modules/img/investigations-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      card4: file(
        relativePath: {
          eq: "components/common/modules/img/alarm-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      faq: file(
        relativePath: { eq: "components/access-control/img/ac-faq-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  const slides = {
    title: "Modernize and Simplify Building Management",
    cards: [
      {
        name: "DC20 Door Controller",
        description:
          "Works with existing door hardware and operates during power outages.",
        link: "/access-control/dc20/",
        image: data.dc20,
        altText:
          "Rhombus DC20 door access controller system, a compact and powerful component of modern access control systems from Rhombus. This sleek device is essential for managing door access control in comprehensive access control system deployments.",
      },
      {
        name: "DR40 Video Intercom",
        description:
          "All-in-one door reader with two-way audio, real-time visibility, and AI analytics.",
        link: "/access-control/door-readers/dr40/",
        image: data.dr40,
        altText:
          "Rhombus DR40 door reader for access control system installed on a wall, showcasing advanced access control technology. This device integrates seamlessly with door access control systems and is managed through sophisticated access control management software, ensuring secure and efficient entry.",
      },
      {
        name: "DR20 Door Reader",
        description:
          "Secure and seamless access with a wave, mobile device tap, or badge.",
        link: "/access-control/door-readers/dr20/",
        image: data.dr20,
        altText:
          "Rhombus DR20 door reader for access control system installed next to a door, showcasing a modern door access control system. This sleek device is a key component of advanced access control systems, offering mobile access and seamless integration with access control management software for enhanced building security and controlling access to entry points.",
      },
      {
        name: "Rhombus Secure Cards",
        description:
          "High Frequency Card with advanced authentication and encryption features.",
        link: "/access-control/secure-cards/",
        image: data.secureCards,
        altText:
          "Employee using a Rhombus Secure Card for access control system to tap on a DR20 door reader, demonstrating efficient door access control solutions. This modern door access system ensures secure and convenient entry for authorized personnel. Rhombus access control software seamlessly integrates with door readers, enhancing security and management capabilities.",
      },
      {
        name: "Rhombus Secure Fobs",
        description:
          "High Frequency Fobs with advanced authentication and encryption features.",
        link: "/access-control/secure-fobs/",
        image: data.secureFobs,
        altText:
          "Employee using a Rhombus Secure Card for access control system to tap on a DR20 door reader, demonstrating efficient door access control solutions. This modern door access system ensures secure and convenient entry for authorized personnel. Rhombus access control software seamlessly integrates with door readers, enhancing security and management capabilities.",
      },
    ],
  };

  const accordianSection = {
    title: "Take Building and Visitor Management to the Next Level",
    items: [
      {
        title: "Native Video and Two-Way Audio",
        p: "Get maximum visibility by integrating access events with live video footage. Get instant arrival notifications, visually verify visitors, and communicate with guests—all from one interface.",
        img: data.slide1,
        alt: "Rhombus DR40 door reader installed beside a door, showcasing a part of rhombus commercial access control systems.",
      },
      {
        title: "Touchless or Keycard Entry",
        p: "Gain instant access to buildings with a wave of your hand, tap of your finger, or the touch of a keycard. Rhombus’ Door Reader’s fast and reliable performance ensures smooth access for guests and employees.",
        img: data.slide2,
        alt: "Rhombus door reader, DC20 which is one of the access control readers offered by Rhombus for access control systems, is installed next to a door in an office building, exemplifying how access control systems enhance security.",
      },
      {
        title: "Operational During Power Outages",
        p: "Never worry when the power goes out. Rhombus Access Control provides uninterrupted service during outages, ensuring building security is maintained in all conditions.",
        img: data.slide3,
        alt: "Rhombus access control door controller mounted on a wall, showcasing a key component of cloud-based access control systems. This device monitors entry and exit points, helps prevent unauthorized access attempts, and integrates with visitor management systems for comprehensive security, unlike the traditional access control systems.",
      },
      {
        title: "Simplified Management",
        p: "Manage unlimited doors and users, control access to entry points securely, set access schedules, create customized lockdown plans, and automate workflows between access control, cameras, sensors, and alarms—all from one user-friendly dashboard.",
        img: data.slide4,
        alt: "Rhombus access control system integrated with other Rhombus security solutions, showcasing the all-in-one management capability. This powerful access control system streamlines security operations, combining video surveillance, access events, and user permissions in one intuitive platform.",
      },
    ],
  };

  const keyAppSection = {
    title: "Easy and Secure Access with the Rhombus Key App",
    list: [
      {
        tabTitle: "Smartphone App",
        title: "Mobile Credentials for Simplified Access",
        description:
          "Securely unlock doors straight from your smartphone. Building access is fast and simple—no more keeping track of physical keys, keycards, or badges. ",
        image: data.keyApp1,
        altText:
          "Rhombus Key app displayed on a mobile device for access control system, illustrating its role in modern access control systems. The app highlights key features such as mobile credentials for easy entry and real-time monitoring, showcasing the key benefits of streamlined access management. This system ensures that only authorized personnel can unlock doors, enhancing security and efficiency in building access.",
      },
      {
        tabTitle: "Easy Access",
        title: "Remote Unlock and Wave-to-Unlock ",
        description:
          "Badging in is a breeze. Unlock doors effortlessly with a wave, a phone tap, or a remote click.",
        image: data.keyApp2,
        altText:
          "Employee using Rhombus Key app on smartphone to enter office, demonstrating mobile access technology of the door access control system to gain access. The Rhombus DR40 door reader is visible, showcasing advanced access control technology that integrates mobile credentials, enhancing building security and streamlining entry for authorized personnel.",
      },
      {
        tabTitle: "Swipe & Favorite",
        title: "Save Frequent Doors",
        description:
          "Put your most-used doors front and center within the Rhombus Key app. Your favorites are saved for quick and easy access.",
        image: data.keyApp3,
        altText:
          "Rhombus Key app for access control system showcasing favorited doors, highlighting its integration with door access control systems. This feature allows users to quickly access their preferred entry points (with internet access), enhancing the overall efficiency of the access control system and ensuring streamlined building security.",
      },
      {
        tabTitle: "Centralized Management",
        title: "Multi-Location Access",
        description:
          "Need to access multiple buildings or locations? No problem—securely unlock all your doors from one simple login.",
        image: data.keyApp4,
        altText:
          "Rhombus Key app for access control system displaying access to multiple locations simultaneously, illustrating the advanced capabilities of modern security systems. This feature highlights how access control systems utilize cutting-edge access control technology to provide seamless management across various sites, ensuring efficient and secure entry for authorized personnel.",
      },
    ],
  };

  const cards = [
    {
      image: data.card1,
      title: "Security Cameras",
      blurb:
        "Take the guesswork out of who’s coming and going. Capture synchronized video of access events.",
      link: { path: "/cameras/", text: "Cloud Cameras" },
    },
    {
      image: data.card2,
      title: "Computer Vision & AI Alerts  ",
      blurb:
        "Automatically detect and receive real-time access alerts via human movement detection and facial recognition.",
      link: { path: "/ai-analytics/", text: "AI Analytics " },
    },
    {
      image: data.card3,
      title: "Investigations ",
      blurb:
        "Investigate using smart search and an intuitive video timeline where access events are automatically marked.",
      link: { path: "/console/", text: "Console" },
    },
    {
      image: data.card4,
      title: "After-Hours Security ",
      blurb:
        "Cross-reference entry data in real time to reduce false alarms and enable faster and more accurate emergency response.",
      link: { path: "/alarm-monitoring/", text: "Alarm Monitoring" },
    },
  ];

  const faq = {
    title: "Access Control FAQs",
    image: data.faq,
    alt: "Person waving at Rhombus DR40 door reader for access to office doors. Modern, touchless entry system in action.",
    QA: [
      {
        question:
          "How does the Rhombus access control system enhance security?",
        answer:
          "The Rhombus system integrates door controllers, readers, and cloud-based software to provide comprehensive access control. It offers mobile credentials and seamless integration with security cameras for enhanced physical security and protection against unauthorized entry.",
      },
      {
        question: "Can Rhombus access control solutions be managed remotely?",
        answer:
          "Yes, Rhombus offers cloud-based access control management software that allows remote management of multiple locations. Administrators can control access, adjust permissions, and monitor entry points from anywhere with an internet connection.",
      },
      {
        question:
          "What are some key features of the Rhombus access control technology?",
        answer:
          "Key features include mobile access, video intercom enabled readers, visitor management systems, audit trails, and integration with video surveillance. The system also monitors access events and potential security breaches, providing you with the best access control solution.",
      },
      {
        question:
          "How does Rhombus ensure only authorized users can access secured areas?",
        answer:
          "Rhombus uses advanced technology like mobile credentials and secure key cards to verify identities. The system allows for customized access policies, granting access based on schedules and permissions, while tracking and logging all entry attempts for security purposes.",
      },
      {
        question:
          "Can Rhombus Access Control adapt to different levels of authorized personnel?",
        answer:
          "Yes, Rhombus Access Control offers flexible permission settings to accommodate various levels of authorized personnel. Administrators can easily customize and automate access based on roles, schedules, and specific needs. This modern approach allows for granular control over who can access different areas of your facility.",
      },
    ],
  };

  return (
    <>
      <AccessControlVideo />
      <DeviceCards data={slides} deviceType="Access Control" center />
      <AcVideo />
      <AccordionSection
        data={accordianSection}
        fancy={false}
        color="var(nuetral-100)"
      />
      <InfoSlider data={keyAppSection.list} title={keyAppSection.title} />
      <PlatormModule
        cards={cards}
        title="Integrate Physical Security with Modern, Commercial Access Control System"
        blurb="Unlock a world of scalable, interconnected security that adapts to your needs – a security system that's smarter together!"
      />
      <FAQSection
        image={faq.image}
        imageAlt={faq.alt}
        reverse
        title={faq.title}
        QA={faq.QA}
      />
      <AccessControlBanner color="var(--secondary-bg)" />
    </>
  );
}
