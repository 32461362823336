export const CAMERA_KEYWORD_HEADLINES = {
  "360 camera for security":
    "Top-Rated 360˚ Cameras: Enhance your security with all-around coverage.",
  "dome camera":
    "Top-Rated Dome Cameras: Discreet and versatile security solution.",
  "camera bullet":
    "Top-Rated Bullet Cameras: Precise, targeted, and long-range surveillance for your property.",
  "business camera system":
    "#1 Business Camera System: Protect your company with advanced monitoring.",
  "camera domes":
    "Camera Domes: Top-rated, sleek, and effective security for your premises.",
  "camera fish eye":
    "Fisheye Cameras: #1 in comprehensive coverage with a single lens.",
  "camera for business security":
    "#1 Cameras for Business Security: Safeguard your property and assets.",
  "camera for commercial":
    "Top-Rated Cameras for Commercial Properties: Ensure the safety of your assets.",
  "business security camera":
    "#1 Business Security Cameras: Essential tools for a secure workplace.",
  "cameras for schools":
    "Top-Rated Cameras for Schools: Enhance campus safety for students and staff.",
  "camera access control":
    "Unify Cameras and Access Control: Regulate entry, monitor your facility, and increase safety.",
  "cloud based camera":
    "Top-Rated Cloud-Based Cameras: Accessible and flexible security solution.",
  "cloud cameras":
    "#1 in Cloud Cameras: Secure your premises with 24/7 remote surveillance.",
  "cloud security camera":
    "Top-Rated Cloud Security Cameras: Protect your property from anywhere.",
  "cloud security camera for business":
    "#1 in Cloud Security Cameras for Businesses: Protect your business from anywhere, any time.",
  "commercial outdoor camera system":
    "Top-Rated Commercial Outdoor Camera System: Rugged and reliable security for any environment.",
  "commercial security camera systems":
    "#1 Commercial Security Camera System: Comprehensive protection for your business.",
  "commercial wireless camera":
    "The Best Commercial Wifi Camera: A flexible camera for easy-to-install video security.",
  "enterprise camera systems":
    "Top-Rated Enterprise Camera System: Advanced surveillance for large-scale operations.",
  "enterprise ip camera":
    "#1 Enterprise IP Cameras: High-performance security for your organization.",
  "enterprise nvr":
    "#1 Enterprise NVR Alternative: Powerful cloud-based recording and footage review solutions.",
  "enterprise security camera":
    "Top-Rated Enterprise Security Cameras: Robust protection for your corporate assets.",
  "enterprise surveillance camera":
    "Top-Rated Enterprise Surveillance Cameras: Comprehensive monitoring across your facilities.",
  "enterprise surveillance cameras":
    "Top-Rated Enterprise Surveillance Cameras: Comprehensive monitoring across your facilities.",
  "fisheye camera":
    "Top-Rated Fisheye Cameras: Wide-angle coverage for enhanced situational awareness.",
  "fisheye cctv camera":
    "Top-Rated Fisheye CCTV Cameras: Panoramic views for hyper-effective surveillance.",
  "ip camera system":
    "#1 in IP Camera System: Flexible and high-resolution security solutions.",
  "License Plate Reader Camera":
    "Top-Rated License Plate Reader Cameras: Automate vehicle identification.",
  "security camera for business":
    "Top-Rated Security Cameras for Businesses: Protect your company's assets.",
  "security camera":
    "Top-Rated Security Cameras: Comprehensive cloud video monitoring that scales.",
  "wireless cctv camera system":
    "Top-Rated Wireless CCTV Camera System: Easy installation and video monitoring.",
};

export const SECURITY_KEYWORD_HEADLINES = {
  "business security camera":
    "#1 Business Security Cameras: Unified physical security from a single pane of glass.",
  "business security camera systems":
    "#1 Business Security Camera System: Unified physical security from a single pane of glass.",
  "business security companies":
    "Top-Rated Business Security Company: Unified physical security from a single pane of glass.",
  "business security system":
    "Top-Rated Business Security System: Unified physical security from a single pane of glass.",
  "business security systems":
    "Top-Rated Business Security System: Unified physical security from a single pane of glass.",
  "business video surveillance":
    "Best-In-Class Business Video Surveillance: Unified physical security from a single pane of glass.",
  "commercial business security systems":
    "The Best-In-Class Commercial Security System: Unified physical security from a single pane of glass.",
  "commercial security service":
    "Top-Rated Commercial Security Service: Unified physical security with an open and flexible system.",
  "commercial security solutions":
    "#1 Commercial Security Solution: Unified physical security with an open and customizable system.",
  "commercial security systems":
    "#1 Commercial Security System: Unified physical security from a single pane of glass.",
  "company security services":
    "Top-Rated Security Service for Companies: Unified physical security out of the box.",
  "enterprise grade security":
    "Top-Rated Enterprise-Grade Security: Unified physical security out of the box.",
  "enterprise video security":
    "Top-Rated Enterprise Video Security: Unified physical security from a single pane of glass.",
  "security door system":
    "Top-Rated Security Door System: Unified physical security out of the box.",
  "security door systems":
    "Top-Rated Security Door System: Unified physical security out of the box.",
  "security monitoring company":
    "#1 Security Monitoring Company: Unified physical security out of the box.",
  "security systems for business":
    "Top-Rated Security System For Businesses: Unified physical security out of the box.",
  "wireless business security systems":
    "Top-Rated Wifi Security System for Businesses: Unified physical security from a single pane of glass.",
};
export const CTA_BUTTON_COPY = "Request Demo";
export const CTA_BUTTON_COPY_2 = "Get Quote";
export const CTA_BUTTON_COPY_3 = "See Rhombus in action";
export const CTA_BUTTON_COPY_4 = "Try Rhombus for free";
export const CTA_BUTTON_COPY_5 = "Start a Free Trial";
export const CTA_BUTTON_COPY_6 = "See Demo";
export const CTA_BUTTON_COPY_7 = "Get a Live Demo";
export const PRICING_COPY = "View Pricing";
export const QUOTE_COPY = "Custom Quote";
export const CTA_LINK = "/lp/cta-a";
export const CTA_LINK_1 = "/lp/cta-1";
export const CTA_LINK_2 = "/lp/cta-2";
export const CTA_LINK_3 = "/lp/cta-3";
export const CTA_LINK_4 = "/lp/cta-4";
export const CTA_LINK_5 = "/lp/cta-free-trial";
export const FREE_TRIAL_LINK = "/free-trial/";
export const LIVE_DEMO_LINK = "/live-demo/";
export const CUSTOM_QUOTE_LINK = "/custom-quote/";

export const POSSIBLE_CTAS = [
  CTA_BUTTON_COPY,
  CTA_BUTTON_COPY_2,
  CTA_BUTTON_COPY_3,
  "Custom Quote",
  "Request Custom Quote",
  "Get pricing",
  "Start Trial",
];

export const FORM_IDS = {
  LIVE_DEMO: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
  FREE_TRIAL: "6f4405c2-70e5-45a8-b139-cad010d9e4e3",
  FREE_TRIAL_ADS: "30d2b33c-32af-4a24-8fc7-5a6fe7ee9b15",
  INSTANT_DEMO: "2a7d4150-3655-4919-a75f-5166708725c9",
};

export const CTA_4_KEYWORD_COPY = {
  "360 security cameras":
    "Take the next step— Start a risk-free trial with the Rhombus 360° Security Camera. Install and start viewing footage from any device in seconds",
  "360 camera for security":
    "Take the next step— Start a risk-free trial with the Rhombus 360° Security Camera. Install and start viewing footage from any device in seconds",
  "360 security camera":
    "Take the next step— Start a risk-free trial with the Rhombus 360° Security Camera. Install and start viewing footage from any device in seconds",
  "360 fisheye camera":
    "Take the next step— Start a risk-free trial with the Rhombus 360° Security Camera. Install and start viewing footage from any device in seconds",
  "4k dome security camera":
    "Take the next step— Start a risk-free trial with a Rhombus 4K Dome Security Camera. Install and start viewing footage from any device in seconds",
  "4k dome camera":
    "Take the next step— Start a risk-free trial with a Rhombus 4K Dome Security Camera. Install and start viewing footage from any device in seconds",
  "business camera system":
    "Take the next step— Start a risk-free trial with Rhombus' Business Camera System. Install and start viewing footage from any device in seconds",
  "cameras for small business":
    "Take the next step— Start a risk-free trial with a Rhombus Camera. Install and start viewing footage from any device in seconds",
  "camera system for business":
    "Take the next step— Start a risk-free trial with the Rhombus Camera System. Install and start viewing footage from any device in seconds",
  "cameras for business":
    "Take the next step— Start a risk-free trial with Rhombus Cameras. Install and start viewing footage from any device in seconds",
  "camera domes":
    "Take the next step— Start a risk-free trial with a Rhombus Dome Camera. Install and start viewing footage from any device in seconds",
  "camera fish eye":
    "Take the next step— Start a risk-free trial with a Rhombus Fish Eye Camera. Install and start viewing footage from any device in seconds",
  "camera security system for business":
    "Take the next step— Start a risk-free trial with Rhombus’ Camera Security System. Install and start viewing footage from any device in seconds",
  "camera for business security":
    "Take the next step— Start a risk-free trial with Rhombus Business Security Cameras. Install and start viewing footage from any device in seconds",
  "camera for commercial security":
    "Take the next step— Start a risk-free trial with Rhombus Commercial Security Cameras. Install and start viewing footage from any device in seconds",
  "cameras bullet":
    "Take the next step— Start a risk-free trial with a Rhombus Bullet Camera. Install and start viewing footage from any device in seconds",
  "best poe bullet camera":
    "Take the next step— Start a risk-free trial with a Rhombus POE Bullet Camera. Install and start viewing footage from any device in seconds",
  "cloud based camera systems for business":
    "Take the next step— Start a risk-free trial with Rhombus' Cloud Camera System. Install and start viewing footage from any device in seconds",
  "cloud cameras":
    "Take the next step— Start a risk-free trial with a Rhombus Cloud-Managed Camera. Install and start viewing footage from any device in seconds",
  "cloud security cameras for business":
    "Take the next step— Start a risk-free trial with a Rhombus Cloud Security Camera. Install and start viewing footage from any device in seconds",
  "commercial outdoor camera system":
    "Take the next step— Start a risk-free trial with the Rhombus Outdoor Camera System. Install and start viewing footage from any device in seconds",
  "commercial security cameras systems":
    "Take the next step— Start a risk-free trial with Rhombus' Commercial Security Camera Solution. Install and start viewing footage from any device in seconds",
  "commercial wireless security camera":
    "Take the next step— Start a risk-free trial with a Rhombus WiFi Security Camera. Install and start viewing footage from any device in seconds",
  "enterprise camera":
    "Take the next step— Start a risk-free trial with a Rhombus Camera. Install and start viewing footage from any device in seconds",
  "enterprise ip camera":
    "Take the next step— Start a risk-free trial with a Rhombus IP Camera. Install and start viewing footage from any device in seconds",
  "enterprise camera systems":
    "Take the next step— Start a risk-free trial with Rhombus' Enterprise Camera System. Install and start viewing footage from any device in seconds",
  "enterprise security cameras":
    "Take the next step— Start a risk-free trial with a Rhombus Enterprise Security Camera. Install and start viewing footage from any device in seconds",
  "enterprise security camera systems":
    "Take the next step— Start a risk-free trial with Rhombus' Enterprise Security Camera System. Install and start viewing footage from any device in seconds",
  "enterprise nvr":
    "Take the next step— Start a risk-free trial with a Rhombus Cloud-Managed Camera. Install and start viewing footage from any device in seconds",
  "enterprise surveillance cameras":
    "Take the next step— Start a risk-free trial with a Rhombus Enterprise Surveillance Camera. Install and start viewing footage from any device in seconds",
  "fisheye cameras":
    "Take the next step— Start a risk-free trial with a Rhombus Fisheye Camera. Install and start viewing footage from any device in seconds",
  "fisheye cctv camera":
    "Take the next step— Start a risk-free trial with Rhombus' Cloud-Managed Fisheye Camera. Install and start viewing footage from any device in seconds",
  "ip camera system for business":
    "Take the next step— Start a risk-free trial with Rhombus' IP Camera System. Install and start viewing footage from any device in seconds",
  "security cameras for business":
    "Take the next step— Start a risk-free trial with a Rhombus Security Camera. Install and start viewing footage from any device in seconds",
  "security camera for business":
    "Take the next step— Start a risk-free trial with a Rhombus Security Camera. Install and start viewing footage from any device in seconds",
  "wireless cctv camera system":
    "Take the next step— Start a risk-free trial with Rhombus' WiFi Camera System. Install and start viewing footage from any device in seconds",
  "security cameras for a business":
    "Take the next step— Start a risk-free trial with a Rhombus Security Camera. Install and start viewing footage from any device in seconds",
};

export const DKI_SURV_E = {
  "cctv surveillance":
    "Elevate your CCTV Surveillance with Rhombus' cutting-edge technology",
  "surveillance camera systems":
    "Up-level your security with Rhombus' advanced surveillance camera systems",
  "wireless surveillance camera systems":
    "Up-level your security with Rhombus' WiFi surveillance solutions",
  "cctv surveillance":
    "Enhance your CCTV surveillance with Rhombus' state-of-the-art cameras",
  "surveillance camera installation service":
    "Get expert surveillance installation and a seamless physical security camera system with Rhombus",
  "best surveillance system":
    "Experience industry-leading performance with Rhombus' top-rated surveillance systems",
  "top surveillance cameras":
    "Capture every detail with Rhombus' top-rated surveillance cameras",
  "video surveillance security companies":
    "Rhombus is proud to be a top-rated video surveillance company on G2",
  "security surveillance systems companies":
    "Choose Rhombus for an unparalleled security surveillance system",
  "surveillance camera installation service":
    "Get seamless setup and expert guidance with Rhombus' installation services",
  "business surveillance":
    "Protect your assets and optimize surveillance with Rhombus cameras",
  "wireless surveillance camera system":
    "Simplify your security with Rhombus' Wifi surveillance camera system",
  "commercial video surveillance cameras":
    "Enhance your commercial security with Rhombus' professional-grade cameras",
  "surveillance camera systems":
    "Upgrade your premises with Rhombus' comprehensive surveillance camera systems",
  "surveillance camera system":
    "Streamline your security with Rhombus' top-rated surveillance camera system",
  "best surveillance systems":
    "Opt for excellence with Rhombus' best-in-class camera surveillance systems",
  "wireless surveillance camera system":
    "See how flexibility meets security with Rhombus' Wifi surveillance solutions",
  "top surveillance camera":
    "Equip your space with Rhombus' top-tier surveillance camera technology",
  "surveillance camera system":
    "Modernize your monitoring with Rhombus' advanced surveillance camera system",
  "top surveillance camera":
    "Secure your premises with Rhombus' industry-leading surveillance camera",
  "best surveillance systems":
    "Elevate your security with Rhombus' top-performing surveillance systems",
  "business surveillance":
    "Optimize your business operations with Rhombus' smart surveillance",
  "commercial surveillance cameras":
    "Strengthen your commercial security with Rhombus' top-rated cameras",
  "security surveillance systems companies":
    "See why Rhombus is a leader among security surveillance systems companies",
  "commercial video surveillance cameras":
    "Boost your business security with Rhombus' commercial-grade cameras",
  "commercial security surveillance system":
    "Fortify your business with Rhombus' commercial security surveillance system",
  "commercial security surveillance systems":
    "Safeguard your enterprise with Rhombus' comprehensive commercial security surveillance",
  "enterprise surveillance":
    "Scale your security effortlessly with Rhombus' enterprise surveillance solutions",
  "video surveillance security companies":
    "See why Rhombus is the top choice for video surveillance",
  "business security surveillance systems":
    "Protect your business assets with Rhombus' advanced surveillance systems",
  "enterprise surveillance system":
    "Unify your enterprise security with Rhombus' comprehensive surveillance system",
  "best surveillance system":
    "Secure your space with Rhombus' industry-leading surveillance system",
  "business security surveillance systems":
    "Safeguard your operations with Rhombus' intelligent business security surveillance systems",
  "commercial surveillance cameras":
    "Elevate your commercial security with Rhombus' state-of-the-art surveillance cameras",
  "surveillance system for business":
    "See the top-rated surveillance solutions for business powered by Rhombus",
  "wireless surveillance camera systems":
    "Cut the cords, not the quality, with Rhombus' Wifi surveillance systems",
  "business security surveillance system":
    "Protect your enterprise with Rhombus' innovative surveillance technology",
  "commercial security surveillance system":
    "Elevate your commercial security with Rhombus' integrated surveillance system",
  "commercial security surveillance systems":
    "Secure your business premises with Rhombus' advanced commercial surveillance systems",
  "commercial surveillance camera":
    "Upgrade your commercial security with Rhombus' high-performance cameras",
  "commercial video surveillance camera":
    "Optimize your business security with Rhombus' advanced video surveillance",
  "enterprise surveillance":
    "Streamline your large-scale security with Rhombus' enterprise surveillance",
  "enterprise surveillance system":
    "Manage multi-site security effortlessly with Rhombus' enterprise system",
  "security surveillance systems company":
    "Elevate your security with Rhombus, a leading surveillance systems company",
  "surveillance system for business":
    "Customize your business security with Rhombus' flexible surveillance solutions",
  "top surveillance cameras":
    "Secure your premises with Rhombus' industry-leading surveillance cameras",
  "video surveillance security company":
    "Protect your enterprise with Rhombus' innovative surveillance technology",
};
