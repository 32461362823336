import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HomePageHero from "./HomePageHero";
import LogoSliderV2 from "components/common/LogoSliderV2";
import PlatformSection from "./PlatformSection";
import ProductsSection from "./ProductsSection";
import TabberSection from "components/rui/page-components/TabberSection";
import CustomerStoriesSection from "./CustomerStoriesSection";
import ReviewBlade from "./ReviewBlade";
import CTABannerHome from "components/common/footer-banners/CTABannerHome";

const HomePage = () => {
  const data = useStaticQuery(graphql`
    query {
      tabberImage1: file(
        relativePath: {
          eq: "components/home-page/assets/tabber-image-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      tabberImage2: file(
        relativePath: {
          eq: "components/home-page/assets/tabber-image-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      tabberImage3: file(
        relativePath: {
          eq: "components/home-page/assets/tabber-image-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      tabberImage4: file(
        relativePath: {
          eq: "components/home-page/assets/tabber-image-4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const tabberContent = [
    {
      tabTitle: "Rapid Search & Response",
      title: "From insights to actions, instantly ",
      bulletPoints: [
        "Visibility anytime, anywhere",
        "Automated workflows",
        "AI-powered search and review",
      ],
      image: data.tabberImage1,
    },
    {
      tabTitle: "Seamless Deployment",
      title: "Minimum burden, maximum coverage",
      bulletPoints: [
        "Quick PoE install",
        "Ultra-low latency and bandwidth",
        "Unlimited scalability",
      ],
      image: data.tabberImage2,
    },
    {
      tabTitle: "Reliable & Secure",
      title: "Secured & ready, when it matters",
      bulletPoints: [
        "99% platform uptime",
        "Camera onboard storage",
        "Optimized for data security",
      ],
      image: data.tabberImage3,
    },
    {
      tabTitle: "Full Interoperability",
      title: "Future-proof operations, breakdown silos",
      bulletPoints: [
        "Integrate with existing hardware",
        "Scale with 50+ app integrations",
        "Evolve tech stack with 100% open API",
      ],
      image: data.tabberImage4,
    },
  ];

  return (
    <>
      <HomePageHero />
      <LogoSliderV2 />
      <PlatformSection />
      <ProductsSection />
      <TabberSection
        preTitle={"OUR PLATFORM"}
        title={"Maximizing security and peace of mind"}
        blurb={
          " A platform that takes the worry out of physical security at scale"
        }
        tabberContent={tabberContent}
      />
      <ReviewBlade />
      <CustomerStoriesSection />
      <CTABannerHome />
    </>
  );
};

export default HomePage;
