import React, { useMemo } from "react";
import { SearchBar, SearchBarWrapper } from "./styles/MarketPlace-css";
import { cleanData, formatSearchFilterTitle } from "./util/functions";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  activeFilterAtom,
  filterTitleAtom,
  searchQueryAtom,
} from "./atoms/atoms";
import IconSearch from "components/common/icon-components/IconSearch";

const Search = ({ currentFilter, setMutableData }) => {
  const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);
  const setFilterTitle = useSetAtom(filterTitleAtom);
  const activeFilter = useAtomValue(activeFilterAtom);

  useMemo(() => {
    if (searchQuery === "") {
      setFilterTitle(`${activeFilter}`);
      setMutableData(currentFilter);
    } else {
      let match = [];
      if (Array.isArray(currentFilter[0])) {
        match = currentFilter.map((item) => {
          const found = item.filter(
            (el) =>
              el.node.frontmatter.appName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              el.node.frontmatter.companyName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              el.node.frontmatter.shortDescription
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
          );
          return found;
        });
      } else {
        match = currentFilter.filter((item) =>
          item.node.frontmatter.appName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
      setFilterTitle(formatSearchFilterTitle(match, searchQuery));
      const resultData =
        match.flat().length <= 6 ? match.flat() : cleanData(match);
      setMutableData(resultData);
    }
  }, [searchQuery]);

  return (
    <SearchBarWrapper>
      <IconSearch color="var(--primary-bg)" />
      <SearchBar
        type="search"
        placeholder="Search apps and integrations"
        aria-label="Search"
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
      />
    </SearchBarWrapper>
  );
};

export default Search;
