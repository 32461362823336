import {
  FlexCenter,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { Body, TitleMed } from "components/rui/typography";
import React from "react";

const WistiaVideoSection = ({ videoId, title, description }) => {
  return (
    <SectionContainer>
      <SectionInner style={{ gap: "5rem" }}>
        <SectionCol style={{ display: "inline-block" }}>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <span
                className={`wistia_embed wistia_async_${videoId} popover=true popoverAnimateThumbnail=true videoFoam=true`}
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                &nbsp;
              </span>
            </div>
          </div>
        </SectionCol>
        <FlexCenter>
          <TextContainer>
            <TitleMed>{title}</TitleMed>
            <Body>{description}</Body>
          </TextContainer>
        </FlexCenter>
      </SectionInner>
    </SectionContainer>
  );
};

export default WistiaVideoSection;
