import { styled } from "@linaria/react";
import React from "react";
import RhombusButton from "../RhombusButton";
import logo from "../../../images/rhombus-symbol.svg";
import { handleBlogPostUTMS } from "./util";
import { TitleMed } from "components/rui/typography";

const Container = styled.div`
  width: 100%;
  padding: 2.5rem;
  border-radius: 8px;
  background: var(--gradient-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.25rem;
  margin: 2.5rem 0;
  a {
    color: var(--white) !important;
    text-decoration: none !important;
    :hover {
      text-decoration: none !important;
    }
  }
  h3 {
    margin: 0 !important;
    font-weight: 800;
    line-height: 120% !important;
  }
`;

const RhombusLogo = styled.img`
  height: 40px !important;
  aspect-ratio: 1 !important;
  object-fit: contain !important;
  margin: 0 !important;
`;

const PostCTA = () => {
  return (
    <Container>
      <RhombusLogo src={logo} alt="Rhombus Logo" />
      <TitleMed style={{ fontSize: "32px" }}>
        Streamline security operations and maximize safety across your spaces
        with Rhombus.
      </TitleMed>
      <RhombusButton
        title={"Unlock 3 Week Free Trial"}
        path={"/free-trial/"}
        onClick={handleBlogPostUTMS}
        type="primary-dark"
      />
    </Container>
  );
};

export default PostCTA;
