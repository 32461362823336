import { tabContainer, tabRow } from "components/common/InfoSlider";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { styled } from "@linaria/react";
import React, { useCallback, useRef, useState } from "react";

import AccessControlSection from "./access-control/AccessControlSection";
import AlarmSection from "./alarms/AlarmSections";
import CameraSection from "./cameras/CameraSection";
import SensorSection from "./sensors/SensorSection";

const ProductSectionInner = styled(SectionInner)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProductTab = styled.li`
  font-weight: 800;
  color: ${(props) =>
    props.active ? "var(--primary-bg)" : "var(--body-subtle)"};
  font-size: 18px;
  height: 47px;
  cursor: pointer;
  transition: all 250ms;
  white-space: nowrap;
  position: relative;
  text-transform: capitalize;
  :hover {
    color: var(--primary-bg);
  }
  :not(:last-of-type)::before {
    content: "";
    position: absolute;
    height: 2px;
    width: calc(100% + 46px);
    background-color: var(--nuetral-200);
    bottom: 0;
    left: 0;
  }

  :last-of-type::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: var(--nuetral-200);
    bottom: 0;
    left: 0;
  }

  ::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: ${(props) =>
      props.active ? "var(--primary-bg)" : "transparent"};
    bottom: 0;
    left: 0;
    transition: all 250ms;
  }
`;

export default function RhombusProducts({ product }) {
  const PRODUCT_TYPES = ["cameras", "sensors", "access control", "alarms"];
  const [selectedProduct, setSelectedProduct] = useState(product);

  const scrollRef = useRef(null);
  const previousSelection = useRef(selectedProduct);

  const renderProductComponent = useCallback(() => {
    previousSelection.current = selectedProduct;
    switch (selectedProduct) {
      case PRODUCT_TYPES[0]:
        return <CameraSection />;
      case PRODUCT_TYPES[1]:
        return <SensorSection />;
      case PRODUCT_TYPES[2]:
        return <AccessControlSection />;
      case PRODUCT_TYPES[3]:
        return <AlarmSection />;
      default:
        return <CameraSection />;
    }
  }, [selectedProduct]);

  const getPrevElementsWidth = () => {
    let totalWidth = 0;
    if (
      selectedProduct === PRODUCT_TYPES[0] ||
      selectedProduct === PRODUCT_TYPES[3]
    )
      return 0;
    PRODUCT_TYPES.forEach((item) => {
      const el = document.getElementById(item);
      totalWidth += el && el.offsetWidth;
    });
    return totalWidth;
  };

  const scroll = (offset) => {
    scrollRef.current.scrollLeft += offset;
  };

  const handleTabClick = (item, index) => {
    const posOffset =
      // eslint-disable-next-line no-mixed-operators
      46 * PRODUCT_TYPES.indexOf(selectedProduct) + getPrevElementsWidth();
    const negOffset = -posOffset;
    const defaultOffset = 46 * 2.5;
    if (previousSelection.current === PRODUCT_TYPES[0] && index !== 0) {
      scroll(defaultOffset);
    } else if (PRODUCT_TYPES.indexOf(previousSelection.current) > index) {
      scroll(negOffset);
    } else {
      scroll(posOffset);
    }
    setSelectedProduct(item);
  };

  return (
    <SectionContainer id="pricing">
      <ProductSectionInner>
        <div
          className={tabContainer}
          ref={scrollRef}
          style={{ marginBottom: "-5rem" }}
        >
          <ul className={tabRow}>
            {PRODUCT_TYPES.map((item, index) => (
              <ProductTab
                id={item}
                key={item}
                onClick={() => handleTabClick(item, index)}
                active={item === selectedProduct}
              >
                {item}
              </ProductTab>
            ))}
          </ul>
        </div>
        {renderProductComponent()}
      </ProductSectionInner>
    </SectionContainer>
  );
}
