import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function B10() {
  const data = useStaticQuery(graphql`
    query {
      b10Header: file(
        relativePath: { eq: "components/sensors/img/b10-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      b10HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/b10-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      b10Dims: file(
        relativePath: { eq: "components/sensors/img/b10-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);
  const sensor = {
    model: "B10",
    title: "B10 Emergency Panic Button",
    specTitle: "B10 Emergency Panic Button Specifications",
    imageHeader: data.b10Header,
    imageHeaderMobile: data.b10HeaderMobile,
    heroAlt:
      "Get one-touch emergency response with the B10 smart panic button mounted on a wall. The button can be programmed to correlate specific actions like instant assistance, triggering loud alarms, or securing doors.",
    image: data.b10Dims,
    dimsAlt:
      "B10 emergency button with dimensions. B10 smart panic button is a versatile device that allows for easy call for help in emergency situations ",
    objectPosition: "90%",
    description:
      "Get 1-touch emergency response and additional context with paired camera video stream. The B10 emergency panic button is easily programmable to trigger actions like emergency dispatch, audio message, and more.",
    pdf: "/files/specs/B10.pdf",
    accessoryCompatible: false,
    column1: [
      {
        title: "Dimensions",
        text: "70mm (2.75in) x 32.2mm (1.26in) x 18.2mm (0.72in)",
      },
      {
        title: "Range",
        text: "Up to 100m (328ft)",
      },
      {
        title: "Battery Type",
        text: "Replaceable 2x1.5V AAA (Lithium Batteries included)",
      },
      {
        title: "Battery Life",
        text: "5 years with typical medium-heavy use**",
      },
      {
        title: "Weight",
        text: "32.4g (w/ battery)",
      },
    ],
    column2: [
      {
        title: "Detection/Function",
        text: "Supports short-press, long-press, and double-press button functions",
      },
      {
        title: "Mounting",
        text: "Two-sided tape (included) or screws (with required mounting cover)",
      },
      {
        title: "Weather Resistance",
        text: "IPX4",
      },
      {
        title: "NDAA, FCC, CE, REACH, RoHS, IC, and RCM Compliant",
      },

      {
        title: "2-Year Warranty Included",
      },
      {
        title: "",
        text: "**Subject to change",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>
          B10 Emergency Panic Button | Commercial IoT Sensors | Rhombus
        </title>
        <meta
          name="description"
          content="Stay in control during a crisis with B10 Emergency Panic Button. With one-touch response, quickly dispatch emergency services, trigger loud alarms, or secure doors as needed to increase safety in critical situations."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-B10.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-B10.png"
        />
      </Helmet>
      <SensorSpecsPage sensor={sensor} />
    </GlobalLayout>
  );
}
