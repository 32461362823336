import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useEffect, useState } from "react";

const SlideShow = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 379px;
  max-width: 669px;
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
`;

const slide = css`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 2s;
`;

const slideActive = cx(
  slide,
  css`
    opacity: 1;
  `
);

const imageWrapper = css`
  height: 100%;
  width: 100%;
  position: relative;
`;

const slideImage = css`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
`;

const FadeInSlideShow = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateActiveIndex = index => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateActiveIndex((activeIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [activeIndex, images.length]);

  return (
    <SlideShow>
      {images.map((item, index) => (
        <div
          key={index}
          className={index === activeIndex ? slideActive : slide}
        >
          <div className={imageWrapper}>
            <GatsbyImage image={getImage(item)} alt="" className={slideImage} />
          </div>
        </div>
      ))}
    </SlideShow>
  );
};

export default FadeInSlideShow;
