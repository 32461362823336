import { styled } from "@linaria/react";

export const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  padding: 5rem 1.25rem;
  overflow: hidden;
  background: ${(props) => props.color ?? "var(--white)"};
  scroll-margin-top: 7.5rem;
`;

export const SectionInner = styled.div`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  gap: 2.5rem;
  &.flex-col {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &.flex-col-start {
    flex-direction: column;
  }
  @media (max-width: 820px) {
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: ${(props) => props.width};
`;

export const SectionCol = styled.div`
  flex-grow: 1;
  width: 50%;
  display: flex;
  &.col {
    flex-direction: column;
    gap: 2.5rem;
    justify-content: center;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const FlexCenter = styled(SectionCol)`
  align-items: center;
  justify-content: center;
`;

export const FlexRow = styled.div`
  display: flex;
  gap: ${(props) => props.gap ?? "unset"};
  align-items: ${(props) => (props.center ? "center" : "unset")};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap ?? "unset"};
  justify-content: ${(props) => (props.center ? "center" : "unset")};
`;

export const FlexEndColumn = styled(SectionCol)`
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 820px) {
    justify-content: flex-start;
  }
`;

export const CarouselContainerWithScrollBar = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  .swiper {
    width: 100% !important;
    padding: 0 2.5rem !important;
    @media (max-width: 600px) {
      padding: 0 1.25rem !important;
    }
  }
  .swiper-wrapper {
    width: 100% !important;
    transform-style: preserve-3d !important;
    -webkit-transform-style: preserve-3d !important;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
    padding-bottom: 2.5rem;
  }
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 0.5rem;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--nuetral-200);
    border-radius: 1rem;
    .swiper-scrollbar-drag {
      background-image: var(--gradient-base);
      border-radius: 1rem;
      width: 100%;
      cursor: grab;
    }
  }

  @media (min-width: 1024px) {
    .swiper-scrollbar.swiper-scrollbar-horizontal,
    .swiper-scrollbar-drag {
      display: none !important;
    }
    @media (min-width: 1513px) {
      -webkit-mask: linear-gradient(
        90deg,
        transparent,
        #fff ${(props) => props.startPercentage}%
          ${(props) => props.endPercentage}% "," transparent
      );
    }
  }
`;

export const SliderSectionInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  max-width: 2600px;
`;

export const DefaultFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

export const ModalContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  background-color: #00000030;
  position: absolute;
  top: ${(props) => props.top};
  left: 0;
  z-index: 999;
`;

export const PopupContainer = styled.div`
  height: fit-content;
  max-width: 800px;
  width: 90%;
  display: flex;
  background:
    linear-gradient(var(--white), var(--white)) padding-box,
    linear-gradient(to right, var(--blue-500), var(--teal-500)) border-box;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 5rem 2.5rem;
  position: relative;
  @media (max-width: 820px) {
    max-width: 500px;
  }
`;

export const DefaultCard = styled.a`
  flex-shrink: 0;
  width: 400px;
  height: 450px;
  max-width: 100%;
  border-radius: 1rem;
  text-decoration: none;
  overflow: hidden;
  box-shadow: 1px 1px 10px #03193320;
  @media (min-width: 1440px) {
    width: calc((100% - 5rem) / 3);
  }
  @media (max-width: 1320px) {
    width: calc((100% - 2.5rem) / 2);
  }
  @media (max-width: 700px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const Dialogue = styled.div`
  position: absolute;
  top: 140%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 600px;
  width: 100%;
  overflow: auto;
  z-index: 10;
  border-radius: 12px;
  border: 1px solid var(--nuetral-200);
  background: var(--white);
  box-shadow: 1px 1px 10px #00000020;
  ::-webkit-scrollbar {
    width: 4px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--nuetral-200);
    border-radius: 3rem;
  }
`;
