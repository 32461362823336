import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import AccessoriesSidebar from "./AccessoriesSidebar";
import AccessoriesFeed from "./AccessoriesFeed";
import { graphql, StaticQuery } from "gatsby";
import {
  acModels,
  cameraModels,
  DEFAULT_SELECTIONS,
  sensorModels,
} from "./constants";
import useIsMobile from "hooks/useIsMobile";
import { styled } from "@linaria/react";
import { getParam } from "components/form/util/functions";

export const MOBILE_MEDIA_QUERY = 1024;

const AccessorySectionContainer = styled(SectionContainer)`
  padding-top: ${(props) => (props.singleDeviceMode ? "5rem" : "2.5rem")};
  background-color: ${(props) =>
    props.singleDeviceMode ? "var(--nuetral-100)" : "var(--white)"};
  overflow: visible;
`;

export default function AccessoriesContainer({
  defaultSelection = DEFAULT_SELECTIONS.cameras,
  singleDeviceMode = false,
  staticTitle = null,
}) {
  const renderContent = (data) => {
    const { edges: _accessories } = data.allMarkdownRemark;
    const [selectedModel, setSelectedModel] = useState(defaultSelection);
    const [currentFilter, setCurrentFilter] = useState(_accessories);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const { isMobile } = useIsMobile(MOBILE_MEDIA_QUERY);

    useEffect(() => {
      const modelFromURL = getParam("model")?.toLocaleUpperCase();
      if (
        cameraModels.includes(modelFromURL) ||
        acModels.includes(modelFromURL) ||
        sensorModels.includes(modelFromURL)
      ) {
        setSelectedModel(modelFromURL);
      }
    }, []);
    useEffect(() => {
      if (typeof window !== "undefined" && selectedModel && !singleDeviceMode) {
        const url = new URL(window.location);
        url.searchParams.set("model", selectedModel);
        window.history.replaceState({}, "", url);
      }
    }, [selectedModel]);

    const filterAccessories = useCallback((model, accessories) => {
      return accessories.filter((item) => {
        const compatibleDevices = item.node.frontmatter.compatibleDevices;
        if (model === DEFAULT_SELECTIONS.cameras) {
          return cameraModels.some((value) =>
            compatibleDevices.includes(value)
          );
        }
        if (model === DEFAULT_SELECTIONS.ac) {
          return acModels.some((value) => compatibleDevices.includes(value));
        }
        if (model === DEFAULT_SELECTIONS.sensors) {
          return sensorModels.some((value) =>
            compatibleDevices.includes(value)
          );
        }
        return compatibleDevices.includes(model);
      });
    }, []);

    const accessoriesFilteredByModel = useMemo(() => {
      return filterAccessories(selectedModel, _accessories);
    }, [selectedModel, _accessories, filterAccessories]);

    useEffect(() => {
      let filteredAccessories = accessoriesFilteredByModel;
      if (
        selectedModel === DEFAULT_SELECTIONS.ac ||
        acModels.includes(selectedModel)
      ) {
        setCurrentFilter(accessoriesFilteredByModel);
      } else if (categoryFilter.length > 0) {
        filteredAccessories = filteredAccessories.filter((item) => {
          const categories = item.node.frontmatter.categories;
          return categoryFilter.some((category) =>
            categories.includes(category)
          );
        });
      }
      setCurrentFilter(filteredAccessories);
    }, [categoryFilter, accessoriesFilteredByModel, selectedModel]);

    const FilterAccessoriesByCategory = useCallback((filterArray) => {
      setCategoryFilter(filterArray);
    }, []);

    const title = useMemo(() => {
      if (selectedModel === DEFAULT_SELECTIONS.cameras) {
        return "All Camera Accessories";
      }
      if (selectedModel === DEFAULT_SELECTIONS.ac) {
        return "All Access Control Accessories";
      }
      if (selectedModel === DEFAULT_SELECTIONS.sensors) {
        return "All Sensor Accessories";
      }
      return `${selectedModel} Accessories`;
    }, [selectedModel]);

    return (
      <AccessorySectionContainer singleDeviceMode={singleDeviceMode}>
        <SectionInner style={{ position: "relative" }}>
          {!isMobile && !singleDeviceMode && (
            <AccessoriesSidebar
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              defaultSelection={defaultSelection}
            />
          )}
          <AccessoriesFeed
            accessories={currentFilter}
            handleFilter={FilterAccessoriesByCategory}
            title={singleDeviceMode && staticTitle ? staticTitle : title}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            singleDeviceMode={singleDeviceMode}
            defaultSelection={defaultSelection}
          />
        </SectionInner>
      </AccessorySectionContainer>
    );
  };

  const query = graphql`
    query {
      allMarkdownRemark(
        limit: 100
        filter: { frontmatter: { templateKey: { eq: "accessory" } } }
        sort: { frontmatter: { price: ASC } }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              sku
              price
              description
              image
              categories
              compatibleDevices
            }
          }
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
