import React from "react";

const IconLightning = ({
  width = "21",
  height = "32",
  color = "var(--blue-500)",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1462 2.22554L11.6604 13.4769H19.2895C19.4386 13.4769 19.5254 13.639 19.4374 13.7545L7.18585 29.8429C7.06363 30.0027 6.80085 29.8767 6.86319 29.6877L10.6619 17.9884H2.1833C2.03174 17.9884 1.94496 17.8215 2.03908 17.706L14.826 2.06804C14.9506 1.91637 15.2036 2.04004 15.1462 2.22554Z"
        stroke={color}
        stroke-width="2.33332"
      />
    </svg>
  );
};

export default IconLightning;
