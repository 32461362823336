import TrialBanner from "components/common/footer-banners/TrialBanner";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";

import PricingFooter from "./PricingFooter";
import RhombusProducts from "./RhombusProducts";

export default function PricingPage({ product = "cameras" }) {
  useEffect(() => {
    const request = new XMLHttpRequest();
    request.open("GET", document.location, true);
    request.send(null);
    request.onload = () => {
      const countryCode = request.getResponseHeader("X-Viewer-Country");
      if (!countryCode) return;
      if (countryCode !== "US") {
        navigate("/lp/custom-quote-request/");
      }
    };
  }, []);

  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/common/footer-banners/images/cta-banner-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const headerSection = {
    preTitle: "Rhombus Pricing",
    title:
      "Cloud Physical Security Pricing Tailored to Your Organization's Needs",
    description:
      "At Rhombus, we provide transparent pricing so you can build the perfect physical security solution for your business, ensuring peace of mind and optimal protection.",
    image: data.headerImage,
    altText: "Rhombus Physical Security Product Suite Pricing",
    button1: { text: "Custom Quote", path: "/custom-quote/" },
  };

  return (
    <>
      <HeroLayout1 data={headerSection} />
      <RhombusProducts product={product} />
      <PricingFooter />
      <TrialBanner />
    </>
  );
}
