import React from "react";

const IconProductivity = ({ size = "44", color = "var(--blue-500)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 45 44"
      fill="none"
    >
      <g clip-path="url(#clip0_29047_18509)">
        <path
          d="M41.6229 11.116C41.2702 10.4974 40.4829 10.2821 39.8645 10.6348C39.2461 10.9875 39.0306 11.7746 39.3833 12.393C41.044 15.3055 41.9219 18.6275 41.9219 22C41.9219 27.1878 39.9017 32.065 36.2333 35.7333C32.565 39.4017 27.6878 41.4219 22.5 41.4219C17.3122 41.4219 12.435 39.4017 8.76667 35.7333C5.09834 32.065 3.07812 27.1878 3.07812 22C3.07812 16.8122 5.09834 11.935 8.76667 8.26667C12.435 4.59834 17.3122 2.57812 22.5 2.57812C25.3002 2.57812 28.0021 3.16113 30.531 4.31105C31.8567 4.91382 33.1093 5.66491 34.269 6.54998L32.8788 6.64409C32.1686 6.69221 31.6317 7.30692 31.6798 8.01728C31.7256 8.7291 32.3514 9.26673 33.053 9.21637L37.5005 8.91533C38.1922 8.88078 38.7579 8.23135 38.6996 7.54213L38.3984 3.09452C38.3503 2.38425 37.736 1.84705 37.0252 1.89544C36.3149 1.94356 35.7781 2.55836 35.8262 3.26863L35.9137 4.56182C34.5782 3.5328 33.1313 2.66114 31.5981 1.96402C28.7319 0.660773 25.6708 0 22.5 0C16.6236 0 11.0989 2.28843 6.94368 6.44368C2.78843 10.5989 0.5 16.1236 0.5 22C0.5 27.8764 2.78843 33.4011 6.94368 37.5563C11.0989 41.7116 16.6236 44 22.5 44C28.3764 44 33.9011 41.7116 38.0563 37.5563C42.2116 33.4011 44.5 27.8764 44.5 22C44.5 18.1804 43.5051 14.4167 41.6229 11.116Z"
          fill={color}
        />
        <path
          d="M32.2144 12.2824C29.7043 9.80478 26.2941 8.2543 22.4984 8.25782C14.9256 8.25601 8.75601 14.4256 8.75781 21.9984C8.75601 29.5711 14.9256 35.7407 22.4984 35.7389C30.0711 35.7407 36.2407 29.5711 36.2389 21.9984C36.2423 18.2025 34.692 14.7925 32.2144 12.2824ZM32.4384 23.2874H33.5859C33.3418 25.4011 32.5053 27.3368 31.2465 28.9236L30.4386 28.1156C29.9352 27.6122 29.119 27.6122 28.6156 28.1156C28.1121 28.619 28.1121 29.4351 28.6156 29.9386L29.4235 30.7465C27.8368 32.0053 25.9011 32.8418 23.7874 33.0859V31.9384C23.7874 31.2265 23.2103 30.6494 22.4984 30.6494C21.7865 30.6494 21.2093 31.2265 21.2093 31.9384V33.0859C19.0957 32.8418 17.1599 32.0053 15.5732 30.7465L16.3812 29.9386C16.8846 29.4351 16.8846 28.619 16.3812 28.1156C15.8778 27.6122 15.0616 27.6122 14.5582 28.1156L13.7502 28.9236C12.4915 27.3368 11.6549 25.4011 11.4108 23.2874H12.5583C13.2702 23.2874 13.8474 22.7103 13.8474 21.9984C13.8474 21.2865 13.2702 20.7093 12.5583 20.7093H11.4108C11.6549 18.5957 12.4915 16.6599 13.7502 15.0732L14.5582 15.8812C14.8099 16.1329 15.1398 16.2588 15.4696 16.2588C15.7994 16.2588 16.1294 16.133 16.3811 15.8812C16.8845 15.3777 16.8845 14.5616 16.3811 14.0582L15.5731 13.2502C17.1598 11.9915 19.0955 11.1549 21.2092 10.9108V12.0583C21.2092 12.7702 21.7864 13.3474 22.4983 13.3474C23.2102 13.3474 23.7873 12.7702 23.7873 12.0583V10.9108C25.901 11.1549 27.8367 11.9915 29.4235 13.2502L28.6155 14.0582C28.1121 14.5616 28.1121 15.3777 28.6155 15.8812C28.8672 16.1329 29.1971 16.2588 29.5269 16.2588C29.8568 16.2588 30.1868 16.133 30.4384 15.8812L31.2464 15.0732C32.5051 16.6599 33.3416 18.5956 33.5858 20.7093H32.4382C31.7263 20.7093 31.1492 21.2865 31.1492 21.9984C31.1492 22.7103 31.7264 23.2874 32.4384 23.2874Z"
          fill={color}
        />
        <path
          d="M25.565 20.7096H23.7891V17.2148C23.7891 16.5029 23.2119 15.9258 22.5 15.9258C21.7881 15.9258 21.2109 16.5029 21.2109 17.2148V21.9986C21.2109 22.7105 21.7881 23.2877 22.5 23.2877H25.565C26.277 23.2877 26.8541 22.7105 26.8541 21.9986C26.8541 21.2867 26.277 20.7096 25.565 20.7096Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_29047_18509">
          <rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconProductivity;
