import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import CameraDetail from "components/cameras/detail/CameraDetailPage";
import {
  CTA_BUTTON_COPY_6,
  CUSTOM_QUOTE_LINK,
  LIVE_DEMO_LINK,
  QUOTE_COPY,
} from "components/data/landing-page-data";

export default function r500() {
  const data = useStaticQuery(graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r500-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r500-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/detail/img/r500-dims-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      bulletCamera: file(
        relativePath: { eq: "components/cameras/img/explore-r500-510-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bulletCameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r500-510-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r520: file(
        relativePath: { eq: "components/cameras/img/r520-product.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);
  const camera = {
    model: "R500",
    type: "Bullet",
    description:
      "High-performing, long-distance reading 4K bullet with AI analytics and 2.5x optical zoom.",
    webHeader: data.webImage,
    mobileHeader: data.mobileImage,
    objectPosition: "20%",
    dims: data.dims,
    pdf: "/files/specs/R500.pdf",
    specs: {
      column1: [
        {
          title: "Resolution",
          text: "8.3MP image sensor, 3840 x 2160 resolution",
        },
        {
          title: "Aperture",
          text: "F/1.5, P-iris",
        },
        {
          title: "Pan / Tilt / Zoom",
          text: "2.5X optical zoom + up to 3X digital PTZ",
        },
        {
          title: "Field of View",
          text: [
            "44° - 18° Diagonal FoV",
            "39° - 16° Horizontal FoV",
            "22° - 9° Vertical FoV",
          ],
        },
        {
          title: "Focal Length",
          text: "11mm - 28mm motorized varifocal lens",
        },
        {
          title: "Dimensions",
          text: "146mm (5.75in) x 225mm (8.85in)) x 92mm (3.22in)",
        },
        {
          title: "Weight",
          text: "0.85kg (1.88lbs)",
        },
        {
          title: "Weather Resistance",
          text: "IP66",
        },
        {
          title: "Night Vision",
          text: "Infrared illuminators (130ft in low light)",
        },
        {
          title: "Onboard Storage Included",
          text: ["512GB or 30 days", "1TB or 60 days"],
        },
      ],
      column2: [
        {
          title: "Ethernet",
          text: "10 / 100Mbps, MDI / MDI-X Auto Sensing",
        },
        {
          title: "Power-Over-Ethernet",
          text: "Standard 802.3af for one-cable installation",
        },
        {
          title: "Power Consumption",
          text: "Max 13 watts",
        },
        {
          title: "Operating Temperature",
          text: ["-55˚ to 55˚ C", "-67˚ to 131˚ F"],
        },
        {
          title: "Operating Humidity",
          text: "10% - 90% RH",
        },
        {
          title: "In the Box",
          text: "Torx key, mounting screws, anchors, waterproof ethernet connector",
        },
        {
          title: "Baseline Steady State",
          text: "10 - 30 Kbps per camera",
        },
        {
          title: "HD WAN Live Streaming",
          text: "530 - 720 Kbps per camera",
        },
        {
          title: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant",
        },
        {
          title: "10-Year Warranty Included",
        },
      ],
    },
    accessoryCompatible: true,
    sensorCompatible: false,
    accessories: ["ACCR5POLE", "ACCR5JB"],
    exploreModels: {
      title: "Explore Other Bullet Cameras",
      subtitle:
        "Find the solution that’s best suited for your needs. Compare all camera models or view other bullet cameras below.",
      cameraCards: [
        {
          image: data.bulletCamera,
          mobileImage: data.bulletCameraMobile,
          alt: "R510 bullet camera model",
          width: "189px",
          model: "R510 Standard Zoom",
          useCase: "Ideal for building perimeters.",
          link: "/cameras/bullet-cameras/r510",
        },
        {
          image: data.r520,
          mobileImage: data.r520,
          alt: "R520 Mini ",
          width: "189px",
          model: "R520 Mini ",
          useCase: "Ideal for monitoring access points.",
          link: "/cameras/bullet-cameras/r520",
        },
      ],
    },
  };
  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>Rhombus R500 Bullet Security Camera</title>
        <meta
          name="description"
          content="4K telephoto bullet camera for enterprise, commercial business, education, healthcare, and more."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/r500-rain-min.png"
        />
      </Helmet>
      <CameraDetail
        camera={camera}
        ctaCopy={CTA_BUTTON_COPY_6}
        ctaLink={LIVE_DEMO_LINK}
        pricingCopy={QUOTE_COPY}
        pricingLink={CUSTOM_QUOTE_LINK}
      />
    </GlobalLayout>
  );
}
