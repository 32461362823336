import { CloseButton } from "components/rhombus-UI/theme/ui-elements";
import React, { useEffect, useState } from "react";
import CloseIcon from "../icon-components/Close";
import { useSpring, animated } from "@react-spring/web";
import { styled } from "@linaria/react";

const Wrapper = styled.div`
  position: absolute;
  top: 140%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
  display: ${props => (props.hidden ? "none" : "block")};
`;

const Dialogue = styled(animated.div)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: fit-content;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--nuetral-200);
  background: var(--white);
  box-shadow: 1px 1px 10px #00000020;
  ::-webkit-scrollbar {
    width: 4px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--nuetral-200);
    border-radius: 3rem;
  }
`;

const CommonModal = ({ onClose, children, isOpen }) => {
  const [fadeIn, api] = useSpring(() => ({}), []);
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen);

  useEffect(() => {
    if (!isOpen) {
      api.start({
        from: { opacity: 1 },
        to: { opacity: 0 },
        config: { duration: 250 },
      });
      setTimeout(() => {
        setIsOpenInternal(isOpen);
      }, 251);
    } else {
      setIsOpenInternal(isOpen);
      api.start({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 250 },
      });
    }
  }, [isOpen]);

  return (
    <Wrapper hidden={!isOpenInternal}>
      <Dialogue style={fadeIn}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {children}
      </Dialogue>
    </Wrapper>
  );
};

export default CommonModal;
