import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { styled } from "@linaria/react";
import { toTitleCase } from "../util/helperFunctions";
import useIsMobile from "hooks/useIsMobile";
import { Body, HeroTitle, PreTitle } from "components/rui/typography";

const HeroContainer = styled(SectionContainer)`
  background: ${(props) => props.backgroundColor};
  overflow: visible;
`;

const HeroLeft = styled(SectionCol)`
  align-items: center;
`;

const HeroRight = styled(SectionCol)`
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const DefaultHeroLayout = ({
  preTitle,
  title,
  blurb,
  image = null,
  center = false,
  backgroundColor,
  maxWidth = "682px",
}) => {
  const { isMobile } = useIsMobile(600);
  return (
    <HeroContainer backgroundColor={backgroundColor} id="top">
      <SectionInner>
        {center ? (
          <CenterContainer>
            <TextContainer
              style={!isMobile && { textAlign: "center", alignItems: "center" }}
              width={maxWidth}
            >
              <PreTitle color="var(--primary-bg)">{preTitle}</PreTitle>
              <HeroTitle color="var(--primary-bg)">
                {toTitleCase(title)}
              </HeroTitle>
              <Body style={{ maxWidth: "682px" }}>{blurb}</Body>
            </TextContainer>
          </CenterContainer>
        ) : (
          <>
            <HeroLeft>
              <TextContainer>
                <PreTitle color="var(--primary-bg)">{preTitle}</PreTitle>
                <HeroTitle color="var(--primary-bg)">
                  {toTitleCase(title)}
                </HeroTitle>
                <Body>{blurb}</Body>
              </TextContainer>
            </HeroLeft>
            <HeroRight>
              {image && (
                <GatsbyImage
                  image={getImage(image)}
                  alt={title}
                  objectFit="contain"
                  style={{ width: "100%" }}
                />
              )}
            </HeroRight>
          </>
        )}
      </SectionInner>
    </HeroContainer>
  );
};

export default DefaultHeroLayout;
