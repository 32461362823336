import React from "react";
import { css } from "@linaria/core";

import videoSensors from "./mp4/sensor-overview-clip.hevc.mp4";
import videoSensorsBackup from "./mp4/backups/sensor-overview-clip.mp4";
import videoSensorsMobile from "./mp4/connected-sensors-mobile.hevc.mp4";
import SlideInContainer from "components/common/SlideInContainer";
import PageContent from "components/page/PageContent";
import useIsMobile from "hooks/useIsMobile";
import useCompatibleVideo from "hooks/useCompatibleVideo";
import { TitleMed } from "components/rhombus-UI/theme/typography";
import TextLinkV2 from "components/common/TextLinkV2";

const containerClass = css`
  width: 100%;
  background: black;
  max-height: 732px;
  position: relative;
`;

const videoClass = css`
  display: block;
  object-fit: cover;
  opacity: 0.65;
  width: 100%;
  max-height: 732px;
  margin: 0px auto;
  min-height: 250px;
`;

const textWrapper = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  color: var(--white);
`;

const videoTextClass = css`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  height: 100%;

  @media (max-width: 600px) {
    width: 100%;
    row-gap: 11px;
    justify-content: flex-end;
    padding-bottom: 34px;
  }

  > div {
    width: 50%;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  h1 {
    color: white;
    max-width: 445px;
  }
`;

const paragraphText = css`
  color: white;
  max-width: 445px;
`;

const pageContentClass = css`
  height: 100%;
`;

export default function CameraHero({}) {
  const { isMobile } = useIsMobile(600);
  const webVideo = useCompatibleVideo(videoSensors, videoSensorsBackup);

  const video = isMobile ? videoSensorsMobile : webVideo;

  return (
    <section className={containerClass}>
      <video
        src={video}
        className={videoClass}
        muted
        autoPlay
        loop
        playsInline
      />
      <div className={textWrapper}>
        <PageContent contentClass={pageContentClass}>
          <div className={videoTextClass}>
            <SlideInContainer>
              <TitleMed>Connected Sensors</TitleMed>
            </SlideInContainer>
            <SlideInContainer>
              <p className={paragraphText}>
                Video security meets real-time sensor data. Receive real-time
                alerts with synchronized video evidence to streamline response.
              </p>
            </SlideInContainer>
            <SlideInContainer>
              <TextLinkV2 path="/sensors/" title={"Learn more"} light />
            </SlideInContainer>
          </div>
        </PageContent>
      </div>
    </section>
  );
}
