import React from "react";

const IconBoundingBoxEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
    >
      <g clip-path="url(#clip0_27235_23700)">
        <path
          d="M3.50781 5.95703H9.36719V3.02734H0.578125V11.8164H3.50781V5.95703Z"
          fill="#2A7DE1"
        />
        <path
          d="M41.7891 5.95703H47.6484V11.8164H50.5781V3.02734H41.7891V5.95703Z"
          fill="#2A7DE1"
        />
        <path
          d="M47.6484 44.043H41.7891V46.9727H50.5781V38.1836H47.6484V44.043Z"
          fill="#2A7DE1"
        />
        <path
          d="M9.36719 44.043H3.50781V38.1836H0.578125V46.9727H9.36719V44.043Z"
          fill="#2A7DE1"
        />
        <path
          d="M25.5781 14.7461C19.924 14.7461 15.3242 19.3459 15.3242 25C15.3242 30.6541 19.924 35.2539 25.5781 35.2539C31.2323 35.2539 35.832 30.6541 35.832 25C35.832 19.3459 31.2323 14.7461 25.5781 14.7461ZM25.5781 32.3242C21.5395 32.3242 18.2539 29.0386 18.2539 25C18.2539 20.9614 21.5395 17.6758 25.5781 17.6758C29.6167 17.6758 32.9023 20.9614 32.9023 25C32.9023 29.0386 29.6167 32.3242 25.5781 32.3242Z"
          fill="#2A7DE1"
        />
        <path
          d="M49.9527 25.8389L50.5386 25L49.9527 24.1611C44.8223 16.8129 35.1715 8.88672 25.5779 8.88672C15.9614 8.88672 6.24463 16.9399 1.20312 24.1611L0.617188 25L1.20312 25.8389C6.33351 33.1871 15.9843 41.1133 25.5779 41.1133C35.1944 41.1133 44.9112 33.0601 49.9527 25.8389ZM25.5779 38.1836C17.3423 38.1836 9.27121 31.4739 4.23466 25.0004C6.59062 21.9769 15.2763 11.8164 25.5779 11.8164C33.8134 11.8164 41.8846 18.5261 46.9211 24.9996C44.5652 28.0231 35.8795 38.1836 25.5779 38.1836Z"
          fill="#2A7DE1"
        />
        <path
          d="M25.5781 20.6055C23.155 20.6055 21.1836 22.5769 21.1836 25C21.1836 27.4231 23.155 29.3945 25.5781 29.3945C28.0012 29.3945 29.9727 27.4231 29.9727 25C29.9727 22.5769 28.0012 20.6055 25.5781 20.6055ZM25.5781 26.4648C24.7706 26.4648 24.1133 25.8076 24.1133 25C24.1133 24.1924 24.7706 23.5352 25.5781 23.5352C26.3857 23.5352 27.043 24.1924 27.043 25C27.043 25.8076 26.3857 26.4648 25.5781 26.4648Z"
          fill="#2A7DE1"
        />
      </g>
      <defs>
        <clipPath id="clip0_27235_23700">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.578125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconBoundingBoxEye;
