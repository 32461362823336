import RhombusButton from "components/common/RhombusButton";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import { MainParagraph, Title } from "components/rhombus-UI/theme/typography";
import { styled } from "@linaria/react";
import React from "react";

const Container = styled.div`
  padding: 5rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.25rem;
`;

const FooterCTA = () => {
  return (
    <Container>
      <Title>Try Rhombus for Free!</Title>
      <MainParagraph>
        Get custom pricing and demo from a Rhombus expert today!
      </MainParagraph>
      <RhombusButton title={CTA_BUTTON_COPY} type="landing" />
    </Container>
  );
};

export default FooterCTA;
