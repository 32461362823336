import { styled } from "@linaria/react";
import {
  FlexColumn,
  FlexEndColumn,
  FlexRow,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { SectionTitle, TitleMed } from "components/rhombus-UI/theme/typography";
import React from "react";
import image1 from "./assets/blakes.png";
import image2 from "./assets/ymca.png";
import image3 from "./assets/middleby.png";
import logo1 from "../../../common/logos/color/blakes-logo.png";
import logo2 from "../../../common/logos/color/ymca-logo.png";
import logo3 from "../../../common/logos/color/middleby-logo.png";

const CASE_STUDIES = [
  {
    image: image1,
    stats: [
      { title: "99%", subTitle: "Faster Searches" },
      { title: "60+", subTitle: "Locations" },
    ],
    quote: (
      <>
        <b>
          The quality and speed of service we get from Rhombus are incredible
        </b>
        . Installation is simple and takes fewer people than before. If there's
        a problem with a camera, I get an alert before I even know there's an
        issue
      </>
    ),
    logo: { src: logo1, height: 32, width: 84 },
  },
  {
    image: image2,
    stats: [
      { title: "50%", subTitle: "shorter investigations" },
      { title: "13", subTitle: "facilities" },
    ],
    quote: (
      <>
        The scalability of the platform has been key as we expand our services,
        and{" "}
        <b>
          the fact that everything is accessible from one platform makes it
          incredibly easy to manage.
        </b>
      </>
    ),
    logo: { src: logo2, height: 32, width: 116 },
  },
  {
    image: image3,
    stats: [
      { title: "80%", subTitle: "time savings" },
      { title: "200+", subTitle: "employees" },
    ],
    quote: (
      <>
        With legacy systems, investigating incidents took me days of manually
        scrolling through footage.{" "}
        <b>With Rhombus, I can do it all in 10 minutes</b>—quickly finding the
        footage and securely sharing clips via text or email.
      </>
    ),
    logo: { src: logo3, height: 22, width: 143 },
  },
];

const CaseStudyContainer = styled(FlexRow)`
  gap: 48px;
  justify-content: center;
  flex-wrap: wrap;
`;

const CaseStudy = styled(FlexColumn)`
  flex-basis: calc(33.3% - 48px);
  min-width: 380px;
  box-shadow: 1px 1px 10px #03193320;
  border-radius: 1rem;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
  .text {
    padding: 24px;
    gap: 48px;
    flex-grow: 1;
    q {
      font-size: 18px;
      line-height: 2;
    }
  }
  .logo {
    object-fit: contain;
    object-position: left;
  }
`;

const HighlightText = styled(FlexColumn)`
  border-left: 1px solid var(--nuetral-900);
  padding-left: 14px;
  gap: 7px;
  h3 {
    font-size: 20px;
  }
  p {
    text-transform: uppercase;
    line-height: 1;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    p {
      font-size: 12px;
    }
  }
`;

const CaseStudiesF = () => {
  return (
    <SectionContainer>
      <SectionInner className="flex-col" style={{ gap: "5rem" }}>
        <SectionTitle>Built for Companies Like Yours</SectionTitle>
        <CaseStudyContainer>
          {CASE_STUDIES.map((c) => (
            <CaseStudy>
              <img src={c.image} />
              <TextContainer className="text">
                <FlexRow style={{ alignItems: "center", gap: "48px" }}>
                  {c.stats.map((s) => (
                    <HighlightText>
                      <h3>{s.title}</h3>
                      <p>{s.subTitle}</p>
                    </HighlightText>
                  ))}
                </FlexRow>
                <q>{c.quote}</q>
                <FlexRow style={{ alignItems: "flex-end", flexGrow: 1 }}>
                  <img
                    src={c.logo.src}
                    className="logo"
                    width={c.logo.width}
                    height={c.logo.height}
                  />
                </FlexRow>
              </TextContainer>
            </CaseStudy>
          ))}
        </CaseStudyContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default CaseStudiesF;
