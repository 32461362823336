import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { styled } from "@linaria/react";
import React from "react";

const LogoRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const LogoWrapper = styled.div`
  transform: scale(0.7);
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    width: calc(100% / 3 - 5rem);
  }
  @media (max-width: 600px) {
    width: calc(50% - 2.5rem);
  }
`;

export default function LogoBladeGSX({ style }) {
  const renderContent = data => {
    const logos = [
      { src: data.logo1, alt: "Rhombus used by Amazon" },
      { src: data.logo2, alt: "Rhombus used by Goodwill" },
      { src: data.logo3, alt: "Rhombus used by Aramark" },
      {
        src: data.logo4,
        alt: "Rhombus used by Mississippi Department of Education",
      },
      { src: data.logo5, alt: "Rhombus used by Stryker" },
      {
        src: data.logo6,
        alt: "Rhombus used by Manheim Township School District",
      },
    ];
    return (
      <SectionContainer style={style}>
        <SectionInner>
          <LogoRow>
            {logos.map(logo => (
              <LogoWrapper key={logo}>
                <GatsbyImage
                  image={getImage(logo.src)}
                  alt={logo.alt}
                  objectFit="contain"
                  loading="eager"
                />
              </LogoWrapper>
            ))}
          </LogoRow>
        </SectionInner>
      </SectionContainer>
    );
  };

  const GET_LOGOS = graphql`
    query {
      logo1: file(
        relativePath: {
          eq: "components/common/logos/slider/black/amazon-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 30
            width: 100
            quality: 100
          )
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/common/logos/slider/black/goodwill-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 149
            height: 25
            quality: 100
          )
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/common/logos/slider/black/aramark-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 172
            height: 49
            quality: 100
          )
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/common/logos/slider/black/mississippi-edu-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 165
            height: 65
            quality: 100
          )
        }
      }
      logo5: file(
        relativePath: {
          eq: "components/common/logos/slider/black/stryker-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 115
            height: 31
            quality: 100
          )
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/slider/black/manheim-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 60
            height: 59
            quality: 100
          )
        }
      }
    }
  `;

  return <StaticQuery render={renderContent} query={GET_LOGOS} />;
}
