import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import Helmet from "react-helmet";
import MarketPlacePage from "components/integrations/marketplace/MarketPlacePage";

export default function Integrations() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Integrations and Open API | Cloud Physical Security System | Rhombus
        </title>
        <meta
          name="description"
          content="Unlock new capabilities with Rhombus API integrations! Build a seamless, customized physical security system by connecting access control, vape sensors, and more to fit your business needs."
        />
      </Helmet>
      <MarketPlacePage />
    </GlobalLayout>
  );
}
