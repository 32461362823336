import { css, cx } from "@linaria/core";

export const disclaimer = css`
  color: var(--body-sublte);
  text-align: center;
  p {
    max-width: 700px;
    width: 100%;
  }
`;

export const cardContainer = css`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 400px;
  width: calc(100% / 3 - 1.75rem);
  background: #f7f9fa;
  border-radius: 1.25rem;
  transition: all 250ms;
  @media (max-width: 1024px) {
    width: calc(100% / 2 - 1.75rem);
  }
  @media (max-width: 820px) {
    width: 100%;
    max-width: 450px;
    min-width: unset;
  }
`;

export const productSectionContainer = css`
  border-bottom: 1px solid var(--nuetral-200);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  padding: 5rem 0;
  align-items: center;
`;

export const cardContainerClass = css`
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  @media (max-width: 820px) {
    justify-content: center;
  }
`;

export const cameraImageClass = css`
  width: 120px;
  pointer-events: none;
`;

export const cameraImageContainer = css`
  background-color: #eee;
  display: flex;
  border-radius: 1.25rem 1.25rem 0 0;
  overflow: hidden;
  align-items: center;
  height: 6rem;
  @media (max-width: 600px) {
    height: 8rem;
  }
`;

export const cameraInfoContainer = css`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 2.5rem;
  .heavy {
    font-weight: 700;
  }
  h2,
  h3 {
    font-weight: 600;
  }
`;

export const tooltipContainer = css`
  display: flex;
`;

export const tooltipClass = css`
  position: relative;
  display: inline-block;
  margin-left: 0.25rem;
  width: 20px;
  button {
    background: transparent;
  }
`;

export const tooltipTextClass = css`
  visibility: hidden;
  width: 215px;
  background-color: var(--nuetral-100);
  border: 1px solid var(--nuetral-300);
  padding: 1.25rem;
  border-radius: 8px;
  z-index: 10;
  position: absolute;
  bottom: 30px;
  left: -43px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: var(--nuetral-200) transparent transparent transparent;
  }

  ${"." + tooltipClass}:hover & {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 1050px) {
    width: 155px;
    padding: 10px 15px;
    left: -13px;
  }
`;

export const typeTooltipText = cx(
  tooltipTextClass,
  css`
    left: -43px;
    bottom: 35px;
    @media (max-width: 1050px) {
      left: -13px;
      bottom: 31px;
    }
  `
);

export const tooltipIconClass = css`
  position: absolute !important; //Needed to override gatsby image styling
  top: 5px;
  width: 9.25px;
`;

export const cameraPriceClass = css`
  font-size: 16px;
`;

export const arrowLinkContainer = css`
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }

  p {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
  }
`;

export const arrowClass = css`
  height: 12px;
  padding-left: 12px;
  padding-right: 6px;
  transition: padding 0.3s ease-in-out;
  ${"." + arrowLinkContainer}:hover & {
    padding-left: 20px;
  }
`;

export const lineClass = css`
  display: flex;
  justify-content: space-between;
`;

export const cameraDaysClass = css`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const licenseTypeContainerClass = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.25rem;
`;

export const licenseTypeContainerClassAccessControl = css`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const yearsContainerClass = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  h3 {
    font-size: 12px;
  }
`;

export const licenseTypeButtonsClass = css`
  display: flex;
  gap: 0.5rem;
`;

export const typeButtonClass = css`
  padding: 0px 20px;
  border-radius: 73px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 500ms ease;
  line-height: 2;
  font-size: 16px;
  text-transform: capitalize;

  &:hover {
    text-decoration: none;
  }
`;

export const buttonSelectedClass = css`
  border: solid 1px var(--primary-bg);
  color: var(--primary-bg);
  background-color: var(--secondary-bg);
  &:hover {
    background-color: var(--secondary-bg);
  }
`;
export const buttonUnselectedClass = css`
  border: solid 1px var(--nuetral-300);
  color: var(--nuetral-300);
  background-color: var(--nuetral-100);
  &:hover {
    background-color: var(--secondary-bg);
    border: solid 1px var(--primary-bg);
    color: var(--primary-bg);
  }
`;
export const typeButtonSelectedClass = cx(typeButtonClass, buttonSelectedClass);

export const typeButtonUnselectedClass = cx(
  typeButtonClass,
  buttonUnselectedClass
);

export const yearsButtonsClass = css`
  display: flex;
  gap: 0.5rem;
`;

export const circleButtonClass = css`
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 500ms ease;
  font-size: 16px;
  height: 33px;
  aspect-ratio: 1;
  outline: none;
  &:hover {
    text-decoration: none;
  }
`;

export const circleButtonSelectedClass = cx(
  circleButtonClass,
  buttonSelectedClass
);

export const circleButtonUnselectedClass = cx(
  circleButtonClass,
  buttonUnselectedClass
);

export const smallFontClass = css`
  font-size: 10px;
  color: var(--body-subtle);
`;

export const qtyInput = css`
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border-radius: 5px;
  font-size: 16px;
  padding: 0;
  font-weight: 400;
  aspect-ratio: 1;
  outline: none;
  border: 1px solid var(--nuetral-300);
  color: var(--nuetral-300);
  transition: all 250ms;
  text-align: center;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus,
  :focus-visible,
  :hover {
    border: solid 1px var(--primary);
  }
`;

export const errorClass = cx(
  qtyInput,
  css`
    border: 1px solid red;
    :focus,
    :focus-visible,
    :hover {
      border: solid 1px red;
    }
  `
);

export const controllerQty = css`
  font-size: 16px;
  width: 30px;
  aspect-ratio: 1;
  text-align: center;
`;

export const costTitle = css`
  font-weight: 400;
`;

export const resolutionText = css`
  color: var(--body-subtle);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 5px;
`;
