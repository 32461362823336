import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { styled } from "@linaria/react";

export const WebinarHeaderContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  min-height: calc(90vh - 5rem);
  padding-bottom: ${props => (props.centerImage ? "5rem" : 0)};
  @media (max-width: 1024px) {
    min-height: 750px;
  }
  @media (max-width: 820px) {
    padding-bottom: 5rem;
  }

  @media (max-width: 600px) {
    padding: 0;
    padding-top: 5rem;
  }
`;

export const WebinarHeaderInner = styled(SectionInner)``;

export const WebinarHeaderSectionLeft = styled(SectionCol)`
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
`;

export const WebinarHeaderTextContainer = styled(TextContainer)`
  @media (max-width: 600px) {
    padding: 0 1.25rem;
  }
`;

export const WebinarHeaderImageWrapper = styled.div`
  display: flex;
  align-items: ${props => (props.centerImage ? "center" : "flex-end")};
  justify-content: center;
  flex-grow: 1;
  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: auto;
  }
  @media (max-width: 820px) {
    width: 90%;
    margin-bottom: -5rem;
  }
`;

export const WebinarHeaderSectionRight = styled(SectionCol)`
  justify-content: flex-end;
`;

export const LearnLine = styled.li`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  svg {
    margin-top: 5px;
  }
`;

export const SpeakerSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  gap: 2.5rem;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const SpeakersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const SpeakerItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  .image-wrapper {
    img {
      height: 95px;
      aspect-ratio: 1;
      border-radius: 50%;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const AboutImage = styled.img`
  width: 100%;
`;
