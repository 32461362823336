import IconAlert from "components/common/icon-components/IconAlert";
import IconBoundingBox from "components/common/icon-components/IconBoundingBox";
import IconCloudSecurity from "components/common/icon-components/IconCloudSecurity";
import {
  FlexCenter,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "@linaria/react";
import React from "react";
import { Body } from "components/rui/typography";

const BenefitsSectionInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const Column = styled(FlexCenter)`
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BenfitsCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 1024px) {
    max-width: 560px;
  }
`;
const Benefit = styled.div`
  display: flex;
  gap: 1rem;
`;

const BenefitTitle = styled.h4`
  color: var(--primary-bg);
  font-size: 1.375rem;
  font-style: normal;
  line-height: 1.875rem;
  letter-spacing: -0.01375rem;
  font-weight: 600;
`;

const benefits = [
  {
    Icon: IconCloudSecurity,
    title: "Eliminate single points of failure",
    description:
      "Say goodbye to NVR/DVR failures. Our distributed cloud architecture ensures your security system remains operational even if individual components fail.",
  },
  {
    Icon: IconBoundingBox,
    title: "Pinpoint critical footage instantly",
    description:
      "Locate specific incidents, objects, or individuals in seconds with AI-powered video search. Respond to security events faster with intelligent, efficient video management.",
  },
  {
    Icon: IconAlert,
    title: "Automate incident response processes",
    description:
      "Receive smart alerts for critical events in real-time. Our system analyzes video feeds to detect anomalies, allowing your team to respond swiftly and effectively to potential threats.",
  },
];

const BenefitsSection2 = () => {
  return (
    <SectionContainer>
      <BenefitsSectionInner>
        <Column>
          <BenfitsCol>
            {benefits.map((benefit) => {
              const { Icon, title, description } = benefit;
              return (
                <Benefit>
                  <Icon />
                  <TextContainer>
                    <BenefitTitle>{title}</BenefitTitle>
                    <Body>{description}</Body>
                  </TextContainer>
                </Benefit>
              );
            })}
          </BenfitsCol>
        </Column>
        <Column>
          <StaticImage
            src="./assets/am-agent.png"
            alt="Rhombus Alarm Monitoring"
            quality={100}
            loading="lazy"
            objectFit="contain"
            placeholder="blurred"
            style={{ maxWidth: "560px" }}
          />
        </Column>
      </BenefitsSectionInner>
    </SectionContainer>
  );
};

export default BenefitsSection2;
