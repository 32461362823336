import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import CameraOverviewHero from "components/cameras/overview/CameraOverviewHero";
import { css } from "@linaria/core";
import AccordionSection from "components/common/AccordionSection";
import CameraOveriewSpecs from "components/cameras/overview/CameraOveriewSpecs";
import IconAiAnalaytics from "components/common/icon-components/IconAiAnalaytics";
import IconWarranty from "components/common/icon-components/IconWarranty";
import IconSensors4 from "components/common/icon-components/IconSensors4";
import IconGrid from "components/common/icon-components/IconGrid";
import IconWeather from "components/common/icon-components/IconWeather";
import IconVision from "components/common/icon-components/IconVision";
import DeviceCards from "components/common/DeviceCards";
import FAQSection from "components/common/FAQSection";
import CTABanner from "components/common/footer-banners/CTABanner";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";

export default function MultisensorCameras() {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-podium-render.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc1: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-outdoors-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc2: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-corner-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc3: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-indoors-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc4: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-weather-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      spec1: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-spec-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      spec2: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-spec-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      spec3: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-spec-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      spec4: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-spec-4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend1: file(
        relativePath: { eq: "components/cameras/img/dome-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend2: file(
        relativePath: { eq: "components/cameras/img/fisheye-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend3: file(
        relativePath: { eq: "components/cameras/img/bullet-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      faqImage: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-faq-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);
  const imageProps = {
    imgStyles: {
      borderRadius: "12px",
      width: "669px",
      maxWidth: "calc(100% - 2.5rem)",
      maxHeight: "416px",
    },
  };
  return (
    <GlobalLayout color="var(--primary-bg)" dark>
      <Helmet>
        <title>
          20MP Multisensor Cameras for Large Spaces | Try for Free | Rhombus
        </title>
        <meta
          name="description"
          content="Take a demo to witness our 20MP multisensor security cameras in action. Get high-resolution video with four sensors for AI-driven search and vehicle detection."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-Multisensor-min.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-Multisensor-min.png"
        />
      </Helmet>
      <CameraOverviewHero
        image={data.heroImage}
        imageAlt="Rhombus multisensor security camera displayed on a podium."
        imageClass={css`
          width: 626px;
          height: 570px;
          @media (max-width: 600px) {
            height: 370px;
          }
        `}
        preTitle="Multisensor cameras"
        title="Complete coverage across expansive spaces with multisensor cameras"
        description="Eliminate blind spots with easy-to-install Rhombus multisensor cameras. Achieve max coverage with wide field of view and 4 adjustable sensors. Packed with high-resolution video and images, leverage independent sensors for AI-based search and vehicle detection."
        primaryButton={{
          title: CTA_BUTTON_COPY_3,
          path: "/free-trial/",
        }}
        secondaryButton={{
          title: "View R600",
          path: "/cameras/multisensor-cameras/r600",
        }}
      />
      <AccordionSection
        color="var(--nuetral-100)"
        fancy={false}
        data={{
          title:
            "Uncompromised visibility with one powerful multisensor camera",
          items: [
            {
              ...imageProps,
              img: data.acc1,
              alt: "Rhombus multisensor camera with multiple sensors covering large spaces with no blind spots",
              title: "Comprehensive coverage",
              p: "Cover your largest spaces and eliminate blind spots with four independent sensors packed into a single device. Unlock detailed insights easily across expansive areas such as public spaces, intersections, school yards, manufacturing facilities, and warehouses.",
            },
            {
              ...imageProps,
              img: data.acc2,
              alt: "Multi sensor security cameras  installed in parking lots to improve safety and emergency responses",
              title: "Save time and stay ahead of threats",
              p: "Instantly find footage when you need it. Leverage AI analytics, event-based search, and configurable alerts to improve safety and security. Enhance emergency response and safety by pairing insights from multisensor cameras with Rhombus Alarm Monitoring.",
            },
            {
              ...imageProps,
              img: data.acc3,
              alt: "Multisensor security cameras installed in a large space to scale security operations effectively",
              title: "Scale quickly and efficiently",
              p: "Save time and costs on deployment with an easy installation. Simplify deployment with fewer cameras and cable drops. Rhombus multisensor camera installation is quickly completed with a single PoE++ port, helping you efficiently scale security operations.",
            },
            {
              ...imageProps,
              img: data.acc4,
              alt: "Multisensor security cameras installed on buildings operate in the harshest weather conditions, monitoring large areas",
              title: "Protect spaces in all weather conditions",
              p: "Stay protected in extreme weather with an IP66 rating, providing ingress protection against solid and water particles. Minimize vandalism with an IK10 rating for impact protection.",
            },
          ],
        }}
      />
      <CameraOveriewSpecs
        videoSrc="https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/r600-launch-trimmed.webm"
        title="Multisensor Security Camera Specs"
        subTitle="See your largest spaces clearly"
        images={[data.spec1, data.spec2, data.spec3, data.spec4]}
        specs={[
          {
            icon: <IconSensors4 />,
            text: "Four sensors with advanced optical zoom",
          },
          {
            icon: <IconGrid />,
            text: "5MP varifocal sensors for superior image clarity",
          },
          {
            icon: <IconAiAnalaytics height="25" width="23" />,
            text: "AI Analytics included onboard for actionable insights ",
          },
          {
            icon: <IconWeather />,
            text: "IP66 weather resistance and IK10 impact resistance",
          },
          {
            icon: <IconVision height="25" width="23" />,
            text: "Exceptional low light performance",
          },
          {
            icon: <IconWarranty height="25" width="23" />,
            text: "Backed by a 10 year warranty",
          },
        ]}
      />

      <FAQSection
        color="var(--nuetral-100)"
        image={data.faqImage}
        reverse
        title="Multisensor Camera FAQs"
        imageAlt="Rhombus R600 multisensor security camera mounted on a wall for wide area surveillance"
        QA={[
          {
            question:
              "What are the benefits of multisensor cameras versus fisheye cameras?",
            answer:
              "Multisensor camera models are ideal for monitoring a large environment, like outside of buildings, public spaces, manufacturing and warehousing facilities, and schoolyards. Multisensor cameras provide multiple independent sensors that can capture a high level of detail and have a better field of view than a fisheye camera, which is important for AI analytics. Fisheye cameras provide 360-degree views but can require de-warping to properly view the complete field of view. These cameras are ideal for general visibility without the need for AI analytics. Fisheye cameras are commonly installed in key areas of warehouses and retail stores. Both the Rhombus R600 multisensor camera and the Rhombus R360 fisheye camera are NDAA compliant.",
          },
          {
            question:
              "Where would I gain the most value from a 20 MP camera like the R600 multisensor camera?",
            answer:
              "The R600 multisensor camera is best deployed in expansive locations like public spaces, on the corners of buildings, school yards, parking lots, manufacturing floors, and warehouse facilities to provide max coverage. The 20 MP resolution is split across four sensor modules, allowing for clear images with better and more expansive viewing",
          },
          {
            question:
              "What AI camera features are available in the R600 multisensor camera?",
            answer:
              "The R600 multisensor camera comes with out-of-the-box AI camera features to help you stay one step ahead of threats. Vehicle recognition, license plate recognition, facial recognition, person of interest, color search, audio analytics, and unusual behavior detection are available on the multisensor camera. These features enhance situational awareness by providing advanced multisensor technology and comprehensive coverage, improving operational insight and reducing overlooked areas. The R600 offers advanced video security solutions with AI-powered video analytics, enhancing the capability to monitor and respond to critical events.",
          },
          {
            question:
              "What type of storage is available for the R600 multisensor camera?",
            answer:
              "The R600 multisensor camera has one solid-state drive (SSD) onboard. There is an option for 1TB with 40 days of storage or 2TB with 90 days of storage. There is also an option for cloud storage. To learn more, contact sales@rhombus.com.",
          },
        ]}
      />
      <DeviceCards
        data={{
          cards: [
            {
              image: data.recommend1,
              altText:
                "Dome business security cameras mounted on office buildings provide commercial surveillance with video footage that keep businesses safe",
              name: "Dome Cameras",
              description:
                "Modern enterprise performance with edge-based analytics ",
              link: "/cameras/dome-cameras/",
              flex: 3,
            },
            {
              image: data.recommend2,
              altText:
                "Fisheye business security camera system offers 360-degree coverage and zero blind spots for day to day operations ",
              name: "Fisheye Cameras",
              description:
                "Immersive coverage and visibility with minimal camera placement",
              link: "/cameras/fisheye-cameras/",
              flex: 3,
            },
            {
              image: data.recommend3,
              altText:
                "Surveillance at commercial properties with bullet commercial security camera systems that can cover extensive distances",
              name: "Bullet Cameras",
              description: "Ruggedized form-factor with high-resolution video",
              link: "/cameras/bullet-cameras/",
              flex: 3,
            },
          ],
        }}
        recommend
        deviceType="Security Camera"
      />
      <CTABanner />
    </GlobalLayout>
  );
}
