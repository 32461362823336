import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";

const imageStyles = css`
  width: 100% !important;
`;

const BladeInner = styled(SectionInner)`
  flex-direction: row;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;
const BladeBlurb = styled(MainParagraph)`
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const Text = styled(TextContainer)`
  gap: 1.6rem;
  justify-content: center;
  @media (max-width: 820px) {
    align-items: center;
    text-align: center;
    max-width: unset;
  }
`;

const AccessControlBanner = ({ color = "var(--gradient-light)" }) => {
  return (
    <SectionContainer
      style={{
        position: "relative",
        minHeight: "500px",
        background: color,
      }}
    >
      <BladeInner>
        <SectionCol>
          <StaticImage
            src="../../access-control/img/access-control-cta.png"
            className={imageStyles}
            alt="Rhombus access control components include the DC20 door controller alongside DR20 and DR40 door readers. This comprehensive door access control system allows seamless integration for remote management, connects with security cameras, analyzes access patterns, and helps prevent security breaches, enhancing building protection"
            quality={100}
            placeholder="blurred"
            objectFit="contain"
          />
        </SectionCol>
        <SectionCol style={{ zIndex: "2" }} className="col">
          <Text>
            <FancyTitle>See Rhombus Access Control</FancyTitle>
            <BladeBlurb>
              Request a demo with our team today and see how you can transform
              building management in your spaces.
            </BladeBlurb>
            <RhombusButton
              path="/live-demo/"
              type="primary"
              title="Request Demo"
            />
          </Text>
        </SectionCol>
      </BladeInner>
    </SectionContainer>
  );
};

export default AccessControlBanner;
