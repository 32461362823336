import { TextContainer } from "components/rhombus-UI/theme/containers";

import ChipFilters from "components/rhombus-UI/ChipFilters";
import { styled } from "@linaria/react";
import React, { useCallback, useState } from "react";
import { ACCESSORY_CATEGORIES, acModels } from "./constants";
import useIsMobile from "hooks/useIsMobile";
import { MOBILE_MEDIA_QUERY } from "./AccessoriesContainer";
import { NotFoundContainer } from "components/integrations/marketplace/styles/MarketPlace-css";
import AccessoriesSidebar from "./AccessoriesSidebar";
import Adjustments from "components/common/icon-components/Adjustments";
import Illustration from "../../integrations/marketplace/img/not-found.svg";
import { FilterButton } from "components/rhombus-UI/theme/ui-elements";
import CommonModal from "components/common/modals/CommonModal";
import { scrollToTop } from "./util";
import { Body, TitleMed, TitleSmall } from "components/rui/typography";
import { ButtonLink } from "components/rui/buttons";

const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: ${(props) => (props.singleDeviceMode ? "100%" : "80%")};
  align-items: ${(props) => (props.singleDeviceMode ? "center" : "flex-start")};
  gap: 2.5rem;
  min-height: 500px;
  scroll-margin-top: 7.5rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const HardwareCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.singleDeviceMode ? "center" : "flex-start"};

  gap: 2.5rem;
`;

const HardwareCard = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 1.25rem;
  border: 1px solid var(--nuetral-200);
  flex-grow: 1;
  max-width: 505px;
  width: calc((100% - (1 * 2.5rem)) / 2);
  gap: 1.25rem;
  align-items: center;
  p {
    font-size: 16px;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 820px) {
    width: 100%;
    max-width: 100%;
    height: fit-content;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 1.25rem;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 128px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  img {
    object-fit: contain;
    width: 100%;
  }
  @media (max-width: 820px) {
    width: 100px;
  }
`;

const AccessoriesFeed = ({
  accessories,
  title,
  handleFilter,
  selectedModel,
  setSelectedModel,
  singleDeviceMode,
  defaultSelection,
}) => {
  const { isMobile } = useIsMobile(MOBILE_MEDIA_QUERY);
  const [modalOpen, setModalOpen] = useState(false);

  const renderModal = useCallback(() => {
    if (!isMobile || singleDeviceMode) return <></>;
    return (
      <CommonModal
        onClose={() => {
          setModalOpen(false);
          scrollToTop("#feed-container");
        }}
        isOpen={modalOpen}
      >
        <AccessoriesSidebar
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          setModalOpen={setModalOpen}
          defaultSelection={defaultSelection}
        />
      </CommonModal>
    );
  }, [modalOpen, isMobile, singleDeviceMode, selectedModel]);

  return (
    <FeedContainer singleDeviceMode={singleDeviceMode} id="feed-container">
      <TextContainer
        style={
          singleDeviceMode ? { textAlign: "center", alignItems: "center" } : {}
        }
      >
        <TitleRow>
          <TitleMed>{title}</TitleMed>
          {isMobile && !singleDeviceMode && (
            <FilterButton
              aria-label="filter"
              title="filter"
              name="filter"
              onClick={() => setModalOpen(!modalOpen)}
              active={modalOpen}
            >
              <Adjustments />
            </FilterButton>
          )}
          {renderModal()}
        </TitleRow>
        <Body>
          {isMobile ? (
            <>
              Use the filter to select a model and view compatible accessories.
              For more information or to purchase accessories, contact{" "}
              <ButtonLink
                href="mailto:sales@rhombus.com"
                title="sales@rhombus.com"
              >
                sales@rhombus.com
              </ButtonLink>
            </>
          ) : (
            <>
              For more information or to purchase accessories, contact{" "}
              <ButtonLink
                href="mailto:sales@rhombus.com"
                title="sales@rhombus.com"
              >
                sales@rhombus.com
              </ButtonLink>
            </>
          )}
        </Body>
      </TextContainer>
      {!acModels.includes(selectedModel) &&
        selectedModel !== "allAccessControl" && (
          <ChipFilters
            options={ACCESSORY_CATEGORIES}
            handleFilter={handleFilter}
            center={singleDeviceMode}
          />
        )}
      <HardwareCards singleDeviceMode={singleDeviceMode}>
        {accessories.length > 0 ? (
          accessories.map((item) => {
            const { name, image, sku, price, description } =
              item.node.frontmatter;
            return (
              <HardwareCard>
                <ImageWrapper>
                  <img src={image} alt={name} />
                </ImageWrapper>
                <TextContainer style={{ flexGrow: 1, gap: "0" }}>
                  <div className="title-row">
                    <TitleSmall>{name}</TitleSmall>
                    <TitleSmall>${price}</TitleSmall>
                  </div>
                  <Body>{sku}</Body>
                  <Body style={{ marginTop: "0.75rem" }}>{description}</Body>
                </TextContainer>
              </HardwareCard>
            );
          })
        ) : (
          <NotFoundContainer>
            <img src={Illustration} alt="not found" />
            <p>We couldn&apos;t find any accessories matching your filters.</p>
          </NotFoundContainer>
        )}
      </HardwareCards>
    </FeedContainer>
  );
};

export default AccessoriesFeed;
