import React from "react";

const RhombusLogo = () => {
  return (
    <svg
      width="154"
      height="39"
      viewBox="0 0 154 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.8918 15.792H43.419C43.6847 15.792 43.9105 15.9855 43.9477 16.2448L44.2057 18.0422H44.2596C45.2542 16.2145 47.0534 15.6326 48.3701 15.6326C48.5639 15.6326 48.7384 15.639 48.9001 15.6503C49.1773 15.6718 49.3916 15.9008 49.3916 16.1753V18.0675C49.3916 18.3736 49.1273 18.6177 48.818 18.5924C48.6435 18.5785 48.4664 18.5722 48.2893 18.5722C46.4079 18.5722 44.5278 19.684 44.5278 22.4655V29.7246C44.5278 30.0156 44.2891 30.2508 43.9939 30.2508H41.8918C41.5967 30.2508 41.358 30.0156 41.358 29.7246V16.3182C41.358 16.0273 41.5967 15.792 41.8918 15.792V15.792Z"
        fill="white"
      />
      <path
        d="M55.7865 17.4073H55.8404C56.6733 16.2689 58.1786 15.448 59.9239 15.448C63.122 15.448 65.1638 17.2757 65.1638 20.6656V29.7259C65.1638 30.0168 64.9251 30.2521 64.6299 30.2521H62.5278C62.2327 30.2521 61.994 30.0168 61.994 29.7259V21.6193C61.994 19.3944 61.0263 18.3091 59.0384 18.3091C57.2649 18.3091 55.7878 19.3425 55.7878 21.4346V29.7272C55.7878 30.0181 55.5491 30.2534 55.2539 30.2534H53.1518C52.8566 30.2534 52.6179 30.0181 52.6179 29.7272V9.56501C52.6179 9.27409 52.8566 9.03882 53.1518 9.03882H55.2539C55.5491 9.03882 55.7878 9.27409 55.7878 9.56501V17.4073H55.7865Z"
        fill="white"
      />
      <path
        d="M67.9589 23.0208C67.9589 18.6241 71.2096 15.4467 75.4292 15.4467C79.6488 15.4467 82.8725 18.6241 82.8725 23.0208C82.8725 27.4175 79.6758 30.5949 75.4292 30.5949C71.1827 30.5949 67.9589 27.4175 67.9589 23.0208ZM79.6745 23.0208C79.6745 20.0281 77.6057 18.3066 75.4292 18.3066C73.2527 18.3066 71.157 20.0281 71.157 23.0208C71.157 26.0135 73.2527 27.735 75.4292 27.735C77.6057 27.735 79.6745 26.0135 79.6745 23.0208Z"
        fill="white"
      />
      <path
        d="M105.79 20.6378V29.7246C105.79 30.0156 105.552 30.2508 105.256 30.2508H103.154C102.859 30.2508 102.62 30.0156 102.62 29.7246V21.1943C102.62 19.34 101.895 18.361 100.149 18.361C98.4022 18.361 97.4345 19.6853 97.4345 21.1412V29.7246C97.4345 30.0156 97.1958 30.2508 96.9007 30.2508H94.7986C94.5034 30.2508 94.2647 30.0156 94.2647 29.7246V21.1943C94.2647 19.34 93.5396 18.361 91.793 18.361C90.0464 18.361 89.1057 19.765 89.1057 21.3006V29.7246C89.1057 30.0156 88.867 30.2508 88.5718 30.2508H86.4697C86.1745 30.2508 85.9359 30.0156 85.9359 29.7246V16.3182C85.9359 16.0273 86.1745 15.792 86.4697 15.792H87.9276C88.1894 15.792 88.4127 15.9792 88.455 16.2334L88.7309 17.8841H88.8118C89.5638 16.5332 90.8805 15.448 92.7889 15.448C94.5393 15.448 95.7264 16.112 96.4938 17.2416C96.6979 17.5413 97.1368 17.5704 97.3614 17.2858C98.2443 16.1614 99.4917 15.448 101.119 15.448C104.317 15.448 105.794 17.5666 105.794 20.639L105.79 20.6378Z"
        fill="white"
      />
      <path
        d="M112.911 16.6331C112.911 17.0948 113.476 17.3427 113.812 17.0227C114.872 16.0134 116.364 15.448 117.855 15.448C121.294 15.448 124.411 18.3875 124.411 23.0221C124.411 27.6566 121.188 30.5961 117.748 30.5961C116.085 30.5961 114.557 29.9296 113.474 28.7671C113.164 28.4357 112.606 28.5976 112.544 29.0429L112.439 29.7967C112.403 30.0573 112.176 30.2508 111.91 30.2508H110.277C109.982 30.2508 109.743 30.0156 109.743 29.7246V9.56501C109.743 9.27409 109.982 9.03882 110.277 9.03882H112.379C112.674 9.03882 112.913 9.27409 112.913 9.56501V16.6331H112.911ZM112.858 23.0208C112.858 25.801 114.658 27.735 117.022 27.735C119.386 27.735 121.213 25.8023 121.213 23.0474C121.213 20.2924 119.386 18.3066 116.995 18.3066C114.604 18.3066 112.858 20.2659 112.858 23.0208V23.0208Z"
        fill="white"
      />
      <path
        d="M127.207 24.5842V16.3182C127.207 16.0273 127.445 15.792 127.74 15.792H129.843C130.138 15.792 130.376 16.0273 130.376 16.3182V24.6107C130.376 26.5966 131.504 27.7363 133.413 27.7363C135.321 27.7363 136.584 26.5979 136.584 24.5842V16.3182C136.584 16.0273 136.823 15.792 137.118 15.792H139.22C139.515 15.792 139.754 16.0273 139.754 16.3182V24.5576C139.754 28.1069 137.336 30.5961 133.413 30.5961C129.49 30.5961 127.207 28.1069 127.207 24.5842V24.5842Z"
        fill="white"
      />
      <path
        d="M153.135 16.9519V18.5494C153.135 18.9466 152.704 19.2072 152.351 19.0174C151.345 18.4748 150.045 18.1219 148.728 18.1219C147.062 18.1219 146.149 18.7569 146.149 19.7637C146.149 20.849 147.25 21.2474 149.104 21.7761C151.414 22.3858 153.994 23.1536 153.994 26.1197C153.994 28.8481 151.817 30.5949 148.19 30.5949C146.335 30.5949 144.598 30.1206 143.279 29.2174C143.138 29.1213 143.058 28.9594 143.058 28.7912V27.0785C143.058 26.651 143.545 26.4069 143.901 26.6497C145.013 27.4074 146.414 27.9475 148.083 27.9475C149.883 27.9475 150.903 27.2063 150.903 26.1463C150.903 24.9548 149.64 24.5576 147.626 23.9479C145.235 23.2598 143.058 22.4124 143.058 19.8434C143.058 17.2744 145.154 15.4467 148.62 15.4467C150.185 15.4467 151.795 15.854 152.878 16.5003C153.037 16.5952 153.134 16.7672 153.134 16.9506L153.135 16.9519Z"
        fill="white"
      />
      <path
        d="M31.067 17.7298L18.1784 5.02537C16.9695 3.83385 15.0099 3.83385 13.801 5.02537L0.911192 17.7298C-0.297708 18.9213 -0.297708 20.8528 0.911192 22.0443L13.801 34.7487C15.0099 35.9403 16.9695 35.9403 18.1784 34.7487L31.0682 22.0443C32.2771 20.8528 32.2771 18.9213 31.0682 17.7298H31.067ZM16.1431 24.5159C13.4263 24.6019 11.2061 22.4137 11.2921 19.7359C11.3704 17.3111 13.3749 15.3354 15.8351 15.2582C18.5519 15.1722 20.7721 17.3617 20.6848 20.0395C20.6065 22.4642 18.6019 24.44 16.1418 24.5171L16.1431 24.5159Z"
        fill="url(#paint0_linear_26554_9729)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_26554_9729"
          x1="7.35609"
          y1="28.3965"
          x2="24.372"
          y2="11.1311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2A7DE1" />
          <stop offset="1" stop-color="#00C1DE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RhombusLogo;
