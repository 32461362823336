import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function M15() {
  const data = useStaticQuery(graphql`
    query {
      m15Header: file(
        relativePath: { eq: "components/sensors/img/m15-header.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      m15HeaderMobile: file(
        relativePath: { eq: "components/sensors/img/m15-hero-mobile-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      m15Dims: file(
        relativePath: { eq: "components/sensors/img/m15-dims.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);

  const sensor = {
    title: "M15 Motion Sensor",
    imageHeader: data.m15Header,
    imageHeaderMobile: data.m15HeaderMobile,
    image: data.m15Dims,
    imageAlt: "Dimensions of Rhombus M15 motion sensor.",
    objectPosition: "center",
    description:
      "Obtain visibility in camera-free areas with advanced motion sensors. Get security insights in spaces where cameras aren't allowed with real-time motion sensing and configurable alerts.",
    pdf: "/files/specs/M15.pdf",
    accessoryCompatible: false,
    column1: [
      {
        title: "Dimensions",
        text: "74.2mm x 38.2mm x 46.9mm (2.92in x 1.5in x 1.85in)",
      },
      {
        title: "Weight",
        text: "85.0g",
      },
      {
        title: "BLE Range",
        text: "Up to 100m (328ft)",
      },
      {
        title: "Battery Life",
        text: "5 years",
      },
      {
        title: "Motion Capabilities",
        text: "Passive infrared",
      },
    ],
    column2: [
      {
        title: "Sensor Coverage",
        text: "Horizontal 120°, Vertical 60°, 12m (39ft) maximum",
      },
      {
        title: "Mounting",
        text: "Two-sided tape (included) or screws (with required mounting bracket)",
      },
      {
        title: "Outdoor Use",
        text: "Used in a covered location; resistant to brief incidental splashes",
      },
      {
        title: "2-Year Warranty Included",
      },
      {
        title: "NDAA, FCC, CE, REACH, RoHS, IC, and RCM Compliant",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>M15 Motion Sensor | Commercial IoT Sensors | Rhombus</title>
        <meta
          name="description"
          content="Gain visibility into unauthorized movements and space occupancy in camera-free areas with the commercial M15 Motion Sensor. With advanced real-time motion sensing capabilities on a cloud-managed physical security platform, get easy and enhanced visibility and insights into your space."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-M15.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-M15.png"
        />
      </Helmet>
      <SensorSpecsPage
        sensor={sensor}
        heroImageAltText="The M15 motion sensor, mounted on a wall, monitors camera less locations and delivers real-time insights through advanced motion sensing technology"
      />
    </GlobalLayout>
  );
}
