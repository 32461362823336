import SlideInContainer from "components/common/SlideInContainer";
import TextLink from "components/common/TextLink";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";

const sectionWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
  padding: 3.2rem 1.6rem;
  padding-bottom: 6.4rem;
  @media (max-width: 768px) {
    gap: 50px;
  }
`;

const innerRow = css`
  display: flex;
  gap: 104px;
  width: 100%;
  max-width: var(--max-width);
  @media (max-width: 768px) {
    flex-direction: column !important;
    gap: 25px;
  }
`;
const innerRowLeft = css`
  width: 50%;
  flex-grow: 1;
  @media (max-width: 768px) {
    height: 50%;
    width: 100%;
  }
`;
const innerRowRight = css`
  width: 50%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 768px) {
    height: 50%;
    width: 100%;
  }
`;
const textWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

import React from "react";
import TextLinkV2 from "components/common/TextLinkV2";
import { Body, TitleMed } from "components/rui/typography";

const IndustryLearnRow = ({ data }) => {
  return (
    <section className={sectionWrapper}>
      {data.map((item, index) => (
        <div
          className={innerRow}
          key={index}
          style={
            (index + 1) % 2 !== 0
              ? { flexDirection: "row" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div className={innerRowLeft}>
            <GatsbyImage image={getImage(item.image)} alt={item.title} />
          </div>
          <div className={innerRowRight}>
            <SlideInContainer>
              <div className={textWrapper}>
                <TitleMed>{item.title}</TitleMed>
                <Body>{item.description}</Body>
                <TextLinkV2 path={item.linkUrl} title={item.linkText} />
              </div>
            </SlideInContainer>
          </div>
        </div>
      ))}
    </section>
  );
};

export default IndustryLearnRow;
