import { useEffect, useMemo, useState } from "react";

export default function useIsMobile(widthThreshold) {
  const [width, setWidth] = useState(0);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    if (typeof window !== "undefined") {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const isMobile = useMemo(() => {
    if (!isClient) {
      return false;
    }
    return width <= widthThreshold;
  }, [width, widthThreshold, isClient]);

  return { isMobile };
}
