import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { styled } from "@linaria/react";
import React from "react";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  @media (max-width: 600px) {
    padding: 5rem 0 0 0;
  }
`;

const TextCol = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 0 1.25rem;
  }
`;

const FormCol = styled(SectionCol)`
  justify-content: flex-end;
`;

const HighlightCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  max-width: 475px;
`;

const Highlight = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  aspect-ratio: 1;
  flex-shrink: 0;
  background: var(--gradient-base);
  border-radius: 50%;
`;

const HighlightText = styled(TitleSmall)`
  font-weight: normal;
`;

const LandingPageHero = ({
  preTitle,
  title,
  blurb,
  formId,
  formTitle,
  formBlurb,
  submitText,
  highlights = null,
}) => {
  const { isMobile } = useIsMobile(820);

  const renderTextWithBoldAmount = (text) => {
    const parts = text.split(/\$[0-9,]+/);
    const amounts = text.match(/\$[0-9,]+/g);

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {amounts && amounts[index] ? <strong>{amounts[index]}</strong> : null}
      </React.Fragment>
    ));
  };

  return (
    <HeroContainer>
      <SectionInner>
        <TextCol>
          <TextContainer>
            <PreTitle>{preTitle}</PreTitle>
            <FancyPageTitle>{title}</FancyPageTitle>
            <MainParagraph>{blurb}</MainParagraph>
          </TextContainer>
          {highlights && (
            <HighlightCol>
              {highlights.map((item) => (
                <Highlight>
                  <IconCircle>{item.icon()}</IconCircle>
                  <HighlightText>
                    {renderTextWithBoldAmount(item.text)}
                  </HighlightText>
                </Highlight>
              ))}
            </HighlightCol>
          )}
        </TextCol>
        <FormCol>
          <FormWrapper width={isMobile ? "100%" : "550px"} title={formTitle}>
            <MainParagraph>{formBlurb}</MainParagraph>
            <RhombusForm
              formId={formId}
              customSubmitText={submitText}
              useFormExpansion
            />
          </FormWrapper>
        </FormCol>
      </SectionInner>
    </HeroContainer>
  );
};

export default LandingPageHero;
