import React from "react";
import {
  HeroRight,
  HeroLeft,
  WebinarsHeroContainer,
  WebinarsHeroInner,
} from "./styles/webinarsPageStyles";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import { toTitleCase } from "components/common/util/helperFunctions";
import FeaturedWebinar from "./FeaturedWebinar";
import { css } from "@linaria/core";
import { HeroTitle, PreTitle } from "components/rui/typography";

const WebinarsHero = () => {
  return (
    <WebinarsHeroContainer>
      <WebinarsHeroInner>
        <HeroLeft>
          <TextContainer width="665px">
            <PreTitle color="var(--primary-bg)">webinars</PreTitle>
            <HeroTitle color="var(--primary-bg)">
              {toTitleCase(
                "Learn more through live interactions and on-demand content"
              )}
            </HeroTitle>
          </TextContainer>
        </HeroLeft>
        <HeroRight>
          <FeaturedWebinar />
        </HeroRight>
      </WebinarsHeroInner>
    </WebinarsHeroContainer>
  );
};

export default WebinarsHero;
