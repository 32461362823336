import React from "react";
import { css } from "@linaria/core";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

const slideContainer = css`
  height: 358px;
  max-width: 329px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 42px 21px 33px;
  line-height: 2;
  margin-bottom: 39px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: grab;

  @media (max-width: 1275px) {
    padding: 32px 15px 33px;
  }
  @media (max-width: 1200px) {
    margin-left: 15px;
  }

  q {
    display: block;
    font-weight: bold;
  }

  cite {
    font-weight: bold;
    font-style: normal;
    line-height: 1.5;
  }
`;

const bottomSectionClass = css`
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 15px;
`;

const imageClass = css`
  width: 75px;
  height: 75px;
`;

const g2Class = css`
  display: flex;
  flex-direction: column;
`;

const starsImageClass = css`
  max-width: 142px;
`;

const g2LogoGroupClass = css`
  display: flex;
  column-gap: 5px;
`;

const g2LogoImageClass = css`
  width: 24px;
  height: 25px;
`;

export default function G2Slide({ slide }) {
  const imageRef = getImage(slide.image);

  return (
    <div className={slideContainer}>
      <div>
        <q>{slide.quote}</q>

        <cite>{slide.cite}</cite>
        {Array.isArray(slide.paragraph) ? (
          slide.paragraph.map(line => <p>{line}</p>)
        ) : (
          <p>{slide.paragraph}</p>
        )}
      </div>

      <div>
        <cite>
          <strong>{slide.reviewer}</strong>
        </cite>
        <div>{slide.title}</div>
        <div className={bottomSectionClass}>
          <GatsbyImage
            image={imageRef}
            alt={`${slide.company} logo`}
            className={imageClass}
            objectFit="contain"
          />
          <div className={g2Class}>
            <StaticImage
              src="./img/slides/stars.png"
              alt="five stars"
              className={starsImageClass}
            />
            <div className={g2LogoGroupClass}>
              <p>Posted on</p>
              <StaticImage
                src="./img/slides/g2-logo.png"
                alt="g2 logo"
                className={g2LogoImageClass}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
