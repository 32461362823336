import React from "react";

const IconCheckMark = ({
  width = "14",
  height = "10",
  color = "currentColor",
  stroke = "2.3",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 10"
      fill="none"
    >
      <path
        d="M1.92188 4.28479L5.39775 7.99354L12.0476 1.39062"
        stroke={color}
        stroke-width={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconCheckMark;
