import { styled } from "@linaria/react";
import React, { useEffect, useMemo, useState } from "react";
import check from "components/common/icons/form-check.svg";

const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCheckBox = styled.input`
  appearance: none;
  height: 25px;
  width: 25px;
  background-color: var(--white);
  border: 1px solid var(--nuetral-200);
  border-radius: 4px;
  display: grid;
  place-content: center;
  transition: all 250ms;
  cursor: pointer;
  flex-shrink: 0;
  ::before {
    content: "";
    width: 16px;
    height: 16px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--primary);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :checked::before {
    transform: scale(1);
    content: url(${check});
  }
  :hover,
  :checked {
    border-color: var(--primary);
  }
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 16px;

  label {
    flex-grow: 1;
    cursor: pointer;
    color: var(--nuetral-400);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    a {
      font-size: 16px;
      text-decoration: none;
      color: var(--primary);
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const BooleanCheckBox = ({ field, formState, setFormState, darkMode }) => {
  const [checked, setChecked] = useState(false);

  const controlledValue = useMemo(() => {
    return checked ? "Yes" : "No";
  }, [checked]);

  useEffect(() => {
    setFormState({
      ...formState,
      [field.name]: {
        objectTypeId: field.objectTypeId,
        value: controlledValue,
      },
    });
  }, [controlledValue]);

  return (
    <FieldSet>
      <Option>
        <StyledCheckBox
          type="checkbox"
          name={field.name}
          id={field.name}
          value={controlledValue}
          checked={checked}
          onChange={() => setChecked(!checked)}
          required={field.required}
        />
        <label
          htmlFor={field.name}
          dangerouslySetInnerHTML={{ __html: field.label }}
        />
      </Option>
    </FieldSet>
  );
};

export default BooleanCheckBox;
