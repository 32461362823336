import React, { useState } from "react";
import {
  LiveWebinarTag,
  WebinarCard,
  WebinarFeed,
  WebinarFeedContainer,
} from "./styles/webinarsPageStyles";
import { formatDateTrimmed } from "components/webinar/util/webinarFunctions";
import TextLinkV2 from "components/common/TextLinkV2";
import { Link } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import Adjustments from "components/common/icon-components/Adjustments";
import WebinarSidebar from "./WebinarSidebar";
import NotFound from "components/common/NotFound";
import { FilterButton } from "components/rhombus-UI/theme/ui-elements";
import CommonModal from "components/common/modals/CommonModal";
import { scrollToTop } from "components/accessories/v2/util";
import { PreTitle, TitleMed } from "components/rui/typography";

const WebinarsFeed = ({ webinars, topics, setActiveFilter, activeFilter }) => {
  const { isMobile } = useIsMobile(1024);
  const [modalOpen, setModalOpen] = useState(false);

  const renderModal = () => {
    if (!isMobile) return <></>;
    return (
      <CommonModal
        onClose={() => {
          setModalOpen(false);
          scrollToTop("#webinars-feed");
        }}
        isOpen={modalOpen}
      >
        <WebinarSidebar
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          topics={topics}
          setModalOpen={setModalOpen}
        />
      </CommonModal>
    );
  };

  return (
    <WebinarFeedContainer>
      <div
        style={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <TitleMed style={{ fontSize: "32px" }}>Explore Webinars</TitleMed>
        {isMobile && (
          <FilterButton
            aria-label="filter"
            title="filter"
            name="filter"
            onClick={() => setModalOpen(!modalOpen)}
            active={modalOpen}
          >
            <Adjustments height={20} width={20} />
          </FilterButton>
        )}
        {renderModal()}
      </div>
      <WebinarFeed>
        {webinars.length > 0 ? (
          webinars.map((webinar) => {
            const type = webinar.node.frontmatter.type;
            const linkText =
              type === "Live Webinar" ? "Register Now" : "Watch Now";
            const slug = webinar.node.fields.slug;
            const startDate = !!webinar.node.frontmatter.dateInfo
              ? webinar.node.frontmatter.dateInfo.startDate
              : webinar.node.frontmatter.startDate;

            const title =
              webinar.node.frontmatter?.cardTitle ??
              webinar.node.frontmatter.title;
            return (
              <WebinarCard key={webinar.node.id} as={Link} to={slug}>
                <div className="image-wrapper">
                  <img
                    src={webinar.node.frontmatter.cardImage}
                    alt={webinar.node.frontmatter.title}
                  />
                </div>
                <div className="card-header">
                  {type === "Live Webinar" ? (
                    <LiveWebinarTag>{type}</LiveWebinarTag>
                  ) : (
                    <PreTitle color="var(--primary-bg)">{type}</PreTitle>
                  )}
                  {!!startDate && type === "Live Webinar" && (
                    <PreTitle color="var(--primary-bg)">
                      {formatDateTrimmed(startDate)}
                    </PreTitle>
                  )}
                </div>
                <div className="card-body">
                  <TitleMed style={{ fontSize: "24px" }}>{title}</TitleMed>
                </div>
                <div className="card-footer">
                  <TextLinkV2 title={linkText} path={slug} />
                </div>
              </WebinarCard>
            );
          })
        ) : (
          <NotFound
            errorMessage={`There are no ${activeFilter}s scheduled at this time. Check back regularly for updates`}
          />
        )}
      </WebinarFeed>
    </WebinarFeedContainer>
  );
};

export default WebinarsFeed;
