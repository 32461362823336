import { styled } from "@linaria/react";
import FadeInYAxis from "components/common/animated-sections/FadeInYAxis";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import {
  Body,
  SectionTitle,
  PreTitle,
  TitleSmall,
} from "components/rui/typography";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const ComplianceSectionContainer = styled(SectionContainer)`
  background-color: var(--nuetral-100);
  background-image: url(./assets/matrix-bg.svg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @media (max-width: 1024px) {
    background-size: contain;
  }
`;

const ComplianceSectionInner = styled(SectionInner)`
  flex-direction: column;
  align-items: center;
`;

const ComplianceTitleSection = styled(TextContainer)`
  align-items: center;
  text-align: center;
  max-width: 887px;
`;

const InfoRow = styled.div`
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
`;

const InfoBox = styled.div`
  width: calc((100% / 4) - (((4 - 1) / 4) * 2.5rem));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  text-align: center;
  h3,
  p {
    max-width: 322px;
  }
  &:nth-child(2) h3 {
    max-width: 200px;
  }
  @media (max-width: 1024px) {
    width: calc((100% / 2) - (((2 - 1) / 2) * 2.5rem));
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const ComplianceSection = ({
  preTitle = "COMPLIANCE OVERVIEW",
  title = "Effortlessly Stay Up to Date with Best Practices and Regulations",
  blurb = "Rhombus adheres to strict cybersecurity and data privacy regulations, ensuring that your system is protected to the fullest extent of modern technology and industry best practices.",
}) => {
  const imageData = useStaticQuery(graphql`
    query {
      NDAA: file(
        relativePath: { eq: "components/compliance/assets/NDAA-navy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            width: 70
            height: 70
          )
        }
      }
      TAA: file(
        relativePath: { eq: "components/compliance/assets/TAA-navy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            width: 70
            height: 70
          )
        }
      }
      SOC: file(
        relativePath: { eq: "components/compliance/assets/SOC-navy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            width: 70
            height: 70
          )
        }
      }
      GDPR: file(
        relativePath: { eq: "components/compliance/assets/GDPR-navy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            width: 70
            height: 70
          )
        }
      }
    }
  `);

  const infoBoxes = [
    {
      image: imageData.NDAA,
      title: "National Defense Authorization Act (NDAA)",
      content:
        "All hardware procured from white-listed manufacturers for cybersecurity purposes.",
    },
    {
      image: imageData.TAA,
      title: "Trade Agreements Act (TAA)",
      content:
        "Hardware sourced exclusively from white-listed countries for cybersecurity purposes.",
    },
    {
      image: imageData.SOC,
      title: "System and Organization Controls 2 (SOC 2)",
      content:
        "Security compliance and adherence to SOC2 standards to ensure data security.",
    },
    {
      image: imageData.GDPR,
      title: "General Data Protection Protection Regulation (GDPR)",
      content: "Stay in full compliance with GDPR data processing agreements.",
    },
  ];

  return (
    <ComplianceSectionContainer id="compliance">
      <ComplianceSectionInner>
        <FadeInYAxis>
          <ComplianceTitleSection>
            {!!preTitle && <PreTitle>{preTitle}</PreTitle>}
            <SectionTitle>{title}</SectionTitle>
            <Body>{blurb}</Body>
          </ComplianceTitleSection>
        </FadeInYAxis>
        <FadeInYAxis>
          <InfoRow>
            {infoBoxes.map((item) => (
              <InfoBox>
                <GatsbyImage
                  image={getImage(item.image)}
                  style={{ userSelect: "none" }}
                />
                <TitleSmall>{item.title}</TitleSmall>
                <Body>{item.content}</Body>
              </InfoBox>
            ))}
          </InfoRow>
        </FadeInYAxis>
      </ComplianceSectionInner>
    </ComplianceSectionContainer>
  );
};

export default ComplianceSection;
