import React from "react";
export const STORIES = [
  {
    industry: "TECHNOLOGY",
    logoKey: "luxerOneLogo",
    stat: { title: "11,000+", subTitle: "Locations" },
    qoute: (
      <>
        Using Rhombus has led to <b>significant cost savings</b>, we've
        estimated that to be <b>$250,000.</b>
      </>
    ),
    link: "/case-studies/luxer-one/",
  },
  {
    industry: "EDUCATION",
    logoKey: "middleBuryLogo",
    stat: { title: "80%", subTitle: "Decrease in System Outages" },
    qoute: (
      <>
        Since integrating Rhombus, we've achieved a{" "}
        <b>30% decrease in student vaping incidents</b>
      </>
    ),
    link: "/case-studies/middlebury-community-schools/",
  },
  {
    industry: "Housing Authority",
    logoKey: "smhaLogo",
    stat: { title: "14", subTitle: "Counties served" },
    qoute: (
      <>
        <b>Rhombus has transformed our approach towards security.</b> It's made
        everything more efficient, from monitoring to response, while giving us
        the ease we need to protect residents and staff.
      </>
    ),
    link: "/case-studies/south-mississippi-housing-authority/",
  },
];
export const STANCE_VIDEO_URL =
  "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/stance-case-study-video.webm";
