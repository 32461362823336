import ITPageTemplate from "components/ITPage/ITPageTemplate";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function ITPage() {
  const renderContent = (data) => {
    const pageContent = {
      headerSection: {
        preTitle: "INFORMATION TECHNOLOGY",
        title: "Streamline Your Physical Security Tech Stack",
        description:
          "Rhombus helps IT Teams of all sizes manage physical security more efficiently. Centralize your technologies with an all-in-one platform that makes it easy to create custom functionality and streamline security operations.",
        image: data.headerImage,
        button1: { text: "Request Demo", path: null },
      },
      logos: [
        {
          img: data.logo1,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo2,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Construction",
        },
        {
          img: data.logo4,
          width: "186px ",
          alt: "Los Angeles Film School",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo3,
          width: "115px",
          alt: "Stryker",
        },
      ],
      benefitRows: [
        {
          preTitle: "MODERNIZE AND CENTRALIZE",
          title: "Unify Your Security on a Flexible, Future-Proof Platform",
          description:
            "Meet your central nervous system for physical security management. With native remote access on a unified cloud platform, it’s easy to manage unlimited users, devices, locations, and solutions at scale.",
          specs: [
            { title: "#1", description: "in Physical Security on G2" },
            {
              title: "99.9%",
              description: "uptime for unlimited devices and locations",
            },
          ],
          image: data.benefitImage1,
        },
        {
          preTitle: "STREAMLINE SECURITY MANAGEMENT",
          title: "Save Time at Every Touchpoint",
          description:
            "Security management has never been more stress-free. Save time and resources with plug-and-play deployment, user-friendly tools for non-IT colleagues, and automatic firmware updates that keep your tech up to date with no manual work.",
          specs: [
            { title: "18x", description: "faster incident resolution*" },
            {
              title: "150%",
              description: "productivity increase for all system users*",
            },
          ],
          note: "*Rhombus case studies and customer surveys",
          image: data.benefitImage2,
        },
        {
          preTitle: "LONG-TERM PARTNERSHIP",
          title: "Partner With a Team That Cares",
          description:
            "Experience best-in-class partnership and support throughout your entire lifetime with Rhombus. Work with a dedicated point of contact and in-house team that provides hands-on support, from end-user training to building custom solutions from scratch.",
          specs: [
            { title: "99%", description: "renewal rate, industry record" },
            {
              title: "24/7",
              description: "in-house support with automatic ticket creation",
            },
          ],
          image: data.benefitImage3,
        },
      ],
      infoSlider: {
        slides: [
          {
            tabTitle: "Open API",
            preTitle: "Your Security, Your Way",
            title: "Open API",
            description:
              "Enjoy the freedom to customize your system to your organization’s unique needs. Leverage Rhombus’ open API to build custom functionality and set up powerful integrations with your favorite third-party systems.",
            image: data.infoSlide1,
          },
          {
            tabTitle: "Resource-Efficient",
            preTitle: "Conserve TEAM RESOURCES",
            title: "Resource-Efficient",
            description:
              "Conserve resources and control usage at scale. Rhombus uses 50% less bandwidth and provides 10x better latency compared to other cloud vendors, so you can deploy reliable cloud security in any environment.",
            image: data.infoSlide2,
          },
          {
            tabTitle: "Secure-by-Default",
            preTitle: "Cybersecurity & Data Privacy",
            title: "Secure-By-Default",
            description:
              "Effortlessly protect your data on a zero-trust platform founded by cybersecurity experts. Your system is safeguarded by end-to-end encryption, 2FA/SAML, and automatic health monitoring and firmware updates. ",
            image: data.infoSlide3,
          },
          {
            tabTitle: "In-Depth Reporting",
            preTitle: "Data-Driven Insights",
            title: "In-Depth Reporting",
            description:
              "Get comprehensive visibility into system performance and operations with diagnostic logs, audit logs, bandwidth reports, device inventory, device up/down time, and more.",
            image: data.infoSlide4,
          },
          {
            tabTitle: "User Roles",
            preTitle: "Manage End-Users",
            title: "User Roles",
            description:
              "Seamlessly add unlimited users, devices, and locations. Gain granular control over user permissions with custom roles and access types that make it easy to scale geographically and vertically within your organization.",
            image: data.infoSlide5,
          },
        ],
      },
      customerReviews: [
        {
          image: data.review1,
          author: {
            avatar: data.avatar1,
            name: "Daniel Hernandez",
            role: "Information Technology Manager of Bluestone Lane",
          },
        },

        {
          image: data.review2,
          author: {
            avatar: data.avatar2,
            name: "Gavin Thirlwall",
            role: "Technical Lead - Internet and Security Engineer of Juniper Networks",
          },
        },
        {
          image: data.review3,
          author: {
            avatar: data.avatar3,
            name: "Erik Tank",
            role: "Systems and Security Architect of East Moline School District 37",
          },
        },
        {
          image: data.review4,
          author: {
            avatar: data.avatar4,
            name: "Edward Ochoa",
            role: "IT Admin of Ochoa IT Consulting",
          },
        },
        {
          image: data.review5,
          author: {
            avatar: data.avatar5,
            name: "Brian Houy",
            role: "Senior IT Executive of SecureWorld Expo",
          },
        },
      ],
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus transforms IT departments into more nimble and scalable teams.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Cloud Physical Security System for IT Teams | Rhombus</title>
          <meta
            name="description"
            content="Centralize physical security system and simplify your tech stack with an integrated, secure, and open platform that makes it easy to create custom functionality and streamline security operations."
          />
        </Helmet>
        <ITPageTemplate data={pageContent} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/ITPage/img/it-hero-img-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefitImage1: file(
        relativePath: { eq: "components/ITPage/img/modernize-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage2: file(
        relativePath: { eq: "components/ITPage/img/stream-line-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage3: file(
        relativePath: { eq: "components/ITPage/img/partnership-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/ITPage/img/open-api-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/ITPage/img/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/ITPage/img/secure-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/ITPage/img/recording.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/ITPage/img/user-roles.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar1: file(relativePath: { eq: "components/ITPage/img/daniel.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar2: file(relativePath: { eq: "components/ITPage/img/gavin.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar3: file(relativePath: { eq: "components/ITPage/img/erik.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar4: file(relativePath: { eq: "components/ITPage/img/edward.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar5: file(relativePath: { eq: "components/ITPage/img/brian.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      review1: file(relativePath: { eq: "components/common/img/review1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review2: file(relativePath: { eq: "components/common/img/review2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review3: file(relativePath: { eq: "components/common/img/review3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review4: file(relativePath: { eq: "components/common/img/review4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review5: file(relativePath: { eq: "components/common/img/review5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review6: file(relativePath: { eq: "components/common/img/review6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
