import { SectionContainer } from "components/rhombus-UI/theme/containers";
import { TitleSmall } from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const SliderWrapper = styled(SectionContainer)`
  padding: 30px 0;
  background: ${(props) => props.bgColor};
`;

const sectionInner = css`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
`;

const carouselWrapper = css`
  width: 100%;
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
`;

const carouselInner = css`
  width: max-content;
  display: flex;
  animation: scroll 80s linear infinite;
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 2.5rem));
    }
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-right: 5rem;
  &:nth-child(11) {
    margin-right: 0;
  }
`;

const logoStyles = css`
  transform: scale(1);
`;

const SliderTitle = styled.h3`
  color: var(--white);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export default function LogoSliderV2({
  title = "",
  bgColor = "var(--primary-bg)",
}) {
  const data = useStaticQuery(graphql`
    query {
      logo1: file(
        relativePath: {
          eq: "components/common/logos/slider/white/goodwill.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 107, height: 18)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/slider/white/lafs.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 95, height: 48)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/slider/white/amazon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 81, height: 25)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/slider/white/stryker.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 71, height: 20)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/slider/white/praesum.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 115, height: 34)
        }
      }
      logo6: file(
        relativePath: { eq: "components/common/logos/slider/white/clark.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 110, height: 32)
        }
      }
      logo7: file(
        relativePath: {
          eq: "components/common/logos/slider/white/blue-apron.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 93, height: 43)
        }
      }
      logo8: file(
        relativePath: { eq: "components/common/logos/slider/white/ca.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 66, height: 33)
        }
      }
      logo9: file(
        relativePath: { eq: "components/common/logos/slider/white/torcon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 62, height: 41)
        }
      }
      logo10: file(
        relativePath: { eq: "components/common/logos/slider/white/hurley.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 88, height: 50)
        }
      }
      logo11: file(
        relativePath: { eq: "components/common/logos/slider/white/aramark.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 102, height: 29)
        }
      }
      logo12: file(
        relativePath: {
          eq: "components/common/logos/slider/white/south-miss.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 56, height: 39)
        }
      }
      logo13: file(
        relativePath: {
          eq: "components/common/logos/slider/white/10-federal.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 107, height: 36)
        }
      }
      logo14: file(
        relativePath: {
          eq: "components/common/logos/slider/white/ready-spaces.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 138, height: 36)
        }
      }
      logo15: file(
        relativePath: {
          eq: "components/common/logos/slider/white/middleby.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 225, height: 18)
        }
      }
    }
  `);

  const LOGO_SET_1 = [
    data.logo1,
    data.logo2,
    data.logo3,
    data.logo4,
    data.logo5,
    data.logo6,
    data.logo7,
    data.logo8,
    data.logo9,
    data.logo10,
    data.logo11,
    data.logo12,
    data.logo13,
    data.logo14,
    data.logo15,
  ];

  const logoRow1 = [...LOGO_SET_1, ...LOGO_SET_1];

  return (
    <SliderWrapper bgColor={bgColor}>
      <div className={sectionInner}>
        {!!title && <SliderTitle>{title}</SliderTitle>}
        <div className={carouselWrapper}>
          <div className={carouselInner} style={{ "--width": logoRow1.length }}>
            {logoRow1.map((logo, index) => (
              <CarouselItem key={index}>
                <GatsbyImage
                  image={getImage(logo)}
                  alt=""
                  objectPosition="center"
                  className={logoStyles}
                  loading="eager"
                />
              </CarouselItem>
            ))}
          </div>
        </div>
      </div>
    </SliderWrapper>
  );
}
