import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function A100() {
  const data = useStaticQuery(graphql`
    query {
      a100Header: file(
        relativePath: { eq: "components/sensors/img/a100-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      a100HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/a100-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      a100Dims: file(
        relativePath: { eq: "components/sensors/img/a100-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);

  const sensor = {
    model: "A100",
    title: "A100 Audio Gateway IoT Sensor",
    specTitle: "A100 IoT Sensor Specifications",
    accTitle: "A100 IoT Sensor Accessories",
    imageHeader: data.a100Header,
    imageHeaderMobile: data.a100HeaderMobile,
    objectPosition: "center",
    description:
      "Automated threat deterrence and audio AI security insights at your fingertips. Rhombus audio gateway provides synchronized audio with video, glass break detection, threat response automation, and more.",
    image: data.a100Dims,
    imageAlt:
      "Rhombus A100 audio gateway Iot sensor, one of the important iot devices, with dimensions displayed. Low-power, future-proof IT device tackling new challenges with advanced technology- an important device for chief technology officer.",
    heroImageAltText:
      "Commercial IoT sensors a100 mounted on wall enable to collect valuable data (audio) with high performance.",
    pdf: "/files/specs/A100.pdf",
    accessoryCompatible: true,
    column1: [
      {
        title: "Dimensions",
        text: "118mm (4.64in) x 118mm (4.64in) x 50.8mm (2in)",
      },
      {
        title: "Format",
        text: "WebM (OPUS), MP4 (AAC)",
      },
      {
        title: "Ethernet",
        text: "10/100 Mbps, MDI/MDX Auto Sensing",
      },
      {
        title: "Power-Over-Ethernet",
        text: "Standard 802.3af for one-cable installation",
      },
      {
        title: "Power Consumption",
        text: "Max 13 watts",
      },
      {
        title: "Onboard Storage",
        text: "Included 64GB",
      },
      {
        title: "Internal Microphone",
        text: "Omni-Directional (94dB SPL @ 1KHz / 100Hz - 10,000Hz)",
      },
    ],
    column2: [
      {
        title: "Internal Speaker",
        text: "82.5dB +/- 3dB",
      },
      {
        title: "External Audio",
        text: "3.5mm microphone input jack; 3.5mm speaker output jack",
      },
      {
        title: "Wireless",
        text: "Bluetooth",
      },
      {
        title: "Baseline, steady state",
        text: "2 - 10 Kbps",
      },
      {
        title: "Live Streaming",
        text: "52 Kbps",
      },
      {
        title: "Operating Temperature",
        text: ["10° to 50° C", "50° to 122° F"],
      },
      {
        title: "Rhombus Sensor Network Compatible",
      },
      {
        title: "CE, FCC, NDAA, TAA Compliant",
      },
      {
        title: "10-Year Warranty Included",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>
          A100 Audio Gateway Sensor | Commercial IoT Sensors | Rhombus
        </title>
        <meta
          name="description"
          content="Automated threat deterrence for businesses and audio AI security insights with A100 commercial IoT sensors. Discover A100 audio gateway's smart audio analytics, synchronized audio-video features, glass break detection, and more."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-A100.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-A100.png"
        />
      </Helmet>
      <SensorSpecsPage sensor={sensor} />
    </GlobalLayout>
  );
}
