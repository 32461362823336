import React from "react";

const IconUX = () => {
  return (
    <svg
      width="53"
      height="48"
      viewBox="0 0 53 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.5 40H30.5V46H21.5V40Z" stroke="#2A7DE1" stroke-width="3" />
      <path
        d="M12.5 46H39.5"
        stroke="#2A7DE1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M9 31L13.1586 24.114C13.4268 23.668 14.0308 23.65 14.3215 24.078L18.3694 30.004C18.6808 30.458 19.3336 30.402 19.5699 29.9L25.2478 17.89C25.4992 17.358 26.2064 17.334 26.4877 17.852L29.8453 24.006C30.0742 24.424 30.6088 24.508 30.9389 24.176L43.7504 11.334"
        stroke="#2A7DE1"
        stroke-width="3.00001"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.8347 39.6273H6.16525C3.05 39.6273 1.5 38.078 1.5 34.9635V6.66373C1.5 3.54719 3.05 2 6.16525 2H45.8347C48.95 2 50.5 3.54719 50.5 6.66373V34.9635C50.5 38.078 48.95 39.6273 45.8347 39.6273Z"
        stroke="#2A7DE1"
        stroke-width="3"
      />
    </svg>
  );
};

export default IconUX;
