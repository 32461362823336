import React, { useRef, useState, useEffect } from "react";
import { useTrail, animated } from "@react-spring/web";
import { useIsVisible } from "hooks/useIsVisible";

const FadeInYAxis = ({
  children,
  reverse = false,
  transformDistance = 500,
  initialOpacity = 0,
  delay = 200,
}) => {
  const sectionRef = useRef(null);
  const isVisible = useIsVisible(sectionRef);
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (!triggered && isVisible) {
      setTriggered(true);
    }
  }, [isVisible, triggered]);

  const trail = useTrail(React.Children.count(children), {
    from: {
      opacity: initialOpacity,
      transform: `translateY(${reverse ? `-${transformDistance}px` : `${transformDistance}px`})`,
    },
    to: {
      opacity: triggered ? 1 : initialOpacity,
      transform: triggered
        ? "translateY(0px)"
        : `translateY(${reverse ? `-${transformDistance}px` : `${transformDistance}px`})`,
    },
    delay,
  });

  return (
    <div ref={sectionRef}>
      {trail.map((animation, index) => (
        <animated.div key={index} style={animation}>
          {React.Children.toArray(children)[index]}
        </animated.div>
      ))}
    </div>
  );
};

export default FadeInYAxis;
