import { CTA_BUTTON_COPY, CTA_LINK_3 } from "components/data/landing-page-data";
import LandingPageFooter from "components/landing-pages/consultant-lp/LandingPageFooter";
import SurveillanceLandingPageF from "components/landing-pages/surveillance/version-f/SurveillanceLandingPageF";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function SurveillanceF() {
  return (
    <GlobalLayout
      color="var(--gradient-light)"
      landingPage
      ctaLink={CTA_LINK_3}
      buttonText={CTA_BUTTON_COPY}
      includeButton
      useFullWidthLogo
      useExperimentalFooter
    >
      <Helmet>
        <title>Security + Surveillance Unified | Rhombus™</title>
        <meta
          name="description"
          content="Stay in the know from anywhere at anytime. Automated alerts and surveillance monitoring ensures your spaces, assets, and people are protected at all times."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SurveillanceLandingPageF />
    </GlobalLayout>
  );
}
