import FAQSection from "components/common/FAQSection";
import {
  CTA_BUTTON_COPY_7,
  CTA_LINK_3,
} from "components/data/landing-page-data";
import BenefitsSection from "components/landing-pages/consultant-lp/BenefitsSection";
import BenefitsSection2 from "components/landing-pages/consultant-lp/BenefitsSection2";
import CaseStudySection from "components/landing-pages/consultant-lp/CaseStudySection";
import CTASection1 from "components/landing-pages/consultant-lp/CTASection1";
import CTASection2 from "components/landing-pages/consultant-lp/CTASection2";
import FeaturesSection from "components/landing-pages/consultant-lp/FeaturesSection";
import Hero from "components/landing-pages/consultant-lp/Hero";
import LandingPageFooter from "components/landing-pages/consultant-lp/LandingPageFooter";
import LogoBlade from "components/landing-pages/consultant-lp/LogoBlade";
import QuoteSection from "components/landing-pages/consultant-lp/QuoteSection";
import TestimonialSection from "components/landing-pages/consultant-lp/TestimonialSection";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

const questions = [
  {
    question: "How do I get started with Rhombus?",
    answer:
      "To get started, you must purchase a camera (one-time fee) and a license. It's as simple as that.",
  },
  {
    question: "Can I purchase just the cameras without a license?",
    answer: "All of our cameras require an ongoing license to operate.",
  },
  {
    question: "Do Rhombus cameras consume a lot of bandwidth?",
    answer:
      "Not at all. Our cameras store all of the video locally on the cameras meaning they take virtually no bandwidth at all.",
  },
  {
    question: "Do Rhombus cameras have a warranty?",
    answer: "Yes - all of our cameras come standard with a 10-year warranty.",
  },
];

export default function CamerasE() {
  return (
    <GlobalLayout
      landingPage
      hideFooter
      includeButton
      buttonText={CTA_BUTTON_COPY_7}
      ctaLink={CTA_LINK_3}
      color="var(--secondary-bg)"
    >
      <Helmet>
        <title>Rhombus Cameras</title>
        <meta
          name="description"
          content="Discover the power of cloud-managed security cameras."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <>
        <Hero
          ctaLink={CTA_LINK_3}
          ctaCopy={CTA_BUTTON_COPY_7}
          title="Smart Security Cameras with AI Analytics and Onboard Storage"
          blurb="Scale quickly with cloud-connected PoE cameras. Transform security operations with a suite of Rhombus cameras for every need. Discover how our cloud-managed platform improves threat deterrence and response with facial recognition, license plate detection, real-time alerts, and more."
        />
        <LogoBlade />
        <TestimonialSection />
        <BenefitsSection />
        <FeaturesSection />
        <CTASection1 ctaLink={CTA_LINK_3} ctaButtonCopy={CTA_BUTTON_COPY_7} />
        <CaseStudySection />
        <BenefitsSection2 />
        <CTASection2 ctaLink={CTA_LINK_3} ctaButtonCopy={CTA_BUTTON_COPY_7} />
        <QuoteSection />
        <FAQSection center QA={questions} title="FAQ" />
        <LandingPageFooter />
      </>
    </GlobalLayout>
  );
}
