import { animated, useSpring } from "@react-spring/web";
import Carrot from "components/common/icon-components/Carrot";
import { Link } from "gatsby";
import { useSetAtom } from "jotai";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useCallback, useState } from "react";

import { megaMenuOpenAtom } from "../atoms/headerAtoms";
import { MEGA_LINKS, NAV_LINKS } from "../lib/menu-data";
import DropDownContent from "./DropDownContent";
import TextLinkV2 from "components/common/TextLinkV2";

const megaMenuContainer = css`
  background: var(--white);
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--nuetral-200);
  box-shadow: 0 20vh 10vh -10vh #0b0c0d30;
  max-height: calc(100vh - 80px);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const megaMenuInner = css`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const DropDownContainer = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DropDownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  text-decoration: none;
  color: var(--nuetral-900);
  font-size: 16px;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  font-weight: 600;
  svg {
    transition: all 250ms ease-in;
    transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0)")};
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 2.5rem);
    left: 0;
    right: 0;
    margin: auto;
    height: 1px;
    background-color: ${(props) =>
      props.active ? "transparent" : "var(--nuetral-200)"};
  }
`;

const FancyDropDownItem = styled(DropDownItem)`
  border-bottom: none;
  width: fit-content;
  ::after {
    width: 0;
  }
  @media (min-width: 820px) {
    display: none;
  }
`;

const MobileMegaMenu = () => {
  const [currentDropDown, setCurrentDropDown] = useState(null);
  const setMegaMenuOpen = useSetAtom(megaMenuOpenAtom);

  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    []
  );

  const handleMegaLinkAction = (link) => {
    setCurrentDropDown(currentLinkStateHandler(link));
  };

  const currentLinkStateHandler = useCallback(
    (link) => {
      if (!currentDropDown) return link;
      if (currentDropDown && link.name === currentDropDown.name) {
        return null;
      }
      return link;
    },
    [currentDropDown]
  );

  const renderDropDownContent = useCallback(
    (link) => {
      if (!currentDropDown) return <></>;
      if (currentDropDown && link.name === currentDropDown.name) {
        return (
          <DropDownContent
            menuData={link.menuData}
            showFeaturedSection={currentDropDown.showFeaturedSection}
            FeaturedSection={currentDropDown.featuredSection}
          />
        );
      }
      return <></>;
    },
    [currentDropDown]
  );

  return (
    <animated.div className={megaMenuContainer} style={fadeIn}>
      <div className={megaMenuInner}>
        <DropDownContainer>
          {MEGA_LINKS.map((link) => (
            <>
              <DropDownItem
                onClick={() => handleMegaLinkAction(link)}
                active={
                  !!(currentDropDown && link.name === currentDropDown.name)
                }
              >
                {link.name} <Carrot />
              </DropDownItem>
              {renderDropDownContent(link)}
            </>
          ))}
          {NAV_LINKS.map((link) => (
            <DropDownItem
              as={Link}
              to={link.path}
              onClick={() => setMegaMenuOpen(false)}
            >
              {link.name}
            </DropDownItem>
          ))}
          <FancyDropDownItem
            as={Link}
            to="https://console.rhombussystems.com/login/"
            target="_blank"
          >
            <TextLinkV2
              target="_blank"
              path={"https://console.rhombussystems.com/login/"}
              title={"Console sign in"}
            />
          </FancyDropDownItem>
        </DropDownContainer>
      </div>
    </animated.div>
  );
};

export default MobileMegaMenu;
