import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";

import { styled } from "@linaria/react";
import React from "react";
import profilePic1 from "./assets/testi-1.png";
import profilePic2 from "./assets/testi-2.png";
import profilePic3 from "./assets/testi-3.png";
import profilePic4 from "./assets/testi-4.png";
import profilePic5 from "./assets/testi-5.png";
import profilePic6 from "./assets/testi-6.png";
import IconQuote from "components/common/icon-components/IconQuote";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  width: 100%;
`;

const PictureSection = styled.figure`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  padding: 0;
  margin: 0;
`;

const PictureLabel = styled.figcaption`
  color: var(--blue-800);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.027px;
`;

const ProfilePictureRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfilePicture = styled.div`
  height: 38px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${props => `url(${props.url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid var(--white);
  margin-right: -5px;
`;

const QuoteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2.5rem;
  gap: 1.25rem;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
  background: var(--white);
  border-radius: 0.75rem;
  width: 100%;
  max-width: 830px;
`;

const Quote = styled.q`
  color: var(--blue-900);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.033px;
`;

const profilePics = [
  profilePic1,
  profilePic2,
  profilePic3,
  profilePic4,
  profilePic5,
  profilePic6,
];

const TestimonialSection = () => {
  return (
    <SectionContainer style={{ background: "#F6F8F9" }}>
      <SectionInner>
        <ContentContainer>
          <PictureSection>
            <ProfilePictureRow>
              {profilePics.map(image => (
                <ProfilePicture url={image} />
              ))}
            </ProfilePictureRow>
            <PictureLabel>Over 480 Customer Reviews</PictureLabel>
          </PictureSection>
          <QuoteBox>
            <IconQuote />
            <Quote>
              Whenever you&apos;re deploying new technology, the people
              you&apos;re working with can make or break the experience. Rhombus
              and LVC have made this experience incredibly easy and seamless.
            </Quote>
            <div>
              <h4>Tim Rattanavilay</h4>
              <p>Senior IT Director, Stance</p>
            </div>
          </QuoteBox>
        </ContentContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default TestimonialSection;
