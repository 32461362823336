import PageContent from "components/page/PageContent";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import React from "react";
import SlideInContainer from "components/common/SlideInContainer";
import TrustedStripe from "./TrustedStripe";
import graphVideo from "./graph-video.mp4";
import RhombusForm from "components/form/RhombusForm";
import FormWrapper from "components/form/FormWrapper";
import {
  FancyPageTitle,
  FancyTitle,
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import useIsMobile from "hooks/useIsMobile";

const rootClass = css`
  padding: 5rem 0;
  background-image: var(--gradient-light);
  display: flex;
  justify-content: center;
  margin-top: -80px;

  @media (max-width: 600px) {
    padding-bottom: 0;
  }
  @media (min-width: 1440px) {
    height: calc(100vh);
  }
`;

const topContentClass = css`
  display: flex;
  gap: 5rem;
  max-width: var(--max-width);
  width: 100%;
  padding-top: 5rem;
  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 55px;
    align-items: center;
  }
`;

const topImageClass = css`
  max-width: 663px;
`;

const formSectionClass = css`
  padding: 5rem 0;
  display: flex;
  justify-content: space-between;
`;

const insideClass = css`
  width: 100%;
  > div {
    width: 100%;
  }

  h1 {
    display: inline-block;
    margin-bottom: 20px;
    background: linear-gradient(
      270deg,
      var(--teal-500) 0%,
      var(--blue-500) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  p {
    margin-bottom: 34px;
  }
`;

const bottomContainerClass = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 105px;
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 55px;
    align-items: center;
  }
`;

const includedContainer = css`
  width: 45%;
  max-width: 468px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const includedLineClass = css`
  display: flex;
  justify-content: space-evenly;
  max-width: 402px;
  > p {
    width: 90%;
  }
`;
const bulletClass = css`
  width: 24px;
  height: 24px;
  margin: 5px 29px 0px 0px;
  aspect-ratio: 1;
  overflow: visible;
`;

const videoClass = css`
  width: 60%;
  max-width: 778px;
  background: transparent;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 445px;
  }
`;

const mobilePadding = css`
  @media (max-width: 820px) {
    padding: 0 1.25rem;
  }
`;

export default function PhysicalSecurityReportPage() {
  const { isMobile } = useIsMobile(820);
  return (
    <>
      <div className={rootClass}>
        <div className={topContentClass}>
          <TextContainer width="665px" className={mobilePadding}>
            <FancyPageTitle>
              2023 State of Modern <br /> Physical Security Report
            </FancyPageTitle>
            <MainParagraph>
              What does modern physical security look like, and where is it
              going? Find out in this industry report sourced from over 375
              experts and decision-makers.
            </MainParagraph>
            <StaticImage
              src="./img/2023-report-min.png"
              alt="Rhombus Physical Security Report"
              placeholder="blurred"
              class={topImageClass}
              objectFit="contain"
              loading="eager"
            />
          </TextContainer>
          <FormWrapper
            title="Get the Report"
            width={isMobile ? "100%" : "665px"}
          >
            <RhombusForm formId="99839bfb-031a-4df5-bc13-da4a9ca6f341" />
          </FormWrapper>
        </div>
      </div>
      <div>
        <PageContent>
          <section className={formSectionClass}>
            <div className={insideClass}>
              <div className={bottomContainerClass}>
                <div className={includedContainer}>
                  <SlideInContainer>
                    <FancyTitle style={{ marginBottom: "1.25rem" }}>
                      What’s Inside
                    </FancyTitle>
                    <MainParagraph>
                      Learn about the patterns, priorities, and pitfalls that
                      enterprise organizations can use to inform their security
                      strategy and build a safer future.
                    </MainParagraph>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div className={includedLineClass}>
                      <StaticImage
                        src="./img/check.png"
                        alt="check"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>Key takeaways for 2023 and beyond</p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div className={includedLineClass}>
                      <StaticImage
                        src="./img/check.png"
                        alt="check"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Analyzing trends:{" "}
                        </span>
                        Insight on the use of video surveillance, access
                        control, environmental sensors, and more
                      </p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div className={includedLineClass}>
                      <StaticImage
                        src="./img/check.png"
                        alt="check"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>Industry challenges and current priorities</p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div className={includedLineClass}>
                      <StaticImage
                        src="./img/check.png"
                        alt="check"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          New technology:{" "}
                        </span>
                        Exploring the role of cybersecurity, integrations,
                        cross-functionality, cloud, and remote vs onsite
                        solutions
                      </p>
                    </div>
                  </SlideInContainer>
                  <SlideInContainer>
                    <div className={includedLineClass}>
                      <StaticImage
                        src="./img/check.png"
                        alt="check"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>
                        Statistics and insights from 375+ security professionals
                      </p>
                    </div>
                  </SlideInContainer>
                </div>
                <video
                  src={graphVideo}
                  className={videoClass}
                  muted
                  autoPlay
                  playsInline
                />
              </div>
            </div>
          </section>
        </PageContent>
      </div>
      <TrustedStripe />
    </>
  );
}
