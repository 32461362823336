import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

import TextLinkV2 from "../TextLinkV2";
import { toTitleCase } from "../util/helperFunctions";

const platFormContainer = css`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const ModuleCard = styled.div`
  display: flex;
  width: calc((100% / 2) - 2rem);
  max-width: 646px;
  height: 260px;
  flex-shrink: 0;
  background-color: var(--white);
  border-radius: 1rem;
  transition: all 250ms ease-in;
  box-shadow: 1px 5px 10px #00000000;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  :hover {
    box-shadow: 1px 5px 10px #00000030;
    transform: translateY(-0.25rem);
  }
  .card-left,
  .card-right {
    width: 50%;
  }
  .card-left {
    background-color: var(--nuetral-100);
  }
  .card-right {
    padding: 1rem;
    display: flex;
    align-items: center;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    aspect-ratio: 1;
    height: unset;
    .card-left {
      height: 40%;
      width: 100%;
    }
    .card-right {
      height: 60%;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const PlatormModule = ({ title, blurb, cards }) => {
  return (
    <SectionContainer style={{ background: "var(--gradient-light)" }}>
      <SectionInner style={{ flexDirection: "column", gap: "5rem" }}>
        <TextContainer style={{ textAlign: "center" }}>
          <FancyTitleMed>{toTitleCase(title)}</FancyTitleMed>
          <MainParagraph>{blurb}</MainParagraph>
        </TextContainer>
        <div className={platFormContainer}>
          {cards.map((card, index) => (
            <ModuleCard key={index} as={Link} to={card.link.path}>
              <div className="card-left">
                <GatsbyImage
                  image={getImage(card.image)}
                  alt={card.title}
                  objectFit="cover"
                  style={{ height: "100%" }}
                  objectPosition="top"
                />
              </div>
              <div className="card-right">
                <TextContainer
                  style={{ height: "100%", justifyContent: "space-evenly" }}
                >
                  <TitleSmall>{card.title}</TitleSmall>
                  <MainParagraph>{card.blurb}</MainParagraph>
                  <TextLinkV2 title={card.link.text} path={card.link.path} />
                </TextContainer>
              </div>
            </ModuleCard>
          ))}
        </div>
      </SectionInner>
    </SectionContainer>
  );
};

export default PlatormModule;
