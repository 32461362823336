import { styled } from "@linaria/react";
import { FORM_IDS } from "components/data/landing-page-data";
import Disclaimer from "components/form/Disclaimer";
import { ZoomInfoForm } from "components/form/zoomInfo/ZoomInfoForm";
import { SectionContainer } from "components/rhombus-UI/theme/containers";
import React from "react";
import TestimonialSlider from "./TestimonialSlider";

const SectionContainerSplit = styled(SectionContainer)`
  padding: 0;
  min-height: 767px;
`;
const SectionInnerSplit = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

const SectionLeft = styled.div`
  background: var(--nuetral-100);
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: calc((100% - var(--max-width)) / 2);
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  align-items: center;
  & > div {
    padding: 0 1.25rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding-left: 0;
    padding: 5rem 1.25rem;
    & > div {
      padding: 0;
    }
  }
  @media (max-width: 600px) {
    & > div {
      width: 100%;
    }
  }
`;

const SectionRight = styled.div`
  background: var(--gradient-light);
  width: 50%;
  padding-right: calc((100% - var(--max-width)) / 2);
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    padding-right: 0;
    width: 100%;
  }
`;

const FormAndTestimonialSection = ({
  title = "Try Rhombus in a risk-free trial",
  subTitle = "Take the next step and start your risk-free trial with Rhombus’ cameras. Install and start viewing footage from any device in seconds.",
  formId = FORM_IDS.FREE_TRIAL_ADS,
  submitButtonText = "Start your free trial",
}) => {
  return (
    <SectionContainerSplit>
      <SectionInnerSplit>
        <SectionLeft>
          <div>
            <ZoomInfoForm
              formId={formId}
              useFancyButton
              useFancyTitle
              buttonText={submitButtonText}
              title={title}
              lastStepTitle={title}
              subTitle={subTitle}
              renderDisclaimer={() => <Disclaimer formId={formId} />}
            />
          </div>
        </SectionLeft>
        <SectionRight>
          <TestimonialSlider />
        </SectionRight>
      </SectionInnerSplit>
    </SectionContainerSplit>
  );
};

export default FormAndTestimonialSection;
