import { css, cx } from "@linaria/core";
import Check from "../../../images/form-check.png";
import Error from "../../../images/form-error.png";
import CheckDark from "../assets/check-dark.svg";
import CheckLight from "../assets/check-light.svg";
import Arrow from "../assets/select-arrow.svg";
import { styled } from "@linaria/react";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.darkMode ? "20px" : "2rem")};
  width: 100%;
  position: relative;
  label {
    color: ${(props) =>
      props.darkMode ? "var(--white)" : "var(--body-subtle)"};
  }
`;

export const formFooter = css`
  color: var(--body-subtle);
  text-align: center;
  display: flex;
  justify-content: center;
  * {
    font-size: 12px;
  }
  p {
    color: var(--body-subtle);
  }
`;

export const formRow = css`
  width: 100%;
  display: flex;
  gap: 1.25rem;
  :has(.hiddenField) {
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: -999;
    pointer-events: none;
  }
`;

export const checkBoxWrapper = css`
  display: flex;
  gap: 10px;
  align-items: center;
  p,
  a {
    font-size: 14px;
  }
  a {
    color: var(--primary);
  }
`;

export const inputStyles = css`
  width: 100%;
  color: var(--body-subtle);
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  padding: 0.5rem 0;
  border: unset;
  border-radius: 0;
  border-bottom: 1px solid var(--nuetral-200);
  transition: all 250ms;
  background: transparent;
  font-family: inherit;
  font-size: 16px;

  :hover,
  :valid,
  :focus-visible,
  :active,
  :focus {
    background: transparent;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid var(--primary);
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset;
    -webkit-text-fill-color: var(--body-subtle);
    border-bottom: 1px solid var(--primary);
  }
  ::placeholder {
    color: var(--body-subtle);
  }
`;

export const invalid = cx(
  inputStyles,
  css`
    border-color: var(--nuetral-200);
    :valid {
      border-color: var(--nuetral-200);
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--white) inset;
    }
  `
);

export const filledInputStyles = cx(
  inputStyles,
  css`
    min-height: 48px;
    width: 100%;
    color: var(--body-subtle);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -webkit-appearance: none;
    background: var(--white);
    padding: 8px 16px;
    border: unset;
    border: 1px solid var(--nuetral-200);
    transition: all 250ms;
    font-family: inherit;
    font-size: 16px;
    caret-color: var(--blue-900);

    :hover,
    :valid,
    :focus-visible,
    :active,
    :focus {
      background: var(--white);
      outline: none;
      box-shadow: none;
      border: 1px solid var(--primary);
      color: var(--blue-900);
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--white) inset;
      -webkit-text-fill-color: var(--blue-900);
    }
    ::placeholder {
      color: var(--body-subtle);
      transition: all 250ms;
    }
    :hover::placeholder,
    :focus::placeholder {
      color: var(--blue-900);
    }
  `
);

export const darkInputStyles = cx(
  filledInputStyles,
  css`
    background: var(--blue-900);
    border: 1px solid var(--blue-900);
    caret-color: var(--white);

    :hover,
    :valid,
    :focus-visible,
    :active,
    :focus {
      background: var(--blue-900);
      color: var(--white);
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--blue-900) inset;
      -webkit-text-fill-color: var(--white);
    }
    ::placeholder {
      color: var(--body-subtle);
      transition: all 250ms;
    }
    :hover::placeholder,
    :focus::placeholder {
      color: var(--white);
    }
  `
);

export const invalidDark = cx(
  darkInputStyles,
  css`
    border-color: var(--error-red);
    :valid {
      border-color: var(--error-red);
    }
  `
);

export const invalidFilled = cx(
  filledInputStyles,
  css`
    border-color: var(--error-red);
    :valid {
      border-color: var(--error-red);
    }
  `
);

export const inputContainer = css`
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  p {
    font-size: 12px;
    color: var(--error-red);
  }
`;

export const inputWrap = css`
  width: 100%;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.25rem;
    width: 1.25rem;
    background-size: contain;
    background-repeat: no-repeat;
    object-position: center;
  }
  :has(select[class*="${inputStyles}"])::after {
    right: 16px;
    width: 10px;
    height: 6px;
    background-image: url(${Arrow});
  }
  :has(input:valid)::after {
    background-image: url(${Check});
  }
  :has(input:valid):has(input[class*="${filledInputStyles}"])::after {
    right: 16px;
    background-image: url(${CheckLight});
  }
  :has(input:valid):has(input[class*="${darkInputStyles}"])::after {
    right: 16px;
    background-image: url(${CheckDark});
  }
  :has(input[class*="${invalid}"])::after {
    background-image: url(${Error});
  }
  :has(input[class*="${invalidDark}"]):has(
      input[class*="${darkInputStyles}"]
    )::after {
    right: 16px;
    background-image: unset;
  }
  :has(input[class*="${invalidFilled}"]):has(
      input[class*="${filledInputStyles}"]
    )::after {
    right: 16px;
    background-image: unset;
  }
`;

export const selectStyles = cx(inputStyles);
export const selectStylesFilled = cx(filledInputStyles);
export const selectStylesDark = cx(darkInputStyles);

export const submitBtn = css`
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  width: 100%;
  border: unset;
  &:hover {
    filter: drop-shadow(0.1rem 0.1rem 0.75rem #4bebff90);
  }
`;

export const textareaStyles = css`
  margin-top: 10px;
  border-radius: 0.5rem;
  background: transparent;
  border: 1px solid #d9d8d6;
  resize: none;
  width: 100%;
  font-family: inherit;
  color: var(--body-subtle);
  padding: 1rem;
  font-size: 16px;
  transition: all 250ms;
  :hover,
  :focus-visible,
  :active,
  :focus {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: 1px solid var(--primary);
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset;
    -webkit-text-fill-color: var(--body-subtle);
    border: 1px solid var(--primary);
  }
  ::placeholder {
    color: var(--body-subtle);
  }
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--nuetral-200);
    border: 4px solid transparent;
    border-radius: 0.5rem;
    background-clip: padding-box;
  }
`;
