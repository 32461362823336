import React from "react";
import { NavButtonContainer } from "../styles/HeaderStyles";
import RhombusButton from "components/common/RhombusButton";
import Burger from "./Burger";
import { useSpring } from "@react-spring/web";
import { useAtomValue, useSetAtom } from "jotai";
import { megaMenuOpenAtom, scrollActiveAtom } from "../atoms/headerAtoms";
import IconCarrot from "components/common/icon-components/IconCarrot";
import { useGetQuoteTitleAtom } from "components/landing-pages/surveillance/SurveillancePageV4";

const animationConfig = {
  mass: 1,
  frictionLight: 20,
  frictionHeavy: 30,
  tension: 575,
  delay: 75,
};

const MobileHeaderContent = ({
  dark,
  landingPage,
  ctaLink,
  includeButton = false,
  buttonType = "gradient",
  buttonText,
}) => {
  const megaMenuOpen = useAtomValue(megaMenuOpenAtom);
  const scrollActive = useAtomValue(scrollActiveAtom);
  const setGetQuoteTitle = useSetAtom(useGetQuoteTitleAtom);

  const [styles, api] = useSpring(
    () => ({
      transformTop: "translate(0, 10px) rotate(0deg)",
      transformMiddle: "translate(0, 0px) rotate(0deg)",
      transformBottom: "translate(0, -10px) rotate(0deg)",
      config: {
        mass: animationConfig.mass,
        friction: animationConfig.frictionHeavy,
        tension: animationConfig.tension,
      },
    }),
    [megaMenuOpen]
  );

  return (
    <>
      <NavButtonContainer>
        {!landingPage && (
          <>
            <RhombusButton
              useHref
              target="_blank"
              title="Console Sign in"
              path="https://console.rhombussystems.com/login/"
              type={
                dark && !megaMenuOpen && !scrollActive
                  ? "secondary-light"
                  : "secondary"
              }
              small
              className={"console-sign-in-link"}
            />
            <RhombusButton
              title="Request Demo"
              path="/live-demo/"
              type={"primary"}
              small
            />
            <Burger
              styles={styles}
              api={api}
              animationConfig={animationConfig}
              dark={dark}
            />
          </>
        )}
        {landingPage && includeButton && (
          <NavButtonContainer>
            <RhombusButton
              small
              title={buttonText}
              path={ctaLink}
              useHref={ctaLink.includes("#")}
              type={buttonType}
              useTitleCase={false}
              renderIcon={() =>
                buttonType === "landing-v2" && (
                  <IconCarrot color="var(--blue-900)" />
                )
              }
              onClick={() => setGetQuoteTitle(false)}
            />
          </NavButtonContainer>
        )}
      </NavButtonContainer>
    </>
  );
};

export default MobileHeaderContent;
