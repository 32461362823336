import { EventContainer } from "components/RCTP-Program/styles/rctp-styles";
import {
  DefaultFlexContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { Title } from "components/rhombus-UI/theme/typography";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import EventCard from "./EventCard";
import { NotFoundContainer } from "components/integrations/marketplace/styles/MarketPlace-css";
import Illustration from "../integrations/marketplace/img/not-found.svg";
import { EventsPageContainer } from "./styles/event-styles";
import EventLoader from "./EventLoader";

export default function EventsFeed() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { startDate: ASC } }
        filter: {
          frontmatter: { templateKey: { eq: "event" }, draft: { ne: true } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              speakingSession
              title
              image
              eventType
              booth
              location
              link
              startDate
              endDate
            }
          }
        }
      }
    }
  `);

  const { edges: _events } = data.allMarkdownRemark;
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentDate = Date.now();
    const filteredEvents = _events.filter((item) => {
      const endDate = new Date(item.node.frontmatter.endDate).getTime();
      return currentDate < endDate;
    });
    setEvents(filteredEvents);
    setIsLoading(false);
  }, [_events]);

  if (isLoading) {
    return (
      <EventsPageContainer id="events-feed">
        <SectionInner style={{ flexDirection: "column" }}>
          <DefaultFlexContainer style={{ flexDirection: "column" }}>
            <Title>Events</Title>
          </DefaultFlexContainer>
          <EventLoader />
        </SectionInner>
      </EventsPageContainer>
    );
  }

  return (
    <EventsPageContainer id="events-feed">
      <SectionInner style={{ flexDirection: "column" }}>
        <DefaultFlexContainer style={{ flexDirection: "column" }}>
          <Title>Events</Title>
        </DefaultFlexContainer>
        <EventContainer>
          {events.length > 0 ? (
            events.map((event) => <EventCard eventData={event} />)
          ) : (
            <NotFoundContainer
              style={{
                maxWidth: "100%",
                justifyContent: "center",
                display: "flex",
                margin: "2.5rem 0",
              }}
            >
              <img src={Illustration} alt="not found" />
              <p>Sorry we couldn't find any events</p>
            </NotFoundContainer>
          )}
        </EventContainer>
      </SectionInner>
    </EventsPageContainer>
  );
}
