import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useCallback, useEffect } from "react";

import logoWhite from "../../../images/logo-icon-white.svg";
import logo from "../../../images/rhombus-symbol-40x40.svg";
import {
  currentLinkAtom,
  headerColorAtom,
  megaMenuOpenAtom,
  scrollActiveAtom,
} from "./atoms/headerAtoms";
import DesktopHeaderContent from "./desktop/DesktopHeaderContent";
import MegaMenu from "./MegaMenu";
import MobileHeaderContent from "./mobile/MobileHeaderContent";
import MobileMegaMenu from "./mobile/MobileMegaMenu";
import useScrollDetection from "hooks/useScrollDetection";

const HeaderContainer = styled.header`
  background: ${(props) =>
    props.active || props.menuOpen ? "var(--white)" : props.color};
  position: ${(props) => (props.diableStickyHeader ? "static" : "sticky")};
  top: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  padding: ${(props) => (props.useIncreasedPadding ? "0 2.5rem" : "0 1.25rem")};
  box-shadow: ${(props) =>
    props.active ? "0 8px 4px -4px #0b0c0d10" : "unset"};
  transition: position 250ms ease-in;
  @media (max-width: 600px) {
    padding: 0 1.25rem;
  }
`;

const HeaderInner = styled.div`
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? "unset" : "var(--max-width)")};
  position: relative;
  overflow: hidden;
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2.5rem;
`;

const logoStyles = css`
  width: 120px;
  height: 31px;
`;

const LogoContainer = styled.div`
  width: 120px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 820px) {
    width: fit-content;
  }
`;

const MobileLogo = styled.img`
  width: 40px;
  aspect-ratio: 1;
`;

const Header = ({
  color,
  dark,
  landingPage,
  customLinks,
  ctaLink,
  useIncreasedPadding,
  logoLink,
  includeButton,
  buttonType,
  useFullWidth,
  useFullWidthLogo,
  diableStickyHeader,
  buttonText,
}) => {
  const megaMenuOpen = useAtomValue(megaMenuOpenAtom);
  const [currentLink, setCurrentLink] = useAtom(currentLinkAtom);
  const [headerColor, setHeaderColor] = useAtom(headerColorAtom);
  const [scrollActive, setScrollActive] = useAtom(scrollActiveAtom);

  const { isMobile } = useIsMobile(1200);
  const { isMobile: isMobileSmall } = useIsMobile(820);
  const setMegaMenuOpen = useSetAtom(megaMenuOpenAtom);

  const scrollDetection = !diableStickyHeader && useScrollDetection();

  useEffect(() => {
    setScrollActive(scrollDetection);
  }, [scrollDetection]);

  useEffect(() => {
    setHeaderColor(color);
  }, []);

  const renderMegaMenu = useCallback(() => {
    if (megaMenuOpen && currentLink && !isMobile) {
      return (
        <MegaMenu
          menuData={currentLink.menuData}
          showFeaturedSection={currentLink.showFeaturedSection}
          FeaturedSection={currentLink.featuredSection}
        />
      );
    }
    if (megaMenuOpen && isMobile) {
      return <MobileMegaMenu />;
    }
    return <></>;
  }, [currentLink, megaMenuOpen, isMobile]);

  const renderResponsiveLayout = () => {
    return isMobile ? (
      <MobileHeaderContent
        dark={dark}
        landingPage={landingPage}
        ctaLink={ctaLink}
        includeButton={includeButton}
        buttonType={buttonType}
        buttonText={buttonText}
      />
    ) : (
      <DesktopHeaderContent
        dark={dark}
        landingPage={landingPage}
        customLinks={customLinks}
        ctaLink={ctaLink}
        includeButton={includeButton}
        buttonType={buttonType}
        buttonText={buttonText}
      />
    );
  };

  const renderResponsiveLogo = () => {
    if (isMobileSmall && !useFullWidthLogo) {
      return (
        <MobileLogo
          src={dark && !megaMenuOpen && !scrollActive ? logoWhite : logo}
          alt="Rhombus logo"
        />
      );
    }
    return dark && !megaMenuOpen && !scrollActive ? (
      <StaticImage
        src="../../../images/full-logo-horizontal-white.svg"
        className={logoStyles}
        alt="Rhombus logo"
        placeholder="none"
        quality={100}
        objectFit="contain"
        loading="eager"
      />
    ) : (
      <StaticImage
        src="../../../images/rhombus-logo-color.svg"
        className={logoStyles}
        alt="Rhombus logo"
        placeholder="none"
        quality={100}
        objectFit="contain"
        loading="eager"
      />
    );
  };

  return (
    <HeaderContainer
      color={headerColor}
      menuOpen={megaMenuOpen}
      active={scrollActive}
      useIncreasedPadding={useIncreasedPadding}
      diableStickyHeader={diableStickyHeader}
    >
      <HeaderInner fullWidth={useFullWidth}>
        <NavContainer>
          <LogoContainer>
            <Link
              to={logoLink}
              onClick={() => {
                setMegaMenuOpen(false);
                setCurrentLink(null);
              }}
            >
              {renderResponsiveLogo()}
            </Link>
          </LogoContainer>
          {renderResponsiveLayout()}
        </NavContainer>
      </HeaderInner>
      {!landingPage && renderMegaMenu()}
    </HeaderContainer>
  );
};

export default Header;
