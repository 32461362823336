import SlideInContainer from "components/common/SlideInContainer";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import { Body, SectionTitle } from "components/rui/typography";

const SectionWrapper = styled(SectionInner)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4rem;
  > div:first-of-type {
    max-width: 660px;
    width: 100%;
  }
`;

const imageContainerClass = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5rem;
  column-gap: 2.5rem;
  width: 100%;
`;

const logoWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 7.5rem) / 4);
  @media (max-width: 820px) {
    width: calc((100% - 2.5rem) / 2);
  }
`;

const imageClass = css`
  pointer-events: none;
  filter: grayscale(1);
`;

export default function Investors() {
  const renderContent = (data) => {
    const logos = [
      {
        img: data.logo1,
        alt: "Cota Capital",
        height: "46px",
        width: "185px",
      },
      {
        img: data.logo3,
        alt: "DFO Management",
        height: "60px",
        width: "60px",
      },
      {
        img: data.logo4,
        alt: "Promus Ventures",
        height: "46px",
        width: "185px",
      },
      {
        img: data.logo2,
        alt: "lemnos Labs",
        height: "46px",
        width: "185px",
      },
      {
        img: data.logo6,
        alt: "Uncorrelated",
        height: "37px",
        width: "147px",
      },
      {
        img: data.logo5,
        alt: "Tru Arrow",
        height: "40px",
        width: "164px",
      },
      {
        img: data.logo7,
        alt: "NightDragon",
        height: "60px",
        width: "112px",
      },
      {
        img: data.logo8,
        alt: "Bluestone Equity Partners",
        height: "60px",
        width: "101px",
      },
    ];
    return (
      <SectionContainer>
        <SectionWrapper>
          <SlideInContainer>
            <TextContainer width="660px">
              <SectionTitle>Our Investors</SectionTitle>
              <Body>
                Rhombus is backed by incredible investors who believe in
                transforming the world of physical security with
                enterprise-grade technology that's accessible to any
                organization.
              </Body>
            </TextContainer>
          </SlideInContainer>
          <div className={imageContainerClass}>
            {logos.map((logo) => {
              const logoRef = getImage(logo.img);
              return (
                <div className={logoWrapper} key={logo.alt}>
                  <GatsbyImage
                    image={logoRef}
                    alt={logo.alt}
                    className={imageClass}
                    style={{ width: logo.width, height: logo.height }}
                    objectFit="contain"
                  />
                </div>
              );
            })}
          </div>
        </SectionWrapper>
      </SectionContainer>
    );
  };
  const query = graphql`
    query {
      logo1: file(
        relativePath: { eq: "components/about/img/cota-capital.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/about/img/lemnos-lab.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(relativePath: { eq: "components/about/img/DFO_Logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/about/img/promus-ventures.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/about/img/TruArrow_Logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: { eq: "components/about/img/uncorrelated.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo7: file(
        relativePath: { eq: "components/about/img/NightDragon_Logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo8: file(
        relativePath: { eq: "components/about/img/Bluestone_Logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
