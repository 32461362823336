import Features from "components/common/Features";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import HeroLayout5 from "components/common/hero/HeroLayout5";
import IndustryStripeSmall from "components/common/IndustryStripeSmall";
import InfoSlider from "components/common/InfoSlider";
import LearnSection from "components/common/landingPage/LearnSection";
import React from "react";

const DemoPageTemplate = ({
  data,
  formTitle = "Request Demo",
  useFormRight = false,
}) => {
  return (
    <>
      {useFormRight ? (
        <HeroLayout5
          pretTitle={data.header.pretTitle}
          title={data.header.title}
          blurb={data.header.paragraph}
          formId={data.header.formId}
          image={data.header.image}
          formTitle={formTitle}
        />
      ) : (
        <HeroLayout2
          data={data.header}
          theme={data.header.theme}
          formTitle={formTitle}
        />
      )}
      <IndustryStripeSmall
        logos={data.featured.logos}
        theme={data.featured.theme}
      />
      {data.learn ? <LearnSection data={data.learn} /> : <></>}
      <InfoSlider
        data={data.benefits.list}
        title={data.benefits.title}
        color="var(--nuetral-100)"
      />
      <Features
        data={data.features}
        title="What Sets Rhombus Apart"
        color="var(--white)"
      />
    </>
  );
};

export default DemoPageTemplate;
