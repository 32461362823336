import {
  AppIcon,
  AppIconWrapper,
  FeedLoader,
  IntegrationCard,
  IntegrationCardHeader,
  NotFoundContainer,
  TagWrapper,
} from "components/integrations/marketplace/styles/MarketPlace-css";
import {
  FancyTextLink,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { Link } from "gatsby";
import { useAtomValue } from "jotai";
import React from "react";

import { loadingAtom } from "./atoms/atoms";
import Illustration from "./img/not-found.svg";
import { scrollToForm } from "./util/functions";
import AppTag from "./AppTag";

const Apps = ({ data }) => {
  const loading = useAtomValue(loadingAtom);

  if (loading) {
    return (
      <FeedLoader>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </FeedLoader>
    );
  }
  if (!data || data.length === 0) {
    return (
      <NotFoundContainer>
        <img src={Illustration} alt="not found" />
        <p>
          We couldn&apos;t find any apps matching your search. Try editing your
          search or{" "}
          <FancyTextLink onClick={() => scrollToForm("#marketplace-form")}>
            request one here.
          </FancyTextLink>
        </p>
      </NotFoundContainer>
    );
  }
  return data.map((item) => {
    const tags = item.node.frontmatter.tags;
    const draft = item.node.frontmatter.draft;
    const slug = draft ? null : item.node.fields.slug;
    return (
      <IntegrationCard as={Link} to={slug} key={item.id} draft={draft}>
        <IntegrationCardHeader>
          <AppIconWrapper>
            <img
              src={item.node.frontmatter.appIcon}
              alt={item.node.frontmatter.appName}
              className={AppIcon}
            />
          </AppIconWrapper>
          <TagWrapper>
            {tags && tags.map((tag) => <AppTag tag={tag} />)}
          </TagWrapper>
        </IntegrationCardHeader>
        <h3>{item.node.frontmatter.appName}</h3>
        <MainParagraph>{item.node.frontmatter.shortDescription}</MainParagraph>
      </IntegrationCard>
    );
  });
};

export default Apps;
