const accessControlData = (data) => {
  const doorReaders = [
    {
      name: "DR40 Video Intercom",
      image: data.dr40,
      altText: "DR40 Video Intercom",
      link: "/access-control/door-readers/dr40/",
      storagePrices: [
        {
          days: "20",
          storage: "512GB",
          value: 899,
        },
        {
          days: "40",
          storage: "1TB",
          value: 999,
        },
      ],
      licencePricing: [
        {
          name: "Professional",
          prices: [
            {
              term: 1,
              price: 199,
            },
            {
              term: 3,
              price: 479,
            },
            {
              term: 5,
              price: 699,
            },
            {
              term: 10,
              price: 1399,
            },
          ],
        },
        {
          name: "Enterprise",
          prices: [
            {
              term: 1,
              price: 249,
            },
            {
              term: 3,
              price: 599,
            },
            {
              term: 5,
              price: 879,
            },
            {
              term: 10,
              price: 1759,
            },
          ],
        },
      ],
    },
    {
      name: "DR20 Door Reader",
      image: data.dr20,
      altText: "DR20 Door Reader",
      link: "/access-control/door-readers/dr20/",
      price: 249,
    },
    {
      name: "DC20 4-Door Controller",
      image: data.dc20,
      altText: "DC20 Door Controller",
      link: "/access-control/dc20/",
      price: 1299,
      footNote: "Module-only option available at request.",
      doorLicensePricing: [
        {
          prices: [
            {
              term: 1,
              price: 199,
            },
            {
              term: 3,
              price: 479,
            },
            {
              term: 5,
              price: 699,
            },
            {
              term: 10,
              price: 1399,
            },
          ],
        },
      ],
    },
  ];

  const secureCard = {
    name: "Secure Card",
    image: data.secureCard,
    altText: "Rhombus Secure Card",
    link: "/access-control/secure-cards/",
    price: 399,
    priceUnit: "100 cards per box",
    useQuantity: true,
    unit: "boxes",
  };

  const secureFob = {
    name: "Rhombus Secure Fob",
    image: data.secureFob,
    altText: "Rhombus Secure Fob",
    link: "/access-control/secure-fobs/",
    price: 499,
    priceUnit: "50 per box",
    useQuantity: true,
    unit: "boxes",
  };

  return { doorReaders, secureCard, secureFob };
};

export default accessControlData;
