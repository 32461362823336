import React from "react";
import { css } from "@linaria/core";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";

const sectionClass = css`
  background: linear-gradient(270deg, var(--teal-500) 0%, var(--blue-500) 100%);
`;

const contentClass = css`
  max-width: 1350px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 1075px) {
    flex-direction: column;
    align-items: center;
  }
`;

const leftClass = css`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1075px) {
    align-items: center;
    width: unset;
  }
`;

const textClass = css`
  padding: 160px 53px 51px;
  width: 600px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 1075px) {
    width: unset;
    max-width: 500px;
    padding: 65px 25px 51px;
  }
  @media (max-width: 375px) {
    padding: 65px 15px 51px;
  }
`;

const smallTextClass = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 2;
`;

const rightClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 76px;
  width: 50%;

  @media (max-width: 1075px) {
    padding: 0px 0px 75px 0px;
  }
`;

const watchImageClass = css`
  width: 83%;
  margin-left: 53px;

  @media (max-width: 1075px) {
    display: none !important; //must be used to override gatsby image styling
  }
`;

const watchImageClassMobile = css`
  display: none !important; //must be used to override gatsby image styling

  @media (max-width: 1075px) {
    display: block;
    max-width: 600px;
  }
`;

export default function LandingPageHeader({
  formRef,
  subTitle,
  webImg,
  mobileImg,
  alt,
  formId,
  termsLink,
}) {
  const web = getImage(webImg);
  const images = withArtDirection(getImage(webImg), [
    {
      media: "(max-width: 700px)",
      image: getImage(mobileImg),
    },
  ]);
  return (
    <section className={sectionClass}>
      <div className={contentClass}>
        <div className={leftClass}>
          <div className={textClass}>
            <h1>See How Rhombus Transforms Security</h1>
            <p>
              {subTitle}
              <sup>*</sup>
            </p>
            <p className={smallTextClass}>
              <sup>*</sup>Terms and conditions apply
            </p>
          </div>
          <GatsbyImage
            image={web}
            alt={alt}
            className={watchImageClass}
            loading="eager"
          />
        </div>
        <div className={rightClass} ref={formRef}>
          <FormWrapper title="Schedule a Demo">
            <RhombusForm formId={formId} />
          </FormWrapper>
        </div>
        <GatsbyImage
          image={images}
          alt={alt}
          className={watchImageClassMobile}
        />
      </div>
    </section>
  );
}
