import RhombusBanner from "components/common/banners/RhombusBanner";
import Features from "components/common/Features";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import InfoSlider from "components/common/InfoSlider";
import RhombusBlade from "components/common/RhombusBlade";
import TestimonySlider from "components/common/TestimonySlider";
import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";

import PartnerVideo from "../PartnerVideo";
import { ButtonSecondaryLight } from "components/rui/buttons";

export default function PartnersPage() {
  const renderContent = (data) => {
    const pageData = {
      headerSection: {
        preTitle: "Partners",
        title: "Unlock New Opportunities",
        description:
          "At Rhombus, we understand the power of strong partnerships. As a leading provider of best-in-class physical security solutions, we invite you to take your business to new heights with our Partner Program.",
        image: data.headerImage,
        button1: {
          useHref: true,
          target: "_blank",
          text: "Apply",
          path: "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
        },
        button2: {
          useHref: true,
          target: "_blank",
          text: "View Partner Portal",
          path: "https://partners.rhombussystems.com/English/?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
        },
      },
      featureSection: [
        {
          icon: data.icon1,
          iconAlt: "Unlock Recurring Revenue",
          title: "Unlock Recurring Revenue",
          p: "Unleash the industry’s leading cloud solution, offer continually evolving and intuitive software, and propel your profits and business growth. ",
        },
        {
          icon: data.icon2,
          iconAlt: "Powerfully Simple Installation",
          title: "Powerfully Simple Installation",
          p: "Complete installations in a single visit, achieving up to 50% fewer truck rolls and drastically reducing your businesses operational costs. ",
        },
        {
          icon: data.icon3,
          iconAlt: "Minimize Customer Touchpoints",
          title: "Minimize Customer Touchpoints",
          p: "Rhombus offers seamlessly streamlined operations with a user-friendly system, reducing customer inquiries and maximizing efficiency. ",
        },
        {
          icon: data.icon4,
          iconAlt: "Award-Winning Team",
          title: "Award-Winning Team",
          p: "Rhombus acts as an extension of your team, with fully dedicated account managers along with technical assistance and training resources. ",
        },
      ],
      infoSlider: [
        {
          tabTitle: "Futureproof Architecture",
          preTitle: "Smarter solution",
          title: "Futureproof Architecture",
          description:
            "No DVR/NVRs, complex configurations, or manual updates. Service more customers with Rhombus’ remote configurations and automatic updates, allowing them to quickly activate advanced features with ease.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Integrate and Win",
          preTitle: "Smarter solution",
          title: "Integrate and Win ",
          description:
            "Deliver powerful and customizable security solutions to every organization. With our open API, we can integrate with even the most intricate systems and provide a unique, tailored experience to your customers.",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Partner Support",
          preTitle: "Smarter solution",
          title: "Best-in-Class Partner Support",
          description:
            "Align your organization with a partner committed to your long-term success. From support to sales, your interactions with Rhombus are built on loyalty, integrity, and trust.",
          image: data.infoSlide3,
        },
      ],
      customerReviews: [
        {
          image: data.review1,
          author: {
            avatar: data.avatar1,
            name: "Bobby Grijalva",
            role: "President of Surveillance Audio Video",
          },
        },

        {
          image: data.review2,
          author: {
            avatar: data.avatar2,
            name: "Jared Stevens",
            role: "Owner of Modern Access and Security",
          },
        },
        {
          image: data.review3,
          author: {
            avatar: data.avatar3,
            name: "Tim Pickett",
            role: "President of Encompass Connect",
          },
        },
      ],
    };
    return (
      <>
        <HeroLayout1 data={pageData.headerSection} />
        <Features
          data={pageData.featureSection}
          title="How We Succeed Together"
          color="var(--white)"
        />
        <PartnerVideo />
        <InfoSlider
          data={pageData.infoSlider}
          title="Make the World Safer with Rhombus"
          color="var(--white)"
        />
        <RhombusBanner
          title="Learn How Rhombus & LVC Create Value for End-Users"
          blurb="LVC, an industry-leading systems integration company, has partnered with Rhombus since 2020 to provide modern, user-friendly physical security solutions. Learn more about what makes this such a successful collaboration."
          image={data.bannerImage}
          Button={() => (
            <ButtonSecondaryLight as={Link} to="/partners/case-studies/lvc/">
              LVC Case Study
            </ButtonSecondaryLight>
          )}
        />
        <TestimonySlider
          data={pageData.customerReviews}
          title="See Why Partners Choose Rhombus"
          subTitle="From a strong sense of partnership to a great product, industry leaders everyday are choosing to work with Rhombus."
          color="var(--nuetral-100)"
        />
        <RhombusBlade
          fancy
          reverse
          title="Becoming a Partner is Simple"
          blurb="Submit your application online in 5 minutes, and our Partner Team will reach out within two business days."
          button={{
            text: "Apply",
            path: "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
            useHref: true,
            target: "_blank",
          }}
        />
      </>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/partner-program/updated/img/partner-header-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/dollar.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/plug.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/trust.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/teamwork.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/partner-program/updated/img/futureproof-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/partner-program/updated/img/integrate-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/partner-program/updated/img/support-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar1: file(
        relativePath: { eq: "components/partner-program/img/bobby-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar2: file(
        relativePath: { eq: "components/partner-program/img/jared-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar3: file(
        relativePath: { eq: "components/partner-program/img/tim-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      review1: file(
        relativePath: {
          eq: "components/partner-program/updated/img/bobby-review.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review2: file(
        relativePath: {
          eq: "components/partner-program/updated/img/jared-review.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review3: file(
        relativePath: {
          eq: "components/partner-program/updated/img/tim-review.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bannerImage: file(
        relativePath: {
          eq: "components/partner-program/updated/img/partner-case-study-blade-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
