import AccordionSection from "components/common/AccordionSection";
import FAQSection from "components/common/FAQSection";
import CTABanner from "components/common/footer-banners/CTABanner";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import Highlights from "components/common/Highlights";
import InfoSlider from "components/common/InfoSlider";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import AlarmMonitoringOverview from "../AlarmMonitoringOverview";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";

export default function AlarmMonitoringPage() {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/alarms/updated/img/alarm-monitoring-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      highlightImage: file(
        relativePath: {
          eq: "components/ai/updated/img/highlight-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/alarms/updated/img/threat-assessment-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/alarms/updated/img/communication-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/alarms/updated/img/audio-deterrent-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/alarms/updated/img/alert-verification-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/alarms/updated/img/share-data-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      drawer1: file(
        relativePath: { eq: "components/alarms/updated/img/drawer1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer2: file(
        relativePath: { eq: "components/alarms/updated/img/drawer2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer3: file(
        relativePath: { eq: "components/alarms/updated/img/drawer3-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer4: file(
        relativePath: { eq: "components/alarms/updated/img/drawer4-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      faq: file(
        relativePath: { eq: "components/alarms/updated/img/alm-faq.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `);
  const pageData = {
    headerSection: {
      preTitle: "Alarm Monitoring",
      title:
        "Get Real Time, After-Hours Security with Commercial Alarm Monitoring",
      description:
        "Smart alarms, real agents. Protect your buildings 24/7/365 with modern alarm monitoring system that combines automatic security alerts with live monitoring services.",
      image: data.headerImage,
      altText:
        "A security personnel monitors unknown activity on a computer, receiving real-time alerts on their phone through Rhombus Alarm Monitoring system",
      button1: { text: CTA_BUTTON_COPY_3, path: "/live-demo/" },
      button2: {
        text: "View Pricing",
        path: "/pricing/alarm-monitoring/#pricing",
      },
    },
    highlights: {
      title:
        "Protect Your Spaces Around the Clock with Professional Monitoring",
      image: data.highlightImage,
      altText:
        "Rhombus security systems sending app notifications to protect your space 24/7",
      list: [
        {
          title: "24/7 Automatic Threat Detection",
          description:
            "Rhombus AI Analytics continuously monitor your environment for potential threats, including trespassing and persons-of-interest.",
        },
        {
          title: "Professional Threat Assessment in Real Time",
          description:
            "After-hours security alerts are routed to a TMA Five Diamond Certified dispatcher who investigates via security camera feeds.",
        },
        {
          title: "Rapid Emergency Response",
          description:
            "When a threat is verified, the agent takes immediate action to neutralize the threat and protect your facilities. ",
        },
      ],
    },
    infoSlider: {
      slides: [
        {
          tabTitle: "Threat Assessment",
          preTitle: "Intelligent, Informed Response",
          title: "Live Threat Assessment",
          description:
            "Ensure accurate and appropriate responses to each unique incident. Agents investigate security alerts in real time using the Rhombus platform and execute emergency protocols based on the situation.",
          image: data.infoSlide1,
          altText:
            "A man monitors his screen to provides professional monitoring and assess security threats in real-time using the Rhombus business security system",
        },
        {
          tabTitle: "Notify Contacts",
          preTitle: "Real Time Communication",
          title: "Notify Your Contacts",
          description:
            "Agents will text or call essential personnel based on the location’s emergency contact list. Contacts are kept in the loop when incidents occur, and false alarms are reduced due to additional verification.",
          image: data.infoSlide2,
          altText:
            "Real-time text notifications sent by the Rhombus business security systems during emergencies",
        },
        {
          tabTitle: "Audio Deterrents",
          preTitle: "DETER & DEESCALATE",
          title: "Issue Audio Deterrents",
          description:
            "Issue an audible deterrent using Rhombus’ Audio Gateway to help neutralize damage or threats before they escalate. Sound a police siren, alarm, custom warning, or use two-way communication to speak directly to interlopers.  ",
          image: data.infoSlide3,
          altText:
            "Rhombus security cameras and alarm monitoring issuing audio deterrents to de-escalate threats",
        },
        {
          tabTitle: "Emergency Dispatch",
          preTitle: "ACCELERATE RESPONSE",
          title: "Dispatch Emergency Services",
          description:
            "If an active threat to a person or property is identified, agents will escalate as needed and immediately dispatch local emergency responders to the affected location. ",
          image: data.infoSlide4,
          altText:
            "Real-time text notification sent to dispatch emergency services in the event of threats as part of the Rhombus security solution to protect your business",
        },
        {
          tabTitle: "Share Data",
          preTitle: "Share Critical Context",
          title: "Share Data with First Responders",
          description:
            "In real time, agents can securely share essential information from the Rhombus Console with emergency services, including footage, livestreams, and location details.",
          image: data.infoSlide5,
          altText:
            "Rhombus physical security solution sharing critical contextual data with first responders",
        },
      ],
    },

    accordionSection: {
      title: "Create Safer Spaces with Less Labor",
      items: [
        {
          title: "Accelerate Emergency Response",
          p: "Agents immediately investigate threats and escalate to local emergency services as needed, ensuring fast, informed response. ",
          img: data.drawer1,
          alt: "Rhombus alarm system provides business security solutions, detecting movement when a man approaches the self-storage space",
        },
        {
          title: "Deter and Deescalate in Real Time",
          p: "With two-way communication, agents can issue audio deterrents and talk down bad actors to minimize threats before they escalate.",
          img: data.drawer2,
          alt: "Rhombus alarm monitoring detecting unidentified personnel in real time inside commercial buildings",
        },
        {
          title: "Reduce False Alarms via Video Verification",
          p: "Eliminate the stress, hassle, and confusion of false alarms. Agents investigate and dismiss false alarms without involving the authorities.",
          img: data.drawer3,
          alt: "Rhombus alarm monitoring security solution detecting unidentified personnel during a break-in",
        },
        {
          title: "Support Teams of All Sizes",
          p: "Supplement your onsite team, cover blind spots for security guards and patrols, and secure sites without needing dedicated staff.",
          img: data.drawer4,
          alt: "Rhombus security cameras covering all blind spots in a grocery store",
        },
      ],
    },
  };

  const questions = [
    {
      question: "What is the average response time for emergency dispatch?",
      answer: (
        <>
          <MainParagraph>
            If emergency dispatch is required, the authorities are typically
            dispatched within a few minutes of the incident occurring.
          </MainParagraph>
          <br></br>
          <MainParagraph>
            When an alarm is triggered, the system automatically sends an alert
            with security livestream(s), sensor data, and GPS data to agents
            located at one of several TMA Five Diamond Certified in the U.S.
            This alerting process happens in real time.
          </MainParagraph>
          <br></br>
          <MainParagraph>
            Once an agent receives the alert, the time to emergency dispatch
            depends on how rapidly the agent verifies that the incident is a
            true emergency and not a false alarm. The average response time is
            several minutes from the incident occurring.
          </MainParagraph>
        </>
      ),
    },
    {
      question: "What is the deployment and management process?",
      answer:
        "To activate Alarm Monitoring at a location, simply apply the license to the cameras that you want covered, set up at least one emergency contact for your location, and configure your alert policies to determine what types of activity will trigger alerts, and at what times.",
    },
    {
      question: "How do I arm and disarm my spaces?",
      answer:
        "You can set your Alarm Monitoring schedule in the Rhombus Console, so that monitoring is automatically active during your chosen days and times. You can also manually start, pause, and resume the service from the Console or from a dedicated tablet.",
    },
    {
      question: "Where will Alarm Monitoring be available?",
      answer: (
        <>
          <MainParagraph>
            Alarm Monitoring is available in all U.S. states, with coverage
            expanding in the future.
          </MainParagraph>
          <br></br>
          <i>California Alarm Company Operator License: 8062</i>
        </>
      ),
    },
  ];

  return (
    <>
      <HeroLayout1 data={pageData.headerSection} />
      <Highlights data={pageData.highlights} color="var(--white)" />
      <InfoSlider
        data={pageData.infoSlider.slides}
        title="Take Action In A Verified Emergency with Live Agent Monitoring"
        color="var(--nuetral-100)"
      />
      <SectionContainer>
        <SectionInner>
          <SectionCol>
            <StaticImage
              alt="Rhombus business security alarm monitoring pads for commercial security"
              loading="eager"
              src="./img/ipad-min.png"
              placeholder="blurred"
              style={{ width: "100%" }}
            />
          </SectionCol>
          <FlexEndColumn>
            <TextContainer style={{ maxWidth: "500px" }}>
              <FancyTitleMed style={{ textAlign: "left" }}>
                Arm and Disarm Your Spaces with Rhombus Alarm Pad
              </FancyTitleMed>
              <MainParagraph>
                Add an onsite touchpoint for authorized users to arm, disarm,
                manage alerts, or respond to incidents.
              </MainParagraph>
            </TextContainer>
          </FlexEndColumn>
        </SectionInner>
      </SectionContainer>
      <AccordionSection
        data={pageData.accordionSection}
        color="var(--nuetral-100)"
      />
      <AlarmMonitoringOverview />
      <FAQSection
        title="Alarm Monitoring FAQs"
        QA={questions}
        color="var(--nuetral-100)"
        image={data.faq}
        imageAlt={
          "A man is alerted on his screen by Rhombus alarm monitoring regarding an intruder"
        }
        reverse
      />
      <CTABanner />
    </>
  );
}
