import { css } from "@linaria/core";
import React from "react";

const loaderContainer = css`
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  span {
    flex-basis: calc((100% - 4rem) / 3);
    min-width: calc((100% - 4rem) / 3);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-image: linear-gradient(
      90deg,
      var(--nuetral-100),
      var(--white),
      var(--nuetral-100),
      var(--white)
    );
    background-size: 300% 100%;
    animation: animateBg 2s infinite;
    border-radius: 1rem;
    padding: 2rem;
    @media (max-width: 1024px) {
      flex-basis: calc((100% - 2rem) / 2);
      min-width: calc((100% - 2rem) / 2);
    }
    @media (max-width: 768px) {
      flex-basis: 100%;
      min-width: 100%;
    }
    div {
      padding: 1rem;
      animation: animateBg 2s infinite;
      background-size: 300% 100%;
      background-image: linear-gradient(
        90deg,
        var(--nuetral-200),
        var(--nuetral-100),
        var(--nuetral-200),
        var(--nuetral-100)
      );
      border-radius: 0;
    }
    div:first-of-type {
      width: 80%;
    }
    div:last-of-type {
      width: 20%;
    }
    @keyframes animateBg {
      0% {
        background-position: 100% 0%;
      }
      100% {
        background-position: 0% 0%;
      }
    }
  }
`;

const JobLoader = () => {
  return (
    <div className={loaderContainer}>
      <span>
        <div></div>
        <div></div>
      </span>
      <span>
        <div></div>
        <div></div>
      </span>
      <span>
        <div></div>
        <div></div>
      </span>
      <span>
        <div></div>
        <div></div>
      </span>
      <span>
        <div></div>
        <div></div>
      </span>
      <span>
        <div></div>
        <div></div>
      </span>
    </div>
  );
};

export default JobLoader;
