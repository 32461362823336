import { Link as GastbyLink } from "gatsby";
import React from "react";

/**
 * Anchor tag (<a/>) with special handling for internal links.
 * Internal links open without a full page load, and the content is preloaded.
 */
export default function Link(props) {
  if (!!props.to) {
    return <GastbyLink {...props} />;
  }
  return <a {...props} />;
}
