const sensorPricingData = (data) => {
  return [
    {
      name: "A100",
      description: "Audio Gateway",
      price: 349,
      image: data.a100Image,
      altText: "A100 Audio Gateway",
      licensePrices: true,
    },
    {
      name: "B10",
      description: "Smart Button",
      price: 99,
      image: data.b10Image,
      altText: "B10 Smart button",
    },
    {
      name: "D20",
      description: "Door Sensor",
      price: 99,
      image: data.d20Image,
      altText: "D20 Door Sensor",
    },
    {
      name: "E15",
      description: "Environmental Sensor",
      power: "Battery Life: 3-years",
      price: 129,
      image: data.e15Image,
      altText: "E15 Environmental Sensor",
    },
    {
      name: "E50",
      description: "Environmental Sensor",
      price: 999,
      image: data.e50Image,
      altText: "E50 Environmental Sensor",
      licensePrices: [
        {
          term: 1,
          price: 199,
        },
        {
          term: 3,
          price: 479,
        },
        {
          term: 5,
          price: 699,
        },
        {
          term: 10,
          price: 1399,
        },
      ],
    },
    {
      name: "M15",
      description: "Motion Sensor",
      price: 99,
      image: data.m15Image,
      altText: "M15 Motion Sensor",
    },
  ];
};

export default sensorPricingData;
