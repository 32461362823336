import ComparePage from "components/compare/ComparePage";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function ComparePageTemplate({ pageContext, data }) {
  const { markdownRemark: competitorData } = data;
  const { competitors } = pageContext;

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus vs Competitors - Competition Comparison Chart</title>
        <meta
          name="description"
          content="Discover how Rhombus stacks up against Verkada, AVA, Meraki, and the competition and see why industry-leaders choose Rhombus for enterprise physical security."
        />
      </Helmet>
      <ComparePage competitors={competitors} compareData={competitorData} />
    </GlobalLayout>
  );
}

export const pageQuery = graphql`
  query ComparePageByID($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        draft
        title
        compareTitle
        compareDescription
        linkText
        link
        thumbnail
        cameraOptions {
          dome {
            text
            compare
          }
          wifiDome {
            text
            compare
          }
          microDome {
            text
            compare
          }
          fourK {
            text
            compare
          }
          fisheye {
            text
            compare
          }
          bullet {
            text
            compare
          }
        }
        cameraSpecs {
          nightVision {
            text
            compare
          }
          poe {
            text
            compare
          }
          atRest {
            text
            compare
          }
          hdWan {
            text
            compare
          }
          indoorOutdoor {
            text
            compare
          }
          continuousStorage {
            text
            compare
          }
          onboardStorage {
            text
            compare
          }
          wifi {
            text
            compare
          }
          compliance {
            text
            compare
          }
          ikTen {
            text
            compare
          }
          warranty {
            text
            compare
          }
          latency {
            text
            compare
          }
          encryptedSdCards {
            text
            compare
          }
        }
        iotSensors {
          doorWindow {
            text
            compare
          }
          waterLeak {
            text
            compare
          }
          environmental {
            text
            compare
          }
          motion {
            text
            compare
          }
          audio {
            text
            compare
          }
          deployment {
            text
            compare
          }
        }
        alarmMonitoring {
          liveMonitor {
            text
            compare
          }
          audioDeter {
            text
            compare
          }
          emergency {
            text
            compare
          }
        }
        console {
          timelapse {
            text
            compare
          }
          stream {
            text
            compare
          }
          sharpness {
            text
            compare
          }
          app {
            text
            compare
          }
          settings {
            text
            compare
          }
          encryption {
            text
            compare
          }
          manage {
            text
            compare
          }
          camera {
            text
            compare
          }
          remote {
            text
            compare
          }
          additional {
            text
            compare
          }
          privacy {
            text
            compare
          }
          updates {
            text
            compare
          }
          local {
            text
            compare
          }
          resolution {
            text
            compare
          }
          live {
            text
            compare
          }
          bitrate {
            text
            compare
          }
          activity {
            text
            compare
          }
          floorPlan {
            text
            compare
          }
          cloud {
            text
            compare
          }
          role {
            text
            compare
          }
          ptz {
            text
            compare
          }
          alerts {
            text
            compare
          }
          audits {
            text
            compare
          }
          monitor {
            text
            compare
          }
          storage {
            text
            compare
          }
          appleTv {
            text
            compare
          }
          shareVideo {
            text
            compare
          }
          shareStream {
            text
            compare
          }
        }
        aiFeatures {
          asterisk
          uniquePeople {
            text
            compare
          }
          vehicleCount {
            text
            compare
          }
          color {
            text
            compare
          }
          deployment {
            text
            compare
          }
          unusual {
            text
            compare
          }
          facial {
            text
            compare
          }
          mask {
            text
            compare
          }
          vehicle {
            text
            compare
          }
          vehicleAlert {
            text
            compare
          }
          indexing {
            text
            compare
          }
          heat {
            text
            compare
          }
          region {
            text
            compare
          }
          unidentified {
            text
            compare
          }
          licensePlate {
            text
            compare
          }
          motion {
            text
            compare
          }
          noise {
            text
            compare
          }
          edge {
            text
            compare
          }
        }
        general {
          firmware {
            text
            compare
          }
          engineering {
            text
            compare
          }
          usaSupport {
            text
            compare
          }
          support {
            text
            compare
          }
        }
      }
    }
  }
`;
