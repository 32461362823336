import InfoSlider from "components/common/InfoSlider";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const CameraInfoSlider = () => {
  const imageData = useStaticQuery(graphql`
    query {
      dome: file(
        relativePath: {
          eq: "components/landing-pages/consultant-lp/assets/camera-slider/dome-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      fisheye: file(
        relativePath: {
          eq: "components/landing-pages/consultant-lp/assets/camera-slider/fisheye-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      bullet: file(
        relativePath: {
          eq: "components/landing-pages/consultant-lp/assets/camera-slider/bullet-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      multisensor: file(
        relativePath: {
          eq: "components/landing-pages/consultant-lp/assets/camera-slider/multisensor-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const slides = [
    {
      tabTitle: "Dome Cameras",
      title: "Get full enterprise protection in minutes",
      description:
        "Get the most from your surveillance with our cutting-edge dome cameras, engineered for maximum coverage and minimal intrusion. Adapt to any environment with unparalleled protection.",
      image: imageData.dome,
      useRoundImage: true,
      bullets: [
        "Protect your entire facility with comprehensive coverage",
        "Streamline security operations with bandwidth-optimized feeds",
        "Safeguard your organization without disrupting the environment",
      ],
    },
    {
      tabTitle: "Fisheye Cameras",
      title: "Maximize visibility with 360-degree coverage",
      description:
        "Enhance your surveillance strategy with our advanced fisheye cameras, designed for expansive coverage and enhanced situational awareness. Monitor vast areas with a single device, providing comprehensive protection and unmatched flexibility.",
      image: imageData.fisheye,
      useRoundImage: true,
      bullets: [
        "Eliminate blind spots with realtime 360° surveillance",
        "Cover vast spaces efficiently with a single camera",
        "Enhance situational awareness with immersive video control",
      ],
    },
    {
      tabTitle: "Bullet Cameras",
      title: "Long range visibility paired with powerful analytics",
      description:
        "Maximize your long range surveillance with our robust bullet cameras. Leverage the power of AI analytics and weather-resistant design for uncompromising protection in any environment.",
      bullets: [
        "Monitor distant areas with powerful zoom and 4K clarity",
        "Respond faster with instant on-device footage analysis",
        "Protect outdoor areas with weather-resistant smart cameras",
      ],
      image: imageData.bullet,
      useRoundImage: true,
    },
    {
      tabTitle: "Multisensor Cameras",
      title: "Uncompromised coverage across large environments",
      description:
        "Optimize large-scale surveillance with our advanced multisensor cameras. Gain expansive coverage and intelligent analytics in a single robust device, streamlining security across your most challenging environments.",
      bullets: [
        "Capture every angle in expansive areas with four-in-one surveillance",
        "Improve emergency response with AI-powered multisensor analytics",
        "Simplify large-scale security with efficient multisensor deployment",
      ],
      image: imageData.multisensor,
    },
  ];
  return (
    <InfoSlider
      title="Reshape your surveillance with flexible smart cameras"
      data={slides}
    />
  );
};

export default CameraInfoSlider;
