import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

const sectionInner = css`
  display: flex;
  justify-content: center;
  width: 100%;
  user-select: none;
`;

const carouselWrapper = css`
  max-width: 1390px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  -webkit-mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
  mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
`;
const carouselInner = css`
  width: max-content;
  display: flex;
  gap: 2.5rem;
  animation: scroll 40s linear infinite;
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 1.25rem));
    }
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  width: 118px;
`;

const badgeStyles = css`
  width: 118px;
  height: 136px;
  transform: scale(0.9) !important;
`;

const G2BadgeSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      badge1: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge2: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge3: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge4: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge5: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge6: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-6.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge7: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-7.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge8: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-8.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge9: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-9.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge10: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-10.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      badge11: file(
        relativePath: { eq: "components/common/img/g2-badges/badge-11.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const badges = [
    data.badge1,
    data.badge2,
    data.badge3,
    data.badge4,
    data.badge5,
    data.badge6,
    data.badge7,
    data.badge8,
    data.badge9,
    data.badge10,
    data.badge11,
  ];

  return (
    <div className={carouselWrapper}>
      <div className={sectionInner}>
        <div className={carouselWrapper}>
          <div
            className={carouselInner}
            style={{ "--width": [...badges, ...badges].length }}
          >
            {[...badges, ...badges].map((badge, index) => (
              <CarouselItem key={index}>
                <GatsbyImage
                  image={getImage(badge)}
                  alt=""
                  objectPosition="center"
                  className={badgeStyles}
                  loading="eager"
                />
              </CarouselItem>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default G2BadgeSlider;
