import { TextContainer } from "components/rhombus-UI/theme/containers";
import { styled } from "@linaria/react";
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import stanceLogo from "./assets/stance-logo.png";
import luxerOneLogo from "./assets/luxerone-logo.png";
import jesuitLogo from "./assets/jesuit-logo.png";
import twentyFourHourLogo from "./assets/twenty-four-hour-logo.svg";
import adamsGroupLogo from "./assets/adams-group-logo.png";
import stanceAuthor from "./assets/stance-author.png";
import luxerOneAuthor from "./assets/luxer-one-author.png";
import jesuitAuthor from "./assets/jesuit-author.png";
import elie from "./assets/elie.png";
import scott from "./assets/scott-s.png";
import { useIsVisible } from "hooks/useIsVisible";
import useRelativeScrollDetection from "hooks/useRelativeScrollDetection";

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${props => (props.fullSize ? "500px" : "374px")};
  width: 100%;
  gap: ${props => (props.fullSize ? "2.5rem" : "1.25rem")};
  cursor: ${props => (props.isDragging ? "grabbing" : "grab")};
  user-select: none;
  overflow: hidden;
  @media (max-width: 600px) {
    min-height: 374px;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 0.25rem;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 4px solid
    ${props => (props.active ? "var(--blue-500)" : "var(--nuetral-200)")};
  width: calc((100% / 3) - (((3 - 1) / 3) * 0.25rem));
  padding: 1rem;
  transition: all 250ms;
  cursor: pointer;
  img {
    width: auto;
    max-width: 90%;
    height: 40px;
    object-fit: contain;
    filter: ${props => (props.active ? "opacity(1)" : "opacity(0.25)")};
    transition: all 250ms;
    image-rendering: optimizeQuality;
  }
  @media (max-width: 820px) {
    flex-shrink: 0;
  }
`;

const Quote = styled.q`
  color: var(--blue-800);
  font-size: ${props => (props.fullSize ? "48px" : "24px")};
  font-weight: 900;
  text-align: center;
  line-height: 180%;
  width: 100%;
  max-width: ${props => (props.fullSize ? "1131px" : "600px")};
  span {
    color: var(--blue-500);
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const AuthorPicture = styled.div`
  background-color: var(--nuetral-200);
  height: 84px;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  img {
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const AuthorInfo = styled(TextContainer)`
  h3 {
    color: var(--blue-800);
    font-size: 20px;
    font-weight: 800;
  }

  p {
    color: var(--nuetral-500);
  }
`;

const REVIEWS = {
  STANCE: {
    logoSrc: stanceLogo,
    quote:
      "Rhombus is an incredible addition to our store security infrastructure. <span>The benefit to our loss prevention efforts has been massive.</span>",
    author: {
      image: stanceAuthor,
      name: "Tim Rattanavilay",
      role: "Senior IT Director, Stance",
    },
  },
  LUXER_ONE: {
    logoSrc: luxerOneLogo,
    quote:
      "We have a system that is able to <span>operate smoothly no matter how many cameras</span> we add.",
    author: {
      image: luxerOneAuthor,
      name: "Arik Levy",
      role: "CEO, Luxer One",
    },
  },
  JESUIT: {
    logoSrc: jesuitLogo,
    quote:
      "Rhombus allows us to be everywhere to <span>better protect our students and staff.</span>",
    author: {
      image: jesuitAuthor,
      name: "Gerry Lane",
      role: "Director of Campus Safety, Jesuit High School",
    },
  },
  TWENTY_FOUR_HOUR: {
    logoSrc: twentyFourHourLogo,
    quote:
      "The ability to <span>access footage remotely and securely with great camera quality</span> has been paramount in asset protection.",
    author: {
      image: elie,
      name: "Elie Kordahy",
      role: "General Manager, 24 Hour Fitness",
    },
  },
  ADAMS_GROUP: {
    logoSrc: adamsGroupLogo,
    quote:
      "The <span>artificial intelligence functions</span> of Rhombus set it apart from its competitors, and we found a <span>lot of value in its detection capabilities.</span>",
    author: {
      image: scott,
      name: "Scott Shelton",
      role: "IT Manager, Adams Group",
    },
  },
};

const TABS = ["STANCE", "LUXER_ONE", "JESUIT"];
const TABS_FULL_SIZE = [
  "STANCE",
  "LUXER_ONE",
  "JESUIT",
  "TWENTY_FOUR_HOUR",
  "ADAMS_GROUP",
];

const ReviewSlider = ({ fullSize = false }) => {
  const tabsMap = fullSize ? TABS_FULL_SIZE : TABS;
  const [currentTab, setCurrentTab] = useState(tabsMap[0]);
  const [isDragging, setIsDragging] = useState(false);
  const scrollDetected = useRelativeScrollDetection();

  const intervalRef = useRef(null);
  const tabsRef = useRef({});
  const containerRef = useRef(null);

  const isVisible = useIsVisible(containerRef);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentTab(prevTab => {
        const currentIndex = tabsMap.indexOf(prevTab);
        const nextIndex = (currentIndex + 1) % tabsMap.length;
        return tabsMap[nextIndex];
      });
    }, 10000);
  };

  useEffect(() => {
    if (isVisible && !scrollDetected) {
      startInterval();
      return () => clearInterval(intervalRef.current);
    }
  }, [isVisible, scrollDetected]);

  useEffect(() => {
    let isMounted = true;
    if (
      tabsRef.current[currentTab] &&
      isMounted &&
      isVisible &&
      !scrollDetected
    ) {
      requestAnimationFrame(() => {
        tabsRef.current[currentTab].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      });
    }
    return () => {
      isMounted = false;
    };
  }, [currentTab, isVisible, scrollDetected]);

  const handleSwipe = ({ dir }) => {
    clearInterval(intervalRef.current);
    setCurrentTab(prevTab => {
      const currentIndex = tabsMap.indexOf(prevTab);
      let nextIndex;
      if (dir === "Left") {
        nextIndex = (currentIndex + 1) % tabsMap.length;
      } else if (dir === "Right") {
        nextIndex = (currentIndex - 1 + tabsMap.length) % tabsMap.length;
      } else {
        return prevTab;
      }
      return tabsMap[nextIndex];
    });
    startInterval();
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe({ dir: "Left" });
    },
    onSwipedRight: () => {
      handleSwipe({ dir: "Right" });
    },
    trackMouse: true,
    onSwiping: () => setIsDragging(true),
    onSwiped: () => setIsDragging(false),
  });

  return (
    <SliderContainer {...handlers} isDragging={isDragging} fullSize={fullSize}>
      <Tabs ref={containerRef}>
        {tabsMap.map(tab => (
          <Tab
            key={tab}
            ref={el => (tabsRef.current[tab] = el)}
            active={tab === currentTab}
            onClick={() => {
              setCurrentTab(tab);
              if (tabsRef.current[tab]) {
                tabsRef.current[tab].scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "center",
                });
              }
            }}
          >
            <img
              src={REVIEWS[tab].logoSrc}
              alt={`${REVIEWS[tab].author.name}'s review on Rhombus Security Cameras`}
            />
          </Tab>
        ))}
      </Tabs>
      <Quote
        fullSize={fullSize}
        dangerouslySetInnerHTML={{ __html: REVIEWS[currentTab].quote }}
      />
      <Author>
        <AuthorPicture>
          <img
            src={REVIEWS[currentTab].author.image}
            alt={REVIEWS[currentTab].author.name}
          />
        </AuthorPicture>
        <AuthorInfo>
          <h3>{REVIEWS[currentTab].author.name}</h3>
          <p>{REVIEWS[currentTab].author.role}</p>
        </AuthorInfo>
      </Author>
    </SliderContainer>
  );
};

export default ReviewSlider;
