// @ts-nocheck
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import React from "react";
import { FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Arrow from "./img/blue-arrow.png";
import { Link } from "gatsby";
import { SectionTitle, TitleMed } from "components/rui/typography";

const sectionContainer = css`
  padding: 4rem 20px;
  display: flex;
  justify-content: center;
`;

const sectionInner = css`
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const swiperContainer = css`
  :not(:has(.swiper-scrollbar-lock)) {
    .swiper-wrapper {
      padding-bottom: 2.5rem;
    }
  }
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 0.5rem;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--nuetral-200);
    border-radius: 1rem;
    .swiper-scrollbar-drag {
      background: var(--primary-bg);
      border-radius: 1rem;
      width: 100%;
      cursor: grab;
    }
  }
`;

const slideWrapper = css`
  width: 364px;
  height: 515px !important;
  flex-shrink: 0;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
`;

const slideImage = css`
  // Gatbsy Image styles must be forced...I know not best practice
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 0 !important;
  filter: brightness(0.8) !important;
`;

const slideInner = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  justify-content: flex-end;
  text-decoration: none;
  -webkit-appearance: none;
  color: var(--white);
  h3,
  p {
    width: 80%;
  }
  h3 {
    font-size: 26px;
    font-weight: 800;
    line-height: normal;
  }
`;

const link = css`
  color: var(--white);
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-decoration: none;
  img {
    height: 10px;
    object-fit: contain;
    transition: all 250ms;
    filter: grayscale(1) brightness(10);
  }
  :hover {
    img {
      margin-left: 5px;
    }
  }
`;

const FeatureSlider = ({ data }) => {
  return (
    <section className={sectionContainer}>
      <div className={sectionInner}>
        <SectionTitle>{data.title}</SectionTitle>
        <div className={swiperContainer}>
          <Swiper
            slidesPerView={1.15}
            mousewheel
            freeMode
            breakpoints={{
              425: {
                slidesPerView: 1.25,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 150,
                  snapOnRelease: true,
                },
              },
              625: {
                slidesPerView: 1.5,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 150,
                  snapOnRelease: true,
                },
              },
              900: {
                slidesPerView: 2.5,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 250,
                  snapOnRelease: true,
                },
              },
              1230: {
                slidesPerView: 3.5,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 329,
                  snapOnRelease: true,
                },
              },
            }}
            speed={600}
            scrollbar={{
              enabled: true,
              draggable: true,
              dragSize: 125,
              snapOnRelease: true,
            }}
            modules={[Scrollbar, Mousewheel, FreeMode]}
            className="mySwiper"
            preventInteractionOnTransition={true}
            spaceBetween={20}
          >
            {data.slides.map((slide) => (
              <SwiperSlide className={slideWrapper}>
                <GatsbyImage
                  image={getImage(slide.image)}
                  alt={slide.title}
                  className={slideImage}
                />
                <Link className={slideInner} to={slide.link.url}>
                  <h3>{slide.title}</h3>
                  <p>{slide.description}</p>
                  <Link href={slide.link.url} className={link}>
                    {slide.link.text}
                    <img src={Arrow} />
                  </Link>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default FeatureSlider;
