import { animated, useSpring } from "@react-spring/web";
import RedirectIcon from "components/common/icon-components/RedirectIcon";
import { Link } from "gatsby";
import { css } from "@linaria/core";
import React, { useEffect } from "react";

import {
  menuColumn,
  MenuLink,
  MenuLinks,
  MenuTitle,
  Tag,
} from "../styles/HeaderStyles";

const menuContainer = css`
  display: flex;
  width: 100%;
  height: auto;
`;

const MegaMenuContent = ({ menuData }) => {
  const [popInLeft, set] = useSpring(
    () => ({
      from: { opacity: 0, marginLeft: "-1rem" },
      to: { opacity: 1, marginLeft: "0" },
      delay: 500,
    }),
    []
  );

  useEffect(() => {
    set({
      from: { opacity: 0, marginLeft: "-1rem" },
      to: { opacity: 1, marginLeft: "0" },
    });
  }, [menuData]);

  const handleNavClass = (menuTitle) => {
    if (menuTitle === "platform") {
      return "platform-column";
    }
    return menuColumn;
  };

  return (
    <div className={menuContainer}>
      {menuData.map((menu) => (
        <animated.nav
          className={handleNavClass(menu.title)}
          key={menu.title}
          style={popInLeft}
        >
          <MenuTitle>{menu.title}</MenuTitle>
          <MenuLinks>
            {menu.links.map((link) => (
              <MenuLink>
                <Link
                  target={link.external ? "_blank" : "_self"}
                  key={link.name}
                  to={link.path}
                  activeClassName="active"
                >
                  {link.name}
                  {link.tag && <Tag>{link.tag}</Tag>}
                  {link.external && <RedirectIcon color="var(--nuetral-400)" />}
                </Link>
              </MenuLink>
            ))}
          </MenuLinks>
        </animated.nav>
      ))}
    </div>
  );
};

export default MegaMenuContent;
