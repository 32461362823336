import CTAPage2 from "components/landing-pages/CTA/CTAPage2";
import React, { useEffect, useState } from "react";
import image from "components/landing-pages/CTA/assets/cameras-1.png";
import imageMobile from "components/landing-pages/CTA/assets/cameras-mobile.png";
import { CTA_4_KEYWORD_COPY } from "components/data/landing-page-data";

export default function CTAPage4() {
  const [headline, setHeadline] = useState("");
  useEffect(() => {
    const defaultHeadline =
      "Take the next step— Start a risk-free trial with Rhombus' Cameras. Install and start viewing footage from any device in seconds";
    const keyWord = localStorage.getItem("utm_keyword");
    setHeadline(CTA_4_KEYWORD_COPY[keyWord] ?? defaultHeadline);
  }, []);

  return (
    <CTAPage2
      bgImage={image}
      bgImageMobile={imageMobile}
      title={"Try Rhombus in a Risk Free Trial"}
      subTitle={headline}
      lastStepTitle={"Try Rhombus in a Risk Free Trial"}
      formId={"30d2b33c-32af-4a24-8fc7-5a6fe7ee9b15"}
    />
  );
}
