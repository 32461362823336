import PageContent from "components/page/PageContent";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import React from "react";

const learnSectionInner = css`
  display: flex;
  padding: 3.2rem 0;
  gap: 3.2rem;

  @media (max-width: 1024) {
    gap: 0;
  }
`;
const learnSectionLeft = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  h2 {
    font-size: 32px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
const learnSectionRight = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  padding-top: 3.2rem;
  @media (max-width: 1024px) {
    padding-top: 0;
  }
`;
const learnLines = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.6rem;
  max-width: 600px;
  width: 100%;
  h2 {
    font-size: 20px;
    line-height: 1;
    @media (min-width: 1024px) {
      display: none;
    }
  }
`;
const imageClass = css`
  max-width: 570px;
  border-radius: 1.6rem;
  @media (max-width: 1024px) {
    display: none !important;
  }
`;

const learnLine = css`
  display: flex;
  gap: 1.6rem;
  .gatsby-image-wrapper {
    overflow: visible;
  }
`;

const check = css`
  height: 1.6rem;
  width: 1.6rem;
  aspect-ratio: 1;
  overflow: visible;
`;

const LearnSection = ({ learn, learnImage }) => {
  const image = getImage(learnImage);
  return (
    <section>
      <PageContent>
        <div className={learnSectionInner}>
          <div className={learnSectionLeft}>
            <h2>What You&apos;ll Learn</h2>
            <GatsbyImage
              image={image}
              alt={learn[0]}
              objectFit="contain"
              objectPosition="top"
              className={imageClass}
            />
          </div>
          <div className={learnSectionRight}>
            <div className={learnLines}>
              <h2>What You&apos;ll Learn</h2>
              {learn.map((item, index) => (
                <div key={index} className={learnLine}>
                  <StaticImage
                    src="./img/check.png"
                    alt="check"
                    objectPosition="center"
                    className={check}
                  />
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </PageContent>
    </section>
  );
};

export default LearnSection;
