import { styled } from "@linaria/react";
import IconBadgeReader from "components/common/icon-components/IconBadgeReader";
import IconClock from "components/common/icon-components/IconClock";
import IconLP from "components/common/icon-components/IconLP";
import IconMapPin from "components/common/icon-components/IconMapPin";
import { FlexColumn, FlexRowCenter } from "components/rui/layout";
import { Body } from "components/rui/typography";
import React from "react";

const FloatingElementBase = styled.div`
  /* Gaussian Blue Effect */
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 100px 28px 0px rgba(24, 50, 59, 0),
    0px 64px 26px 0px rgba(24, 50, 59, 0.01),
    0px 36px 22px 0px rgba(24, 50, 59, 0.05),
    0px 16px 16px 0px rgba(24, 50, 59, 0.09),
    0px 4px 9px 0px rgba(24, 50, 59, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 2px solid var(--body-subtle);
  background: rgba(23, 50, 59, 0.5);
  color: var(--white);
  padding: 16px;
  user-select: none;
  p {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    transform: scale(0.75);
  }
  @media (max-width: 900px) {
    transform: scale(1);
  }
`;

const FloatingRound = styled(FloatingElementBase)`
  border-radius: 9999px;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 8px 24px 8px 12px;
  margin-top: 80px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  flex-shrink: 0;
`;

const AlertImage = styled.div`
  background: url("./assets/lp-alert.png");
  height: 100px;
  aspect-ratio: 2/1;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const MockButton = styled.div`
  display: flex;
  padding: 4.246px 12.737px;
  justify-content: center;
  align-items: center;
  gap: 10.614px;
  border-radius: 4px;
  background: #fff;
  color: var(--primary-bg);
`;

export const FloatingNotification = () => {
  return (
    <FloatingRound>
      <IconWrapper>
        <IconBadgeReader />
      </IconWrapper>
      <Body light>Manager's office door left ajar</Body>
    </FloatingRound>
  );
};

export const FloatingAlert = () => {
  return (
    <FloatingElementBase>
      <FlexColumn gap="12px">
        <AlertImage />
        <FlexColumn gap="8px">
          <FlexRowCenter gap="8px">
            <IconLP color="var(--white)" size="16" />
            <Body light>RKK6610</Body>
          </FlexRowCenter>
          <FlexRowCenter gap="8px">
            <IconClock color="var(--white)" />
            <Body light>Thursday, Aug 22 at 3:45 PM </Body>
          </FlexRowCenter>
          <FlexRowCenter gap="8px">
            <IconMapPin color="var(--white)" size="16" />
            <Body light>Parking Lot - West</Body>
          </FlexRowCenter>
        </FlexColumn>
        <MockButton>View Footage</MockButton>
      </FlexColumn>
    </FloatingElementBase>
  );
};
