import HeroLayout2 from "components/common/hero/HeroLayout2";
import Highlights from "components/common/Highlights";
import IndustryStripeSmall from "components/common/IndustryStripeSmall";
import InfoSlider from "components/common/InfoSlider";
import ScalabilityBlade from "components/common/ScalabilityBlade";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import GlobalLayout from "components/page/GlobalLayout";
import CustomerStoryCarousel from "components/vs/updated/CustomerStoryCarousel";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function BrandLandingPageA() {
  const renderContent = (data) => {
    const pageData = {
      header: {
        title:
          "Take Charge of Your Physical Security with the Rhombus Platform",
        paragraph:
          "Rhombus makes it radically simple to install, scale, and manage your physical with an all-in-one platform.",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        theme: "light",
      },
      featured: {
        logos: [
          {
            img: data.logo1,
            width: "118px",
            alt: "Amazon",
          },
          {
            img: data.logo2,
            width: "130px",
            alt: "Hurley",
          },
          {
            img: data.logo3,
            width: "177px",
            alt: "Aramark",
          },
          {
            img: data.logo4,
            width: "115px ",
            alt: "Stryker",
          },
          {
            img: data.logo5,
            width: "149px",
            alt: "Goodwill",
          },
          {
            img: data.logo6,
            width: "137px",
            alt: "Clark Construction",
          },
        ],
      },
      infoSlides: [
        {
          tabTitle: "AI Analytics",
          title: "Stay in the Know with Real-Time, AI-Powered Smart Alerts",
          description:
            "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Smart Cameras",
          title: "Protect What Matters Most with Powerful Camera Solutions",
          description:
            "Rhombus suite of dome, fisheye, and bullet cameras deliver exceptional performance and reliability for businesses of all sizes. Capture, detect, and manage security events from a single pane of glass.",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Environmental Monitoring",
          title: "Create Healthier Spaces and Manage Climate-Controlled Areas",
          description:
            "Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with real-time alerts and synchronized video.",
          image: data.infoSlide3,
        },
        {
          tabTitle: "Access Control",
          title: "Unify Building Management Like Never Before",
          description:
            "Pair seamlessly with the Rhombus Platform for all-in-one security at scale. Manage all aspects of your spaces from a single pane of glass to streamline operations, eliminate complexity, and improve safety.",
          image: data.infoSlide4,
        },
        {
          tabTitle: "Alarm Monitoring",
          title:
            "Protect Your Spaces Around the Clock with Professional Monitoring",
          description:
            "Smart alarms, real agents. Protect your buildings 24/7/365 with modern alarm monitoring that combines automatic security alerts with live monitoring services.",
          image: data.infoSlide5,
        },
      ],
      customerStories: {
        title: "Industry Leaders Trust Rhombus as their Security Solution",
        cards: [
          {
            img: data.story1,
            logo: {
              img: data.cslogo1,
              width: "131px",
              alt: "University Area CDC",
            },
            quote:
              "“I feel like the Rhombus team genuinely cares about our organization and the problems we're trying to solve—they were never just trying to sell us cameras.”",
          },
          {
            img: data.story2,
            logo: {
              img: data.cslogo2,
              width: "143px",
              alt: "Blake's Lota Burger",
            },
            quote:
              "“Similar tech on the surface, but Rhombus had more features, the portal was more user-friendly, and it had everything we needed. That was the start of our wonderful partnership.”",
          },
          {
            img: data.story3,
            logo: {
              img: data.cslogo3,
              width: "217px",
              alt: "Highlands School District",
            },
            quote:
              "“My biggest priority was not the latest discount another company could give me, but that my Superintendent will say that I chose the right partner and security platform in five years. That’s why I chose Rhombus over Verkada.”",
          },
        ],
      },
      highlights: {
        title: "Your Security Comes First at Rhombus",
        image: data.highlightImage,
        list: [
          {
            title: "Peace of Mind",
            description:
              "Experience stress-free physical security with an industry-leading 10-year warranty, automatic updates, and vigilant health monitoring.",
          },
          {
            title: "A Perfect Track Record",
            description:
              "Receive immediate notice of potential threats and take swift and informed action. Share video and coordinate with first responders.",
          },
          {
            title: "World-Class Support & Care",
            description:
              "Get phenomenal support from a team committed to your success with 24/7 US-based support and a dedicated account manager.",
          },
        ],
      },
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Physical Security Modernized | Rhombus™</title>
          <meta
            name="description"
            content="Protect your people & assets from anywhere with Rhombus’ award winning cloud-managed physical security suite. A safer future starts with Rhombus."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2
          data={pageData.header}
          theme={pageData.header.theme}
          formTitle={CTA_BUTTON_COPY}
          buttonText={CTA_BUTTON_COPY}
          width="953px"
          height="600px"
        />
        <IndustryStripeSmall logos={pageData.featured.logos} theme="dark" />
        <InfoSlider
          data={pageData.infoSlides}
          title="An All-in-One Platform"
          button
        />
        <Highlights data={pageData.highlights} center />
        <ScalabilityBlade />
        <TrustedPartnerSection />
        <CustomerStoryCarousel
          data={pageData.customerStories.cards}
          title={pageData.customerStories.title}
        />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/why-rhombus/img/why-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: { eq: "components/why-rhombus/img/why-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/landing-pages/search/img/smart-cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e50-round-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/landing-pages/search/img/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: {
          eq: "components/alarms/updated/img/alert-verification-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo1: file(
        relativePath: { eq: "components/common/logos/uacdc-white-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo2: file(
        relativePath: { eq: "components/common/logos/blakes-lotaburger.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo3: file(
        relativePath: {
          eq: "components/common/logos/highlands-school-district.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      story1: file(
        relativePath: { eq: "components/vs/updated/img/story-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story2: file(
        relativePath: { eq: "components/vs/updated/img/story-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story3: file(
        relativePath: { eq: "components/vs/updated/img/story-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/amazon-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      highlightImage: file(
        relativePath: {
          eq: "components/landing-pages/search/img/security-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
