import React from "react";
import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import { graphql, useStaticQuery } from "gatsby";
import RctpProgramAccordian from "components/RCTP-Program/RctpProgramAccordian";
import RhombusBlade from "components/common/RhombusBlade";
import UpComingTrainings from "components/RCTP-Program/UpComingTrainings";
import WistiaVideoSection from "components/common/WistiaVideoSection";
import CTASection from "components/common/CTA/CTASection";

const videoId = "60nd6ynnat";

export default function RctpProgram() {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/RCTP-Program/img/rctp-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      trainingImage: file(
        relativePath: { eq: "components/RCTP-Program/img/custom-training.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const headerSection = {
    preTitle: "RCTP PROGRAM",
    title: "Become a Certified Rhombus Partner",
    description:
      "Our comprehensive Rhombus Certified Technical Professional (RCTP) Training Program equips you with the knowledge and skills to confidently recommend, design, and implement Rhombus security solutions for any project.",
    image: data.headerImage,
    button1: {
      text: "Browse Locations",
      path: "/rctp/training-sessions#event-locations",
    },
  };

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus Certified Technical Professional Training</title>
        <meta
          name="description"
          content="Become a Rhombus technical expert with the knowledge and skills to confidently recommend, design, and implement Rhombus security solutions for any project."
        />
        <script
          src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`}
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <HeroLayout1 data={headerSection} />
      <WistiaVideoSection
        videoId={videoId}
        title="Rhombus Certified Technical Professional (RCTP) Training"
        description={
          <>
            Our intensive, one-day RCTP training program is your fast track to
            mastering the entire Rhombus product line. Immerse yourself in the
            intricate world of Rhombus cameras, access control, sensors, alarms,
            and more.
            <br></br>
            <br></br>
            <b> Watch the video to learn what a day with us at RCTP is like!</b>
          </>
        }
      />
      <RctpProgramAccordian />
      <UpComingTrainings />
      <CTASection
        image={data.trainingImage}
        preTitle={"Bespoke Enablement"}
        title={"Tailored Training for Your Team"}
        blurb={
          "Want to bring RCTP to your team on your schedule? We offer customized training sessions designed around your unique needs - whether it's a specific curriculum, agenda, or location. Let's build the perfect enablement experience together."
        }
        buttonCopy={"Request a Custom Training"}
        buttonLink={"https://form.jotform.com/250794178129163 "}
        useHref
      />
      <RhombusBlade
        fancy
        reverse
        title="Becoming a Partner is Simple"
        blurb="Submit your application online in 5 minutes, and our Partner Team will reach out within two business days."
        button={{
          text: "Apply",
          path: "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
          useHref: true,
          target: "_blank",
        }}
      />
    </GlobalLayout>
  );
}
