import FadeInXAxis from "components/common/animated-sections/FadeInXAxis";
import TextLinkV2 from "components/common/TextLinkV2";
import {
  DefaultFlexContainer,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { FancyTitle, TitleMed } from "components/rhombus-UI/theme/typography";
import { styled } from "@linaria/react";
import React from "react";
import { Converter } from "showdown";

const AboutSectionInner = styled(SectionInner)`
  flex-direction: column;
  gap: 5rem;
`;

const AboutRow = styled(DefaultFlexContainer)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const AboutColumn = styled(SectionCol)`
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

const AboutImage = styled.img`
  width: 100%;
  max-width: 665px;
  flex-shrink: 0;
  object-fit: contain;
  object-position: top;
`;

const Logo = styled.img`
  object-fit: contain;
  @media (max-width: 600px) {
    max-width: 50%;
  }
`;

const AboutSection = ({
  title = "About",
  rows = [
    {
      title: "",
      description: "",
      link: "",
      image: "",
      logo: { src: "", alt: "", width: "", height: "" },
      name: "",
    },
  ],
}) => {
  const converter = new Converter();

  return (
    <SectionContainer style={{ paddingTop: 0 }}>
      <AboutSectionInner>
        <FancyTitle style={{ textAlign: "center" }}>{title}</FancyTitle>
        {rows.map(row => {
          const {
            title,
            description,
            image,
            link,
            logo: { src = null, alt = null, height = null, width = null } = {},
            name,
          } = row;
          const _description = converter.makeHtml(description);
          return (
            <AboutRow key={title}>
              <AboutColumn>
                <FadeInXAxis reverse>
                  <TextContainer style={{ gap: "1.5rem" }} width="665px">
                    {!!src && (
                      <Logo src={src} alt={alt} height={height} width={width} />
                    )}
                    <TitleMed color="var(--blue-500)">{title}</TitleMed>
                    <div dangerouslySetInnerHTML={{ __html: _description }} />
                    <TextLinkV2
                      path={link}
                      title={`Learn more about ${name}`}
                      target="_blank"
                    />
                  </TextContainer>
                </FadeInXAxis>
              </AboutColumn>
              <AboutColumn>
                <AboutImage src={image} />
              </AboutColumn>
            </AboutRow>
          );
        })}
      </AboutSectionInner>
    </SectionContainer>
  );
};

export default AboutSection;
