import { styled } from "@linaria/react";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap};
  flex-grow: ${(props) => (props.grow ? 1 : "unset")};
  width: ${(props) => (props.grow ? "100%" : "unset")};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.gap};
  flex-grow: ${(props) => (props.grow ? 1 : "unset")};
  width: ${(props) => (props.grow ? "100%" : "unset")};
`;

export const FlexRowWrap = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const FlexColumnCenter = styled(FlexColumn)`
  justify-content: center;
`;

export const FlexColumnEnd = styled(FlexColumn)`
  justify-content: flex-end;
`;

export const FlexRowCenter = styled(FlexRow)`
  align-items: center;
  gap: ${(props) => props.gap};
`;

export const FlexRowCenterCenter = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.gap};
`;
export const FlexRowEnd = styled(FlexRow)`
  justify-content: flex-end;
  gap: ${(props) => props.gap};
`;

export const FlexRowApart = styled(FlexRow)`
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.gap ?? "24px"};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
`;

// Dynamic Containers

export const ContentRow = styled(FlexRow)`
  gap: 45px;
  max-width: 100%;
  flex-grow: 1;
  @media (max-width: 1200px) {
    flex-direction: ${(props) =>
      props.reverseOnMobile ? "column-reverse" : "column"};
  }
`;

export const ContentCol = styled.div`
  flex-grow: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
