import { Link as GastbyLink } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

/**
 * Anchor tag (<a/>) with special handling for internal links.
 * Internal links open without a full page load, and the content is preloaded.
 */

/**
 * @deprecated
 */

export default class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string,
  };

  isInternal() {
    if (!this.props.to) {
      return false;
    }
    return this.props.to.indexOf("/") === 0;
  }

  render() {
    // internal links: render a special link component
    if (this.isInternal()) {
      return <GastbyLink {...this.props} />;
    }

    // exernal links: render a regular anchor tag
    const adjustedProps = Object.assign({}, this.props);
    if (adjustedProps.to) {
      adjustedProps.href = adjustedProps.to;
      delete adjustedProps.to;
    }
    delete adjustedProps.activeClassName;
    return <a {...adjustedProps} />;
  }
}
