import TextLink from "components/common/TextLink";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React, { useMemo, useState } from "react";

import { licencePricing } from "../cameras/cameraPricingData";
import {
  cameraImageClass,
  cameraImageContainer,
  cameraInfoContainer,
  cameraPriceClass,
  cardContainer,
  circleButtonSelectedClass,
  circleButtonUnselectedClass,
  licenseTypeButtonsClass,
  licenseTypeContainerClass,
  lineClass,
  smallFontClass,
  tooltipClass,
  tooltipContainer,
  tooltipIconClass,
  typeButtonSelectedClass,
  typeButtonUnselectedClass,
  typeTooltipText,
  yearsButtonsClass,
  yearsContainerClass,
} from "../styles/pricingStyles";
import TextLinkV2 from "components/common/TextLinkV2";

export default function SensorCardLicense({ info }) {
  const [licensePriceIndex, setLicensePriceIndex] = useState(0);
  const [licenseType, setLicenseType] = useState(0);
  const licenseYearData = useMemo(() => {
    return info.name === "A100"
      ? licencePricing[licenseType].prices
      : info.licensePrices;
  }, [info, licenseType]);

  const licensePricingForSensors = licencePricing.filter((pricingObject) =>
    pricingObject.for.includes(info.name)
  );

  const specLink = useMemo(() => {
    if (info.name === "E50") {
      return `/sensors/environmental-monitoring/${info.name.toLowerCase()}`;
    }
    return `/sensors/${info.name.toLowerCase()}/`;
  }, [info]);
  const imageRef = getImage(info.image);

  return (
    <div className={cardContainer}>
      <div className={cameraImageContainer}>
        <GatsbyImage
          image={imageRef}
          className={cameraImageClass}
          alt={info?.altText ?? info.name}
          objectFit="contain"
        />
      </div>
      <div className={cameraInfoContainer}>
        <div>
          <div className={lineClass}>
            <h3 className="heavy">
              {info.name} {info.description}
            </h3>
            <div className={cameraPriceClass}>{`$${info.price}`}</div>
          </div>
          <TextLinkV2 path={specLink} target="_blank" title={"View Specs"} />
        </div>
        <div>
          <div className={licenseTypeContainerClass}>
            <div className={lineClass}>
              <h3>License</h3>
              <div className={cameraPriceClass}>{`$${licenseYearData[
                licensePriceIndex
              ].price.toLocaleString()}`}</div>
            </div>
            <div className={yearsContainerClass}>
              <h3>Years</h3>
              <div className={yearsButtonsClass}>
                {licenseYearData.map((p, index) => (
                  <button
                    onClick={() => setLicensePriceIndex(index)}
                    key={index}
                    className={
                      licensePriceIndex === index
                        ? circleButtonSelectedClass
                        : circleButtonUnselectedClass
                    }
                  >
                    {p.term}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {info.name === "A100" ? (
            <div className={yearsContainerClass}>
              <div className={tooltipContainer}>
                <h3>Type</h3>
                <div className={tooltipClass}>
                  <StaticImage
                    src="../img/tooltip-icon.png"
                    className={tooltipIconClass}
                    alt="License Type Tooltip"
                    placeholder="blurred"
                  />
                  <span className={typeTooltipText}>
                    AI-features and cloud archiving not included in the
                    Professional License. For a detailed comparison, visit our{" "}
                    <TextLink to="/license-comparison/audio">
                      License Comparison
                    </TextLink>{" "}
                    page.
                  </span>
                </div>
              </div>
              <div className={licenseTypeButtonsClass}>
                {licensePricingForSensors.map((p, index) => (
                  <button
                    onClick={() => setLicenseType(index)}
                    key={index}
                    className={
                      licenseType === index
                        ? typeButtonSelectedClass
                        : typeButtonUnselectedClass
                    }
                  >
                    {p.name}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <div className={lineClass}>
            <h3 className="heavy">Total Cost</h3>
            <h3>
              {`$${(
                info.price + licenseYearData[licensePriceIndex].price
              ).toLocaleString()}`}
            </h3>
          </div>
          <div className={lineClass}>
            <p className={smallFontClass}>Includes hardware and license</p>
            <p className={smallFontClass}>
              <strong>(MSRP USD)</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
