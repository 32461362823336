import React from "react";

const IconWarning = ({ size = 57, color = "var(--blue-500)" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9086 8.72891L5.85298 40.5447C5.46015 41.225 5.25227 41.9963 5.25002 42.7818C5.24777 43.5674 5.45123 44.3399 5.84015 45.0224C6.22908 45.7049 6.78992 46.2737 7.4669 46.6722C8.14387 47.0707 8.91338 47.2851 9.69889 47.2939H47.8149C48.6 47.2848 49.3692 47.0705 50.0458 46.6721C50.7225 46.2737 51.2831 45.7051 51.6719 45.023C52.0608 44.3408 52.2644 43.5688 52.2624 42.7835C52.2604 41.9983 52.053 41.2273 51.6608 40.5471L32.6052 8.72655C32.2042 8.06481 31.6394 7.51764 30.9653 7.13787C30.2912 6.75811 29.5306 6.55859 28.7569 6.55859C27.9832 6.55859 27.2225 6.75811 26.5484 7.13787C25.8743 7.51764 25.3095 8.06481 24.9086 8.72655V8.72891Z"
        stroke={color}
        stroke-width="2.61713"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="26.4023"
        y="19.0938"
        width="4.70161"
        height="14.1048"
        rx="2.35081"
        fill={color}
      />
      <circle cx="28.7532" cy="40.2492" r="2.35081" fill={color} />
    </svg>
  );
};

export default IconWarning;
