import React, { useEffect, useState } from "react";
import {
  EventTag,
  SpeakingSessionTag,
  StyledEventCard,
} from "./styles/event-styles";
import { Link } from "gatsby";
import {
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import RhombusButton from "components/common/RhombusButton";
import {
  DefaultFlexContainer,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { getMonth, getDay } from "components/RCTP-Program/util/rctp-functions";
import useIsMobile from "hooks/useIsMobile";
import TextLinkV2 from "components/common/TextLinkV2";
import { useSpring, animated } from "@react-spring/web";

const EventCard = ({ eventData }) => {
  const event = eventData.node.frontmatter;
  const [hoverActive, setHoverActive] = useState(false);
  const { isMobile } = useIsMobile(700);

  const renderTag = (type) => {
    switch (type) {
      case "In-Person":
        return <EventTag>{type}</EventTag>;
      case "Virtual":
        return (
          <EventTag style={{ background: "var(--blue-900)" }}>{type}</EventTag>
        );
      case "Hybrid Event":
        return (
          <EventTag style={{ background: "var(--blue-900)" }}>{type}</EventTag>
        );
      default:
        return <></>;
    }
  };

  const handleLink = (speakingSession, title, link) => {
    const slug = title.split(" ").join("-").toLowerCase();
    if (speakingSession) {
      return `/events/in-person/${slug}`;
    } else {
      return link;
    }
  };

  const handleDate = (startDate, endDate) => {
    if (
      getMonth(startDate) + getDay(startDate) ===
      getMonth(endDate) + getDay(endDate)
    ) {
      return `${getMonth(startDate)} ${getDay(startDate)}`;
    }
    return `${getMonth(startDate)} ${getDay(startDate)}-${getDay(endDate)}`;
  };

  const handleButtonTitle = (type) => {
    if (type === "Hybrid Event") {
      return "Register";
    } else return "Join us";
  };

  const [fadeIn, api] = useSpring(() => ({}), []);

  useEffect(() => {
    api.start({
      from: {
        opacity: isMobile ? 1 : 0,
        position: "absolute",
      },
      to: {
        opacity: hoverActive || isMobile ? 1 : 0,
        position: hoverActive || isMobile ? "static" : "absolute",
      },
    });
  }, [hoverActive, isMobile]);

  return (
    <StyledEventCard
      as={Link}
      to={handleLink(event.speakingSession, event.title, event.link)}
      target="_blank"
      onMouseLeave={() => setHoverActive(false)}
      onMouseOver={() => setHoverActive(true)}
    >
      <img src={event.image} />
      {renderTag(event.eventType[0])}
      {event.speakingSession && (
        <SpeakingSessionTag>Speaking Session</SpeakingSessionTag>
      )}
      <div className="card-inner">
        <TextContainer>
          <TitleMed>{event.title}</TitleMed>
          {event.booth && <TitleSmall>Booth #{event.booth}</TitleSmall>}
          <DefaultFlexContainer
            style={{
              justifyContent: "space-between",
              marginTop: "1.25rem",
              flexDirection: "row",
            }}
          >
            {event.location && <MainParagraph>{event.location}</MainParagraph>}
            {event.eventType[0] === "Hybrid Event" && (
              <MainParagraph>Online or In-Person</MainParagraph>
            )}
            <MainParagraph style={{ textTransform: "uppercase" }}>
              {handleDate(event.startDate, event.endDate)}
            </MainParagraph>
          </DefaultFlexContainer>
        </TextContainer>
        <animated.div style={fadeIn}>
          <RhombusButton
            type="white"
            title={
              event.speakingSession
                ? "Learn More"
                : handleButtonTitle(event.eventType[0])
            }
            path={event.link}
            useHref
            target="_blank"
          />
        </animated.div>
      </div>
    </StyledEventCard>
  );
};

export default EventCard;
