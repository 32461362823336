import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  Title,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";
import { useSetAtom } from "jotai";
import { useGetQuoteTitleAtom } from "components/landing-pages/surveillance/SurveillancePageV4";

const BladeContainer = styled(SectionContainer)`
  position: relative;
  min-height: 500px;
  background: ${(props) =>
    props.darkMode
      ? "var(--blue-900)"
      : props.lightMode
        ? "var(--lp-light-bg)"
        : "var(--gradient-light)"};
  padding: ${(props) => (props.darkMode ? "5rem 2.5rem" : "5rem 1.25rem")};
  @media (max-width: 600px) {
    min-height: unset;
    padding: 5rem 1.25rem;
  }
`;
const imageStyles = css`
  width: 100% !important;
  margin-bottom: -5.5rem;
  @media (max-width: 600px) {
    margin-bottom: -8rem;
  }
`;

const BladeInner = styled(SectionInner)`
  flex-direction: row-reverse;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;
const BladeBlurb = styled(MainParagraph)`
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const Text = styled(TextContainer)`
  gap: 1.6rem;
  @media (max-width: 820px) {
    align-items: center;
    text-align: center;
  }
`;

const TrialBanner = ({
  title = "Try Rhombus for Free!",
  blurb = "See why school districts, cities, and Fortune 500 companies use Rhombus",
  darkMode = false,
  lightMode = false,
  buttonCopy = "Start Trial",
  ctaLink = "/free-trial/",
}) => {
  const setGetQuoteTitle = useSetAtom(useGetQuoteTitleAtom);

  return (
    <BladeContainer darkMode={darkMode} lightMode={lightMode}>
      <BladeInner>
        <SectionCol style={{ alignItems: "flex-end" }}>
          <StaticImage
            src="./images/rhombus-console-suite-min.png"
            className={imageStyles}
            alt="Poduim with two Rhombus cameras and a computer and phone showing the console"
            quality={100}
            placeholder="blurred"
            objectFit="contain"
          />
        </SectionCol>
        <SectionCol style={{ zIndex: "2" }} className="col">
          <Text>
            {darkMode || lightMode ? (
              <Title
                fontSize="48px"
                color={darkMode ? "var(--white)" : "var(--blue-900)"}
              >
                {title}
              </Title>
            ) : (
              <FancyTitle>{title}</FancyTitle>
            )}
            <BladeBlurb color={darkMode ? "var(--white)" : "var(--blue-900)"}>
              {blurb}
            </BladeBlurb>
            <RhombusButton
              path={ctaLink}
              type={
                darkMode ? "landing-v2" : lightMode ? "landing-v3" : "primary"
              }
              title={buttonCopy}
              onClick={() => setGetQuoteTitle(false)}
            />
          </Text>
        </SectionCol>
      </BladeInner>
    </BladeContainer>
  );
};

export default TrialBanner;
