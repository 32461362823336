import React from "react";
import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import AccessoriesV2 from "components/accessories/v2/AccessoriesV2";
import { DEFAULT_SELECTIONS } from "components/accessories/v2/constants";

export default function Accessories() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Rhombus Accessories - Enterprise Security Cameras and Sensors
        </title>
        <meta
          name="description"
          content="Accessory compatibility for Rhombus devices. Ensure successful deployment with a list of authorized Rhombus accessories."
        />
      </Helmet>
      <AccessoriesV2 defaultSelection={DEFAULT_SELECTIONS.sensors} />
    </GlobalLayout>
  );
}
