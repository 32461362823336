import GlobalLayout from "components/page/GlobalLayout";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import CameraDetail from "components/cameras/detail/CameraDetailPage";
import { FlexColumn } from "components/rui/layout";
import { Body } from "components/rui/typography";
import {
  CTA_BUTTON_COPY_6,
  LIVE_DEMO_LINK,
} from "components/data/landing-page-data";

const RelayConnectorN100 = () => {
  const data = useStaticQuery(graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/n100-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/n100-header-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/relay-connector/assets/n100-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      r120Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r120-200-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r120CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r120-200-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r500Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r500-510-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r500CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r500-510-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r360Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r360.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r360CameraMobile: file(
        relativePath: { eq: "components/cameras/img/explore-r360-mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r600Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r600.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r600CameraMobile: file(
        relativePath: { eq: "components/cameras/img/explore-r600.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const camera = {
    model: "N100",
    type: "",
    heroTitle: "N100 Relay Core",
    specTitle: "N100 Relay Core Overview and Specifications",
    description:
      "Transform yesterday's cameras to tomorrow's intelligence. Relay Core integrates existing third-party cameras for a phased migration to cloud-managed physical security.",
    webHeader: data.webImage,
    mobileHeader: data.mobileImage,
    objectPosition: "center",
    dims: data.dims,
    pdf: "/files/specs/n100.pdf",
    specSubTitle: (
      <FlexColumn gap="16px">
        <Body>
          <b>Rhombus Relay Core</b> lets organizations easily upgrade their
          existing cameras and gain the benefits of the Rhombus platform. With
          remote access, AI insights, and centralized management in a compact,
          easy-to-use form factor, Relay Core transforms third-party RTSP
          streaming cameras into smart cameras on the Rhombus console.
        </Body>
        <Body>
          The N100 uses ONVIF protocols, when supported, to ingest third-party
          cameras. Once camera streams reach the Rhombus device, video footage
          and cloud communication are fully encrypted.¹
        </Body>
      </FlexColumn>
    ),
    specs: {
      renderDimsWithHeader: true,
      column1: [
        {
          title: "Onboard Storage",
          text: ["2TB"],
        },
        {
          title: "Camera Storage",
          text: ["Up to 20 days of storage per camera²"],
        },
        {
          title: "Networking",
          text: ["1x 2.5Gb RJ45"],
        },
        {
          title: "Dimensions",
          text: ["117mm x 112 mm x 54mm"],
        },
        {
          title: "Power Supply",
          text: ["12-24VDC input, 19V 120W external PSU included"],
        },
        {
          title: "Power Consumption",
          text: ["120W max"],
        },
      ],
      column2: [
        {
          title: "N100 License Options",
          bullets: true,
          text: [
            "View and storage only with no additional license",
            "Standard Analytics with Rhombus Professional License³",
            "Advanced Analytics with Rhombus Enterprise License³",
          ],
        },
        { title: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant" },
        { title: "Rhombus 5-Year Warranty Included" },
        {
          subtle: true,
          text: [
            "¹ As camera manufacturers own their firmware updates, Rhombus cannot offer firmware updates for non-Rhombus cameras. Users will need to manage and upgrade their firmware to avoid potential security issues. ",
            "² Days of storage and number of cameras per device can vary based on camera resolution, bitrate, and motion. ",
            "³ Performance of Rhombus AI analytics can vary based on camera image quality and performance.",
          ],
        },
      ],
    },
    accessoryCompatible: true,
    sensorCompatible: false,
    exploreModels: {
      title: "Explore Rhombus Cameras",
      subtitle:
        "Find the solution that's best suited for your needs. Compare all camera models or view other models below.",
      cameraCards: [
        {
          image: data.r120Camera,
          mobileImage: data.r120CameraMobile,
          alt: "dome camera model",
          width: "189px",
          model: "Dome",
          useCase: "Ideal for most environments",
          link: "/cameras/dome-cameras/",
        },
        {
          image: data.r500Camera,
          mobileImage: data.r500CameraMobile,
          alt: "bullet camera model",
          width: "195px",
          model: "Bullet",
          useCase: "Ideal for outdoor environments",
          link: "/cameras/bullet-cameras/",
        },
        {
          image: data.r360Camera,
          mobileImage: data.r360CameraMobile,
          alt: "fisheye camera model",
          width: "195px",
          model: "Fisheye",
          useCase: "Ideal for immersive coverage",
          link: "/cameras/fisheye-cameras/",
        },
        {
          image: data.r600Camera,
          mobileImage: data.r600CameraMobile,
          alt: "fisheye camera model",
          width: "195px",
          model: "Multisensor",
          useCase: "Ideal for complete coverage",
          link: "/cameras/multisensor-cameras/",
        },
      ],
    },
  };
  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>
          Cloud Benefits for Existing Cameras | Relay Core Connector | Rhombus
        </title>
        <meta
          name="description"
          content="Rhombus Relay Core provides a flexible path for organizations to migrate existing cameras to cloud-managed physical security. Start a free trial to modernize your existing cameras today. "
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/n100-meta.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/n100-meta.png"
        />
      </Helmet>
      <CameraDetail
        camera={camera}
        pricingCopy={CTA_BUTTON_COPY_6}
        pricingLink={LIVE_DEMO_LINK}
      />
    </GlobalLayout>
  );
};

export default RelayConnectorN100;
