import React from "react";
import PopupTemplate from "./PopupTemplate";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  DefaultFlexContainer,
  SectionCol,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { Link, StaticQuery, graphql } from "gatsby";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { useSetAtom } from "jotai";
import { showPopupAtom } from "components/page/atoms/globalAtoms";
import { ButtonBase } from "components/rhombus-UI/theme/buttons";

const imageStyles = css`
  width: 100% !important;
  height: 100% !important;
  border-radius: 0.5rem !important;
  overflow: hidden !important;
`;

const PopupText = styled(TextContainer)`
  @media (max-width: 820px) {
    text-align: center;
    align-items: center;
  }
`;

export default function SecurityChecklistPopup() {
  const setShowPopup = useSetAtom(showPopupAtom);
  const renderContent = data => {
    return (
      <PopupTemplate>
        <DefaultFlexContainer style={{ alignItems: "unset" }}>
          <SectionCol>
            <GatsbyImage
              image={getImage(data.popupImage)}
              alt="Rhombus Physical Security Checklist"
              objectFit="cover"
              className={imageStyles}
              objectPosition="top"
            />
          </SectionCol>
          <SectionCol>
            <PopupText>
              <TitleMed>Download the Physical Security Checklist</TitleMed>
              <MainParagraph>
                How secure is your organization? Assess your security operations
                with a comprehensive step-by-step guide and measure your setup
                against modern best practices.
              </MainParagraph>
              <ButtonBase
                as={Link}
                to="/lp/physical-security-checklist/"
                onClick={() => setShowPopup(false)}
                style={{ marginTop: "0.5rem" }}
              >
                Download The Checklist
              </ButtonBase>
            </PopupText>
          </SectionCol>
        </DefaultFlexContainer>
      </PopupTemplate>
    );
  };
  const GET_IMAGE = graphql`
    query {
      popupImage: file(
        relativePath: {
          eq: "components/landing-pages/img/security-report-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGE} render={renderContent} />;
}
