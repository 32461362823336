import { useEffect, useState } from "react";

const useCompatibleVideo = (hevc, mp4) => {
  const [video, setVideo] = useState(hevc);
  const brokenBrowsers = ["firefox", "opera", "edge"];
  const currentBrowser =
    typeof window !== `undefined` ? navigator.userAgent.toLowerCase() : null;
  useEffect(() => {
    currentBrowser &&
      brokenBrowsers.find(item => {
        if (currentBrowser.includes(item)) {
          setVideo(mp4);
        }
      });
  }, []);
  return video;
};

export default useCompatibleVideo;
