import React from "react";

const IconCheckCircle = ({
  bgColor = "var(--blue-500)",
  color = "var(--white)",
  size = "20",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      style={{ flexShrink: 0 }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 10C20 15.5225 15.5225 20 10 20C4.4775 20 0 15.5225 0 10C0 4.4775 4.4775 0 10 0C15.5225 0 20 4.4775 20 10Z"
        fill={bgColor}
      />
      <path
        d="M6.01953 9.81354L9.04203 13.0385L14.8245 7.29688"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconCheckCircle;
