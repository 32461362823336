import { styled } from "@linaria/react";
import IconSliderArrow from "components/common/icon-components/IconSliderArrow";
import IconStar from "components/common/icon-components/IconStar";
import { useIsVisible } from "hooks/useIsVisible";
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 592px;
  gap: 32px;
  padding: 0 1.25rem;
  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
  user-select: none;
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  height: 300px;
`;

const Quote = styled.q`
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.033px;
`;

const SliderNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
`;

const SliderBullets = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
`;

const SliderBullet = styled.div`
  width: 8px;
  height: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#000" : "#8D8D8D")};
`;

const ArrowButton = styled.button`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  &.next {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const REVIEWS = [
  {
    quote:
      "Rhombus is an incredible addition to our store security infrastructure. The benefit to our loss prevention efforts has been massive.",
    author: {
      name: "Tim Rattanavilay",
      role: "Senior IT Director, Stance",
    },
  },
  {
    quote:
      "We have a system that is able to operate smoothly no matter how many cameras we add.",
    author: {
      name: "Arik Levy",
      role: "CEO, Luxer One",
    },
  },
  {
    quote:
      "Rhombus allows us to be everywhere to better protect our students and staff.",
    author: {
      name: "Gerry Lane",
      role: "Director of Campus Safety, Jesuit High School",
    },
  },
  {
    quote:
      "The ability to access footage remotely and securely with great camera quality has been paramount in asset protection.",
    author: {
      name: "Elie Kordahy",
      role: "General Manager, 24 Hour Fitness",
    },
  },
  {
    quote:
      "The artificial intelligence functions of Rhombus set it apart from its competitors, and we found a lot of value in its detection capabilities.",
    author: {
      name: "Scott Shelton",
      role: "IT Manager, Adams Group",
    },
  },
];

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const intervalRef = useRef(null);
  const containerRef = useRef(null);
  const isVisible = useIsVisible(containerRef);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const currentIndex = prevIndex;
        const nextIndex = (currentIndex + 1) % REVIEWS.length;
        return nextIndex;
      });
    }, 10000);
  };

  useEffect(() => {
    if (isVisible) {
      startInterval();
      return () => clearInterval(intervalRef.current);
    }
  }, [isVisible]);

  const handleClickPrev = () => {
    clearInterval(intervalRef.current);
    if (currentIndex === 0) {
      setCurrentIndex(REVIEWS.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleClickNext = () => {
    clearInterval(intervalRef.current);
    if (currentIndex === REVIEWS.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleSwipe = ({ dir }) => {
    if (dir === "Left") {
      handleClickNext();
    } else {
      handleClickPrev();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe({ dir: "Left" });
    },
    onSwipedRight: () => {
      handleSwipe({ dir: "Right" });
    },
    trackMouse: true,
    onSwiping: () => setIsDragging(true),
    onSwiped: () => setIsDragging(false),
  });

  return (
    <SliderContainer {...handlers} isDragging={isDragging} ref={containerRef}>
      <Slide>
        <div>
          <IconStar />
          <IconStar />
          <IconStar />
          <IconStar />
          <IconStar />
        </div>
        <Quote>{REVIEWS[currentIndex].quote}</Quote>
        <div>
          <b>{REVIEWS[currentIndex].author.name}</b>
          <p>{REVIEWS[currentIndex].author.role}</p>
        </div>
      </Slide>
      <SliderNavigation>
        <ArrowButton onClick={handleClickPrev}>
          <IconSliderArrow />
        </ArrowButton>
        <SliderBullets>
          {REVIEWS.map((_, index) => (
            <SliderBullet active={index === currentIndex} />
          ))}
        </SliderBullets>
        <ArrowButton className="next" onClick={handleClickNext}>
          <IconSliderArrow />
        </ArrowButton>
      </SliderNavigation>
    </SliderContainer>
  );
};

export default TestimonialSlider;
