import React, { useEffect, useMemo, useState } from "react";

import {
  cameraInfoContainer,
  cameraPriceClass,
  cardContainer,
  circleButtonSelectedClass,
  circleButtonUnselectedClass,
  licenseTypeButtonsClass,
  licenseTypeContainerClass,
  lineClass,
  smallFontClass,
  typeButtonSelectedClass,
  typeButtonUnselectedClass,
  yearsButtonsClass,
  yearsContainerClass,
} from "../styles/pricingStyles";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import {
  ALM_LICENSE_PRICING,
  LICENSE_TYPES,
  PANIC_BUTTON_DISPATCH_TEXT,
  VERIFICATION_TEXT,
} from "./constants";
import TextLinkV2 from "components/common/TextLinkV2";

export default function AlarmCard() {
  const [selectedLicense, setSelectedLicense] = useState(
    ALM_LICENSE_PRICING[0]
  );
  const [selectedSubLicense, setSelectedSublicense] = useState(null);

  const [price, setPrice] = useState(
    ALM_LICENSE_PRICING[0].priceMatrix[0].price
  );

  const priceMatrixToMap = useMemo(() => {
    if (selectedSubLicense) {
      return selectedSubLicense.priceMatrix;
    } else {
      return selectedLicense.priceMatrix;
    }
  }, [selectedSubLicense, selectedLicense]);

  useEffect(() => {
    setPrice(priceMatrixToMap[0].price);
  }, [priceMatrixToMap]);

  return (
    <div className={cardContainer}>
      <div className={cameraInfoContainer}>
        <div>
          <h3 className="heavy">Live Professional Monitoring</h3>
          <TextLinkV2
            path={"/alarm-monitoring/"}
            target="_blank"
            title={"Learn More"}
          />
        </div>
        <div>
          <div className={licenseTypeContainerClass}>
            <div className={lineClass}>
              <h3>License</h3>
              <div
                className={cameraPriceClass}
              >{`$${price.toLocaleString()}`}</div>
            </div>
            <div className={licenseTypeButtonsClass}>
              {ALM_LICENSE_PRICING.map((l) => (
                <button
                  onClick={() => {
                    if (l.license === LICENSE_TYPES.ENTERPRISE) {
                      setSelectedSublicense(l.subLicenses[0]);
                    } else {
                      setSelectedSublicense(null);
                    }
                    setSelectedLicense(l);
                  }}
                  key={l.license}
                  className={
                    selectedLicense === l
                      ? typeButtonSelectedClass
                      : typeButtonUnselectedClass
                  }
                >
                  {l.license}
                </button>
              ))}
            </div>
            {selectedLicense.license !== LICENSE_TYPES.ENTERPRISE && (
              <div>
                <MainParagraph style={{ fontSize: "14px" }}>
                  Up to <b>{selectedLicense?.monthlyVerifications}</b>{" "}
                  {selectedLicense.license === LICENSE_TYPES.STARTER
                    ? PANIC_BUTTON_DISPATCH_TEXT
                    : VERIFICATION_TEXT}
                </MainParagraph>
              </div>
            )}
          </div>
          {selectedLicense?.subLicenses && (
            <div className={licenseTypeContainerClass}>
              <div className={lineClass}>
                <h3 style={{ fontSize: "12px" }}>
                  Monthly Video Verifications
                </h3>
                <div className={cameraPriceClass}></div>
              </div>
              <div className={licenseTypeButtonsClass}>
                {selectedLicense.subLicenses.map((l) => (
                  <button
                    onClick={() => setSelectedSublicense(l)}
                    key={l.monthlyVerifications}
                    className={
                      selectedSubLicense === l
                        ? typeButtonSelectedClass
                        : typeButtonUnselectedClass
                    }
                  >
                    {l.monthlyVerifications}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div className={yearsContainerClass}>
            <h3>Years</h3>
            <div className={yearsButtonsClass}>
              {priceMatrixToMap.map((p) => (
                <button
                  onClick={() => setPrice(p.price)}
                  key={p.term}
                  className={
                    price === p.price
                      ? circleButtonSelectedClass
                      : circleButtonUnselectedClass
                  }
                >
                  {p.term}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className={lineClass}>
            <h3>Total Cost</h3>
            <h3>{`$${price.toLocaleString()}`}</h3>
          </div>
          <div className={lineClass}>
            <p className={smallFontClass}></p>
            <p className={smallFontClass}>
              <strong>(MSRP USD)</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
