import React from "react";

const IconCyberSecurity = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M7.50741 9.04102H4.87109C4.87109 9.04102 4.87109 11.5148 4.87109 16.2389C4.87109 20.963 13.2128 25.7243 13.2128 25.7243"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M22.1092 14.2729H22.1048V14.8687C22.1048 17.3671 20.4186 19.4817 18.5966 21.0245C17.6982 21.7851 16.7969 22.3808 16.1188 22.7867C15.7804 22.9892 15.4993 23.1434 15.3042 23.2464C15.2863 23.2558 15.2691 23.2648 15.2527 23.2734C15.2301 23.2624 15.206 23.2508 15.1806 23.2383C14.9697 23.1349 14.666 22.9802 14.3007 22.7771C13.5687 22.3702 12.5959 21.7731 11.6266 21.0108C9.65482 19.4603 7.84974 17.3481 7.84974 14.8687V8.00039L14.9706 5.50301L22.1472 8.19905L22.1092 14.2729Z"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M15 5.24241V2.48633L6.0625 5.46549"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M6.0612 5.46719C6.0612 6.12533 5.52767 6.65885 4.86953 6.65885C4.21139 6.65885 3.67786 6.12533 3.67786 5.46719C3.67786 4.80905 4.21139 4.27552 4.86953 4.27552C5.52767 4.27552 6.0612 4.80905 6.0612 5.46719Z"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M14.9987 26.3188C14.9987 26.9769 14.4652 27.5104 13.807 27.5104C13.1489 27.5104 12.6154 26.9769 12.6154 26.3188C12.6154 25.6606 13.1489 25.1271 13.807 25.1271C14.4652 25.1271 14.9987 25.6606 14.9987 26.3188Z"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M26.319 5.46719C26.319 6.12533 25.7855 6.65885 25.1273 6.65885C24.4692 6.65885 23.9357 6.12533 23.9357 5.46719C23.9357 4.80905 24.4692 4.27552 25.1273 4.27552C25.7855 4.27552 26.319 4.80905 26.319 5.46719Z"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M25.1286 6.65625C25.1286 6.65625 25.124 10.8437 25.1286 14.7218C25.1332 18.6 22.5228 23.2855 17.3828 25.7229"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
      <path
        d="M11.875 13.9638L13.9476 16.3111L18.0646 10.8379"
        stroke="white"
        stroke-width="1.19167"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default IconCyberSecurity;
