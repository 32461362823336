import {
  popupCookieExistsAtom,
  showPopupAtom,
} from "components/page/atoms/globalAtoms";
import {
  ModalContainer,
  PopupContainer,
} from "components/rhombus-UI/theme/containers";
import useScrollBounds from "hooks/useScrollBounds";
import { useSetAtom } from "jotai";
import Cookies from "js-cookie";
import React from "react";
import CloseIcon from "../../page/img/close@3x.png";
import { useSpring, animated } from "@react-spring/web";
import { css } from "@linaria/core";
import { CloseButton } from "components/rhombus-UI/theme/ui-elements";

const fadeInWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PopupTemplate = ({ children }) => {
  const { scrollTop } = useScrollBounds();
  const setShowPopup = useSetAtom(showPopupAtom);
  const setPopupCookie = useSetAtom(popupCookieExistsAtom);

  const handleExit = () => {
    setShowPopup(false);
    Cookies.set("physical-security-checklist-popup-session", "user-exited");
    setPopupCookie(true);
  };

  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 500 },
    }),
    []
  );

  return (
    <ModalContainer style={{ top: `${scrollTop}px` }} onClick={handleExit}>
      <animated.div style={fadeIn} className={fadeInWrapper}>
        <PopupContainer onClick={e => e.stopPropagation()}>
          <CloseButton onClick={handleExit}>
            <img src={CloseIcon} />
          </CloseButton>
          {children}
        </PopupContainer>
      </animated.div>
    </ModalContainer>
  );
};

export default PopupTemplate;
