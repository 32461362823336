import IconCheckCircle from "components/common/icon-components/IconCheckCircle";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import { Link } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import { useSetAtom } from "jotai";
import { styled } from "@linaria/react";
import React from "react";
import { useGetQuoteTitleAtom } from "./SurveillancePageV4";

const HeroContainer = styled(SectionContainer)`
  background: var(--lp-light-bg);
  @media (max-width: 600px) {
    padding: 2.5rem 1.25rem;
  }
`;

const HeroInner = styled(SectionInner)`
  flex-direction: column;
  gap: 5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    gap: 2.5rem;
  }
`;

const HeroTop = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 2.5rem;
  }
  @media (max-width: 600px) {
    gap: 1.25rem;
  }
`;

const HeroCol = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
  justify-content: space-evenly;
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

const HeroBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroTitle = styled.h1`
  color: var(--blue-800);
  font-size: 56px;
  font-weight: ${props => (props.isMobile ? 600 : 900)};
  line-height: 1.1;
  max-width: 663px;
  width: 100%;
  span {
    font-size: calc(56px * 0.7);
    font-weight: ${props => (props.isMobile ? 500 : 800)};
  }
  @media (max-width: 1300px) {
    font-size: 48px;
    span {
      font-size: calc(48px * 0.7);
    }
  }
  @media (max-width: 1075px) {
    font-size: 42px;
    span {
      font-size: calc(42px * 0.7);
    }
  }
  @media (max-width: 1024px) {
    text-align: center;
  }
  @media (max-width: 600px) {
    font-size: 32px;
    span {
      font-size: calc(32px * 0.7);
    }
  }
`;

const BulletList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.25rem;
  @media (max-width: 1024px) {
    max-width: 663px;
  }
`;

const BulletListItem = styled.li`
  width: 45%;
  display: flex;
  gap: 0.5rem;
  svg {
    margin-top: 5px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    max-width: 663px;
  }
`;

export const HeroButtonPrimary = styled.button`
  display: flex;
  width: 220px;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--cta-blue);
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 200%;
  transition: all 250ms;
  border: 2px solid transparent;
  text-decoration: none;
  :hover {
    background: var(--blue-800);
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding: 12px;
    display: block;
  }
`;

export const HeroButtonSecondary = styled(HeroButtonPrimary)`
  background: transparent;
  border-color: var(--blue-800);
  color: var(--blue-800);
  :hover {
    border-color: var(--cta-blue-light);
    background: var(--cta-blue-light);
    color: var(--white);
  }
`;

const HeroImage = styled.img`
  max-width: 663px;
  width: 100%;
  object-fit: contain;
`;

const StatRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 2.5rem;
  @media (max-width: 600px) {
    row-gap: 1.25rem;
  }
`;

const Stat = styled.div`
  text-align: center;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3,
  h4 {
    color: var(--blue-800);
    max-width: 227px;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: ${props => (props.isMobile ? 600 : 900)};
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  @media (max-width: 1024px) {
    width: 50%;

    h4 {
      font-size: 1.25rem;
    }
  }
  @media (max-width: 663px) {
    h3,
    h4 {
      max-width: 140px;
    }
  }
`;

const stats = [
  { title: "#1 Rated", subTitle: "Video Surveillance on G2" },
  { title: "50,000+", subTitle: "Locations" },
  { title: "100,000+", subTitle: "Devices Deployed" },
  { title: "5,000,000+", subTitle: "Alerts Sent Per Day" },
];

const listItems = [
  "AI and cloud-based solution",
  "Automatically identify security events",
  "Seamlessly monitor all locations",
  "Resolve incidents in real time",
];

export const CTA_1 = { copy: "Get Quote", link: "/lp/cta-1/" };
export const CTA_2 = { copy: "Start Instant Demo", link: "/lp/cta-2/" };

const IMAGE_URL =
  "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/surveillance-hero-animation-min.webp";

const SurveillanceHeroV3 = () => {
  const { isMobile } = useIsMobile(820);
  const setGetQuoteTitle = useSetAtom(useGetQuoteTitleAtom);
  return (
    <HeroContainer>
      <HeroInner>
        <HeroTop>
          <HeroCol>
            <HeroTitle isMobile={isMobile}>
              Get Smart Surveillance <br />
              <span>For All Your Sites</span>
            </HeroTitle>
            {!isMobile && (
              <BulletList>
                {listItems.map(item => (
                  <BulletListItem>
                    <IconCheckCircle bgColor="var(--blue-800)" />
                    <MainParagraph>{item}</MainParagraph>
                  </BulletListItem>
                ))}
              </BulletList>
            )}
            {!isMobile && (
              <ButtonRow>
                <HeroButtonPrimary
                  as={Link}
                  to={CTA_1.link}
                  onClick={() => setGetQuoteTitle(true)}
                >
                  {CTA_1.copy}
                </HeroButtonPrimary>
                <HeroButtonSecondary as={Link} to={CTA_2.link}>
                  {CTA_2.copy}
                </HeroButtonSecondary>
              </ButtonRow>
            )}
          </HeroCol>
          <HeroCol>
            <HeroImage src={IMAGE_URL} alt="Rhombus Smart Surveillance" />
          </HeroCol>
        </HeroTop>
        <HeroBottom>
          <StatRow>
            {stats.map(stat => {
              const { title, subTitle } = stat;
              return (
                <Stat isMobile={isMobile}>
                  <h3>{title}</h3>
                  <h4>{subTitle}</h4>
                </Stat>
              );
            })}
          </StatRow>
        </HeroBottom>
      </HeroInner>
    </HeroContainer>
  );
};

export default SurveillanceHeroV3;
