import { styled } from "@linaria/react";

export const PreTitle = styled.h3`
  color: ${(props) => (props.color ? props.color : "unset")};
  font-size: 16px;
  font-weight: 600;
  line-height: 200%;
  text-transform: uppercase;
`;

export const PageTitle = styled.h1`
  font-size: 48px;
  font-weight: 600;
  line-height: 116.1%;
  color: ${(props) => (props.color ? props.color : "unset")};
  @media (max-width: 600px) {
    font-size: 40px;
  }
`;

export const Title = styled.h2`
  font-size: ${(props) => props.fontSize ?? "42px"};
  font-weight: 600;
  line-height: 116.1%;
  color: ${(props) => (props.color ? props.color : "unset")};
  @media (max-width: 1024px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

export const TitleMed = styled(Title)`
  font-size: ${(props) => props.fontSize ?? "32px"};
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

export const SectionTitle = styled(Title)`
  font-size: 48px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 42px;
  }
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

export const TitleSmall = styled.h3`
  color: ${(props) => props?.color ?? "unset"};
  font-weight: 600;
  line-height: 116.1%;
  font-size: ${(props) => props.fontSize ?? "18px"};
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const FancyPageTitle = styled(PageTitle)`
  &.center {
    text-align: center;
  }
`;

export const FancyTitle = styled.h2`
  font-size: ${(props) => props.fontSize ?? "42px"};
  font-weight: 600;
  line-height: 116.1%;
  @media (max-width: 1024px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

export const FancyTitleMed = styled(FancyTitle)`
  font-size: 32px;
  line-height: 116.1%;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 24px;
  }
  &.left-align {
    @media (min-width: 768px) {
      text-align: left;
    }
  }
`;

export const MainParagraph = styled.p`
  color: ${(props) => (props.color ? props.color : "unset")};
  &.center {
    text-align: center;
  }
  a {
    color: var(--blue-500);
    font-size: inherit;
    text-decoration: none;
  }
`;

export const GradientTextLink = styled.a`
  font-size: 14px;
  background: var(--gradient-base);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  img {
    height: 10px;
    object-fit: contain;
    transition: all 250ms;
  }
  :hover img {
    margin-left: ${(props) => (props.bottom ? "unset" : "4px")};
  }
`;

export const FancyTextLink = styled.a`
  font-size: inherit;
  background-image: linear-gradient(
    90deg,
    var(--blue-500),
    var(--teal-500),
    #2a7de1,
    var(--teal-500)
  );
  background-size: 300% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    transform: rotate(-90deg);
    transition: all 250ms ease-in;
  }
  :hover svg {
    transform: rotate(-90deg) translateY(0.25rem);
  }
  &:hover {
    animation: animateBg 3s linear infinite;
    @keyframes animateBg {
      0% {
        background-position: 100% 0%;
      }
      100% {
        background-position: 0% 0%;
      }
    }
  }
  img {
    height: 10px;
    object-fit: contain;
    transition: all 250ms;
  }
  :hover {
    img {
      margin-left: 5px;
    }
  }
`;

export const WhiteLink = styled.a`
  color: var(--white);
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-decoration: none;
  img {
    height: 10px;
    object-fit: contain;
    transition: all 250ms;
    filter: grayscale(1) brightness(10);
  }
  :hover {
    img {
      margin-left: 5px;
    }
  }
`;

export const TextLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
  text-decoration: none;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  color: ${(props) => props.color};
  background: transparent;
  svg {
    transform: rotate(-90deg);
    transition: all 250ms ease-in;
  }
  :hover svg {
    transform: rotate(-90deg) translateY(0.25rem);
  }
`;

export const StyledIconLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
  text-decoration: none;
  color: ${(props) => props.color};
`;
