import { styled } from "@linaria/react";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import React from "react";

const Row = styled.div`
  display: flex;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Video = styled.video`
  object-fit: cover;
  width: 800px;
  max-width: 100%;
  aspect-ratio: 16/9;
  object-position: center;
  overflow: hidden;
  border-radius: 1.25rem;
  background-color: var(--nuetral-300);
  @media (max-width: 1024px) {
    aspect-ratio: unset;
    width: 100%;
    height: 100%;
  }
`;

const videoSrc =
  "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/Rhombus+Access+Control_Product+Video_Final+(1).mp4";

const AcVideo = () => {
  return (
    <SectionContainer style={{ paddingTop: 0 }}>
      <SectionInner>
        <Row>
          <Video autoPlay loop muted playsInline>
            <source src={videoSrc} type="video/webm" />
          </Video>
          <TextContainer width="450px" style={{ justifyContent: "center" }}>
            <TitleMed>
              Streamline Security Operations on a Single Pane of Glass
            </TitleMed>
            <MainParagraph>
              Manage doors, cameras, and building security from one intuitive
              dashboard, accessible through desktop or mobile app. Eliminate
              system silos, enhance visibility, and scale effectively with a
              unified, cloud-managed physical security system that evolves with
              your needs.
            </MainParagraph>
          </TextContainer>
        </Row>
      </SectionInner>
    </SectionContainer>
  );
};

export default AcVideo;
