import { css } from "@linaria/core";
import React from "react";
import { MenuLinks, MenuTitle, menuColumn } from "./styles/HeaderStyles";
import { useSpring, animated } from "@react-spring/web";
import MegaMenuContent from "./megaMenu/MegaMenuContent";
import IconLink from "components/common/IconLink";
import Dome from "components/common/icon-components/Dome";
import Phone from "components/common/icon-components/Phone";

const megaMenuContainer = css`
  background: var(--white);
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--nuetral-200);
  box-shadow: 0 20vh 10vh -10vh #0b0c0d30;
`;

const megaMenuInner = css`
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
`;

const megaMenuFooter = css`
  width: 100%;
  border-top: 1px solid var(--nuetral-200);
  background-color: var(--white);
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.75rem 1.25rem;
`;

const footerInner = css`
  width: 100%;
  max-width: var(--max-width);
`;

const footerNav = css`
  display: flex;
  gap: 0.75rem;
  height: 100%;
  align-items: center;
  .divider {
    height: 1.75rem;
    width: 1px;
    background-color: var(--nuetral-200);
  }
`;

const navMenuSection = css`
  flex-grow: 1;
  padding-left: calc((100% - var(--max-width)) / 2 - 1.25rem);
  :has(.platform-column) {
    padding-left: 0;
  }
  .platform-column {
    background-color: var(--tertiary-bg);
    display: flex;
    flex-direction: column;
    padding: 1.75rem 0;
    padding-left: calc((100% - var(--max-width)) / 2);
    padding-right: 1.25rem;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: max-content;
    ul {
      min-width: 300px;
      max-width: 600px;
    }
    @media (max-width: 1415px) {
      padding-left: 1.25rem;
    }
    @media (max-width: 1200px) {
      padding: 1.25rem 0;
      padding-left: 0;
      height: fit-content;
      width: calc(100% / 3);
    }
    @media (max-width: 820px) {
      max-width: 100%;
      width: 100%;
    }
  }
`;

const featuredSection = css`
  flex-grow: 1;
  background: var(--tertiary-bg);
  padding-right: calc((100% - var(--max-width)) / 2 - 1.25rem);
  justify-content: flex-end;
  display: flex;
  max-width: fit-content;
  .${menuColumn} {
    max-width: 500px;
    width: 464px;
  }
`;

const MegaMenu = ({ menuData, showFeaturedSection, FeaturedSection }) => {
  const [fadeIn] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    []
  );

  const renderFeaturedSection = () => {
    if (showFeaturedSection) {
      return (
        <div className={featuredSection}>
          <nav className={menuColumn}>
            <MenuTitle>Featured</MenuTitle>
            <MenuLinks>
              <FeaturedSection />
            </MenuLinks>
          </nav>
        </div>
      );
    } else return <></>;
  };

  return (
    <animated.div style={fadeIn} className={megaMenuContainer}>
      <div className={megaMenuInner}>
        <div className={navMenuSection}>
          <MegaMenuContent menuData={menuData} />
        </div>
        {renderFeaturedSection()}
      </div>
      <footer className={megaMenuFooter}>
        <div className={footerInner}>
          <nav className={footerNav}>
            <IconLink
              path="/contact-sales/"
              title="Contact Sales"
              reverse
              Icon={Phone}
              color="var(--primary-bg)"
            />
            <div className="divider"></div>

            <IconLink
              path="/free-trial/"
              title="Free Trial"
              reverse
              Icon={Dome}
              color="var(--primary-bg)"
            />
          </nav>
        </div>
      </footer>
    </animated.div>
  );
};

export default MegaMenu;
