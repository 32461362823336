import Link from "components/page/headers/HeaderLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "@linaria/core";
import React, { useState } from "react";
import { FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const cardContainer = css``;
const maskedBoth = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      #0000,
      var(--nuetral-900) 10% 80%,
      var(--nuetral-900)
    );
  `
);
const maskedStart = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      var(--white),
      var(--nuetral-900) 10% 80%,
      var(--nuetral-900)
    );
  `
);
const maskedEnd = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      var(--nuetral-900),
      var(--nuetral-900) 10% 80%,
      var(--white)
    );
  `
);

const swiperWrapper = css`
  height: 100%;
  :not(:has(.swiper-scrollbar-lock)) {
    .swiper-wrapper {
      padding-bottom: 2.5rem;
    }
  }
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 0.5rem;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--nuetral-200);
    border-radius: 1rem;
    .swiper-scrollbar-drag {
      background-image: var(--gradient-base);
      border-radius: 1rem;
      width: 100%;
      cursor: grab;
    }
  }
`;

const card = css`
  min-height: 443px;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
`;

const cardInner = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  gap: 40px;
  text-decoration: none;
  background: linear-gradient(
    180deg,
    rgba(42, 125, 225, 0.18) 0%,
    rgba(42, 125, 225, 0.7) 66.71%
  );
  p {
    color: var(--white);
    font-size: 25px;
    max-width: 464px;
    line-height: normal;
  }
  @media (max-width: 1024px) {
    p {
      font-size: 16px;
    }
  }
`;

const cardImage = css`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: -1 !important;
  filter: brightness(0.7) !important;
  isolation: isolate !important;
  border-radius: 15px !important;
`;

const StoryCards = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile } = useIsMobile(768);
  const updateIndex = swiperInstance => {
    if (swiperInstance === null) return;
    const currentSlide = swiperInstance?.activeIndex;
    setCurrentIndex(currentSlide);
  };

  const renderMask = () => {
    const start = currentIndex === 0;
    const end = isMobile
      ? currentIndex === data.length - 1
      : currentIndex === data.length % 3;
    if (start) {
      return maskedStart;
    } else if (end) {
      return maskedEnd;
    } else return maskedBoth;
  };

  return (
    <div className={renderMask()}>
      <Swiper
        initialSlide={currentIndex}
        onActiveIndexChange={updateIndex}
        spaceBetween={20}
        slidesPerView={2.5}
        freeMode
        mousewheel
        breakpoints={{
          300: {
            slidesPerView: 1.25,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 150,
              snapOnRelease: true,
            },
          },
          625: {
            slidesPerView: 1.5,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 150,
              snapOnRelease: true,
            },
          },
          768: {
            slidesPerView: 2,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 150,
              snapOnRelease: true,
            },
          },
          1024: {
            slidesPerView: 2.5,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 150,
              snapOnRelease: true,
            },
          },
        }}
        scrollbar
        modules={[Scrollbar, FreeMode, Mousewheel]}
        className={swiperWrapper}
      >
        {data.map((item, index) => (
          <SwiperSlide className={card} key={index}>
            <GatsbyImage
              image={getImage(item.img)}
              alt={""}
              className={cardImage}
            />
            <Link to={item.link && item.link} className={cardInner}>
              <GatsbyImage
                image={getImage(item.logo.img)}
                alt={item.logo.alt}
                style={{ width: item.logo.width }}
              />
              <p>{item.quote}</p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default StoryCards;
