import React from "react";

const IconMapPin = ({ size = "26", color = "var(--blue-500)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M20.9756 3.22086C18.8987 1.14385 16.1371 0 13.1998 0C10.2625 0 7.50094 1.14385 5.42398 3.22086C3.34697 5.29792 2.20312 8.05941 2.20312 10.9967C2.20312 16.9387 7.82147 21.881 10.8399 24.5361C11.2593 24.9051 11.6215 25.2238 11.9101 25.4933C12.2717 25.8311 12.7358 25.9999 13.1998 25.9999C13.6639 25.9999 14.1279 25.8311 14.4895 25.4933C14.7781 25.2237 15.1403 24.9051 15.5597 24.5361C18.5781 21.8809 24.1965 16.9387 24.1965 10.9967C24.1964 8.05941 23.0526 5.29792 20.9756 3.22086ZM14.5538 23.3926C14.1251 23.7697 13.755 24.0953 13.4498 24.3804C13.3096 24.5112 13.09 24.5113 12.9497 24.3804C12.6446 24.0952 12.2744 23.7696 11.8457 23.3926C9.00808 20.8964 3.72606 16.25 3.72606 10.9967C3.72606 5.77293 7.9759 1.52308 13.1997 1.52308C18.4235 1.52308 22.6733 5.77293 22.6733 10.9967C22.6734 16.25 17.3914 20.8964 14.5538 23.3926Z"
        fill={color}
      />
      <path
        d="M13.1977 5.73438C10.5255 5.73438 8.35156 7.90827 8.35156 10.5804C8.35156 13.2526 10.5255 15.4265 13.1977 15.4265C15.8698 15.4265 18.0437 13.2526 18.0437 10.5804C18.0437 7.90827 15.8698 5.73438 13.1977 5.73438ZM13.1977 13.9034C11.3653 13.9034 9.8746 12.4127 9.8746 10.5804C9.8746 8.7481 11.3653 7.25736 13.1977 7.25736C15.03 7.25736 16.5207 8.7481 16.5207 10.5804C16.5207 12.4127 15.03 13.9034 13.1977 13.9034Z"
        fill={color}
      />
    </svg>
  );
};

export default IconMapPin;
