import AccessControlBanner from "components/common/footer-banners/AccessControlBanner";
import React from "react";

import DoorReaderFeatures from "./DoorReaderFeatures";
import DoorReaderModels from "./DoorReaderModels";
import DoorReadersHero from "./DoorReadersHero";
import DoorReadersOverviewHeader from "./DoorReadersOverviewHeader";
import { graphql, useStaticQuery } from "gatsby";
import FAQSection from "components/common/FAQSection";

const faq = {
  title: "Door Readers FAQs",
  alt: "Rhombus DR20 door card reader next to office door, providing employees seamless access and security benefits.",
  QA: [
    {
      question: "What types of access methods do Rhombus door readers support?",
      answer:
        "Rhombus door readers are versatile components of modern systems, supporting multiple access methods including smart cards, mobile apps, and wave-to-unlock gestures. They also offer options for proximity and biometric readers, ensuring businesses can maintain robust security measures while providing convenient access for authorized personnel.",
    },
    {
      question: "How easy is it to install Rhombus door card reader?",
      answer:
        "Rhombus door readers are designed for securing locations with easy and quick installation. However, the overall system cost and ease can vary based on factors such as building size, layout, type of reader chosen, and installation fees. It's important to consider these factors when selecting the most suitable access control solution for your business.",
    },
    {
      question: "Can Rhombus door readers be managed remotely?",
      answer:
        "Yes, Rhombus door readers feature cloud management capabilities, offering a centralized platform for monitoring and controlling access points. This allows administrators to manage access control from any location, ensuring consistent maintenance of security protocols. The remote management feature enhances security and provides convenience for system administrators.",
    },
    {
      question: "Do Rhombus door readers offer touchless entry options?",
      answer:
        'Yes, Rhombus door readers support touchless entry, which is an important part of modern security solutions. The DR20 door reader and DR40 video intercom door reader models both feature a "Wave to Unlock" function, allowing for hygienic, contactless access. This touchless entry capability, combined with quick installation, makes Rhombus door readers an ideal choice for businesses looking to enhance security and comply with various standards and regulations.',
    },
    {
      question:
        "Do Rhombus door readers work during power outages or internet downtime?",
      answer: (
        <>
          Yes, Rhombus door readers are designed to function during power and
          internet disruptions:
          <ul>
            <li>
              Built-in backup power keeps readers operational during outages.
            </li>
            <li>
              Offline mode uses locally stored credentials when internet is
              unavailable.
            </li>
            <li>
              Access events are stored locally and synced to the cloud once
              connectivity resumes.
            </li>
            <li>
              Doors can be set to unlock or remain locked during power loss,
              based on your security preferences.
            </li>
          </ul>
          This ensures continuous access control and security, regardless of
          external factors.
        </>
      ),
    },
  ],
};

export default function DoorReadersPage() {
  const faqImage = useStaticQuery(graphql`
    query {
      src: file(
        relativePath: { eq: "components/access-control/img/dr-faq-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  return (
    <>
      <DoorReadersOverviewHeader />
      <DoorReaderModels />
      <DoorReadersHero />
      <DoorReaderFeatures />
      <FAQSection
        image={faqImage.src}
        imageAlt={faq.alt}
        reverse
        title={faq.title}
        QA={faq.QA}
      />
      <AccessControlBanner />
    </>
  );
}
