import { styled } from "@linaria/react";
import TextLinkV2 from "components/common/TextLinkV2";
import GlobalLayout from "components/page/GlobalLayout";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { SITE_MAP_MENUS } from "components/sitemap/constants";
import React from "react";
import { Helmet } from "react-helmet";

const SiteMapInner = styled(SectionInner)`
  flex-direction: column;
  gap: 5rem;
`;

const MenuContainer = styled.div`
  display: flex;
  column-gap: 2.5rem;
  row-gap: 5rem;
  flex-wrap: wrap;
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  flex-basis: calc(25% - 2.5rem);
  min-width: 250px;
  .rhombus-text-link:hover {
    text-decoration: underline;
  }
`;

const Sitemap = () => {
  return (
    <GlobalLayout>
      <Helmet>
        <title>
          Rhombus Visual Sitemap - Explore Our Cloud Physical Security Solutions
          | Rhombus
        </title>
        <meta
          name="description"
          content="Navigate the visual sitemap to explore video surveillance security solutions, features, and resources. Find detailed information on products, services, and integrations offered by Rhombus."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-sitemap.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-sitemap.png"
        />
      </Helmet>
      <SectionContainer>
        <SiteMapInner>
          <FancyPageTitle>Sitemap</FancyPageTitle>
          <MenuContainer>
            {SITE_MAP_MENUS.map((menu) => (
              <Menu key={menu.title}>
                <TitleSmall>{menu.title}</TitleSmall>
                {menu.links.map((link) => (
                  <TextLinkV2 title={link.title} path={link.to} key={link.to} />
                ))}
              </Menu>
            ))}
          </MenuContainer>
        </SiteMapInner>
      </SectionContainer>
    </GlobalLayout>
  );
};

export default Sitemap;
