import { toZonedTime } from "date-fns-tz";

export const COLLECTIONS = [
  { name: "Live", type: "Live Webinar" },
  { name: "On-Demand", type: "On-Demand Webinar" },
];
export const WEBINAR_TYPES = {
  live: "Live Webinar",
  OnDemand: "On-Demand Webinar",
};

const collectionsList = ["All", WEBINAR_TYPES.OnDemand, WEBINAR_TYPES.live];

const filterDuplicatesHelper = (array) => {
  const filteredArray = array.filter((v, i, self) => {
    return i == self.indexOf(v);
  });

  return filteredArray.sort();
};

export const getAllTopicsInUse = (webinars) => {
  const returnArr = [];
  webinars.forEach((item) =>
    item.node.frontmatter.topics.forEach((el) => returnArr.push(el))
  );
  return filterDuplicatesHelper(returnArr);
};

const filterByTopic = (webinars, topic) => {
  if (topic === "All Topics") {
    return webinars;
  } else {
    const filtered = webinars.filter((webinar) => {
      const found = webinar.node.frontmatter.topics.includes(topic);
      return found;
    });
    return filtered;
  }
};

const filterByCollection = (webinars, collection) => {
  if (collection === "All") {
    return webinars;
  } else
    return webinars.filter((webinar) => {
      return webinar.node.frontmatter.type === collection;
    });
};

export const filterWebinarsByCollectionOrTopic = (webinars, activeFilter) => {
  if (collectionsList.includes(activeFilter)) {
    return filterByCollection(webinars, activeFilter);
  } else {
    return filterByTopic(webinars, activeFilter);
  }
};

export const getType = (currentType, startDate) => {
  const timeZone = "Etc/UTC";

  const currentDate = new Date().getTime();

  const _startDate = toZonedTime(new Date(startDate), timeZone).getTime();
  if (currentType === WEBINAR_TYPES.OnDemand) {
    return currentType;
  }
  if (currentDate > _startDate) {
    return WEBINAR_TYPES.OnDemand;
  }
  return currentType;
};

export const chooseEventToFeature = (webinar, introWebinar, hybridEvent) => {
  const timeZone = "Etc/UTC";
  const currentDate = new Date().getTime();

  // Extract dates and ensure they're valid (i.e., in the future)
  const webinarDate = toZonedTime(
    new Date(webinar[0]?.node.frontmatter.startDate),
    timeZone
  ).getTime();
  const introWebinarDate = toZonedTime(
    new Date(introWebinar[0]?.node.frontmatter.startDate),
    timeZone
  ).getTime();
  const hybridEventDate = toZonedTime(
    new Date(hybridEvent[0]?.node.frontmatter.dateInfo?.startDate),
    timeZone
  ).getTime();

  const validWebinarDate =
    webinarDate && webinarDate > currentDate ? webinarDate : null;
  const validIntroWebinarDate =
    introWebinarDate && introWebinarDate > currentDate
      ? introWebinarDate
      : null;
  const validHybridEventDate =
    hybridEventDate && hybridEventDate > currentDate ? hybridEventDate : null;

  // Initialize an array of objects containing the event and its valid date
  const eventsWithDates = [
    { event: webinar ?? [], date: validWebinarDate },
    { event: introWebinar ?? [], date: validIntroWebinarDate },
    { event: hybridEvent ?? [], date: validHybridEventDate },
  ];

  const closestEvent = eventsWithDates
    .filter((eventObj) => eventObj.date !== null)
    .reduce((closest, eventObj) => {
      return !closest || eventObj.date < closest.date ? eventObj : closest;
    }, null);

  return closestEvent ? closestEvent.event : (webinar ?? []);
};

export const isHybridEventExpired = (hybridEvent) => {
  const timeZone = "Etc/UTC";

  const currentDate = new Date();
  const hybridEventDate = toZonedTime(
    new Date(hybridEvent.node.frontmatter.dateInfo.startDate),
    timeZone
  );
  return hybridEventDate.getTime() > currentDate.getTime();
};

export const isLiveWebinarExpired = (webinar) => {
  const timeZone = "Etc/UTC";

  const currentDate = new Date();
  const webinarStartDate = toZonedTime(
    new Date(webinar.node.frontmatter.startDate),
    timeZone
  );

  return webinarStartDate.getTime() > currentDate.getTime();
};
