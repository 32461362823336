import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import AccessControlPage from "components/access-control/AccessControlPage";

export default function AccessControlLandingPage() {
  return (
    <GlobalLayout dark color="transparent">
      <Helmet>
        <title>
          Door Access Control Systems for Enhanced Security | Rhombus
        </title>
        <meta
          name="description"
          content="Streamline building security with Rhombus door access control systems. Schedule a free consultation for how to secure your spaces with a cloud system for doors, cameras and sensors."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-access-control.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-access-control.png"
        />
      </Helmet>
      <AccessControlPage />
    </GlobalLayout>
  );
}
