import CaseStudyFeed from "components/case-study/CaseStudyFeed";
import CaseStudyHeader from "components/case-study/CaseStudyHeader";
import CaseStudyLogoSlider from "components/case-study/CaseStudyLogoSlider";
import FeaturedCaseStudySlider from "components/case-study/FeaturedCaseStudySlider";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function CaseStudies() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Customer Stories | Cloud Physical Security System | Rhombus
        </title>
        <meta
          name="description"
          content="Learn how Rhombus improved safety and security for school districts, hospitals, storage facilities, and other organizations with their cloud-managed system. Start a free trial today!"
        />
        <script
          src="https://fast.wistia.com/embed/medias/bny3yv3e5l.jsonp"
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <CaseStudyHeader />
      <CaseStudyLogoSlider />
      <FeaturedCaseStudySlider />
      <CaseStudyFeed />
      <TrialBanner />
    </GlobalLayout>
  );
}
