import CTABanner from "components/common/footer-banners/CTABanner";
import DefaultHeroLayout from "components/common/hero/DefaultHeroLayout";
import SupportSection from "components/common/SupportSection";
import LicenseTable from "components/license-comparison/LicenseTable";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import Helmet from "react-helmet";

export default function AudioLicenseComparison() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus Audio License Comparison - A100 Audio Features</title>
        <meta
          name="description"
          content="Rhombus audio gateway license and feature comparison. Learn about the unique differences between Rhombus audio security licenses."
        />
      </Helmet>
      <DefaultHeroLayout
        preTitle="License Comparison"
        title="Determine Which License Fits Your Business Requirements"
        backgroundColor="var(--gradient-light-fade)"
        center
      />
      <LicenseTable selectedTab="Audio" />
      <SupportSection />
      <CTABanner />
    </GlobalLayout>
  );
}
