import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";
import bulletVideo from "components/cameras/mp4/lpr-video.hevc.mp4";
import bulletVideoBackup from "components/cameras/mp4/backups/lpr-video.mp4";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";

export default function BulletCameras() {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/bullet-header-new-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r500-product-card.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r510-product-card.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device3: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r520-product-card-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/license-plates-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r500-detection-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/r500-rain-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(relativePath: { eq: "components/common/icons/HD.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(relativePath: { eq: "components/common/icons/sensor.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(relativePath: { eq: "components/common/icons/vision.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/common/icons/weather.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(
        relativePath: { eq: "components/common/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(
        relativePath: { eq: "components/common/icons/certification.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      recommend1: file(
        relativePath: { eq: "components/cameras/img/dome-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend2: file(
        relativePath: { eq: "components/cameras/img/fisheye-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend3: file(
        relativePath: { eq: "components/cameras/img/r600-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: {
          eq: "components/cameras/bullet-cameras/img/bullet-spec-r520-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      faq: file(relativePath: { eq: "components/cameras/img/bullet-faq.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  const pageData = {
    headerSection: {
      preTitle: "Bullet cameras",
      title: "See Further and Clearer with Smart Bullet Cameras",
      mobileTitle: "Get Long Range Visibility",
      description:
        "Capture crystal-clear details at extensive distances, even in harsh weather and low light, with bullet security cameras. Stay ahead of security threats by harnessing the power of advanced AI, remote access, and centralized management.",
      image: data.headerImage,
      altText: "Bullet security cameras with new mini bullet camera on podium",
      button1: {
        text: CTA_BUTTON_COPY_3,
        path: "/free-trial/",
      },
    },
    cardSection: {
      title: "Clearer, Smarter Security with the Bullet Camera Series",
      center: true,
      cards: [
        {
          image: data.device1,
          altText:
            "R500 bullet security camera in parking lot, delivering long-range detail with night vision for high-quality surveillance footage",
          name: "R500 Telephoto Bullet Camera",
          description:
            "8MP Bullet with Telephoto Zoom ideal for ultra-long-distance visibility and analytics",
          link: "/cameras/bullet-cameras/r500/",
          flex: 3,
        },
        {
          image: data.device2,
          altText:
            "The R510 bullet surveillance camera, which has a wide angle lens compared to the R500, is mounted outside an office. It is known for long-range coverage and detailed image quality in outdoor settings.",
          name: "R510 Standard Zoom Bullet Camera",
          description:
            "8MP Bullet with Optical Zoom ideal for powerful analytics and long-distance visibility",
          link: "/cameras/bullet-cameras/r510/",

          flex: 3,
        },
        {
          image: data.device3,
          altText:
            "R520 Mini 5MP bullet camera mounted on wall ideal for perimeter protection",
          name: "R520 Mini Bullet Camera",
          description:
            "5MP Mini Bullet with Optical Zoom ideal for monitoring access points",
          link: "/cameras/bullet-cameras/r520/",
          flex: 3,
        },
      ],
    },

    benefitsSection: {
      title: "Deploy Modern Security Technology in a Classic Form Factor",
      center: true,
      items: [
        {
          image: data.benefit1,
          altText:
            "Mobile screen displaying high-quality license plate images captured by outdoor bullet security cameras, showcasing long-range detail and night vision",
          title: "Long-Range Visibility & Analytics",
          description:
            "Capture detailed footage at range with powerful AI analytics for proactive, smarter surveillance. Monitor entryways, parking lots, and more using optical zoom and 4K video.",
        },
        {
          image: data.benefit2,
          altText:
            "Bullet security camera placed in a parking lot, designed for effective surveillance to deliver long-range detail.",
          title: "Faster Insights with Camera AI Processing",
          description:
            "Get crystal-clear footage and lightning-fast processing right out of the box. Footage is analyzed directly on the camera to save time, optimize bandwidth, and accelerate live alerts.",
        },
        {
          image: data.benefit3,
          altText:
            "Outdoor bullet security camera in rain, showcasing weatherproof housing as one of its key benefits. Not all bullet cameras have such casing. It delivers long-range detail and night vision for high-quality video footage.",
          title: "Ruggedized, Weatherproof Housing",
          description:
            "Classic, durable form enables uninterrupted surveillance in any condition—scorching heat, freezing cold, or pouring rain. Effectively deter theft & monitor access points.",
        },
      ],
    },
    specSection: {
      title: "Capture with Incredible Clarity",
      altText: "R520 Mini 5MP bullet camera to monitor parking lots",
      specs: [
        {
          icon: data.icon1,
          title: "Crystal Clear",
          subTitle: "Up to 4K Video",
        },
        {
          icon: data.icon2,
          title: "Up to 8.3MP",
          subTitle: "Image Sensor",
        },
        {
          icon: data.icon3,
          title: "Motorized Zoom",
          subTitle: "Optical & Telephoto",
        },
        {
          icon: data.icon4,
          title: "IP66",
          subTitle: "Weather Resistance",
        },
        {
          icon: data.icon5,
          title: "AI Analytics",
          subTitle: "Included Onboard",
        },
        {
          icon: data.icon6,
          title: "10-Year",
          subTitle: "Warranty",
        },
      ],
    },
    exploreSection: {
      cards: [
        {
          image: data.recommend1,
          altText:
            "Dome Cameras: Flexible deployment with high performance and edge-based analytics",
          name: "Dome Cameras",
          description:
            "Flexible deployment with high performance and edge-based analytics",
          link: "/cameras/dome-cameras/",
          flex: 3,
        },
        {
          image: data.recommend2,
          altText:
            "Fisheye Cameras: Immersive coverage and visibility with minimal camera placement",
          name: "Fisheye Cameras",
          description:
            "Immersive coverage and visibility with minimal camera placement",
          link: "/cameras/fisheye-cameras/",
          flex: 3,
        },
        {
          image: data.recommend3,
          altText:
            "Multisensor business security camera mounted on a wall to provide video analytics and robust surveillance system with a single powerful camera and multiple sensors",
          name: "Multisensor Cameras",
          description: "One powerful camera for complete coverage",
          link: "/cameras/multisensor-cameras/",
          flex: 3,
        },
      ],
    },
  };

  const faq = {
    title: "Bullet Security Cameras FAQs",
    image: data.faq,
    alt: "Rhombus IR bullet surveillance camera in front of computer screen displaying live footage, showcasing surveillance capabilities",
    QA: [
      {
        question:
          "What are the key benefits of bullet surveillance cameras compared to other security camera types?",
        answer: (
          <>
            Rhombus bullet cameras offer several advantages:
            <ul>
              <li>
                Long distance capture for monitoring large areas like parking
                lots and airports
              </li>
              <li>Weatherproof case for reliable outdoor performance</li>
              <li>
                High-quality images and video footage in both daylight and
                low-light conditions
              </li>
              <li>Advanced night vision capabilities with IR illumination</li>
              <li>Easy installation on walls or ceilings</li>
              <li>
                Remote access and cloud-based storage for enhanced security{" "}
              </li>
            </ul>
          </>
        ),
      },
      {
        question:
          "How do Rhombus outdoor bullet security cameras perform in extreme weather conditions?",
        answer:
          "Rhombus outdoor bullet cameras are designed with weatherproof casing, making them suitable for various environmental conditions. They can withstand rain, snow, and extreme temperatures, ensuring continuous security operations and protection for your site, whether it's a parking lot, airport, or any other outdoor area.",
      },
      {
        question: "Do Rhombus bullet cameras have zoom capabilities?",
        answer:
          "Yes, Rhombus bullet cameras feature motorized zoom with both optical and telephoto capabilities. This allows for flexible surveillance, enabling users to adjust the field of view remotely and focus on specific areas of interest. The zoom functionality, combined with the 4K resolution, ensures that you can capture clear, detailed footage at various distances.",
      },
      {
        question: "How do Rhombus bullet cameras differ from box cameras?",
        answer:
          "Rhombus bullet cameras have a cylindrical shape and are easier to install outdoors, while box cameras offer interchangeable lenses and are typically used indoors. Box camera types have common applications for indoor surveillance. ",
      },
      {
        question:
          "Can Rhombus bullet cameras capture clear footage at night or in low light conditions?",
        answer:
          "Yes, Rhombus bullet cameras are equipped with advanced night vision technology. IR illuminators are one of the important internal components, allowing the cameras to capture clear video footage even in complete darkness. This capability ensures 24/7 surveillance and security for your property.",
      },
    ],
  };

  return (
    <GlobalLayout color="var(--primary-bg)" dark>
      <Helmet>
        <title>4K Commercial Bullet Cameras | Try for Free | Rhombus</title>
        <meta
          name="description"
          content="Start a free trial for our 4K bullet security cameras, featuring LPR, facial recognition, and more. See clearly at longer distances and get proactive surveillance with AI-powered analytics."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-bullet-camera.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-bullet-camera.png"
        />
      </Helmet>
      <DevicePageTemplate
        data={pageData}
        video={bulletVideo}
        videoBackup={bulletVideoBackup}
        deviceType="Camera"
        recommend
        reverse
        image={data.specImage}
        faq={faq}
      />
    </GlobalLayout>
  );
}
