import TextLinkV2 from "components/common/TextLinkV2";
import { graphql, Link, StaticQuery } from "gatsby";
import { useSetAtom } from "jotai";
import React from "react";

import { megaMenuOpenAtom } from "../atoms/headerAtoms";
import { FeaturedCard } from "../styles/HeaderStyles";

export default function FeaturedPartners() {
  const setMegaMenuOpen = useSetAtom(megaMenuOpenAtom);
  const renderContent = (data) => {
    const { edges: _caseStudies } = data.allMarkdownRemark;

    return (
      <>
        {_caseStudies.map((item) => (
          <FeaturedCard
            as={Link}
            to={item.node.fields.slug}
            onClick={() => setMegaMenuOpen(false)}
          >
            <div className="card-image-wrapper">
              <img
                src={item.node.frontmatter.heroImage}
                className="card-image"
                alt={item.node.frontmatter.companyName}
              />
            </div>
            <div className="text-container">
              <h3>Customer Stories</h3>
              <p>
                <b>{item.node.frontmatter.companyName}</b>
                {" - "}
                {item.node.frontmatter.pageTitle}
              </p>
              <div className="link-container">
                <TextLinkV2
                  path={item.node.fields.slug}
                  title="Read Story"
                  small
                />
              </div>
            </div>
          </FeaturedCard>
        ))}
      </>
    );
  };
  const query = graphql`
    query {
      allMarkdownRemark(
        limit: 2
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: {
            templateKey: { eq: "case-study" }
            featured: { eq: true }
            draft: { ne: true }
          }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              pageTitle
              companyName
              heroImage
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
