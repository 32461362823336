import { StyledIconLink } from "components/rhombus-UI/theme/typography";
import { Link } from "gatsby";
import React from "react";
import Carrot from "./icon-components/Carrot";

const IconLink = ({
  path,
  title,
  target = "_self",
  color = "var(--blue-500)",
  Icon = Carrot,
  reverse = false,
}) => {
  return (
    <StyledIconLink
      as={Link}
      to={path}
      target={target}
      className="rhombus-text-link"
      color={color}
    >
      {reverse ? (
        <>
          <Icon color={color} /> {title}
        </>
      ) : (
        <>
          {title} <Icon color={color} />
        </>
      )}
    </StyledIconLink>
  );
};

export default IconLink;
