import React from "react";

const Dome = ({ color = "currentColor", height = "21", width = "16" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.97802 7.30056C3.18464 7.08352 17.6658 7.30056 17.6658 7.30056C17.6658 7.30056 20.0384 8.37061 19.987 12.4306L19.9878 15.3463H1.05744V14.3273C1.05744 13.5341 1.03052 12.741 1.00202 11.9478C0.968769 11.0142 1.33452 9.02732 2.97802 7.30056Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72811 7.13835C3.8239 7.25485 4.0519 7.20458 4.25298 7.20458H5.11352H7.62627H13.7245H17.2759C17.287 7.20458 17.2973 7.20458 17.3076 7.20458C17.3131 7.20458 17.1437 6.63245 17.1302 6.5742C17.0503 6.23826 16.964 5.90632 16.8516 5.57916C16.6283 4.92883 16.3291 4.30564 15.9506 3.73511C15.1408 2.51664 13.9636 1.54474 12.571 1.1362C10.7035 0.588805 8.25961 0.90958 6.63115 1.99558C5.00269 3.08239 3.87852 4.9408 3.68457 6.9229C3.67427 7.02663 3.69169 7.09446 3.72811 7.13835Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00781 7.20415H14.9798C14.9798 7.20415 14.9085 6.63441 14.6671 5.86759C13.4906 2.134 9.3819 1.10864 7.1296 4.04827C6.54298 4.8143 6.09173 5.83966 6.00781 7.20415Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6937 3.4578C11.6937 3.77937 11.1569 4.0395 10.4943 4.0395C9.83246 4.0395 9.29492 3.77937 9.29492 3.4578C9.29492 3.13623 9.83246 2.8761 10.4943 2.8761C11.1569 2.8761 11.6937 3.13623 11.6937 3.4578Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6937 3.4578C11.6937 3.77937 11.1569 4.0395 10.4943 4.0395C9.83246 4.0395 9.29492 3.77937 9.29492 3.4578C9.29492 3.13623 9.83246 2.8761 10.4943 2.8761C11.1569 2.8761 11.6937 3.13623 11.6937 3.4578Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M1.16992 12.6648H19.8691"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Dome;
