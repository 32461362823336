import {
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { FancyTextLink } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useState } from "react";

import Carrot from "../../images/carrot-down.png";
import Arrow from "./img/blue-arrow.png";
import { Body, TitleMed, TitleSmall } from "components/rui/typography";
import { ButtonLink } from "components/rui/buttons";

const FAQSectionContainer = styled(SectionContainer)``;

const FAQInnerContainer = styled(SectionInner)`
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  gap: 4rem;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const FAQColumm = styled(SectionCol)`
  flex-direction: column;
  gap: 2rem;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const titleContainer = css`
  max-width: 600px;
`;

const faqList = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const activeContent = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  max-height: 0;
  margin-top: -1rem;
  opacity: 0;
  transition:
    max-height 1s cubic-bezier(0.47, 0.42, 0, 0.99),
    margin-top 1s cubic-bezier(0.47, 0.42, 0, 0.99),
    opacity 1s cubic-bezier(0.47, 0.42, 0, 0.99);
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const faqContainer = css`
  padding: 1.5rem 0.5rem;
  cursor: pointer;
  border-top: 1px solid var(--nuetral-200);
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: background 250ms;
  :hover {
    background: var(--secondary-bg);
  }
  &:first-of-type {
    border-top: 1px solid transparent;
  }
`;

const questionRow = css`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const faqActive = cx(
  faqContainer,
  css`
    .${activeContent} {
      max-height: 400px;
      color: rgba(6, 6, 6, 0.7);
      opacity: 1;
      margin-top: 0;
    }
  `
);

const carrot = css`
  height: 11px;
  aspect-ratio: 1;
  object-fit: contain;
  transition: all 500ms cubic-bezier(0.47, 0.42, 0, 0.99);
`;

const carrotActive = cx(
  carrot,
  css`
    transform: rotate(180deg);
  `
);

const imageStyles = css`
  width: 100%;
  border-radius: 12px;
  max-width: 669px;
  height: 370px;
  overflow: hidden;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const FAQSection = ({
  title = "Frequently Asked Questions",
  QA,
  color = "var(--white)",
  image = null,
  imageAlt = null,
  reverse = false,
  center = false,
}) => {
  const [activeIndex, setActiveIndex] = useState();

  const handleActiveClass = (index) => {
    if (index === activeIndex) {
      return faqActive;
    }
    return faqContainer;
  };

  return (
    <FAQSectionContainer style={{ background: color }} id="faqs">
      <FAQInnerContainer reverse={reverse} center={center}>
        {!center && (
          <SectionCol style={{ position: "relative" }}>
            {!image && (
              <div className={titleContainer}>
                <TitleMed>{title}</TitleMed>
              </div>
            )}
            {image && (
              <GatsbyImage
                image={getImage(image)}
                alt={imageAlt ?? title}
                className={imageStyles}
              />
            )}
          </SectionCol>
        )}

        <FAQColumm center={center}>
          {image && <TitleMed>{title}</TitleMed>}
          {center && !image && (
            <div className={titleContainer}>
              <TitleMed>{title}</TitleMed>
            </div>
          )}
          <div className={faqList}>
            {QA.map((item, index) => (
              <div
                className={handleActiveClass(index)}
                key={item.question}
                onClick={() =>
                  activeIndex === index
                    ? setActiveIndex(undefined)
                    : setActiveIndex(index)
                }
              >
                <div className={questionRow}>
                  <TitleSmall>{item.question}</TitleSmall>
                  <img
                    src={Carrot}
                    alt="carrot"
                    className={index === activeIndex ? carrotActive : carrot}
                  />
                </div>
                <div className={activeContent}>
                  <Body style={{ fontSize: "16px" }}>{item.answer}</Body>
                  {item.link && (
                    <ButtonLink
                      href={item.link.path}
                      target="_blank"
                      title={item.link.text}
                    >
                      {item.link.text}
                    </ButtonLink>
                  )}
                </div>
              </div>
            ))}
          </div>
        </FAQColumm>
      </FAQInnerContainer>
    </FAQSectionContainer>
  );
};

export default FAQSection;
