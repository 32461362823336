import TrialBanner from "components/common/footer-banners/TrialBanner";
import { graphql, StaticQuery } from "gatsby";
import { css, cx } from "@linaria/core";
import { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import BlogFeaturedPosts from "./BlogFeaturedPosts";
import BlogHeader from "./BlogHeader";
import BlogPagination from "./BlogPagination";
import BlogPostCard from "./BlogPostCard";
import BlogSearch from "./BlogSearch";
import IndustryFilter from "./IndustryFilter";
import { TitleMed } from "components/rhombus-UI/theme/typography";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { useAtom } from "jotai";
import { menuOpenAtom } from "./state";

const blogContentClass = css`
  display: flex;
  flex-direction: column;
`;

const subtitleClass = css`
  margin: 25px 0;
  text-align: center;
`;

const postsContainerClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
`;

const postsClass = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 60px;
  margin-bottom: 75px;
  width: 100%;

  @media (max-width: 975px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

const postsWithFeaturedClass = cx(
  postsClass,
  css`
    padding-top: 16px;
  `
);

const filterContainer = css`
  position: sticky;
  top: 100px;
  -webkit-box-align: start;
  align-self: flex-start;
  height: fit-content;

  @media (max-width: 900px) {
    position: unset;
    top: unset;
    -webkit-box-align: unset;
    align-self: unset;
    height: unset;

    background: var(--white);
    position: fixed;
    z-index: -1;
    width: 100%;
  }
`;

const filterContainerClosed = cx(
  filterContainer,
  css`
    display: none;
    visibility: hidden;
    height: 0;
    opacity: 0;
    width: 0px;
  `
);

const filterContainerOpen = cx(
  filterContainer,
  css`
    background: var(--white);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    overflow-y: scroll;
    opacity: 0;
    display: block;

    animation: 0.5s ease-in-out 0.5s 1 normal forwards running fadein;
    @keyframes fadein {
      0% {
        opacity: 0.25;
        clip-path: ellipse(5% 10% at 100% 0%);
      }
      20% {
        opacity: 0.25;
        clip-path: ellipse(15% 25% at 100% 0%);
      }
      40% {
        opacity: 0.25;
        clip-path: ellipse(30% 40% at 100% 0%);
      }
      60% {
        opacity: 0.25;
        clip-path: ellipse(55% 75% at 100% 0%);
      }
      80% {
        opacity: 0.25;
        clip-path: ellipse(90% 105% at 100% 0%);
      }
      90% {
        opacity: 0.25;
        clip-path: ellipse(120% 105% at 100% 0%);
      }
      99% {
        opacity: 0.99;
        clip-path: ellipse(150% 150% at 100% 0%);
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }
  `
);

export default function BlogPage({
  catData,
  categoryGroups,
  blogSettings,
  headerText,
  category,
}) {
  const renderContent = (data) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(category);
    const [menuOpen, setMenuOpen] = useAtom(menuOpenAtom);

    useEffect(() => {
      if (menuOpen) {
        setTimeout(() => {
          document.body.style.overflowY = "hidden";
        }, 1000);
      } else {
        document.body.style.overflowY = "unset";
      }
    }, [menuOpen]);

    const getFeatured = [
      blogSettings.node.frontmatter.featuredBlogPost,
      ...blogSettings.node.frontmatter.picks,
    ];

    const growthAndCultureFeatured = [
      blogSettings.node.frontmatter.featuredGrowthCulture,
      ...blogSettings.node.frontmatter.picksGrowthCulture,
    ];

    const industrySolutionsFeatured = [
      blogSettings.node.frontmatter.featuredIndustrySolutions,
      ...blogSettings.node.frontmatter.picksIndustrySolutions,
    ];

    const physicalSecurityFeatured = [
      blogSettings.node.frontmatter.featuredPhysicalSecurity,
      ...blogSettings.node.frontmatter.picksPhysicalSecurity,
    ];

    const productUpdatesFeatured = [
      blogSettings.node.frontmatter.featuredProductUpdates,
      ...blogSettings.node.frontmatter.picksProductUpdates,
    ];

    useEffect(() => {
      setSelectedCategory(category);
    }, []);

    const { edges: _posts } = catData.allMarkdownRemark;
    const { edges: _postsAll } = data.allMarkdownRemark;

    const featuredBlogSlug = `/blog/${get(
      blogSettings,
      "node.frontmatter.featuredBlogPost"
    )}/`;

    const featuredBlogPost = _postsAll.find(
      (post) => get(post, "node.fields.slug") === featuredBlogSlug
    );

    const allFeatured = getFeatured.map((featured) => {
      const featuredSlug = `/blog/${featured}/`;
      return _postsAll.find(
        (post) => get(post, "node.fields.slug") === featuredSlug
      );
    });

    const getPosts = (slugs) => {
      return slugs.map((featured) => {
        const featuredSlug = `/blog/${featured}/`;

        return _postsAll.find(
          (post) => get(post, "node.fields.slug") === featuredSlug
        );
      });
    };

    const getFeaturedPosts = () => {
      switch (category) {
        case "All Categories":
          return allFeatured;
        case "Physical Security":
          return getPosts(physicalSecurityFeatured);
        case "Industry Solutions":
          return getPosts(industrySolutionsFeatured);
        case "Growth and Company Culture":
          return getPosts(growthAndCultureFeatured);
        case "Product Updates":
          return getPosts(productUpdatesFeatured);
      }
    };

    const getSectionTitle = useCallback(() => {
      if (selectedCategory === "Industry Solutions") {
        return "Sort by Industry";
      }
      return "Recent Posts";
    }, [selectedCategory]);

    const pageSize = 12;
    const siblingCount = 1;

    const postTotal = _posts
      // filter out the featured blog posts so we can display them at the top of the page
      .filter((post) => {
        const filterPosts = getFeaturedPosts();
        return !filterPosts.includes(post);
      });

    const [paginationTotal] = useState(postTotal.length);

    const posts = useMemo(() => {
      const firstPageIndex = (currentPage - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      return (
        _posts
          // filter out the featured blog posts so we can display them at the top of the page
          .filter((post) => {
            const filterPosts = getFeaturedPosts();
            return !filterPosts.includes(post);
          })
          // show only posts for the current pagination page
          .slice(firstPageIndex, lastPageIndex)
      );
    }, [currentPage]);

    return (
      <div className={blogContentClass}>
        <BlogHeader
          headerText={headerText}
          categoryGroups={categoryGroups}
          category={category}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setMenuOpen={setMenuOpen}
        />
        <SectionContainer style={{ padding: "0 1.25rem" }}>
          <SectionInner>
            <div
              className={menuOpen ? filterContainerOpen : filterContainerClosed}
            >
              <BlogSearch
                posts={_postsAll}
                featuredPosts={getFeaturedPosts()}
              />
            </div>
            <div className={postsContainerClass}>
              <BlogFeaturedPosts
                featuredPosts={getFeaturedPosts()}
                category={selectedCategory}
              />
              {!!posts.length && (
                <TitleMed className={subtitleClass}>
                  {getSectionTitle()}
                </TitleMed>
              )}
              {!!posts.length && selectedCategory !== "Industry Solutions" && (
                <>
                  <div
                    className={
                      featuredBlogPost ? postsWithFeaturedClass : postsClass
                    }
                  >
                    {posts.map((post) => (
                      <BlogPostCard key={post.node.id} post={post.node} />
                    ))}
                  </div>
                  <BlogPagination
                    currentPage={currentPage}
                    totalCount={paginationTotal}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                    siblingCount={siblingCount}
                  />
                </>
              )}
              {!!posts.length && selectedCategory === "Industry Solutions" && (
                <IndustryFilter
                  items={postTotal}
                  categories={[
                    "Education",
                    "Healthcare",
                    "Commercial/Retail",
                    "Other",
                  ]}
                  card={BlogPostCard}
                />
              )}
            </div>
          </SectionInner>
        </SectionContainer>
        <TrialBanner />
      </div>
    );
  };

  const allBlogPostsQuery = graphql`
    query AllBlogPosts {
      allMarkdownRemark(
        limit: 100
        sort: { frontmatter: { date: DESC } }
        filter: {
          frontmatter: { templateKey: { eq: "blog-post" }, draft: { ne: true } }
        }
      ) {
        totalCount
        edges {
          node {
            id
            excerpt(pruneLength: 400)
            fields {
              slug
            }
            html
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              category
              thumbnail
              featuredImages {
                featuredImageThumbnail
                featuredImageAltText
                featuredImageTitle
              }
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={allBlogPostsQuery} render={renderContent} />;
}
