import SlideInContainer from "components/common/SlideInContainer";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import React from "react";

import TextLinkV2 from "../common/TextLinkV2";
import { SectionContainer, SectionInner } from "../rhombus-UI/theme/containers";
import { FancyTitleMed, TitleSmall } from "../rhombus-UI/theme/typography";

const leftContainer = css`
  width: 60%;
  max-width: 693px;
  padding-top: 19px;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  @media (max-width: 1480px) {
    padding-left: 40px;
  }

  @media (max-width: 1175px) {
    width: 100%;
    padding-top: unset;
  }

  @media (max-width: 1110px) {
    padding-left: 20px;
  }
`;

const learnClass = css`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 1.25rem;
`;

const learnContainer = css`
  padding: 25px 0px 75px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  max-width: 693px;
`;

const learnLineClass = css`
  display: flex;
`;
const bulletClass = css`
  width: 25px !important;
  height: 25px !important;
  margin: 5px 25px 0px 0px !important;
  aspect-ratio: 1 !important;
  overflow: visible !important;
  flex-shrink: 0;
`;
const rightContainer = css`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1480px) {
    padding-right: 40px;
  }

  @media (max-width: 1175px) {
    width: 100%;
    justify-content: center;
    display: none;
  }

  @media (max-width: 1110px) {
    padding-right: 20px;
  }

  @media (max-width: 600px) {
    padding-right: unset;
  }
`;

export default function GrantsFormSection() {
  const lines = [
    "Researching Federal and State Level Grants",
    "Examples of Successful Grant Applications",
    "Pre and Post Grant Award Installations",
    "Flexible Deployments Designed to Meet and Exceed Grant Requirements",
  ];

  return (
    <SectionContainer>
      <SectionInner style={{ gap: "5rem" }}>
        <div className={rightContainer}>
          <StaticImage
            src="./img/grant-info-image-min.png"
            alt=""
            objectFit="cover"
            style={{ borderRadius: "1rem", overflow: "hidden" }}
          />
        </div>
        <div className={leftContainer}>
          <SlideInContainer>
            <FancyTitleMed style={{ textAlign: "left" }}>
              Navigating the Path to Secure Funding
            </FancyTitleMed>
          </SlideInContainer>
          <p>
            We understand that fostering a safe, secure environment is a top
            priority for schools, and recognize the challenges they face to
            secure adequate funding. Our goal is to help schools and districts
            access state funding and grants that align with their security
            needs.
          </p>
          <p>
            Rhombus Partners work with experts to provide hands-on support in
            navigating the grant application process, assisting you in finding
            the optimal funding solution tailored to your school or district's
            requirements.
          </p>
          <div className={learnClass}>
            <SlideInContainer>
              <TitleSmall>How Our Partners Can Help:</TitleSmall>
            </SlideInContainer>
            <div className={learnContainer}>
              {lines.map((line, i) => {
                return (
                  <SlideInContainer key={i}>
                    <div className={learnLineClass}>
                      <StaticImage
                        src="./img/check.png"
                        alt="checked"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>{line}</p>
                    </div>
                  </SlideInContainer>
                );
              })}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1rem",
                  marginTop: "2.5rem",
                }}
              >
                <TitleSmall>Ready to Learn more?</TitleSmall>
                <TextLinkV2 path="#form" title="Start by requesting a demo" />
              </div>
            </div>
          </div>
        </div>
      </SectionInner>
    </SectionContainer>
  );
}
