import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import React from "react";
import IconSystem from "./icon-components/IconSystem";
import IconAiAnalaytics from "./icon-components/IconAiAnalaytics";
import IconLightning from "./icon-components/IconLightning";
import LocationIcon from "./icon-components/LocationIcon";
import {
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { styled } from "@linaria/react";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";

const PlatformSectionContainer = styled(SectionContainer)`
  background: var(--primary-bg);
  padding-bottom: 0;
  overflow: visible;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  @media (max-width: 600px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

const PlatformSectionInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const FeatureCol = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
  color: var(--white);
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    h3 {
      width: 100%;
      max-width: 520px;
    }
  }
`;

const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  max-width: 520px;
  padding-bottom: 5rem;
  @media (max-width: 1024px) {
    padding-bottom: 0;
  }
`;

const Feature = styled.div`
  display: flex;
  gap: 1.25rem;
`;

const FeatureIcon = styled.div`
  width: 28px;
  flex-shrink: 0;
`;

const ImageColumn = styled(SectionCol)`
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const imageStyles = css`
  height: 100%;
  max-width: 673px;
  @media (max-width: 600px) {
    margin-bottom: -5rem;
  }
`;

const Title = styled(TitleMed)`
  text-align: center;
  color: var(--white);
`;

const RhombusPlatformFeatures = () => {
  const features = [
    {
      renderIcon: () => (
        <IconSystem height="28" width="28" color="var(--primary)" />
      ),
      title: "User-Friendly",
      description:
        "Rhombus combines consumer-level ease of use with enterprise-grade performance, no technical expertise required.",
    },
    {
      renderIcon: () => (
        <IconAiAnalaytics height="28" width="28" color="var(--primary)" />
      ),
      title: "AI Analytics",
      description:
        "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.",
    },
    {
      renderIcon: () => (
        <IconLightning height="28" width="28" color="var(--primary)" />
      ),
      title: "Bandwidth & Latency",
      description:
        "Get the best performance with 50% less bandwidth utilization and 10x better latency compared to other cloud vendors.",
    },
    {
      renderIcon: () => (
        <LocationIcon height="28px" width="28" color="var(--primary)" />
      ),
      title: "Infinite Scalability",
      description:
        "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
    },
  ];

  return (
    <PlatformSectionContainer id="platform-features">
      <Title fontSize="40px">Rhombus Platform Features</Title>
      <PlatformSectionInner>
        <ImageColumn>
          <StaticImage
            src="./img/podium-renders/podium-render.png"
            className={imageStyles}
            objectFit="contain"
            objectPosition="bottom"
          />
        </ImageColumn>
        <FeatureCol>
          <FeaturesList>
            {features.map((feature, index) => {
              const { renderIcon, title, description } = feature;
              return (
                <Feature key={`${title}-${index}`}>
                  <FeatureIcon>{renderIcon()}</FeatureIcon>
                  <TextContainer style={{ marginTop: "calc(28px / 4)" }}>
                    <TitleSmall fontSize="24px">{title}</TitleSmall>
                    <MainParagraph>{description}</MainParagraph>
                  </TextContainer>
                </Feature>
              );
            })}
          </FeaturesList>
        </FeatureCol>
      </PlatformSectionInner>
    </PlatformSectionContainer>
  );
};

export default RhombusPlatformFeatures;
