import {
  CTA_BUTTON_COPY_3,
  CTA_LINK_4,
} from "components/data/landing-page-data";
import LandingPageAlt from "components/landing-pages/consultant-lp/LandingPageAlt";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function CamerasD() {
  return (
    <GlobalLayout
      landingPage
      hideFooter
      includeButton
      buttonText={CTA_BUTTON_COPY_3}
      ctaLink={CTA_LINK_4}
      color="var(--secondary-bg)"
    >
      <Helmet>
        <title>Rhombus Cameras</title>
        <meta
          name="description"
          content="Discover the power of cloud-managed security cameras."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <LandingPageAlt />
    </GlobalLayout>
  );
}
