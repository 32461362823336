import React from "react";
import { css, cx } from "@linaria/core";
import PageContent from "components/page/PageContent";
import SlideInContainer from "./SlideInContainer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const sectionContainer = css`
  display: flex;
  justify-content: center;
  background: var(--gradient-light);
`;

const sectionContainerDark = cx(
  sectionContainer,
  css`
    background: var(--primary-bg);
  `
);

const contentContainer = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px 0;
  @media (max-width: 600px) {
    padding: 4rem 0;
  }
`;

const contentClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 3.2rem;
`;

const logoWrapper = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 975px) {
    width: 30%;
  }
  @media (max-width: 650px) {
    width: 45%;
  }
`;

const imageContainerClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  align-self: center;
  flex-wrap: wrap;
`;

const imageClass = css`
  width: 135px;
  pointer-events: none;
`;

const IndustryStripeSmall = ({ logos, theme }) => {
  return (
    <section
      className={theme === "dark" ? sectionContainerDark : sectionContainer}
    >
      <div className={contentContainer}>
        <PageContent>
          <div className={contentClass}>
            <SlideInContainer>
              <div className={imageContainerClass}>
                {logos.map((logo) => {
                  const logoRef = getImage(logo.img);
                  return (
                    <div className={logoWrapper} key={logo.alt}>
                      <GatsbyImage
                        image={logoRef}
                        style={{ maxWidth: logo.width }}
                        alt={logo.alt}
                        className={imageClass}
                      />
                    </div>
                  );
                })}
              </div>
            </SlideInContainer>
          </div>
        </PageContent>
      </div>
    </section>
  );
};

export default IndustryStripeSmall;
