import React from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import PageContent from "components/page/PageContent";
import SlideInContainer from "components/common/SlideInContainer";
import useIsMobile from "hooks/useIsMobile";

const sectionContainer = css`
  display: flex;
  justify-content: center;
  padding: 4rem 10px;
`;

const sectionInner = css`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const sectionHeader = css`
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 20px;
    padding-right: 0;
  }
`;

const title = css`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;
    color: var(--nuetral-900);
    max-width: 544px;
  }
  h3 {
    font-weight: 900;
    color: var(--blue-500);
    font-size: 16px;
    text-transform: uppercase;
  }
`;

const logos = css`
  display: flex;
  gap: 20px;
  @media (min-width: 1024px) {
    justify-content: space-between;
    width: 300px;
  }
  @media (max-width: 1023px) {
    justify-content: flex-end;
  }
`;

const iconStyles = css`
  height: 25px !important;
  aspect-ratio: 1 !important;
`;

const sectionBody = css`
  display: flex;
  justify-content: center;
`;

const table = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div {
    border-radius: 15px;
  }
  > div:nth-child(odd) {
    background: var(--nuetral-100);
  }
`;

const tableRow = css`
  width: 100%;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1023px) {
    padding: 30px 15px;
  }
`;

const titleCol = css``;
const titleCell = css``;
const specCol = cx(logos);
const specCell = css`
  display: flex;
  min-width: 50px;
  width: 50%;
  justify-content: center;
  align-items: center;
  @media (max-width: 1023px) {
    width: unset;
    h1 {
      font-size: 16px;
    }
  }
`;

const VsComparison = ({ data }) => {
  const { isMobile } = useIsMobile(1022);
  const rhombusData = data.rhombus;
  const compareData = data.competitor.data;
  const competitorLogo = getImage(data.competitor.logo);
  const logoWidth = isMobile
    ? data.competitor.mobileWidth
    : data.competitor.logoWidth;

  const checkIcon = () => (
    <StaticImage
      src="../../../images/form-check.png"
      alt="check"
      className={iconStyles}
      loading="eager"
      placeholder="blurred"
      objectFit="contain"
    />
  );
  const notIcon = () => (
    <StaticImage
      src="../../../images/form-error.png"
      alt="no"
      className={iconStyles}
      loading="eager"
      placeholder="blurred"
      objectFit="contain"
    />
  );
  const handleSpec = spec => {
    if (typeof spec == "boolean") {
      const icon = spec ? checkIcon() : notIcon();
      return icon;
    } else return spec;
  };

  return (
    <section className={sectionContainer}>
      <PageContent>
        <div className={sectionInner}>
          <div className={sectionHeader}>
            <div className={title}>
              <h3>compare</h3>
              <h2>{data.title}</h2>
            </div>
            <div className={logos}>
              <div className={specCell}>
                <StaticImage
                  src="../../../images/rhombus-symbol-40x40.svg"
                  alt="rhombus logo"
                  loading="eager"
                  placeholder="blurred"
                  objectFit="contain"
                />
              </div>
              <div className={specCell}>
                {competitorLogo ? (
                  <GatsbyImage
                    image={competitorLogo}
                    alt={""}
                    loading="eager"
                    objectFit="contain"
                    style={{
                      maxWidth: logoWidth,
                    }}
                  />
                ) : (
                  <h1>{data.competitor.title}</h1>
                )}
              </div>
            </div>
          </div>
          <div className={sectionBody}>
            <div className={table}>
              {data.keys.map((key, index) => {
                return (
                  <SlideInContainer>
                    <div className={tableRow} key={key}>
                      <div className={titleCol}>
                        <div className={titleCell}>{key}</div>
                      </div>
                      <div className={specCol}>
                        <div className={specCell}>
                          {isMobile
                            ? handleSpec(true)
                            : handleSpec(rhombusData[key])}
                        </div>
                        <div className={specCell}>
                          {isMobile
                            ? handleSpec(false)
                            : handleSpec(compareData[key])}
                        </div>
                      </div>
                    </div>
                  </SlideInContainer>
                );
              })}
            </div>
          </div>
        </div>
      </PageContent>
    </section>
  );
};

export default VsComparison;
