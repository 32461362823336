import React, { useRef } from "react";
import LogoSliderV2 from "components/common/LogoSliderV2";
import ReviewSliderSection from "components/common/sliders/ReviewSliderSection";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import { CTA_BUTTON_COPY, CTA_LINK_1 } from "components/data/landing-page-data";
import RhombusPlatformFeatures from "components/common/RhombusPlatformFeatures";
import SurveillanceHeroV3 from "./SurveillanceHeroV3";
import StickyButtonBanner from "./StickyButtonBanner";
import useIsMobile from "hooks/useIsMobile";
import { useIsVisible } from "hooks/useIsVisible";
import AllInOnePlatformSection from "./AllInOnePlatformSection";
import { atom } from "jotai";

export const useGetQuoteTitleAtom = atom(false);

export default function SurveillancePageV4() {
  const { isMobile } = useIsMobile(820);
  const bannerRef = useRef(null);
  const bannerVisible = useIsVisible(bannerRef);

  return (
    <>
      <SurveillanceHeroV3 />
      <LogoSliderV2 bgColor="var(--blue-800)" />
      <AllInOnePlatformSection />
      <RhombusPlatformFeatures />
      <ReviewSliderSection />
      <div ref={bannerRef}>
        <TrialBanner
          buttonCopy={CTA_BUTTON_COPY}
          ctaLink={CTA_LINK_1}
          lightMode
        />
      </div>
      {isMobile && !bannerVisible && <StickyButtonBanner />}
    </>
  );
}
