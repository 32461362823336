import GlobalLayout from "components/page/GlobalLayout";
import AccessControlSpecsPage from "components/access-control/specs/AccessControlSpecsPage";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import useIsMobile from "hooks/useIsMobile";

export default function DR20() {
  const data = useStaticQuery(graphql`
    query {
      dr20Header: file(
        relativePath: {
          eq: "components/access-control/specs/img/dr20-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dr20HeaderMobile: file(
        relativePath: {
          eq: "components/access-control/specs/img/dr20-header-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dr20Dims: file(
        relativePath: {
          eq: "components/access-control/specs/img/dr20-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR40: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr40.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDC20: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dc20-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);
  const { isMobile } = useIsMobile(700);
  let exploreDR40Width = "51px";

  if (isMobile) {
    exploreDR40Width = "41px";
  }

  const dr20 = {
    title: "DR20",
    model: "DR20",
    imageHeader: data.dr20Header,
    imageHeaderMobile: data.dr20HeaderMobile,
    objectPosition: "center",
    description:
      "Quickly access your spaces by card, mobile app, or wave-to-unlock gestures with the DR20 Door Reader— a modern, touchless, and reliable smart reader.",
    image: data.dr20Dims,
    pdf: "/files/specs/DR20.pdf",
    manual: "/files/manuals/DR20-Manual.pdf",
    column1: [
      {
        title: "Dimensions",
        text: "Mullion 50mm (1.97in) x 130mm (5.12in) x 20mm (0.79in) (height off wall, 28.3mm including cable)",
      },
      {
        title: "Credential Compatibility",
        text: "Rhombus Badge, ISO 14443A (CSN), Rhombus Key app",
      },
      {
        title: "Weight",
        text: "0.31kg (0.7lbs)",
      },
      {
        title: "Weather Resistance",
        text: "IP65",
      },
      {
        title: "Operating Temperature",
        text: "-40˚C (-40˚F) to 50˚C (122˚F)",
      },
      {
        title: "Operating Humidity",
        text: "10%-90%",
      },
    ],
    column2: [
      {
        title: "Frequency",
        text: "13.56 MHz",
      },
      {
        title: "Controller Compatibility",
        text: "DC20",
      },
      {
        title: "Input Voltage",
        text: "8-28VDC",
      },
      {
        title: "Power Consumption MAX",
        text: "4.5W",
      },
      {
        title: "Mounting",
        text: "Wood / drywall screws",
      },
      {
        title: "In the Box",
        text: "User manual, drill template, 1-Gang Wall Plate",
      },
      {
        title: "FCC, UL 294, CE, RCM, AUS and NZ Compliant",
        text: "",
      },
      {
        title: "10-Year Warranty Included",
      },
    ],
    exploreCards: [
      {
        image: data.exploreDR40,
        alt: "DR40 Access Control Door Reader",
        width: exploreDR40Width,
        model: "DR40",
        useCase: "Equipped with a door reader, camera, and intercom",
        link: "/access-control/door-readers/dr40/",
      },
      {
        image: data.exploreDC20,
        alt: "DC20 Access Control Unit",
        width: "168px",
        model: "DC20",
        useCase: "4-Door Access Control Unit",
        link: "/access-control/dc20/",
      },
    ],
  };

  return (
    <GlobalLayout dark color="transparent">
      <Helmet>
        <title>Rhombus DR20 Access Control Door Reader</title>
        <meta
          name="description"
          content="Access spaces by card, mobile app, or wave-to-unlock gestures with a modern, touchless, and reliable smart reader."
        />
      </Helmet>
      <AccessControlSpecsPage model={dr20} />
    </GlobalLayout>
  );
}
