import AccordionSection from "components/common/AccordionSection";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import InfoBoxSection from "components/common/InfoBoxSection";
import InfoSlider from "components/common/InfoSlider";
import PageIntro from "components/common/PageIntro";
import SectionIntro from "components/common/SectionIntro";
import GlobalLayout from "components/page/GlobalLayout";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import CTABanner from "components/common/footer-banners/CTABanner";
import ComplianceSection from "components/compliance/ComplianceSection";
import IconSecureShield from "components/common/icon-components/IconSecureShield";
import IconWarranty from "components/common/icon-components/IconWarranty";
import IconLock from "components/common/icon-components/IconLock";

export default function trust() {
  const renderContent = (data) => {
    const headerSection = {
      preTitle: "trust",
      title: "Your Data is Secure with Rhombus ",
      description:
        "A better security platform from a team you can trust. Learn how partnering with Rhombus improves cybersecurity and sets your organization up for long-term success with secure-by-default architecture.",
      image: data.headerImage,
    };

    const infoSlides = [
      {
        tabTitle: "Access Protocols",
        preTitle: "CONTROL SYSTEM ACCESS",
        title: "Strict Access Protocols",
        description:
          "Only you control and share access to your data—no exceptions. System access must be explicitly granted to Rhombus employees: there is no ‘super admin’ mode. No one except for authorized end users at your organization have the authority to initiate and provide account access to Support or Partners. ",
        image: data.infoSlide1,
      },
      {
        tabTitle: "Granular Permissions",
        title: "Role-Based Access",
        description:
          "Extend precisely as much system access to users as you want, no more and no less. Gain granular control over user permissions with custom roles and access types based on role, location, and more.",
        image: data.infoSlide2,
      },
      {
        tabTitle: "Secure Sharing",
        preTitle: "Safer Communication",
        title: "Secure Sharing",
        description:
          "Quickly and securely share livestreams and video clips without extending system access. Include passwords and expirations dates to maintain tight control over footage.",
        image: data.infoSlide3,
      },
      {
        tabTitle: "SSO & 2FA",
        preTitle: "SECURE LOG IN",
        title: "Single Sign-On & Multi-Factor Authentication",
        description:
          "Create a secure login experience. Integrate with your preferred SSO provider and set up 2FA for an added layer of protection. No Rhombus infrastructure access relies solely on username and password and all internal infrastructure requires 2FA tokens that change every 30 seconds.",
        image: data.infoSlide4,
      },
      {
        tabTitle: "Access Logs",
        preTitle: "AUDIT SYSTEM ACCESS",
        title: "Access Logs",
        description:
          "All granted access is logged and can be revoked at any time. Review comprehensive access records to understand who has accessed your system, and any changes they have made.",
        image: data.infoSlide5,
      },
    ];

    const accordianSection = {
      title: "Security That’s Built in to Your Solutions",
      items: [
        {
          title: "End-to-End Encryption",
          p: "All media, video, and cloud communication is fully encrypted at rest and in transit, giving you total security and protection against man-in-the middle attacks. The system segregates and rotates all encryption keys, so that even if one key is compromised, all others remain secure.",
          img: data.drawer1,
          alt: "End-to-End Encryption",
        },
        {
          title: "Automatic Security Updates",
          p: "Firmware updates happen automatically, ensuring that you’re always 100% current with the latest cybersecurity protections. Automatic health monitoring catches potential issues before they develop, and regular third-party audits provide an added layer of protection.",
          img: data.drawer2,
          alt: "Automatic Security Updates",
        },
        {
          title: "Hardware Security",
          p: "All Rhombus devices are engineered in-house, and we use 100% whitelisted manufacturers who meet NDAA and TAA compliance. We design all devices from the ground up to be durable and reliable, and back our hardware with an industry-leading 10-year warranty.",
          img: data.drawer3,
          alt: "Hardware Security",
        },
      ],
    };

    const pageSections = [
      {
        Icon: <IconLock />,
        title: "Security",
        blurb: "Robust, built-in security architecture for total peace of mind",
        anchor: "security",
      },
      {
        Icon: <IconSecureShield color="var(--teal-500)" />,
        title: "Data Privacy",
        blurb: "Manage, protect, and share your data with complete control",
        anchor: "privacy",
      },
      {
        Icon: <IconWarranty color="var(--teal-500)" height="50" width="43" />,
        title: "Compliance",
        blurb:
          "Built-in adherence to global, regional, and industry regulations",
        anchor: "compliance",
      },
    ];

    const infoBoxes2 = [
      {
        icon: data.infoIcon5,
        title: "Health Insurance Portability and Accountability Act (HIPAA)",
        blurb:
          "Maintain HIPAA compliance using Rhombus as a tool to ensure the protection of PHI.",
      },
      {
        icon: data.infoIcon7,
        title: "Payment Card Industry Compliance (PCI)",
        blurb:
          "Meet PCI standards by protecting cardholder data and sensitive authentication data.",
      },
      {
        icon: data.infoIcon8,
        title: "Biometric Information Privacy Act (BIPA)",
        blurb:
          "Comply with BIPA regarding the collection and storage of biometric information.",
      },
      {
        icon: data.infoIcon9,
        title:
          "Personal Information Protection and Electronic Documents Act (PIPEDA)",
        blurb:
          "Meet PIPEDA regulations on how businesses collect, use, and disclose such data.",
      },
      {
        icon: data.infoIcon10,
        title: "Cybersecurity Maturity Model Certification (CMMC)",
        blurb:
          "Comply with DoD cybersecurity standards for defense industrial base (DIB) contractors.",
      },
      {
        icon: data.infoIcon11,
        title: "National Institute of Standards and Technology (NIST)",
        blurb:
          "Comply with the strictest cybersecurity & data privacy standards in the US.",
      },
      {
        icon: data.infoIcon12,
        title: "Criminal Justice Information Services (CJIS)",
        blurb:
          "Adhere to standards set by criminal justice and law enforcement for securing CJI data.",
      },
    ];

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            The Trusted Choice for Security, Data Privacy, and Compliance |
            Rhombus
          </title>
          <meta
            name="description"
            content="Partnering with Rhombus improves cybersecurity and sets your organization up for long-term success with secure-by-default architecture."
          />
        </Helmet>
        <HeroLayout1 data={headerSection} right />
        <PageIntro title="The Trusted Choice" sections={pageSections} />
        <SectionIntro
          preTitle="Security Overview"
          title="Secure-by-Default Architecture for Total Peace of Mind"
          blurb="With decades of cybersecurity experience, our team of experts follow a security-first approach from design to engineering. We use ‘Zero Trust’ architecture that protects at every layer from the ground up."
          elementId={pageSections[0].anchor}
          color="var(--nuetral-100)"
        />
        <AccordionSection
          data={accordianSection}
          fancy={false}
          color="var(--nuetral-100)"
        />
        <SectionIntro
          preTitle="DATA PRIVACY OVERVIEW"
          title="Manage, Protect, and Share Your Data with Complete Control"
          blurb="When data privacy truly matters, Rhombus has your back. We safeguard your data so you can enjoy complete security and peace of mind."
          elementId={pageSections[1].anchor}
        />
        <InfoSlider
          data={infoSlides}
          title="Built-In Data Privacy Protection"
          fancy={false}
        />
        <ComplianceSection />
        <InfoBoxSection
          title="Maintain Compliance with Industry & International Regulations"
          infoBoxes={infoBoxes2}
        />
        <CTABanner />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/trust/img/trust-hero-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      drawer1: file(
        relativePath: { eq: "components/trust/img/heat-maps-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer2: file(
        relativePath: {
          eq: "components/landing-pages/search/img/security-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      drawer3: file(
        relativePath: { eq: "components/trust/img/r100-library-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/trust/img/video-wall-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/trust/img/user-roles-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/trust/img/share-clip-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/trust/img/secure-login-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/trust/img/in-depth-recording-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/common/icons/security.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(relativePath: { eq: "components/common/icons/shield.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/common/icons/certification.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }

      infoIcon1: file(
        relativePath: { eq: "components/common/icons/USFlag.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon2: file(
        relativePath: { eq: "components/common/icons/World.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon3: file(
        relativePath: { eq: "components/common/icons/SOC2-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon4: file(
        relativePath: { eq: "components/common/icons/SOC2-2gear.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon5: file(
        relativePath: { eq: "components/common/icons/health.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon6: file(
        relativePath: { eq: "components/common/icons/GDPR.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon7: file(
        relativePath: { eq: "components/common/icons/CreditCards.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon8: file(
        relativePath: { eq: "components/common/icons/FingerPrint.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon9: file(
        relativePath: { eq: "components/common/icons/Leaf.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon10: file(
        relativePath: { eq: "components/common/icons/CMMC.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon11: file(
        relativePath: { eq: "components/common/icons/SecureByDefault.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoIcon12: file(
        relativePath: { eq: "components/common/icons/CJIS.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
