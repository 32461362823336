import { toTitleCase } from "components/common/util/helperFunctions";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  FancyPageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import React from "react";

const sectionContainer = css`
  display: flex;
  justify-content: center;
  padding: 4rem 20px;
  padding-bottom: 0;
  background: var(--gradient-light);
  @media (max-width: 600px) {
    padding: 0;
    padding-top: 4rem;
  }
`;

const sectionContainerCenter = css`
  display: flex;
  justify-content: center;
  padding: 4rem 20px;
  background: var(--gradient-light);
  @media (max-width: 600px) {
    padding: 0;
    padding-top: 4rem;
  }
`;

const sectionInner = css`
  display: flex;
  max-width: var(--max-width);
  width: 100%;
  gap: 4rem;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    gap: 0;
  }
`;

const sectionLeft = css`
  display: flex;
  width: 50%;
  flex-grow: 1;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 1100px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

const sectionRight = cx(
  sectionLeft,
  css`
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 4rem;
    @media (max-width: 1100px) {
      justify-content: center;
    }
    @media (max-width: 600px) {
      padding: 0;
    }
  `
);

const textWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 34px;
  max-width: 665px;
  flex-grow: 1;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const dateRow = css`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-weight: 800;
  }
`;

const iconStyles = css`
  height: 25px !important;
  aspect-ratio: 1 !important;
`;

const imageWrapper = css`
  height: 50%;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  @media (max-width: 1100px) {
    display: none;
  }
  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-bottom: -20px;
  }
`;

const imageWrapperCenter = css`
  height: 50%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    display: none;
  }
  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-bottom: 1.25rem;
  }
`;

const imageStyles = css`
  width: 100% !important;
  flex-shrink: 0;
`;

const WebinarHeader = ({ data, imageCenter = false }) => {
  return (
    <section
      className={imageCenter ? sectionContainerCenter : sectionContainer}
    >
      <div className={sectionInner}>
        <div className={sectionLeft}>
          <div className={textWrapper}>
            <div>
              <PreTitle>{data.preTitle}</PreTitle>
              <FancyPageTitle>{toTitleCase(data.title)}</FancyPageTitle>
            </div>
            {data.date ? (
              <div className={dateRow}>
                <StaticImage
                  src="../../webinars/templates/assets/time.png"
                  alt="time"
                  placeholder="blurred"
                  loading="eager"
                  objectFit="contain"
                  className={iconStyles}
                />
                <p>{data.date}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={imageCenter ? imageWrapperCenter : imageWrapper}>
            <GatsbyImage
              image={getImage(data.image)}
              alt={data.title}
              loading="eager"
              className={imageStyles}
            />
          </div>
        </div>
        <div className={sectionRight}>
          <FormWrapper width="546px" height="527px" title={data.formTitle}>
            <RhombusForm formId={data.formId} />
          </FormWrapper>
        </div>
      </div>
    </section>
  );
};

export default WebinarHeader;
