import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  GradientTextLink,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import React from "react";
import SlideInContainer from "./SlideInContainer";
import { styled } from "@linaria/react";
import Arrow from "./img/blue-arrow.png";

const flexRow = css`
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
`;

const flexItem = css`
  flex-basis: calc((100% - 4rem) / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  p {
    max-width: 270px;
  }
  @media (max-width: 600px) {
    flex-basis: 100%;
  }
`;

const PageIntroInner = styled(SectionInner)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4rem;
  > div {
    width: 100%;
  }
`;

const PageIntro = ({ title, sections }) => {
  return (
    <SectionContainer>
      <PageIntroInner>
        <FancyTitle>{title}</FancyTitle>
        <SlideInContainer>
          <div className={flexRow}>
            {sections.map((item) => {
              const { title, Icon, blurb, anchor } = item;
              return (
                <div key={title} className={flexItem}>
                  <TextContainer
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    {Icon}
                    <TitleSmall>{title}</TitleSmall>
                    <MainParagraph>{blurb}</MainParagraph>
                    <GradientTextLink
                      href={`#${anchor}`}
                      style={{ flexDirection: "column" }}
                      bottom
                    >
                      Learn More
                      <img src={Arrow} style={{ transform: "rotate(90deg)" }} />
                    </GradientTextLink>
                  </TextContainer>
                </div>
              );
            })}
          </div>
        </SlideInContainer>
      </PageIntroInner>
    </SectionContainer>
  );
};

export default PageIntro;
