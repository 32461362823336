import { styled } from "@linaria/react";
import IconBoundingBoxEye from "components/common/icon-components/IconBoundingBoxEye";
import IconFiles from "components/common/icon-components/IconFiles";
import IconNetworkSecure from "components/common/icon-components/IconNetworkSecure";
import IconSecurity from "components/common/icon-components/IconSecurity";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
  PreTitle,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const SolutionsSectionContainer = styled(SectionContainer)`
  background: var(--nuetral-100);
`;

const SolutionsSectionInner = styled(SectionInner)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleSection = styled(TextContainer)`
  align-items: center;
  text-align: center;
`;

const SolutionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 5rem;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 2.5rem;
  }
`;

const SolutionColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  @media (max-width: 1200px) {
    flex-direction: row;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const IconBox = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 263px;
  width: 100%;
  gap: 1.25rem;
  @media (max-width: 600px) {
    max-width: unset;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  max-width: 610px;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  border-radius: 16px;
  background: #deeafa;
  overflow: hidden;
  flex-shrink: 0;
`;

const SOLUTIONS = [
  [
    {
      Icon: IconBoundingBoxEye,
      title: "Create personalized security solutions",
      description:
        "Integrate seamlessly with existing systems, develop custom applications, and adapt your security with our 100% open API.",
    },
    {
      Icon: IconSecurity,
      title: "Minimize downtime, maximize security",
      description:
        "Keep your security running 24/7 with cloud-based cameras and automatic failover. No NVRs, which means no single points of failure.",
    },
  ],
  [
    {
      Icon: IconFiles,
      title: "Safeguard your surveillance data",
      description:
        "Avoid costly breaches, and protect your sensitive information with our proprietary end-to-end data framework.",
    },
    {
      Icon: IconNetworkSecure,
      title: "Rapid installation and deployment",
      description:
        "Deploy rapidly and ensure full protection for your assets with a network of local installers and 24/7 US-based support.",
    },
  ],
];

const SolutionsSection = () => {
  return (
    <SolutionsSectionContainer>
      <SolutionsSectionInner>
        <TitleSection>
          <PreTitle>Smarter physical security</PreTitle>
          <FancyTitleMed>
            The all-in-one solution for modern surveillance
          </FancyTitleMed>
          <MainParagraph>
            Build, manage, and scale intelligent security systems that adapt and
            grow with your organization.
          </MainParagraph>
        </TitleSection>
        <SolutionsContainer>
          <SolutionColumn>
            {SOLUTIONS[0].map((solution) => {
              const { Icon, description, title } = solution;
              return (
                <IconBox>
                  <Icon />
                  <TitleSmall>{title}</TitleSmall>
                  <MainParagraph>{description}</MainParagraph>
                </IconBox>
              );
            })}
          </SolutionColumn>
          <ImageContainer>
            <StaticImage
              src="../img/surve-e-solutions-min.png"
              alt="The all-in-one solution for modern surveillance"
              loading="lazy"
              quality={100}
              placeholder="dominantColor"
              objectFit="contain"
            />
          </ImageContainer>
          <SolutionColumn>
            {SOLUTIONS[1].map((solution) => {
              const { Icon, description, title } = solution;
              return (
                <IconBox>
                  <Icon />
                  <TitleSmall>{title}</TitleSmall>
                  <MainParagraph>{description}</MainParagraph>
                </IconBox>
              );
            })}
          </SolutionColumn>
        </SolutionsContainer>
      </SolutionsSectionInner>
    </SolutionsSectionContainer>
  );
};

export default SolutionsSection;
