import { styled } from "@linaria/react";
import React, { useMemo, useState } from "react";
import Carrot from "./icon-components/Carrot";
import { useOutsideClick } from "hooks/useOutsideClick.js";

const Select = styled.div`
  height: fit-content;
  background-color: var(--white);
  outline: 1px solid var(--nuetral-200);
  outline-color: ${(props) =>
    props.active ? "var(--blue-500)" : "var(--nuetral-200)"};
  padding: 1rem;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 250ms;
  position: relative;
  border-radius: ${(props) => (props.active ? "8px 8px 0 0" : "8px")};
  cursor: pointer;
  :hover {
    outline-color: var(--blue-500);
  }
  ::after {
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    height: 2px;
    opacity: ${(props) => (props.active ? 1 : 0)};
    background: var(--white);
    z-index: 10;
    transition: all 0ms;
  }
  svg {
    transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0)")};
    transition: all 250ms;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.active ? "1" : "0")};
  max-height: ${(props) => (props.active ? "fit-content" : "0")};
  padding: ${(props) => (props.active ? "1rem" : "0")};
  outline-width: ${(props) => (props.active ? "1px" : "0")};
  outline-style: solid;
  outline-color: ${(props) =>
    props.active ? "var(--blue-500)" : "var(--white)"};
  overflow: auto;
  background-color: var(--white);
  border-radius: 0 0 8px 8px;
  gap: 1.25rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  transition:
    opacity 250ms,
    max-height 250ms,
    padding 250ms;
  text-align: left;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Option = styled.div`
  cursor: pointer;
  font-weight: 600;
  color: ${(props) => (props.active ? "var(--blue-500)" : "var(--blue-900)")};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 250ms;
  text-align: left;
  flex-grow: 1;
  :hover {
    color: var(--blue-500);
  }
  @media (max-width: 1200px) {
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: calc(100% - 1rem - 34px);
    }
  }
`;

const CatText = styled.span`
  text-transform: capitalize;
  color: var(--nuetral-300);
  font-weight: 900;
`;

const OptionImage = styled.img`
  height: 24px;
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const DropDownSelector = ({
  options,
  value,
  relatedValues,
  defaultValue = "Select Model",
  onSelect,
  deviceType = "Cameras",
}) => {
  const [active, setActive] = useState(false);

  const ref = useOutsideClick(() => setActive(false));

  const renderCat = (cat) => {
    return (
      <CatText>
        {cat} {deviceType}
      </CatText>
    );
  };

  const handleCheckPreviousAndNextElements = (
    options,
    currentCat,
    currentIndex
  ) => {
    if (currentIndex === 0) return currentCat;
    const previousCat = options[currentIndex - 1].cat;
    const nextCat = options[currentIndex + 1]?.cat;
    if (
      (previousCat !== currentCat && nextCat === currentCat) ||
      previousCat !== currentCat
    ) {
      return currentCat;
    } else return;
  };

  const handleCatPlacement = (options, currentCat, currentIndex) => {
    const cat = handleCheckPreviousAndNextElements(
      options,
      currentCat,
      currentIndex
    );
    return cat && renderCat(cat);
  };

  const currentOption = useMemo(() => {
    const match = options.filter((item) => item.value === value);
    return match;
  }, [value, options]);

  return (
    <>
      <Select ref={ref} active={active} onClick={() => setActive(!active)}>
        <Option active={!!value}>
          {currentOption.length !== 0 && (
            <OptionImage
              src={currentOption[0].image}
              alt={currentOption[0].value}
            />
          )}
          {value ? <span>{value}</span> : defaultValue}
        </Option>

        <Carrot />

        <OptionContainer active={active} onClick={(e) => e.stopPropagation()}>
          {options.map((option, index) => {
            if (option.value === value) return <></>;
            if (relatedValues && relatedValues.includes(option.value))
              return <></>;
            return (
              <>
                {handleCatPlacement(options, option.cat, index)}
                <Option
                  onClick={() => {
                    onSelect(option.value);
                    setActive(false);
                  }}
                >
                  {option.image && (
                    <OptionImage
                      src={option.image}
                      alt={option.value}
                      width="24"
                      height="24"
                    />
                  )}
                  <span>{option.value}</span>
                </Option>
              </>
            );
          })}
        </OptionContainer>
      </Select>
    </>
  );
};

export default DropDownSelector;
