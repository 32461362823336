import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import DoorReadersPage from "components/access-control/door-readers/overview/DoorReadersPage";

export default function AccessControlLandingPage() {
  return (
    <GlobalLayout color="tranaprent" dark>
      <Helmet>
        <title>
          Secure Building Doors Easily | Door Card Readers | Rhombus
        </title>
        <meta
          name="description"
          content="Upgrade to Rhombus smart door card readers for seamless  and secure access. Wave-to-unlock, mobile app, or card entry with easy install and remote management."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-door-readers.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-door-readers.png"
        />
      </Helmet>
      <DoorReadersPage />
    </GlobalLayout>
  );
}
