import React from "react";
import { styled } from "@linaria/react";

const PickerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5rem;
`;

const Picker = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  justify-content: space-evenly;
  gap: 2.5rem;
  border: 1px solid var(--nuetral-200);
  background: var(--white);
  padding: 0.625rem;
  border-radius: 0.75rem;
  @media (max-width: 600px) {
    gap: 1rem;
  }
`;

const PickerTab = styled.div`
  font-family: var(--headerFontFamily);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 48px;
  border-radius: 8px;
  background: ${(props) => (props.active ? "var(--blue-900)" : "transparent")};
  color: ${(props) => (props.active ? "var(--white)" : "var(--blue-900)")};
  cursor: pointer;
  font-weight: 600;
  transition: all 250ms;
  :hover {
    background: ${(props) =>
      props.active ? "var(--blue-500)" : "var(--blue-900)"};
    color: var(--white);
  }
  @media (max-width: 600px) {
    font-size: 16px;
    :hover {
      background: var(--blue-900);
    }
  }
`;

const LicensePicker = ({ tabTitles, activeTab, setActiveTab }) => {
  return (
    <PickerContainer>
      <Picker>
        {tabTitles.map((tab) => (
          <PickerTab
            onClick={() => setActiveTab(tab)}
            active={tab === activeTab}
          >
            {tab}
          </PickerTab>
        ))}
      </Picker>
    </PickerContainer>
  );
};

export default LicensePicker;
