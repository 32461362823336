import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import IconWarning from "components/common/icon-components/IconWarning";
import RhombusButton from "components/common/RhombusButton";
import {
  CTA_BUTTON_COPY,
  CTA_LINK_2,
  CTA_LINK_3,
} from "components/data/landing-page-data";
import {
  FlexCenter,
  FlexRow,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  Body,
  HeroTitle as HeroTitleStyle,
  PreTitle,
} from "components/rui/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  @media (max-width: 600px) {
    padding: 2.5rem 1.25rem;
  }
`;

const HeroInner = styled(SectionInner)`
  gap: 5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const HeroSection = styled(SectionCol)`
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

const HeroTitle = styled(HeroTitleStyle)`
  font-size: 64px;
  @media (max-width: 1240px) {
    font-size: 56px;
  }
  @media (max-width: 600px) {
    font-size: 48px;
    font-weight: 800;
  }
`;

const HeroP = styled(Body)`
  font-size: 24px;
  @media (max-width: 1240px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const ButtonContainer = styled(FlexRow)`
  gap: 1.25rem;
  margin-top: 0.75rem;
  a {
    border-radius: 4px;
    width: calc(50% - 1.25rem);
  }
  @media (max-width: 1240px) {
    flex-direction: column;
    a {
      width: 100%;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 660px;
`;

const Alert = styled.div`
  max-width: 370px;
  z-index: 5;
  position: absolute;
  top: 3rem;
  left: -3rem;
  display: inline-flex;
  padding: 1.25rem 1.75rem;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid var(--nuetral-200);
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(3px);
  p {
    font-size: 22px;
    line-height: 1.2;
  }
  @media (max-width: 820px) {
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
    top: unset;
    bottom: -1.5rem;
    p {
      font-size: 18px;
      line-height: 1.2;
    }
  }
`;

const logoImageClass = css`
  width: 568px;
  @media (max-width: 1024px) {
    max-width: 568px;
    width: 100%;
  }
`;

const heroImageClass = css`
  @media (max-width: 820px) {
    max-height: 300px;
    border-radius: 1rem;
  }
`;

const HeroF = () => {
  return (
    <HeroContainer>
      <HeroInner>
        <HeroSection>
          <TextContainer width="660px">
            <HeroTitle color="var(--primary-bg)">
              Surveillance Optimized for Your Security Needs
            </HeroTitle>
            <HeroP>
              The all-in-one cloud surveillance and security platform trusted by
              Leaders and IT Teams.
            </HeroP>
            <ButtonContainer>
              <RhombusButton
                title={CTA_BUTTON_COPY}
                path={CTA_LINK_3}
                type="primary-dark"
              />
              <RhombusButton
                title={"Watch Rhombus Overview"}
                path={CTA_LINK_2}
                type="secondary"
              />
            </ButtonContainer>
          </TextContainer>
          <TextContainer style={{ gap: "1.25rem" }} width="660px">
            <PreTitle color="var(--primary-bg)">
              Trusted by Industry Leaders
            </PreTitle>
            <StaticImage
              src="./assets/hero-logos.png"
              quality={100}
              loading="eager"
              objectFit="contain"
              objectPosition={"left"}
              className={logoImageClass}
            />
          </TextContainer>
        </HeroSection>
        <HeroSection>
          <ImageContainer>
            <Alert>
              <IconWarning />
              <p>Unusual Behavior Detected at Parking Lot.</p>
            </Alert>
            <StaticImage
              src="./assets/surv-f-hero-min.png"
              quality={100}
              loading="eager"
              className={heroImageClass}
            />
          </ImageContainer>
        </HeroSection>
      </HeroInner>
    </HeroContainer>
  );
};

export default HeroF;
