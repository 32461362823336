import IndustryPageUpdated from "components/industries/updated/IndustryPageUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function HealthcareIndustry() {
  const renderContent = (data) => {
    const pageData = {
      industry: "Manufacturing",
      header: {
        headerTitle:
          "Monitor Worker Safety, Quality Control, and Productivity at Scale",
        headerParagraph:
          "Get facility-wide visibility on one easy-to-use platform. Streamline security operations, enhance quality control, and improve worker safety with proactive insight into unusual activity, environmental conditions, and more.",
        headerImg: data.headerImage,
        mobileHeaderImg: data.headerImageMobile,
      },
      industryTitle: "Trusted by Manufacturing Leaders",
      industryLogos: [
        {
          img: data.logo1,
          width: "122px",
          alt: "Design Ready Controls",
        },
        {
          img: data.logo2,
          width: "138px",
          alt: "stryker",
        },
        {
          img: data.logo3,
          width: "91px",
          alt: "UTZ",
        },
        {
          img: data.logo4,
          width: "75px",
          alt: "siete",
        },
        {
          img: data.logo5,
          width: "120px",
          alt: "Metalsa",
        },
        {
          img: data.logo6,
          width: "128px",
          alt: "accupac",
        },
      ],

      learnRows: [
        {
          image: data.learnImage1,
          title: "Oversee the Production Floor from Anywhere",
          description:
            "Secure your facilities 24/7/365 with cloud-based physical security. Leverage native remote access and real-time AI alerts to proactively detect safety issues and monitor quality control, compliance, and productivity at scale.",
          linkText: "Learn About Cloud-Based Cameras",
          linkUrl: "/cameras",
        },
        {
          image: data.learnImage2,
          title:
            "Protect Facilities from Security Threats and Environmental Hazards",
          description:
            "Safeguard staff, equipment, and assets from security threats and hazardous conditions. Receive instant alerts with synchronized footage for unusual activity, temperature fluctuations, or when smoke, vape, or toxic air quality is detected.",
          linkText: "Learn About Iot Sensors",
          linkUrl: "/sensors",
        },
        {
          image: data.learnImage3,
          title:
            "Accelerate Incident Review with Fast, Efficient Investigations",
          description:
            "Quickly diagnose issues with multi-camera smart search. Gather video and sensor evidence across all locations to perform a root cause analysis, evaluate liability, and restore operations quickly and confidently.",
          linkText: "Learn About AI Analytics",
          linkUrl: "/ai-analytics",
        },
        {
          image: data.learnImage4,
          title: "Quickly Communicate with the Plant Floor",
          description:
            "Pair audio with cameras to get the full picture and easily communicate at scale with the factory floor. Broadcast messages, alerts, and alarms, and remotely communicate directly with those on the ground through two-way audio.",
          linkText: "Learn About Audio Gateways",
          linkUrl: "/sensors/a100/",
        },
        {
          image: data.learnImage5,
          title: "Stay in Control of Access Points",
          description:
            "Prevent potential security breaches and accidents by regulating entry points with unified access control. Ensure only authorized personnel can access restricted areas and centrally manage unlimited buildings and credentials from anywhere.",
          linkText: "Learn About Access Control",
          linkUrl: "/access-control",
        },
      ],
      industryUseCases: [
        {
          title: "Rhombus in Automotive",
          tabTitle: "Automotive ",
          description:
            "Get real-time visibility of the production floor and proactively detect potential worker safety issues with 24/7/365 visibility.",
          image: data.useCase1,
          mobileImage: data.useCaseMobile1,
        },
        {
          title: "Rhombus in Food & Beverage",
          tabTitle: "Food & Beverage",
          description:
            "Streamline operations, reduce shrinkage, and monitor food safety at scale with environmental sensors paired with video.",
          image: data.useCase2,
          mobileImage: data.useCaseMobile2,
        },
        {
          title: "Rhombus in Medical & Pharmaceutical",
          tabTitle: "Medical & Pharmaceutical ",
          description:
            "Secure restricted areas, monitor compliance, and improve quality control with unified video security and environmental sensors.",
          image: data.useCase3,
          mobileImage: data.useCaseMobile3,
        },
        {
          title: "Rhombus in Metal, Wood, & Plastics",
          tabTitle: "Metal, Wood, & Plastics ",

          description:
            "Improve worker safety with proactive alerts for unusual activity, unsafe air quality, temperature fluctuations, and more.",
          image: data.useCase4,
          mobileImage: data.useCaseMobile4,
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "lightning bolt",
          title: "Streamline Operations",
          p: "Reduce operational inefficiency and deploy unlimited devices across locations without DVR/NVRs, unreliable hardware, or manual maintenance.",
        },
        {
          icon: data.icon2,
          iconAlt: "dollar",
          title: "Improve Visibility and Costs",
          p: "Eliminate blind spots and unlock new business insights with enterprise-grade security cameras and sensors backed by an industry-leading warranty.",
        },
        {
          icon: data.icon3,
          iconAlt: "alert",
          title: "Reduce Workplace Accidents",
          p: "Protect employees from injury and harm with proactive real-time video alerts for suspicious activity, unusual behavior, and more.",
        },
        {
          icon: data.icon4,
          iconAlt: "investigations",
          title: "Accelerate Incident Review",
          p: "Protect liability and accelerate investigations with AI-powered tools and seamless video sharing capabilities via URL, email, or SMS.",
        },
      ],
      form: {
        title: "Request Demo",
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        paragraph:
          "Contact our team today and see why Rhombus is the leading physical security solution for businesses of all sizes.",
        caseStudy: {
          title: "Rhombus is Trusted by Manufacturing Leaders",
          image: data.qouteImage,
          logo: {
            img: data.caseStudyLogo,
            width: "131px",
            alt: "fitas flax",
          },
          link: "/case-studies/fitas-flax",
          author: {
            avatar: data.avatar,
            name: "William Redig",
            role: "Head of IT at Fitas Flax",
            qoute:
              "“We wanted a solution that would set us to be successful in the future, and we’re glad we chose Rhombus.”",
          },
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Video Security System for Manufacturing | Cloud Physical Security |
            Rhombus
          </title>
          <meta
            name="description"
            content="Secure manufacturing facilities with Rhombus enterprise-grade security cameras. Improve video surveillance, safety, quality control, and operations with AI insights and real-time alerts. Start a free trial today!"
          />
        </Helmet>
        <IndustryPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/manufacturing-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/manufacturing-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: {
          eq: "components/industries/img/manufacturing/logo1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/manufacturing/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/manufacturing/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/manufacturing/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: {
          eq: "components/industries/img/manufacturing/logo5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/manufacturing/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/dollar.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/alert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: {
          eq: "components/industries/img/icons/investigations.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage1: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage2: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/smart-sensors-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage3: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/ai-analytics-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage4: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/audio-gateways-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage5: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase1: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/automotive-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase2: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/food-and-bev-manufacturing-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase3: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/medical-manufacturing-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase4: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/metal-wood-manufacturing-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile1: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/mobile-automotive-manufacturing-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile2: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/mobile-food-and-bev-manufacturing-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile3: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/mobile-medical-manufacturing-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile4: file(
        relativePath: {
          eq: "components/industries/updated/img/manufacturing/mobile-metal-wood-manufacturing-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      qouteImage: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/fitas-flax-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/william-redig.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      caseStudyLogo: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/fitas-flax-logo.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
