import { styled } from "@linaria/react";

export const AdvantageCard = styled.a`
  display: flex;
  min-width: 180px;
  width: 200px;
  height: 269px;
  padding: 32px 20px;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  background: var(--white);
  transition: all 250ms;
  text-decoration: none;
  user-select: none;
  h4 {
    font-size: 20px;
    max-width: 10ch;
  }
  :hover {
    border-color: var(--blue-500);
    background: var(--tertiary-bg);
    box-shadow: 0px 5px 10px var(--primary-bg-30);
  }
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    aspect-ratio: 1;
    background: var(--primary-bg);
    border-radius: 50%;
  }
  @media (max-width: 768px) {
    min-width: 312px;
    width: 100%;
    height: unset;
  }
`;
