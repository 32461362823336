import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css } from "@linaria/core";
import React, { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const sectionContainer = css`
  height: 775px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: var(--nuetral-900);
`;

const bgImage = css`
  position: absolute !important;
  left: 0;
  height: 100%;
  width: 100%;
  filter: brightness(0.8);
`;

const sectionInner = css`
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

const tabContainer = css`
  height: 20%;
  overflow: hidden;
  width: fit-content;
`;

const tabRow = css`
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
  gap: 4.8rem;
  color: var(--white);
`;

const lineContainer = css`
  width: 100%;
  display: block;
  margin-top: -4rem;
  padding-left: 2.4rem;
  background: transparent;
`;

const tab = css`
  font-weight: 800;
  cursor: pointer;
  opacity: 0.9;
  transition: all 250ms;
  :hover {
    opacity: 1;
  }
`;

const itemBody = css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-left: 40px;
`;

const textContainer = css`
  max-width: 30%;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  h2 {
    font-size: 32px;
    line-height: 44px;
  }
  @media (max-width: 1024px) {
    padding-left: 1.6rem;
    justify-content: flex-end;
    height: 50%;
    z-index: 10;
    max-width: 90%;
  }
`;

const mobileSliderWrapper = css`
  height: 730px;
  background-color: var(--nuetral-900);
`;

const swiperContainer = css`
  height: 100%;
  .swiper-button-next,
  .swiper-button-prev {
    bottom: 3.2rem;
    top: unset;
  }
  .swiper-button-next {
    right: 3.2rem;
  }
  .swiper-button-prev {
    left: 3.2rem;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1rem;
    color: var(--white);
  }
`;

const useCaseSlide = css`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const IndustryUseCases = ({ data }) => {
  const [selection, setSelection] = useState(data[0]);
  const [currentIndex, setCurrentIndex] = useState(data.indexOf(selection));
  const [width, setWidth] = useState(null);
  const { isMobile } = useIsMobile(1024);

  useEffect(() => {
    setCurrentIndex(data.indexOf(selection));
    getTabWidth();
  }, [selection]);

  const getTabWidth = async () => {
    const id = await selection.tabTitle;
    const tab = document.getElementById(id);
    setWidth(tab && tab.offsetWidth);
  };

  const calculateMargin = () => {
    const formula = 76.8 * currentIndex + getPrevElementsWidth();
    return formula + "px";
  };

  const getPrevElementsWidth = () => {
    let totalWidth = 0;
    if (currentIndex === 0) return 0;
    for (let i = 0; i < currentIndex; i++) {
      let el = document.getElementById(data[i].tabTitle);
      totalWidth += el && el.offsetWidth;
    }
    return totalWidth;
  };

  const desktopLayout = (
    <section className={sectionContainer}>
      <GatsbyImage
        image={getImage(selection.image)}
        alt={selection.title}
        className={bgImage}
      />
      <div className={sectionInner}>
        <div className={tabContainer}>
          <ul className={tabRow}>
            {data.map((item, index) => (
              <li
                key={item.tabTitle}
                id={item.tabTitle}
                onClick={() => setSelection(item)}
                className={tab}
              >
                {item.tabTitle}
              </li>
            ))}
          </ul>
          <div className={lineContainer}>
            <div
              style={{
                width: width,
                marginLeft: calculateMargin(),
                transition: "all 500ms",
                background: "var(--white)",
                height: "2px",
              }}
            ></div>
          </div>
        </div>
        <div className={itemBody}>
          <div className={textContainer}>
            <h2>{selection.title}</h2>
            <p>{selection.description}</p>
          </div>
        </div>
      </div>
    </section>
  );

  const mobileLayout = (
    <section className={mobileSliderWrapper}>
      <Swiper
        slidesPerView={1}
        effect="fade"
        className={swiperContainer}
        navigation
        modules={[Navigation, Pagination]}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className={useCaseSlide}>
            <GatsbyImage
              image={getImage(item.mobileImage)}
              alt={item.title}
              className={bgImage}
            />
            <div className={textContainer}>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
  if (isMobile) {
    return mobileLayout;
  } else return desktopLayout;
};

export default IndustryUseCases;
