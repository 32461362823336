import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function E50() {
  const data = useStaticQuery(graphql`
    query {
      e50Header: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/E50-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      e50HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e50-product-hero-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      e50dims: file(
        relativePath: { eq: "components/sensors/img/e50-dims.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);
  const sensor = {
    title: "E50",
    imageHeader: data.e50Header,
    imageHeaderMobile: data.e50HeaderMobile,
    image: data.e50dims,
    imageAlt:
      "Top view of the Rhombus E50 environmental sensor for comprehensive indoor air quality monitoring. Features multiple sensors for detecting particulate matter (PM1.0/2.5/4.0/10), temperature, humidity, VOC index, carbon monoxide, carbon dioxide, NOx index, formaldehyde, pressure, glass break, and loud noise. Compact design with low power consumption for continuous environmental monitoring of various air quality factors and ambient conditions.",
    objectPosition: "right",
    description:
      "Promote healthy spaces, protect assets and stay compliant with advanced environmental monitoring powered by AI and specialized sensors. Expand security insights with audio detections.",
    pdf: "/files/specs/E50.pdf",
    accessoryCompatible: true,
    column1: [
      {
        title: "Dimensions",
        text: "117mm (4.6in) X 59mm (2.3in)",
      },
      {
        title: "Power",
        text: [
          "Power over Ethernet (POE)",
          "Standard 802.3af for one-cable installation",
        ],
      },
      {
        title: "Humidity Measurement",
        text: [
          "±4.5 %RH @ 25 °C, 30-70% RH, typical",
          "±6.0 %RH @ 25 °C, 30-70% RH, max",
        ],
      },
      {
        title: "Temperature Measurement",
        text: [
          "±0.45 °C @ 15-30 °C, 50% RH, typical",
          "±0.70 °C @ 15-30 °C, 50% RH, max",
        ],
      },
      {
        title: "Mounting",
        text: "Screws and anchors included",
      },
      {
        title: "Outdoor Use",
        text: "For use indoors or outdoors if protected from moisture",
      },
    ],
    column2: [
      {
        title: "Detection",
        text: [
          "Vape & Smoke",
          "Temperature ",
          "Humidity",
          "Carbon Monoxide",
          "Carbon Dioxide",
          "Audio Detection: Glass break, loud noise, T3/T4 alarm",
          "Particulate matter (PM): PM1.0/2.5/4.0/10",
          "VOC index",
          "NOx index",
          "Formaldehyde",
          "Pressure",
        ],
      },
      {
        title: "Accessories",
        text: [
          "Supports external temp probe and leak probe via jack, both optional accessories",
        ],
      },
      {
        title: "Rhombus Sensor Network Compatible",
      },
      {
        title: "Rhombus 10-year Warranty included",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>Rhombus E50 Advanced Environmental Sensor</title>
        <meta
          name="description"
          content="Discover the Rhombus E50 environmental sensor, an all-in-one solution to get real-time environmental insights in your spaces. Monitor and track air quality, vape, temperature, humidity insights, and audio analytics."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-environmental-sensors-min.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-environmental-sensors-min.png"
        />
      </Helmet>
      <SensorSpecsPage
        sensor={sensor}
        heroImageAltText="Rhombus E50 environmental sensor mounted in a school restroom is shown detecting vape clouds. The sensor's multiple environmental monitoring capabilities, including humidity sensors and temperature sensors, are highlighted as it measures relative humidity, ambient temperature, and air quality. The device showcases its ability to accurately assess environmental factors and provide crucial data for maintaining environmental health in educational settings."
      />
    </GlobalLayout>
  );
}
