import StoryCards from "components/common/StoryCards";
import PageContent from "components/page/PageContent";
import { PreTitle, TitleMed } from "components/rhombus-UI/theme/typography";
import { css } from "@linaria/core";
import React from "react";

const sectionContainer = css`
  display: flex;
  justify-content: center;
  padding: 4rem 10px;
  background: var(--nuetral-100);
`;

const sectionInner = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const titleClass = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  h2 {
    max-width: 544px;
  }
`;
/**
 * @deprecated
 */

const CustomerStoryCarousel = ({ data, title }) => {
  return (
    <section className={sectionContainer}>
      <PageContent>
        <div className={sectionInner}>
          <div className={titleClass}>
            <PreTitle>CUSTOMER SUCCESS STORIES</PreTitle>
            <TitleMed color="var(--blue-500)">{title}</TitleMed>
          </div>
          <StoryCards data={data} />
        </div>
      </PageContent>
    </section>
  );
};

export default CustomerStoryCarousel;
