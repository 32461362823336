import AccessControlBanner from "components/common/footer-banners/AccessControlBanner";
import SensorSpecsHeader from "components/sensors/SensorSpecsHeader";
import React, { useRef } from "react";

import AccessControlSpecs from "./AccessControlSpecs";
import ExploreOtherAccessControl from "./ExploreOtherAccessControl";
import AccessoriesContainer from "components/accessories/v2/AccessoriesContainer";

const noACModels = ["DC20", "Secure Card", "Secure Fob"];

export default function AccessControlSpecsPage({ model }) {
  const formRef = useRef(null);
  const {
    title,
    imageHeader,
    imageHeaderMobile,
    description,
    objectPosition,
    exploreCards,
  } = model;

  const modelName = model?.model ?? title;

  return (
    <>
      <SensorSpecsHeader
        title={title}
        description={description}
        webHeader={imageHeader}
        mobileHeader={imageHeaderMobile}
        objectPosition={objectPosition}
        includePricingLink={false}
        formRef={formRef}
        accessControlCTA
        alt={model?.heroAlt ?? title}
      />
      <AccessControlSpecs model={model} />
      {!noACModels.includes(modelName) && (
        <AccessoriesContainer
          staticTitle={model?.accTitle ?? null}
          defaultSelection={modelName}
          singleDeviceMode
        />
      )}
      <ExploreOtherAccessControl
        title="Explore Other Access Control Components"
        subtitle="Build the perfect Access Control solution to suit your specific needs."
        cards={exploreCards}
      />
      <AccessControlBanner />
    </>
  );
}
