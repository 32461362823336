import React from "react";

const IconStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
    >
      <path
        d="M9.149 0.667031C9.49275 -0.149428 10.6635 -0.149427 11.0072 0.667033L13.036 5.48592C13.181 5.83012 13.5087 6.0653 13.8848 6.09509L19.1508 6.51217C20.043 6.58283 20.4048 7.68282 19.725 8.25809L15.7129 11.6534C15.4263 11.8959 15.3011 12.2765 15.3887 12.639L16.6145 17.7157C16.8221 18.5758 15.875 19.2557 15.1111 18.7948L10.6027 16.0742C10.2806 15.8799 9.87562 15.8799 9.5536 16.0742L5.04511 18.7948C4.28124 19.2557 3.33408 18.5758 3.54176 17.7157L4.76755 12.639C4.8551 12.2765 4.72995 11.8959 4.44338 11.6534L0.431187 8.25809C-0.248593 7.68282 0.113193 6.58283 1.00542 6.51217L6.27148 6.09509C6.64762 6.0653 6.97528 5.83012 7.12019 5.48592L9.149 0.667031Z"
        fill="#FF9518"
      />
    </svg>
  );
};

export default IconStar;
