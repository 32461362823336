import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BookedEventTag,
  EventTag,
  TrainingSessionCard,
} from "./styles/rctp-styles";
import {
  MAX_SUBMISSIONS,
  getEventSubmissionCount,
  renderDateRange,
} from "./util/rctp-functions";
import {
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import RhombusButton from "components/common/RhombusButton";
import { Link } from "gatsby";

const TrainingEvent = ({ eventData }) => {
  const [seatsLeft, setSeatsLeft] = useState(null);

  const trainingSession = eventData.node.frontmatter;
  const slug = eventData.node.fields.slug;
  const id = eventData.node.id;
  const dates = trainingSession.trainingEvents
    .map((item) => item.trainingDates.map((i) => [i.startDate, i.endDate]))
    .flat(Infinity);

  const formId = trainingSession.formId;

  useEffect(() => {
    (async () => {
      const submissionCount = await getEventSubmissionCount(formId);
      if (!submissionCount) return;
      const count = MAX_SUBMISSIONS * dates.length ?? 1 - submissionCount;
      setSeatsLeft(count);
    })();
  }, [dates]);

  const isBooked = useMemo(() => {
    return seatsLeft === 0;
  }, [seatsLeft]);

  const isLimited = useMemo(() => {
    return seatsLeft <= 10 && seatsLeft > 0;
  }, [seatsLeft]);

  const renderTag = () => {
    return isBooked ? (
      <BookedEventTag>
        <p>No Seats Left</p>
      </BookedEventTag>
    ) : isLimited ? (
      <EventTag>
        <p>Limited Seats</p>
      </EventTag>
    ) : (
      <></>
    );
  };

  return (
    <TrainingSessionCard
      as={Link}
      to={slug}
      key={id}
      booked={isBooked}
      className="card"
    >
      <img src={trainingSession.image} />
      {renderTag()}
      <div className="card-inner">
        <div>
          <div className="title-row">
            <TitleMed>{trainingSession.state}</TitleMed>
            <MainParagraph>{renderDateRange(dates)}</MainParagraph>
          </div>
          <MainParagraph style={{ marginTop: "2.5rem" }}>
            One-day RCTP training program dedicated to mastering the entire
            Rhombus product line.
          </MainParagraph>
        </div>
        <RhombusButton
          type="primary-light"
          title="Learn More"
          path={slug}
          disabled={isBooked}
        />
      </div>
    </TrainingSessionCard>
  );
};

export default TrainingEvent;
