import {
  DefaultCard,
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { styled } from "@linaria/react";

export const EventsPageContainer = styled(SectionContainer)`
  overflow: visible;
  @media (max-width: 600px) {
    padding-top: 2.5rem;
  }
`;

export const EvenstHeroContainer = styled(SectionContainer)`
  background: var(--gradient-light-fade);
`;

export const EventsHeroInner = styled(SectionInner)``;

export const HeroLeft = styled(SectionCol)`
  align-items: center;
`;

export const HeroRight = styled(SectionCol)`
  align-items: center;
  justify-content: flex-end;
`;

export const StyledEventCard = styled(DefaultCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 250ms ease-in-out;
  position: relative;
  color: var(--white);
  background-color: var(--blue-900);
  &::after {
    content: "";
    position: absolute;
    height: ${props => (props.booked ? "100%" : "0")};
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: hsl(from var(--nuetral-300) h s l / 50%);
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-position: bottom;
  }

  .card-inner {
    height: 50%;
    background: hsl(from var(--blue-900) h s l / 77%);
    z-index: 1;
    padding: 1.25rem;
    transition: all 250ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 4rem;
  }

  :hover .card-inner {
    height: 100%;
  }

  @media (max-width: 700px) {
    .card-inner {
      height: fit-content;
      justify-content: space-between;
    }
    :hover .card-inner {
      height: fit-content;
    }
  }
`;

export const EventTag = styled.div`
  position: absolute;
  top: 1.75rem;
  left: 0;
  padding: 0 1.25rem;
  background: var(--blue-500);
  z-index: 3;
  border-radius: 0 2rem 2rem 0;
  display: flex;
  align-items: center;
  height: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
`;

export const SpeakingSessionTag = styled(EventTag)`
  background: var(--blue-100);
  color: var(--blue-500);
  left: 0;
  padding-left: 8rem;
  z-index: 2;
  @media (max-width: 700px) {
    padding-left: 10rem;
  }
`;
