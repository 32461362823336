import TrialBanner from "components/common/footer-banners/TrialBanner";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import GlobalLayout from "components/page/GlobalLayout";
import RhombusBenefits from "components/webinars/templates/RhombusBenefits";
import RhombusBenefitsEducation from "components/webinars/templates/RhombusBenefitsEducation";
import WebinarAboutSection from "components/webinars/templates/WebinarAboutSection";
import WebinarHeader from "components/webinars/templates/WebinarHeader";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Converter } from "showdown";

const WebinarTemplate = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  useEffect(() => {
    if (frontmatter.draft && window) {
      window.location.href = "/404";
    }
  }, []);

  const converter = new Converter();
  const html = converter.makeHtml(frontmatter.aboutCopy);

  const renderBenefitsSection = (topics) => {
    if (topics.every((item) => item === "Education")) {
      return <RhombusBenefitsEducation />;
    } else return <RhombusBenefits />;
  };

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>{frontmatter.metaTitle}</title>
        <meta name="description" content={frontmatter.metaDescription} />
        {frontmatter.draft && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <WebinarHeader
        formId={frontmatter.formId}
        image={frontmatter.heroImage}
        title={frontmatter.title}
        type={frontmatter.type}
        date={frontmatter.startDate}
        centerImage={frontmatter.centerImage}
        url={frontmatter.url}
      />
      <WebinarAboutSection
        copy={html}
        learnLines={frontmatter.LearnItems}
        speakers={frontmatter.speakers}
        image={frontmatter.aboutImage}
      />
      {renderBenefitsSection(frontmatter.topics)}
      <IndustryLeadersCards />
      <TrialBanner />
    </GlobalLayout>
  );
};

export const PAGE_QUERY = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        url
        metaTitle
        metaDescription
        featured
        type
        topics
        title
        formId
        startDate
        cardImage
        heroImage
        centerImage
        aboutCopy
        aboutImage
        draft
        speakers {
          speakerCompany
          speakerImage
          speakerName
          speakerRole
        }
        LearnItems
      }
    }
  }
`;

export default WebinarTemplate;
