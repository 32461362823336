import Features from "components/common/Features";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import IndustryStripeSmall from "components/common/IndustryStripeSmall";
import InfoSlider from "components/common/InfoSlider";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import TryRhombusStripe2 from "components/common/TryRhombusStripe2";
import React from "react";

import VsComparison from "./VsComparison";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";

const VsPageUpdated = ({ data, g2Section = false }) => {
  return (
    <>
      <HeroLayout2
        data={data.header}
        theme="light"
        formTitle={CTA_BUTTON_COPY}
        buttonText={CTA_BUTTON_COPY}
      />
      <IndustryStripeSmall logos={data.header.logos} theme="dark" />
      {g2Section && <TrustedPartnerSection button={false} />}
      <InfoSlider
        data={data.benefits.list}
        title={data.benefits.title}
        color="var(--nuetral-100)"
      />
      <VsComparison data={data.compareTable} />
      <IndustryLeadersCards color="var(--nuetral-100)" />
      <Features
        data={data.features}
        title="Rhombus Platform Features"
        color="var(--white)"
      />
      <TryRhombusStripe2
        title="Get a Quote"
        buttonText="Get Quote"
        blurb="Request a quote from our team today!"
      />
    </>
  );
};

export default VsPageUpdated;
