import TextLinkV2 from "components/common/TextLinkV2";
import { SectionContainer } from "components/rhombus-UI/theme/containers";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import logo from "../../../images/rhombus-symbol-40x40.svg";
import useIsMobile from "hooks/useIsMobile";

const logoStyles = css`
  width: 233.387px;
  height: 60px;
  flex-shrink: 0;
`;

const FooterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #e5e6ea;
  padding: 1.25rem 0;
`;

const FooterRowInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width);
  @media (max-width: 1390px) {
    padding: 0 1.25rem;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

const SubFooter = styled.div`
  padding: 1.25rem 0;
  text-align: center;
`;

const MobileLogo = styled.img`
  width: 40px;
  aspect-ratio: 1;
`;

const LandingPageFooter = () => {
  const { isMobile } = useIsMobile(600);
  return (
    <SectionContainer style={{ padding: 0, flexDirection: "column" }}>
      <FooterRow>
        <FooterRowInner>
          {isMobile ? (
            <MobileLogo src={logo} alt="Rhombus logo" />
          ) : (
            <StaticImage
              src="../../../images/rhombus-logo-color.svg"
              className={logoStyles}
              alt="Rhombus logo"
              placeholder="none"
              quality={100}
              objectFit="contain"
              loading="eager"
            />
          )}
          <Links>
            <TextLinkV2
              title="Privacy Policy"
              path="https://legal.rhombus.com/privacy/privacy-policy"
              target="_blank"
            />
            <TextLinkV2
              title="Terms of Service"
              path="https://legal.rhombus.com/end-user-tos"
              target="_blank"
            />
          </Links>
        </FooterRowInner>
      </FooterRow>
      <SubFooter>
        <span style={{ fontSize: "12px" }}>
          © Rhombus, Inc. All Rights Reserved
        </span>
      </SubFooter>
    </SectionContainer>
  );
};

export default LandingPageFooter;
