import IconAlert from "components/common/icon-components/IconAlert";
import IconBoundingBox from "components/common/icon-components/IconBoundingBox";
import IconDeploy from "components/common/icon-components/IconDeploy";
import IconLockWithArrows from "components/common/icon-components/IconLockWithArrows";
import IconSystemProtection from "components/common/icon-components/IconSystemProtection";
import RhombusBlade from "components/common/RhombusBlade";
import {
  CTA_BUTTON_COPY_4,
  CTA_LINK_5,
} from "components/data/landing-page-data";
import BenefitsSection from "components/landing-pages/consultant-lp/BenefitsSection";
import CameraInfoSlider from "components/landing-pages/consultant-lp/CameraInfoSlider";
import CaseStudySection from "components/landing-pages/consultant-lp/CaseStudySection";
import FormAndTestimonialSection from "components/landing-pages/consultant-lp/FormAndTestimonialSection";
import Hero from "components/landing-pages/consultant-lp/Hero";
import LandingPageFooter from "components/landing-pages/consultant-lp/LandingPageFooter";
import LogoBlade from "components/landing-pages/consultant-lp/LogoBlade";
import SolutionsSection from "components/landing-pages/consultant-lp/SolutionsSection";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function SurveillanceD() {
  const images = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: { eq: "components/landing-pages/img/surv-e-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      section1: file(
        relativePath: {
          eq: "components/landing-pages/img/surv-e-section-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      section2: file(
        relativePath: {
          eq: "components/landing-pages/img/surv-e-section-2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      section3: file(
        relativePath: {
          eq: "components/landing-pages/img/surv-e-section-3.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      footer: file(
        relativePath: {
          eq: "components/cameras/img/rhombus-cameras-podium-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return (
    <GlobalLayout
      color="var(--blue-100)"
      landingPage
      ctaLink={CTA_LINK_5}
      buttonText={CTA_BUTTON_COPY_4}
      includeButton
      useFullWidthLogo
      hideFooter
    >
      <Helmet>
        <title>Security + Surveillance Unified | Rhombus™</title>
        <meta
          name="description"
          content="Stay in the know from anywhere at anytime. Automated alerts and surveillance monitoring ensures your spaces, assets, and people are protected at all times."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Hero
        center
        title="Streamline surveillance with scalable, cloud-driven security"
        blurb="Redefine your surveillance with a flexible, cloud-based platform that gives you the power to build and scale personalized physical security solutions that grow with your organization and its evolving security needs."
        alt="Redefine your surveillance with a flexible, cloud-based platform that gives you the power to build and scale personalized physical security solutions that grow with your organization and its evolving security needs."
        image={images.hero}
        ctaCopy={CTA_BUTTON_COPY_4}
        ctaLink={CTA_LINK_5}
        bulletPoints={[
          "Integrate into existing systems with our fully open API and integrations",
          "Seamlessly scale your coverage across multiple locations and devices ",
          "Migrate quickly with our local, US-based installers and 24/7 support",
        ]}
      />
      <LogoBlade />
      <SolutionsSection />
      <BenefitsSection
        image={images.section2}
        altText=""
        benefits={[
          {
            Icon: IconLockWithArrows,
            title: "Bypass traditional NVRs and connect directly to the cloud",
            description:
              "Our cameras operate independently, ensuring continuous recording and seamless scalability as your needs grow.",
          },
          {
            Icon: IconBoundingBox,
            title: "Break free from closed security ecosystems",
            description:
              "Integrate your security data with existing systems and tools, and create custom solutions within our open API platform.",
          },
          {
            Icon: IconAlert,
            title: "Minimize maintenance with self-updating cameras",
            description:
              "Automatically update with the latest features, optimizations, and  protocols, ensuring you have the most advanced protection.",
          },
        ]}
      />
      <FormAndTestimonialSection />
      <CameraInfoSlider />
      <BenefitsSection
        color="var(--nuetral-100)"
        image={images.section3}
        altText=""
        benefits={[
          {
            Icon: IconDeploy,
            title: "Deploy surveillance in minutes, not days",
            description:
              "Secure your site rapidly with just a camera, power, and network connection. Eliminate bulky NVRs and start monitoring in minutes.",
          },
          {
            Icon: IconBoundingBox,
            title: "Break free from closed security ecosystems",
            description:
              "Get crystal-clear footage with 10x lower latency and 50% less bandwidth usage than other cloud solutions, ensuring smooth, instantaneous access to your security feeds.",
          },
          {
            Icon: IconSystemProtection,
            title: "Real-time visibility without straining your network",
            description:
              "Customize features, integrate with existing systems, and scale protection as your organization evolves.",
          },
        ]}
      />
      <CaseStudySection color="var(--white)" />
      <RhombusBlade
        title="Try Rhombus cameras with our risk-free trial"
        blurb="Take the next step and start your risk-free trial with Rhombus’ cameras. Install and start viewing footage from any device in seconds."
        button={{
          path: "/lp/cta-free-trial/",
          text: "Start your free trial",
        }}
        fancy
        fancyButton
        image={images.footer}
      />
      <LandingPageFooter />
    </GlobalLayout>
  );
}
