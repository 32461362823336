import React from "react";
import { Link } from "gatsby";
import { ButtonLink } from "components/rui/buttons";
import IconArrow from "./icon-components/IconArrow";
import { styled } from "@linaria/react";

const StyledLink = styled(ButtonLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 2.5rem;
  svg {
    transform: rotate(180deg);
  }
`;

const BackLink = ({ path, text, light = false }) => {
  return (
    <StyledLink as={Link} to={path} light={light}>
      <IconArrow />
      {text}
    </StyledLink>
  );
};

export default BackLink;
