import DemoFormSection from "components/common/DemoFormSection";
import DeviceBenefits from "components/common/DeviceBenefits";
import TrialBanner2 from "components/common/footer-banners/TrialBanner2";
import G2BadgeSlider from "components/common/G2BadgeSlider";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import Highlights from "components/common/Highlights";
import IntegrationSlider from "components/common/IntegrationSlider";
import ScalabilityBlade from "components/common/ScalabilityBlade";
import TestimonySlider from "components/common/TestimonySlider";
import TitleBlade from "components/common/TitleBlade";
import ComplianceSection from "components/compliance/ComplianceSection";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function WhyRhombusPage() {
  const renderContent = (data) => {
    const pageData = {
      headerSection: {
        preTitle: "Why Rhombus?",
        title: "A Safer Future Starts with a Team You Can Trust",
        description:
          "When it comes to protecting your organization, partner with a team you trust. At Rhombus, we put your needs first and are constantly innovating to ensure your organization is secure today and in the future.",
        image: data.headerImage,
        button1: { text: "Request Demo", path: null },
        button2: { text: "View Pricing", path: "/pricing/" },
      },
      benefitsSection: {
        title: "Partner With a Team that Cares",
        items: [
          {
            image: data.benefit1,
            title: "Custom, curated solutions from experts",
            description:
              "Receive security consultations and recommendations that truly fit your needs; receive better solutions from a team that listens to your use cases and understands your pain points.",
          },
          {
            image: data.benefit2,
            title: "Phenomenal support in a long-term partnership ",
            description:
              "Experience best-in-class partnership through your entire lifetime with us. From purchase to deployment and beyond, we’re dedicated to your success and with you at every step.",
          },
          {
            image: data.benefit3,
            title: "Futureproof platform that grows with your needs",
            description:
              "Gain the flexibility to build a solution that perfectly fits your needs. Freely scale, integrate, customize, and enhance your system at your own pace, on one unified platform.",
          },
        ],
      },
      highlights: {
        title: "Protect What Matters with Confidence",
        image: data.highlightImage,
        list: [
          {
            title: "Peace of Mind",
            description:
              "Experience stress-free physical security with an industry-leading 10-year warranty, automatic updates, and vigilant health monitoring.",
          },
          {
            title: "A Perfect Track Record",
            description:
              "Receive immediate notice of potential threats and take swift and informed action. Share video and coordinate with first responders.",
          },
          {
            title: "World-Class Support & Care",
            description:
              "Get phenomenal support from a team committed to your success with 24/7 US-based support and a dedicated account manager.",
          },
        ],
      },
      customerReviews: [
        {
          image: data.review1,
          author: {
            avatar: data.avatar1,
            name: "Daniel Hernandez",
            role: "Information Technology Manager of Bluestone Lane",
          },
        },

        {
          image: data.review2,
          author: {
            avatar: data.avatar2,
            name: "Gavin Thirlwall",
            role: "Technical Lead - Internet and Security Engineer of Juniper Networks",
          },
        },
        {
          image: data.review3,
          author: {
            avatar: data.avatar3,
            name: "Erik Tank",
            role: "Systems and Security Architect of East Moline School District 37",
          },
        },
        {
          image: data.review4,
          author: {
            avatar: data.avatar4,
            name: "Edward Ochoa",
            role: "IT Admin of Ochoa IT Consulting",
          },
        },
        {
          image: data.review5,
          author: {
            avatar: data.avatar5,
            name: "Brian Houy",
            role: "Senior IT Executive of SecureWorld Expo",
          },
        },
      ],
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus can transform your physical security.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };
    return (
      <>
        <HeroLayout1 data={pageData.headerSection} />
        <TitleBlade
          title="We set out to disrupt the outdated, legacy physical security systems"
          image={data.bladeImage}
        />
        <DeviceBenefits data={pageData.benefitsSection} />
        <Highlights data={pageData.highlights} center />
        <ScalabilityBlade />
        <SectionContainer style={{ paddingBottom: "0" }}>
          <SectionInner
            style={{ flexDirection: "column", textAlign: "center" }}
          >
            <FancyTitleMed>Rated #1 in Physical Security on G2</FancyTitleMed>
            <MainParagraph>
              Cloud-based physical security is the future of the industry.
            </MainParagraph>
            <G2BadgeSlider />
          </SectionInner>
        </SectionContainer>
        <TestimonySlider
          data={pageData.customerReviews}
          title={null}
          subTitle={null}
          color="var(--white)"
          button1={{
            text: "Read G2 Reviews",
            path: "https://www.g2.com/products/rhombus-systems/reviews",
          }}
          button2={{ text: "View Customer Stories", path: "/case-studies/" }}
        />
        <ComplianceSection
          preTitle=""
          title="Your Data is Secure with Rhombus"
        />
        <IntegrationSlider />
        <DemoFormSection data={pageData.formSection} />
        <TrialBanner2 />
      </>
    );
  };
  const GET_IMAGES = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/why-rhombus/img/why-rhombus-hero-round.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      bladeImage: file(
        relativePath: { eq: "components/common/img/r100-blade-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: { eq: "components/why-rhombus/img/solutions-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: { eq: "components/why-rhombus/img/partnership-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: { eq: "components/why-rhombus/img/futureproof-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      highlightImage: file(
        relativePath: {
          eq: "components/why-rhombus/img/mixed-products-console-short-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      avatar1: file(relativePath: { eq: "components/ITPage/img/daniel.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar2: file(relativePath: { eq: "components/ITPage/img/gavin.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar3: file(relativePath: { eq: "components/ITPage/img/erik.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar4: file(relativePath: { eq: "components/ITPage/img/edward.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar5: file(relativePath: { eq: "components/ITPage/img/brian.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      review1: file(relativePath: { eq: "components/common/img/review1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review2: file(relativePath: { eq: "components/common/img/review2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review3: file(relativePath: { eq: "components/common/img/review3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review4: file(relativePath: { eq: "components/common/img/review4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review5: file(relativePath: { eq: "components/common/img/review5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review6: file(relativePath: { eq: "components/common/img/review6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
