import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { styled } from "@linaria/react";
import React from "react";
import SlideInContainer from "./SlideInContainer";
import { Body, PreTitle, SectionTitle } from "components/rui/typography";

const SectionWithBorder = styled(SectionContainer)`
  position: relative;
  scroll-margin-top: 90px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  > div div {
    width: 100%;
  }
`;

const SectionIntro = ({
  preTitle,
  title,
  blurb,
  elementId,
  color = "var(--white)",
}) => {
  return (
    <SectionWithBorder id={elementId} color={color}>
      <SectionInner style={{ justifyContent: "center" }}>
        <SlideInContainer>
          <TextContainer style={{ textAlign: "center", alignItems: "center" }}>
            <PreTitle>{preTitle}</PreTitle>
            <SectionTitle style={{ maxWidth: "887px" }}>{title}</SectionTitle>
            <Body style={{ maxWidth: "745px" }}>{blurb}</Body>
          </TextContainer>
        </SlideInContainer>
      </SectionInner>
    </SectionWithBorder>
  );
};

export default SectionIntro;
