import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import Checklist from "components/rhombus-UI/Checklist";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const imageStyles = css`
  width: 100%;
  height: 100%;
  max-width: 600px;
`;

const Container = styled(SectionContainer)`
  background: ${(props) =>
    props.secondary ? "var(--nuetral-100)" : "var(--white)"};
`;

const ContainerInner = styled(SectionInner)`
  flex-direction: ${(props) => (props.secondary ? "row-reverse" : "row")};
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;

const Col = styled(SectionCol)`
  @media (max-width: 820px) {
    justify-content: center;
  }
`;

const Text = styled(TextContainer)`
  gap: 24px;
  height: 100%;
  justify-content: center;
`;

const Title = styled(FancyTitle)`
  font-size: 36px;
`;

const HighlightSection = ({
  image,
  title,
  blurb,
  listItems,
  secondary = false,
}) => {
  return (
    <Container secondary={secondary}>
      <ContainerInner secondary={secondary}>
        <Col>
          <GatsbyImage
            image={getImage(image)}
            className={imageStyles}
            objectFit="contain"
          />
        </Col>
        <Col>
          <Text width="546px">
            <Title>{title}</Title>
            <MainParagraph>{blurb}</MainParagraph>
            <Checklist listItems={listItems} />
          </Text>
        </Col>
      </ContainerInner>
    </Container>
  );
};

export default HighlightSection;
