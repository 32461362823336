import {
  FlexRow,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import React from "react";
import image1 from "./assets/video.png";
import image2 from "./assets/access-control.png";
import image3 from "./assets/integrations.png";
import image4 from "./assets/remote-management.png";
import { styled } from "@linaria/react";
import {
  MainParagraph,
  SectionTitle,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";

const CARDS = [
  {
    image: image1,
    title: "Intelligent Video Surveillance",
    blurb:
      "High-quality video with automatic incident detection and automated alerts.",
  },
  {
    image: image2,
    title: "Access Control",
    blurb: "Real-time permissions updates and role-based access control.",
  },
  {
    image: image3,
    title: "Integration & API",
    blurb:
      "Plug-and-play integrations and an open API. Works with your existing tools & systems.",
  },
  {
    image: image4,
    title: "Remote Management",
    blurb: "Deploy and manage unlimited locations remotely.",
  },
];

const CardContainer = styled(FlexRow)`
  gap: 5rem;
  justify-content: center;
  flex-wrap: wrap;
`;

const Card = styled(FlexRow)`
  max-width: 648px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 1rem;
  background-color: var(--nuetral-100);
  img {
    max-width: 324px;
    height: 324px;
    object-fit: cover;
  }
  .text {
    flex-grow: 1;
    padding: 28px;
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 18px;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    img {
      width: 100%;
      max-width: unset;
    }
    .text {
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
`;

const AllInOneF = () => {
  return (
    <SectionContainer>
      <SectionInner className="flex-col" style={{ gap: "5rem" }}>
        <SectionTitle>All-In-One</SectionTitle>
        <CardContainer>
          {CARDS.map((card) => (
            <Card>
              <img src={card.image} />
              <TextContainer className="text">
                <TitleSmall>{card.title}</TitleSmall>
                <MainParagraph>{card.blurb}</MainParagraph>
              </TextContainer>
            </Card>
          ))}
        </CardContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default AllInOneF;
