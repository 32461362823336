import CTAPage2 from "components/landing-pages/CTA/CTAPage2";
import React from "react";
import image from "components/landing-pages/CTA/assets/r600/r600-bg-image.png";
import useIsMobile from "hooks/useIsMobile";

export default function CTAPage5() {
  const { isMobile: isLaptop } = useIsMobile(1225);
  const { isMobile: isTablet } = useIsMobile(1024);
  return (
    <CTAPage2
      bgImage={image}
      bgImageMobile={image}
      title={"See the R600 in Action"}
      lastStepTitle={"See the R600 in Action"}
      subTitle={
        "Take the next step— Secure your space with Rhombus' R600. Install and start viewing footage from any device in seconds"
      }
      formId={"3b3e5ed2-8bae-4d5c-bd2e-932dc2ca23b1"}
      mobileFormPadding="17.5rem"
      bgImageRightPos="-15rem"
      bgImageBottomPos={isLaptop ? "150px" : isTablet ? "200px" : "50px"}
      backLink="/lp/multisensor-camera/"
      useCenterMobileImage
    />
  );
}
