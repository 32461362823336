import { styled } from "@linaria/react";
import RhombusButton from "components/common/RhombusButton";
import Link from "components/page/headers/HeaderLink";
import { AdvantageCard } from "components/rui/cards";
import IconAiInsights from "components/rui/icons/IconAiInsights";
import IconApi from "components/rui/icons/IconApi";
import IconCyberSecurity from "components/rui/icons/IconCyberSecurity";
import IconInteroperable from "components/rui/icons/IconInteroperable";
import IconStack from "components/rui/icons/IconStack";
import IconSystem from "components/rui/icons/IconSystem";
import { FlexColumn, FlexRow } from "components/rui/layout";
import SliderNavDots from "components/rui/slider-components/SliderNavDots";
import { Body, TitleSmall } from "components/rui/typography";
import useIsMobile from "hooks/useIsMobile";
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

export const PROTECT_CARDS = [
  {
    icon: () => <IconSystem />,
    title: "Cloud-edge system",
    text: "Scale infinitely, operate offline, minimize latency",
    link: "/console/",
  },
  {
    icon: () => <IconAiInsights />,
    title: "Actionable AI insights",
    text: "Respond faster with real-time detections",
    link: "/ai-analytics/",
  },
  {
    icon: () => <IconCyberSecurity />,
    title: "Rooted in cybersecurity",
    text: "Protect from breaches, ensure compliance",
    link: "/trust/",
  },
];

export const ADAPT_CARDS = [
  {
    icon: () => <IconInteroperable />,

    title: "Fully interoperable",
    text: "Maximize existing investments, minimize silos",
    link: "/cameras/relay-connector/n100/",
  },
  {
    icon: () => <IconStack />,
    title: "50+ scalable integrations",
    text: "Automate workflows, reduce IT burden",
    link: "/integrations/",
  },
  {
    icon: () => <IconApi />,
    title: "100% open API",
    text: "Innovate with full flexibility and control",
    link: "https://developer.rhombus.com/",
  },
];

const CardContainer = styled(FlexRow)`
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const AdvantageCards = ({ cards }) => {
  const { isMobile } = useIsMobile(767);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
  };

  const scrollToIndex = (index) => {
    if (containerRef.current) {
      const cardWidth = 312 + 16;
      containerRef.current.scrollTo({
        left: index * cardWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToIndex(currentIndex);
  }, [currentIndex, scrollToIndex]);

  return (
    <FlexColumn gap="16px" {...handlers}>
      <CardContainer gap="16px" ref={containerRef}>
        {cards.map((card, index) => (
          <AdvantageCard key={index} as={Link} to={card.link}>
            <div className="icon-container">{card.icon?.()}</div>
            <TitleSmall>{card.title}</TitleSmall>
            <Body>{card.text}</Body>
            {isMobile && (
              <RhombusButton
                title={"Learn more"}
                path={card.link}
                type="secondary"
              />
            )}
          </AdvantageCard>
        ))}
      </CardContainer>
      {isMobile && (
        <SliderNavDots
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          slides={cards}
        />
      )}
    </FlexColumn>
  );
};

export default AdvantageCards;
