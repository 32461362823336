import React, { useMemo } from "react";
import DropDownSelector from "components/common/DropDownSelector";
import { Column } from "./styles";
import { styled } from "@linaria/react";

const DeviceRender = styled.img`
  width: 100%;
  max-width: 167px;
  height: 167px;
  flex-shrink: 0;
  object-fit: contain;
  @media (max-width: 1024px) {
    width: 60%;
    height: calc(167px * 0.6);
  }
`;

const SelectorCol = ({
  data,
  selectedDevice,
  setSelectedDevice,
  stickyActive,
  allSelectedDevices,
}) => {
  const options = data.map((option) => {
    return {
      image: option.renderSmall,
      value: option.modelName,
      cat: option.category,
    };
  });

  const deviceRender = useMemo(() => {
    const match = data.filter((item) => item.modelName === selectedDevice);
    return match[0]?.renderLarge;
  }, [selectedDevice]);

  // const devicePrice = useMemo(() => {
  //   const match = data.filter((item) => item.modelName === selectedDevice);
  //   return match[0]?.basePrice;
  // }, [selectedDevice]);

  return (
    <Column>
      <DropDownSelector
        options={[...options]}
        defaultValue="Select Model"
        value={selectedDevice}
        onSelect={(option) => setSelectedDevice(option)}
      />
      {!stickyActive && (
        <>
          {!!deviceRender && <DeviceRender src={deviceRender} />}
          {/* {!!devicePrice && <TitleSmall>From ${devicePrice}</TitleSmall>} */}
        </>
      )}
    </Column>
  );
};

export default SelectorCol;
