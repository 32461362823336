import React from "react";
import { css } from "@linaria/core";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const slideContainer = css`
  width: 329px;
  height: 337px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 62px 21px 34px;
  line-height: 2;
  margin-bottom: 39px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: grab;

  @media (max-width: 1450px) {
    width: 275px;
    height: 307px;
    padding: 32px 21px 34px;
  }

  @media (max-width: 1200px) {
    width: 260px;
    height: 307px;
    padding: 22px 11px 24px;
  }

  @media (max-width: 1050px) {
    width: 275px;
    height: 307px;
    padding: 32px 21px 34px;
  }

  @media (max-width: 850px) {
    width: 329px;
    height: 337px;
    padding: 62px 21px 34px;
  }

  @media (max-width: 625px) {
    width: 275px;
    height: 307px;
    padding: 32px 21px 34px;
    margin: 0 auto 39px auto;
  }

  @media (max-width: 425px) {
    width: 250px;
    height: 282px;
    padding: 32px 21px 34px;
    margin: 0 auto 39px auto;
  }

  q {
    display: block;
    font-size: 14px;

    @media (max-width: 700px) {
      font-size: 12px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 47px;

    @media (max-width: 1450px) {
      margin-bottom: 17px;
    }
    @media (max-width: 700px) {
      font-size: 12px;
    }
  }

  cite {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;

    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

const logoClass = css`
  height: 44px;
  min-height: 44px;
  max-width: 71px;
  width: fit-content;
  display: flex !important; //must be used to override gatsby image styling
`;

/**
 * @deprecated
 */

export default function CustomerStorySlide({ slide }) {
  const imageRef = getImage(slide.image);

  return (
    <div className={slideContainer}>
      <q>{slide.quote}</q>
      <div>
        <cite>{slide.cite}</cite>
        <p>{slide.company}</p>
        <GatsbyImage
          image={imageRef}
          alt={`${slide.company} logo`}
          className={logoClass}
          objectFit="contain"
        />
      </div>
    </div>
  );
}
