import React from "react";
import parse from "html-react-parser";
import PostCTA from "components/common/CTA/PostCTA";

const getLastH2 = (elements) => {
  if (!elements || elements.length === 0) return null;

  const traverse = (nodes) => {
    if (!Array.isArray(nodes)) nodes = [nodes];
    let lastH2 = null;

    nodes.forEach((node) => {
      if (node && node.type === "h2") {
        lastH2 = node;
      } else if (node && node.props && node.props.children) {
        const childLastH2 = traverse(node.props.children);
        if (childLastH2) lastH2 = childLastH2;
      }
    });

    return lastH2;
  };

  return traverse(elements);
};

const Content = ({ content, className, useCTA = false }) => {
  const parsedHtml = parse(content);
  const lastH2 = getLastH2(parsedHtml);

  const transform = (node) => {
    if (node.type === "tag" && node.name === "h2") {
      const text = node.children?.[0]?.data;
      if (text === lastH2?.props?.children && useCTA) {
        return (
          <>
            <PostCTA />
            <h2>{text}</h2>
          </>
        );
      }
    }
  };

  const transformedContent = parse(content, { replace: transform });

  return <div className={className}>{transformedContent}</div>;
};

export default Content;
