import React from "react";

const IconBadgeReader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="22"
      viewBox="0 0 10 22"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="8.28729"
        height="20"
        rx="4.14365"
        stroke="#2A7DE1"
        stroke-width="1.10497"
      />
      <path
        d="M6.03525 18.0061L5.36699 17.2933C5.30432 17.2265 5.20272 17.2265 5.14005 17.2933L4.47181 18.0061C4.40914 18.073 4.40914 18.1813 4.47181 18.2482L5.14006 18.961C5.20273 19.0278 5.30434 19.0278 5.36701 18.961L6.03526 18.2482C6.09793 18.1813 6.09793 18.073 6.03526 18.0061H6.03525ZM5.26149 18.3869C5.12064 18.3917 5.00553 18.2689 5.01002 18.1186C5.0141 17.9826 5.11799 17.8718 5.24555 17.8674C5.3864 17.8626 5.50151 17.9854 5.49702 18.1356C5.49294 18.2717 5.38905 18.3825 5.26149 18.3869Z"
        fill="#2A7DE1"
      />
      <line
        x1="1.2207"
        y1="9.17618"
        x2="9.287"
        y2="9.17618"
        stroke="#2A7DE1"
        stroke-width="0.883978"
      />
      <circle
        cx="5.19935"
        cy="12.8236"
        r="1.76796"
        stroke="#2A7DE1"
        stroke-width="0.883978"
      />
    </svg>
  );
};

export default IconBadgeReader;
