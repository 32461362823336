import { FancyPageTitle } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "@linaria/core";
import React from "react";
import RhombusButton from "../RhombusButton";
import { Body, HeroTitle, PreTitle } from "components/rui/typography";

const headerContainer = css`
  display: flex;
  justify-content: center;
  background: var(--gradient-light);
  padding: 4rem 20px 0 20px;

  min-height: 690px;
`;

const headerInner = css`
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  gap: 2rem;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;

const headerLeft = css`
  width: 50%;
  flex-grow: 1;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const headerRight = cx(
  headerLeft,
  css`
    display: flex;
    align-items: center;
  `
);

const textWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--nuetral-900);
`;

const imageWrapper = css`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
`;

const imageClass = css`
  width: 100% !important;
  @media (max-width: 820px) {
    margin-top: 1.25rem;
  }
`;

const HeroLayout3 = ({ data }) => {
  return (
    <section className={headerContainer}>
      <div className={headerInner}>
        <div className={headerLeft}>
          <div className={imageWrapper}>
            <GatsbyImage
              image={getImage(data.image)}
              alt={data?.altText ?? data.title}
              className={imageClass}
            />
          </div>
        </div>
        <div className={headerRight}>
          <div className={textWrapper}>
            <PreTitle color="var(--primary-bg)">{data.preTitle}</PreTitle>
            <HeroTitle color="var(--primary-bg)">{data.title}</HeroTitle>
            <Body>{data.description}</Body>
            {data?.button && (
              <RhombusButton
                type="primary-dark"
                title={data.button.title}
                path={data.button.path}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroLayout3;
