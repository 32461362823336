import { styled } from "@linaria/react";
import React from "react";

const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: max-content;
  @media (max-width: 700px) {
    justify-content: center;
  }
`;

const EventSkeletion = styled.div`
  width: 400px;
  height: 450px;
  max-width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  background-size: 300% 100%;
  background-image: ${(props) =>
    props.darkMode
      ? "linear-gradient(90deg, var(--nuetral-900), var(--blue-800), var(--nuetral-900), var(--blue-800))"
      : "linear-gradient(90deg, #f1f1f1,var(--white), #f1f1f1, var(--white))"};
  animation: animateBg 2s infinite;
  @keyframes animateBg {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

const skeletons = [1, 2, 3, 4, 5, 6, 7, 8];

const EventLoader = () => {
  return (
    <SkeletonContainer>
      {skeletons.map(() => (
        <EventSkeletion></EventSkeletion>
      ))}
    </SkeletonContainer>
  );
};

export default EventLoader;
