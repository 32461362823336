import FormAndTestimonialSection from "components/landing-pages/consultant-lp/FormAndTestimonialSection";
import FormBanner from "components/landing-pages/consultant-lp/FormBanner";
import HighlightSection from "components/landing-pages/consultant-lp/HighlightSection";
import IntegrationBanner from "components/landing-pages/consultant-lp/IntegrationBanner";
import LandingPageFooter from "components/landing-pages/consultant-lp/LandingPageFooter";
import PriceComp from "components/landing-pages/consultant-lp/PriceComp";
import StatsBlade from "components/landing-pages/consultant-lp/StatsBlade";
import SimpleHero from "components/landing-pages/heroes/SimpleHero";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const pricingCTA = "Get Pricing";
const pricingLink = "#pricing-form";
const formId = "6b9307b5-fde3-45ef-b77a-e59136b4a147";

const pricing = () => {
  const imageData = useStaticQuery(graphql`
    query {
      zapier: file(
        relativePath: {
          eq: "components/landing-pages/consultant-lp/assets/zapier.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      openApi: file(
        relativePath: {
          eq: "components/landing-pages/consultant-lp/assets/open-api.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <GlobalLayout
      landingPage
      hideFooter
      color="var(--gradient-light)"
      buttonText={pricingCTA}
      ctaLink={pricingLink}
      includeButton
      useFullWidthLogo
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SimpleHero
        preTitle="Rhombus Pricing"
        title="Flexible pricing engineered for long-term ROI"
        blurb="Build a scalable security that grows with your business, ensuring optimal protection without hidden costs or budget surprises."
        buttonTitle={pricingCTA}
        buttonPath={pricingLink}
      />
      <StatsBlade />
      <PriceComp />
      <FormAndTestimonialSection
        title="Get Rhombus Pricing"
        subTitle=""
        submitButtonText={pricingCTA}
        formId={formId}
      />
      <IntegrationBanner />
      <HighlightSection
        secondary
        image={imageData.zapier}
        title={
          "Connect your tools and automate protection with Zapier and Make integrations"
        }
        blurb={
          "Streamline your security operations by connecting Rhombus to central applications like Google Workspace, Microsoft 365,  and Slack, with simple, drag-and-drop functionality."
        }
        listItems={[
          "Streamline daily tasks by creating customizable connections between Rhombus and any app in minutes.",
          "Simplify notifications by connecting to Gmail, Outlook, Teams, Slack, Ring Central, and more. ​",
          "Manage camera schedules, alerts, and more based on day of the week or time of day. ",
        ]}
      />
      <HighlightSection
        image={imageData.openApi}
        title={"Future-proof your physical security with our 100% open API"}
        blurb={
          "Customize your security with Rhombus' open API. Leverage Rhombus' open API to connect your surveillance with existing business tools and create limitless functionality."
        }
        listItems={[
          "Build custom functionality and features with the Rhombus Open API.",
          "Enjoy easy-to-use plug-and-play integrations that cater to all security needs.",
          "Unify your video security with business systems, deep analytics, and more.",
        ]}
      />
      <FormBanner
        preTitle={"Get pricing"}
        title={"See how Rhombus fits your security investment"}
        formTitle={"Get Rhombus Pricing"}
        formId={formId}
        submitText={pricingCTA}
      />
      <LandingPageFooter />
    </GlobalLayout>
  );
};

export default pricing;
