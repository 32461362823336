export const FRIENDLY_CAMERA_NAMES = {
  allCameras: "All",
  R120: "R120 Mini Dome",
  R170: "R170 Micro Dome",
  R200: "R200 Mini Dome",
  R230: "R230 WiFi Dome",
  R360: "R360 Fisheye",
  R400: "R400 Dome",
  R500: "R500 Telephoto",
  R510: "R510 Standard Zoom",
  R520: "R520 Mini",
  R600: "R600 Multisensor",
  N100: "N100 Relay Core",
};

export const FRIENDLY_ACCESS_CONTROL_NAMES = {
  allAccessControl: "All",
  DR20: "DR20 Door Reader",
  DR40: "DR40 Video Intercom",
};

export const FRIENDLY_SENSOR_NAMES = {
  allSensors: "All",
  A100: "A100 Audio Gateway",
  E50: "E50 Environmental",
};

export const ACCESSORY_CATEGORIES = [
  "Wall Mount",
  "Ceiling Mount",
  "Corner Mount",
  "Pole Mount",
  "Rack Mount",
];

export const cameraModels = [
  "R120",
  "R170",
  "R200",
  "R230",
  "R360",
  "R400",
  "R500",
  "R510",
  "R520",
  "R600",
  "N100",
];

export const acModels = ["DR20", "DR40"];

export const sensorModels = ["A100", "E50"];

export const DEFAULT_SELECTIONS = {
  cameras: "allCameras",
  ac: "allAccessControl",
  sensors: "allSensors",
};
