import React from "react";

import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import { StaticQuery, graphql, useStaticQuery } from "gatsby";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";
import r360Video from "components/cameras/mp4/r360-fisheye-animation.hevc.mp4";
import r360VideoBackup from "components/cameras/mp4/backups/r360-fisheye-animation.mp4";
import {
  CTA_BUTTON_COPY_3,
  CTA_BUTTON_COPY_6,
  LIVE_DEMO_LINK,
} from "components/data/landing-page-data";

export default function FisheyeCameras() {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/fisheye-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device3: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device4: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device5: file(
        relativePath: {
          eq: "components/cameras/dome-cameras/img/dome-5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/cover-all-angles-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/monitor-large-areas-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/cameras/fisheye-cameras/img/arial-view-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: { eq: "components/cameras/img/fisheye-spec-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(relativePath: { eq: "components/common/icons/sensor.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/common/icons/dimensions.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(relativePath: { eq: "components/common/icons/servers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/common/icons/weather.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(relativePath: { eq: "components/common/icons/impact.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(
        relativePath: { eq: "components/common/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImageMobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      recommend1: file(
        relativePath: { eq: "components/cameras/img/dome-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend2: file(
        relativePath: { eq: "components/cameras/img/bullet-explore-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      recommend3: file(
        relativePath: { eq: "components/cameras/img/r600-card-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      fisheye: file(
        relativePath: { eq: "components/cameras/img/fisheye-spec-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      faq: file(
        relativePath: { eq: "components/cameras/img/fisheye-faq.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);

  const pageData = {
    headerSection: {
      preTitle: "Fisheye cameras",
      title: "360° Coverage, 0 Blind Spots with Fisheye Cameras",
      description:
        "Seamlessly monitor large areas with panoramic coverage from fisheye surveillance cameras. Achieve unparalleled situational awareness with real-time immersive and dewarped video security on a user-friendly cloud platform.",
      image: data.headerImage,
      altText:
        "Rhombus R360 fisheye surveillance camera displayed on a podium, showcasing the advanced features of fisheye cameras for security",
      button1: {
        text: CTA_BUTTON_COPY_6,
        path: LIVE_DEMO_LINK,
      },
      button2: {
        text: "View R360",
        path: "/cameras/fisheye-cameras/r360",
      },
    },
    benefitsSection: {
      title:
        "Experience Clear, 360-Degree Coverage With Fisheye Surveillance Cameras",
      items: [
        {
          image: data.benefit1,
          altText:
            "Rhombus fisheye camera on a ceiling, featuring a fisheye lens to enhance security with wide-angle IP camera capabilities.",
          title: "Gain Total Visibility",
          description:
            "Say goodbye to blind spots. With real-time de-warping of 360° footage, you can monitor large areas from multiple angles and ensure nothing is out of frame or missed. ",
        },
        {
          image: data.benefit2,
          altText:
            "Rhombus fisheye camera with advanced features installed indoors at a workplace, eliminating the need for multiple progressive scan cameras.",
          title: "Get Complete Coverage across Large Areas",
          description:
            "From stadiums to auditoriums to showroom floors, fisheye security cameras provide comprehensive coverage of large, open spaces. Get visibility you need with just one camera.",
        },
        {
          image: data.benefit3,
          altText:
            "Image from a Rhombus fisheye security camera showing a customer completing a transaction at a retail counter with motion detection.",
          title: "Engage with Every Viewing Angle",
          description:
            "Take advantage of the 360° visibility with immersive views or create your own tile views. Dive into the video and move around within the scene for enhanced situational awareness.",
        },
      ],
    },
    specSection: {
      reverse: true,
      title: "Achieve Uncompromised Visibility",
      image: data.specImage,
      altText:
        "Rhombus fisheye camera installed indoor in a warehouse, offering a fisheye view, NDAA compliance, and compatibility with many IP cameras.",
      specs: [
        { icon: data.icon1, title: "12MP", subTitle: "Image Sensor" },
        {
          icon: data.icon2,
          title: "Compact",
          subTitle: "130mm x 58mm",
        },
        {
          icon: data.icon3,
          title: "Up to 60 Days",
          subTitle: "On-Camera Storage",
        },
        {
          icon: data.icon4,
          title: "IP66",
          subTitle: "Weather Resistance",
        },
        {
          icon: data.icon5,
          title: "IK10",
          subTitle: "Impact Resistance",
        },
        {
          icon: data.icon6,
          title: "Live Dewarping",
          subTitle: "Video",
        },
      ],
    },
    exploreSection: {
      cards: [
        {
          image: data.recommend1,
          name: "Dome Cameras",
          description:
            "Flexible deployment with high performance and edge-based analytics",
          link: "/cameras/dome-cameras/",
          flex: 3,
        },
        {
          image: data.recommend2,
          name: "Bullet Cameras",
          description: "Ruggedized form-factor with high-resolution video",
          link: "/cameras/bullet-cameras/",
          flex: 3,
        },
        {
          image: data.recommend3,
          name: "Multisensor Cameras",
          description: "One powerful camera for complete coverage",
          link: "/cameras/multisensor-cameras/",
          flex: 3,
        },
      ],
    },
  };

  const faq = {
    title: "Fisheye Cameras FAQs",
    image: data.faq,
    alt: "Rhombus R360 fisheye camera installed outdoors, providing panoramic views for wide area surveillance and is NDAA compliant.",
    QA: [
      {
        question:
          "How does a fisheye camera differ from a traditional dome camera?",
        answer:
          "Fisheye cameras use a specialized wide-angle camera lens to capture a 360-degree panoramic view, providing more comprehensive coverage than traditional dome surveillance cameras. This allows a single fisheye camera to monitor large areas that might require many cameras.",
      },
      {
        question:
          "What viewing modes are available with Rhombus fisheye security cameras?",
        answer:
          "Rhombus fisheye surveillance cameras offer multiple viewing modes, including a 360-degree panoramic view, dewarped quad view, and an immersive view. These options allow users to customize their surveillance perspective for optimal monitoring.",
      },
      {
        question:
          "How is the video quality of fisheye cameras in low light conditions?",
        answer:
          "Rhombus fisheye cameras are equipped with advanced low-light performance capabilities. They utilize infrared light for night vision, ensuring clear video quality even in dimly lit environments.",
      },
      {
        question: "What are suitable locations to install a fisheye camera?",
        answer: (
          <>
            Fisheye cameras are ideal for use cases where you need a large field
            of view. A few examples include:
            <ul>
              <li>Retail Stores: Monitor checkout areas.</li>
              <li>Shopping Malls: Cover large spaces and entrances.</li>
              <li>Parking Lots: Enhance security with wide views</li>
              <li>Warehouses: Reduce the need for many cameras</li>
            </ul>
            Rhombus fisheye security cameras also feature night vision and an
            auto switch for optimal performance in various lighting conditions.
          </>
        ),
      },
      {
        question: "Can fisheye surveillance cameras be used outdoors?",
        answer:
          "Yes, fisheye surveillance cameras are designed for both indoor and outdoor use. The Rhombus R360 features weatherproof ratings (IP66) and vandal-resistant designs, making it suitable for various environments. It provides panoramic coverage and enhanced visibility, ensuring comprehensive monitoring of large areas regardless of the weather conditions.",
      },
    ],
  };

  return (
    <GlobalLayout color="var(--primary-bg)" dark>
      <Helmet>
        <title>360° Fisheye Cameras | Try for Free | Rhombus </title>
        <meta
          name="description"
          content="Start a free trial to discover the power of Rhombus' fisheye surveillance cameras for 360° coverage and unmatched situational awareness in large areas."
        />
        <meta
          name="thumbnail"
          content="https://rhombus.com/img/MetaTag-Fisheye.png"
        />
        <meta
          property="og:image"
          content="https://rhombus.com/img/MetaTag-Fisheye.png"
        />
      </Helmet>
      <DevicePageTemplate
        data={pageData}
        video={r360Video}
        videoBackup={r360VideoBackup}
        deviceType="Camera"
        recommend
        reverse
        image={data.fisheye}
        faq={faq}
      />
    </GlobalLayout>
  );
}
