import React, { useEffect, useRef, useState } from "react";
import { styled } from "@linaria/react";
import { useIsElementOverflowing } from "hooks/useIsElementOverflowing";
import CloseIcon from "components/common/icon-components/Close";
import IconCheckMark from "components/common/icon-components/IconCheckMark";

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
`;

const ChipContainer = styled.div`
  display: flex;
  overflow: auto;
  gap: 1.25rem;
  align-items: center;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Chip = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--primary-bg);
  border-radius: 30px;
  border: 1px solid var(--primary-bg);
  text-align: center;
  font-size: 16px;
  flex-shrink: 0;
  padding: 5px 15px;
  font-weight: 350;
  line-height: 200%;
  transition: all 250ms;
  background-color: ${(props) =>
    props.active ? "var(--secondary-bg)" : "unset"};
  position: relative;
  overflow: hidden;
  img {
    transition: all 250ms;
  }
  input {
    position: absolute;
    height: 50px;
    width: 200px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  :has(input:checked) {
    background-color: var(--secondary-bg);
  }

  :hover {
    background-color: var(--secondary-bg);
  }
`;

const ChipFilters = ({
  options = [],
  handleFilter,
  center = false,
  defaultSelected = null,
  fallBackSelection,
}) => {
  const filtersRef = useRef(null);
  const chipsRef = useRef({});
  const [selected, setSelected] = useState(defaultSelected);
  const [iconStatus, setIconStatus] = useState({});
  const { isOverflowing } = useIsElementOverflowing(filtersRef);
  const [currentIndex, setCurrentIndex] = useState(0);

  const selectionsRef = useRef(selected);
  selectionsRef.current = selected;

  useEffect(() => {
    if (defaultSelected) {
      setCurrentIndex(options.indexOf(defaultSelected));
    }
  }, []);

  useEffect(() => {
    if (!defaultSelected) return;
    if (chipsRef.current[currentIndex]) {
      requestAnimationFrame(() => {
        chipsRef.current[currentIndex].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      });
    }
  }, [currentIndex]);

  useEffect(() => {
    handleFilter(
      selected ? [selected] : fallBackSelection ? [fallBackSelection] : []
    );
  }, [selected]);

  const handleSingleSelect = (filter, index) => {
    if (isOverflowing) {
      setCurrentIndex(index);
    }
    setSelected((prev) => (prev === filter ? null : filter));
  };

  const handleSelect = (filter, index) => {
    handleSingleSelect(filter, index);
  };

  const handleMouseOver = (filter) => {
    setIconStatus({ ...iconStatus, [filter]: { closed: true } });
  };

  const handleMouseLeave = (filter) => {
    setIconStatus({ ...iconStatus, [filter]: { closed: false } });
  };

  return (
    <FilterContainer center={center}>
      <ChipContainer ref={filtersRef}>
        {options.map((item, index) => (
          <Chip
            key={item}
            active={selected === item}
            ref={(el) => (chipsRef.current[index] = el)}
          >
            <input
              type="checkbox"
              onChange={() => handleSelect(item, index)}
              onMouseEnter={() => handleMouseOver(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              checked={selected === item}
            />
            <span>{item}</span>
            {selected === item ? (
              <>
                {iconStatus[item]?.closed ? <CloseIcon /> : <IconCheckMark />}
              </>
            ) : (
              <></>
            )}
          </Chip>
        ))}
      </ChipContainer>
    </FilterContainer>
  );
};

export default ChipFilters;
