import {
  CategoryCol,
  Selection,
} from "components/integrations/marketplace/styles/MarketPlace-css";
import { styled } from "@linaria/react";
import React, { useCallback } from "react";
import {
  DEFAULT_SELECTIONS,
  FRIENDLY_ACCESS_CONTROL_NAMES,
  FRIENDLY_CAMERA_NAMES,
  FRIENDLY_SENSOR_NAMES,
} from "./constants";

import { CAMERAS } from "components/compare-devices/camera-data";

import DR20 from "./img/dr20-render-small.png";
import DR40 from "./img/dr40-render-small.png";
import A100 from "./img/a100-tiny-render.png";
import E50 from "./img/e50-tiny-render.png";
import useIsMobile from "hooks/useIsMobile";
import { MOBILE_MEDIA_QUERY } from "./AccessoriesContainer";
import { scrollToTop } from "./util";

export const SidebarContainer = styled(CategoryCol)`
  width: 20%;
  position: sticky;
  top: 7.5rem;
  height: fit-content;
  flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const SelectionDropDown = styled.details`
  user-select: none;
  &[open] > summary::after {
    transform: rotate(0);
  }
`;

export const StyledSummary = styled.summary`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;
  width: 100%;
  max-width: 200px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  ::marker,
  ::-webkit-details-marker {
    display: none;
    content: "";
  }
  ::after {
    content: "";
    background-image: url(./img/summary-arrow.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    transform: rotate(180deg);
    transition: all 250ms ease-in-out;
  }
  :hover {
    color: var(--blue-500);
  }
`;

export const SelectionContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  padding-top: 1rem;
  list-style: none;
  gap: 1rem;
  @media (max-width: 600px) {
    padding-top: 1.25rem;
    gap: 1.25rem;
  }
`;

const AccessoriesSidebar = ({
  selectedModel,
  setSelectedModel,
  defaultSelection = DEFAULT_SELECTIONS.cameras,
  setModalOpen,
}) => {
  const { isMobile } = useIsMobile(MOBILE_MEDIA_QUERY);

  const handleSelect = useCallback(
    (key) => {
      setSelectedModel(key);
      if (isMobile) {
        setModalOpen(false);
        scrollToTop("#feed-container");
      }
    },
    [isMobile]
  );

  return (
    <SidebarContainer reverse={defaultSelection === DEFAULT_SELECTIONS.ac}>
      <SelectionDropDown
        open={defaultSelection === DEFAULT_SELECTIONS.cameras || isMobile}
      >
        <StyledSummary>Cameras</StyledSummary>
        <SelectionContainer>
          {Object.keys(FRIENDLY_CAMERA_NAMES).map((key) => {
            const currentCamera = CAMERAS.find((item) =>
              item.modelName.includes(key)
            );
            return (
              <Selection
                active={selectedModel === key}
                onClick={() => handleSelect(key)}
              >
                {key !== "allCameras" && (
                  <img
                    src={currentCamera.renderSmall}
                    alt={FRIENDLY_CAMERA_NAMES[key]}
                    height="24"
                    width="24"
                  />
                )}
                {FRIENDLY_CAMERA_NAMES[key]}
              </Selection>
            );
          })}
        </SelectionContainer>
      </SelectionDropDown>
      <SelectionDropDown
        open={defaultSelection === DEFAULT_SELECTIONS.ac || isMobile}
      >
        <StyledSummary>Access Control</StyledSummary>
        <SelectionContainer>
          {Object.keys(FRIENDLY_ACCESS_CONTROL_NAMES).map((key) => (
            <Selection
              active={selectedModel === key}
              onClick={() => handleSelect(key)}
            >
              {key !== "allAccessControl" && (
                <img
                  src={key === "DR20" ? DR20 : DR40}
                  alt={FRIENDLY_ACCESS_CONTROL_NAMES[key]}
                  height="24"
                  width="24"
                />
              )}
              {FRIENDLY_ACCESS_CONTROL_NAMES[key]}
            </Selection>
          ))}
        </SelectionContainer>
      </SelectionDropDown>
      <SelectionDropDown
        open={defaultSelection === DEFAULT_SELECTIONS.sensors || isMobile}
      >
        <StyledSummary>Sensors</StyledSummary>
        <SelectionContainer>
          {Object.keys(FRIENDLY_SENSOR_NAMES).map((key) => (
            <Selection
              active={selectedModel === key}
              onClick={() => handleSelect(key)}
            >
              {key !== "allSensors" && (
                <img
                  src={key === "A100" ? A100 : E50}
                  alt={FRIENDLY_SENSOR_NAMES[key]}
                  height="24"
                  width="24"
                />
              )}
              {FRIENDLY_SENSOR_NAMES[key]}
            </Selection>
          ))}
        </SelectionContainer>
      </SelectionDropDown>
    </SidebarContainer>
  );
};

export default AccessoriesSidebar;
