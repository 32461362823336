import React from "react";
import { styled } from "@linaria/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { graphql, StaticQuery } from "gatsby";

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  background: var(--gradient-light);
  justify-content: center;
  padding: 1.25rem 0;
`;

export const StyledSlider = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: var(--max-width);
  -webkit-mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
`;

const SlideTrack = styled.div`
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * ${props => props.logoCount * 2});
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * ${props => props.logoCount}));
    }
  }
`;

const Slide = styled.div`
  height: 100px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const logoStyles = css`
  width: 135px;
`;

export default function LogoStripeSlider() {
  const renderContent = data => {
    const logos = [
      {
        img: data.logo1,
        width: "118px",
        alt: "Amazon",
      },
      {
        img: data.logo2,
        width: "130px",
        alt: "Hurley",
      },
      {
        img: data.logo3,
        width: "177px",
        alt: "Aramark",
      },
      {
        img: data.logo4,
        width: "115px ",
        alt: "Stryker",
      },
      {
        img: data.logo5,
        width: "149px",
        alt: "Goodwill",
      },
      {
        img: data.logo6,
        width: "137px",
        alt: "Clark Construction",
      },
    ];
    const repeatedLogos = [...logos, ...logos];
    return (
      <SliderContainer>
        <StyledSlider>
          <SlideTrack logoCount={logos.length}>
            {repeatedLogos.map((slide, index) => (
              <Slide key={index} className="slide">
                <GatsbyImage
                  image={getImage(slide.img)}
                  className={logoStyles}
                  style={{ width: slide.width }}
                />
              </Slide>
            ))}
          </SlideTrack>
        </StyledSlider>
      </SliderContainer>
    );
  };

  const GET_LOGOS = graphql`
    query {
      logo1: file(relativePath: { eq: "components/common/logos/amazon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/common/logos/hurley.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-black.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(relativePath: { eq: "components/common/logos/stryker.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_LOGOS} render={renderContent} />;
}
