import React from "react";

const CloseIcon = ({ color = "currentColor", height = "10", width = "11" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 3102">
        <path
          id="Stroke 3"
          d="M1.07422 0.75L9.42386 9.25"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Stroke 5"
          d="M1 9.17447L9.5 0.824829"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
