import React, { useCallback } from "react";
import {
  handleInputType,
  validateEmail,
  validatePhone,
  validateText,
} from "./util/functions";
import he from "he";
import {
  darkInputStyles,
  filledInputStyles,
  inputContainer,
  inputStyles,
  inputWrap,
  invalid,
  invalidDark,
  invalidFilled,
} from "./styles/formStyles";

const FormInput = ({
  field,
  type,
  formState,
  setFormState,
  validationState,
  setValidationState,
  darkMode,
  filled,
}) => {
  const fieldType = field.name === "email" ? "email" : handleInputType(type);

  const inputClass = filled
    ? filledInputStyles
    : darkMode
    ? darkInputStyles
    : inputStyles;

  const handleErrorClass = useCallback(
    name => {
      const valid =
        validationState[name].valid || validationState[name].valid === "";
      if (!valid) {
        return darkMode ? invalidDark : filled ? invalidFilled : invalid;
      }
      return inputClass;
    },
    [validationState, darkMode, filled]
  );

  const handleValidation = (name, type, value) => {
    switch (type) {
      case "text":
        return setValidationState(validateText(name, validationState, value));
      case "email":
        return setValidationState(validateEmail(name, validationState, value));
      case "tel":
        return setValidationState(validatePhone(name, validationState, value));
      default:
        break;
    }
  };
  return (
    <div className={inputContainer} key={field.name}>
      <div className={inputWrap}>
        <input
          data-testid={field.name}
          onChange={e => {
            setFormState({
              ...formState,
              [field.name]: {
                objectTypeId: field.objectTypeId,
                value: e.target.value,
              },
            });
            handleValidation(field.name, fieldType, e.target.value);
          }}
          className={handleErrorClass(field.name)}
          type={fieldType}
          placeholder={he.decode(field.placeholder)}
          required={field.required}
          value={formState[field.name].value}
          name={field.name}
        />
      </div>
      <p>{validationState[field.name].message}</p>
    </div>
  );
};

export default FormInput;
