import { styled } from "@linaria/react";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import React from "react";
import {
  FlexColumn,
  FlexRow,
  FlexRowCenter,
  FlexRowEnd,
  TextContainer,
} from "components/rui/layout";
import { Body, HeroTitle } from "components/rui/typography";
import RhombusButton from "components/common/RhombusButton";
import { FloatingAlert, FloatingNotification } from "./floatingElements";
import useIsMobile from "hooks/useIsMobile";
import { CUSTOM_QUOTE_LINK } from "components/data/landing-page-data";

const HeroContainer = styled(SectionContainer)`
  margin-top: -80px;
  // offset our negative margin
  padding-top: 160px;
  position: relative;
  min-height: 700px;
  @media (max-width: 900px) {
    min-height: 100vh;
    background-image:
      linear-gradient(to top, rgba(0, 0, 0, 0) 0%, var(--primary-bg) 100%),
      url("./assets/home-hero.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const HeroLeft = styled(SectionCol)`
  @media (min-width: 1200px), (max-width: 1050px) {
    padding-right: 2.75rem;
  }
  @media (max-width: 1024px) {
    padding-right: 1.25rem;
  }
`;

const HeroImage = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 2.5rem 3.75rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 763px;
  height: 700px;
  background-image:
    linear-gradient(to top, rgba(0, 0, 0, 0) 50%, var(--primary-bg) 100%),
    url("./assets/home-hero.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0px 0px 0px 16px;
  overflow: hidden;
  @media (min-width: 2000px) {
    width: 900px;
  }
  @media (max-width: 1440px) {
    width: 660px;
  }
  @media (max-width: 1200px) {
    width: 550px;
  }
  @media (max-width: 1024px) {
    width: 440px;
  }
`;

const FloatingElementsContainer = styled(FlexRow)`
  width: 100%;
  height: 45%;
  justify-content: center;
  align-items: flex-start;
  gap: 5rem;
  @media (max-width: 1200px) {
    gap: 2.5rem;
  }
  @media (max-width: 1024px) {
    gap: 0;
  }
`;

const HomePageHero = () => {
  const { isMobile } = useIsMobile(900);
  const { isMobile: isMobileSmall } = useIsMobile(600);

  if (isMobile) {
    return (
      <HeroContainer color="var(--primary-bg)">
        <SectionInner>
          <FlexColumn gap="2.5rem">
            <TextContainer>
              <HeroTitle>Built to protect. Designed to adapt.</HeroTitle>
              <Body large light>
                Experience the future of cloud-managed physical security that's
                secure, open, and fully interoperable. Safeguard spaces, protect
                people, and streamline operations on a single, integrated
                platform.
              </Body>
              <FlexRowCenter gap="28px">
                <RhombusButton title="See Demo" path={"/live-demo/"} />
                {!isMobileSmall && (
                  <RhombusButton
                    title={"Request Custom Quote"}
                    type="secondary-light"
                    path={CUSTOM_QUOTE_LINK}
                  />
                )}
              </FlexRowCenter>
            </TextContainer>
            <FlexRowEnd gap="5rem">
              <FloatingAlert />
            </FlexRowEnd>
          </FlexColumn>
        </SectionInner>
      </HeroContainer>
    );
  }
  return (
    <HeroContainer color="var(--primary-bg)">
      <SectionInner>
        <HeroLeft>
          <TextContainer center>
            <HeroTitle>Built to protect. Designed to adapt.</HeroTitle>
            <Body large light>
              Experience the future of cloud-managed physical security that's
              open, secure, and fully interoperable. Safeguard spaces, protect
              people, and streamline operations on a single, integrated
              platform.
            </Body>
            <FlexRowCenter gap="28px">
              <RhombusButton title="See Demo" path={"/live-demo/"} />
              <RhombusButton
                title={"Request Custom Quote"}
                type="secondary-light"
                path={CUSTOM_QUOTE_LINK}
              />
            </FlexRowCenter>
          </TextContainer>
        </HeroLeft>
        <SectionCol>
          <HeroImage>
            <FloatingElementsContainer>
              <FloatingNotification />
              <FloatingAlert />
            </FloatingElementsContainer>
          </HeroImage>
        </SectionCol>
      </SectionInner>
    </HeroContainer>
  );
};

export default HomePageHero;
