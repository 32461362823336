export const LICENSE_TYPES = {
  STARTER: "Starter",
  PRO: "Professional",
  ENTERPRISE: "Enterprise",
};

export const ALM_LICENSE_PRICING = [
  {
    license: "Starter",
    monthlyVerifications: 10,
    priceMatrix: [
      { term: 1, price: 899 },
      { term: 3, price: 2099 },
      { term: 5, price: 3099 },
    ],
  },
  {
    license: "Professional",
    monthlyVerifications: 100,
    priceMatrix: [
      { term: 1, price: 1799 },
      { term: 3, price: 4299 },
      { term: 5, price: 6299 },
    ],
  },
  {
    license: "Enterprise",
    subLicenses: [
      {
        monthlyVerifications: 500,
        priceMatrix: [
          { term: 1, price: 7199 },
          { term: 3, price: 17199 },
          { term: 5, price: 25099 },
        ],
      },
      {
        monthlyVerifications: 1000,
        priceMatrix: [
          { term: 1, price: 14399 },
          { term: 3, price: 34599 },
          { term: 5, price: 50399 },
        ],
      },
      {
        monthlyVerifications: 1500,
        priceMatrix: [
          { term: 1, price: 21599 },
          { term: 3, price: 51839 },
          { term: 5, price: 75599 },
        ],
      },
      {
        monthlyVerifications: 2000,
        priceMatrix: [
          { term: 1, price: 28799 },
          { term: 3, price: 69119 },
          { term: 5, price: 100799 },
        ],
      },
    ],
  },
];

export const PANIC_BUTTON_DISPATCH_TEXT =
  "monthly dispatches. Panic button only.";
export const VERIFICATION_TEXT = "monthly video verifications per location.";
