import React from "react";
import Hero from "./Hero";
import LogoBlade from "./LogoBlade";
import TestimonialSection from "./TestimonialSection";
import BenefitsSection from "./BenefitsSection";
import FeaturesSection from "./FeaturesSection";
import CTASection1 from "./CTASection1";
import CaseStudySection from "./CaseStudySection";
import BenefitsSection2 from "./BenefitsSection2";
import CTASection2 from "./CTASection2";
import QuoteSection from "./QuoteSection";
import FAQSection from "components/common/FAQSection";
import LandingPageFooter from "./LandingPageFooter";
import { CTA_BUTTON_COPY_3 } from "components/data/landing-page-data";

const questions = [
  {
    question: "How do I get started with Rhombus?",
    answer:
      "To get started, you must purchase a camera (one-time fee) and a license. It's as simple as that.",
  },
  {
    question: "Can I purchase just the cameras without a license?",
    answer: "All of our cameras require an ongoing license to operate.",
  },
  {
    question: "Do Rhombus cameras consume a lot of bandwidth?",
    answer:
      "Not at all. Our cameras store all of the video locally on the cameras meaning they take virtually no bandwidth at all.",
  },
  {
    question: "Do Rhombus cameras have a warranty?",
    answer: "Yes - all of our cameras come standard with a 10-year warranty.",
  },
];

const LandingPageAlt = () => {
  return (
    <>
      <Hero
        ctaCopy={CTA_BUTTON_COPY_3}
        title="Smart Security Cameras with AI Analytics and Onboard Storage"
        blurb="Scale quickly with cloud-connected PoE cameras. Transform security operations with a suite of Rhombus cameras for every need. Discover how our cloud-managed platform improves threat deterrence and response with facial recognition, license plate detection, real-time alerts, and more."
      />
      <LogoBlade />
      <TestimonialSection />
      <BenefitsSection />
      <FeaturesSection />
      <CTASection1 ctaButtonCopy={CTA_BUTTON_COPY_3} />
      <CaseStudySection />
      <BenefitsSection2 />
      <CTASection2 ctaButtonCopy={CTA_BUTTON_COPY_3} />
      <QuoteSection />
      <FAQSection center QA={questions} title="FAQ" />
      <LandingPageFooter />
    </>
  );
};

export default LandingPageAlt;
