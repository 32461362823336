import GlobalLayout from "components/page/GlobalLayout";
import {
  FlexCenter,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PageTitle,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { styled } from "@linaria/react";
import React from "react";
import { Helmet } from "react-helmet";
import heroImage from "components/landing-pages/img/gsx/gsx-2024-hero.png";
import { graphql, StaticQuery } from "gatsby";
import AccordionSection from "components/common/AccordionSection";
import LogoBladeGSX from "components/landing-pages/gsx/LogoBladeGSX";
import RhombusButton from "components/common/RhombusButton";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import { StaticImage } from "gatsby-plugin-image";
import FourColumnFeatures from "components/common/FourColumnFeatures";
import IconSystem from "components/common/icon-components/IconSystem";
import IconUX from "components/common/icon-components/IconUX";
import IconNetwork from "components/common/icon-components/IconNetwork";
import IconSecureShield from "components/common/icon-components/IconSecureShield";

const Hero = styled(SectionContainer)`
  background-image: url(${heroImage});
  background-size: cover;
  background-position: right;
  text-align: center;
  align-items: center;
  min-height: 650px;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(8, 45, 89, 0.4);
    z-index: 1;
  }
  @media (max-width: 820px) {
    background-position: center;
  }
`;

const HeroText = styled(TextContainer)`
  z-index: 2;
  align-items: center;
  color: var(--white);
  gap: 0;
  h3 {
    font-size: 30px;
  }

  p {
    font-size: 24px;
    margin: 1.25rem 0;
  }

  @media (min-width: 1440px) {
    h1 {
      font-size: 65px;
    }
  }
`;

const Text = styled(TextContainer)`
  h2,
  & {
    text-align: left;
  }
`;

const imageProps = {
  imgStyles: {
    borderRadius: "12px",
    width: "669px",
    maxWidth: "calc(100% - 2.5rem)",
    maxHeight: "416px",
  },
};

const FlexContainer = styled(FlexCenter)`
  justify-content: flex-start;
  @media (max-width: 820px) {
    justify-content: center;
  }
`;

export default function GSXLandingPage() {
  const renderContent = data => {
    return (
      <GlobalLayout>
        <Helmet>
          <title>Meet Rhombus at GSX | Rhombus™</title>
          <meta
            name="description"
            content="Rhombus cloud-managed physical security platform.The power to choose what keeps you safe."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/MetaTag-R600-min.png"
          />
          <meta
            property="og:image"
            content="https://rhombus.com/img/MetaTag-R600-min.png"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Hero>
          <SectionInner>
            <HeroText>
              <StaticImage
                src="../../components/landing-pages/img/gsx/gsx-logo.png"
                alt="Meet Rhombus at Booth 960: Learn how to future proof your operation at GSX"
                quality={100}
                loading="eager"
                placeholder="blurred"
                height={40}
                width={127}
                style={{ marginBottom: "1.25rem" }}
              />
              <PageTitle>Meet Rhombus at Booth 960</PageTitle>
              <PreTitle>Orlando, FL | September 23-25, 2024</PreTitle>
              <MainParagraph>
                Learn how to future proof your operation at GSX
              </MainParagraph>
              <RhombusButton
                type="white"
                title="Get Your Free Expo Pass"
                useHref
                target="_blank"
                path="https://vipguestinvites.com/shows/GSX2024/submissions/rhombus/rhombus.html"
              />
            </HeroText>
          </SectionInner>
        </Hero>
        <LogoBladeGSX
          style={{
            background: "var(--gradient-light)",
            padding: "2.5rem 1.25rem",
          }}
        />
        <SectionContainer>
          <SectionInner>
            <FlexContainer>
              <StaticImage
                src="../../components/landing-pages/img/gsx/product-suite-min.png"
                alt="Rhombus Access Control for Schools"
                quality={100}
                loading="lazy"
                placeholder="blurred"
                style={{
                  width: "668px",
                  maxWidth: "100%",
                  maxHeight: "420px",
                }}
              />
            </FlexContainer>
            <FlexContainer>
              <Text>
                <PreTitle>CLOUD-managed PHYSICAL SECURITY</PreTitle>
                <TitleMed color="var(--blue-500)">
                  The power to choose what keeps you safe
                </TitleMed>
                <MainParagraph>
                  Rhombus simplifies security with a seamless experience on a
                  single pane of glass while allowing you to personalize a
                  solution - on a secure and open data platform - for needs that
                  matter to you the most.
                </MainParagraph>
                <RhombusButton
                  style={{ margin: "1.25rem 0 0 0" }}
                  title={CTA_BUTTON_COPY}
                  path={"/live-demo/"}
                />
              </Text>
            </FlexContainer>
          </SectionInner>
        </SectionContainer>
        <AccordionSection
          color="var(--nuetral-100)"
          fancy={false}
          data={{
            title: "A unified experience all in a single pane of glass",
            items: [
              {
                ...imageProps,
                img: data.acc1,
                alt: "",
                title: "Smart Cameras",
                p:
                  "Protect your organization and modernize security management with smart cloud cameras. With plug-and-play deployment, onboard AI analytics, and native remote access, it's easy to secure your spaces with confidence.",
              },
              {
                ...imageProps,
                img: data.acc2,
                alt: "",
                title: "Access Control and Alarm Monitoring",
                p:
                  "Simplify building management with Access Control. Round the clock protection with Alarm Monitoring that combines AI video events with live monitoring services.",
              },
              {
                ...imageProps,
                img: data.acc3,
                alt: "",
                title: "IoT Sensors",
                p:
                  "Real-time, actionable insights across your environment with powerful sensors such as environmental sensors, entry monitoring, audio gateways, and motion sensors, and smart buttons.",
              },
              {
                ...imageProps,
                img: data.acc4,
                alt: "",
                title: "Open Platform",
                p:
                  "Access control integrations, turnkey technology partners, and the most open API to seamlessly integrate into existing systems and workflows.",
              },
            ],
          }}
        />
        <FourColumnFeatures
          features={[
            {
              title: "Ease of use",
              blurb:
                "Quick set-up with a single PoE, making installation a breeze. Get your users up and running fast with flexible policies and out-of-the-box analytics.",
              icon: () => <IconSystem />,
            },
            {
              title: "Unified experience",
              blurb:
                "Cloud cameras, access control, IoT sensors and alarm monitoring – all in a single pane of glass - accessible securely, anytime and anywhere.",
              icon: () => <IconUX />,
            },
            {
              title: "Open and flexible",
              blurb:
                "Future proof operations with 40+ technology and access control integrations and robust API to integrate with existing and future systems.",
              icon: () => <IconNetwork />,
            },
            {
              title: "Secure by default",
              blurb:
                "End-to-end encryption to give you total security, automatic security updates, and NDAA and TAA compliant hardware.",
              icon: () => <IconSecureShield />,
            },
          ]}
          title="A platform designed to grow with you"
          bgColor="var(--white)"
        />
      </GlobalLayout>
    );
  };

  const query = graphql`
    query {
      heroImage: file(
        relativePath: {
          eq: "components/landing-pages/img/gsx/gsx-2024-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc1: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-outdoors-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc2: file(
        relativePath: {
          eq: "components/landing-pages/img/gsx/rhombus-access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc3: file(
        relativePath: {
          eq: "components/landing-pages/img/gsx/rhombus-iot-sensors.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      acc4: file(
        relativePath: {
          eq: "components/landing-pages/img/gsx/rhombus-open-platform.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
