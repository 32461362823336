import { format, toZonedTime } from "date-fns-tz";
import { format as formatDate } from "date-fns";

export const formatISODateWithTimeZone = (ISODate, timeZone) => {
  if (!ISODate) return null;

  const zonedDate = toZonedTime(ISODate, "Etc/UTC");
  const timeZoneAbbreviation = format(zonedDate, "zzz", { timeZone });
  const date = formatDate(zonedDate, "eeee, MMMM do 'at' h:mmaaa");
  const twentyFourHourTime = formatDate(zonedDate, "H:mm");

  if (timeZone === "Europe/London") {
    return `${date} ${timeZoneAbbreviation} (${twentyFourHourTime} ${timeZoneAbbreviation})`;
  }

  return `${date} ${timeZoneAbbreviation}`;
};

export const checkExpiration = async (date) => {
  try {
    const response = await fetch("/api/webinar/isExpiredWebinar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date }),
    });

    if (!response.ok) {
      throw new Error("Failed to check expiration");
    }

    const data = await response.json();
    console.log("Is expired:", data.isExpired);
    return data.isExpired;
  } catch (error) {
    console.error("Error:", error);
  }
};
