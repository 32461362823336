import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import BlogPage from "../components/blog/BlogPage";
import GlobalLayout from "../components/page/GlobalLayout";

export default function CategoryRoute({ pageContext, data }) {
  const { title, description, categoryGroups, blogSettings, category } =
    pageContext;
  const headerText = {
    title: title,
    paragraph: description,
  };
  return (
    <GlobalLayout>
      <Helmet
        title={title}
        meta={[{ name: "description", content: description }].filter(
          (meta) => meta.content
        )}
      />
      <BlogPage
        catData={data}
        categoryGroups={categoryGroups}
        blogSettings={blogSettings}
        headerText={headerText}
        category={category}
      />
    </GlobalLayout>
  );
}

export const categoryPageQuery = graphql`
  query CategoryPage($categoryRegex: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 100
      sort: { frontmatter: { date: DESC } }
      filter: {
        frontmatter: {
          type: { eq: "blog-post" }
          category: { regex: $categoryRegex }
          draft: { ne: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            category
            subCategory
            thumbnail
          }
        }
      }
    }
  }
`;
