import React from "react";

const IconClock = ({ size = "17", stroke = "var(--white)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.98291 5.22135V8.55469L10.9829 10.5547M2.98291 8.55469C2.98291 9.34262 3.1381 10.1228 3.43963 10.8508C3.74116 11.5787 4.18312 12.2402 4.74027 12.7973C5.29742 13.3545 5.95886 13.7964 6.68681 14.098C7.41476 14.3995 8.19498 14.5547 8.98291 14.5547C9.77084 14.5547 10.5511 14.3995 11.279 14.098C12.007 13.7964 12.6684 13.3545 13.2256 12.7973C13.7827 12.2402 14.2247 11.5787 14.5262 10.8508C14.8277 10.1228 14.9829 9.34262 14.9829 8.55469C14.9829 6.96339 14.3508 5.43727 13.2256 4.31205C12.1003 3.18683 10.5742 2.55469 8.98291 2.55469C7.39161 2.55469 5.86549 3.18683 4.74027 4.31205C3.61505 5.43727 2.98291 6.96339 2.98291 8.55469Z"
        stroke={stroke}
        stroke-width="1.09288"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconClock;
