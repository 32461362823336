import { styled } from "@linaria/react";

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.active ? "var(--blue-500)" : "var(--blue-900)"};
  width: 45px;
  aspect-ratio: 1;
  border-radius: 0.5rem;
  transition: all 250ms;
  color: var(--white);
  flex-shrink: 0;
`;

export const CloseButton = styled.button`
  height: 2.5rem;
  padding: 0.5rem;
  aspect-ratio: 1;
  border: none;
  outline: none;
  top: 1.25rem;
  right: 1.25rem;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: transparent;
  transition: all 250ms;
  svg {
    height: 14px;
    width: 14px;
    color: var(--nuetral-400);
    transition: all 250ms;
  }
  :hover {
    background: var(--nuetral-100);
  }
`;
