import TextLink from "components/common/TextLink";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useEffect, useState } from "react";

import {
  cameraDaysClass,
  cameraImageClass,
  cameraImageContainer,
  cameraInfoContainer,
  cameraPriceClass,
  cardContainer,
  circleButtonSelectedClass,
  circleButtonUnselectedClass,
  controllerQty,
  costTitle,
  errorClass,
  licenseTypeButtonsClass,
  licenseTypeContainerClass,
  licenseTypeContainerClassAccessControl,
  lineClass,
  qtyInput,
  smallFontClass,
  tooltipClass,
  tooltipContainer,
  tooltipIconClass,
  tooltipTextClass,
  typeButtonSelectedClass,
  typeButtonUnselectedClass,
  typeTooltipText,
  yearsButtonsClass,
  yearsContainerClass,
} from "../styles/pricingStyles";
import TextLinkV2 from "components/common/TextLinkV2";

export default function AccessControlCard({ info }) {
  const licencePricing = info.licencePricing;
  const doorLicensePricing = info.doorLicensePricing;
  const storagePricing = info.storagePrices;
  const [priceIndex, setPriceIndex] = useState(0);
  const [licensePriceIndex, setLicensePriceIndex] = useState(0);
  const [licenseType, setLicenseType] = useState(0);
  const [doorQty, setDoorQty] = useState(1);
  const [error, setError] = useState(false);
  const imageRef = getImage(info.image);
  const [unitQty, setUnitQty] = useState(1);
  const footNote = info?.footNote ?? null;

  useEffect(() => {
    setError(unitQty <= 0 || unitQty > 1000);
  }, [unitQty]);

  const handleSetQty = (value) => {
    setError(false);
    if (value % 1 !== 0 || value < 0) {
      setError(true);
    } else if (value <= 1000) {
      setDoorQty(value);
    } else setError(true);
  };

  const handleSubTotal = () => {
    if (storagePricing) {
      const subTotal = storagePricing[priceIndex].value;
      return subTotal;
    }
    if (info.price) {
      const subTotal = info.price;
      return subTotal;
    }
    return null;
  };

  const handleDoorQty = () => {
    if (doorQty <= 4) {
      return 1;
    }
    if (doorQty <= 7) {
      return 2;
    }
    const sum = doorQty % 4 === 0 ? ~~(doorQty / 4) : ~~(doorQty / 4) + 1; // eslint-disable-line no-bitwise
    return sum;
  };

  const moneyString = (num) => {
    return `$${num.toLocaleString()}`;
  };

  const handleTotalHelper = useCallback(
    (unitPrice) => {
      if (unitQty <= 0) {
        return moneyString(unitPrice);
      }
      if (unitQty > 1000) {
        return moneyString(unitPrice * 1000);
      }
      const total = unitQty * unitPrice;
      return moneyString(total);
    },
    [unitQty]
  );

  const handleTotal = () => {
    const subTotal = handleSubTotal();
    if (licencePricing) {
      const licensingTotal =
        licencePricing[licenseType].prices[licensePriceIndex].price;
      const total = subTotal + licensingTotal;
      return moneyString(total);
    }
    if (doorLicensePricing) {
      const licensingTotal =
        doorLicensePricing[licenseType].prices[licensePriceIndex].price;
      // eslint-disable-next-line no-mixed-operators
      const total = subTotal * handleDoorQty() + licensingTotal * doorQty;
      return moneyString(total);
    }
    return handleTotalHelper(subTotal);
  };

  const handleLicenseSubTotal = () => {
    let subTotal = null;
    if (licencePricing) {
      subTotal = licencePricing[licenseType].prices[licensePriceIndex].price;
    } else {
      subTotal =
        doorLicensePricing[licenseType].prices[licensePriceIndex].price;
    }
    return moneyString(subTotal);
  };

  const renderStorageSection = () => {
    if (storagePricing) {
      return (
        <div>
          <div className={lineClass}>
            <div className={tooltipContainer}>
              <h3>Days of storage</h3>
              <div className={tooltipClass}>
                <StaticImage
                  src="../img/tooltip-icon.png"
                  className={tooltipIconClass}
                  alt="Days of Storage Tooltip"
                  placeholder="blurred"
                />
                <span className={tooltipTextClass}>
                  Storage days based on default camera settings.
                </span>
              </div>
            </div>
            <div className={cameraPriceClass}>
              {moneyString(storagePricing[priceIndex].value)}
            </div>
          </div>
          <div className={cameraDaysClass}>
            {storagePricing.map((p, index) => (
              <button
                key={p.days}
                className={
                  index === priceIndex
                    ? circleButtonSelectedClass
                    : circleButtonUnselectedClass
                }
                onClick={() => setPriceIndex(index)}
              >
                {p.days}
              </button>
            ))}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderLicensing = () => {
    if (licencePricing) {
      return (
        <div>
          <div className={licenseTypeContainerClass}>
            <div className={lineClass}>
              <div className={tooltipContainer}>
                <h3>License</h3>
                <div className={tooltipClass}>
                  <StaticImage
                    src="../img/tooltip-icon.png"
                    className={tooltipIconClass}
                    alt="License Type Tooltip"
                    placeholder="blurred"
                  />
                  <span className={typeTooltipText}>
                    AI-features and cloud archiving not included in the
                    Professional License. For a detailed comparison, visit our{" "}
                    <TextLink to="/license-comparison/video-intercom">
                      License Comparison
                    </TextLink>{" "}
                    page.
                  </span>
                </div>
              </div>

              <div className={cameraPriceClass}>{handleLicenseSubTotal()}</div>
            </div>
            <div className={licenseTypeButtonsClass}>
              {licencePricing.map((p, index) => (
                <button
                  onClick={() => setLicenseType(index)}
                  key={index}
                  className={
                    licenseType === index
                      ? typeButtonSelectedClass
                      : typeButtonUnselectedClass
                  }
                >
                  {p.name}
                </button>
              ))}
            </div>
          </div>
          <div className={yearsContainerClass}>
            <h3>Years</h3>
            <div className={yearsButtonsClass}>
              {licencePricing[licenseType].prices.map((p, index) => (
                <button
                  onClick={() => setLicensePriceIndex(index)}
                  key={index}
                  className={
                    licensePriceIndex === index
                      ? circleButtonSelectedClass
                      : circleButtonUnselectedClass
                  }
                >
                  {p.term}
                </button>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderQuantityInput = () => {
    if (info.useQuantity) {
      return (
        <div className={lineClass}>
          <div className={tooltipContainer}>
            <h3>Number of {info.unit}</h3>
          </div>
          <div className={cameraPriceClass}>
            <input
              className={error ? errorClass : qtyInput}
              type="number"
              value={unitQty}
              min={1}
              max={1000}
              onChange={(e) => setUnitQty(e.target.value)}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderDoorLicenseSection = () => {
    if (doorLicensePricing) {
      return (
        <div>
          <div className={licenseTypeContainerClassAccessControl}>
            <div className={lineClass}>
              <div className={tooltipContainer}>
                <h3>Number of Doors</h3>
                <div className={tooltipClass}>
                  <StaticImage
                    src="../img/tooltip-icon.png"
                    className={tooltipIconClass}
                    alt="License Type Tooltip"
                    placeholder="blurred"
                  />
                  <span className={typeTooltipText}>
                    Number of Doors affects licensing cost, which is calculated
                    per door.
                  </span>
                </div>
              </div>
              <div className={cameraPriceClass}>
                <input
                  className={error ? errorClass : qtyInput}
                  type="number"
                  value={doorQty}
                  min={1}
                  max={1000}
                  onChange={(e) => handleSetQty(e.target.value)}
                />
              </div>
            </div>
            <div className={lineClass}>
              <div className={tooltipContainer}>
                <div>
                  <h3>Number of Controllers</h3>
                  <p className={smallFontClass}>
                    4 door maximum per controller
                  </p>
                </div>
              </div>
              <div className={controllerQty}>{handleDoorQty()}</div>
            </div>
            <div className={lineClass}>
              <div className={tooltipContainer}>
                <div>
                  <h3>Door License</h3>
                  <p className={smallFontClass}>License is per door</p>
                </div>
              </div>
              <div className={cameraPriceClass}>{handleLicenseSubTotal()}</div>
            </div>
          </div>
          <div className={yearsContainerClass}>
            <h3>Years</h3>
            <div className={yearsButtonsClass}>
              {doorLicensePricing[licenseType].prices.map((p, index) => (
                <button
                  onClick={() => setLicensePriceIndex(index)}
                  key={index}
                  className={
                    licensePriceIndex === index
                      ? circleButtonSelectedClass
                      : circleButtonUnselectedClass
                  }
                >
                  {p.term}
                </button>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={cardContainer} id={info.name.toLowerCase()}>
      <div className={cameraImageContainer}>
        <GatsbyImage
          image={imageRef}
          className={cameraImageClass}
          alt={info?.altText ?? info.name}
          objectFit="contain"
        />
      </div>
      <div className={cameraInfoContainer}>
        <div>
          <div className={lineClass}>
            <h3 className="heavy">{info.name}</h3>
            {!storagePricing ? (
              <h3 className={costTitle}>{moneyString(handleSubTotal())}</h3>
            ) : (
              <></>
            )}
          </div>
          {doorLicensePricing ? (
            <p className={smallFontClass}>Price shown is per unit</p>
          ) : (
            <p className={smallFontClass}>{info.priceUnit}</p>
          )}
          <TextLinkV2 path={info.link} target="_blank" title={"View Specs"} />
        </div>
        {renderQuantityInput()}
        {renderStorageSection()}
        {renderLicensing()}
        {renderDoorLicenseSection()}
        <div>
          <div className={lineClass}>
            <h3 className="heavy">Total Cost</h3>
            <h3>{handleTotal()}</h3>
          </div>
          <div className={lineClass}>
            <p className={smallFontClass}>
              Includes hardware{" "}
              {info.name !== "DR20 Door Reader" && "and license"}
            </p>
            <p className={smallFontClass}>
              <strong>(MSRP USD)</strong>
            </p>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <p className={smallFontClass}>{footNote}</p>
        </div>
      </div>
    </div>
  );
}
