import React from "react";

const IconApi = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M26.2513 13.9993C26.2513 20.7648 20.7668 26.2493 14.0013 26.2493C7.23581 26.2493 1.7513 20.7648 1.7513 13.9993C1.7513 7.23386 7.23581 1.74935 14.0013 1.74935C20.7668 1.74935 26.2513 7.23386 26.2513 13.9993Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M3.5 20.1248L12.6816 10.7915L10.5028 8.5918"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M5.54297 22.7491L17.0007 11.0371"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M8.16797 24.4995L15.0162 17.5957H17.9441"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M11.5169 7.58333C11.5169 8.54983 10.7334 9.33333 9.76693 9.33333C8.80043 9.33333 8.01693 8.54983 8.01693 7.58333C8.01693 6.61684 8.80043 5.83333 9.76693 5.83333C10.7334 5.83333 11.5169 6.61683 11.5169 7.58333Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M19.681 9.91537C19.681 10.8819 18.8975 11.6654 17.931 11.6654C16.9645 11.6654 16.181 10.8819 16.181 9.91537C16.181 8.94887 16.9645 8.16536 17.931 8.16536C18.8975 8.16536 19.681 8.94887 19.681 9.91537Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
      <path
        d="M21.4349 17.4993C21.4349 18.4658 20.6514 19.2493 19.6849 19.2493C18.7184 19.2493 17.9349 18.4658 17.9349 17.4993C17.9349 16.5329 18.7184 15.7493 19.6849 15.7493C20.6514 15.7493 21.4349 16.5328 21.4349 17.4993Z"
        stroke="white"
        stroke-width="1.16667"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default IconApi;
