import IndustryPageUpdated from "components/industries/updated/IndustryPageUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function EducationIndustry() {
  const renderContent = (data) => {
    const pageData = {
      industry: "Retail",
      header: {
        headerTitle: "Improve Store Operations Through Key Insights",
        headerParagraph:
          "Uncover emerging trends and improve decision-making through data-driven insights. Harness the full potential of AI analytics to effectively gauge occupancy, optimize space utilization, track dwell time, and more.",
        headerImg: data.headerImage,
        mobileHeaderImg: data.headerImageMobile,
      },
      industryTitle: "Trusted by Innovative Retail Leaders",
      industryLogos: [
        {
          img: data.logo1,
          width: "137px",
          alt: "LA Fitness",
        },
        {
          img: data.logo2,
          width: "127px",
          alt: "Stance",
        },
        {
          img: data.logo3,
          width: "175px",
          alt: "The UPS Store",
        },
        {
          img: data.logo4,
          width: "122px",
          alt: "Razor",
        },
        {
          img: data.logo5,
          width: "148px",
          alt: "The Paper Store",
        },
        {
          img: data.logo6,
          width: "140px",
          alt: "Talkmore Wireless",
        },
      ],

      learnRows: [
        {
          image: data.learnImage1,
          title: "Proactively Protect Staff, Customers, and Merchandise ",
          description:
            "Fortify security across retail locations with cloud-based security cameras that provide comprehensive benefits, from remote access and scalability to real-time alerts for unwanted activity.",
          linkText: "Learn About Cloud-Based Cameras",
          linkUrl: "/cameras",
        },
        {
          image: data.learnImage2,
          title: "Trigger Quick Emergency Response in Critical Situations",
          description:
            "Swiftly respond to emergencies and after-hours threats with a range of unified solutions, including person-of-interest detection, panic buttons, and alarm monitoring. Verify threats and relay key details to emergency services efficiently.",
          linkText: "Learn About Alarm Monitoring",
          linkUrl: "/alarm-monitoring/",
        },
        {
          image: data.learnImage3,
          title:
            "Integrate Point-of-Sale and Space Utilization Data with Footage",
          description:
            "Layer data on video feeds to visualize occupancy trends, queue lengths, and space utilization to make informed decisions around operations and staffing. Integrate with point-of-sale (POS) systems to automatically pair transactions with footage.",
          linkText: "Learn About Integrations",
          linkUrl: "/integrations",
        },
        {
          image: data.learnImage4,
          title:
            "Quickly Investigate Theft, Shrinkage, and Disputes with Smart Search",
          description:
            "Make investigations easy and fast with multi-camera smart search. Pinpoint relevant footage using intuitive onboard analytics, including face and license plate search, color search, and region search, and review days of footage in minutes. ",
          linkText: "Learn About AI Analytics",
          linkUrl: "/ai-analytics",
        },
        {
          image: data.learnImage5,
          title:
            "Safeguard Stores for Staff and Customers with Unified Access Control",
          description:
            "Centrally manage unlimited doors, stores, and credentials from anywhere. Prevent security breaches, set access schedules for staff, and easily understand who’s onsite by pairing access events with synchronized video, time stamps, and badge data.",
          linkText: "Learn About Access Control",
          linkUrl: "/access-control",
        },
      ],
      industryUseCases: [
        {
          title: "Rhombus in Retail Stores",
          tabTitle: "Retail Stores",
          description:
            "Safeguard staff and customers, detect theft and fraud, and protect stores after hours. Cover blind spots with chain-wise visibility.",
          image: data.useCase1,
          mobileImage: data.useCaseMobile1,
        },
        {
          title: "Rhombus in Grocery & Food Markets",
          tabTitle: "Grocery & Food ",

          description:
            "Reduce shrinkage across multiple stores, monitor inventory, and protect perishables with unified cameras and sensors.",
          image: data.useCase2,
          mobileImage: data.useCaseMobile2,
        },
        {
          title: "Rhombus in Health & Personal Care",
          tabTitle: "Health & Personal Care",
          description:
            "Protect equipment, resolve disputes with video evidence, and create a 24/7 safe space in gyms, spas, salons, and more.",
          image: data.useCase3,
          mobileImage: data.useCaseMobile3,
        },
        {
          title: "Rhombus in Automotive",
          tabTitle: "Automotive",

          description:
            "Secure inventory, gain insight into customer behavior, and use license plate recognition to boost visibility across lots and dealerships.",
          image: data.useCase4,
          mobileImage: data.useCaseMobile4,
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "check mark",
          title: "Simplify Security Management",
          p: "Leverage infinite scalability with no manual maintenance. Streamline hardware infrastructure and manage your security from a single pane of glass.",
        },
        {
          icon: data.icon2,
          iconAlt: "ai analytics",
          title: "Unlock New Operational Insights",
          p: "Identify trends and make data-driven decisions. Utilize powerful AI analytics to measure occupancy, space utilization, dwell time, transactions, and more.",
        },
        {
          icon: data.icon3,
          iconAlt: "detection",
          title: "Reduce Theft and Costs",
          p: "Identify suspicious behavior and receive alerts during after-hour events. Respond proactively with live video monitoring and local emergency dispatch.",
        },
        {
          icon: data.icon4,
          iconAlt: "shield",
          title: "Create Safer Spaces",
          p: "Eliminate blind spots and protect sensitive areas. Monitor, detect, and respond to security incidents across all store locations.",
        },
      ],
      form: {
        title: "Request Demo",
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        paragraph:
          "Contact our team today and see why Rhombus is the leading physical security solution for businesses of all sizes.",
        caseStudy: {
          title: "Rhombus is Trusted by Education Leaders",
          image: data.qouteImage,
          logo: {
            img: data.caseStudyLogo,
            width: "131px",
            alt: "colony",
          },
          link: "/case-studies/colony-hardware/",
          author: {
            avatar: data.avatar,
            name: "Cindee Burns",
            role: "Director of Facilities and Safety at Colony Hardware",
            qoute:
              "“<strong>No other cloud system comes close.</strong> Rhombus checked off more of the boxes - they had better pricing, easy-to-use software, and a team that’s transparent, honest, and service oriented.”",
          },
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Video Security System for Retail | Cloud Physical Security | Rhombus
          </title>
          <meta
            name="description"
            content="Enhance security and optimize retail operations with Rhombus' cloud-based video surveillance system. Utilize AI analytics to safeguard staff and customers, track occupancy, and optimize spaces. Start a free trial today!"
          />
        </Helmet>

        <IndustryPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/retail-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/retail-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: { eq: "components/industries/img/retail/logo1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/industries/img/retail/logo2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/industries/img/retail/logo3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/industries/img/retail/logo4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/industries/img/retail/logo5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: { eq: "components/industries/img/retail/logo6.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/detection.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/shield.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage1: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage2: file(
        relativePath: {
          eq: "components/industries/updated/img/education/alarm-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage3: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/integrations-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage4: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/color-search-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage5: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase1: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/retail-stores-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase2: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/grocery-and-food-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase3: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/health-and-personal-care-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase4: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/automotive-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile1: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/mobile-retail-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile2: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/mobile-grocery-store-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile3: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/mobile-gym-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile4: file(
        relativePath: {
          eq: "components/industries/updated/img/retail/mobile-automotive-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      caseStudyLogo: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/colony-logo.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      qouteImage: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/colony-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/cindee-burns-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
