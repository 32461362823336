import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import useIsMobile from "hooks/useIsMobile";
import { styled } from "@linaria/react";
import React from "react";
import Video from "../Video";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Body, HeroTitle, PreTitle } from "components/rui/typography";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  padding-bottom: ${(props) => (props.image ? 0 : "5rem")};
  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`;

const HeroTextContainer = styled(TextContainer)`
  max-width: 665px;
  @media (max-width: 600px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

const FormColumn = styled(SectionCol)`
  justify-content: flex-end;
  padding-bottom: 5rem;
  @media (max-width: 820px) {
    flex-direction: column;
    gap: 2.5rem;
    padding-bottom: 0;
  }
  @media (max-width: 600px) {
    gap: 0;
    align-items: center;
  }
`;

const HeroLayout5 = ({
  pretTitle,
  title,
  blurb,
  formId,
  formTitle,
  titleSmall = false,
  video = null,
  buttonText = null,
  useHTMLInjection = false,
  image = null,
  altText,
  doNotTrack,
}) => {
  const { isMobile } = useIsMobile(820);
  return (
    <HeroContainer image={!!image}>
      <SectionInner>
        <SectionCol style={{ alignItems: "center" }}>
          <HeroTextContainer
            style={image ? { alignSelf: "flex-end" } : undefined}
          >
            <PreTitle>{pretTitle}</PreTitle>
            <HeroTitle color="var(--primary-bg)">{title}</HeroTitle>
            {useHTMLInjection ? (
              <Body
                style={{ margin: "1.25rem 0", maxWidth: "550px" }}
                dangerouslySetInnerHTML={{ __html: blurb }}
              />
            ) : (
              <Body style={{ margin: "1.25rem 0" }}>{blurb}</Body>
            )}
            {video && !isMobile && <Video src={video.src} height="300px" />}
            {image && !video && !isMobile && (
              <GatsbyImage
                image={getImage(image)}
                loading="eager"
                alt={altText ?? title}
              />
            )}
          </HeroTextContainer>
        </SectionCol>
        <FormColumn>
          <FormWrapper
            width={isMobile ? "100%" : "550px"}
            height="527px"
            title={formTitle}
          >
            <RhombusForm
              formId={formId}
              customSubmitText={buttonText}
              doNotTrack={doNotTrack}
            />
          </FormWrapper>
          {video && isMobile && <Video src={video.src} height="300px" />}
          {image && !video && isMobile && (
            <GatsbyImage
              image={getImage(image)}
              loading="eager"
              alt={altText ?? title}
              style={{ marginTop: "2.5rem", width: "90%" }}
            />
          )}
        </FormColumn>
      </SectionInner>
    </HeroContainer>
  );
};

export default HeroLayout5;
