import React from "react";

const IconAiAnalaytics = ({
  width = "48",
  height = "52",
  color = "var(--blue-500)",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 52"
      fill="none"
    >
      <path
        d="M10.9037 50V42.29C10.9037 41.33 10.5847 40.406 9.97008 39.632C8.45707 37.722 5.43104 33.5559 3.37194 28.4579C-2.61943 13.6239 11.9861 -1.94414 28.3043 2.90188C28.7858 3.04388 29.2654 3.20588 29.7449 3.38588C35.2959 5.47988 39.0285 10.3219 40.0776 15.7739C40.3184 17.0319 40.5337 18.4799 40.6903 20.1379C40.8058 21.3559 41.3225 22.5019 42.1994 23.4159C44.6558 25.9719 48.4061 30.5099 44.0686 30.5099L41.4302 30.7019V39.612C41.4302 39.612 41.1522 43.138 36.989 43.332V43.332C34.1646 43.226 31.8275 45.34 31.8804 47.95L31.9215 50"
        stroke={color}
        stroke-width="3.00001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1846 45.5666V27.3106L11.5391 18.4766"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1016 24.5453L25.6919 16.6933V7.69531"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1836 35.9141H30.2188V27.3101L35.7424 21.6641"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2528 20.0146C11.5442 20.0146 10.9688 19.4266 10.9688 18.7026C10.9688 17.9786 11.5442 17.3906 12.2528 17.3906C12.9613 17.3906 13.5368 17.9786 13.5368 18.7026C13.5368 19.4266 12.9613 20.0146 12.2528 20.0146Z"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0809 31.999C13.3723 31.999 12.7969 31.411 12.7969 30.687C12.7969 29.963 13.3723 29.375 14.0809 29.375C14.7894 29.375 15.3649 29.963 15.3649 30.687C15.3649 31.411 14.7894 31.999 14.0809 31.999Z"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.0965 23.3984C26.8051 23.3984 27.3805 23.9864 27.3805 24.7104C27.3805 25.4344 26.8051 26.0224 26.0965 26.0224C25.388 26.0224 24.8125 25.4344 24.8125 24.7104C24.8125 23.9864 25.388 23.3984 26.0965 23.3984Z"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.9743 8.03857C26.9743 8.76257 26.3988 9.35057 25.6903 9.35057C24.9817 9.35057 24.4062 8.76257 24.4062 8.03857C24.4062 7.31456 24.9817 6.72656 25.6903 6.72656C26.3988 6.72656 26.9743 7.31456 26.9743 8.03857Z"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5446 12.3511C18.5446 13.0751 17.9691 13.6631 17.2606 13.6631C16.552 13.6631 15.9766 13.0751 15.9766 12.3511C15.9766 11.6271 16.552 11.0391 17.2606 11.0391C17.9691 11.0391 18.5446 11.6271 18.5446 12.3511Z"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.0289 21.6636C37.0289 22.3876 36.4535 22.9756 35.7449 22.9756C35.0364 22.9756 34.4609 22.3876 34.4609 21.6636C34.4609 20.9396 35.0364 20.3516 35.7449 20.3516C36.4535 20.3516 37.0289 20.9396 37.0289 21.6636Z"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.0032 25.125H25.9375"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.5075 19.3001L17.2578 12.9141"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.9149 30.6875H14.082"
        stroke={color}
        stroke-width="2.00001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0529 40.6406V49.7846"
        stroke={color}
        stroke-width="2.18001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconAiAnalaytics;
