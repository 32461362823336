import { HeaderTextContainer } from "components/integrations/marketplace/styles/MarketPlace-css";
import { SectionContainer } from "components/rhombus-UI/theme/containers";

import { graphql, StaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import React from "react";

import TextLinkV2 from "../common/TextLinkV2";
import {
  CardTitle,
  FeaturedCaseStudy,
  HeaderLeft,
  HeaderRight,
  HeroInner,
  HeroVideo,
  VideoWrapper,
} from "./styles/caseStudyStyles";
import { Body, HeroTitle, PreTitle } from "components/rui/typography";

export default function CaseStudyHeader() {
  const renderContent = (data) => {
    const { edges: _caseStudy } = data.allMarkdownRemark;
    const { isMobile } = useIsMobile(1024);
    const caseStudy = _caseStudy[0].node;

    const renderFeaturedCaseStudyContent = () => {
      if (isMobile) {
        return (
          <>
            <img
              src={caseStudy.frontmatter.companyLogo}
              className="logo"
              alt=""
            />
            <CardTitle
              style={{
                textAlign: "left",
              }}
              color="var(--blue-900)"
            >
              {caseStudy.frontmatter.pageTitle}
            </CardTitle>
            <TextLinkV2
              title="Read Story"
              color="var(--blue-500)"
              path={caseStudy.fields.slug}
            />
          </>
        );
      }
      return (
        <>
          <img
            src={caseStudy.frontmatter.companyLogo}
            className="logo"
            alt=""
          />
          <CardTitle
            style={{
              textAlign: "left",
              fontSize: "18px",
            }}
            color="var(--blue-900)"
          >
            {caseStudy.frontmatter.pageTitle}
          </CardTitle>
          <TextLinkV2
            title="Read Story"
            color="var(--blue-500)"
            path={caseStudy.fields.slug}
          />
        </>
      );
    };

    return (
      <SectionContainer
        style={{
          background: "var(--gradient-light-fade)",
        }}
      >
        <HeroInner>
          <HeaderLeft style={{ paddingBottom: "0" }}>
            <HeaderTextContainer>
              <PreTitle color="var(--primary-bg)">Customer Stories</PreTitle>
              <HeroTitle color="var(--primary-bg)">
                Making a Difference One Partnership at a Time
              </HeroTitle>
              <Body>
                Experience real-world success stories showcasing how Rhombus
                fosters secure partnerships. No matter your company's size, we
                are committed to delivering simple, smart, and powerful
                solutions tailored to meet unique security needs.
              </Body>
            </HeaderTextContainer>
          </HeaderLeft>
          <HeaderRight>
            <VideoWrapper>
              <HeroVideo
                src={caseStudy.frontmatter.wistiaDataURL}
                playsInline
                autoPlay
                loop
                muted
              />
              <FeaturedCaseStudy>
                {renderFeaturedCaseStudyContent()}
              </FeaturedCaseStudy>
            </VideoWrapper>
          </HeaderRight>
        </HeroInner>
      </SectionContainer>
    );
  };
  const query = graphql`
    query {
      allMarkdownRemark(
        limit: 1
        filter: {
          frontmatter: {
            templateKey: { eq: "case-study" }
            featuredHero: { eq: true }
            companyName: { eq: "Stance" }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              wistiaDataURL
              companyLogo
              heroImage
              companyName
              pageTitle
              featured
              industry
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
