import SlideInContainer from "components/common/SlideInContainer";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { StaticImage } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import { Body, SectionTitle } from "components/rui/typography";

const SectionWrapper = styled(SectionInner)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4rem;
  > div:first-of-type {
    width: 100%;
    max-width: 660px;
  }
`;

const gridContainer = css`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  gap: 1rem;
  .item {
    padding: 1rem;
    border-radius: 4px;
  }
  .item1 {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }

  .item2 {
    grid-row: 1 / span 1;
    grid-column: 2 / span 2;
  }

  .item3 {
    grid-row: 1 / span 2;
    grid-column: 4 / span 1;
  }

  .item4 {
    grid-row: 2 / span 2;
    grid-column: 1 / span 3;
  }

  .item5 {
    grid-row: 3 / span 1;
    grid-column: 4 / span 1;
  }
`;

const TeamGrid = () => {
  return (
    <SectionContainer style={{ background: "var(--nuetral-100)" }}>
      <SectionWrapper>
        <SlideInContainer>
          <TextContainer width="660px">
            <SectionTitle>Rhombus Gives Back</SectionTitle>
            <Body>
              Rhombus is on a mission to build a safer future. As part of that
              goal, we’ve woven a spirit of community service into our culture.
              We are proud to partner with local Sacramento charities and
              foundations to give back to this wonderful community where it all
              started.
            </Body>
          </TextContainer>
        </SlideInContainer>

        <div className={gridContainer}>
          <StaticImage
            src="./img/team-1-min.png"
            alt="Pickig up Trash on the Sacramento River"
            className="item item1"
          />
          <StaticImage
            src="./img/team-2-min.png"
            alt="River Cleanup Team Photo"
            className="item item2"
          />
          <StaticImage
            src="./img/team-3-min.png"
            alt="American River Trail Trash Cleanup"
            className="item item3"
          />
          <StaticImage
            src="./img/team-4-min.png"
            alt="Midtown Trash Cleanup"
            className="item item4"
          />
          <StaticImage
            src="./img/team-5-min.png"
            alt="Rhombus Christmas Mall, Sacramento"
            className="item item5"
          />
        </div>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default TeamGrid;
