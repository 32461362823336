import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useMemo } from "react";

import {
  cameraImageClass,
  cameraImageContainer,
  cameraInfoContainer,
  cardContainer,
  lineClass,
  smallFontClass,
} from "../styles/pricingStyles";
import TextLinkV2 from "components/common/TextLinkV2";

export default function SensorCard({ info }) {
  const specLink = useMemo(() => {
    if (info.name === "E15") {
      return `/sensors/environmental-monitoring/${info.name.toLowerCase()}`;
    }
    return `/sensors/${info.name.toLowerCase()}/`;
  }, [info]);

  const imageRef = getImage(info.image);

  return (
    <div className={cardContainer}>
      <div className={cameraImageContainer}>
        <GatsbyImage
          image={imageRef}
          className={cameraImageClass}
          alt={info?.altText ?? info.name}
          objectFit="contain"
        />
      </div>
      <div className={cameraInfoContainer}>
        <div>
          <h3 className="heavy">
            {info.name} {info.description}
          </h3>
          <TextLinkV2 path={specLink} target="_blank" title={"View Specs"} />
        </div>
        <div>
          <div className={lineClass}>
            <h3 className="heavy">Total Cost</h3>
            <h3> {`$${info.price.toLocaleString()} `}</h3>
          </div>
          <div className={lineClass}>
            <p className={smallFontClass}>No license required*</p>
            <p className={smallFontClass}>
              <strong>(MSRP USD)</strong>
            </p>
          </div>
          {info.name === "B10" ? (
            <div className={lineClass}>
              <p className={smallFontClass} style={{ fontSize: "9px" }}>
                *Panic Mode requires an existing Alarm Monitoring license
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
