import { styled } from "@linaria/react";
import IconCamera from "components/common/icon-components/IconCamera";
import IconGlobe from "components/common/icon-components/IconGlobe";
import IconMapPin from "components/common/icon-components/IconMapPin";
import IconProductivity from "components/common/icon-components/IconProductivity";
import LocationIcon from "components/common/icon-components/LocationIcon";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import React from "react";

const STATS = [
  {
    Icon: <IconGlobe />,
    title: "2000+",
    subTitle: "Global customers",
  },
  {
    Icon: <IconMapPin color="var(--blue-500)" size="44px" />,
    title: "30,000+",
    subTitle: "Locations secured",
  },
  {
    Icon: <IconCamera />,
    title: "100,000+",
    subTitle: "Cameras worldwide",
  },
  {
    Icon: <IconProductivity />,
    title: "150%",
    subTitle: "Productivity increase",
  },
];

const StatContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 24px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 32px;
  }
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 24px;
  }
  h3 {
    font-size: 32px;
  }
  p {
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    width: calc(50% - 24px);
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StatsBlade = () => {
  return (
    <SectionContainer style={{ background: "var(--nuetral-100)" }}>
      <SectionInner>
        <StatContainer>
          {STATS.map((stat) => {
            const { Icon, title, subTitle } = stat;
            return (
              <Stat>
                {Icon}
                <TitleSmall>{title}</TitleSmall>
                <MainParagraph>{subTitle}</MainParagraph>
              </Stat>
            );
          })}
        </StatContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default StatsBlade;
