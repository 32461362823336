import ContactPageV2 from "components/contact/ContactPageV2";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import Helmet from "react-helmet";

export default function CustomQuoteLandingPageInternational() {
  return (
    <GlobalLayout color="var(--gradient-light)" landingPage>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ContactPageV2 />
    </GlobalLayout>
  );
}
