import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "./g2SwiperStyles.css";
import useIsMobile from "hooks/useIsMobile";

export default function G2SwiperCarousel({ children }) {
  const { isMobile } = useIsMobile(1050);

  useEffect(() => {
    if (!isMobile && children.length < 4) {
      const swiperWrapperEl = document.querySelector(".swiper-wrapper");
      swiperWrapperEl.style.justifyContent = "center";
    }
  }, [isMobile]);

  return (
    <div className="g2">
      <Swiper
        slidesPerView={1.15}
        breakpoints={{
          425: {
            slidesPerView: 1.25,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 150,
              snapOnRelease: true,
            },
          },
          625: {
            slidesPerView: 1.5,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 150,
              snapOnRelease: true,
            },
          },
          900: {
            slidesPerView: 2.5,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 250,
              snapOnRelease: true,
            },
          },
          1230: {
            slidesPerView: 3.5,
            scrollbar: {
              enabled: true,
              draggable: true,
              dragSize: 329,
              snapOnRelease: true,
            },
          },
        }}
        speed={600}
        scrollbar={{
          enabled: true,
          draggable: true,
          dragSize: 125,
          snapOnRelease: true,
        }}
        modules={[Scrollbar]}
        className="mySwiper"
        preventInteractionOnTransition={true}
        spaceBetween={20}
      >
        {children.map((child, i) => {
          return <SwiperSlide key={i}>{child}</SwiperSlide>;
        })}
      </Swiper>
    </div>
  );
}
