import React from "react";

const RedirectIcon = ({
  color = "currentColor",
  height = "10",
  width = "10",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M10.6667 3.41669H3.41667C2.77573 3.41669 2.16104 3.6713 1.70783 4.12451C1.25461 4.57773 1 5.19241 1 5.83335V17.9167C1 18.5576 1.25461 19.1723 1.70783 19.6255C2.16104 20.0787 2.77573 20.3334 3.41667 20.3334H15.5C16.1409 20.3334 16.7556 20.0787 17.2088 19.6255C17.6621 19.1723 17.9167 18.5576 17.9167 17.9167V10.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.45825 11.875L20.3333 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2916 1H20.3333V7.04167"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RedirectIcon;
