import useIsMobile from "hooks/useIsMobile";
import { styled } from "@linaria/react";
import React, { useState } from "react";
import ReactPlayer from "react-player";

const PlayerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  :hover button {
    height: 100%;
    width: 100%;
    background: ${props => (props.disabled ? "#082d5930" : "#00c1de30")};
  }
`;

const VideoWrapper = styled.div`
  overflow: hidden;
  video {
    @media (max-width: 600px) {
      object-fit: cover;
    }
  }
`;

const PlayButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: ${props => (props.disabled ? "#082d5980" : "#00c1de80")};
  height: 80px;
  width: 120px;
  transition: all 250ms;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomVideoPlayer = ({ videoUrl, disabled }) => {
  const { isMobile } = useIsMobile(600);
  const [playing, setPlaying] = useState(false);
  return (
    <PlayerWrapper
      onClick={() => !disabled && setPlaying(!playing)}
      disabled={disabled}
    >
      <ReactPlayer
        controls={playing && !disabled}
        playing={playing && !disabled}
        url={`${videoUrl}#t=19`}
        width={"100%"}
        height={isMobile ? 195 : 300}
        wrapper={VideoWrapper}
        style={{ maxWidth: "538px" }}
      />
      {!playing && (
        <PlayButton onClick={() => setPlaying(true)} disabled={disabled}>
          {disabled ? (
            <svg
              width="37"
              height="48"
              viewBox="0 0 37 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 0.25C10.8244 0.25 8.25 2.82436 8.25 6V17H4C1.79086 17 0 18.7909 0 21V44C0 46.2091 1.79086 48 4 48H33C35.2091 48 37 46.2091 37 44V21C37 18.7909 35.2091 17 33 17H28.75V6C28.75 2.82436 26.1756 0.25 23 0.25H14ZM25.25 17V6C25.25 4.75736 24.2426 3.75 23 3.75H14C12.7574 3.75 11.75 4.75736 11.75 6V17H25.25ZM23.361 29.6794C23.361 27.0938 21.2651 25 18.6816 25C16.0959 25 14 27.0938 14 29.6794C14 31.1768 14.7074 32.5088 15.8021 33.3642V36.729C15.8021 38.3113 17.0971 39.6063 18.6816 39.6063C20.2639 39.6063 21.5589 38.3113 21.5589 36.729V33.3642C22.6536 32.5088 23.361 31.1768 23.361 29.6794Z"
                fill="#fff"
              />
            </svg>
          ) : (
            <svg
              width="60"
              height="60"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon points="30,20 70,50 30,80" fill="#fff" />
            </svg>
          )}
        </PlayButton>
      )}
    </PlayerWrapper>
  );
};

export default CustomVideoPlayer;
