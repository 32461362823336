import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import React, { useState } from "react";
import { Body, HeroTitle } from "components/rui/typography";
import { ButtonPrimaryDark } from "components/rui/buttons";
import { TextContainer } from "components/rui/layout";

const HeaderWrapper = styled(SectionContainer)`
  min-height: 90vh;
  background-image: var(--gradient-light);
`;

const HeaderInner = styled(SectionInner)``;

const headerLeft = css`
  flex-grow: 1;
  width: 50%;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    height: 50%;
    width: 100%;
  }
`;
const headerRight = css`
  flex-grow: 1;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    height: 50%;
    width: 100%;
    justify-content: flex-start;
  }
`;

const imageStyles = css`
  width: 100% !important;
`;

const IndustryHeaderUpdated = ({ data }) => {
  const [scrollRef, _] = useState("#book-demo");

  const scrollToForm = () => {
    const references = document.querySelector(scrollRef);
    references.scrollIntoView({
      behavior: "smooth",
    });
  };

  const images = withArtDirection(getImage(data.headerImg), [
    {
      media: "(max-width: 768)",
      image: getImage(data.mobileHeaderImg),
    },
  ]);
  return (
    <HeaderWrapper>
      <HeaderInner>
        <div className={headerLeft}>
          <TextContainer>
            <HeroTitle color="var(--primary-bg)">{data.headerTitle}</HeroTitle>
            <Body>{data.headerParagraph}</Body>
            <ButtonPrimaryDark
              onClick={() => scrollToForm()}
              style={{ marginTop: "0.5rem" }}
            >
              Request Demo
            </ButtonPrimaryDark>
          </TextContainer>
        </div>
        <div className={headerRight}>
          <GatsbyImage
            image={images}
            alt={data.headerTitle}
            objectFit="contain"
            className={imageStyles}
          />
        </div>
      </HeaderInner>
    </HeaderWrapper>
  );
};

export default IndustryHeaderUpdated;
