import React from "react";
import InfoSlider from "../InfoSlider";
import { graphql, useStaticQuery } from "gatsby";

const DefaultInfoSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      slide1: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide2: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide3: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      slide4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `);
  return (
    <InfoSlider
      title="The Benefits of Rhombus"
      data={[
        {
          title: "Enjoy an all-in-one solution that grows with your needs.",
          tabTitle: "Infinite Scalability",

          description:
            "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage access control, security cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
          image: data.slide1,
        },
        {
          tabTitle: "Seamless Deployment",
          title: "Register plug-and-play devices in as little as 5 minutes.",
          description:
            "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates. ",
          image: data.slide2,
        },
        {
          tabTitle: "Proactive Alerts",
          title:
            "Stay in the know with real-time alerts for faces, vehicles, and more.",
          description:
            "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
          image: data.slide3,
        },
        {
          tabTitle: "Easy Investigations",
          title: "Save time with multi-camera investigations and smart search.",
          description:
            "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
          image: data.slide4,
        },
      ]}
    />
  );
};

export default DefaultInfoSlider;
