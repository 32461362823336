import React from "react";

const IconSystem = ({
  width = "52",
  height = "51",
  color = "var(--blue-500)",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 52 51"
      fill="none"
    >
      <path
        d="M37.8466 27.3684C36.9626 33.1983 31.9286 37.6663 25.8507 37.6663C19.1507 37.6663 13.7188 32.2343 13.7188 25.5324C13.7188 18.8304 19.1507 13.3984 25.8507 13.3984C28.3107 13.3984 30.5986 14.1304 32.5086 15.3864"
        stroke={color}
        stroke-width="2.99998"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0038 4.32767L31.3938 1.67969C35.0878 3.20968 35.0978 3.21368 38.7938 4.74567L38.6118 8.30765C40.3918 10.0876 41.2918 10.9876 43.0717 12.7676L46.6337 12.5856C48.1657 16.2816 48.1697 16.2916 49.6997 19.9856L47.0517 22.3756V28.6855L49.6997 31.0755C48.1697 34.7695 48.1657 34.7795 46.6337 38.4735L43.0717 38.2915C41.2918 40.0734 40.3918 40.9714 38.6118 42.7534L38.7938 46.3154C35.0978 47.8454 35.0878 47.8494 31.3938 49.3794L29.0038 46.7334H22.6939L20.3039 49.3794C16.6099 47.8494 16.5999 47.8454 12.9059 46.3154L13.0879 42.7534C11.3059 40.9714 10.4079 40.0734 8.62596 38.2915L5.06398 38.4735C3.53399 34.7795 3.52999 34.7695 2 31.0755L4.64598 28.6855V22.3756L2 19.9856C3.52999 16.2916 3.53399 16.2816 5.06398 12.5856L8.62596 12.7676C10.4079 10.9876 11.3059 10.0876 13.0879 8.30765L12.9059 4.74567C16.5999 3.21368 16.6099 3.20968 20.3039 1.67969L22.6939 4.32767H29.0038Z"
        stroke={color}
        stroke-width="2.99998"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.4062 25.1644L25.2782 31.4284L39.1541 17.6484"
        stroke={color}
        stroke-width="2.99998"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconSystem;
