import BackLink from "components/common/BackLink";
import RhombusButton from "components/common/RhombusButton";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { styled } from "@linaria/react";
import React from "react";

const HeroContainer = styled(SectionContainer)`
  background: var(--primary-bg);
  min-height: 570px;
  padding-bottom: 0;
  color: #fff;
`;

const HeroInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const SectionLeft = styled(SectionCol)`
  align-items: flex-end;
  @media (max-width: 1024px) {
    justify-content: center;
    width: 100%;
  }
`;

const SectionRight = styled(SectionCol)`
  align-items: center;
  padding-bottom: 5rem;
  @media (max-width: 1024px) {
    justify-content: center;
    width: 100%;
    padding-bottom: 0;
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    * {
      width: 100%;
    }
  }
`;

const CameraOverviewHero = ({
  image,
  imageAlt,
  preTitle,
  imageClass,
  title,
  description,
  primaryButton = { title: "View", path: "/" },
  secondaryButton = { title: "Compare", path: "/" },
  backLink = { text: "Browse All Cameras", path: "/cameras/" },
}) => {
  return (
    <HeroContainer>
      <HeroInner>
        <SectionLeft>
          <GatsbyImage
            image={getImage(image)}
            alt={imageAlt}
            objectFit="contain"
            objectPosition={"bottom"}
            className={imageClass}
          />
        </SectionLeft>
        <SectionRight>
          <TextContainer>
            {backLink && (
              <BackLink path={backLink.path} text={backLink.text} light />
            )}
            <PreTitle>{preTitle}</PreTitle>
            <FancyPageTitle>{title}</FancyPageTitle>
            <MainParagraph style={{ margin: "0.75rem 0 1.25rem 0" }}>
              {description}
            </MainParagraph>
            <ButtonRow>
              <RhombusButton
                type="primary-light"
                title={primaryButton.title}
                path={primaryButton.path}
              />
              <RhombusButton
                type="secondary-light"
                title={secondaryButton.title}
                path={secondaryButton.path}
              />
            </ButtonRow>
          </TextContainer>
        </SectionRight>
      </HeroInner>
    </HeroContainer>
  );
};

export default CameraOverviewHero;
