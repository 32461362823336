import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import React from "react";
import caseStudy1 from "./assets/cs-1.png";
import caseStudy1Logo from "../../../../static/img/lbusd-white-logo-2-1-.png";
import caseStudy2 from "./assets/cs-2.png";
import caseStudy2Logo from "../../../../static/img/isff-white.png";
import caseStudy3 from "./assets/cs-3.png";
import caseStudy3Logo from "../../../../static/img/stance-logo-white.png";
import { styled } from "@linaria/react";
import {
  Body,
  PreTitle,
  SectionTitle,
  TitleSmall,
} from "components/rui/typography";
import { TextContainer } from "components/rui/layout";

const features = [
  {
    imageSrc: caseStudy1,
    logo: { src: caseStudy1Logo, h: "101px", w: "290px" },
    title: "Enhancing Laguna Beach Unified's district security",
    description:
      "Find out how LBUSD enhanced campus safety and streamlined operations by upgrading to Rhombus' cloud-managed security system.",
  },
  {
    imageSrc: caseStudy2,
    logo: { src: caseStudy2Logo, h: "69px", w: "236px" },
    title: "Empowering In-Shape's branch growth",
    description:
      "See how In-Shape Family Fitness revolutionized operations across 60+ locations with Rhombus' cloud-based cameras.",
  },
  {
    imageSrc: caseStudy3,
    logo: { src: caseStudy3Logo, h: "59px", w: "241px" },
    title: "Reshaping Stance's retail security and loss prevention ",
    description:
      "Explore how Stance, the innovative athleisure brand, minimized its loss prevention and security management across 20+ stores.",
  },
];

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

const FeatureRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
`;

const FeatureCard = styled.div`
  width: calc((100% / 3) - 2 * 1.125rem);
  max-width: 420px;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  overflow: hidden;
  height: 450px;
  gap: 1.25rem;
  /* box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05); */
  @media (max-width: 1024px) {
    width: calc((100% / 2) - 1.125rem);
  }

  @media (max-width: 768px) {
    width: calc(100%);
  }
`;

const CardImage = styled.div`
  height: 60%;
  width: 100%;
  max-height: 240px;
  border-radius: 12px;
  overflow: hidden;
  background: ${(props) => `url(${props.url})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
    max-width: 90%;
    z-index: 2;
  }
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: hsl(from var(--blue-900) h s l / 60%);
  }
`;
const CardContent = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const CaseStudySection = ({ color = "#F6F8F9" }) => {
  return (
    <SectionContainer color={color}>
      <SectionInner>
        <ContentContainer>
          <TextContainer center style={{ alignItems: "center" }}>
            <PreTitle color={"var(--primary-bg)"} center>
              Trusted by leaders like you
            </PreTitle>
            <SectionTitle color={"var(--primary-bg)"}>
              Proven security solutions for growing organizations
            </SectionTitle>
          </TextContainer>
          <FeatureRow>
            {features.map((feature) => {
              const { imageSrc, title, description, logo } = feature;
              return (
                <FeatureCard>
                  <CardImage url={imageSrc}>
                    <img src={logo.src} height={logo.h} width={logo.w} />
                  </CardImage>
                  <CardContent>
                    <TextContainer>
                      <TitleSmall style={{ fontSize: "22px" }}>
                        {title}
                      </TitleSmall>
                      <Body>{description}</Body>
                    </TextContainer>
                  </CardContent>
                </FeatureCard>
              );
            })}
          </FeatureRow>
        </ContentContainer>
      </SectionInner>
    </SectionContainer>
  );
};

export default CaseStudySection;
