import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import React from "react";
import EventSelector from "./EventSelector";
import LocationIcon from "components/common/icon-components/LocationIcon";
import BackLink from "components/common/BackLink";
import { Body, HeroTitle, TitleSmall } from "components/rui/typography";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
`;

const HeroInner = styled(SectionInner)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const sectionLeft = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const sectionRight = css`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1200px) {
    width: 100%;
    position: relative;
  }
`;

const imageWrapper = css`
  position: relative;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const heroImage = css`
  width: 695px;
  height: 623px;
  object-fit: cover;
  overflow: hidden;
  margin-right: -7.5rem;
  border-radius: 1.25rem;
  object-position: bottom;
  @media (min-width: 1600px) {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
`;

const stateTag = css`
  position: absolute;
  background: var(--white);
  color: var(--blue-500);
  top: 1.25rem;
  left: 1.25rem;
  padding: 0.75rem 1.25rem;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const stateTagRight = cx(
  stateTag,
  css`
    top: 2.5rem;
    right: 2.5rem;
    left: unset;
    @media (min-width: 1200px) {
      display: none;
    }
  `
);

const eventSelectorWrapper = css`
  border-radius: 1rem;
  display: flex;
  background: var(--nuetral-100);
  align-items: center;
  gap: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  width: 100%;
  img {
    height: calc(100% - 2.5rem);
    flex-grow: 1;
    border-radius: 1rem;
    overflow: hidden;
    object-fit: cover;
  }
  @media (min-width: 1200px) {
    background: none;
    padding-right: 0;

    img {
      display: none;
    }
  }
  @media (max-width: 1023px) {
    background: none;
    padding-right: 0;

    img {
      display: none;
    }
  }
`;

const TrainingSessionHero = ({ state, image, trainings, link, formId }) => {
  return (
    <HeroContainer>
      <HeroInner>
        <div className={sectionLeft}>
          <BackLink
            path="/rctp/training-sessions#event-locations"
            text="Event Locations"
          />
          <TextContainer>
            <HeroTitle color="var(--primary-bg)">
              Rhombus Certified Technical Professional Training
            </HeroTitle>
            <Body>
              Our intensive, one-day RCTP training program is your fast track to
              mastering the entire Rhombus product line. Immerse yourself in the
              intricate world of Rhombus cameras, access control, sensors,
              alarms, and more.
            </Body>
          </TextContainer>
          <div className={eventSelectorWrapper}>
            <EventSelector
              events={trainings}
              title="upcoming trainings"
              link={link}
              formId={formId}
            />
            <img
              src={image}
              alt={`Rhombus Certified Technical Professional - ${state}`}
            />
            <div className={stateTagRight}>
              <LocationIcon />
              <TitleSmall color="var(--blue-500)">{state}</TitleSmall>
            </div>
          </div>
        </div>
        <div className={sectionRight}>
          <div className={imageWrapper}>
            <img
              src={image}
              alt={`Rhombus Certified Technical Professional - ${state}`}
              className={heroImage}
            />
            <div className={stateTag}>
              <LocationIcon />
              <TitleSmall color="var(--blue-500)">{state}</TitleSmall>
            </div>
          </div>
        </div>
      </HeroInner>
    </HeroContainer>
  );
};

export default TrainingSessionHero;
