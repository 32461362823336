import React from "react";
import SubFooter from "./SubFooter";
import { styled } from "@linaria/react";
import IconRhombus from "components/common/icon-components/IconRhombus";
import SocialLinks from "./SocialLinks";
import { EXPERIMENTAL_FOOTER_MENUS, FOOTER_MENUS } from "./constants";
import { Link } from "gatsby";
import { css } from "@linaria/core";
import AppDownload from "./AppDownload";
import ComplianceLogos from "./ComplianceLogos";
import useIsMobile from "hooks/useIsMobile";
import { sendPostHogEvent } from "components/form/util/functions";
import RhombusLogo from "components/common/icon-components/RhombusLogo";
import G2Badges from "./G2Badges";
import { TitleSmall } from "components/rui/typography";

const FooterWrapper = styled.footer`
  background-color: var(--primary-bg);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 1.25rem;
  @media (max-width: 1100px) {
    padding-bottom: 0;
  }
`;

const FooterContainerX = styled(FooterContainer)`
  @media (max-width: 1100px) {
    padding-bottom: 3.75rem;
  }
`;

const FooterInner = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  gap: 5rem;
  @media (min-width: 1440px) {
    gap: 7.5rem;
  }
  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FooterInnerX = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  gap: 5rem;
  @media (min-width: 1440px) {
    gap: 7.5rem;
  }
`;

const FooterStart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: row;
    width: 100%;
  }
`;

const Menus = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 2.5rem;
  @media (max-width: 1100px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

const MenusX = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 96px;
  @media (max-width: 1024px) {
    gap: 1.25rem;
    justify-content: space-between;
  }
  @media (max-width: 820px) {
    gap: 2.5rem;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  color: var(--white);
  h4 {
    margin-bottom: 28px;
    font-weight: 500;
  }
  a {
    font-size: 16px;
    text-decoration: none;
    color: var(--white);
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  @media (max-width: 1100px) {
    width: calc((100% - 10rem) / 4);
  }
  @media (max-width: 820px) {
    width: calc(50% - 2.5rem);
  }
`;

const MenuX = styled.nav`
  display: flex;
  flex-direction: column;
  color: var(--white);
  h4 {
    margin-bottom: 28px;
    font-weight: 500;
  }
  a {
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
`;

const FooterEnd = styled(FooterStart)`
  gap: 2.5rem;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    flex-grow: 1;
    width: 100%;
  }
`;

const linkStyles = css`
  font-size: 16px;
  text-decoration: none;
  color: var(--white);
  transition: all 250ms;
  :hover {
    color: var(--primary);
  }
`;

const LogoBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  > a {
    width: unset !important;
    justify-content: unset;
    flex-wrap: wrap;
    @media (max-width: 1100px) {
      width: calc(120px + 1.25rem) !important;
    }
    div:has(img) {
      width: 60px;
    }
  }
`;

const FooterV2 = ({ useExperimentalFooter }) => {
  const { isMobile } = useIsMobile(1100);
  if (useExperimentalFooter) {
    return (
      <FooterWrapper>
        <FooterContainerX>
          <FooterInnerX>
            <MenusX>
              <Link
                to="/"
                onClick={() => sendPostHogEvent("ph_footer_link_click")}
              >
                <RhombusLogo />
              </Link>
              {EXPERIMENTAL_FOOTER_MENUS.map((menu) => (
                <MenuX key={menu.title}>
                  <TitleSmall light>{menu.title}</TitleSmall>
                  {menu.links.map((link) => (
                    <Link
                      to={link.path}
                      className={linkStyles}
                      onClick={() => sendPostHogEvent("ph_footer_link_click")}
                    >
                      {link.title}
                    </Link>
                  ))}
                </MenuX>
              ))}
            </MenusX>
            <LogoBadgeContainer>
              <ComplianceLogos />
              <G2Badges />
            </LogoBadgeContainer>
          </FooterInnerX>
        </FooterContainerX>
        <SubFooter />
      </FooterWrapper>
    );
  }
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterInner>
          <FooterStart>
            <Link
              to="/"
              onClick={() => sendPostHogEvent("ph_footer_link_click")}
            >
              <IconRhombus />
            </Link>

            {!isMobile && <SocialLinks />}
            {isMobile && <ComplianceLogos />}
          </FooterStart>
          <Menus>
            {FOOTER_MENUS.map((menu) => (
              <Menu key={menu.title}>
                <TitleSmall light>{menu.title}</TitleSmall>
                {menu.links.map((link) => (
                  <Link
                    to={link.path}
                    className={linkStyles}
                    onClick={() => sendPostHogEvent("ph_footer_link_click")}
                  >
                    {link.title}
                  </Link>
                ))}
              </Menu>
            ))}
            {isMobile && <AppDownload />}
          </Menus>
          <FooterEnd>
            {!isMobile && <ComplianceLogos />}
            {!isMobile && <AppDownload />}
          </FooterEnd>
        </FooterInner>
      </FooterContainer>
      <SubFooter />
    </FooterWrapper>
  );
};

export default FooterV2;
