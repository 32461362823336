export const CHUNK_SIZE = 9;

export const BREAK_POINT = 820;

export const CATEGORIES = {
  All: "Create a seamless and customized experience with plug-and-play integrations.",
  "AI Analytics": "",
  "Access Control":
    "Improve building security with video-enhanced access control. Combine your existing system with real-time footage and video verification.",
  General:
    "Create a seamless and customized experience with plug-and-play integrations.",
  "Guest Management":
    "Integrate with guest management systems to enhance visitor experiences and increase building security.",
  "Incident Management & Notifications":
    "Accelerate incident response and receive comprehensive video and sensor data exactly where, when, and how you need it. ",
  "Point-of-Sale":
    "Integrate with POS systems to pair transactions with footage, layer data on video feeds, and make informed business decisions.",
  "Single Sign On":
    "Integrate with SSO solutions to enhance cybersecurity and the user experience.",
  "Smart Buildings":
    "Connect building systems for easy IoT management, automation, and efficiency. ",
  "Storage Management":
    "Seamlessly save footage, clips, and data directly to your preferred storage provider.",
  "School Safety": "",
  Retail:
    "Integrate with retail management systems to optimize the customer experience and drive data-based business growth.",
};

export const COLLECTIONS = [
  "New",
  "Beta",
  "Certified",
  "Featured",
  "Coming Soon",
  "3rd Party",
];

export const COLLECTION_NAMES = {
  NEW: "New",
  CERTIFIED: "Certified",
  FEATURED: "Featured",
  COMING_SOON: "Coming Soon",
  BETA: "Beta",
  THIRD_PARTY: "3rd Party",
};

export const WORKS_WITH = [
  "Access Control",
  "Alarm Monitoring",
  "Cameras",
  "IoT Sensors",
];
