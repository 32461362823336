import AccessControlSpecsPage from "components/access-control/specs/AccessControlSpecsPage";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import Helmet from "react-helmet";

export default function SecureFobs() {
  const { isMobile } = useIsMobile(700);
  let exploreDR40Width = "51px";
  let exploreDR20Width = "48px";

  if (isMobile) {
    exploreDR40Width = "41px";
    exploreDR20Width = "38px";
  }

  const data = useStaticQuery(graphql`
    query {
      secureFobsHeader: file(
        relativePath: {
          eq: "components/access-control/img/rhombus-secure-fob-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      secureFobsHeaderMobile: file(
        relativePath: {
          eq: "components/access-control/img/rhombus-secure-fob-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      secureFobsDims: file(
        relativePath: {
          eq: "components/access-control/img/rhombus-secure-fob-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR20: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr20.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR40: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr40.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `);

  const secureFobs = {
    title: "Rhombus Secure Access Fob",
    model: "Secure Fob",
    imageHeader: data.secureFobsHeader,
    imageHeaderMobile: data.secureFobsHeaderMobile,
    heroAlt:
      'A white key fob featuring the word "Rhombus" in bold text and key chain  set against a wood background.',
    objectPosition: isMobile ? "center" : "top",
    description:
      "Give users a High Frequency (13.56 MHz) Secure Fob with advanced authentication and encryption features.",
    image: data.secureFobsDims,
    dimsAlt:
      'A white key fob featuring the word "rhombus" in bold text, set against a plain background.',
    pdf: "/files/specs/secure-fob.pdf",
    column1: [
      {
        title: "Dimensions",
        text: ["40.5mm (1.59in) x 32mm (1.26in) x 5mm (0.20in)"],
      },
      {
        title: "Weight",
        text: ["0.166oz (4.7g)"],
      },
      {
        title: "Material",
        text: "ABS",
      },
      {
        title: "Color",
        text: "White",
      },
      {
        title: "Surface Finish",
        text: "Matte",
      },
      {
        title: "Operating Temperature",
        text: "-25˚C to 85˚C (-13˚F to 185˚F)",
      },
      {
        title: "IC",
        text: "MIFARE DESFire EV1",
      },
      {
        title: "Protocol",
        text: "ISO/IEC 14443A",
      },
      {
        title: "Operating Frequency",
        text: "13.56MHz",
      },
    ],
    column2: [
      {
        title: "Encryption",
        text: "128bit AES",
      },
      {
        title: "Memory",
        text: "2KB",
      },
      {
        title: "UID",
        text: "7B",
      },
      {
        title: "Data Retention",
        text: "10 Years",
      },
      {
        title: "Write Cycles",
        text: "100,000",
      },
      {
        title: "Quantity",
        text: "50 fobs per box, keyrings included",
      },
      {
        title: "Box Dimensions",
        text: "203mm (8in) x 88.9mm (3.5in) x 63.5mm (2.5in)",
      },
      {
        title: "Shipping Weight",
        text: "0.32kg (0.7lbs)",
      },
    ],
    exploreCards: [
      {
        image: data.exploreDR20,
        alt: "DR20 Access Control Door Reader",
        width: exploreDR20Width,
        model: "DR20",
        useCase: "Modern, touchless, and reliable smart reader",
        link: "/access-control/door-readers/dr20/",
      },
      {
        image: data.exploreDR40,
        alt: "DR40 Access Control Door Reader",
        width: exploreDR40Width,
        model: "DR40",
        useCase: "Equipped with a door reader, camera, and intercom",
        link: "/access-control/door-readers/dr40/",
      },
    ],
  };

  return (
    <GlobalLayout color="transparent" dark>
      <Helmet>
        <title>Building Access Your Way | Rhombus Secure Access Fobs</title>
        <meta
          name="description"
          content="Key fob entry system for Rhombus Access Control. Give users convenient, secure fob access to commercial buildings, campuses, and apartment complexes with advanced authentication and encryption features."
        />
      </Helmet>
      <AccessControlSpecsPage model={secureFobs} />
    </GlobalLayout>
  );
}
