import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { css, cx } from "@linaria/core";
import { useAtom, useAtomValue } from "jotai";
import { megaMenuOpenAtom, scrollActiveAtom } from "../atoms/headerAtoms";

const hamburgerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 18px;
  height: 36px;
  padding: 0;
  border: none;
  overflow: hidden;
  background: transparent;
  div {
    width: 100%;
    height: 2px;
    background: var(--nuetral-500);
    transition: background 250ms ease-in;
  }
  :hover div {
    background: var(--nuetral-900);
  }
`;

const hamburgerDarkBackground = cx(
  hamburgerStyles,
  css`
    div {
      background: var(--nuetral-200);
    }
    :hover div {
      background: var(--white);
    }
  `
);

const Burger = ({ styles, api, animationConfig, dark }) => {
  const [megaMenuOpen, setMegaMenuOpen] = useAtom(megaMenuOpenAtom);
  const scrollActive = useAtomValue(scrollActiveAtom);

  const rotate = useSpring({
    transform: megaMenuOpen ? "rotate(360deg)" : "rotate(0deg)",
    delay: 150,
  });

  const handleClick = () => {
    api.start({
      to: megaMenuOpen
        ? [
            {
              transformTop: "translate(0, 17px) rotate(0deg)",
              transformMiddle: "translate(0, 0px) rotate(0deg)",
              transformBottom: "translate(0, -17px) rotate(0deg)",
              config: { clamp: true },
            },
            {
              transformTop: "translate(0, 10px) rotate(0deg)",
              transformMiddle: "translate(0, 0px) rotate(0deg)",
              transformBottom: "translate(0, -10px) rotate(0deg)",

              config: {
                clamp: false,
                friction: animationConfig.frictionLight,
                tension: animationConfig.tension,
              },
              delay: animationConfig.delay,
            },
          ]
        : [
            {
              transformTop: "translate(0, 17px) rotate(0deg)",
              transformMiddle: "translate(0, 0px) rotate(0deg)",
              transformBottom: "translate(0, -17px) rotate(0deg)",
              config: { clamp: true },
            },
            {
              transformTop: "translate(0, 17px) rotate(45deg)",
              transformMiddle: "translate(0, 0px) rotate(45deg)",
              transformBottom: "translate(0, -17px) rotate(-45deg)",
              config: {
                clamp: false,
                friction: animationConfig.frictionLight,
                tension: animationConfig.tension,
              },
              delay: animationConfig.delay,
            },
          ],
    });
    setMegaMenuOpen((prev) => !prev);
  };

  return (
    <animated.button
      onClick={handleClick}
      className={
        dark && !megaMenuOpen && !scrollActive
          ? hamburgerDarkBackground
          : hamburgerStyles
      }
      style={rotate}
    >
      <animated.div
        style={{
          transform: styles.transformTop,
          width: styles.width,
        }}
      />
      <animated.div
        style={{
          transform: styles.transformMiddle,
        }}
      />
      <animated.div
        style={{
          transform: styles.transformBottom,
        }}
      />
    </animated.button>
  );
};

export default Burger;
