import React from "react";
import HeroF from "./HeroF";
import HighlightsF from "./HighlightsF";
import PlatformF from "./PlatformF";
import AllInOneF from "./AllInOneF";
import CaseStudiesF from "./CaseStudiesF";
import FormSectionF from "./FormSectionF";

const SurveillanceLandingPageF = () => {
  return (
    <>
      <HeroF />
      <HighlightsF />
      <PlatformF />
      <AllInOneF />
      <CaseStudiesF />
      <FormSectionF />
    </>
  );
};

export default SurveillanceLandingPageF;
