import { FORM_IDS } from "components/data/landing-page-data";
import { ButtonLink } from "components/rui/buttons";
import { Body } from "components/rui/typography";
import { Link } from "gatsby";
import React from "react";

const Disclaimer = ({ formId }) => {
  return formId === FORM_IDS.FREE_TRIAL ||
    formId === FORM_IDS.FREE_TRIAL_ADS ? (
    <Body style={{ fontSize: "12px" }}>
      <ButtonLink
        as={Link}
        style={{ fontSize: "12px" }}
        to="https://legal.rhombus.com/legal/product-evaluation-agreement"
        target="_blank"
        title="*Trial terms apply."
      >
        *Trial terms apply.
      </ButtonLink>{" "}
      The Rhombus team decides equipment allocation & duration. Some larger
      trials may require a deposit. Rhombus does not charge for any products you
      return at the end of the trial period.
    </Body>
  ) : (
    <Body style={{ fontSize: "12px" }}>
      **By submitting this request, you have read and agree to our{" "}
      <ButtonLink
        as={Link}
        to="https://legal.rhombus.com/privacy/privacy-policy"
        target="_blank"
        style={{ fontSize: "12px" }}
        title="Privacy Policy."
      >
        Privacy Policy.
      </ButtonLink>
    </Body>
  );
};

export default Disclaimer;
